// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

export const getDataKey = (simulationId: string) =>
  `SimulationModal_SimulationTrend-${simulationId}`;

const loadSimulationTrend = (simulationId: string) => {
  const url = UrlService.getApiUrl(config.api.detailPage.simulation.trendUrl, {
    simulationId
  });

  return loadData({
    key: getDataKey(simulationId),
    url,
    method: "get"
  });
};

export default loadSimulationTrend;
