// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IRow } from "common/datagrid/DataGrid";
import IParameterInfo from "../../../models/IParameterInfo";
import isClickable from "./isClickable";

const showFamilyInfo = (row: IRow<IParameterInfo>, isInFamily: boolean) => {
  const clickable = isClickable(row);
  return isInFamily && clickable;
};

export default showFamilyInfo;
