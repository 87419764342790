// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { getDataKey } from "../actions/loadThermal";
import IThermal from "../models/IThermal";

const getThermalSelector = createSelector<
  IState,
  (assetId: string) => Data<IThermal>,
  (assetId: string) => Data<IThermal>
>(
  (state: IState) => (assetId: string) => state.data[getDataKey(assetId)],
  (thermal) => thermal
);

export default getThermalSelector;
