// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { IColumn, IRow } from "common/datagrid/DataGrid";
import HeaderCell from "common/datagrid/HeaderCell";
import * as React from "react";

export interface IHeaderRowProps {
  columns: IColumn[];
  onColumnClick?: (columnId: string, modifierIsPressed: boolean) => void;
  showCheckboxColumn?: boolean;
  onCheckAll?: (e: CheckboxChangeEvent) => void;
  checkedAll?: boolean;
  indeterminateCheckAll?: boolean;
  checkedRows: IRow[];
}

const HeaderRow = (props: IHeaderRowProps) => {
  const {
    columns,
    onColumnClick,
    showCheckboxColumn,
    onCheckAll,
    checkedAll,
    indeterminateCheckAll
  } = props;

  const checkboxHeader = (
    <div
      className={`
    grid-cell default-grey-label checkbox-column-header
  `}
    >
      <Checkbox
        onChange={(e) => {
          if (onCheckAll) onCheckAll(e);
        }}
        checked={checkedAll !== undefined && checkedAll}
        indeterminate={indeterminateCheckAll}
      />
    </div>
  );

  const cells = columns.map((c) => (
    <HeaderCell
      key={c.config.id}
      column={c}
      columns={columns}
      onColumnClick={onColumnClick}
    />
  ));

  return (
    <div className="grid-row">
      {showCheckboxColumn && checkboxHeader}
      {cells}
    </div>
  );
};

export default HeaderRow;
