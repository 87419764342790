// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data from "core/data/models/Data";
import { Actions } from "features/detailpage/actions/DetailPageActions";
import actionsReducer, {
  defaultState as actionsDefaultState,
  IState as IActionsState
} from "features/detailpage/features/actions/reducers/ActionsReducer";
import agingReducer, {
  defaultState as agingDefaultState,
  IState as IAgingState
} from "features/detailpage/features/aging/reducers/AgingReducer";
import assetTreeReducer, {
  IState as IAssetTreeState
} from "features/detailpage/features/assettree/reducers/AssetTreeReducer";
import IBatterConductanceState from "features/detailpage/features/batteryconductance/models/IState";
import batterConductanceReducer, {
  defaultState as batterConductanceDefaultState
} from "features/detailpage/features/batteryconductance/reducers/BatteryConductanceReducer";
import IBatteryVoltageState from "features/detailpage/features/batteryvoltage/models/IState";
import batteryVoltageReducer, {
  defaultState as batteryVoltageDefaultState
} from "features/detailpage/features/batteryvoltage/reducers/BatteryVoltageReducer";
import bushingOfflineReducer, {
  defaultState as bushingOfflineDefaultState,
  IState as IBushingOfflineState
} from "features/detailpage/features/bushingsoffline/reducers/BushingOfflineReducer";
import IBushingVisualizationState from "features/detailpage/features/bushingvisualization/models/IState";
import bushingVisualizationReducer, {
  defaultState as bushingVisualizationDefaultState
} from "features/detailpage/features/bushingvisualization/reducers/BushingVisualizationReducer";
import issuesReducer, {
  defaultState as issuesDefaultState,
  IState as IIssuesState
} from "features/detailpage/features/issues/reducers/IssuesReducer";
import ltcReducer, {
  defaultState as ltcDefaultState,
  IState as ILTCState
} from "features/detailpage/features/ltc/reducers/LTCReducer";
import messagesReducer, {
  IState as IMessagesState
} from "features/detailpage/features/messages/reducers/MessagesReducer";
import agingVisualizationReducer, {
  defaultState as agingVisualizationDefaultState,
  IState as IAgingVisualizationState
} from "features/detailpage/features/replacementscore/reducers/AgingVisualizationReducer";
import replacementScoreReducer, {
  defaultState as replacementScoreDefaultState,
  IState as IReplacementScoreState
} from "features/detailpage/features/replacementscore/reducers/ReplacementScoreReducer";
import reportsReducer, {
  defaultState as reportsDefaultState,
  IState as IReportsState
} from "features/detailpage/features/reports/reducers/ReportsReducer";
import rightPanelReducer, {
  defaultState as rightPanelDefaultState,
  IState as IRightPanelState
} from "features/detailpage/features/rightpanel/reducers/RightPanelReducer";
import riskMatrixReducer, {
  defaultState as riskMatrixDefaultState,
  IState as IRiskMatrixState
} from "features/detailpage/features/riskmatrix/reducers/RiskMatrixReducer";
import riskTrendReducer, {
  defaultState as riskTrendDefaultState,
  IState as IRiskTrendState
} from "features/detailpage/features/risktrend/reducers/RiskTrendReducer";
import subfactorsGridReducer, {
  defaultState as subfactorsGridDefaultState,
  IState as ISubfactorsGridState
} from "features/detailpage/features/subfactorsgrid/reducers/SubfactorsGridReducer";
import { Action, Reducer } from "redux";

export interface IState
  extends IAssetTreeState,
    IMessagesState,
    IReportsState,
    IRiskMatrixState,
    IReplacementScoreState,
    IIssuesState,
    IActionsState,
    ISubfactorsGridState,
    IRightPanelState,
    IBushingVisualizationState,
    ILTCState,
    IBatterConductanceState,
    IBatteryVoltageState,
    IAgingState,
    IAgingVisualizationState,
    IBushingOfflineState,
    IRiskTrendState {}

const defaultState: IState = {
  assetTree: new Data(),
  messages: new Data(),
  ...reportsDefaultState,
  ...riskMatrixDefaultState,
  ...replacementScoreDefaultState,
  ...issuesDefaultState,
  ...actionsDefaultState,
  ...subfactorsGridDefaultState,
  ...rightPanelDefaultState,
  ...bushingVisualizationDefaultState,
  ...ltcDefaultState,
  ...batterConductanceDefaultState,
  ...batteryVoltageDefaultState,
  ...agingDefaultState,
  ...bushingOfflineDefaultState,
  ...agingVisualizationDefaultState,
  ...riskTrendDefaultState
};

const detailPageReducer: Reducer<IState> = (
  state = defaultState,
  action: Action
) => {
  switch (action.type) {
    case Actions.DetailPage_Reset: {
      return defaultState;
    }
    default:
      state = assetTreeReducer(state, action) as IState;
      state = messagesReducer(state, action) as IState;
      state = reportsReducer(state, action) as IState;
      state = riskMatrixReducer(state, action) as IState;
      state = replacementScoreReducer(state, action) as IState;
      state = issuesReducer(state, action) as IState;
      state = actionsReducer(state, action) as IState;
      state = subfactorsGridReducer(state, action) as IState;
      state = rightPanelReducer(state, action) as IState;
      state = bushingVisualizationReducer(state, action) as IState;
      state = ltcReducer(state, action) as IState;
      state = batterConductanceReducer(state, action) as IState;
      state = batteryVoltageReducer(state, action) as IState;
      state = agingReducer(state, action) as IState;
      state = bushingOfflineReducer(state, action) as IState;
      state = agingVisualizationReducer(state, action) as IState;
      state = riskTrendReducer(state, action) as IState;
      return state;
  }
};

export default detailPageReducer;
