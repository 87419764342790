// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";

import Switch from "common/form/components/Switch";

import useRiskMatrixToggleButtons from "../hooks/useRiskMatrixToggleButton";
import IAssetNameplate from "features/detailpage/models/IAssetNameplate";

import "./RiskMatrixToggleButtons.less";

interface IRiskMatrixToggleButtonsProps {
  disabled?: boolean;
  dataQa?: string;
  assetNameplate: IAssetNameplate;
}

const RiskMatrixToggleButtons = ({
  disabled,
  dataQa,
  assetNameplate
}: IRiskMatrixToggleButtonsProps) => {
  const {
    items,
    handleSwitchChange,
    defaultSwitchSelectedId,
    isShown
  } = useRiskMatrixToggleButtons(assetNameplate);

  return (
    <div className="risk-matrix-toggle-buttons">
      {isShown && (
        <Switch
          defaultSelected={defaultSwitchSelectedId}
          disabled={disabled}
          items={items}
          onChange={handleSwitchChange}
          data-qa={dataQa}
        />
      )}
    </div>
  );
};

export default RiskMatrixToggleButtons;
