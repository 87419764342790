// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import MenuPanel, {
  IMenuPanelItem
} from "@pg/common/build/components/MenuPanel";
import Navbar, {
  Divider,
  IMenuItem,
  MenuItem
} from "@pg/common/build/components/Navbar";
import {
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import DocumentService from "core/app/services/DocumentService";
import StorageService from "core/app/services/StorageService";
import Data from "core/data/models/Data";
import UrlService from "core/data/services/UrlService";
import getCustomerSelector from "core/selectors/getCustomerSelector";
import getExperimentalSelector from "core/selectors/getExperimentalSelector";
import { DataConfiguration, ReportsConfiguration } from "features/MenuPanel";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { IState } from "reducers/Index";
import store from "stores/Index";
import styled from "styled-components";
import { ThunkDispatch } from "thunk";
import { config } from "utils/AppConfig";
import loadLinkedReports from "../actions/loadLinkedReports";
import ICustomer from "../models/ICustomer";
import ILinkedReport from "../models/ILinkedReport";
import { getUserSelector } from "../reducers/AppReducer";
import { ITenant } from "../reducers/settings/TenantReducer";
import { IUser } from "../reducers/settings/UserReducer";
import getLinkedReportsSelector from "../selectors/getLinkedReportsSelector";
import AuthorizationService from "../services/AuthorizationService";
import { UserRoles } from "./auth/Authorization";
import { routesConfig } from "./router/RouterProvider";

interface ISiteContentActions {
  loadLinkedReports: () => void;
}

interface ISiteContentData {
  customer: Data<ICustomer>;
  experimental: boolean;
  linkedReports: Data<ILinkedReport[]>;
  locale: string;
  tenants: ITenant[];
  user: Data<IUser>;
}

interface ISiteContentOwnProps {
  className?: string;
  switch: JSX.Element;
}

interface ISiteContentProps
  extends ISiteContentActions,
    ISiteContentData,
    ISiteContentOwnProps {}

const SiteContent = (props: ISiteContentProps) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const { isAvailable } = useFeature();

  const items = useMemo(() => {
    const dashboardItems: IMenuItem[] = [
      new MenuItem(
        routesConfig.dashboards.mainDashboard,
        intl.formatMessage({
          id: "app_menu.main_dashboard",
          defaultMessage: "Main"
        }),
        null,
        {
          filterBar: {
            restoreOnLoad: true
          }
        }
      ),
      new MenuItem(
        routesConfig.dashboards.assetRiskSummary,
        intl.formatMessage({
          id: "app_menu.asset_risk_summary",
          defaultMessage: "Summary"
        }),
        `f_as=["InService"]`,
        {
          filterBar: {
            restoreOnLoad: true
          }
        }
      ),
      new MenuItem(
        routesConfig.dashboards.assetRiskChanges,
        intl.formatMessage({
          id: "app_menu.asset_risk_changes",
          defaultMessage: "Risk Changes"
        }),
        null,
        {
          filterBar: {
            restoreOnLoad: true
          }
        }
      ),
      new MenuItem(
        routesConfig.dashboards.spatialView,
        intl.formatMessage({
          id: "app_menu.spatial",
          defaultMessage: "Spatial View"
        }),
        null,
        {
          filterBar: {
            restoreOnLoad: true
          }
        }
      )
    ];

    if (props.experimental) {
      dashboardItems.push(
        new MenuItem(
          routesConfig.dashboards.assetRiskDashboard,
          intl.formatMessage({
            id: "app_menu.asset_risk_dashboard",
            defaultMessage: "Risk"
          }),
          null,
          {
            filterBar: {
              restoreOnLoad: true
            }
          }
        )
      );
    }

    if (props.linkedReports?.data?.length) {
      dashboardItems.push(new Divider());

      props.linkedReports.data.forEach((r) => {
        dashboardItems.push(new MenuItem(r.Url, r.DisplayName));
      });
    }

    const items: IMenuItem[] = [
      new MenuItem(
        routesConfig.assets,
        intl.formatMessage({
          id: "app_menu.asset_query",
          defaultMessage: "Assets"
        }),
        `f_as=["InService"]`,
        {
          filterBar: {
            restoreOnLoad: true
          }
        }
      ),
      new MenuItem(
        routesConfig.locations,
        intl.formatMessage({
          id: "app_menu.locations",
          defaultMessage: "Locations"
        }),
        null,
        {
          filterBar: {
            restoreOnLoad: true
          }
        }
      ),
      new MenuItem(
        routesConfig.issues,
        intl.formatMessage({
          id: "app_menu.watchlist",
          defaultMessage: "Issues"
        }),
        `f_is=["New"]&f_is=["InProgress"]&f_is=["Monitor"]`,
        {
          filterBar: {
            restoreOnLoad: true
          }
        }
      ),
      new MenuItem(
        routesConfig.familyAnalytics,
        intl.formatMessage({
          id: "app_menu.family_analytics",
          defaultMessage: "Comparisons"
        }),
        `f_as=["InService"]`,
        {
          filterBar: {
            restoreOnLoad: true
          }
        }
      ),
      ...(isAvailable(VisualizationKeys.AlarmsPage_Tab)
        ? [
            new MenuItem(
              routesConfig.alarms,
              intl.formatMessage({
                id: "app_menu.alarms",
                defaultMessage: "Alarms"
              }),
              null,
              {
                filterBar: {
                  restoreOnLoad: true
                }
              }
            )
          ]
        : [])
    ];

    if (
      AuthorizationService.isAuthorized(props.user.data, [
        UserRoles.Administrator,
        UserRoles.Engineer,
        UserRoles.LimitedEngineer,
        UserRoles.ViewerEngineer

      ])
    ) {
      items.unshift(
        new MenuItem(
          routesConfig.dashboard,
          intl.formatMessage({
            id: "app_menu.homepage",
            defaultMessage: "Dashboard"
          }),
          null,
          {
            filterBar: {
              restoreOnLoad: true
            }
          },
          dashboardItems
        )
      );
    }

    return items;
  }, [
    intl,
    props.experimental,
    props.linkedReports,
    props.user.data,
    isAvailable
  ]);

  useEffect(() => {
    if (
      AuthorizationService.isAuthorized(props.user.data, [
        UserRoles.Administrator,
        UserRoles.Engineer
      ])
    )
      props.loadLinkedReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedTenant, setSelectedTenant] = useState({
    tenantId: props.tenants.filter(
      (t) => t.DisplayName === props.user?.data.CustomerId
    )[0].InternalName,
    displayName: props.user?.data.CustomerId
  });

  const handleSelectedTenantChange = useCallback(
    (newTenant: { tenantId: string; displayName: string }) => {
      setSelectedTenant(newTenant);
      window.location.href = UrlService.getApiUrl(
        config.api.user.switchTenant,
        [{ name: "tenantName", value: newTenant.tenantId }]
      );
    },
    []
  );

  const getLogoutUrl = useCallback(
    () =>
      UrlService.getApiUrl(config.api.user.logoutUrl, [
        {
          name: "redirectTo",
          value: window.location.href
        }
      ]),
    []
  );

  const moduleVersions = useMemo(
    () => [
      {
        name: "Lumada APM",
        version: store.getState().app.version.ui.data
      }
    ],
    []
  );

  const menuPanelItems = useMemo(() => {
    const menuPanelItemsAdministrator: IMenuPanelItem[] = [];

    if (
      AuthorizationService.isAuthorized(props.user?.data, [
        UserRoles.Administrator
      ])
    ) {
      menuPanelItemsAdministrator.push({
        id: "ahc-data-upload",
        icon: <Icon name="upload" />,
        name: intl.formatMessage({
          defaultMessage: "Data Upload",
          id: "app_menu.settings.data_upload"
        }),
        embeddedDrawer: (
          <div className="ahc" lang={props.locale}>
            <DataConfiguration />
          </div>
        ),
        dataQa: "data-configuration"
      });

      if (
        props.customer?.data &&
        props.customer?.data.PowerBiEmbeddedPremiumWorkspaceId
      ) {
        menuPanelItemsAdministrator.push({
          id: "ahc-reports-upload",
          icon: <Icon name="apps" />,
          name: intl.formatMessage({
            defaultMessage: "Power BI Reports",
            id: "app_menu.settings.reports_upload"
          }),
          embeddedDrawer: (
            <div className="ahc" lang={props.locale}>
              <ReportsConfiguration />
            </div>
          ),
          dataQa: "powerBiReports"
        });
      }

      menuPanelItemsAdministrator.push({
        id: "ahc-data-integration",
        icon: <Icon name="settings" />,
        name: intl.formatMessage({
          defaultMessage: "Model Admin",
          id: "app_menu.settings.data_integration"
        }),
        action: {
          internalPathname: routesConfig.modelAdmin
        },
        dataQa: "modelAdmin"
      });
    }

    const basicMenuItems: IMenuPanelItem[] = [
      {
        id: "ahc-notifications",
        icon: <Icon name="notifications" />,
        name: intl.formatMessage({
          defaultMessage: "Notifications",
          id: "notifications.label"
        }),
        action: {
          internalPathname: routesConfig.notifications
        },
        dataQa: "notifications"
      },
      {
        id: "ahc-help",
        icon: <Icon name="help_outline" />,
        name: intl.formatMessage({
          defaultMessage: "Help",
          id: "app_menu.settings.help"
        }),
        action: {
          onActionClick: () => {
            const url = UrlService.getApiUrl(config.api.document.userGuide);
            DocumentService.saveOrOpen(url, "application/pdf", intl);
          }
        },
        dataQa: "help"
      },
      {
        id: "release-notes",
        icon: <Icon name="description" />,
        name: intl.formatMessage({
          defaultMessage: "Release Notes",
          id: "app_menu.settings.release_notes"
        }),
        action: {
          onActionClick: () => {
            const url = UrlService.getApiUrl(config.api.document.releaseNotes);
            DocumentService.saveOrOpen(url, "application/pdf", intl);
          }
        },
        dataQa: "release-notes"
      },
      {
        id: "ahc-licenses",
        icon: <Icon name="verified" />,
        name: intl.formatMessage({
          defaultMessage: "Licenses",
          id: "app_menu.settings.licenses"
        }),
        action: {
          internalPathname: routesConfig.licenses
        },
        dataQa: "licenses"
      },
      {
        id: "ahc-provider-information",
        icon: <Icon name="info" />,
        name: intl.formatMessage({
          defaultMessage: "Provider Information",
          id: "app_menu.settings.provider_information"
        }),
        action: {
          externalPathname: "https://www.hitachienergy.com/provider-information"
        },
        dataQa: "provider-information"
      },
      {
        id: "ahc-privacy-policy",
        icon: <Icon name="info" />,
        name: intl.formatMessage({
          defaultMessage: "Privacy Policy",
          id: "app_menu.settings.privacy_policy"
        }),
        action: {
          externalPathname: "https://www.hitachienergy.com/privacy-notices"
        },
        dataQa: "privacy-policy"
      }
    ];

    const menuPanelItems: IMenuPanelItem[] = [
      ...menuPanelItemsAdministrator,
      ...basicMenuItems
    ];

    return menuPanelItems;
  }, [intl, props.customer, props.locale, props.user]);

  return (
    <div className={props.className}>
      <div className="navbar-container">
        <Navbar
          history={history}
          items={items}
          location={location}
          productName={config.app.name}
          shadow
          rightSideExtra={
            <MenuPanel
              className="hamburger"
              user={{
                customerId: props.user?.data.CustomerId,
                login: props.user?.data.Login,
                name: props.user?.data.Name,
                surname: props.user?.data.Surname
              }}
              selectedTenant={selectedTenant}
              onSelectedTenantChange={handleSelectedTenantChange}
              tenants={props.tenants.map((t: ITenant) => ({
                tenantId: t.InternalName,
                displayName: t.DisplayName
              }))}
              translations={{
                changeTenant: intl.formatMessage({
                  defaultMessage: "Change tenant",
                  id: "external-panel.change-tenant"
                }),
                availableTenants: intl.formatMessage({
                  defaultMessage: "Available Tenants",
                  id: "settings_main.tanant_selection.available_tenants"
                }),
                saveChange: intl.formatMessage({
                  defaultMessage: "Save change",
                  id: "settings_main.tenant_selection.switch_button"
                }),
                logout: intl.formatMessage({
                  defaultMessage: "Logout",
                  id: "external-panel.logout"
                }),
                anyIssues: intl.formatMessage({
                  defaultMessage: "Any issues while using Lumada APM?",
                  id: "external-panel.support_label"
                }),
                contactUs: intl.formatMessage({
                  defaultMessage: "Contact us",
                  id: "external-panel.support_contact"
                }),
                noCustomer: intl.formatMessage({
                  defaultMessage: "No customer assignment",
                  id: "external-panel.no-customer-assignment"
                }),
                notAuthenticated: intl.formatMessage({
                  defaultMessage: "Not Authenticated",
                  id: "external-panel.not-authenticated"
                })
              }}
              onLogout={() => {
                StorageService.clearUserSessionStorageItems();
                StorageService.clearSessionStorageItems();
                window.location.href = getLogoutUrl();
              }}
              menuItems={menuPanelItems}
              contactUrl={config.UI.supportContactUrl}
              appName={
                <div className="app-name" data-qa="app-name">
                  {(moduleVersions || []).map((moduleVersion) => (
                    <div
                      key={moduleVersion.name}
                      data-qa={
                        (moduleVersion.name || "").split(" ").join("_") +
                        "-version"
                      }
                    >
                      <FormattedMessage
                        id="external-panel.module-version"
                        defaultMessage="{moduleName} {major}.{minor}.{sprint}.{build}"
                        values={{
                          moduleName: moduleVersion.name,
                          major: (
                            moduleVersion.version.Major || "0"
                          ).toString(),
                          minor: (
                            moduleVersion.version.Minor || "0"
                          ).toString(),
                          sprint: (
                            moduleVersion.version.Sprint || "0"
                          ).toString(),
                          build: (moduleVersion.version.Build || "0").toString()
                        }}
                      />
                    </div>
                  ))}
                </div>
              }
              location={location}
              history={history}
            />
          }
        />
      </div>
      <div className="switch-container">{props.switch}</div>
    </div>
  );
};

const StyledSiteContent = styled(SiteContent)`
  position: relative;
  height: 100vh;
  overflow: hidden;

  .navbar-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
  }

  .switch-container {
    position: absolute;
    top: 50px;
    bottom: 0px;
    width: 100vw;
    overflow: auto;
  }
`;

const mapStateToProps = (state: IState): ISiteContentData => ({
  customer: getCustomerSelector(state),
  experimental: getExperimentalSelector(state),
  linkedReports: getLinkedReportsSelector(state),
  locale: state.app.locale,
  tenants: state.app.tenants.data,
  user: getUserSelector(state)
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any>
): ISiteContentActions => {
  return {
    loadLinkedReports: () => dispatch(loadLinkedReports())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StyledSiteContent);
