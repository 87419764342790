// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import { IState } from "reducers/Index";

export const getPbiReportsSelector = createSelector(
  (state: IState) => state.detailPage.reports.pbiReports,
  pbiReports => pbiReports
);

export default getPbiReportsSelector;
