// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import WearI2tChart from "common/WearI2tChart";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import { Statuses } from "core/data/models/Data";
import useContactWearChart from "../hooks/useContactWearChart";

interface IContactWearChartProps {
  assetId: string;
}

const ContactWearChart = ({ assetId }: IContactWearChartProps) => {
  const { wearChartData, title } = useContactWearChart(assetId);
  return (
    <>
      {wearChartData && wearChartData.status === Statuses.Loading && (
        <Processing />
      )}
      {wearChartData &&
        wearChartData.status === Statuses.Succeeded &&
        wearChartData.data &&
        wearChartData.data.Chart && (
          <WearI2tChart title={title} trendChart={wearChartData.data.Chart} />
        )}
      {wearChartData && wearChartData.status === Statuses.NotFound && (
        <WidgetNoDataMessage />
      )}
      {wearChartData && wearChartData.status === Statuses.Failed && (
        <WidgetErrorMessage />
      )}
    </>
  );
};

export default ContactWearChart;
