// Copyright 2016-2022 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { dataKey } from "../actions/loadAssetRiskIndicators";
import IAssetRiskIndicators from "../models/IAssetRiskIndicators";

const getAssetRiskIndicatorsSelector = createSelector<
  IState,
  Data<IAssetRiskIndicators>,
  Data<IAssetRiskIndicators>
>(
  (state: IState) => state.data[dataKey],
  (assetRiskTrend) => assetRiskTrend
);

export default getAssetRiskIndicatorsSelector;
