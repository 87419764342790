// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";

import { getDataKey } from "../actions/loadParameters";
import IParameterInfo from "../models/IParameterInfo";

const getParametersCountSelector = createSelector<
  IState,
  (assetId: string) => number,
  (assetId: string) => number
>(
  (state: IState) => (assetId: string) => {
    const parameters: Data<IParameterInfo[]> = state.data[getDataKey(assetId)];
    return parameters?.data?.length || 0;
  },
  (parameters) => parameters
);

export default getParametersCountSelector;
