// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import { Button } from "antd";
import Container from "common/Container";
import { ParameterService } from "common/formatters/ParameterName";
import SectionName from "components/common/SectionName";
import React from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import DataIntegrationStore, {
  IModel,
  IModelParameter,
  IModelPossibleValues,
  ModelType,
  ParameterMode
} from "stores/dashboards/dataintegration/DataIntegrationStore";
import "./ModelParametersMapping.less";
import preventEnterPressedInputMixin from "./PreventEnterPressed";

interface IModelParametersMappingProps {
  intl: IntlShape;
  modelPossibleValues: IModelPossibleValues;
  parameters: IModelParameter[];
  selectedModel: IModel;
  sources: string[];
}

class ModelParametersMapping extends React.Component<
  IModelParametersMappingProps,
  {}
> {
  public render() {
    const gridRows = (this.props.parameters || []).map(
      (parameter: IModelParameter, i: number) => {
        let parameterIdCellClassName = "grid-cell";
        if (
          !(
            parameter.ModelParameter === undefined ||
            parameter.ModelParameter == null ||
            DataIntegrationStore.isParameterIdValid(parameter.ModelParameter)
          )
        ) {
          parameterIdCellClassName += " invalid-value";
        }

        let sourceCellClassName = "grid-cell";
        if (
          !DataIntegrationStore.isParameterSourceValid(
            parameter.Source,
            parameter.Input
          )
        ) {
          sourceCellClassName += " invalid-value";
        }

        let inputCellClassName = "grid-cell";
        if (
          !DataIntegrationStore.isParameterInputValid(
            parameter.Source,
            parameter.Input
          )
        ) {
          inputCellClassName += " invalid-value";
        }

        return (
          <div
            className={`grid-row ${
              parameter._Mode === ParameterMode.Edit ? "selected" : ""
            }`}
            key={`new${i}` + parameter._Mode}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              DataIntegrationStore.setParameterToEditMode(i);
            }}
          >
            <div className={parameterIdCellClassName}>
              {parameter._Mode === ParameterMode.Add ? (
                <div>
                  <input
                    defaultValue={parameter.ModelParameter}
                    name={`new${i}_modelId`}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      DataIntegrationStore.setParameterId(
                        i,
                        (e.target as any).value
                      );
                    }}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                      DataIntegrationStore.setParameterId(
                        i,
                        (e.target as any).value
                      );
                    }}
                    {...preventEnterPressedInputMixin}
                    title={this.props.intl.formatMessage({
                      id: "data_integration.model_config.grid.param_id.acceptable_chars",
                      defaultMessage: "Acceptable characters: any"
                    })}
                    type="text"
                  />
                </div>
              ) : (
                <span>
                  {this.getFormattedModelParameter(parameter.ModelParameter)}
                </span>
              )}
            </div>
            <div className={sourceCellClassName}>
              {parameter._Mode === ParameterMode.Add ||
              parameter._Mode === ParameterMode.Edit ? (
                <div>
                  <select
                    defaultValue={parameter.Source || ""}
                    name={`new${i}_source`}
                    onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                      DataIntegrationStore.setParameterSource(
                        i,
                        (e.target as any).value
                      );
                    }}
                    value={parameter.Source || ""}
                  >
                    {[""].concat(this.props.sources).map((source) => (
                      <option key={source} value={source}>
                        {source
                          ? this.props.intl.formatMessage({
                              id: `data_integration.model_config.grid.${source}`,
                              defaultMessage: source
                            })
                          : ""}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <span>
                  {parameter.Source
                    ? this.props.intl.formatMessage({
                        id: `data_integration.model_config.grid.${parameter.Source}`,
                        defaultMessage: parameter.Source
                      })
                    : ""}
                </span>
              )}
            </div>
            <div className={inputCellClassName}>
              {parameter._Mode === ParameterMode.Add ||
              parameter._Mode === ParameterMode.Edit ? (
                <div>
                  <input
                    defaultValue={parameter.Input}
                    list={`new${i}_input`}
                    name={`new${i}_input`}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      DataIntegrationStore.setParameterInput(
                        i,
                        (e.target as any).value
                      );
                    }}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                      DataIntegrationStore.setParameterInput(
                        i,
                        (e.target as any).value
                      );
                    }}
                    {...preventEnterPressedInputMixin}
                    title={this.props.intl.formatMessage({
                      id: "data_integration.model_config.grid.input.acceptable_chars",
                      defaultMessage:
                        "Acceptable characters: big or small letters, digits, space, _ and ."
                    })}
                    type="text"
                  />
                  <datalist id={`new${i}_input`}>
                    {(
                      this.props.modelPossibleValues.PossibleValues[
                        parameter.Source
                      ] || []
                    ).map((value: string) => (
                      <option key={value} value={value} />
                    ))}
                  </datalist>
                </div>
              ) : (
                <span>{parameter.Input}</span>
              )}
            </div>
          </div>
        );
      }
    );

    return (
      <Container className="model-parameters-mapping">
        <SectionName
          messageId="data_integration.model_config.model_parameters_mapping"
          messageDefault="Model Parameters Mapping"
        />
        <div className="grid-custom">
          {this.props.selectedModel &&
          this.props.selectedModel.ModelType !== ModelType.Standard ? (
            <div className="grid-bottons" data-qa="add-parameter">
              <Button
                type="default"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  DataIntegrationStore.addNewParameter();
                }}
              >
                <Icon name="add" />
                <FormattedMessage
                  id="data_integration.model_config.model_parameters_mapping.add_param"
                  defaultMessage="Add parameter"
                />
              </Button>
            </div>
          ) : null}
          <div className="grid-header">
            <div className="grid-row">
              <div className="grid-cell">
                <FormattedMessage
                  id="data_integration.model_config.model_parameters_mapping.model_param"
                  defaultMessage="Model Parameter"
                />
              </div>
              <div className="grid-cell">
                <FormattedMessage
                  id="data_integration.model_config.model_parameters_mapping.source"
                  defaultMessage="Source"
                />
              </div>
              <div className="grid-cell">
                <FormattedMessage
                  id="data_integration.model_config.model_parameters_mapping.input"
                  defaultMessage="Input"
                />
              </div>
            </div>
          </div>
          <div className="grid-body">{gridRows}</div>
        </div>
      </Container>
    );
  }

  public componentDidMount() {
    this.props.parameters.sort((a: IModelParameter, b: IModelParameter) => {
      const left = this.getFormattedModelParameter(a.ModelParameter);
      const right = this.getFormattedModelParameter(b.ModelParameter);
      return left.toLocaleLowerCase() > right.toLocaleLowerCase() ? -1 : 1;
    });
  }

  private getFormattedModelParameter(parameterName: string): string {
    const modelId = this.props.selectedModel.ModelId;
    const implementationId = this.props.selectedModel.ImplementationId;
    if (!parameterName) {
      return "";
    }
    return ParameterService.format(
      parameterName,
      modelId,
      implementationId,
      this.props.intl
    );
  }
}

export default injectIntl(ModelParametersMapping);
