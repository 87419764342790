// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IAjaxAction } from "reducers/Index";
import { Dispatch } from "redux";

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import IOnlineBushingData from "../models/IOnlineBushingData";

export enum Actions {
  DetailPage_BushingVisualization_SetOnlineBushingData = "DetailPage_BushingVisualization_SetOnlineBushingData"
}

export const setOnlineBushingDataAction = (
  request: JQueryXHR,
  data?: IOnlineBushingData
): IAjaxAction<IOnlineBushingData> => ({
  type: Actions.DetailPage_BushingVisualization_SetOnlineBushingData,
  request,
  data
});

export const loadOnlineBushingData = (
  dispatch: Dispatch,
  assetId?: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.onlineBushingVisualizationUrl,
      {
        assetId
      }
    );

    const request = EndpointService.getJson<IOnlineBushingData>(
      url,
      (request, data) => {
        dispatch(setOnlineBushingDataAction(request, data));
        resolve();
      },
      request => {
        dispatch(setOnlineBushingDataAction(request));
        reject();
      }
    );

    dispatch(setOnlineBushingDataAction(request));
  });
};
