// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useSelector } from "react-redux";
import getScatterPlotFilters from "../selectors/getScatterPlotFiltersSelector";
import getScatterPlotXDataSelector from "../selectors/getScatterPlotXDataSelector";
import getScatterPlotYDataSelector from "../selectors/getScatterPlotYDataSelector";

const useFamilyAnalyticsWidgetContainer = () => {
  const scatterPlotFilters = useSelector(getScatterPlotFilters);
  const scatterPlotYData = useSelector(getScatterPlotYDataSelector);
  const scatterPlotXData = useSelector(getScatterPlotXDataSelector);

  return {
    scatterPlotFilters,
    scatterPlotYData,
    scatterPlotXData
  };
};

export default useFamilyAnalyticsWidgetContainer;
