// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";
import { useIntl } from "react-intl";

interface IBushingOfflineChartHeader {
  chartTitle: string;
  manufacturer?: string;
  model?: string;
  installDate?: string;
}

const BushingOfflineChartHeader = ({
  chartTitle,
  manufacturer,
  model,
  installDate
}: IBushingOfflineChartHeader) => {
  const intl = useIntl();

  return (
    <div className="analytics-chart-header">
      <div>{chartTitle}</div>
      <div>
        {manufacturer} {model}{" "}
        {installDate
          ? `(${intl.formatDate(installDate, { year: "numeric" })})`
          : null}
      </div>
    </div>
  );
};

export default BushingOfflineChartHeader;
