// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Dispatch } from "redux";

import removeHeaterStatus from "./removeHeaterStatus";
import removeOperatingMechanismMotor from "./removeOperatingMechanismMotor";
import removeMotorStarts from "./removeMotorStarts";

const removeAuxiliariesData = (dispatch: Dispatch<any>, assetId: string) => {
  dispatch(removeHeaterStatus(assetId));
  dispatch(removeOperatingMechanismMotor(assetId));
  dispatch(removeMotorStarts(assetId));
};

export default removeAuxiliariesData;
