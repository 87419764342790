// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data from "core/data/models/Data";
import { IAjaxAction, IState as IRootState } from "reducers/Index";
import { Reducer } from "redux";
import { createSelector } from "reselect";
import { Actions } from "../actions/KeyRiskChangesActions";
import IKeyRiskChangesInfo from "../models/IKeyRiskChangesInfo";

export interface IState {
  keyRiskChanges: {
    info: Data<IKeyRiskChangesInfo[]>;
  };
}

export const defaultState: IState = {
  keyRiskChanges: {
    info: new Data()
  }
};

const reducer: Reducer<IState> = (
  state = defaultState,
  action: IAjaxAction<IKeyRiskChangesInfo[]>
) => {
  switch (action.type) {
    case Actions.KeyRiskChanges_SetInfo:
      return {
        ...state,
        keyRiskChanges: {
          ...state.keyRiskChanges,
          info: new Data(action.request, action.data)
        }
      };
    default:
      return state;
  }
};

export default reducer;

// #region Selectors

export const getKeyRiskChangesInfoSelector = createSelector(
  (state: IRootState) => state.mainDashboard.keyRiskChanges.info,
  (keyRiskChanges) => keyRiskChanges
);
