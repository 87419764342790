// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import RoutableTabs from "common/RoutableTabs";
import React, { useMemo } from "react";
import useDuvalTabs from "../hooks/useDuvalTabs";

interface IDuvalTabsProps {
  assetId: string;
  implementationId: string;
}

const DuvalTabs = ({ assetId, implementationId }: IDuvalTabsProps) => {
  const tabs = useDuvalTabs(assetId, implementationId.toLowerCase());
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);

  return (
    <div className="duval-tabs">
      <RoutableTabs tabs={tabs} noBorder={true} searchParams={searchParams} />
    </div>
  );
};

export default DuvalTabs;
