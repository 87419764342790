// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Action, Dispatch } from "redux";

import { config } from "utils/AppConfig";
import UrlService from "core/data/services/UrlService";
import EndpointService from "core/data/services/EndpointService";
import { IAjaxAction } from "reducers/Index";

import IOpenIssuesInfo from "../models/IOpenIssuesInfo";

// #region Enums

export enum Actions {
  OpenIssues_LoadOpenIssues = "OpenIssues_LoadOpenIssues"
}

// #endregion Enums

// #region Actions

const setOpenIssuesInfo = (
  request: JQuery.jqXHR,
  data?: IOpenIssuesInfo
): IAjaxAction<IOpenIssuesInfo> => ({
  type: Actions.OpenIssues_LoadOpenIssues,
  request,
  data
});

export const loadOpenIssuesInfo = (dispatch: Dispatch<Action>) => {
  const url: string = UrlService.getApiUrl(config.api.homepage.openIssuesInfo);

  const request = EndpointService.getJson<IOpenIssuesInfo>(
    url,
    (request, data) => dispatch(setOpenIssuesInfo(request, data)),
    (request) => dispatch(setOpenIssuesInfo(request))
  );

  dispatch(setOpenIssuesInfo(request));
};

// #endregion
