// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import IReading from "../models/IReading";

const mapToReadings = (readings: {
  [readingName: string]: IReading;
}): { [readingName: string]: IReading } => {
  const keys = Object.keys(readings);
  const filteredReadings: { [readingName: string]: IReading } = {};

  keys.forEach((k) => {
    const hasData = !!Object.keys(readings[k]).length;
    if (hasData) filteredReadings[k] = readings[k];
  });

  return filteredReadings;
};

export default mapToReadings;
