// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { kebabCase } from "lodash";
import { FormattedMessage } from "react-intl";

import ILTCOperationsChartLegendItem from "features/detailpage/features/ltc/models/ILTCOperationsChartLegendItem";
import { Statuses } from "common/ChartLegend/hooks/useLegend";

interface ILTCOperationsBarChartLegendItemProps {
  item: ILTCOperationsChartLegendItem;
  status: Statuses;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}

const LTCOperationsBarChartLegendItem = ({
  item,
  status,
  onClick,
  onMouseOver,
  onMouseOut
}: ILTCOperationsBarChartLegendItemProps) => {
  return (
    <span
      className={`ltc-operations chart-legend__item ${Statuses[status] &&
        Statuses[status].toLowerCase()}`}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <div
        className="ltc-operations chart-legend__item-box"
        style={{ backgroundColor: item.color }}
      >
        {item.isGridLine && <div className="ltc-legend-grid-line" />}
      </div>
      <span className="ltc-operations chart-legend__item-text">
        <FormattedMessage
          defaultMessage={item.name}
          id={`detail_page.widgets.analytics.transformers.ltc.ltc_operations_chart_legend.${kebabCase(
            item.name
          )}`}
        />
      </span>
    </span>
  );
};

export default LTCOperationsBarChartLegendItem;
