// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { memo } from "react";

import AssetRiskMatrix from "common/AssetRiskMatrix";

import Data from "core/data/models/Data";
import useRiskMatrixChart from "../hooks/useRiskMatrixChart";

import "./RiskMatrixChart.less";
import IAssetDetails from "features/detailpage/models/IAssetDetails";

export interface IRiskMatrixChartProps {
  assetId: string;
  assetDetails: Data<IAssetDetails>;
  grayscale: boolean;
}

const RiskMatrixChart = memo(
  ({ assetId, assetDetails, grayscale }: IRiskMatrixChartProps) => {
    const {
      assetRiskConfiguration,
      currentAsset,
      otherAssets,
      clusters,
      prognosticPoints,
      handleOnAssetClick,
      handleOnClusterClick
    } = useRiskMatrixChart({
      assetId,
      assetDetails
    });

    return (
      <div className="risk-matrix-chart" data-qa="risk-matrix-chart">
        <AssetRiskMatrix
          currentAsset={currentAsset}
          otherAssets={otherAssets}
          clusters={clusters}
          prognosticPoints={prognosticPoints}
          thresholds={assetRiskConfiguration.data}
          grayscale={grayscale}
          onAssetClick={handleOnAssetClick}
          onClusterClick={handleOnClusterClick}
        />
      </div>
    );
  }
);

export default RiskMatrixChart;
