// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { isNull } from "lodash";
import { Statuses } from "core/data/models/Data";
import getDuvalPentagonSelector from "../selectors/getDuvalPentagonSelector";
import { loadDuvalPentagons } from "../actions/loadDuvalPentagons";

const useDuvalPentagonTab = (assetId: string) => {
  const dispatch = useDispatch();
  const duvalPoints = useSelector(getDuvalPentagonSelector);

  useEffect(() => {
    if (duvalPoints.status !== Statuses.Succeeded || isNull(duvalPoints.data)) {
      dispatch(loadDuvalPentagons(assetId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { duvalPoints };
};

export default useDuvalPentagonTab;
