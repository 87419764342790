// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data from "core/data/models/Data";
import {
  getDataKey,
  IParametersData
} from "features/ConfigurationTool/actions/loadParameters";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";

const getParameterSelector = createSelector<
  IState,
  (assetId: string) => Data<IParametersData>,
  (assetId: string) => Data<IParametersData>
>(
  (state: IState) => (assetId: string) => state.data[getDataKey(assetId)],
  (assetDetails) => assetDetails
);

export default getParameterSelector;
