// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import $ from "jquery";

import { UserRoles } from "core/app/components/auth/Authorization";
import { IUser } from "core/app/reducers/settings/UserReducer";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

export default class AuthorizationService {
  public static setupAuthorization() {
    $(document).ajaxError(function (
      event: JQuery.Event,
      jqXHR: JQueryXHR,
      ajaxSettings: JQueryAjaxSettings,
      thrownError: string
    ): void {
      const url = UrlService.getApiUrl(config.api.user.loginUrl, [
        {
          name: "redirectTo",
          value: AuthorizationService.getRedirectUrl()
        }
      ]);

      if (jqXHR.status === 401) {
        window.location.href = url;
      }
    });
  }

  public static isAuthorized(user: IUser, roles: UserRoles[]): boolean {
    if (!user.IsAuthenticated) return false;
    for (const role of user.Roles)
      for (const expectedRole of roles)
        if (role === UserRoles[expectedRole]) return true;
    return false;
  }

  private static getRedirectUrl(): string {
    return (
      window.location.pathname + window.location.search + window.location.hash
    );
  }
}
