// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";

import { config } from "utils/AppConfig";

export const dataKey = "App_LinkedReports";

const loadLinkedReports = () => {
  const url = UrlService.getApiUrl(
    config.api.reports.dashboardExternalReports,
    { reportType: "Dashboard" }
  );

  return loadData({ key: dataKey, url });
};

export default loadLinkedReports;
