// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Reducer } from "redux/index";

import { Actions } from "core/app/actions/settings/EnvironmentActions";
import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";

export interface IEnvironment {
  AdSupportContactUrl: string;
}

export interface IState {
  environment: Data<IEnvironment>;
}

const environmentReducer: Reducer<IState> = (
  state: IState,
  action: IAjaxAction<IEnvironment>
) => {
  switch (action.type) {
    case Actions.Settings_SetEnvironment:
      state = Object.assign({}, state, {
        environment: new Data(action.request, action.data)
      } as IState);
      return state;
    default:
      return state;
  }
};

export default environmentReducer;
