// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import LTCLatestUpdateInfo from "features/detailpage/features/ltc/components/LTCLatestUpdateInfo";
import LTCOperationsBarChart from "features/detailpage/features/ltc/components/LTCOperationsBarChart";
import LTCPercentageUsedInfo from "features/detailpage/features/ltc/components/LTCPercentageUsedInfo";
import ILTCOperations from "features/detailpage/features/ltc/models/ILTCOperations";
import { isEmpty } from "lodash";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import "./LTCOperations.less";

interface ILTCOperationsComponent {
  ltcOperations: ILTCOperations;
  percentageUsed: number;
}

const LTCOperationsComponent = ({
  ltcOperations: {
    AlarmThreshold,
    CumulativeOperationCount,
    LastUpdate,
    MaximumOperationCount,
    WarningThreshold
  },
  percentageUsed
}: ILTCOperationsComponent) => (
  <div className="container ltc-operations-widget" data-qa="ltc-operations">
    <div className="bootstrap-row align-items-center">
      <div className="col-12">
        <div className="ltc-operations-chart__wrapper">
          <h2 className="ltc-operations-chart__title">
            <FormattedMessage
              id="detail_page.widgets.analytics.transformers.ltc.ltc_operations_chart_title"
              defaultMessage="LTC Operations"
            />
          </h2>
          <LTCOperationsBarChart
            percentageUsed={percentageUsed}
            warningThreshold={WarningThreshold}
            alarmThreshold={AlarmThreshold}
          />
        </div>
      </div>
      <div className="col-7">
        <LTCLatestUpdateInfo
          cumulativeOperationCount={CumulativeOperationCount}
          maximumOperationCount={MaximumOperationCount}
          lastUpdate={LastUpdate}
        />
      </div>
      <div className="col-5">
        <LTCPercentageUsedInfo
          value={percentageUsed}
          warningThreshold={WarningThreshold}
          alarmThreshold={AlarmThreshold}
        />
      </div>
    </div>
  </div>
);

interface ILTCOperationsStateProps {
  ltcOperations: Data<ILTCOperations>;
  percentageUsed: number;
}

interface ILTCOperationsProps extends ILTCOperationsStateProps {}

export default ({ ltcOperations, percentageUsed }: ILTCOperationsProps) => {
  switch (ltcOperations.status) {
    case Statuses.Loading:
      return <Processing className="spinner small dark" />;
    case Statuses.Succeeded:
      return isEmpty(ltcOperations.data) ? (
        <WidgetNoDataMessage />
      ) : (
        <LTCOperationsComponent
          ltcOperations={ltcOperations.data}
          percentageUsed={percentageUsed}
        />
      );
    case Statuses.NotFound:
      return <WidgetNoDataMessage />;
    case Statuses.Failed:
      return <WidgetErrorMessage />;
    default:
      return (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      );
  }
};
