// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { SelectedFilters } from "common/FilterBar";
import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

export const dataKey = `AssetRiskDashboard_Trends`;

const loadTrends = (filters: SelectedFilters) => {
  const url = UrlService.getApiUrl(config.api.homepage.assetsSummary.trendsUrl);

  return loadData({
    key: dataKey,
    url,
    method: "post",
    content: {
      search: filters.search,
      filters: filters.selects
    }
  });
};

export default loadTrends;
