// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import React, { useState } from "react";
import { useHistory } from "react-router";

import "./Back.less";

const Back = () => {
  const history = useHistory();
  const [historyLength] = useState(() => history.length);

  const handleBackClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const n = history.length - historyLength + 1;
    history.go(-n);
  };

  return (
    <div
      className="asset-ribbon-back"
      data-qa="return-to-previous-site"
      onClick={handleBackClick}
    >
      <Icon name="chevron_left" size="lg" />
    </div>
  );
};

export default Back;
