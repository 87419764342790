// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import IThreshold from "@apm/widgets/build/models/IThreshold";
import ITimeseries from "@apm/widgets/build/models/ITimeseries";
import { IOption } from "@apm/widgets/build/widgets/LineVisualization";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { colorStatusOrange, colorStatusYellow } from "styles/ColorVariables";
import IDataSeries from "../models/IDataSeries";
import IDCChart from "../models/IDCChart";
import IDCChartOption from "../models/IDCChartOption";

const useLineVisualization = (
  assetId: string,
  measuredApparentCapacitanceChart: IDCChart | undefined,
  oltcOilLevelAndTemperatureChart: IDCChart | undefined,
  oilTemperatureChart: IDCChart | undefined,
  rollingAverageOfVectorLengthChart: IDCChart | undefined,
  coreEarthingCurrent: IDCChart | undefined,
  measuredMoistureChart: IDCChart | undefined,
  sf6PressureChart: IDCChart | undefined,
  sf6PressureVectorLengthChart: IDCChart | undefined
) => {
  const intl = useIntl();

  const getSeries = useCallback(
    (assetDataSeries: { [name: string]: IDataSeries } | undefined) => {
      if (!assetDataSeries) return;

      const seriesKeys = Object.keys(assetDataSeries);
      const series = seriesKeys.map<ITimeseries>((k) => ({
        name: k,
        displayName: k,
        values: assetDataSeries[k]?.Values,
        unit: assetDataSeries[k]?.Unit,
        primary: assetId === k
      }));

      return series;
    },
    [assetId]
  );

  const getTapPositionSeries = useCallback(
    (
      assetDataSeries: { [name: string]: IDataSeries } | undefined,
      typeOfDataSeries: string
    ) => {
      if (!assetDataSeries) return;

      const tapPositionSeriesWithData: ITimeseries[] = [];

      for (const key of Object.keys(assetDataSeries)) {
        if (assetDataSeries[key]?.Values)
          tapPositionSeriesWithData.push({
            name: typeOfDataSeries,
            displayName: intl.formatMessage({
              defaultMessage: typeOfDataSeries,
              id: `detail_page.dc_tab.dc_tab_charts.tap_position`
            }),
            values: assetDataSeries[key]?.Values,
            unit: assetDataSeries[key]?.Unit,
            primary: false
          });
      }

      return [...tapPositionSeriesWithData];
    },
    [intl]
  );

  const getThresholds = useCallback(
    (
      assetId: string,
      referenceLines:
        | {
            [name: string]: {
              [name: string]: IDataSeries | null | undefined;
            };
          }
        | undefined
        | null
    ) => {
      if (!referenceLines) return;
      if (!referenceLines[assetId]) return;

      const thresholdsKeys = Object.keys(referenceLines[assetId]);
      const thresholds = thresholdsKeys.map<IThreshold>((k, i) => {
        const values = referenceLines[assetId][k]?.Values;
        const sections = values
          ? Object.keys(values).map((l) => ({
              start: l,
              end: l,
              value: values[l]
            }))
          : undefined;

        return {
          name: k,
          displayName: intl.formatMessage({
            defaultMessage: k,
            id: `detail_page.dc_tab.dc_tab_charts.${k}`
          }),
          color: i === 0 ? colorStatusYellow : colorStatusOrange,
          sections,
          type: "line"
        };
      });

      return thresholds;
    },
    [intl]
  );

  const getOptions = useCallback(
    (
      dataSeries: IDCChartOption[] | undefined,
      referenceLines:
        | {
            [name: string]: {
              [name: string]: IDataSeries | null | undefined;
            };
          }
        | undefined
        | null
    ) => {
      if (
        dataSeries?.length === 2 &&
        dataSeries?.some((d) => d.TypeOfDataSeries === "HVDC.TapChanger.TapPosition")
      ) {
        const chartSeries = dataSeries?.find(
          (d) => d.TypeOfDataSeries !== "HVDC.TapChanger.TapPosition"
        );
        const tapPositionSeries = dataSeries?.find(
          (d) => d.TypeOfDataSeries === "HVDC.TapChanger.TapPosition"
        );

        const series = [
          ...getSeries(chartSeries.AssetDataSeries),
          ...getTapPositionSeries(
            tapPositionSeries.AssetDataSeries,
            tapPositionSeries.TypeOfDataSeries
          )
        ];

        const option: IOption = {
          name: chartSeries.TypeOfDataSeries,
          displayName: intl.formatMessage({
            defaultMessage: chartSeries.TypeOfDataSeries,
            id: `detail_page.dc_tab.dc_tab_charts.${chartSeries.TypeOfDataSeries}`
          }),
          series
        };

        return [option];
      } else if (referenceLines && dataSeries?.length === 1) {
        const chartOption = dataSeries[0];
        const series = getSeries(chartOption.AssetDataSeries);
        const referenceLinesKeys = Object.keys(referenceLines);
        const options = referenceLinesKeys?.map<IOption>((k) => {
          const thresholds = getThresholds(k, referenceLines);
          const option: IOption = {
            name: k,
            displayName: k,
            series,
            thresholds
          };

          return option;
        });

        return options;
      } else {
        const options = dataSeries?.map<IOption>((o) => {
          const series = getSeries(o.AssetDataSeries);
          const option: IOption = {
            name: o.TypeOfDataSeries,
            displayName: intl.formatMessage({
              defaultMessage: o.TypeOfDataSeries,
              id: `detail_page.dc_tab.dc_tab_charts.${o.TypeOfDataSeries}`
            }),
            series
          };

          return option;
        });

        return options;
      }
    },
    [getSeries, getTapPositionSeries, getThresholds, intl]
  );

  const measuredApparentCapacitanceTrend = useMemo(() => {
    const options = getOptions(
      measuredApparentCapacitanceChart?.DataSeries,
      measuredApparentCapacitanceChart?.ReferenceLines
    );

    return options;
  }, [
    getOptions,
    measuredApparentCapacitanceChart?.DataSeries,
    measuredApparentCapacitanceChart?.ReferenceLines
  ]);

  const oltcOilLevelAndTemperatureTrend = useMemo(() => {
    const options = getOptions(
      oltcOilLevelAndTemperatureChart?.DataSeries,
      oltcOilLevelAndTemperatureChart?.ReferenceLines
    );

    return options;
  }, [
    getOptions,
    oltcOilLevelAndTemperatureChart?.DataSeries,
    oltcOilLevelAndTemperatureChart?.ReferenceLines
  ]);

  const oilTemperatureTrend = useMemo(() => {
    const options = getOptions(
      oilTemperatureChart?.DataSeries,
      oilTemperatureChart?.ReferenceLines
    );

    return options;
  }, [
    getOptions,
    oilTemperatureChart?.DataSeries,
    oilTemperatureChart?.ReferenceLines
  ]);

  const rollingAverageOfVectorLengthTrend = useMemo(() => {
    const options = getOptions(
      rollingAverageOfVectorLengthChart?.DataSeries,
      rollingAverageOfVectorLengthChart?.ReferenceLines
    );

    return options;
  }, [
    getOptions,
    rollingAverageOfVectorLengthChart?.DataSeries,
    rollingAverageOfVectorLengthChart?.ReferenceLines
  ]);

  const coreEarthingCurrentTrend = useMemo(() => {
    const options = getOptions(
      coreEarthingCurrent?.DataSeries,
      coreEarthingCurrent?.ReferenceLines
    );

    return options;
  }, [
    coreEarthingCurrent?.DataSeries,
    coreEarthingCurrent?.ReferenceLines,
    getOptions
  ]);

  const measuredMoistureTrend = useMemo(() => {
    const options = getOptions(
      measuredMoistureChart?.DataSeries,
      measuredMoistureChart?.ReferenceLines
    );

    return options;
  }, [
    getOptions,
    measuredMoistureChart?.DataSeries,
    measuredMoistureChart?.ReferenceLines
  ]);

  const sf6PressureTrend = useMemo(() => {
    const options = getOptions(
      sf6PressureChart?.DataSeries,
      sf6PressureChart?.ReferenceLines
    );

    return options;
  }, [
    getOptions,
    sf6PressureChart?.DataSeries,
    sf6PressureChart?.ReferenceLines
  ]);

  const sf6PressureVectorLengthTrend = useMemo(() => {
    const options = getOptions(
      sf6PressureVectorLengthChart?.DataSeries,
      sf6PressureVectorLengthChart?.ReferenceLines
    );

    return options;
  }, [
    getOptions,
    sf6PressureVectorLengthChart?.DataSeries,
    sf6PressureVectorLengthChart?.ReferenceLines
  ]);

  const translations = useMemo(
    () => ({
      empty: intl.formatMessage({
        defaultMessage: "No data available",
        id: "global.chart.no_data_available"
      })
    }),
    [intl]
  );

  return {
    measuredApparentCapacitanceTrend,
    oltcOilLevelAndTemperatureTrend,
    oilTemperatureTrend,
    rollingAverageOfVectorLengthTrend,
    coreEarthingCurrentTrend,
    measuredMoistureTrend,
    sf6PressureTrend,
    sf6PressureVectorLengthTrend,
    translations
  };
};

export default useLineVisualization;
