// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { connect } from "react-redux";

import IntlProvider, {
  IIntlProviderData
} from "core/app/components/IntlProvider";
import { IState } from "reducers/Index";

function mapStateToProps(state: IState): IIntlProviderData {
  return {
    locale: state.app.locale,
    messages: state.app.translations.data.messages
  };
}

function mapDispatchToProps(): {} {
  return {};
}

const IntlProviderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntlProvider);

export default IntlProviderContainer;
