// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Col, Row } from "antd";
import UrlService from "core/data/services/UrlService";
import React, { useCallback } from "react";
import { config } from "utils/AppConfig";
import HeaterStatusInfo from "./HeaterStatusInfo";
import MotorStarts from "./MotorStarts";
import OperatingMechanismMotorInfo from "./OperatingMechanismMotorInfo";

interface IAuxiliariesTabProps {
  assetId: string;
}

const AuxiliariesTab = ({ assetId }: IAuxiliariesTabProps) => {
  const getAcknowledgeUrl = useCallback(
    (assetId: string, parameterName: string) =>
      UrlService.getApiUrl(config.api.detailPage.auxiliariesAcknowledgeUrl, {
        assetId,
        parameterName
      }),
    []
  );

  return (
    <Row>
      <Col
        span={12}
        order={2}
        lg={{ span: 8, order: 1 }}
        data-qa="heater-status-widget"
      >
        <HeaterStatusInfo
          assetId={assetId}
          getAcknowledgeUrl={getAcknowledgeUrl}
        />
      </Col>
      <Col
        span={12}
        order={3}
        lg={{ span: 7, order: 2 }}
        data-qa="operating-mechanism-widget"
      >
        <OperatingMechanismMotorInfo
          assetId={assetId}
          getAcknowledgeUrl={getAcknowledgeUrl}
        />
      </Col>
      <Col
        span={24}
        order={1}
        lg={{ span: 9, order: 3 }}
        data-qa="motor-starts-widget"
      >
        <MotorStarts assetId={assetId} />
      </Col>
    </Row>
  );
};

export default AuxiliariesTab;
