// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import "common/tile/Tile.less";
import "common/tile/TileErrorMessage.less";

interface ITileErrorMessageProps {
  messageId: string;
  defaultMessage: string;
  className?: string;
}

class TileErrorMessage extends React.Component<ITileErrorMessageProps, {}> {
  public render() {
    return (
      <div className={"tile error " + this.props.className}>
        <div className="tile-content">
          <Icon name="error" />
          <span className="error-message">
            <FormattedMessage
              id={this.props.messageId}
              defaultMessage={this.props.defaultMessage}
            />
          </span>
        </div>
      </div>
    );
  }
}

export default TileErrorMessage;
