// Copyright 2016-2022 Hitachi Energy. All rights reserved.
const coolingClassificationType = [
  "onan",
  "onan/onaf",
  "onan/olaf/onaf",
  "onan/onaf/ofaf",
  "onan/onaf/odaf",
  "onan/ofaf/ofaf",
  "onan/odaf/odaf",
  "ofaf",
  "odaf"
];

export default coolingClassificationType;
