// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { DuvalPoint } from "../../../components/analytics/DuvalTriangle/DuvalTriangle";
import IDuvalRegion from "../../../components/analytics/DuvalTriangle/models/IDuvalRegion";

const type1Default: IDuvalRegion[] = [
  {
    Name: "D1",
    Color: "#CCEEFC",
    LabelCenter: new DuvalPoint(0, 5, 95),
    LabelXOffset: 0,
    LabelYOffset: -10,
    Points: [
      new DuvalPoint(87, 0, 13),
      new DuvalPoint(0, 0, 100),
      new DuvalPoint(0, 23, 77),
      new DuvalPoint(63, 24, 13)
    ]
  },
  {
    Name: "D2",
    Color: "#00A7F1",
    LabelCenter: new DuvalPoint(0, 45, 55),
    LabelXOffset: 0,
    LabelYOffset: -10,
    Points: [
      new DuvalPoint(65, 23, 12),
      new DuvalPoint(0, 23, 77),
      new DuvalPoint(0, 71, 29),
      new DuvalPoint(31, 40, 29),
      new DuvalPoint(47, 40, 13)
    ]
  },
  {
    Name: "PD",
    Color: "#4386FB",
    LabelCenter: new DuvalPoint(99, 1, 0),
    LabelXOffset: 5,
    LabelYOffset: 3,
    Points: [
      new DuvalPoint(100, 0, 0),
      new DuvalPoint(98, 0, 2),
      new DuvalPoint(98, 2, 0)
    ]
  },
  {
    Name: "T1",
    Color: "#87D4EA",
    LabelCenter: new DuvalPoint(90, 10, 0),
    LabelXOffset: 5,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(98, 0, 2),
      new DuvalPoint(96, 0, 4),
      new DuvalPoint(75, 20, 5),
      new DuvalPoint(80, 20, 0),
      new DuvalPoint(98, 2, 0)
    ]
  },
  {
    Name: "T2",
    Color: "#8EB7FD",
    LabelCenter: new DuvalPoint(65, 35, 0),
    LabelXOffset: 5,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(80, 20, 0),
      new DuvalPoint(75, 20, 5),
      new DuvalPoint(46, 50, 4),
      new DuvalPoint(50, 50, 0)
    ]
  },
  {
    Name: "T3",
    Color: "#DAE7FE",
    LabelCenter: new DuvalPoint(25, 70, 5),
    LabelXOffset: -25,
    LabelYOffset: 0,
    Points: [
      new DuvalPoint(50, 50, 0),
      new DuvalPoint(35, 50, 15),
      new DuvalPoint(0, 85, 15),
      new DuvalPoint(0, 100, 0)
    ]
  },
  {
    Name: "DT",
    Color: "#2E98B7",
    LabelCenter: new DuvalPoint(0, 72, 28),
    LabelXOffset: 0,
    LabelYOffset: -10,
    Points: [
      new DuvalPoint(96, 0, 4),
      new DuvalPoint(87, 0, 13),
      new DuvalPoint(47, 40, 13),
      new DuvalPoint(31, 40, 29),
      new DuvalPoint(0, 71, 29),
      new DuvalPoint(0, 85, 15),
      new DuvalPoint(35, 50, 15),
      new DuvalPoint(46, 50, 4)
    ]
  }
];

export default type1Default;
