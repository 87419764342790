// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import $ from "jquery";

import EndpointService from "core/data/services/EndpointService";
import UrlService, { apiPrefix } from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

const csrfTokenHeaderName = "X-Csrf-Token";

export default class CsrfService {
  private static csrfToken: string;

  static setupCsrfProtection(): Promise<{}> {
    CsrfService.configureJQuery();

    return new Promise((resolve, reject) => {
      const url = UrlService.getApiUrl(config.api.security.csrfTokenUrl);
      EndpointService.get(url, resolve, reject);
    });
  }

  private static configureJQuery() {
    $(document).ajaxSend(function (
      event: JQuery.Event,
      jqXHR: JQueryXHR,
      ajaxSettings: JQueryAjaxSettings
    ): void | false {
      if (ajaxSettings.url.indexOf(apiPrefix) < 0) return;
      if (CsrfService.csrfToken == null || CsrfService.csrfToken === "") return;
      if (ajaxSettings.headers == null) ajaxSettings.headers = {};
      ajaxSettings.headers[csrfTokenHeaderName] = CsrfService.csrfToken;
      jqXHR.setRequestHeader(csrfTokenHeaderName, CsrfService.csrfToken);
    });

    $(document).ajaxSuccess(function (
      event: JQuery.Event,
      jqXHR: JQueryXHR,
      ajaxSettings: JQueryAjaxSettings
    ): void | false {
      if (ajaxSettings.url.indexOf(apiPrefix) < 0) return;
      CsrfService.setCsrfToken(jqXHR);
    });

    $(document).ajaxError(function (
      event: JQuery.Event,
      jqXHR: JQueryXHR,
      ajaxSettings: JQueryAjaxSettings,
      thrownError: string
    ): void | false {
      if (ajaxSettings.url.indexOf(apiPrefix) < 0) return;
      CsrfService.setCsrfToken(jqXHR);
    });
  }

  private static setCsrfToken(jqXHR: JQueryXHR): void {
    const token = jqXHR.getResponseHeader(csrfTokenHeaderName);
    if (token === undefined || token == null || token === "") return;
    if (token !== CsrfService.csrfToken) {
      CsrfService.csrfToken = token;
    }
  }
}
