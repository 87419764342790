// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useSelector } from "react-redux";

// selectors
import getAgingOperationsSelector from "features/detailpage/features/aging/selectors/getAgingOperationsSelector";

const useAgingOperations = () => {
  const agingOperations = useSelector(getAgingOperationsSelector);

  return {
    agingOperations
  };
};

export default useAgingOperations;
