// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { first, isNil, max, min } from "lodash";
import { useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";

import getDefaultConfiguration from "./defaultChartConfiguration";

import IBushingLineChartDataSet from "features/detailpage/features/bushingsoffline/models/IBushingLineChartDataSet";
import IBushingReferencedDataSet from "features/detailpage/features/bushingsoffline/models/IBushingReferenceDataSet";
import ISize from "features/detailpage/features/bushingsoffline/models/ISize";

import { ChartConfiguration, GridLineOptionsWithAxis } from "c3";
import Axis from "features/detailpage/features/bushingsoffline/models/Axis";
import BushingOfflineChartService from "features/detailpage/features/bushingsoffline/services/BushingOfflineChartService";

interface IUseBushingsOfflineChartConfiguration {
  series: IBushingLineChartDataSet[];
  size?: ISize;
  powerFactorReference?: IBushingReferencedDataSet;
  capacitanceReference?: IBushingReferencedDataSet;
}

const useBushingsOfflineChartConfiguration = ({
  series,
  size,
  powerFactorReference,
  capacitanceReference
}: IUseBushingsOfflineChartConfiguration) => {
  const intl = useIntl();
  return useMemo(() => {
    const configuration = getDefaultConfiguration(intl);
    const powerFactorSerie = first(series.filter((s) => s.axis === Axis.Left));
    const capacitanceSerie = first(series.filter((s) => s.axis === Axis.Right));

    setDataConfig(configuration, series);
    setReferences(configuration, powerFactorReference, capacitanceReference);
    setEmptyLabel(configuration, intl);
    setSize(configuration, size);
    setMaxMinAxisYValue(configuration, powerFactorSerie, powerFactorReference);
    setMaxMinAxisY2Value(configuration, capacitanceSerie, capacitanceReference);

    return configuration;
  }, [intl, series, powerFactorReference, capacitanceReference, size]);
};

export default useBushingsOfflineChartConfiguration;

// #region Functions

function setDataConfig(
  configuration: ChartConfiguration,
  series: IBushingLineChartDataSet[]
) {
  const { xs, columns, axes, types, colors } =
    BushingOfflineChartService.getChartConfigurationSeriesData(series);

  configuration.data.xs = xs;
  configuration.data.columns = columns;
  configuration.data.axes = axes;
  configuration.data.types = types;
  configuration.data.colors = colors;
}

function setReferences(
  configuration: ChartConfiguration,
  powerFactorReference?: IBushingReferencedDataSet,
  capacitanceReference?: IBushingReferencedDataSet
) {
  const lines: GridLineOptionsWithAxis[] = [];

  if (!isNil(powerFactorReference?.value)) {
    lines.push({
      value: powerFactorReference.value,
      class: "power-factor-threshold",
      axis: "y"
    });
  }

  if (!isNil(capacitanceReference?.value)) {
    lines.push({
      value: capacitanceReference.value,
      class: "capacitance-threshold",
      axis: "y2"
    });
  }

  configuration.grid.y.lines = lines;
}

function setEmptyLabel(configuration: ChartConfiguration, intl: IntlShape) {
  const emptyLabel = intl.formatMessage({
    id: "detail_page.widgets.analytics.linechart.nodata",
    defaultMessage: "NO DATA AVAILABLE"
  });
  configuration.data.empty.label.text = emptyLabel;
}

function setSize(configuration: ChartConfiguration, size?: ISize) {
  if (size) {
    configuration.size = size;
  }
}

function setMaxMinAxisYValue(
  configuration: ChartConfiguration,
  powerFactorSeries: IBushingLineChartDataSet,
  powerFactorReference?: IBushingReferencedDataSet
) {
  if (!isNil(powerFactorReference)) {
    let maxValue = powerFactorReference.value;

    if (
      powerFactorSeries &&
      powerFactorSeries.values &&
      powerFactorSeries.values.length > 0
    ) {
      const seriesMaxValue = max(powerFactorSeries.values.map((s) => s.value));

      if (seriesMaxValue > maxValue) {
        maxValue = seriesMaxValue;
      }
    }
    configuration.axis.y.max = maxValue;
  }
}

function setMaxMinAxisY2Value(
  configuration: ChartConfiguration,
  capacitanceSeries: IBushingLineChartDataSet,
  capacitanceReference?: IBushingReferencedDataSet
) {
  if (!isNil(capacitanceReference)) {
    const maxValue = capacitanceReference.value;
    const minValue = capacitanceReference.value;

    if (
      capacitanceSeries &&
      capacitanceSeries.values &&
      capacitanceSeries.values.length > 0
    ) {
      const seriesMaxValue = max(capacitanceSeries.values.map((s) => s.value));
      const seriesMinValue = min(capacitanceSeries.values.map((s) => s.value));

      if (maxValue > seriesMaxValue) {
        configuration.axis.y2.max = maxValue;
      }

      if (minValue < seriesMinValue) {
        configuration.axis.y2.min = minValue;
      }
    }
  }
}

// #endregion
