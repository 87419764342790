// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { useStore, useDispatch } from "react-redux";
import {
  ThunkAction as ReduxThunkAction,
  ThunkDispatch as ReduxThunkDispatch
} from "redux-thunk";

import { Action } from "redux";
import { IState } from "reducers/Index";

type DefaultExtraArgs = undefined;

export type ThunkAction<R, A extends Action<any>> = ReduxThunkAction<
  R,
  IState,
  DefaultExtraArgs,
  A
>;

export type ThunkDispatch<A extends Action<any>> = ReduxThunkDispatch<
  IState,
  DefaultExtraArgs,
  A
>;

const useThunkMiddleware = <R, A extends Action<any>>(
  thunkAction: ThunkAction<R, A>,
  ...args: Array<any>
) => {
  const store = useStore();
  const dispatch = useDispatch();

  const callback = React.useCallback(
    () => thunkAction(dispatch, store.getState(), undefined),
    [dispatch, store, thunkAction]
  );

  return callback;
};

export { useThunkMiddleware };
