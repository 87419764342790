// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import React from "react";

import "common/tile/TileSwitch.less";

interface IDot {
  checked: boolean;
  onClick: React.EventHandler<React.MouseEvent<HTMLDivElement>>;
}

interface ITileSwitchProps {
  dots: IDot[];
  onLeftClick: React.EventHandler<React.MouseEvent<HTMLImageElement>>;
  onRightClick: React.EventHandler<React.MouseEvent<HTMLImageElement>>;
}

class TileSwitch extends React.Component<ITileSwitchProps, {}> {
  public render() {
    return (
      <div className="tile-switch" data-qa="tile-switch">
        <Icon
          className="arrow"
          dataQa="left"
          name="arrow_left"
          onClick={this.props.onLeftClick}
        />
        {(this.props.dots || []).map((d, i) => {
          return (
            <div
              key={i}
              onClick={d.onClick}
              className={d.checked ? "dot fill" : "dot"}
              data-qa="dot"
            />
          );
        })}
        <Icon
          className="arrow"
          dataQa="right"
          name="arrow_right"
          onClick={this.props.onRightClick}
        />
      </div>
    );
  }
}

export default TileSwitch;
