// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Container from "common/Container";
import DataGrid from "common/datagrid/DataGrid";
import { SelectedFilters, StatusBar } from "common/FilterBar";
import SectionName from "components/common/SectionName";
import { isNil } from "lodash";
import styled from "styled-components";
import { spacingXLarge } from "styles/StyleVariables";
import useLocationsGrid from "../hooks/useLocationsGrid";
import useLocationsPanel from "../hooks/useLocationsPanel";
import LocationsPanel from "./LocationsPanel";

interface ILocationsGridProps {
  className?: string;
  filters: SelectedFilters;
}

const LocationsGrid = ({ className, filters }: ILocationsGridProps) => {
  const {
    handleLocationPanelClose,
    handleLocationPanelOpen,
    isLocationPanelVisible,
    selectedRow,
    selectedContent,
    setSelectedContent,
    contentItems
  } = useLocationsPanel();

  const { dataEndpoint, rowsTotal, columns } = useLocationsGrid({
    filters,
    handleLocationPanelOpen
  });

  return (
    <div className={`${className} data-grid__parent`}>
      <div className="data-grid__scroll">
        <Container>
          <div className="header">
            <div className="title">
              {!isNil(rowsTotal) ? (
                <SectionName
                  messageDefault="Locations / {numberOfLocations, number} items"
                  messageId="locations_page.header.locations_total"
                  messageValues={{
                    numberOfLocations: rowsTotal
                  }}
                />
              ) : (
                <SectionName
                  messageDefault="Locations"
                  messageId="locations_page.header.locations"
                />
              )}
              <StatusBar />
            </div>
          </div>
          <DataGrid columns={columns} dataEndpoint={dataEndpoint} />
          <LocationsPanel
            isLocationsPanelVisible={isLocationPanelVisible}
            handleLocationsPanelClose={handleLocationPanelClose}
            row={selectedRow}
            selectedContent={selectedContent}
            setSelectedContent={setSelectedContent}
            contentItems={contentItems}
          />
        </Container>
      </div>
    </div>
  );
};

export default styled(LocationsGrid)`
  overflow: hidden;
  height: 100%;

  & {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        display: flex;
        align-items: center;

        > *:first-child {
          margin-right: ${spacingXLarge};
        }
      }
    }
  }

  .data-grid__scroll {
    height: 100%;
  }
`;
