// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import IParameter from "@apm/widgets/build/components/Parameters/models/IParameter";
import IParametersGroup from "@apm/widgets/build/components/Parameters/models/IParametersGroup";
import IParameterValue from "@apm/widgets/build/components/Parameters/models/IParameterValue";
import { isNil } from "lodash";
import { FormattedMessage, IntlShape } from "react-intl";
import mapToThresholdCorrection from "../../../utils/mapToThresholdCorrection";
import IAuxiliariesParameter from "../models/IAuxiliariesParameter";
import IAuxiliariesParametersValuesModel from "../models/IAuxiliariesParametersValuesModel";
import IAuxiliariesParameterValue from "../models/IAuxiliariesParameterValue";

export default class AuxilariesParametersMapper {
  private readonly tankStatusParameterKey = "TankStatus";

  constructor(private intl: IntlShape) {}

  public map({
    ColumnsNames,
    Parameters,
    Title
  }: IAuxiliariesParametersValuesModel): IParametersGroup {
    return {
      name: this.intl.formatMessage({
        id: `detail_page.widgets.circuit_breaker.auxiliaries.${Title}`,
        defaultMessage: Title
      }),
      columns: ColumnsNames.map(
        (cn) =>
          ({
            block: (
              <div data-qa="parameter-column-header">
                <FormattedMessage
                  defaultMessage={cn}
                  id={`detail_page.widgets.circuit_breaker.auxiliaries.${cn}`}
                />
              </div>
            ),
            inline: this.intl.formatMessage({
              id: `detail_page.widgets.circuit_breaker.auxiliaries.${cn}`,
              defaultMessage: cn
            })
          } || undefined)
      ),
      parameters: Parameters.map((p) => this.mapParameters(p))
    };
  }

  private mapParameters({ Name, Values }: IAuxiliariesParameter): IParameter {
    return {
      id: Name,
      name: this.intl.formatMessage({
        id: `detail_page.widgets.circuit_breaker.auxiliaries.parameters.${Name}`,
        defaultMessage: Name
      }),
      values: Values.map((p) => {
        if (Name === this.tankStatusParameterKey) {
          return this.mapTankStatus(p);
        } else {
          return this.mapValues(p);
        }
      })
    };
  }

  private mapValues(value: IAuxiliariesParameterValue): IParameterValue {
    if (value?.DataType.toLowerCase() === "dot") {
      return {
        externalId: value.ExternalId,
        thresholdCorrection: mapToThresholdCorrection(
          value.UserThresholdCorrection
        ),
        valueDate: new Date(value.ParameterDate),
        value: {
          type: "dot",
          value: value?.Value?.toLowerCase() as "problem" | "caution" | "normal"
        }
      };
    }

    return {
      value: !isNil(value?.Value) ? Number(value?.Value) : null
    };
  }

  private mapTankStatus(value: IAuxiliariesParameterValue): IParameterValue {
    return {
      externalId: value.ExternalId,
      thresholdCorrection: mapToThresholdCorrection(
        value.UserThresholdCorrection
      ),
      valueDate: new Date(value.ParameterDate),
      value: {
        type: "dot",
        value: value?.Value?.toLowerCase() as "problem" | "caution" | "normal"
      }
    };
  }
}
