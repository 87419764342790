// Copyright 2016-2022 Hitachi Energy. All rights reserved.

import { Divider, List, Skeleton } from "antd";
import Processing from "components/common/Processing";
import { FC, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { spacingSmall } from "styles/StyleVariables";
import IssuesListAssetInfo from "../components/IssuesListAssetInfo";
import useLocationsIssuesList from "../hooks/useLocationsIssuesList";
import AssetWithIssuesViewModel from "../models/AssetWithIssues";
import ViewInIssues from "./ViewInIssues";

interface IIssuesListProps {
  className?: string;
  organization: string;
  region: string;
  location: string;
}

const ListWrapper = styled.div`
  width: calc(100% - ${spacingSmall});
`;

const StyledListItem = styled(List.Item)`
  padding: 0;
`;

const scrollWrapperId = "issue-list-scrollable-wrapper";

const IssuesList: FC<IIssuesListProps> = ({
  className = "",
  organization,
  region,
  location
}) => {
  const { loadMoreData, assetsWithIssues, rowsTotal, loading } =
    useLocationsIssuesList({
      organization,
      region,
      location
    });

  const allIssues = useMemo<AssetWithIssuesViewModel[]>(() => {
    return assetsWithIssues?.reduce((acc, el) => [...acc, ...el.Issues], []);
  }, [assetsWithIssues]);

  return (
    <div className={className}>
      <div id={scrollWrapperId} className="issue-list">
        {loading && assetsWithIssues.length === 0 ? (
          <Processing />
        ) : (
          <InfiniteScroll
            dataLength={allIssues.length}
            next={loadMoreData}
            hasMore={allIssues.length < rowsTotal}
            loader={
              <Skeleton
                paragraph={{ rows: 1 }}
                active={allIssues?.length < rowsTotal || !allIssues}
              />
            }
            endMessage={<Divider plain></Divider>}
            scrollableTarget={scrollWrapperId}
          >
            <ListWrapper>
              <List
                dataSource={assetsWithIssues}
                renderItem={(item) => (
                  <StyledListItem
                    key={item.AssetId}
                    data-qa="asset-with-issues"
                  >
                    <IssuesListAssetInfo key={item.AssetId} asset={item} />
                  </StyledListItem>
                )}
              />
            </ListWrapper>
          </InfiniteScroll>
        )}
      </div>
      <ViewInIssues
        organization={organization}
        region={region}
        location={location}
      />
    </div>
  );
};

export default styled(IssuesList)`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .issue-list {
    height: 100%;
    overflow: auto;
  }
`;
