// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import UrgencyConverter from "common/converters/UrgencyConverter";
import FormContainer from "common/form/containers/FormContainer";
import FormModel from "common/form/models/Form";
import ModalWindow from "components/common/modal/ModalWindow";
import ProcessingModal from "components/common/ProcessingModal";
import ActionsContainer from "features/detailpage/features/actions/containers/ActionsContainer";
import IssueDetailsCommentsModalContainer from "features/detailpage/features/issues/containers/IssueDetailsCommentsModalContainer";
import IssueDetailsHeaderContainer from "features/detailpage/features/issues/containers/IssueDetailsHeaderContainer";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import IIssueFormCreate from "features/detailpage/features/issues/models/IIssueFormCreate";
import IIssueFormEdit from "features/detailpage/features/issues/models/IIssueFormEdit";
import IIssueMeta from "features/detailpage/features/issues/models/IIssueMeta";
import IssueModes from "features/detailpage/features/issues/models/IssueModes";
import IWorkOrder from "features/detailpage/models/IWorkOrder";
import IWorkRequest from "features/detailpage/models/IWorkRequest";
import IWorkRequestForm from "features/detailpage/models/IWorkRequestForm";
import React, { createRef, RefObject } from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import {
  endProcessingIssues,
  startProcessingIssues
} from "../actions/IssuesActions";
import { IssueStatuses } from "../models/IssueStatuses";
import IssueDetailsCondition from "./IssueDetailsCondition";
import IssueDetailsMaintenancePriority from "./IssueDetailsMaintenancePriority";
import IssueDetailsRecommendation from "./IssueDetailsRecommendation";
import IssueDetailsUrgency from "./IssueDetailsUrgency";

export const issueDetailsFormName = "DetailPage_IssueDetailsForm";

export interface IIssueDetailsActions {
  createIssue: (
    intl: IntlShape,
    assetId: string,
    issue: IIssueFormCreate
  ) => Promise<void>;
  editIssue: (
    intl: IntlShape,
    issueId: string,
    issue: IIssueFormEdit
  ) => Promise<void>;
  openModal: (issueId: string) => void;
  removeNewIssue: () => void;
  switchIssueModeToEdit: (issueId: string) => void;
  switchIssueModeToView: (issueId: string) => void;
  updateActiveIssue: (issueId: string) => Promise<void>;
}

export interface IIssueDetailsOwnProps {
  issue: IIssue;
  meta: IIssueMeta;
  onSaved?: () => void;
  onStatusSaved?: () => void;
  readonly: boolean;
  urgencies: string[];
}

type IIssueDetailsProps = IIssueDetailsActions &
  IIssueDetailsOwnProps & { intl: IntlShape } & ReturnType<
    typeof mapDispatchToProps
  >;

interface IIssueDetailsState {
  actionsHaveChanged: boolean;
  formHasChanged: boolean;
  formIsValid: boolean;
  title: string;
  condition: string;
  recommendation: string;
  showProcessing: boolean;
  workRequest: IWorkRequest | IWorkRequestForm;
  workOrders: IWorkOrder[];
}

class IssueDetails extends React.Component<
  IIssueDetailsProps,
  IIssueDetailsState
> {
  constructor(props: IIssueDetailsProps) {
    super(props);

    this.formRef = createRef<HTMLFormElement>();

    this.state = {
      actionsHaveChanged: false,
      formHasChanged: false,
      formIsValid: false,
      title: props.issue.HeaderText,
      condition: props.issue.ConditionText,
      recommendation: props.issue.RecommendationText,
      showProcessing: false,
      workRequest: props.issue.WorkRequest,
      workOrders: props.issue.WorkOrders
    };
  }

  componentDidMount() {
    this.allowToSetState = true;
  }

  componentWillUnmount() {
    this.allowToSetState = false;
  }

  render() {
    const { intl, issue, meta, openModal, readonly, urgencies } = this.props;
    const {
      actionsHaveChanged,
      formHasChanged,
      formIsValid,
      showProcessing,
      condition,
      title,
      recommendation,
      workRequest,
      workOrders
    } = this.state;

    return (
      <FormContainer
        className={`
          issue-details
          ${meta.mode === IssueModes.View ? "view" : ""}
          ${meta.mode === IssueModes.Create ? "create" : ""}
          ${meta.mode === IssueModes.Edit ? "edit" : ""}
        `}
        name={issueDetailsFormName}
        onChange={this.handleFormChange}
        onSubmit={this.handleSubmit}
        showActionButtons={false}
        formRef={this.formRef}
      >
        <div className="bootstrap-row">
          <div className="col-24">
            <IssueDetailsHeaderContainer
              allowSave={formIsValid && (formHasChanged || actionsHaveChanged)}
              issue={issue}
              meta={meta}
              readonly={readonly}
              onEditButtonClick={this.handleEditButtonClick}
              onSaveButtonClick={this.handleSaveButtonClick}
              onCancelButtonClick={this.handleCancelButtonClick}
              onStatusSaved={this.handleStatusSaved}
            />
          </div>
        </div>
        <div className="bootstrap-row">
          <div className="col-24 issue-condition">
            <IssueDetailsCondition issue={issue} meta={meta} />
          </div>
        </div>
        <div className="bootstrap-row">
          <div className="col-24 issue-recommendation">
            <IssueDetailsRecommendation issue={issue} meta={meta} />
          </div>
        </div>
        <div className="bootstrap-row">
          <div className="col-8 issue-urgency">
            <IssueDetailsUrgency
              issue={issue}
              meta={meta}
              urgencies={urgencies}
            />
          </div>
          {issue.Status !== "Closed" && issue.Status !== "Monitor" && (
            <div className="col-8 issue-maintenance-priority">
              <IssueDetailsMaintenancePriority issue={issue} />
            </div>
          )}
          {meta.mode !== IssueModes.Create && (
            <div className="col-8 issue-comments">
              <div className="default-grey-label">
                <FormattedMessage
                  defaultMessage="Comments"
                  id="detail_page.issues.issue_details.comments"
                />
              </div>
              {readonly && (
                <div className="value bold">
                  <span>{issue.Comments ? issue.Comments.length : 0}</span>
                  {issue.Comments && issue.Comments.length ? (
                    <span> - </span>
                  ) : null}
                  {issue.Comments && issue.Comments.length ? (
                    <span
                      className="anchor"
                      onClick={() => {
                        openModal(this.modalId);
                      }}
                    >
                      <FormattedMessage
                        defaultMessage="View"
                        id="detail_page.issues.issue_details.comments.view"
                      />
                    </span>
                  ) : null}
                </div>
              )}
              {!readonly && (
                <div className="value bold">
                  <span>{issue.Comments ? issue.Comments.length : 0}</span>
                  <span> - </span>
                  <span
                    className="anchor"
                    onClick={() => {
                      openModal(this.modalId);
                    }}
                  >
                    {issue.Comments && issue.Comments.length ? (
                      <FormattedMessage
                        defaultMessage="View"
                        id="detail_page.issues.issue_details.comments.view"
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="Add"
                        id="detail_page.issues.issue_details.comments.add"
                      />
                    )}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="bootstrap-row">
          <div className="col-24">
            <ActionsContainer
              assetId={issue.AssetId}
              issueId={issue.Id}
              issueCondition={condition}
              issueRecommendation={recommendation}
              issueTitle={title}
              onChanged={this.handleActionsChanged}
              readonly={readonly}
              workRequest={workRequest}
              workOrders={workOrders}
            />
          </div>
        </div>
        <ModalWindow
          className="modal-window-mixed"
          modalId={this.modalId}
          modalTitle={intl.formatMessage({
            defaultMessage: "Comments",
            id: "detail_page.issues.issue_details.comments"
          })}
        >
          <IssueDetailsCommentsModalContainer
            comments={issue.Comments}
            issueId={issue.Id}
            modalId={this.modalId}
            readonly={readonly}
            onCommentAdded={this.handleCommentAdded}
          />
        </ModalWindow>
        {showProcessing && <ProcessingModal />}
      </FormContainer>
    );
  }

  private allowToSetState: boolean;
  private modalId = "DetailPage_Comments";
  private formRef: RefObject<HTMLFormElement>;

  private handleEditButtonClick = () => {
    const { issue, switchIssueModeToEdit, startProcessingIssues } = this.props;
    switchIssueModeToEdit(issue.Id);
    startProcessingIssues();
  };

  private handleSaveButtonClick = () => {
    const event = document.createEvent("Event");
    event.initEvent("submit", true, true);
    this.formRef.current?.dispatchEvent(event);
  };

  private handleCancelButtonClick = () => {
    const {
      issue,
      meta,
      removeNewIssue,
      switchIssueModeToView,
      endProcessingIssues
    } = this.props;
    if (meta.mode === IssueModes.Create) {
      removeNewIssue();
    } else {
      switchIssueModeToView(issue.Id);
    }

    this.setState({
      workRequest: issue.WorkRequest,
      workOrders: issue.WorkOrders
    });

    endProcessingIssues();
  };

  private handleStatusSaved = (status: IssueStatuses) => {
    const { onStatusSaved } = this.props;
    if (onStatusSaved) onStatusSaved();
    if (status !== "Closed") this.updateActiveIssue();
  };

  private handleCommentAdded = () => {
    this.updateActiveIssue();
  };

  private updateActiveIssue() {
    const {
      updateActiveIssue,
      issue: { Id }
    } = this.props;

    updateActiveIssue(Id);
  }

  private handleActionsChanged = (
    workRequest: IWorkRequest | IWorkRequestForm,
    workOrders: IWorkOrder[]
  ) => {
    const actionsHaveChanged =
      JSON.stringify(workRequest || undefined) !==
        JSON.stringify(this.props.issue.WorkRequest || undefined) ||
      JSON.stringify(workOrders || undefined) !==
        JSON.stringify(this.props.issue.WorkOrders || undefined);

    this.setState({
      actionsHaveChanged,
      workRequest: workRequest || null,
      workOrders: workOrders || null
    });
  };

  private handleFormChange = (form: FormModel) => {
    this.setState({
      formHasChanged: form.hasChanged,
      formIsValid: form.valid,
      title: form.inputs.get("title") ? form.inputs.get("title").value : "",
      condition: form.inputs.get("condition")
        ? form.inputs.get("condition").value
        : "",
      recommendation: form.inputs.get("recommendation")
        ? form.inputs.get("recommendation").value
        : ""
    });
  };

  private handleSubmit = (form: FormModel) => {
    const { intl } = this.props;
    const {
      createIssue,
      editIssue,
      issue,
      meta,
      onSaved,
      switchIssueModeToView
    } = this.props;
    const { workRequest: userWorkRequest, workOrders: userWorkOrders } =
      this.state;

    if (meta.mode === IssueModes.Create) {
      const issueForm: IIssueFormCreate = {
        HeaderText: form.inputs.get("title").value,
        ConditionText: form.inputs.get("condition").value,
        RecommendationText: form.inputs.get("recommendation").value,
        Status: form.inputs.get("status").value,
        Impact: issue.Impact,
        Urgency: UrgencyConverter.convertUiUrgencyToApiUrgency(
          form.inputs.get("urgency").value
        ),
        WorkRequest: userWorkRequest,
        WorkOrders:
          userWorkOrders && userWorkOrders.length
            ? userWorkOrders.map((o) => o.WorkOrderIdentifier)
            : null
      };

      this.showProcessing();
      createIssue(intl, issue.AssetId, issueForm)
        .then(() => {
          switchIssueModeToView(issue.Id);
          this.hideProcessing();
          if (onSaved) onSaved();
        })
        .catch(this.hideProcessing);
    } else if (meta.mode === IssueModes.Edit) {
      const issueForm: IIssueFormEdit = {
        HeaderText: form.inputs.get("title").value,
        ConditionText: form.inputs.get("condition").value,
        RecommendationText: form.inputs.get("recommendation").value,
        Status: form.inputs.get("status").value,
        Impact: issue.Impact,
        Urgency: UrgencyConverter.convertUiUrgencyToApiUrgency(
          form.inputs.get("urgency").value
        ),
        WorkRequest: userWorkRequest,
        WorkOrders:
          userWorkOrders && userWorkOrders.length
            ? userWorkOrders.map((o) => o.WorkOrderIdentifier)
            : null
      };

      this.showProcessing();
      editIssue(intl, issue.Id, issueForm)
        .then(() => {
          switchIssueModeToView(issue.Id);
          this.hideProcessing();
          if (onSaved) onSaved();
        })
        .catch(this.hideProcessing);
    }
  };

  private hideProcessing = () => {
    if (this.allowToSetState) this.setState({ showProcessing: false });
  };

  private showProcessing = () => {
    if (this.allowToSetState) this.setState({ showProcessing: true });
  };
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  startProcessingIssues: () => dispatch(startProcessingIssues()),
  endProcessingIssues: () => dispatch(endProcessingIssues())
});

const IssueDetailsWithIntl = connect(
  null,
  mapDispatchToProps
)(injectIntl(IssueDetails));

export default IssueDetailsWithIntl;
