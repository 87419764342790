// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import Reports, {
  IReportsActions,
  IReportsStateProps
} from "features/detailpage/features/reports/components/Reports";

import getPbiReportsSelector from "features/detailpage/features/reports/selectors/getPbiReportsSelector";
import getLinkedReportsSelector from "features/detailpage/features/reports/selectors/getLinkedReportsSelector";

import {
  loadPbiReports,
  loadLinkedReports
} from "features/detailpage/features/reports/actions/ReportsActions";

import { IState } from "reducers/Index";

function mapStateToProps(state: IState): IReportsStateProps {
  return {
    pbiReports: getPbiReportsSelector(state),
    linkedReports: getLinkedReportsSelector(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action>): IReportsActions {
  return {
    loadPbiReports: (assetType: string) => loadPbiReports(dispatch, assetType),
    loadLinkedReports: (assetId: string) => loadLinkedReports(dispatch, assetId)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
