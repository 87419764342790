// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React, { useMemo } from "react";
import { FormattedDate } from "react-intl";

import { IRow } from "common/datagrid/DataGrid";
import isClickable from "../utils/isClickable";
import IParameterInfo from "../../../models/IParameterInfo";

const ColumnLastUpdate = (lastUpdate: string, row: IRow<IParameterInfo>) => {
  const clickable = useMemo(() => isClickable(row), [row]);

  return (
    <div
      className={`column measurement-time ${clickable ? "" : "non-clickable"}`}
    >
      {lastUpdate && (
        <FormattedDate
          value={lastUpdate}
          day="numeric"
          month="numeric"
          year="numeric"
          hour="numeric"
          minute="numeric"
          second="numeric"
        />
      )}
    </div>
  );
};

export default ColumnLastUpdate;
