// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "thunk";

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { config } from "utils/AppConfig";
import IBushingOfflineDataResult from "../models/IBushingOfflineDataResult";

export enum Actions {
  DetailPage_BushingOffline_SetTransformerParameters = "DetailPage_BushingOffline_SetTransformerParameters",
}

export const setTransformerParametersAction = (
  request: JQuery.jqXHR,
  data?: IBushingOfflineDataResult[]
): IAjaxAction<IBushingOfflineDataResult[]> => ({
  request,
  data,
  type: Actions.DetailPage_BushingOffline_SetTransformerParameters
});

export const loadTransformerParametersAction = (
  assetId: string
): ThunkAction<Promise<void>, Action> => (dispatch: ThunkDispatch<Action>) =>
    loadOfflineBushingData(dispatch, assetId);

function loadOfflineBushingData(
  dispatch: ThunkDispatch<Action>,
  assetId: string
): Promise<void> {
  return new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.offlineBushingVisualizationUrl,
      [
        {
          name: "assetId",
          value: assetId
        }
      ]
    );

    const request = EndpointService.getJson<IBushingOfflineDataResult[]>(
      url,
      (request, data) => {
        dispatch(setTransformerParametersAction(request, data));
        resolve();
      },
      request => {
        dispatch(setTransformerParametersAction(request));
        reject();
      }
    );

    dispatch(setTransformerParametersAction(request));
  });
}
