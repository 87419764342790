// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { ConfigProvider } from "antd";
import { Locale } from "antd/lib/locale-provider";
import deDE from "antd/lib/locale/de_DE";
import enUS from "antd/lib/locale/en_US";
import esES from "antd/lib/locale/es_ES";
import jaJP from "antd/lib/locale/ja_JP";
import plPL from "antd/lib/locale/pl_PL";
import ptBR from "antd/lib/locale/pt_BR";
import zhCN from "antd/lib/locale/zh_CN";
import { ReactNode } from "react";
import { supportedLocales } from "../constants/supportedLocales";

export interface IAntdProviderData {
  locale: string;
}

export interface IAntdProviderProps extends IAntdProviderData {
  children?: ReactNode;
}

export const antdLocales: { [key: typeof supportedLocales[number]]: Locale } = {
  en: enUS,
  es: esES,
  pl: plPL,
  zh: zhCN,
  ja: jaJP,
  pt: ptBR,
  de: deDE
};

const AntdProvider = ({ children, locale }: IAntdProviderProps) => {
  return (
    <ConfigProvider locale={antdLocales[locale] ?? enUS}>
      {children}
    </ConfigProvider>
  );
};

export default AntdProvider;
