// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Translations, Value } from "@apm/widgets/build/components/Parameters";
import { Typography } from "antd";
import { isNil } from "lodash";
import { ReactNode, useMemo } from "react";
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  useIntl
} from "react-intl";

const { Text } = Typography;

const useParametersTranslations = () => {
  const intl = useIntl();

  const translations = useMemo(() => {
    const translations: Translations = {
      missingData: intl.formatMessage({
        defaultMessage: "This value may be incorrect due to missing data",
        id: "global.value.missing_data"
      }),
      disable: intl.formatMessage({
        defaultMessage: "DIS",
        id: "global.value.disable"
      }),
      undefined: intl.formatMessage({
        defaultMessage: "UND",
        id: "global.value.undef"
      }),
      caution: intl.formatMessage({
        defaultMessage: "CAUTION",
        id: "detailpage.parameter_info.caution"
      }),
      nameAndValue: (parameter: ReactNode, value: Value, unit: string) =>
        intl.formatMessage(
          {
            defaultMessage: "{parameter} - {value, number} {unit}",
            id: "detailpage.parameter_info.name_and_value"
          },
          {
            parameter,
            value,
            unit
          }
        ),
      parameter: (
        parameterName: string,
        columnName: JSX.Element | string | undefined
      ) =>
        intl.formatMessage(
          {
            defaultMessage:
              parameterName && columnName
                ? `{parameterName} / {columnName}`
                : parameterName
                ? `{parameterName}`
                : columnName
                ? `{columnName}`
                : null,
            id:
              parameterName && columnName
                ? "detailpage.parameter_info.parameter_column"
                : parameterName
                ? "detailpage.parameter_info.parameter"
                : columnName
                ? "detailpage.parameter_info.column"
                : undefined
          },
          {
            parameterName,
            columnName
          }
        ),
      problem: intl.formatMessage({
        defaultMessage: "PROBLEM",
        id: "detailpage.parameter_info.problem"
      }),
      initialyIdentified: intl.formatMessage({
        defaultMessage: "Initialy identified",
        id: "detailpage.parameter_info.initialy_identified"
      }),
      initialyIdentifiedValue: (value: Date) =>
        !isNil(value)
          ? intl.formatDate(value, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric"
            })
          : "-",
      issuerDetails: (date: ReactNode, user: ReactNode) =>
        intl.formatMessage(
          {
            defaultMessage: `{updateDate} {by} {nameAndSurname}`,
            id: "detailpage.parameter_info.issuer_details"
          },
          {
            updateDate: <Text>{date}</Text>,
            by: (
              <Text>
                <FormattedMessage
                  defaultMessage="by"
                  id="detailpage.parameter_info.by"
                />
              </Text>
            ),
            nameAndSurname: <Text strong>{user}</Text>
          }
        ),
      updateDate: (date: Date) =>
        !isNil(date) ? (
          <FormattedDate
            value={date}
            year="numeric"
            month="numeric"
            day="numeric"
            hour="numeric"
            minute="numeric"
            second="numeric"
          />
        ) : (
          "-"
        ),
      expectedRangeIncreased: intl.formatMessage({
        defaultMessage: "ExpectedMaxMult set to",
        id: "detailpage.parameter_info.range_increased"
      }),
      expectedRangeDecreased: intl.formatMessage({
        defaultMessage: "ExpectedMinMult set to",
        id: "detailpage.parameter_info.range_decreased"
      }),
      acknowledged: intl.formatMessage({
        defaultMessage: "Acknowledged",
        id: "detailpage.parameter_info.acknowledged"
      }),
      value: (value: string | undefined, unit: string) =>
        intl.formatMessage(
          {
            defaultMessage: "{value} {unit}",
            id: "detailpage.parameter_info.value"
          },
          { value, unit }
        ),
      adjustExpectedRange: intl.formatMessage({
        defaultMessage: "Adjust Expected Range",
        id: "detailpage.parameter_info.adjust_expected_range"
      }),
      acknowledge: intl.formatMessage({
        defaultMessage: "Acknowledge",
        id: "detailpage.parameter_info.aknowledge"
      }),
      or: intl.formatMessage({
        defaultMessage: "OR",
        id: "detailpage.parameter_info.or"
      }),
      range: (start: number | undefined, end: number | undefined) =>
        isNil(start)
          ? intl.formatMessage(
              {
                defaultMessage: "< {end}",
                id: "detailpage.parameter_info.range.end"
              },
              {
                end: <FormattedNumber value={end} />
              }
            )
          : isNil(end)
          ? intl.formatMessage(
              {
                defaultMessage: "≥ {start}",
                id: "detailpage.parameter_info.range.start"
              },
              {
                start: <FormattedNumber value={start} />
              }
            )
          : intl.formatMessage(
              {
                defaultMessage: "{start} - {end}",
                id: "detailpage.parameter_info.range"
              },
              {
                start: <FormattedNumber value={start} />,
                end: <FormattedNumber value={end} />
              }
            )
    };

    return translations;
  }, [intl]);

  return {
    translations
  };
};

export default useParametersTranslations;
