// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import { IState } from "reducers/Index";

import Data from "core/data/models/Data";
import { getDataKey } from "../actions/loadUltraHighFrequency";
import IPartialDischargeResult from "../models/IPartialDischargeResult";

const getUltraHighFrequencySelector = createSelector(
  (state: IState) => (assetId: string) => {
    return state.data[getDataKey(assetId)] as Data<IPartialDischargeResult>;
  },
  (ultraHighFrequency) => ultraHighFrequency
);

export default getUltraHighFrequencySelector;
