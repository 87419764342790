// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";

import BatteryVoltageContainer from "../containers/BatteryVoltageContainer";

interface IBatteryVoltageTabProps {
  assetId: string;
}

const BatteryVoltageTab: React.FC<IBatteryVoltageTabProps> = ({ assetId }) => (
  <BatteryVoltageContainer assetId={assetId} />
);

export default BatteryVoltageTab;
