// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";
import { useIntl } from "react-intl";

import OverlayComponent from "components/common/OverlayComponent";
import Data from "core/data/models/Data";
import RiskMatrixChart from "features/detailpage/features/riskmatrix/components/RiskMatrixChart";
import RiskMatrixToggleButtons from "features/detailpage/features/riskmatrix/components/RiskMatrixToggleButtons";

import IAssetDetails from "features/detailpage/models/IAssetDetails";

interface IRiskMatrixProps {
  overlay: boolean;
  assetId: string;
  assetDetails: Data<IAssetDetails>;
}

const RiskMatrix = ({ overlay, assetId, assetDetails }: IRiskMatrixProps) => {
  const intl = useIntl();

  return overlay ? (
    <OverlayComponent
      message={intl.formatMessage({
        id: "detail_page.widgets.risk_matrix_widget.empty_degradation_score",
        defaultMessage:
          "Oops.. my model didn’t calculate any Condition for me yet"
      })}
      element={
        <RiskMatrixComponent
          overlay={overlay}
          assetId={assetId}
          assetDetails={assetDetails}
        />
      }
      expanded={false}
    />
  ) : (
    <RiskMatrixComponent
      overlay={overlay}
      assetId={assetId}
      assetDetails={assetDetails}
    />
  );
};

interface IRiskMatrixComponentProps {
  assetId: string;
  assetDetails: Data<IAssetDetails>;
  overlay: boolean;
}

const RiskMatrixComponent = ({
  assetId,
  overlay,
  assetDetails
}: IRiskMatrixComponentProps) => (
  <div className={"risk-matrix-widget"}>
    <div className={"risk-matrix-container"}>
      <RiskMatrixToggleButtons
        disabled={overlay}
        assetNameplate={assetDetails?.data?.NameplateWithModelInfo}
      />
      <RiskMatrixChart
        assetId={assetId}
        assetDetails={assetDetails}
        grayscale={overlay}
      />
    </div>
  </div>
);

export default RiskMatrix;
