// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { FormattedNumber, FormattedMessage } from "react-intl";

interface ISubfactorsGridFooter {
  subfactorsCount: number;
}

const SubfactorsGridFooter = ({ subfactorsCount }: ISubfactorsGridFooter) => (
  <div className="footer">
    <div className="counter" data-qa="number-of-subfactors">
      <span>
        <FormattedMessage
          id="detail_page_widgets.subfactors_grid.subfactors"
          defaultMessage="Subfactors:"
        />
      </span>{" "}
    </div>
    <span className="count" data-qa="count">
      <FormattedNumber value={subfactorsCount} />
    </span>
  </div>
);

export default SubfactorsGridFooter;
