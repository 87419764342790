// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

import Label from "./Label";
import NumberValue from "./NumberValue";

import "./Messages.less";

interface IMessagesProps {
  messagesCount: number;
  hasAlertMessages: boolean;
  hasWarningMessages: boolean;
}

const Messages = ({
  hasAlertMessages,
  hasWarningMessages,
  messagesCount
}: IMessagesProps) => {
  return (
    <div
      className={`asset-detail-messages asset-ribbon-section${
        hasAlertMessages ? " asset-detail-messages-alert" : ""
      }${hasWarningMessages ? " asset-detail-messages-warning" : ""}`}
    >
      <div className="asset-ribbon-column">
        <div data-qa="header-messages-label">
          <Label>
            <FormattedMessage
              id="asset_detail_page.header.messages"
              defaultMessage="Messages"
            />
          </Label>
        </div>
        <div className="header-messages">
          <div data-qa="header-messages-count">
            <NumberValue
              {...(!hasAlertMessages && !hasWarningMessages
                ? { className: "blue-accent" }
                : {})}
            >
              <FormattedNumber value={messagesCount} />
            </NumberValue>
          </div>
          <div className="header-messages-value">
            {hasAlertMessages && <Icon name="warning" />}
            {!hasAlertMessages && hasWarningMessages && <Icon name="info" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
