// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as d3 from "d3";

export default class BarChartService {
  static addClassNameToBar = (
    chartContainerId: string,
    className: (i: number) => string
  ) => {
    const svgSelector = `${chartContainerId} svg`;
    const barsSelector =
      ".c3-chart .c3-chart-bars .c3-chart-bar .c3-bars .c3-bar";

    const svg = d3.select(svgSelector);
    const bars = svg.selectAll(barsSelector);

    bars.attr("class", function(datum: any, i: number) {
      const current = d3.select(this).attr("class");
      return `${current} ${className(i)}`;
    });
  };
}
