// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useState } from "react";

import { IConfigurationExtensions } from "common/chart/components/C3Chart";
import IVoltageData from "../models/IVoltageData";

const useChartExtensions = (voltageData: IVoltageData) => {
  return useState<IConfigurationExtensions>({
    data: {
      class: i => {
        return voltageData.Cells[i].IsPilotCell ? "pilot-cell" : "";
      }
    }
  });
};

export default useChartExtensions;
