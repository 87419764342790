// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Action, Dispatch } from "redux";

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { config } from "utils/AppConfig";
import IWorkOrder from "features/detailpage/models/IWorkOrder";

export const Actions = {
  DetailPage_Actions_SetWorkOrders: "DetailPage_Actions_SetWorkOrders"
};

export const setWorkOrdersAction = (
  request: JQuery.jqXHR,
  data?: IWorkOrder[]
): IAjaxAction<IWorkOrder[]> => {
  const action: IAjaxAction<IWorkOrder[]> = {
    type: Actions.DetailPage_Actions_SetWorkOrders,
    request,
    data
  };
  return action;
};

export const loadWorkOrders = (dispatch: Dispatch<Action>, assetId: string) =>
  new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.issues.workOrdersUrl,
      {
        assetId
      }
    );

    const request = EndpointService.getJson<IWorkOrder[]>(
      url,
      (request, data) => {
        dispatch(setWorkOrdersAction(request, data));
        resolve(data);
      },
      (request) => {
        dispatch(setWorkOrdersAction(request));
        reject(request);
      }
    );

    dispatch(setWorkOrdersAction(request));
  });
