// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import AssetImplementationId from "features/detailpage/models/AssetImplementationId";

const isKinectricsNetworkTransformerSiliconeModel = (
  implementationId: string
) => {
  return (
    implementationId?.toLowerCase() ===
    AssetImplementationId.KinectricsTransformerSiliconeIEEE
  );
};

export default isKinectricsNetworkTransformerSiliconeModel;
