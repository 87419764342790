// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Reducer } from "redux";

import Data from "core/data/models/Data";
import { Actions } from "features/issues/actions/IssuesActions";
import { IAjaxAction } from "reducers/Index";

export interface IAction {
  DueDate: string;
  ExternalLink: string;
  Status: string;
  Summary: string;
}

export interface IState {
  actions: Data<IAction[]>;
  closeIssueResult: Data<null>;
}

const issuesReducer: Reducer<IState> = (
  state = {
    actions: new Data(),
    closeIssueResult: new Data()
  },
  action: IAjaxAction<IAction[]>
) => {
  switch (action.type) {
    case Actions.Issues_SetActions:
      return Object.assign({}, state, {
        actions: new Data(action.request, action.data)
      } as IState);
    case Actions.Issues_SetCloseIssueResult:
      return Object.assign({}, state, {
        closeIssueResult: new Data(action.request, null)
      } as IState);
    default:
      return state;
  }
};

export default issuesReducer;
