// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import { useState, useCallback } from "react";
import { useHistory } from "react-router";
import { isEmpty } from "lodash";

import { IRow } from "common/datagrid/DataGrid";
import isClickable from "../utils/isClickable";
import IParameterInfo from "../../../models/IParameterInfo";

import Guid from "core/guid/Guid";

const searchValueQueryKey = "p_search";

const useParametersGridSucceeded = (onSelectionCleared?: () => void) => {
  const history = useHistory();
  const [search, setSearch] = useState<string>(
    ((search: string) => {
      return new SearchParams(search).get(searchValueQueryKey) || "";
    })(history.location.search)
  );
  const [gridKey, setGridKey] = useState<string>(Guid.getUniqGuid());

  const filterRows = useCallback(
    (p: IRow<IParameterInfo>) => {
      if (!search) return true;
      if (
        p.data.TranslatedName?.toLowerCase().indexOf(search.toLowerCase()) >= 0
      )
        return true;
      if (p.data.Unit?.toLowerCase().indexOf(search.toLowerCase()) >= 0)
        return true;
      if (
        p.data.FactorsKeywords?.toLowerCase().indexOf(search.toLowerCase()) >= 0
      )
        return true;
      return false;
    },
    [search]
  );

  const handleClear = useCallback(() => {
    if (onSelectionCleared) onSelectionCleared();
    setGridKey(Guid.getUniqGuid());
  }, [onSelectionCleared]);

  const handleSelectableRow = useCallback(
    (row: IRow<IParameterInfo>) => isClickable(row),
    []
  );

  const handleSearch = useCallback(
    (value: string) => {
      const searchParams = new SearchParams(history.location.search);
      if (!isEmpty(value)) {
        searchParams.set(searchValueQueryKey, value);
      } else {
        searchParams.delete(searchValueQueryKey);
      }
      history.replace({
        search: searchParams.toString()
      });
      setSearch(value);
    },
    [history]
  );

  return {
    search,
    filterRows,
    handleSearch,
    handleSelectableRow,
    handleClear,
    gridKey
  };
};

export default useParametersGridSucceeded;
