// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import IParameterValue from "@apm/widgets/build/components/Parameters/models/IParameterValue";
import getMatchedThreshold from "@apm/widgets/build/components/Parameters/utils/getMatchedThreshold";
import { IntlShape } from "react-intl";
import mapToParameterOptions from "../../../utils/mapToParameterOptions";
import mapToThreshold from "../../../utils/mapToThreshold";
import mapToThresholdCorrection from "../../../utils/mapToThresholdCorrection";
import IMechanicalPoleParameterValue from "../models/IMechanicalPoleParameterValue";

const dotValueParameterNames = [
  "MonitorCoilClose",
  "MonitorCoilPrimaryTrip",
  "MonitorCoilSecondaryTrip"
];

const lastOperationParameterName = "LastOperation";

const mapToParameterValue = (
  name: string,
  value: IMechanicalPoleParameterValue,
  intl: IntlShape
): IParameterValue => {
  const isDotValue = dotValueParameterNames.some((x) => x === name);
  const isDateValue = name === lastOperationParameterName;

  const options = mapToParameterOptions(value.Options);
  const thresholds = value.Thresholds?.map(mapToThreshold);

  if (isDotValue) {
    const threshold = getMatchedThreshold(value.Value as number, thresholds);
    const parameterValue: IParameterValue = {
      options,
      value: {
        type: "dot",
        value: threshold?.state.toLowerCase() as
          | "problem"
          | "caution"
          | "normal"
      },
      externalId: value.ExternalId
    };

    return parameterValue;
  } else if (isDateValue) {
    const date = value.Value
      ? intl.formatDate(value.Value, {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric"
        })
      : null;

    const parameterValue: IParameterValue = {
      options,
      value: date,
      externalId: value.ExternalId
    };

    return parameterValue;
  } else {
    const parameterValue: IParameterValue = {
      options,
      thresholds,
      thresholdCorrection: mapToThresholdCorrection(
        value.UserThresholdCorrection
      ),
      value: value.Value,
      valueDate: new Date(value.ParameterDate),
      externalId: value.ExternalId
    };

    return parameterValue;
  }
};

export default mapToParameterValue;
