// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import { Typography } from "antd";
import { memo, useCallback } from "react";
import { matchPath, useLocation, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { spacingBase, spacingMedium } from "styles/StyleVariables";
import IRoutableTab from "../models/IRoutableTab";

import {
  colorContentAreaLight,
  colorGray10,
  colorPrimary
} from "styles/ColorVariables";

const { Text } = Typography;

interface IHeaderPropsRouterProps {
  url: string;
  pathname: string;
}

interface IHeaderPropsOwnProps {
  className?: string;
  tabs: IRoutableTab[];
  noBorder?: boolean;
  searchParams?: SearchParams;
}

type HeaderProps = IHeaderPropsRouterProps & IHeaderPropsOwnProps;

interface IHeaderBodyProps {
  id: string;
  name: string;
  HeaderContent?: () => JSX.Element;
  disabled?: boolean;
  active?: boolean;
}

const HeaderBody = ({
  id,
  name,
  HeaderContent,
  disabled,
  active
}: IHeaderBodyProps) => {
  return (
    <div
      key={id}
      className={[
        "tab-routable-header",
        active ? "header-active" : "",
        disabled ? "header-disabled" : ""
      ]
        .filter(Boolean)
        .join(" ")}
    >
      {HeaderContent && <HeaderContent />}
      {name && !HeaderContent && (
        <Text className="tab-header-label" strong={true}>
          {name}
        </Text>
      )}
    </div>
  );
};

const Header = memo(
  ({ className, tabs, url, pathname, searchParams }: HeaderProps) => {
    const isActivePath = useCallback(
      (id: string) => {
        const matched = matchPath(pathname, `${url}/${id}`);
        return matched !== null;
      },
      [pathname, url]
    );

    return (
      <div className={`${className} tabs-routable-header`}>
        {tabs?.map(({ id, disabled, name, noLink, HeaderContent }) =>
          noLink || disabled ? (
            <HeaderBody
              key={id}
              id={id}
              name={name}
              HeaderContent={HeaderContent}
              disabled={disabled}
              active={isActivePath(id)}
            />
          ) : (
            <Link
              key={id}
              to={{
                pathname: `${url}/${id}`,
                search: searchParams?.toString()
              }}
            >
              <HeaderBody
                id={id}
                name={name}
                HeaderContent={HeaderContent}
                disabled={disabled}
                active={isActivePath(id)}
              />
            </Link>
          )
        )}
      </div>
    );
  }
);

const StyledHeader = styled(Header)`
  display: flex;

  .tab-routable-header {
    text-align: center;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${spacingMedium} ${spacingBase} ${spacingMedium} ${spacingBase};
    border-left-style: solid;
    border-left-color: transparent;
    border-right-style: solid;
    border-right-color: transparent;
    border-left-width: ${({ noBorder }) => (noBorder ? "0px" : "0.5px")};
    border-right-width: ${({ noBorder }) => (noBorder ? "0px" : "0.5px")};

    &.header-active:not(.header-disabled),
    &:hover:not(.header-disabled) {
      box-shadow: inset 0px 8px 0px -5px ${colorPrimary};
      border-left-color: ${({ noBorder }) =>
        noBorder ? "transparent" : colorGray10};
      border-right-color: ${({ noBorder }) =>
        noBorder ? "transparent" : colorGray10};
    }

    &.header-active:not(.header-disabled) {
      background-color: ${colorContentAreaLight};
    }

    &.header-disabled {
      opacity: 0.5;
    }

    .tab-header-label {
      font-size: 9pt;
    }
  }
`;

export default (props: IHeaderPropsOwnProps) => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  return <StyledHeader {...props} url={url} pathname={pathname} />;
};
