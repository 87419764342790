// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import DataUpload, { ITemplate } from "@apm/widgets/build/widgets/DataUpload";
import { notifications } from "@pg/common/build/components/Notifications";
import Processing from "components/common/Processing";
import Data, { Statuses } from "core/data/models/Data";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { ThunkDispatch } from "thunk";
import { config } from "utils/AppConfig";
import loadTemplates from "../actions/loadTemplates";
import removeTemplates from "../actions/removeTemplates";
import { ITemplateInfo } from "../models/ITemplate";
import getTemplatesSelector from "../selectors/getTemplatesSelector";

interface IDataConfigurationActions {
  loadTemplates: () => void;
  removeTemplates: () => void;
}

interface IDataConfigurationData {
  templates: Data<ITemplateInfo[]>;
}

interface IDataConfigurationProps
  extends IDataConfigurationActions,
    IDataConfigurationData {}

const DataConfiguration = (props: IDataConfigurationProps) => {
  const intl = useIntl();

  useEffect(() => {
    props.loadTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const templates: ITemplate[] = useMemo(
    () =>
      props.templates?.data?.map((t) => ({
        name: t.Name,
        filename: t.FileName
      })),
    [props.templates]
  );

  const url = useMemo(
    () => UrlService.getApiUrl(config.api.settings.data.xlsxDataImportUrl),
    []
  );

  const status = useMemo(() => {
    return props.templates?.status === Statuses.Loading
      ? "loading"
      : props.templates?.status === Statuses.Failed
      ? "failed"
      : props.templates?.status === Statuses.Succeeded
      ? "succeeded"
      : null;
  }, [props.templates]);

  return (
    <DataUpload
      templatesStatus={status}
      templates={templates}
      translations={{
        templates: intl.formatMessage({
          defaultMessage: "Templates",
          id: "settings_main.data_configuration.templates"
        }),
        templateName: (name: string) =>
          intl.formatMessage({
            defaultMessage: name,
            id: `settings_main.data_configuration.templates.${name.toLowerCase()}`
          }),
        downloadAll: intl.formatMessage({
          defaultMessage: "Download all templates",
          id: "settings_main.data_configuration.download_all_templates"
        }),
        dragAndDrop: intl.formatMessage({
          defaultMessage: "Drag and drop data",
          id: "settings_main.data_configuration.drag_and_drop_message"
        }),
        or: intl.formatMessage({
          defaultMessage: "OR",
          id: "settings_main.data_configuration.or"
        }),
        clickHere: intl.formatMessage({
          defaultMessage: "Click here to upload it",
          id: "settings_main.data_configuration.click_here"
        }),
        failed: props.templates?.message
      }}
      downloadTemplate={(name: string) => {
        saveAs(
          UrlService.getApiUrl(config.api.settings.data.template, {
            name
          })
        );
      }}
      upload={(
        file: File,
        fileName: string,
        onSuccess: (body: Object) => void,
        onError: (event: Error, body?: Object) => void,
        onProgress: (event: { percent: number }) => void
      ) => {
        const reader: any = new FileReader();

        reader.onload = function (e: Event) {
          const base64FileData = (e.target as any).result
            ? (e.target as any).result.split("base64,")[1]
            : "";

          const dataToSend = {
            FileByteData: base64FileData,
            FileName: file.name
          };

          EndpointService.put(
            url,
            () => onSuccess({ dataToSend }),
            (request) => {
              onError({
                name: request.responseText,
                message: ""
              });
              if (request.status === 400) {
                const response = request.responseJSON.XLSX_PARSER;
                const errorMessage = response ? response[0] : "";
                let assetId = "";

                if (response && response.length >= 2) assetId = response[1];

                notifications.error({
                  message: intl.formatMessage(
                    {
                      defaultMessage: "Uploading {name} failed.",
                      id: `settings_main.data_configuration.file_upload_error.${errorMessage}`
                    },
                    {
                      name: file.name,
                      assetId: assetId
                    }
                  )
                });
              } else
                notifications.error({
                  message: intl.formatMessage(
                    {
                      defaultMessage: "Uploading {name} failed.",
                      id: `settings_main.data_configuration.file_upload_error`
                    },
                    {
                      name: file.name
                    }
                  )
                });
            },
            (percent) => onProgress({ percent }),
            dataToSend,
            "application/json; charset=UTF-8"
          );
        };

        reader.readAsDataURL(file);
      }}
      customProcessing={<Processing className="spinner small light" />}
    />
  );
};

const mapStateToProps = (state: IState): IDataConfigurationData => ({
  templates: getTemplatesSelector(state)
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any>
): IDataConfigurationActions => {
  return {
    loadTemplates: () => dispatch(loadTemplates()),
    removeTemplates: () => dispatch(removeTemplates())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataConfiguration);
