// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";

import "components/common/OverlayComponent.less";

interface IOverlayComponentProps {
  message: string;
  element: JSX.Element;
  expanded: boolean;
}

class OverlayComponent extends React.Component<IOverlayComponentProps, {}> {
  public render(): JSX.Element {
    return (
      <div className="overlay-component">
        <div className="error-message">
          <div
            className={this.props.expanded ? "message-content-expanded" : null}
          >
            {this.props.message}
          </div>
          <div
            className={this.props.expanded ? "message-empty-expanded" : null}
          />
        </div>

        <div className="content">{this.props.element}</div>
      </div>
    );
  }
}

export default OverlayComponent;
