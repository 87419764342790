// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { kebabCase } from "lodash";
import { FormattedMessage } from "react-intl";

import { IAgingOperationsChartLegendItem } from "features/detailpage/features/aging/models/IAgingOperationsChartLegendItem";
import { Statuses } from "common/ChartLegend/hooks/useLegend";

interface IAgingOperationsBarChartLegendItemProps {
  item: IAgingOperationsChartLegendItem;
  status: Statuses;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}

const AgingOperationsBarChartLegendItem = ({
  item,
  status,
  onClick,
  onMouseOver,
  onMouseOut
}: IAgingOperationsBarChartLegendItemProps) => {
  return (
    <span
      className={`aging-operations chart-legend__item ${Statuses[status] &&
        Statuses[status].toLowerCase()}`}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <div
        className="aging-operations chart-legend__item-box"
        style={{ backgroundColor: item.color }}
      >
        {item.isGridLine && <div className="aging-legend-grid-line" />}
      </div>
      <span className="aging-operations chart-legend__item-text">
        <FormattedMessage
          defaultMessage={item.name}
          id={`detail_page.widgets.circuit_breaker.aging.operations_chart_legend.${kebabCase(
            item.name
          )}`}
        />
      </span>
    </span>
  );
};

export default AgingOperationsBarChartLegendItem;
