// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { FormattedNumber } from "react-intl";

export interface IValueColumnProps extends React.Props<IValueColumnProps> {
  value: number;
}

const ValueColumn = ({ value, children }: IValueColumnProps) => (
  <div className="grid-cell value">
    {value && (
      <FormattedNumber
        value={value}
        minimumFractionDigits={1}
        maximumFractionDigits={3}
      />
    )}
    {children}
  </div>
);

export default ValueColumn;
