// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import {
  colorGray80,
  colorStatusRed,
  colorTeal100
} from "@pg/common/build/styles/ColorVariables";
import { DatePicker, Form, Tabs } from "antd";
import { TypedValue } from "features/detailpage/models/IParameter";
import { isNil } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { spacingLarge, spacingXLarge } from "styles/StyleVariables";
import AssetModalContext from "../contexts/AssetModalContext";
import FormContext from "../contexts/FormContext";
import { IFormItem } from "../hooks/useForms";
import IFieldData from "../models/IFieldData";
import IFormValues from "../models/IFormValues";
import { ITabInfo } from "../models/ITabInfo";
import { dateFormat, disableFutureDates } from "../utils/datePicker";
import CustomSections from "./CustomSections";

const { TabPane } = Tabs;

interface ITabbedInspectionProps {
  className?: string;
  formInfo: IFormItem;
}

const TabbedInspection = ({ className, formInfo }: ITabbedInspectionProps) => {
  const {
    validateMessages,
    registerForm,
    changeInspectionField,
    changeInspectionDateForFields,
    changeTabsInfo
  } = useContext(AssetModalContext);

  const intl = useIntl();
  const form = Form.useForm<IFormValues>(formInfo?.form)[0];

  const tabs: ITabInfo[] = useMemo(
    () =>
      formInfo.formConfiguration.subTabs.map((t) => ({
        tabId: t.tabName.id.toString(),
        invalid: false,
        touched: false
      })),
    [formInfo.formConfiguration.subTabs]
  );

  const dataTypes: { [name: string]: TypedValue } = useMemo(() => {
    const fieldsConfig: { [name: string]: TypedValue } = {};
    formInfo.formConfiguration.subTabs.forEach((subTab) => {
      subTab.sections.forEach((sectionElement) => {
        if (sectionElement.fields)
          sectionElement.fields.forEach((field) => {
            fieldsConfig[field.fieldKey] = field.dataType;
          });
        if (sectionElement.sectionList)
          sectionElement.sectionList.forEach((section) => {
            section.fields.forEach((field) => {
              fieldsConfig[field.fieldKey] = field.dataType;
            });
          });
      });
    });

    return fieldsConfig;
  }, [formInfo.formConfiguration]);

  useEffect(() => {
    registerForm(formInfo.formConfiguration.formKey, form, tabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const [activeTab, setActiveTab] = useState(
    formInfo.formConfiguration.subTabs[0].tabName.id.toString()
  );

  useEffect(() => {
    if (formInfo.invalid) {
      form.validateFields();
    }
  }, [formInfo.invalid, form, activeTab]);

  const handleTabChange = useCallback((activeKey: string) => {
    setActiveTab(activeKey);
  }, []);

  const inspectionDateKey = useMemo(() => {
    return `DateOfInspection_${formInfo.formConfiguration.formKey}`;
  }, [formInfo.formConfiguration.formKey]);

  const handleFieldsChange = useCallback(
    (changedFields: IFieldData[], allFields: IFieldData[]) => {
      const fieldsWithoutInspectionDateKey = allFields.filter(
        ({ name }) => name[0] !== inspectionDateKey
      );

      const isTouched = fieldsWithoutInspectionDateKey.some(
        (field) => field.touched
      );

      if (isTouched) {
        const isError = fieldsWithoutInspectionDateKey.some(
          (field) => field.errors?.length > 0
        );
        const tabInfo: ITabInfo[] = [
          {
            tabId: activeTab,
            invalid: isError,
            touched: isTouched
          }
        ];

        changeTabsInfo(formInfo.formConfiguration.formKey, tabInfo);
      }

      const dateOfInspection = allFields.find(
        (f) => f.name[0] === inspectionDateKey
      );

      changedFields.forEach((field) => {
        if (field.touched) {
          if (field.name[0] === inspectionDateKey) {
            const allFieldsNames = Object.keys(dataTypes);
            changeInspectionDateForFields(field.value as Date, allFieldsNames);
          } else {
            changeInspectionField(
              field.name[0],
              dataTypes[field.name[0]] === "Bool" && !isNil(field.value)
                ? field.value === "true"
                : field.value,
              dataTypes[field.name[0]],
              dateOfInspection?.value as Date
            );
          }
        }
      });
    },
    [
      changeTabsInfo,
      changeInspectionDateForFields,
      changeInspectionField,
      formInfo.formConfiguration.formKey,
      inspectionDateKey,
      activeTab,
      dataTypes
    ]
  );

  const activeTabSections = useMemo(
    () => (
      <CustomSections
        configuration={
          formInfo.formConfiguration.subTabs.find(
            (t) => t.tabName.id === activeTab
          ).sections
        }
      />
    ),
    [activeTab, formInfo.formConfiguration]
  );

  const formatDataQa = (name: string) => {
    return name.toLocaleLowerCase().replace(/\s/g, "-");
  };

  const subTabs = useMemo(
    () => (
      <Tabs
        defaultActiveKey={formInfo.formConfiguration.subTabs[0].tabName.id.toString()}
        onChange={(key: string) => handleTabChange(key)}
      >
        {formInfo.formConfiguration.subTabs.map((t) => (
          <TabPane
            tab={
              <span
                data-qa={`${formatDataQa(
                  t.tabName.defaultMessage.toString()
                )}-tab`}
              >
                {intl.formatMessage({ ...t.tabName })}
                {formInfo?.tabs?.find((tab) => tab.tabId === t.tabName.id)
                  ?.invalid ? (
                  <Icon className="tab-invalid-icon" name="circle" size="xs" />
                ) : formInfo?.tabs?.find((tab) => tab.tabId === t.tabName.id)
                    ?.touched ? (
                  <Icon className="tab-touched-icon" name="circle" size="xs" />
                ) : (
                  ""
                )}
              </span>
            }
            key={t.tabName.id}
          >
            {t.tabName.id === activeTab ? activeTabSections : null}
          </TabPane>
        ))}
      </Tabs>
    ),
    [
      formInfo.formConfiguration.subTabs,
      formInfo?.tabs,
      handleTabChange,
      activeTabSections,
      intl,
      activeTab
    ]
  );

  return (
    <div className={className}>
      <Form
        form={form}
        layout="horizontal"
        colon={false}
        requiredMark={false}
        validateMessages={validateMessages}
        onFieldsChange={handleFieldsChange}
        key={formInfo.formConfiguration.formKey}
      >
        <FormContext.Provider value={form}>
          <Form.Item
            label={intl.formatMessage({
              id: "configuration_tool.label.date_of_inspection",
              defaultMessage: "Date of inspection"
            })}
            className="date-of-inspection"
            name={inspectionDateKey}
            rules={[{ required: true }]}
          >
            <DatePicker
              disabledDate={disableFutureDates}
              format={dateFormat(intl)}
              data-qa="InspectionDate"
            />
          </Form.Item>
          {subTabs}
        </FormContext.Provider>
      </Form>
    </div>
  );
};
const StyledTabbedInspection = styled(TabbedInspection)`
  .tab-touched-icon {
    margin-left: ${spacingXLarge};
    color: ${colorTeal100};
  }

  .tab-invalid-icon {
    margin: auto ${spacingLarge};
    color: ${colorStatusRed};
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      span {
        color: ${colorGray80};
      }

      .tab-touched-icon {
        margin-left: ${spacingXLarge};
        color: ${colorTeal100};
      }

      .tab-invalid-icon {
        margin: auto ${spacingLarge};
        color: ${colorStatusRed};
      }
    }
  }
`;

export default StyledTabbedInspection;
