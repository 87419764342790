// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Modal from "common/Modal";
import styled from "styled-components";
import AssetModalContextProvider from "../providers/AssetModalContextProvider";
import AssetModalContent from "./AssetModalContent";

interface IAssetModalProps {
  assetId?: string;
  visible: boolean;
  onCancel?: () => void;
  className?: string;
}

const AssetModal = ({
  assetId,
  visible,
  onCancel,
  className
}: IAssetModalProps) => {
  return (
    <Modal
      centered
      width="95%"
      bodyStyle={{
        height: "95vh"
      }}
      visible={visible}
      footer={null}
      destroyOnClose={true}
      onCancel={onCancel}
      className={className}
      data-qa="AssetModal"
    >
      <AssetModalContextProvider initialAssetId={assetId}>
        <AssetModalContent onCancel={onCancel} />
      </AssetModalContextProvider>
    </Modal>
  );
};

const StyledAssetModal = styled(AssetModal)`
  padding-bottom: 0px;

  .ant-modal-body {
    display: flex;
    flex-direction: column;
  }
`;

export default StyledAssetModal;
