// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import * as React from "react";
import { Link } from "react-router-dom";

import { routesConfig } from "core/app/components/router/RouterProvider";

import "./SlidePanelTitle.less";

export interface ISlidePanelTitleProps {
  assetId: string;
  assetName: string;
}

const SlidePanelTitle = ({ assetId }: ISlidePanelTitleProps) => {
  const searchParams = new SearchParams({ assetId });

  return (
    <Link
      className="slide-panel-title bold"
      to={{
        pathname: routesConfig.detailPage,
        search: searchParams.toString()
      }}
      data-qa="asset-header"
    >
      {assetId}
    </Link>
  );
};

export default SlidePanelTitle;
