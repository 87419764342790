// Copyright 2016-2021 Hitachi Power Grids. All rights reserved.
import { Select } from "antd";
import Form from "antd/lib/form";
import boolValues from "features/ConfigurationTool/constants/boolValues";
import IField from "features/ConfigurationTool/models/IField";
import { Moment } from "moment";
import React, { useEffect, useMemo } from "react";

const { Option } = Select;

interface ISelectFieldProps {
  configuration: IField;
  toggleEdit: () => void;
  getValidationErrors: (fieldKey: string) => Promise<void>;
  getInitialValue?: (
    fieldName: string
  ) => string | number | boolean | Date | Moment;
}

const SelectField = ({
  configuration,
  toggleEdit,
  getValidationErrors,
  getInitialValue
}: ISelectFieldProps) => {
  const ref = React.createRef<HTMLInputElement>();

  useEffect(() => {
    ref.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listValues = useMemo(
    () =>
      configuration.inputType === "Bool"
        ? boolValues
        : configuration.listValues,
    [configuration.inputType, configuration.listValues]
  );

  return (
    <Form.Item
      name={configuration.fieldKey}
      rules={configuration.rules}
      initialValue={
        getInitialValue ? getInitialValue(configuration.fieldKey) : null
      }
    >
      <Select
        ref={ref}
        onBlur={toggleEdit}
        onChange={() => getValidationErrors(configuration.fieldKey)}
        showSearch
        allowClear
        defaultOpen
      >
        {Array.isArray(listValues)
          ? listValues.map((value) => (
              <Option key={value.toString()} value={value.toString()}>
                {value.toString()}
              </Option>
            ))
          : Object.keys(listValues).map((value) => (
              <Option key={value} value={value}>
                {listValues[value]}
              </Option>
            ))}
      </Select>
    </Form.Item>
  );
};

export default SelectField;
