// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { IBasicFilter } from "powerbi-models";
import styled from "styled-components";
import { Typography } from "antd";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Reports from "features/detailpage/features/reports/components/Reports";
import { ReportsModes } from "features/detailpage/features/reports/models/ReportsModes";
import IReport from "features/detailpage/models/IReport";

import getPbiReportsSelector from "features/detailpage/features/reports/selectors/getPbiReportsSelector";
import { IState } from "reducers/Index";
import Data from "core/data/models/Data";

const { Text } = Typography;

const Header = ({ className }: { className?: string }) => (
  <Text className={className} strong={true}>
    <FormattedMessage
      id="detail_page.widgets.embedded_reports"
      defaultMessage="Embedded reports"
    />
  </Text>
);

const StyledHeader = styled(Header)`
  font-size: 9pt;
`;

interface IReportsHeaderTabDataProps {
  pbiReports: Data<IReport[]>;
}

interface IReportsHeaderTabOwnProps {
  assetId: string;
  assetType: string;
}

type ReportsHeaderTabProps = IReportsHeaderTabOwnProps &
  RouteComponentProps &
  IReportsHeaderTabDataProps;

const ReportsHeaderTab = ({
  assetId,
  assetType,
  history,
  match: { path },
  pbiReports
}: ReportsHeaderTabProps) => {
  const handlePbiReportsClick = useCallback(
    (report: IReport, filters?: IBasicFilter[]) => {
      const searchParams = new SearchParams();
      searchParams.set("assetId", assetId);
      searchParams.set("pbi_n", report.Name);
      searchParams.set("pbi_f", JSON.stringify(filters));
      history.push({
        pathname: `${path}/EmbeddedReports`,
        search: searchParams.toString()
      });
    },
    [assetId, history, path]
  );

  return (
    <Reports
      Header={() => <StyledHeader />}
      reportsMode={ReportsModes.EmbeddedReports}
      assetId={assetId}
      assetType={assetType}
      pbiReports={pbiReports}
      emptyReportsTranslationId="detail_page.widgets.embedded_reports.no_reports"
      emptyReportsDefaultText="No reports"
      onPbiReportClicked={handlePbiReportsClick}
    />
  );
};

const mapStateToProps = (state: IState): IReportsHeaderTabDataProps => ({
  pbiReports: getPbiReportsSelector(state)
});

export default connect(mapStateToProps, null)(withRouter(ReportsHeaderTab));
