// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { loadRiskMatrixConfiguration } from "features/detailpage/features/riskmatrix/actions/RiskMatrixActions";

const useLoadRiskMatrixConfiguration = () => {
  const dispatch = useDispatch();
  return useCallback(() => loadRiskMatrixConfiguration(dispatch), [dispatch]);
};

export default useLoadRiskMatrixConfiguration;
