// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";

import DataGrid, {
  IColumnConfig,
  IDataEndpoint
} from "common/datagrid/DataGrid";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import { FormattedNumber, useIntl } from "react-intl";

import "./ModelConfigurationGrid.less";

export interface IModelConfigurationGridProps {
  modelId: string;
  modelImplementationId: string;
  configurationName: string;
}

const NumberColumn = ({ value }: { value: number }) => (
  <div className="grid-cell-content numericValue">
    <FormattedNumber value={value} />
  </div>
);

const TranslatedColumn = ({
  value,
  messageType,
  modelPrefix,
  implementationPrefix 
}: {
  value: string;
  messageType: string;
  modelPrefix: string;
  implementationPrefix: string;
}) => {
  const intl = useIntl();
  var formattedMessage = intl.formatMessage({
    id: `${modelPrefix}.${messageType}.${value}`,
    defaultMessage: intl.formatMessage({
      id: `${implementationPrefix}.${messageType}.${value}`,
      defaultMessage: value
    })
  });
  return <div className="grid-cell-content textValue">
    <span>{formattedMessage}</span>
  </div>
};

class ModelConfigurationGrid extends React.Component<
  IModelConfigurationGridProps
> {

  getColumns(modelId: string, implementationId: string) : IColumnConfig[] {
    return [
      {
        component: (value) => (
          <TranslatedColumn
            value={value}
            messageType="subscore"
            modelPrefix={modelId}
            implementationPrefix={implementationId}
          />
        ),
        frozen: true,
        id: "Subsystem",
        message: {
          defaultMessage: "Subsystem",
          id: "detail_page.model_configuration.grid.columns.subsystem"
        },
        weight: 2,
        sortable: false
      },
      {
        component: (value) => (
          <TranslatedColumn
            value={value}
            messageType="parameter"
            modelPrefix={modelId}
            implementationPrefix={implementationId}
          />
        ),
        frozen: true,
        id: "Condition",
        message: {
          defaultMessage: "Parameter",
          id: "detail_page.model_configuration.grid.columns.condition"
        },
        weight: 4,
        sortable: false
      },
      {
        id: "Unit",
        message: {
          defaultMessage: "Unit of Measurement",
          id: "detail_page.model_configuration.grid.columns.unit"
        },
        weight: 2,
        sortable: false
      },
      {
        component: (value) => <NumberColumn value={value} />,
        id: "Importance",
        message: {
          defaultMessage: "Parameter Importance",
          id: "detail_page.model_configuration.grid.columns.importance"
        },
        weight: 1.8,
        sortable: false
      },
      {
        component: (value) => <NumberColumn value={value} />,
        id: "CalculationType",
        message: {
          defaultMessage: "Calculation Type",
          id: "detail_page.model_configuration.grid.columns.calculation_type"
        },
        weight: 1.8,
        sortable: false
      },
      {
        component: (value) => <NumberColumn value={value} />,
        id: "LowLimit",
        message: {
          defaultMessage: "Low Limit",
          id: "detail_page.model_configuration.grid.columns.low_limit"
        },
        weight: 1.5,
        sortable: false
      },
      {
        component: (value) => <NumberColumn value={value} />,
        id: "HighLimit",
        message: {
          defaultMessage: "High Limit",
          id: "detail_page.model_configuration.grid.columns.high_limit"
        },
        weight: 1.5,
        sortable: false
      },
      {
        component: (value) => <NumberColumn value={value} />,
        id: "RelativeValue",
        message: {
          defaultMessage: "Relative Value",
          id: "detail_page.model_configuration.grid.columns.relative_value"
        },
        weight: 1.5,
        sortable: false
      },
      {
        component: (value) => <NumberColumn value={value} />,
        id: "BreakPoint0",
        message: {
          defaultMessage: "Break Point 0",
          id: "detail_page.model_configuration.grid.columns.break_point0"
        },
        weight: 1.5,
        sortable: false
      },
      {
        component: (value) => <NumberColumn value={value} />,
        id: "BreakPoint1",
        message: {
          defaultMessage: "Break Point 1",
          id: "detail_page.model_configuration.grid.columns.break_point1"
        },
        weight: 1.5,
        sortable: false
      },
      {
        component: (value) => <NumberColumn value={value} />,
        id: "BreakPoint2",
        message: {
          defaultMessage: "Break Point 2",
          id: "detail_page.model_configuration.grid.columns.break_point2"
        },
        weight: 1.5,
        sortable: false
      },
      {
        component: (value) => <NumberColumn value={value} />,
        id: "BreakPoint3",
        message: {
          defaultMessage: "Break Point 3",
          id: "detail_page.model_configuration.grid.columns.break_point3"
        },
        weight: 1.5,
        sortable: false
      },
      {
        component: (value) => <NumberColumn value={value} />,
        id: "BreakPoint4",
        message: {
          defaultMessage: "Break Point 4",
          id: "detail_page.model_configuration.grid.columns.break_point4"
        },
        weight: 1.5,
        sortable: false
      },
      {
        component: (value) => <NumberColumn value={value} />,
        id: "AgeScale",
        message: {
          defaultMessage: "Age Scale",
          id: "detail_page.model_configuration.grid.columns.age_scale"
        },
        weight: 1.5,
        sortable: false
      },
      {
        component: (value) => <NumberColumn value={value} />,
        id: "AgeStandard",
        message: {
          defaultMessage: "Age Standard",
          id: "detail_page.model_configuration.grid.columns.age_standard"
        },
        weight: 1.5,
        sortable: false
      },
      {
        component: (value) => <NumberColumn value={value} />,
        id: "AgeMax",
        message: {
          defaultMessage: "Age Max",
          id: "detail_page.model_configuration.grid.columns.age_max"
        },
        weight: 1.5,
        sortable: false
      }
    ];
  }

  render() {
    const { modelId, modelImplementationId, configurationName } = this.props;
    const columns = this.getColumns(modelId, modelImplementationId);

    const dataEndpoint: IDataEndpoint = {
      url: UrlService.getApiUrl(config.api.detailPage.modelConfigurationUrl, [
        {
          name: "modelId",
          value: modelId
        },
        {
          name: "configurationName",
          value: configurationName
        }
      ]),
      type: "GET"
    };

    return (
      <div className="model-configuration-grid-container data-grid__parent grid-container ">
        <div className="model-configuration-grid-scroll data-grid__scroll">
          <DataGrid
            columns={columns}
            dataEndpoint={dataEndpoint}
            multiColumnSorting={false}
            showFooter={false}
          />
        </div>
      </div>
    );
  }
}

export default ModelConfigurationGrid;
