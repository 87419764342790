// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";

import "./Manufacturer.less";
import TooltipWrapper, { Themes } from "common/tooltipwrapper/TooltipWrapper";

interface IManufacturerProps {
  manufacturer: string | undefined;
  model: string | undefined;
}

const Manufacturer = ({ manufacturer, model }: IManufacturerProps) => (
  <div className="column manufacturer">
    <TooltipWrapper
      Tooltip={() => <div>{manufacturer}</div>}
      theme={Themes.Dark}
    >
      <div className="manufacturer-name default-grey-label">{manufacturer}</div>
    </TooltipWrapper>
    <TooltipWrapper Tooltip={() => <div>{model}</div>} theme={Themes.Dark}>
      <div className="model" title={model}>
        {model}
      </div>
    </TooltipWrapper>
  </div>
);

export default Manufacturer;
