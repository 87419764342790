// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IThreshold } from "@apm/widgets/build/widgets/ParameterCharts";
import {
  colorStatusOrange,
  colorStatusRed
} from "@pg/common/build/styles/ColorVariables";
import { IntlShape } from "react-intl";
import IThresholds from "../models/IThresholds";

const getColor = (name: string) => {
  return name === "Alarm"
    ? colorStatusRed
    : name === "Alert"
    ? colorStatusOrange
    : undefined;
};

const getDisplayName = (name: string, intl: IntlShape) =>
  intl.formatMessage({
    defaultMessage: name,
    id: `detail_page.widgets.analytics.transformers.sot.${name.toLowerCase()}`
  });

const mapToThresholds = (thresholds: IThresholds, intl: IntlShape) => {
  const thresholdList: IThreshold[] = Object.keys(thresholds)?.map<IThreshold>(
    (name) => {
      return {
        name: name,
        displayName: getDisplayName(name, intl),
        color: getColor(name),
        isAreaColored: false,
        sections: thresholds[name]?.map((t) => ({
          start: t.Start,
          end: t.End,
          value: t.Value
        }))
      };
    }
  );

  return thresholdList;
};

export default mapToThresholds;
