// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import RogersRatio from "features/detailpage/features/rogersratio/components/RogersRatio";
import { useRogersRatioReducer } from "features/detailpage/features/rogersratio/reducers/RogersRatioReducer";
import React from "react";
import { useIntl } from "react-intl";

type RogersRatioContainerProps = {
  assetId: string;
};

const RogersRatioContainer = ({ assetId }: RogersRatioContainerProps) => {
  const intl = useIntl();
  const { rogersRatioData, loadRogersRatioData } =
    useRogersRatioReducer(assetId);

  React.useEffect(() => {
    loadRogersRatioData(intl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <RogersRatio rogersRatioData={rogersRatioData} />;
};

export default RogersRatioContainer;
