// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { EventEmitter } from "events";
import $ from "jquery";

import { parentClassName, scrollClassName } from "common/datagrid/DataGrid";
import eventHub from "core/events/EventHub";

export default class ElementService {
  static getEventEmitter(parentElement: HTMLElement): EventEmitter | undefined {
    if (parentElement) {
      const parentElementId = parentElement.getAttribute("id");
      const eventEmitter = eventHub.get(parentElementId);
      return eventEmitter;
    } else {
      return;
    }
  }

  static getFooterElement(gridId: string): HTMLElement {
    const footerElement = document.querySelector<HTMLElement>(
      `#${gridId} .grid-footer.static`
    );
    return footerElement;
  }

  static getFooterHeight(footerElement: HTMLElement): number | undefined {
    if (footerElement) {
      return footerElement.clientHeight;
    } else {
      return undefined;
    }
  }

  static getFooterTop(footerElement: HTMLElement): number | undefined {
    return footerElement
      ? footerElement.getBoundingClientRect().top
      : undefined;
  }

  static getFooterWidth(footerElement: HTMLElement): number | undefined {
    if (footerElement) {
      return footerElement.clientWidth;
    } else {
      return undefined;
    }
  }

  static getHeaderElement(gridId: string): HTMLElement {
    const headerElement = document.querySelector<HTMLElement>(
      `#${gridId} .grid-header.static`
    );
    return headerElement;
  }

  static getHeaderTop(headerElement: HTMLElement): number | undefined {
    if (headerElement) {
      const headerOffsetValue = headerElement.getBoundingClientRect().top || 0;
      return headerOffsetValue;
    } else {
      return undefined;
    }
  }

  static getHeaderHeight(headerElement: HTMLElement): number | undefined {
    if (headerElement) {
      return headerElement.clientHeight;
    } else {
      return undefined;
    }
  }

  static getHeaderWidth(headerElement: HTMLElement): number | undefined {
    if (headerElement) {
      return headerElement.clientWidth;
    } else {
      return undefined;
    }
  }

  static getLastRowElement(gridId: string): HTMLElement {
    const lastRowElement = document.querySelector<HTMLElement>(
      `#${gridId} .grid-body .grid-row-container:last-of-type`
    );
    return lastRowElement;
  }

  static getParentElement(gridId: string): HTMLElement {
    const gridElement = document.querySelector<HTMLElement>(`#${gridId}`);
    const parentElement = $(gridElement).closest(`.${parentClassName}`)[0];
    return parentElement || gridElement;
  }

  static getScrollElement(gridId: string): HTMLElement | undefined {
    const gridElement = document.querySelector<HTMLElement>(`#${gridId}`);
    const scrollElement = $(gridElement).closest(`.${scrollClassName}`)[0];
    return scrollElement || gridElement;
  }

  static getParentTop(parentElement: HTMLElement): number | undefined {
    return parentElement
      ? parentElement.getBoundingClientRect().top
      : undefined;
  }

  static getParentHeight(parentElement: HTMLElement): number | undefined {
    if (parentElement) {
      return parentElement.clientHeight;
    } else {
      return undefined;
    }
  }

  static isLastRowsVisible(gridId: string) {
    const parentElement = ElementService.getParentElement(gridId);
    const lastRowElement = ElementService.getLastRowElement(gridId);

    if (parentElement && lastRowElement) {
      return (
        parentElement.getBoundingClientRect().top + parentElement.clientHeight >
        lastRowElement.getBoundingClientRect().top -
          lastRowElement.clientHeight * 20
      );
    } else {
      return false;
    }
  }
}
