// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useState, useRef } from "react";

import {
  ISlidePanelData,
  IssueSlidePanelMode
} from "features/issues/components/IssuesSlidePanel";

import SlidePanel from "common/slidepanel/SlidePanel";

import {
  IOnMaintenancePriorityClickOptions,
  IOnNumberOfActionsClickOptions
} from "features/issues/components/IssuesGrid";

const useSlidePanel = () => {
  const slidePanelRef = useRef<SlidePanel>();

  const openSlidePanel = () => {
    if (slidePanelRef.current) {
      slidePanelRef.current.open();
    }
  };
  const [slidePanelData, setSlidePanelData] = useState<ISlidePanelData>();
  const [issueSlidePanelMode, setIssueSlidePanelMode] = useState<
    IssueSlidePanelMode
  >(null);

  const handleMaintenancePriorityClick = (
    options: IOnMaintenancePriorityClickOptions
  ) => {
    setSlidePanelData(options);
    setIssueSlidePanelMode(IssueSlidePanelMode.MaintenancePriority);
    openSlidePanel();
  };

  const handleNumberOfActionsClick = (
    options: IOnNumberOfActionsClickOptions
  ) => {
    setSlidePanelData(options);
    setIssueSlidePanelMode(IssueSlidePanelMode.NumberOfActions);
    openSlidePanel();
  };

  return {
    handleMaintenancePriorityClick,
    handleNumberOfActionsClick,
    slidePanelData,
    issueSlidePanelMode,
    slidePanelRef
  };
};

export default useSlidePanel;
