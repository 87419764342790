// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { ChartAPI } from "c3";
import { useRef, useCallback, useEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";

const useAssetRiskResizeObserver = () => {
  const chartApi = useRef<ChartAPI>();
  const chartContainer = useRef<HTMLDivElement>();
  const resizeObserver = useRef<ResizeObserver>();

  const onResized = useCallback(() => {
    if (chartApi.current) {
      chartApi.current.flush();
    }
  }, []);

  const observe = (container: HTMLDivElement) => {
    const observer = new ResizeObserver(onResized);
    chartContainer.current = container;
    observer.observe(chartContainer.current);
    resizeObserver.current = observer;
  };

  const unobserve = () => {
    if (resizeObserver.current && chartContainer.current) {
      resizeObserver.current.unobserve(chartContainer.current);
    }
  };

  const createResizeObserver = useCallback(
    (chart: ChartAPI, container: HTMLDivElement) => {
      unobserve();
      observe(container);
      chartApi.current = chart;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => () => unobserve(), []);

  return { createResizeObserver };
};

export default useAssetRiskResizeObserver;
