// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import "./LTCLatestUpdateInfo.less";

const LTCLatestUpdateWrapper = ({ children }: React.Props<any>) => (
  <div className="shadow-sm p-3 bg-white rounded">{children}</div>
);

interface ILTCLatestUpdateInfoProps {
  cumulativeOperationCount: number;
  maximumOperationCount: number;
  lastUpdate: string;
}

const LTCLatestUpdateInfo = ({
  cumulativeOperationCount,
  maximumOperationCount,
  lastUpdate
}: ILTCLatestUpdateInfoProps) => (
  <LTCLatestUpdateWrapper>
    <div className="ltc-latest-update">
      <div className="ltc-latest-update__title">
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.ltc.last_update"
          defaultMessage="Last update"
        />
      </div>
      <div className="ltc-latest-update__date">
        <FormattedDate value={new Date(lastUpdate)} />
      </div>
      <div className="ltc-latest-update__counts container">
        <div className="bootstrap-row">
          <div className="ltc-latest-update__label col-18">
            <FormattedMessage
              id="detail_page.widgets.analytics.transformers.ltc.cumulative_operation_count"
              defaultMessage="Cumulative Operation Count"
            />
          </div>
          <div className="ltc-latest-update__value col text-right">
            <FormattedNumber value={cumulativeOperationCount} />
          </div>
        </div>
        <div className="bootstrap-row">
          <div className="ltc-latest-update__label col-18">
            <FormattedMessage
              id="detail_page.widgets.analytics.transformers.ltc.maximum_operation_count"
              defaultMessage="Maximum Operation Count"
            />
          </div>
          <div className="ltc-latest-update__value col text-right">
            <FormattedNumber value={maximumOperationCount} />
          </div>
        </div>
      </div>
    </div>
  </LTCLatestUpdateWrapper>
);

export default LTCLatestUpdateInfo;
