// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data from "core/data/models/Data";
import { getDataKey } from "features/ConfigurationTool/actions/loadConnectedDevices";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import IConnectedDevicesData from "../models/connectedDevices/IConnectedDevicesData";

const getConnectedDevicesDataSelector = createSelector<
  IState,
  (assetId: string) => Data<IConnectedDevicesData>,
  (assetId: string) => Data<IConnectedDevicesData>
>(
  (state: IState) => (assetId: string) => state.data[getDataKey(assetId)],
  (connectedDevices) => connectedDevices
);

export default getConnectedDevicesDataSelector;
