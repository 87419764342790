// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";
import { IState } from "reducers/Index";
import NotificationsService from "../services/NotificationsService";

import getAllOrganizationsCheckedSelector from "./getAllOrganizationsCheckedSelector";
import getNotificationsEnabledSelector from "./getNotificationsEnabledSelector";

const getOrganizationsCheckedKeys = createSelector(
  (state: IState) => state.notifications.modelInput.data,
  getAllOrganizationsCheckedSelector,
  getNotificationsEnabledSelector,
  (data, allOrganizationsChecked, notificationsEnabled) =>
    data && data.Organizations && notificationsEnabled
      ? NotificationsService.convertIOrganizationsToStringArray(
          data.Organizations,
          allOrganizationsChecked
        )
      : []
);

export default getOrganizationsCheckedKeys;
