// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IRawQueryParams } from "core/app/reducers/LocationReducer";
import LocationService from "core/app/services/LocationService";
import * as history from "history";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import store from "stores/Index";
import ILocationState from "../../models/ILocationState";

export interface IRouteManagerActions {
  setQueryParams: (rawQueryParams: IRawQueryParams) => void;
}

export interface IRouteManagerProps extends IRouteManagerActions {}

class RouteManager extends React.Component<
  IRouteManagerProps & RouteComponentProps
> {
  private unregisterLocationListener: () => void;

  constructor(props: IRouteManagerProps & RouteComponentProps) {
    super(props);

    this.configureHistory(props.history);
    this.registerLocationListener();
  }

  componentWillUnmount() {
    this.unregisterLocationListener();
  }

  render() {
    const { children } = this.props;
    const childArray = React.Children.toArray(children);
    return <div>{childArray}</div>;
  }

  private mapQueryParamsToState(location: any) {
    const { setQueryParams } = this.props;
    const rawQueryParams = LocationService.getRawQueryParamsFromSearch(
      location.search
    );
    setQueryParams(rawQueryParams);
  }

  private registerLocationListener() {
    const { history } = this.props;
    this.unregisterLocationListener = history.listen((location: any) => {
      this.mapQueryParamsToState(location);
    });
  }

  private configureHistory(history: history.History) {
    const push = history.push;
    const replace = history.replace;

    history.push = (to: history.Path | any, state?: ILocationState) => {
      const location = this.restoreLocation(to, state);
      push(location);
    };

    history.replace = (to: history.Path | any, state?: ILocationState) => {
      const location = this.restoreLocation(to, state);
      replace(location);
    };
  }

  private restoreLocation = (
    to: history.Path | any,
    state?: ILocationState
  ) => {
    let location: any;
    if (typeof to === "string") {
      const i = to.indexOf("?");
      const hasSearch = i >= 0;
      const path = hasSearch ? to.substr(0, i) : to;
      const search = hasSearch ? to.substr(i + 1) : "";
      location = {
        pathname: path,
        search: search,
        state: state
      };
    } else {
      location = to;
    }

    const storedQueryParams = store.getState().app.location.queryParams;
    const storedRawQueryParams =
      LocationService.mapNamedQueryParamsToRaw(storedQueryParams);
    const searchRawQueryParams = LocationService.getRawQueryParamsFromSearch(
      location.search
    );
    const rawQueryParams = this.combineQueryParams(
      storedRawQueryParams,
      searchRawQueryParams
    );
    const search = LocationService.getSearchFromRawQueryParams(rawQueryParams);

    location.search = search;

    return location;
  };

  private combineQueryParams(
    rawQueryParams1: IRawQueryParams,
    rawQueryParams2: IRawQueryParams
  ): IRawQueryParams {
    const rawQueryParams = {
      ...rawQueryParams1,
      ...rawQueryParams2
    };

    return rawQueryParams;
  }
}

const RouteManagerWithRouter = withRouter(RouteManager);

export default RouteManagerWithRouter;
