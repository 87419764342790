// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import TextFieldInputContainer from "common/form/containers/TextFieldInputContainer";
import ProcessingModal from "components/common/ProcessingModal";
import { Statuses } from "core/data/models/Data";
import IssueStatusSelectContainer from "features/detailpage/features/issues/containers/IssueStatusSelectContainer";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import IIssueMeta from "features/detailpage/features/issues/models/IIssueMeta";
import IssueModes from "features/detailpage/features/issues/models/IssueModes";
import { IssueStatuses } from "features/detailpage/features/issues/models/IssueStatuses";
import React from "react";
import { IntlShape, useIntl } from "react-intl";
import IssueCreatedDate, { Formats } from "./IssueCreatedDate";
import { issueDetailsFormName } from "./IssueDetails";
import IssueDetailsHeaderActions from "./IssueDetailsHeaderActions";
import IssueIcon from "./IssueIcon";

export interface IIssueDetailsHeaderActions {
  editIssueStatus: (
    intl: IntlShape,
    issueId: string,
    status: IssueStatuses
  ) => Promise<void>;
}

export interface IIssueDetailsHeaderData {}

export interface IIssueDetailsHeaderOwnProps {
  allowSave: boolean;
  issue: IIssue;
  meta: IIssueMeta;
  onEditButtonClick?: () => void;
  onSaveButtonClick?: () => void;
  onCancelButtonClick?: () => void;
  onStatusSaved?: (status: IssueStatuses) => void;
  readonly: boolean;
}

interface IIssueDetailsHeaderProps
  extends IIssueDetailsHeaderActions,
    IIssueDetailsHeaderData,
    IIssueDetailsHeaderOwnProps {}

const IssueDetailsHeader = ({
  allowSave,
  editIssueStatus,
  issue,
  meta,
  onCancelButtonClick,
  onEditButtonClick,
  onSaveButtonClick,
  onStatusSaved,
  readonly
}: IIssueDetailsHeaderProps) => {
  const intl = useIntl();
  const title = issue.HeaderText;

  const handleEditButtonClick = () => {
    if (onEditButtonClick) onEditButtonClick();
  };

  const handleSaveButtonClick = () => {
    if (onSaveButtonClick) onSaveButtonClick();
  };

  const handleCancelButtonClick = () => {
    if (onCancelButtonClick) onCancelButtonClick();
  };

  const handleStatusChange = (status: IssueStatuses) => {
    if (meta.mode === IssueModes.View) {
      editIssueStatus(intl, issue.Id, status).then(() => {
        if (onStatusSaved) onStatusSaved(status);
      });
    }
  };

  return (
    <div
      className={`
        issue-header
        ${meta.mode === IssueModes.View ? "view" : ""}
        ${meta.mode === IssueModes.Create ? "create" : ""}
        ${meta.mode === IssueModes.Edit ? "edit" : ""}
        ${readonly ? "readonly" : ""}
      `}
    >
      <div className="issue-header-main">
        <div className="issue-header-status">
          <IssueIcon issue={issue} />
          <IssueStatusSelectContainer
            formName={issueDetailsFormName}
            issueStatus={issue.Status}
            onChange={handleStatusChange}
            disabled={readonly}
          />
        </div>
        <IssueCreatedDate
          className="issue-header-date"
          format={Formats.Date}
          issue={issue}
        />
        {readonly ? null : (
          <IssueDetailsHeaderActions
            allowSave={allowSave}
            className="issue-actions"
            mode={meta.mode}
            onEditButtonClick={handleEditButtonClick}
            onSaveButtonClick={handleSaveButtonClick}
            onCancelButtonClick={handleCancelButtonClick}
          />
        )}
      </div>
      {meta.mode === IssueModes.View && (
        <div className="issue-header-title value bold" data-qa="issue-title">
          {title}
        </div>
      )}
      {(meta.mode === IssueModes.Create || meta.mode === IssueModes.Edit) && (
        <div className="issue-header-title">
          <TextFieldInputContainer
            defaultValue={title}
            formName={issueDetailsFormName}
            inputName="title"
            label={{
              defaultMessage: "Title",
              id: "detail_page.issues.issue_details.form.title"
            }}
            maxLength={1000}
            required={true}
          />
        </div>
      )}
      {meta.editStatusRequest &&
        meta.editStatusRequest.status === Statuses.Loading && (
          <ProcessingModal />
        )}
    </div>
  );
};

export default IssueDetailsHeader;
