// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Typography } from "antd";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  spacingLarge,
  spacingMedium,
  spacingSmall,
  spacingXLarge
} from "styles/StyleVariables";
import TooltipFormattedDate from "./TooltipFormattedDate";

const { Text } = Typography;

interface IAssetRiskExecutionDateTooltipProps {
  lastUpdatedBefore: string;
  conditionCalculationDate: moment.MomentInput;
  conditionDate: moment.MomentInput;
  latestParameterDate: moment.MomentInput;
  className?: string;
}

const AssetRiskExecutionDateTooltipExtended = ({
  lastUpdatedBefore,
  conditionCalculationDate,
  conditionDate,
  latestParameterDate,
  className
}: IAssetRiskExecutionDateTooltipProps) => {
  return (
    <div className={`${className} asset-risk-execution-date-tooltip-extended`}>
      <Title level={5} className="header">
        <FormattedMessage
          defaultMessage="Last updated {diff} ago."
          id="asset-risk-execution-date-tooltip.diff"
          values={{
            diff: lastUpdatedBefore
          }}
        />
      </Title>
      <div className="details">
        <div data-qa="tooltip-calculation-date">
          <Text strong={true}>
            <FormattedMessage
              id="asset-risk-execution-date-tooltip.calculation-date"
              defaultMessage="Calculation date"
            />
            <TooltipFormattedDate date={conditionCalculationDate} />
          </Text>
          <div className="desc">
            <FormattedMessage
              id="asset-risk-execution-date-tooltip.calculation-date-desc"
              defaultMessage="The latest date of the condition calculation for this asset"
            />
          </div>
        </div>
        <hr />
        <div data-qa="tooltip-execution-date">
          <Text strong={true}>
            <FormattedMessage
              id="asset-risk-execution-date-tooltip.condition-date"
              defaultMessage="Condition date"
            />
            <TooltipFormattedDate date={conditionDate} />
          </Text>
          <div className="desc">
            <FormattedMessage
              id="asset-risk-execution-date-tooltip.condition-date-desc"
              defaultMessage="The latest timestamp among the parameter values that were used for the condition calculation"
            />
          </div>
        </div>
        <hr />
        <div data-qa="tooltip-latest-parameter-date">
          <Text strong={true}>
            <FormattedMessage
              id="asset-risk-execution-date-tooltip.latest-parameter-date"
              defaultMessage="Latest parameter"
            />
            <TooltipFormattedDate date={latestParameterDate} />
          </Text>
          <div className="desc">
            <FormattedMessage
              id="asset-risk-execution-date-tooltip.latest-parameter-date-desc"
              defaultMessage="The latest timestamp among the uploaded parameter values"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const StyledAssetRiskExecutionDateTooltipExtended = styled(
  AssetRiskExecutionDateTooltipExtended
)`
  &.asset-risk-execution-date-tooltip-extended {
    max-width: 40ch;
    margin: ${spacingMedium} 0;
    padding: 0 ${spacingLarge};

    hr {
      margin: ${spacingLarge} 0;
    }

    .header {
      margin-bottom: ${spacingXLarge};
    }

    .details {
      .date {
        float: right;
      }

      .desc {
        padding-top: ${spacingSmall};
      }
    }
  }
`;

export default StyledAssetRiskExecutionDateTooltipExtended;
