// Copyright 2016-2022 Hitachi Energy. All rights reserved.
export class ApiUrgencies {
  static readonly AsSoonAsPossible = "AsSoonAsPossible";
  static readonly WithinAYear = "WithinAYear";
  static readonly WithinOneToThreeYears = "WithinOneToThreeYears";
  static readonly WithinThreeToFiveYears = "WithinThreeToFiveYears";
  static readonly Unknown = "Unknown";
}

export class UiUrgencies {
  static readonly AsSoonAsPossible = "AsSoonAsPossible";
  static readonly Normal = "Normal";
  static readonly LongTerm = "LongTerm";
  static readonly Unknown = "Unknown";
}

export default class UrgencyConverter {
  static convertApiUrgencyToUiUrgency(urgencyLevel: string): string {
    switch (urgencyLevel) {
      case ApiUrgencies.AsSoonAsPossible:
      case ApiUrgencies.WithinAYear:
        return UiUrgencies.AsSoonAsPossible;
      case ApiUrgencies.WithinOneToThreeYears:
        return UiUrgencies.Normal;
      case ApiUrgencies.WithinThreeToFiveYears:
        return UiUrgencies.LongTerm;
      case ApiUrgencies.Unknown:
      default:
        return UiUrgencies.Unknown;
    }
  }

  static convertUiUrgencyToApiUrgency(urgency: string): string {
    switch (urgency) {
      case UiUrgencies.AsSoonAsPossible:
        return ApiUrgencies.AsSoonAsPossible;
      case UiUrgencies.Normal:
        return ApiUrgencies.WithinOneToThreeYears;
      case UiUrgencies.LongTerm:
        return ApiUrgencies.WithinThreeToFiveYears;
      default:
        return ApiUrgencies.Unknown;
    }
  }

  static convertApiUrgenciesToUiUrgencies(urgencies: string[]): string[] {
    return (urgencies || [])
      .map(item => UrgencyConverter.convertApiUrgencyToUiUrgency(item))
      .filter((item, i, ar) => ar.indexOf(item) === i);
  }
}
