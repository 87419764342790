// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import RoutableTabs from "common/RoutableTabs";
import { useMemo } from "react";
import DielectricContext from "../../../contexts/DielectricContext";
import { IModelDetails } from "../../DielectricTab/DielectricTab";
import useDielectricSF6Tab from "../hooks/useDielectricSF6Tab";
import "./DielectricSF6Tab.less";

interface IDielectricSF6TabProps {
  assetId: string;
  model: IModelDetails;
}

const DielectricSF6Tab = ({ assetId, model }: IDielectricSF6TabProps) => {
  const { tabs } = useDielectricSF6Tab(assetId, model);
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);

  return (
    <DielectricContext.Provider
      value={{
        assetId
      }}
    >
      <RoutableTabs tabs={tabs} noBorder={true} searchParams={searchParams} />
    </DielectricContext.Provider>
  );
};

export default DielectricSF6Tab;
