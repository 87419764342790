// Copyright 2016-2021 Hitachi Power Grids. All rights reserved.
import { Col, Form, Popover } from "antd";
import { isNil } from "lodash";
import { Moment } from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { colorGray0, colorStatusRed } from "styles/ColorVariables";
import FormContext from "../../contexts/FormContext";
import IField from "../../models/IField";
import DatePickerField from "./DatePickerField";
import InputField from "./InputField";
import InputNumberField from "./InputNumberField";
import SelectField from "./SelectField";
import ValueField from "./ValueField";

interface IEditableFieldProps {
  className?: string;
  span: string | number;
  fieldConfiguration: IField;
  getPopupContainer: () => HTMLDivElement;
  getInitialValue?: (
    fieldName: string
  ) => string | number | boolean | Date | Moment;
}

interface IValidationError {
  errorFields: {
    errors: string[];
  }[];
}

const EditableField = ({
  className,
  span,
  fieldConfiguration,
  getPopupContainer,
  getInitialValue
}: IEditableFieldProps) => {
  const form = useContext(FormContext)!;

  const [errors, setErrors] = useState<string[]>([]);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (
      fieldConfiguration &&
      !isNil(form.getFieldValue([fieldConfiguration.fieldKey]))
    ) {
      form
        .validateFields([fieldConfiguration.fieldKey])
        .catch((errorInfo: IValidationError) => {
          const errorList: string[] = [];
          errorInfo.errorFields.forEach((errorField) => {
            errorField.errors.forEach((error) => {
              errorList.push(error);
            });
          });
          setErrors(errorList);
        });
    }
  }, [fieldConfiguration, form]);

  const toggleEdit = useCallback(() => {
    setEditing(!editing);
  }, [editing]);

  const getValidationErrors = useCallback(
    async (fieldKey: string) => {
      form
        .validateFields([fieldKey])
        .then(() => {
          if (errors?.length !== 0) setErrors([]);
        })
        .catch((errorInfo: IValidationError) => {
          const errorList: string[] = [];
          errorInfo.errorFields.forEach((errorField) => {
            errorField.errors.forEach((error) => {
              errorList.push(error);
            });
          });
          setErrors(errorList);
        });
    },
    [errors?.length, form]
  );

  const invalid = useMemo(() => errors?.length > 0, [errors?.length]);

  const EditableField = useMemo(() => {
    if (!editing) return null;
    if (fieldConfiguration.inputType === "list") return SelectField;
    if (fieldConfiguration.inputType === "Bool") return SelectField;
    if (fieldConfiguration.inputType === "Decimal") return InputNumberField;
    if (fieldConfiguration.inputType === "DateTime") return DatePickerField;
    if (fieldConfiguration.inputType === "String") return InputField;
  }, [editing, fieldConfiguration.inputType]);

  return (
    <Col
      className={`${className} table-cell ${invalid ? "invalid" : ""}`}
      span={span}
    >
      <Popover
        overlayClassName={`table-cell-validation-popover ${
          !invalid ? "popover-no-content" : ""
        }`}
        content={(errors ?? [])[0]}
        getPopupContainer={getPopupContainer}
      >
        {EditableField && (
          <EditableField
            configuration={fieldConfiguration}
            toggleEdit={toggleEdit}
            getValidationErrors={getValidationErrors}
            getInitialValue={getInitialValue}
          />
        )}
        {!editing && (
          <Form.Item
            name={fieldConfiguration.fieldKey}
            rules={fieldConfiguration.rules}
            initialValue={
              getInitialValue
                ? getInitialValue(fieldConfiguration.fieldKey)
                : null
            }
          >
            <ValueField
              configuration={fieldConfiguration}
              toggleEdit={toggleEdit}
            />
          </Form.Item>
        )}
      </Popover>
    </Col>
  );
};

const inputHeight = "30px";
const inputPadding = "5px 12px";

const StyledEditableField = styled(EditableField)`
  &.invalid {
    .input-value {
      color: ${colorStatusRed};
    }
  }

  .input-value {
    width: 100%;
    height: ${inputHeight};
    padding: ${inputPadding};
    margin: 0px;
  }

  .ant-form-item {
    margin-bottom: 0px;

    .ant-form-item-control {
      flex: none;
    }

    .ant-form-item-explain {
      display: none;
    }

    .ant-input-number,
    .ant-input,
    .ant-select-selector,
    .ant-picker {
      border-radius: 0px;
    }

    .ant-input-number {
      &:not(.ant-input-number:focus) {
        &:not(.ant-input-number-focused) {
          &:not(.ant-input-number:hover) {
            border-color: ${colorGray0};
          }
        }
      }
    }

    .ant-input {
      &:not(.ant-input:focus) {
        &:not(.ant-input-focused) {
          &:not(.ant-input:hover) {
            border-color: ${colorGray0};
          }
        }
      }
    }

    .ant-select {
      &:not(.ant-select:focus) {
        &:not(.ant-select-focused) {
          &:not(.ant-select:hover) {
            .ant-select-selector {
              border-color: ${colorGray0};
            }
          }
        }
      }
    }

    .ant-picker {
      &:not(.ant-picker-focused) {
        &:not(.ant-picker:hover) {
          border-color: ${colorGray0};
        }
      }
    }
  }
`;

export default StyledEditableField;
