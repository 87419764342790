// Copyright 2016-2021 Hitachi Power Grids. All rights reserved.
import { Col, Row, Typography } from "antd";
import { Moment } from "moment";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import {
  colorGray0,
  colorGray10,
  colorWidgetBackground
} from "styles/ColorVariables";
import {
  spacingSmall,
  spacingXLarge,
  spacingXXLarge
} from "styles/StyleVariables";
import ISection from "../models/ISection";
import EditableField from "./SectionTable/EditableField";

const { Title } = Typography;
const { Text } = Typography;

interface ICustomSectionTableProps {
  className?: string;
  configuration: ISection;
  getPopupContainer: () => HTMLDivElement;
  getInitialValue?: (
    fieldName: string
  ) => string | number | boolean | Date | Moment;
}

const CustomSectionTable = ({
  className,
  configuration,
  getPopupContainer,
  getInitialValue
}: ICustomSectionTableProps) => {
  const intl = useIntl();

  const columnNodes = useMemo(() => {
    const columns = configuration.sectionConfiguration.columns;
    const rows = configuration.sectionConfiguration.rows;
    const columnsTotalSpan = columns
      .map((x) => x.span)
      .reduce((a, b) => a + b, 0);
    const labelSpan = 24 - columnsTotalSpan;
    const nodes = [];

    nodes.push(
      <Col
        className={"table-cell table-label"}
        span={labelSpan}
        key={"placeholder"}
      />
    );

    columns.forEach((columnConfig, i) =>
      nodes.push(
        <Col
          className={"table-cell table-label"}
          span={columnConfig.span}
          key={`column${i}`}
        >
          <Text>{intl.formatMessage({ ...columnConfig.labelName })}</Text>
        </Col>
      )
    );

    rows.forEach((rowConfig, i) => {
      nodes.push(
        <Col
          className={"table-cell table-label"}
          span={labelSpan}
          key={`row${i}`}
        >
          <Text>{intl.formatMessage({ ...rowConfig.labelName })}</Text>
        </Col>
      );

      configuration.fields
        .slice(i * columns.length, (i + 1) * columns.length)
        .forEach((field, j) => {
          nodes.push(
            <EditableField
              span={columns[j].span}
              fieldConfiguration={field}
              getPopupContainer={getPopupContainer}
              key={`row${i}field${j}`}
              getInitialValue={getInitialValue}
            />
          );
        });
    });
    return nodes;
  }, [
    configuration.fields,
    configuration.sectionConfiguration,
    getPopupContainer,
    intl,
    getInitialValue
  ]);

  return (
    <div className={`${className} section`}>
      <Title className="table-title" level={5}>
        <FormattedMessage {...configuration.sectionName} />
      </Title>
      <Row gutter={[0, 0]}>{columnNodes}</Row>
    </div>
  );
};

const StyledCustomSectionTable = styled(CustomSectionTable)`
  .table-cell {
    border-left: 1px solid ${colorGray10};
    border-top: 1px solid ${colorGray10};
    background-color: ${colorGray0};
  }

  .table-label {
    padding: ${spacingSmall} ${spacingXLarge};
    background-color: ${colorWidgetBackground};
  }

  .table-title {
    padding: ${spacingXXLarge} ${spacingXXLarge} 0px ${spacingXXLarge};
  }
`;

export default StyledCustomSectionTable;
