// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import FilterBar, { SelectedFilters } from "common/FilterBar";
import { useState } from "react";
import styled from "styled-components";
import AlarmsGrid from "./AlarmsGrid";

interface IAlarmsProps {
  className?: string;
}

const Alarms = ({ className }: IAlarmsProps) => {
  const [filters, setFilters] = useState<SelectedFilters>();
  const handleFilter = (filters: SelectedFilters) => {
    setFilters(filters);
  };

  return (
    <div className={`${className} alarms`}>
      <FilterBar
        onFilter={handleFilter}
        namespace="alarms"
        useSearch
        useAssetOrganizationWithRegionAndLocation
        useAssetTypeWithSubType
        useAlarmTypes
      >
        {filters && <AlarmsGrid filters={filters} />}
      </FilterBar>
    </div>
  );
};

export default styled(Alarms)`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  > div:first-of-type {
    flex-shrink: 0;
  }
`;
