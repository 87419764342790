// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import removeWear from "./removeWear";
import removeChartData from "./removeChartData";
import { Dispatch } from "redux";

const removeWearData = (dispatch: Dispatch<any>, assetId: string) => {
  dispatch(removeWear(assetId));
  dispatch(removeChartData(assetId));
};

export default removeWearData;
