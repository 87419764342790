// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { RegionOptions } from "c3";
import C3Chart from "common/chart/components/C3Chart";
import ChartLegend, { LegendItem } from "common/ChartLegend";
import ResizeWrapper from "common/ResizeWrapper";
import {
  highVoltageSeriesColor,
  lowVoltageSeriesColor
} from "features/detailpage/features/bushingvisualization/components/BushingVisualization";
import IOnlineBushingData from "features/detailpage/features/bushingvisualization/models/IOnlineBushingData";
import IReading from "features/detailpage/features/bushingvisualization/models/IReading";
import { uniqBy } from "lodash";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import IForecast from "../models/IForecast";
import "./BushingWeeklyChart.less";
import BushingWeeklyInfo from "./BushingWeeklyInfo";

interface IBushingWeeklyChartProps {
  onlineBushingData: IOnlineBushingData;
}

export interface ILegendState {
  [name: string]: {
    focused: boolean;
    selected: boolean;
  };
}

const alertAreaOpacity = 0.05;
const warningAreaOpacity = 0.05;
const currentForecastAreaOpacity = 0.05;

const warningLegendColor = "#FAF5B6";
const alertLegendColor = "#FFEDDB";

const BushingWeeklyChart = ({
  onlineBushingData
}: IBushingWeeklyChartProps) => {
  const intl = useIntl();
  let chartRef: c3.ChartAPI;

  const getMagnitude = (
    date: moment.Moment,
    readings: IReading[],
    forecast: IForecast
  ) => {
    const values = [...readings, forecast].filter(
      (r) => r && moment(r.Date).format(dateFormat) === date.format(dateFormat)
    );
    return values && values.length ? values[0].Magnitude : null;
  };

  const dateFormat = "YYYY-MM-DD";
  const lvReadingDates =
    onlineBushingData.LowVoltageSumCurrentReadings &&
    onlineBushingData.LowVoltageSumCurrentReadings.map((r) => r.Date);
  const hvReadingDates =
    onlineBushingData.HighVoltageSumCurrentReadings &&
    onlineBushingData.HighVoltageSumCurrentReadings.map((r) => r.Date);
  const readingDates = uniqBy([...lvReadingDates, ...hvReadingDates], (d) =>
    moment(d).format(dateFormat)
  ).map((d) => moment(d));
  const currentDate = moment.max(readingDates);
  const forecastDate =
    onlineBushingData.HighVoltageSumCurrentForecast &&
    moment(onlineBushingData.HighVoltageSumCurrentForecast.Date);

  const dates = forecastDate ? [...readingDates, forecastDate] : readingDates;

  const lvMagnitudes = dates.map((d) =>
    getMagnitude(
      d,
      onlineBushingData.LowVoltageSumCurrentReadings,
      onlineBushingData.LowVoltageSumCurrentForecast
    )
  );
  const hvMagnitudes = dates.map((d) =>
    getMagnitude(
      d,
      onlineBushingData.HighVoltageSumCurrentReadings,
      onlineBushingData.HighVoltageSumCurrentForecast
    )
  );

  const maxMagnitude = Math.max(...lvMagnitudes, ...hvMagnitudes);
  const alertLevel = onlineBushingData.SumCurrentAlertLevel;
  const warningLevel = onlineBushingData.SumCurrentWarningLevel;

  const maxYAxisValue = maxMagnitude * 1.1;

  const columns: [string, ...c3.Primitive[]][] = [
    ["x", ...dates.map((d) => d.format(dateFormat))],
    ["highVoltage", ...hvMagnitudes],
    ["lowVoltage", ...lvMagnitudes]
  ];

  const regions: RegionOptions[] = [
    {
      axis: "y",
      class: "alert-area",
      opacity: alertAreaOpacity,
      start: alertLevel
    },
    {
      axis: "y",
      class: "warning-area",
      opacity: warningAreaOpacity,
      start: warningLevel,
      end: alertLevel
    },
    {
      axis: "x",
      class: "current-forecast-area",
      opacity: currentForecastAreaOpacity,
      start: currentDate.format(dateFormat)
    }
  ];

  const forecastRegions: { [key: string]: RegionOptions[] } = {
    highVoltage: [
      {
        start: currentDate.format(dateFormat),
        style: "dashed"
      }
    ],
    lowVoltage: [
      {
        start: currentDate.format(dateFormat),
        style: "dashed"
      }
    ]
  };

  const colors = {
    highVoltage: highVoltageSeriesColor,
    lowVoltage: lowVoltageSeriesColor
  };

  const legendItems: LegendItem[] = [
    {
      color: highVoltageSeriesColor,
      id: "highVoltage",
      name: "High Voltage"
    },
    {
      color: lowVoltageSeriesColor,
      id: "lowVoltage",
      name: "Low Voltage"
    }
  ];

  return (
    <div className="bushing-weekly-chart">
      <ResizeWrapper>
        <C3Chart
          configuration={{
            data: {
              x: "x",
              columns,
              names: {
                highVoltage: "High Voltage",
                lowVoltage: "Low Voltage"
              },
              regions: forecastRegions,
              colors
            },
            regions,
            grid: {
              y: {
                show: true,
                lines: [
                  { value: alertLevel, class: "alert-line" },
                  { value: warningLevel, class: "warning-line" }
                ]
              },
              x: {
                show: true,
                lines: [{ value: currentDate.format(dateFormat) }]
              }
            },
            axis: {
              x: {
                type: "timeseries",
                show: true,
                padding: { right: 0, left: 0 },
                tick: {
                  format: function (d) {
                    const date = moment(d);
                    return intl.formatDate(date.toDate());
                  }
                },
                max: forecastDate
                  ? forecastDate.format(dateFormat)
                  : currentDate.clone().add(1, "day").format(dateFormat)
              },
              y: {
                show: true,
                min: 0,
                max: maxYAxisValue,
                padding: { bottom: 0 },
                tick: {
                  format: (x: number): string =>
                    intl.formatMessage(
                      {
                        defaultMessage: "{value}%",
                        id: "detail_page.parameters.bushing_visualization.tick_y_axis"
                      },
                      {
                        value: x.toString()
                      }
                    )
                },
                label: {
                  text: intl.formatMessage({
                    defaultMessage: "%",
                    id: "detail_page.parameters.bushing_visualization.label_y_axis"
                  }),
                  position: "outer-top"
                }
              }
            },
            legend: {
              show: false
            },
            line: {
              connectNull: true
            },
            point: {
              r: function (d) {
                const date = moment(d.x);
                if (date.isSame(currentDate, "date")) return 8;
                if (forecastDate && date.isSame(forecastDate, "date")) return 0;
                return 4;
              }
            },
            padding: {
              right: 20,
              top: 20
            },
            tooltip: {
              format: {
                title: function (d) {
                  const date = moment(d as any);
                  return intl.formatDate(date.toDate());
                },
                name: function (name, ratio, id, index) {
                  return intl.formatMessage({
                    defaultMessage: id,
                    id: `detail_page.parameters.bushing_visualization.tooltip_${id}`
                  });
                }
              }
            }
          }}
          onRendered={(chart) => {
            chartRef = chart;
          }}
        />
      </ResizeWrapper>
      <div className="bushing-weekly-legend">
        <ChartLegend
          items={legendItems}
          itemMessageIdPrefix="detail_page.parameters.bushing_visualization.weekly_chart"
          onFocus={(id: string) => {
            chartRef.focus(id);
          }}
          onRevert={() => {
            chartRef.revert();
          }}
          onToggle={(id: string) => {
            chartRef.toggle(id);
          }}
        />
        <BushingWeeklyInfo
          warningColor={warningLegendColor}
          alertColor={alertLegendColor}
        />
      </div>
    </div>
  );
};

export default BushingWeeklyChart;
