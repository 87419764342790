// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import IScoreFactor from "./IScoreFactor";

interface IParameterTranslatableProps {
  TranslatedName?: string;
  TranslatedFactors?: Array<IScoreFactor>;
}

export enum TypedValueEnum {
  Decimal = "Decimal",
  String = "String",
  DateTime = "DateTime",
  Bool = "Bool"
}
export type TypedValue = keyof typeof TypedValueEnum;

export type ParameterSourceType =
  | "AssetNameplate"
  | "AssetParameter"
  | "DerivativeParameter";

export interface ITypedValue {
  Type: TypedValue;
  Value: number | boolean | string;
}

export default interface IParameter extends IParameterTranslatableProps {
  Name: string;
  Unit: string;
  Factors: Array<IScoreFactor>;
  LastUpdate: Date;
  LastValue: ITypedValue;
  PreviousUpdate: Date;
  PreviousValue: ITypedValue;
  Change: string;
  ParameterSource: ParameterSourceType;
  ExternalName: string;
}
