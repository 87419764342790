// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { connectedDevicesConfiguration } from "../constants/connectedDevicesConfiguration";
import { inspectionConfiguration } from "../constants/inspectionConfiguration";
import { menuConfiguration } from "../constants/menuConfiguration";
import { nameplateConfiguration } from "../constants/nameplateConfiguration";
import DataTypes from "../models/DataTypes";
import FormTypes from "../models/FormTypes";
import IForm from "../models/IForm";

export interface ITranslatedMenuItem {
  key: string;
  title: string;
  iconName?: string;
  formType?: FormTypes;
  dataType?: DataTypes;
  children?: Array<ITranslatedMenuItem>;
}

const useConfiguration = () => {
  const intl = useIntl();

  const formsConfiguration: IForm[] = useMemo(() => {
    return [
      ...nameplateConfiguration.forms,
      ...inspectionConfiguration.forms,
      ...connectedDevicesConfiguration.forms
    ];
  }, []);

  const getFormConfiguration = useCallback(
    (formKey: string) => {
      const filteredForms = formsConfiguration.filter(
        (form) => form.formKey === formKey
      );
      if (filteredForms.length > 0) {
        return filteredForms[0];
      }
    },
    [formsConfiguration]
  );

  const translatedMenuConfiguration: ITranslatedMenuItem[] = useMemo(() => {
    return menuConfiguration.map((menuItem) => ({
      key: menuItem.key,
      title: intl.formatMessage({ ...menuItem.title }),
      children: menuItem.children.map((subMenuItem) => {
        return {
          key: subMenuItem.key,
          iconName: subMenuItem.iconName,
          title: intl.formatMessage({ ...subMenuItem.title }),
          formType: subMenuItem.formType,
          dataType: subMenuItem.dataType
        };
      })
    }));
  }, [intl]);

  const getParameterNames = useCallback((object, keysList) => {
    if (object.hasOwnProperty("fieldKey")) {
      keysList.push(object.fieldKey);
    }

    for (let i = 0; i < Object.keys(object).length; i++) {
      if (typeof object[Object.keys(object)[i]] == "object") {
        getParameterNames(object[Object.keys(object)[i]], keysList);
      }
    }
  }, []);

  const getConfiguredParameterNames = useCallback(
    (formKeys?: string[]) => {
      const parametersKeys: string[] = [];

      const forms = formKeys
        ? inspectionConfiguration.forms.filter((f) =>
            formKeys.includes(f.formKey)
          )
        : inspectionConfiguration.forms;
      for (let i = 0; i < Object.keys(forms).length; i++) {
        getParameterNames(forms[i], parametersKeys);
      }

      return parametersKeys;
    },
    [getParameterNames]
  );

  const configuredParameterNames: string[] = useMemo(() => {
    return getConfiguredParameterNames();
  }, [getConfiguredParameterNames]);

  return {
    translatedMenuConfiguration,
    configuredParameterNames,
    getFormConfiguration,
    getConfiguredParameterNames
  };
};

export default useConfiguration;
