// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import Processing from "components/common/Processing";
import Data, { Statuses } from "core/data/models/Data";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import IConductanceData from "../models/IConductanceData";
import "./BatteryConductance.less";
import BatteryConductanceChart from "./BatteryConductanceChart";
import BatteryConductanceTitle from "./BatteryConductanceTitle";

interface IBatteryConductanceProps {
  conductanceData: Data<IConductanceData>;
}

const BatteryConductance = ({ conductanceData }: IBatteryConductanceProps) => (
  <div className="battery-conductance container">
    <div className="bootstrap-row">
      <div className="col-24">
        {conductanceData && conductanceData.status === Statuses.Loading && (
          <BatteryConductanceProcessing />
        )}
        {conductanceData && conductanceData.status === Statuses.Succeeded && (
          <BatteryConductanceSucceeded conductanceData={conductanceData.data} />
        )}
        {conductanceData && conductanceData.status === Statuses.Failed && (
          <BatteryConductanceFailed />
        )}
        {conductanceData && conductanceData.status === Statuses.NotFound && (
          <BatteryConductanceNotFound />
        )}
      </div>
    </div>
  </div>
);

const BatteryConductanceProcessing = () => (
  <div className="bushing-visualization container">
    <Processing />
  </div>
);

interface IBatteryConductanceSucceededProps {
  conductanceData: IConductanceData;
}

const BatteryConductanceSucceeded = ({
  conductanceData
}: IBatteryConductanceSucceededProps) => (
  <div className="chart">
    <BatteryConductanceTitle />
    <BatteryConductanceChart conductanceData={conductanceData} />
  </div>
);

const BatteryConductanceFailed = () => (
  <div className="battery-conductance container">
    <div className="failed">
      <Icon name="warning" />
      <FormattedMessage
        defaultMessage="Data loading failed"
        id="detail_page.parameters.battery_conductance.data_loading_failed"
      />
    </div>
  </div>
);

const BatteryConductanceNotFound = () => (
  <div className="battery-conductance container">
    <div className="no-data">
      <Icon name="warning" />
      <FormattedMessage
        defaultMessage="No data found"
        id="detail_page.parameters.battery_conductance.no_data_found"
      />
    </div>
  </div>
);

export default BatteryConductance;
