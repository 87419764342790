// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createContext } from "react";

interface IDielectricContext {
  assetId: string;
}

const DielectricContext = createContext<IDielectricContext>({
  assetId: ""
});

export default DielectricContext;
