// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { IRoutableTab } from "common/RoutableTabs";

import ReplacementPriorityContainer from "features/detailpage/features/replacementscore/containers/ReplacementPriorityContainer";
import AgingVisualization from "features/detailpage/features/replacementscore/components/aging/AgingVisualization";

const useReplacementScoreTabs = (
  assetId: string,
  replacementScoreModelImplementationId: string
) => {
  const intl = useIntl();
  const tabs: IRoutableTab[] = useMemo(() => {
    return [
      {
        id: "ReplacementPriority",
        name: intl.formatMessage({
          id: "detail_page.replacement_score.tabs.replacement_priority",
          defaultMessage: "Replacement Priority"
        }),
        Content: () => (
          <ReplacementPriorityContainer
            assetId={assetId}
            replacementScoreModelImplementationId={
              replacementScoreModelImplementationId
            }
          />
        )
      },
      {
        id: "Aging",
        name: intl.formatMessage({
          id: "detail_page.replacement_score.tabs.aging",
          defaultMessage: "Aging"
        }),
        Content: () => <AgingVisualization assetId={assetId} />
      }
    ];
  }, [assetId, intl, replacementScoreModelImplementationId]);

  return tabs;
};

export default useReplacementScoreTabs;
