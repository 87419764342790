// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";
import { MessageDescriptor, useIntl } from "react-intl";

import FormattedValue from "components/common/FormattedValue";

import "./LabeledRow.less";

interface ILabeledRowProps {
  label: string | MessageDescriptor;
  value?: string | number;
  defaultValue?: string | number;
  truncate?: boolean;
  unit?: string;
  additionalData?: string;
}

const LabeledRow = ({
  label,
  value,
  defaultValue,
  truncate,
  unit,
  additionalData
}: ILabeledRowProps) => {
  const intl = useIntl();

  return (
    <div className="labeled-row">
      <span className="label">
        {typeof label === "string" ? label : intl.formatMessage(label)}
      </span>
      <span className="value">
        <FormattedValue
          value={value !== null && value !== undefined ? value : defaultValue}
          truncate={truncate}
          unit={unit}
          additionalData={additionalData}
        />
      </span>
    </div>
  );
};

export default LabeledRow;
