// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import Widget from "components/common/widget/Widget";
import DetailPageWidgetContext from "features/detailpage/contexts/DetailPageWidgetContext";
import ParametersTabs from "features/detailpage/features/ParametersTabs";
import ReplacementScoreTabs from "features/detailpage/features/replacementscore/components/tabs/ReplacementScoreTabs";
import RiskMatrixWidget from "features/detailpage/features/riskmatrix/components/RiskMatrixWidget";
import { memo, useContext } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import RiskTrendWidgets from "../features/risktrend/components/RiskTrendWidgets";
import WidgetsRoutes from "../models/WidgetsRoutes";
import AssetNameplate from "./AssetNameplate";
import "./DetailPageWidgets.less";
import MessagesWidget from "./MessagesWidget";

class WidgetsIds {
  static readonly HealthScoreTrendWidgetId = "DetailPageHealthScoreWidget";
  static readonly RiskMatrixWidgetId = "DetailPageRiskMatrixWidget";
  static readonly ParametersListWidgetId = "DetailPageParametersList";
  static readonly AssetNameplateWidgetId = "DetailPageAssetNameplateWidget";
  static readonly MessagesWidgetId = "DetailPageMessagesWidget";
  static readonly ReplacementScoreWidgetId = "ReplacementScoreWidget";
}

interface IDetailPageWidgetsContextProps {
  isParametersListWidgetVisible: boolean;
  isAssetNameplateWidgetVisible: boolean;
}

interface IDetailPageWidgetsRouteProps {
  path: string;
}

interface IDetailPageWidgetsOwnProps {
  assetId: string;
  modelId: string;
  implementationId: string;
  replacementScoreModelImplementationId: string;
  assetType: string;
  analyticsAvailable: boolean;
  dcTransformerAvailable: boolean;
  kinectricsNTAvailable: boolean;
  batteryAvailable: boolean;
  cbkAvailable: boolean;
  expanded: boolean;
}

type DetailPageWidgetsProps = IDetailPageWidgetsOwnProps &
  IDetailPageWidgetsContextProps &
  IDetailPageWidgetsRouteProps;

const DetailPageWidgets = memo(
  ({
    assetId,
    modelId,
    implementationId,
    replacementScoreModelImplementationId,
    assetType,
    analyticsAvailable,
    dcTransformerAvailable,
    kinectricsNTAvailable,
    batteryAvailable,
    cbkAvailable,
    expanded,
    isAssetNameplateWidgetVisible,
    isParametersListWidgetVisible,
    path
  }: DetailPageWidgetsProps) => {
    return (
      <div
        className={`detail-page-widgets scroll-area ${
          isParametersListWidgetVisible && "horizontal"
        }`}
      >
        <div
          className={
            isParametersListWidgetVisible ? "widgets" : "widgets max-col12vw"
          }
        >
          {isAssetNameplateWidgetVisible && (
            <Widget
              element={<AssetNameplate assetId={assetId} />}
              titleId="detail_page.widgets.asset_nameplate_widget.title"
              titleDefault="Nameplate"
              size={null}
              widgetId={WidgetsIds.AssetNameplateWidgetId}
            />
          )}
          <Switch>
            <Route path={`${path}/${WidgetsRoutes.Risk}`}>
              <div className="charts-wrapper" data-qa="risk-tab">
                <Widget
                  element={<RiskMatrixWidget assetId={assetId} />}
                  titleId="detail_page.widgets.risk_matrix_widget.title"
                  titleDefault="Risk Matrix"
                  size={null}
                  widgetId={WidgetsIds.RiskMatrixWidgetId}
                  className="risk-matrix-widget"
                />
                <RiskTrendWidgets
                  expanded={expanded}
                  widgetId={WidgetsIds.HealthScoreTrendWidgetId}
                  assetId={assetId}
                  modelId={modelId}
                  implementationId={implementationId}
                />
              </div>
            </Route>
            <Route path={`${path}/${WidgetsRoutes.Parameters}`}>
              <ParametersTabs
                modelId={modelId}
                implementationId={implementationId}
                assetId={assetId}
                assetType={assetType}
                analyticsAvailable={analyticsAvailable}
                dcTransformerAvailable={dcTransformerAvailable}
                kinectricsNetworkTransformerAvailable={kinectricsNTAvailable}
                batteryAvailable={batteryAvailable}
                cbkAvailable={cbkAvailable}
              />
            </Route>
            <Route path={`${path}/${WidgetsRoutes.Messages}`}>
              <Widget
                element={<MessagesWidget assetId={assetId} />}
                titleId="detail_page.widgets.messages.title"
                titleDefault="Messages"
                size={null}
                widgetId={WidgetsIds.MessagesWidgetId}
              />
            </Route>
            <Route path={`${path}/${WidgetsRoutes.RPS}`}>
              <div className="replacement-score-widget-wrapper">
                <Widget
                  element={
                    <ReplacementScoreTabs
                      assetId={assetId}
                      replacementScoreModelImplementationId={
                        replacementScoreModelImplementationId
                      }
                    />
                  }
                  titleId="detail_page.widgets.replacement_score.title"
                  titleDefault="Replacement Score"
                  hideHeader={true}
                  size={null}
                  widgetId={WidgetsIds.ReplacementScoreWidgetId}
                />
              </div>
            </Route>
            <Redirect
              path={path}
              to={{
                pathname: `${path}/${WidgetsRoutes.Risk}`,
                search: new SearchParams({ assetId }).toString()
              }}
            />
          </Switch>
        </div>
      </div>
    );
  }
);

export default (props: IDetailPageWidgetsOwnProps) => {
  const { isAssetNameplateWidgetVisible, isParametersListWidgetVisible } =
    useContext(DetailPageWidgetContext);
  const { path } = useRouteMatch();
  return (
    <DetailPageWidgets
      {...props}
      isAssetNameplateWidgetVisible={isAssetNameplateWidgetVisible}
      isParametersListWidgetVisible={isParametersListWidgetVisible}
      path={path}
    />
  );
};
