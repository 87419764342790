// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { SearchParams } from "@pg/common";
import Icon from "@pg/common/build/components/Icon";
import Text from "antd/lib/typography/Text";
import { routesConfig } from "core/app/components/router/RouterProvider";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { colorTeal90 } from "styles/ColorVariables";
import { spacingLarge, spacingXSmall } from "styles/StyleVariables";

interface IViewInAssetsProps {
  className?: string;
  organization: string;
  region: string;
  location: string;
}

const ViewInAssets = ({
  className,
  organization,
  region,
  location
}: IViewInAssetsProps) => {
  const intl = useIntl();

  const search = useMemo(() => {
    const searchParams = new SearchParams();
    const fao = [organization, region, location].map((v) => v || null);
    searchParams.append("f_ao", JSON.stringify(fao));
    searchParams.append("f_as", JSON.stringify(["InService"]));
    return searchParams.toString();
  }, [location, organization, region]);

  return (
    <div className={className}>
      <NavLink
        key="view-in-assets"
        to={{
          pathname: routesConfig.assets,
          search
        }}
        data-qa="view-in-button"
      >
        <Icon name="open_in_new" />
        <Text>
          {intl.formatMessage({
            defaultMessage: "View in Assets",
            id: "locations_page.side_panel.view_in_assets"
          })}
        </Text>
      </NavLink>
    </div>
  );
};

export default styled(ViewInAssets)`
  border-top: ${spacingXSmall} solid ${colorTeal90};
  width: 100%;
  padding-top: ${spacingLarge};

  > a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > span {
      color: ${colorTeal90};
    }
  }
`;
