// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import ParameterCharts, {
  TimeFrames
} from "@apm/widgets/build/widgets/ParameterCharts";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import { Statuses } from "core/data/models/Data";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { IState } from "reducers/Index";
import { ThunkDispatch } from "thunk";
import loadDGAData from "../actions/loadGDAData";
import removeDGAData from "../actions/removeDGAData";
import dataSourceKey from "../constants/dataSourceKey";
import getDGADataSelector from "../selectors/getDGADataSelector";
import getChart from "../utils/getChart";
import getChartGroups from "../utils/getChartGroups";
import getLegend from "../utils/getLegend";

interface IDGAActions {
  loadDGAData: (assetId: string) => void;
  removeDGAData: (assetId: string) => void;
}
interface IDGAOwnProps {
  assetId: string;
  implementationId?: string;
  dataSources: string[];
  defaultDataSource?: string;
}

type DGAProps = IDGAOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const DGA = ({
  assetId,
  implementationId,
  dataSources,
  defaultDataSource,
  dgaData,
  loadDGAData,
  removeDGAData
}: DGAProps) => {
  const intl = useIntl();

  const { search } = useLocation();

  useEffect(() => {
    loadDGAData(assetId);
    return () => {
      removeDGAData(assetId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  const chartGroups = useMemo(
    () => getChartGroups(intl, dgaData?.data),
    [dgaData?.data, intl]
  );

  const dataSourceConfig = useMemo(
    () => ({
      dataSources: dataSources,
      defaultDataSource: defaultDataSource,
      dataSourceSessionKey: dataSourceKey
    }),
    [dataSources, defaultDataSource]
  );

  return (
    <>
      {dgaData?.status === Statuses.Loading && <Processing />}
      {dgaData?.status === Statuses.Succeeded && (
        <ParameterCharts
          chartGroups={chartGroups}
          getChart={(chartName: string, timeframe: TimeFrames) =>
            getChart(
              intl,
              chartName,
              dgaData?.data.GasStandard,
              dgaData?.data.GasStandardRevision,
              timeframe,
              search
            )
          }
          getLegend={getLegend(
            intl,
            implementationId,
            dgaData?.data.GasStandard,
            dgaData?.data.GasStandardRevision
          )}
          formatTooltipTitle={(date) =>
            intl.formatDate(date, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric"
            })
          }
          formatXTick={(date) => intl.formatDate(date)}
          dataSourceConfig={dataSourceConfig}
          translations={{
            dataSource: (dataSource) =>
              intl.formatMessage({
                defaultMessage: dataSource,
                id: `detail_page.widgets.analytics.transformers.dga.data_source.${dataSource}`
              }),
            series: (seriesName) =>
              intl.formatMessage({
                defaultMessage: seriesName,
                id: `detail_page.widgets.analytics.transformers.dga.${seriesName}`
              }),
            dataSourceLabel: intl.formatMessage({
              defaultMessage: "Data source",
              id: `global.data_source`
            }),
            empty: intl.formatMessage({
              defaultMessage: "No data available",
              id: "global.chart.no_data_available"
            }),
            timeFrame: (timeFrame) =>
              intl.formatMessage({
                defaultMessage: timeFrame,
                id: `detail_page.widgets.analytics.transformers.dga.${timeFrame}`
              }),
            switchRatio: intl.formatMessage({
              defaultMessage: "Switch ratio",
              id: "detail_page.widgets.analytics.transformers.dga.switch_ratio"
            })
          }}
        />
      )}
      {dgaData?.status === Statuses.NotFound && (
        <div>
          <WidgetNoDataMessage />
        </div>
      )}
      {dgaData?.status === Statuses.Failed && (
        <div>
          <WidgetErrorMessage
            messageId="global.empty"
            messageDefault={dgaData?.message}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: IState, ownProps: IDGAOwnProps) => ({
  dgaData: getDGADataSelector(state)(ownProps.assetId)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any>): IDGAActions => ({
  loadDGAData: (assetId: string) => dispatch(loadDGAData(assetId)),
  removeDGAData: (assetId: string) => dispatch(removeDGAData(assetId))
});

export default connect(mapStateToProps, mapDispatchToProps)(DGA);
