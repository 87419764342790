// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data from "core/data/models/Data";
import { IDuvalPoint } from "common/DuvalPentagon";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { dataKey } from "../actions/loadDuvalPentagons";

const getDuvalPentagonSelector = createSelector<
  IState,
  Data<IDuvalPoint[]>,
  Data<IDuvalPoint[]>
>(
  (state: IState) => state.data[dataKey],
  (stationRisk) => stationRisk || new Data()
);

export default getDuvalPentagonSelector;
