// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { dataKey } from "../actions/loadLinkedReports";

const getLinkedReportsSelector = createSelector(
  (state: IState) => state.data[dataKey],
  (linkedReports) => linkedReports
);

export default getLinkedReportsSelector;
