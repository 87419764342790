// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Reducer } from "redux";

import { IAssetRiskConfigurationValue } from "common/AssetRiskMatrix";
import Data from "core/data/models/Data";
import {
  Actions,
  ISetIsComponentAction
} from "features/detailpage/features/riskmatrix/actions/RiskMatrixActions";
import { IAjaxAction } from "reducers/Index";

import IRiskMatrixConfiguration from "../models/IRiskMatrixConfiguration";
import IRiskMatrixClusters from "../models/IRiskMatrixClusters";

export interface IState {
  riskMatrix: {
    riskMatrixConfiguration: Data<IRiskMatrixConfiguration>;
    assetRiskConfiguration: Data<IAssetRiskConfigurationValue>;
    riskMatrixClusters: Data<IRiskMatrixClusters>;
    isComponent?: boolean;
  };
}

export const defaultState: IState = {
  riskMatrix: {
    riskMatrixConfiguration: new Data(),
    assetRiskConfiguration: new Data(),
    riskMatrixClusters: new Data()
  }
};

const reducer: Reducer<IState> = (
  state = defaultState,
  action:
    | IAjaxAction<
        | IAssetRiskConfigurationValue
        | IRiskMatrixConfiguration
        | IRiskMatrixClusters
      >
    | ISetIsComponentAction
): IState => {
  switch (action.type) {
    case Actions.DetailPage_RiskMatrix_SetModelConfig:
      return {
        ...state,
        riskMatrix: {
          ...state.riskMatrix,
          assetRiskConfiguration: new Data(
            (action as IAjaxAction<IAssetRiskConfigurationValue>).request,
            (action as IAjaxAction<IAssetRiskConfigurationValue>).data
          )
        }
      };
    case Actions.DetailPage_RiskMatrix_SetRiskMatrixConfiguration:
      return {
        ...state,
        riskMatrix: {
          ...state.riskMatrix,
          riskMatrixConfiguration: new Data(
            (action as IAjaxAction<IRiskMatrixConfiguration>).request,
            (action as IAjaxAction<IRiskMatrixConfiguration>).data
          )
        }
      };
    case Actions.DetailPage_RiskMatrix_SetClusters:
      return {
        ...state,
        riskMatrix: {
          ...state.riskMatrix,
          riskMatrixClusters: new Data(
            (action as IAjaxAction<IRiskMatrixClusters>).request,
            (action as IAjaxAction<IRiskMatrixClusters>).data
          )
        }
      };
    case Actions.DetailPage_RiskMatrix_SetIsComponent:
      return {
        ...state,
        riskMatrix: {
          ...state.riskMatrix,
          isComponent: (action as ISetIsComponentAction).isComponent
        }
      };
    default:
      return state;
  }
};

export default reducer;
