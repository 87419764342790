// Copyright 2016-2022 Hitachi Energy. All rights reserved.
const assetSubTypes = [
  "auxiliary",
  "distribution",
  "generator_step_up",
  "grounding",
  "industrial",
  "other",
  "phase_shifter",
  "series_reactor",
  "shunt_reactor",
  "substation_service",
  "transmission"
];

export default assetSubTypes;
