// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import loadChartData from "../actions/loadChartData";
import getWearChartDataSelector from "../selectors/getWearChartDataSelector";
import isDataAvailable from "../utils/isDataAvailable";

const useContactWearChart = (assetId: string) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const wearChartData = useSelector(getWearChartDataSelector)(assetId);

  const title = useMemo(
    () =>
      intl.formatMessage({
        id: "detail_page.widgets.circuit_breaker.aging.contact_wear_chart.title",
        defaultMessage: "Contact Wear (I²t)"
      }),
    [intl]
  );

  useEffect(() => {
    if (!isDataAvailable(wearChartData)) {
      dispatch(loadChartData(assetId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    wearChartData,
    title
  };
};

export default useContactWearChart;
