// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import ModelConfigurationItem from "features/detailpage/components/modelconfiguration/ModelConfigurationItem";
import IAssetDetails from "../models/IAssetDetails";
import CompanyConfigurationItem from "./companyhierarchy/CompanyHierarchyConfigurationItem";

export interface INameplateItem {
  id: string;
  defaultMessage?: string;
  key: string;
  value: any;
  truncate?: boolean;
  unit?: string;
  additionalData?: string;
  isLargeContent?: boolean;
  isBoolValue?: boolean;
}

class AssetNameplateItems {
  static getNameplateItems(asset: IAssetDetails): INameplateItem[] {
    const nameplate = asset.NameplateWithModelInfo;
    if (nameplate) {
      return this.buildNameplateItems(asset);
    } else {
      return [];
    }
  }

  private static buildNameplateItems(asset: IAssetDetails): INameplateItem[] {
    const nameplate = asset.NameplateWithModelInfo;
    const items: INameplateItem[] = [
      {
        id: "asset.nameplate.description",
        key: null,
        value: nameplate.Description,
        isLargeContent: true
      },
      {
        id: "asset.nameplate.organization",
        key: null,
        value: nameplate.Organization
      },
      {
        id: "asset.nameplate.region",
        key: null,
        value: nameplate.Region
      },
      {
        id: "asset.nameplate.asset_type",
        key: null,
        value: nameplate.AssetType
      },
      {
        id: "asset.nameplate.assetsubtype",
        key: null,
        value: nameplate.AssetSubType
      },
      {
        id: "asset.nameplate.assetspatialtype",
        key: null,
        value: nameplate.AssetSpatialType
      },
      {
        id: "asset.nameplate.rated_power",
        key: null,
        value: nameplate.RatedPower,
        unit: "MVA"
      },
      {
        id: "asset.nameplate.location",
        key: null,
        value: nameplate.Location
      },
      {
        id: "asset.nameplate.territory",
        key: null,
        value: nameplate.Territory
      },
      {
        id: "asset.nameplate.install_date",
        key: null,
        value: nameplate.InstallDate,
        truncate: true
      },
      {
        id: "asset.nameplate.voltage",
        key: null,
        value: nameplate.Voltage,
        unit: "kV"
      },
      {
        id: "asset.nameplate.longitude",
        key: null,
        value: nameplate.Longitude
      },
      {
        id: "asset.nameplate.manufacturer",
        key: null,
        value: nameplate.Manufacturer
      },
      {
        id: "asset.nameplate.manufactured_date",
        key: null,
        value: nameplate.ManufacturedDate,
        truncate: true
      },
      {
        id: "asset.nameplate.importance",
        key: null,
        value: nameplate.Importance
      },
      {
        id: "asset.nameplate.latitude",
        key: null,
        value: nameplate.Latitude
      },
      {
        id: "asset.nameplate.customer_expected_life",
        key: null,
        value: nameplate.CustomerExpectedLife
      },
      {
        id: "asset.nameplate.manufacturer_expected_life",
        key: null,
        value: nameplate.ManufacturerExpectedLife
      },
      {
        id: "asset.nameplate.is_obsolete",
        key: null,
        value: nameplate.IsObsolete,
        isBoolValue: true
      },
      {
        id: "asset.nameplate.model_id",
        key: null,
        value: nameplate.ModelId
      },
      {
        id: "asset.nameplate.status",
        key: null,
        value: nameplate.Status
      },
      {
        id: "asset.nameplate.serial_number",
        key: null,
        value: nameplate.SerialNumber
      },
      {
        id: "asset.nameplate.replacement_score_model_id",
        key: null,
        value: nameplate.ReplacementScoreModelId
      },
      {
        id: "asset.nameplate.nameplate_reference_date",
        key: null,
        value: nameplate.NameplateReferenceDate,
        truncate: true
      },
      {
        id: "asset.nameplate.company_hierarchy_name",
        key: null,
        value: () => (
          <CompanyConfigurationItem
            assetHierarchyCode={
              nameplate.CompanyHierarchyCode
            }
            companyHierarchyNodes={asset.CompanyHierarchyNodes}
          />
        )
      }
    ];

    if (nameplate.ManufacturerModel !== null) {
      items.push({
        id: "asset.nameplate.manufacturer_model",
        key: null,
        value: nameplate.ManufacturerModel
      });
    }

    if (nameplate.ModelConfigurationName !== null) {
      items.push({
        id: "asset.nameplate.model_configuration_name",
        key: null,
        value: () => (
          <ModelConfigurationItem
            modelId={nameplate.ModelId}
            modelImplementationId={nameplate.ModelImplementationId}
            modelConfigurationName={nameplate.ModelConfigurationName}
            showModelConfigurationTable={nameplate.ShowModelConfigurationTable}
          />
        )
      });
    }

    if (nameplate.EamEquipmentNumber !== null) {
      items.push({
        id: "asset.nameplate.eam_equipment_number",
        key: null,
        value: nameplate.EamEquipmentNumber
      });
    }

    if (nameplate.AssetModelGroupName !== null) {
      items.push({
        id: "asset.nameplate.asset_model_group_name",
        key: null,
        value: nameplate.AssetModelGroupName
      });
    }

    Object.keys(nameplate.Attributes || {}).forEach((attributeKey) => {
      const attributeValue = nameplate.Attributes[attributeKey];
      items.push({
        id: attributeKey,
        defaultMessage: attributeKey,
        key: attributeKey,
        value: attributeValue,
        isBoolValue: typeof attributeValue === "boolean"
      });
    });

    return items;
  }
}

export default AssetNameplateItems;
