// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";

import {
  WidgetNoDataMessage,
  WidgetErrorMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import ParameterHistogramSucceeded from "./ParameterHistogramSucceeded";
import IParameterHistogram from "../models/IParameterHistogram";

import "./ParameterHistogram.less";

interface IParameterHistogramProps {
  title: string;
  unit: string;
  histogramData: Data<IParameterHistogram[]>;
}

const ParameterHistogram = ({
  title,
  unit,
  histogramData
}: IParameterHistogramProps) => {
  return (
    <div className="parameters-histogram container">
      {histogramData && histogramData.status === Statuses.Succeeded && (
        <ParameterHistogramSucceeded
          parameterHistogramData={histogramData.data}
          title={title}
          unit={unit}
        />
      )}
      {histogramData && histogramData.status === Statuses.Failed && (
        <WidgetErrorMessage />
      )}
      {histogramData && histogramData.status === Statuses.NotFound && (
        <WidgetNoDataMessage />
      )}
    </div>
  );
};

export default ParameterHistogram;
