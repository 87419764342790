// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Action, Reducer } from "redux";
import keyRiskChangesReducer, {
  defaultState as keyRiskChangesDefaultState,
  IState as IKeyRiskChangesState
} from "../keyriskchanges/reducers/KeyRiskChangesReducer";
import numberOfAssetsReducer, {
  defaultState as numberOfAssetsDefaultState,
  INumberOfAssetsState
} from "../numberofassets/reducers/NumberOfAssetsReducer";
import openIssuesReducer, {
  defaultState as openIssuesDefaultState,
  IOpenIssuesState
} from "../openissues/reducers/OpenIssuesReducer";

export interface IState
  extends INumberOfAssetsState,
    IOpenIssuesState,
    IKeyRiskChangesState {}

const defaultState: IState = {
  ...numberOfAssetsDefaultState,
  ...openIssuesDefaultState,
  ...keyRiskChangesDefaultState
};

const mainDashboardReducer: Reducer<IState> = (
  state: IState = defaultState,
  action: Action
) => {
  state = numberOfAssetsReducer(state, action) as IState;
  state = openIssuesReducer(state, action) as IState;
  state = keyRiskChangesReducer(state, action) as IState;

  return state;
};

export default mainDashboardReducer;
