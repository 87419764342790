// Copyright 2016-2022 Hitachi Power Grids. All rights reserved.
import { DatePicker, Form } from "antd";
import { TypedValue } from "features/detailpage/models/IParameter";
import { isNil } from "lodash";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import AssetModalContext from "../contexts/AssetModalContext";
import FormContext from "../contexts/FormContext";
import { IFormItem } from "../hooks/useForms";
import IFormValues from "../models/IFormValues";
import { dateFormat, disableFutureDates } from "../utils/datePicker";
import CustomSections from "./CustomSections";

interface IInspectionProps {
  formInfo: IFormItem;
}

const Inspection = ({ formInfo }: IInspectionProps) => {
  const {
    validateMessages,
    getFormInfo,
    registerForm,
    updateFormItemTouchedInfo,
    changeInspectionField,
    changeInspectionDateForFields
  } = useContext(AssetModalContext);

  const intl = useIntl();
  const form = Form.useForm<IFormValues>(
    getFormInfo(formInfo.formKey)?.form
  )[0];

  const inspectionDateKey = useMemo(() => {
    return `DateOfInspection_${formInfo.formKey}`;
  }, [formInfo.formKey]);

  useEffect(() => {
    registerForm(formInfo.formKey, form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    if (formInfo.invalid) {
      form.validateFields();
    }
  }, [form, formInfo.invalid]);

  const dataTypes: { [name: string]: TypedValue } = useMemo(() => {
    const fieldsConfig: { [name: string]: TypedValue } = {};
    formInfo.formConfiguration.sections.forEach((sectionElement) => {
      if (sectionElement.fields)
        sectionElement.fields.forEach((field) => {
          fieldsConfig[field.fieldKey] = field.dataType;
        });
      if (sectionElement.sectionList)
        sectionElement.sectionList.forEach((section) => {
          section.fields.forEach((field) => {
            fieldsConfig[field.fieldKey] = field.dataType;
          });
        });
    });
    return fieldsConfig;
  }, [formInfo]);

  const handleValuesChange = useCallback(
    (changedValues: Partial<IFormValues>, values: IFormValues) => {
      Object.keys(changedValues || {}).forEach((key) => {
        if (key === inspectionDateKey) {
          changeInspectionDateForFields(
            changedValues[key] as Date,
            Object.keys(values).filter((k) => k !== inspectionDateKey)
          );
        } else {
          changeInspectionField(
            key,
            dataTypes[key] === "Bool" && !isNil(changedValues[key])
              ? changedValues[key] === "true"
              : changedValues[key],
            dataTypes[key],
            values[inspectionDateKey] as Date
          );
        }
        updateFormItemTouchedInfo(formInfo.formKey);
      });
    },
    [
      changeInspectionDateForFields,
      changeInspectionField,
      updateFormItemTouchedInfo,
      formInfo.formKey,
      inspectionDateKey,
      dataTypes
    ]
  );

  return (
    <Form
      form={form}
      onValuesChange={handleValuesChange}
      layout="horizontal"
      colon={false}
      requiredMark={false}
      validateMessages={validateMessages}
      key={formInfo.formKey}
    >
      <Form.Item
        label={intl.formatMessage({
          id: "configuration_tool.label.date_of_inspection",
          defaultMessage: "Date of inspection"
        })}
        className="date-of-inspection"
        name={inspectionDateKey}
        rules={[{ required: true }]}
      >
        <DatePicker
          disabledDate={disableFutureDates}
          format={dateFormat(intl)}
          data-qa="InspectionDate"
        />
      </Form.Item>
      <FormContext.Provider value={form}>
        <CustomSections configuration={formInfo.formConfiguration.sections} />
      </FormContext.Provider>
    </Form>
  );
};

export default Inspection;
