// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import { loadVoltageData } from "../actions/BatteryVoltageActions";
import BatteryVoltage from "../components/BatteryVoltage";
import batteryVoltageSelector from "../selectors/batteryVoltageSelector";

interface IBatteryVoltageContainerProps {
  assetId: string;
}

const BatteryVoltageContainer = ({
  assetId
}: IBatteryVoltageContainerProps) => {
  const dispatch = useDispatch();
  const voltageData = useSelector(batteryVoltageSelector);
  const memoizedLoadVoltageData = useCallback(
    () => loadVoltageData(dispatch as Dispatch, assetId),
    [assetId, dispatch]
  );

  useEffect(() => {
    memoizedLoadVoltageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <BatteryVoltage voltageData={voltageData} />;
};

export default BatteryVoltageContainer;
