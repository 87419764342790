// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { IntlShape } from "react-intl";
import { renderToStaticMarkup } from "react-dom/server";

interface IDuvalTriangleTooltipProps {
  intl: IntlShape;
  date: Date;
  leftLabel: string;
  leftPPM: number;
  leftPercent: number;
  rightLabel: string;
  rightPPM: number;
  rightPercent: number;
  baseLabel: string;
  basePPM: number;
  basePercent: number;
}

const DuvalTriangleTooltip = ({
  intl,
  date,
  baseLabel,
  basePPM,
  basePercent,
  leftLabel,
  leftPPM,
  leftPercent,
  rightLabel,
  rightPPM,
  rightPercent
}: IDuvalTriangleTooltipProps) => {
  return (
    <>
      <div className="header">{intl.formatDate(date)}</div>
      <table>
        <tr>
          <td className="left">{leftLabel}</td>
          <td className="right">
            {intl.formatMessage(
              {
                id:
                  "detail_page.widgets.analytics.transformers.duval_triangles.ppm",
                defaultMessage: "{value} ppm"
              },
              {
                value: leftPPM
              }
            )}
          </td>
          <td className="right">
            {intl.formatMessage(
              {
                id:
                  "detail_page.widgets.analytics.transformers.duval_triangles.percent",
                defaultMessage: "{value} %"
              },
              {
                value: leftPercent
              }
            )}
          </td>
        </tr>
        <tr>
          <td className="left">{rightLabel}</td>
          <td className="right">
            {intl.formatMessage(
              {
                id:
                  "detail_page.widgets.analytics.transformers.duval_triangles.ppm",
                defaultMessage: "{value} ppm"
              },
              {
                value: rightPPM
              }
            )}
          </td>
          <td className="right">
            {intl.formatMessage(
              {
                id:
                  "detail_page.widgets.analytics.transformers.duval_triangles.percent",
                defaultMessage: "{value} %"
              },
              {
                value: rightPercent
              }
            )}
          </td>
        </tr>
        <tr>
          <td className="left">{baseLabel}</td>
          <td className="right">
            {intl.formatMessage(
              {
                id:
                  "detail_page.widgets.analytics.transformers.duval_triangles.ppm",
                defaultMessage: "{value} ppm"
              },
              {
                value: basePPM
              }
            )}
          </td>
          <td className="right">
            {intl.formatMessage(
              {
                id:
                  "detail_page.widgets.analytics.transformers.duval_triangles.percent",
                defaultMessage: "{value} %"
              },
              {
                value: basePercent
              }
            )}
          </td>
        </tr>
      </table>
    </>
  );
};

export default DuvalTriangleTooltip;

export const getStaticMarkup = (props: IDuvalTriangleTooltipProps) =>
  renderToStaticMarkup(<DuvalTriangleTooltip {...props} />);
