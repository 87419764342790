// Copyright 2016-2022 Hitachi Energy. All rights reserved.

import ChartLegend from "common/ChartLegend";
import * as d3 from "d3";
import { I3dCoords, I3dDataCoords, I3dFace, _3d } from "d3-3d";
import {
  RogerRatioCoords,
  RogerRatioZone,
  RogersRatioDataFetched,
  TracePoint,
  TracePointsData,
  ZonesLimits
} from "features/detailpage/features/rogersratio/hooks/useProcessRogersRatioData";
import { isEqual } from "lodash";
import * as React from "react";
import { injectIntl, IntlShape } from "react-intl";
import {
  colorBlue10,
  colorBlue20,
  colorBlue30,
  colorBlue40,
  colorBlue50,
  colorBlue60
} from "styles/ColorVariables";
import "./RogersRatioChart.less";

interface ITracePointCoords {
  x: number;
  y: number;
  z: number;
}

interface ICalculatedTracePointCoords extends ITracePointCoords {
  [key: string]: string | number;
}

interface IRogerRatioProps {
  rogersRatioZones: RogerRatioZone[] & ZonesLimits[];
  tracePoints: TracePointsData;
  showOfflineOnly: boolean;
  rogersRatioData: RogersRatioDataFetched;
  intl: IntlShape;
}

interface IRogerRatioState {
  traceColor: Array<number>;
  orient1: boolean;
  orient2: boolean;
  orient3: boolean;
}

interface IChartData {
  xzGrid3d: Array<any>;
  xyGrid3d: Array<any>;
  yzGrid3d: Array<any>;
  cubes3D: Array<any>;
  points3d: Array<any>;
  xScale: Array<any>;
  yScale: Array<any>;
  zScale: Array<any>;
  yScale_forX: Array<any>;
  yScale_forZ: Array<any>;
}
interface II3dCubeData {
  [index: number]: I3dDataCoords;
  centroid: I3dCoords;
  faces: Array<I3dFace>;
  height: number;
  id: string;
  x0: number;
  x1: number;
  y0: number;
  y1: number;
  z0: number;
  z1: number;
}
export interface ICube {
  [index: number]: ICubeCoords;
  id?: string;
  height?: number;
  x0?: number;
  x1?: number;
  y0?: number;
  y1?: number;
  z0?: number;
  z1?: number;
  color?: string;
  name?: string;
}

interface ICubeCoords {
  x: number;
  y: number;
  z: number;
}

type d3Selection<T extends d3.BaseType> = d3.Selection<T, {}, any, any>;

type selectionDataType = {
  id: string;
  color: string;
};

class RogerRatio extends React.Component<IRogerRatioProps, IRogerRatioState> {
  private svgRef: SVGSVGElement;
  private divRef: HTMLDivElement;
  private tooltipDiv: any;

  private svg: d3Selection<SVGSVGElement>;
  private gridGroups: d3Selection<SVGGElement>;
  private xzGridGroup: d3Selection<SVGGElement>;
  private xyGridGroup: d3Selection<SVGGElement>;
  private yzGridGroup: d3Selection<SVGGElement>;
  private scaleGroup: d3Selection<SVGGElement>;
  private cubesGroup: d3Selection<SVGGElement>;
  private traceGroup: d3Selection<SVGGElement>;
  private xzGrid: Array<Array<number>>;
  private xyGrid: Array<Array<number>>;
  private yzGrid: Array<Array<number>>;

  private ticksNumber: number;
  private scale: number;

  private origin: Array<number>;
  private cubesData: Array<ICube>;
  private traceData: Array<ITracePointCoords>;

  private xLine: Array<Array<number>>;
  private yLine: Array<Array<number>>;
  private zLine: Array<Array<number>>;
  private yLine_forX: Array<Array<number>>;
  private yLine_forZ: Array<Array<number>>;
  private alpha: number;
  private beta: number;
  private startAngle: number;
  private color: any;

  private xzGrid3d: any;
  private xScale3d: any;
  private xyGrid3d: any;
  private yzGrid3d: any;
  private yScale3d: any;
  private zScale3d: any;
  private yScale3d_forX: any;
  private yScale3d_forZ: any;
  private cubes3D: any;
  private points3d: any;

  private mx: number;
  private my: number;
  private mouseX: number;
  private mouseY: number;

  constructor(props: IRogerRatioProps) {
    super(props);

    this.state = {
      traceColor: [75, 136, 255],
      orient1: false,
      orient2: false,
      orient3: false
    };
  }

  private handleChartLegendFocused = (plotName: string) => {
    this.processCubeSelection(
      (
        selection: d3Selection<SVGGElement>,
        selectionData: selectionDataType
      ) => {
        if (plotName !== selectionData.id) {
          selection.attr("fill", "#BABABA");
        } else {
          selection.attr("fill", "#ABC3FF");
        }
      }
    );
  };

  private handleChartLegendReverted = () => {
    this.processCubeSelection(
      (
        selection: d3Selection<SVGGElement>,
        selectionData: selectionDataType
      ) => {
        selection.attr("fill", selectionData.color);
      }
    );
  };

  private handleChartLegendToggled = (plotName: string) => {
    this.processCubeSelection(
      (
        selection: d3Selection<SVGGElement>,
        selectionData: selectionDataType
      ) => {
        if (plotName === selectionData.id) {
          if (selection.style("display") === "none") {
            selection.style("display", "block");
          } else {
            selection.style("display", "none");
          }
        }
      }
    );
  };

  private processCubeSelection = (
    processSelection: (sel: d3Selection<SVGGElement>, selData: object) => void
  ) => {
    this.cubesGroup.selectAll(".cube._3d").each(function () {
      const selection = d3.select(this) as d3Selection<SVGGElement>;
      const selectionData: object = selection.data()[0];

      processSelection(selection, selectionData);
    });
  };

  render() {
    const legendItems =
      this.cubesData &&
      this.cubesData.map(({ id, name, color }) => ({
        id,
        name,
        color
      }));

    return (
      <div
        className="rogers-container"
        ref={(ref: HTMLDivElement) => (this.divRef = ref)}
      >
        <svg
          ref={(ref: SVGSVGElement) => (this.svgRef = ref)}
          className="gasRatio"
          id="gasRatio"
          style={{
            overflow: "visible"
          }}
        />
        <ChartLegend
          items={legendItems}
          onFocus={this.handleChartLegendFocused}
          onRevert={this.handleChartLegendReverted}
          onToggle={this.handleChartLegendToggled}
          itemMessageIdPrefix="detail_page.widgets.analytics.transformers.rogers_ratio"
        />
      </div>
    );
  }

  initSvg = () => {
    this.svg = d3
      .select(this.svgRef)
      .call(
        d3
          .drag()
          .on("drag", this.handleDragged)
          .on("start", this.handleDragStart)
          .on("end", this.handleDragEnd)
      )
      .attr("width", 800)
      .attr("height", 700);
  };

  getCubeColorsPalette = () => {
    return [
      colorBlue10,
      colorBlue20,
      colorBlue30,
      colorBlue40,
      colorBlue50,
      colorBlue60
    ];
  };

  componentDidMount() {
    const width = this.divRef.parentElement.clientWidth;

    this.ticksNumber = 5;
    this.scale = width / 4 / this.ticksNumber;
    this.initSvg();
    this.origin = [225, 550];
    this.cubesData = [];
    this.traceData = [];
    this.xzGrid = [];
    this.xyGrid = [];
    this.yzGrid = [];
    this.xLine = [];
    this.yLine = [];
    this.zLine = [];
    this.yLine_forX = [];
    this.yLine_forZ = [];
    this.alpha = 0;
    this.beta = 0;
    this.startAngle = Math.PI / 6;
    this.color = d3
      .scaleOrdinal()
      .domain(this.cubesData as any)
      .range(this.getCubeColorsPalette());
    this.gridGroups = this.svg.append("g").attr("class", "grids");
    this.xzGridGroup = this.gridGroups.append("g").attr("class", "xzGrid");
    this.xyGridGroup = this.gridGroups.append("g").attr("class", "xyGrid");
    this.yzGridGroup = this.gridGroups.append("g").attr("class", "yzGrid");
    this.scaleGroup = this.svg.append("g").attr("class", "scales");
    this.cubesGroup = this.svg
      .append("g")
      .attr("class", "cubes")
      .attr("id", "cubes");
    this.traceGroup = this.svg.append("g").attr("class", "traces");
    this.mx = null;
    this.my = null;
    this.mouseX = null;
    this.mouseY = null;

    this.xzGrid3d =
      this.xyGrid3d =
      this.yzGrid3d =
        _3d()
          .shape("GRID", 21)
          .rotateY(this.startAngle)
          .rotateX(-this.startAngle)
          .origin(this.origin)
          .scale(this.scale);

    this.xScale3d =
      this.yScale3d =
      this.zScale3d =
      this.yScale3d_forX =
      this.yScale3d_forZ =
        _3d()
          .shape("LINE_STRIP")
          .rotateY(this.startAngle)
          .rotateX(-this.startAngle)
          .origin(this.origin)
          .scale(this.scale);

    this.cubes3D = _3d()
      .shape("CUBE")
      .x((d: ITracePointCoords) => d.x)
      .y((d: ITracePointCoords) => d.y)
      .z((d: ITracePointCoords) => d.z)
      .rotateY(this.startAngle)
      .rotateX(-this.startAngle)
      .origin(this.origin)
      .scale(this.scale);

    this.points3d = _3d()
      .shape("POINT")
      .x((d: ITracePointCoords) => d.x)
      .y((d: ITracePointCoords) => d.y)
      .z((d: ITracePointCoords) => d.z)
      .origin(this.origin)
      .rotateY(this.startAngle)
      .rotateX(-this.startAngle)
      .scale(this.scale);

    const r = this.ticksNumber / 2;
    this.xzGrid3d.rotateCenter([r, 0, r]);
    this.xyGrid3d.rotateCenter([r, 0, r]);
    this.yzGrid3d.rotateCenter([r, 0, r]);
    this.xScale3d.rotateCenter([r, 0, r]);
    this.yScale3d.rotateCenter([r, 0, r]);
    this.zScale3d.rotateCenter([r, 0, r]);
    this.yScale3d_forX.rotateCenter([r, 0, r]);
    this.yScale3d_forZ.rotateCenter([r, 0, r]);
    this.cubes3D.rotateCenter([r, 0, r]);
    this.points3d.rotateCenter([r, 0, r]);

    d3.selectAll(".ps").on("click", this.init);
    d3.selectAll(".orient1").on("click", this.orient1);
    d3.selectAll(".orient2").on("click", this.orient2);
    d3.selectAll(".orient3").on("click", this.orient3);
    this.init();
    this.appendTooltipDiv();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.showOfflineOnly !== prevProps.showOfflineOnly) {
      this.buildTrace();
      const data: any = {
        points3d: this.points3d(this.traceData)
      };
      this.setState(
        {
          traceColor: [75, 136, 255]
        },
        () => {
          this.appendTraceCircles(data, 0);
          this.makeLastTracePointBlack();
        }
      );
    }
  }

  private init = () => {
    this.cubesData = [];
    this.traceData = [];
    this.xzGrid = [];
    this.xyGrid = [];
    this.yzGrid = [];

    const xLine = this.xLine;
    const yLine = this.yLine;
    const zLine = this.zLine;
    const yLine_forX = this.yLine_forX;
    const yLine_forZ = this.yLine_forZ;
    const j = this.ticksNumber;

    const cubesNames = [
      "Unit Normal",
      "Low-energy density arching - PD",
      "Arching - High energy discharge",
      "Low temperature thermal",
      "Thermal < 700°C",
      "Thermal > 700°C"
    ];

    this.props.rogersRatioZones.forEach((zone: RogerRatioZone, index: number) =>
      this.createCubeData(zone, index, cubesNames[0 + index])
    );

    this.buildTrace();

    for (let i = 0; i <= this.ticksNumber; i += j / 20) {
      for (let k = 0; k <= this.ticksNumber; k += j / 20) {
        this.xzGrid.push([k, 0, i]);
        this.xyGrid.push([k, -i, 0]);
        this.yzGrid.push([j, -i, k]);
      }
    }

    d3.range(0, j + 1, 1).forEach((d) => xLine.push([d, 0, j]));
    d3.range(0, j + 1, 1).forEach((d) => yLine.push([0, -d, 0]));
    d3.range(0, j + 1, 1).forEach((d) => zLine.push([0, 0, d]));
    d3.range(0, 1, 1).forEach((d) => yLine_forX.push([2.5, d + 0.5, j + 1]));
    d3.range(0, 1, 1).forEach((d) => yLine_forZ.push([0, d + 0.5, 2.5]));

    this.xLine = xLine;
    this.yLine = yLine;
    this.zLine = zLine;
    this.yLine_forX = yLine_forX;
    this.yLine_forZ = yLine_forZ;

    const data: IChartData = {
      xzGrid3d: this.xzGrid3d(this.xzGrid),
      xyGrid3d: this.xyGrid3d(this.xyGrid),
      yzGrid3d: this.yzGrid3d(this.yzGrid),
      cubes3D: this.cubes3D(this.cubesData),
      points3d: this.points3d(this.traceData),
      xScale: this.xScale3d([this.xLine]),
      yScale: this.yScale3d([this.yLine]),
      zScale: this.zScale3d([this.zLine]),
      yScale_forX: this.yScale3d_forX([this.yLine_forX]),
      yScale_forZ: this.yScale3d_forZ([this.yLine_forZ])
    };
    this.processData(data, 1000);
  };

  private createCubeData(
    zone: RogerRatioZone,
    index: number,
    name: string
  ): void {
    const cube: ICube = this.formCube(zone.limits);

    cube.id = "cube_" + (index + 1);
    cube.height = zone.limits.maxY - zone.limits.minY;
    cube.x0 = zone.limits.minX;
    cube.x1 = zone.limits.maxX;
    cube.y0 = zone.limits.minY;
    cube.y1 = zone.limits.maxY;
    cube.z0 = zone.limits.minZ;
    cube.z1 = zone.limits.maxZ;
    cube.color = this.color(cube.id);
    cube.name = name;
    this.cubesData.push(cube);
  }

  private processData(data: IChartData, duration: number) {
    this.resetTraceColor();

    this.appendXZGrid(data);
    this.appendXYGrid(data);
    this.appendYZGrid(data);
    const { cubes, ce } = this.appendCubes(data);
    this.appendFaces(cubes, ce, duration);
    this.sortCubeFacesText(ce);
    this.appendTraceCircles(data, duration);
    this.makeLastTracePointBlack();
    this.appendXScale(data);
    this.appendXScaleText(data);
    this.appendYScale(data);
    this.appendYScaleText(data);
    this.appendZScale(data);
    this.appendZScaleText(data);
    this.appendXScaleTitle(data);
    this.appendYScaleTitle(data);
    this.appendZScaleTitle(data);
  }

  private appendXZGrid({ xzGrid3d }: IChartData): void {
    const xzGrid = this.xzGridGroup
      .selectAll("path.xzGrid")
      .data(xzGrid3d) as d3Selection<SVGPathElement>;
    xzGrid
      .enter()
      .append("path")
      .classed("xzGrid _3d", true)
      .merge(xzGrid)
      .attr("stroke", "black")
      .attr("stroke-width", 0.1)
      .attr("fill-opacity", 0)
      .attr("d", this.xzGrid3d.draw);
    xzGrid.exit().remove();
  }

  private appendXYGrid({ xyGrid3d }: IChartData): void {
    const xyGrid = this.xyGridGroup
      .selectAll("path.xyGrid")
      .data(xyGrid3d) as d3Selection<SVGPathElement>;
    xyGrid
      .enter()
      .append("path")
      .classed("xyGrid _3d", true)
      .merge(xyGrid)
      .attr("stroke", "black")
      .attr("stroke-width", 0.1)
      .attr("fill-opacity", 0)
      .attr("d", this.xyGrid3d.draw);
    xyGrid.exit().remove();
  }

  private appendYZGrid({ yzGrid3d }: IChartData): void {
    const yzGrid = this.yzGridGroup
      .selectAll("path.yzGrid")
      .data(yzGrid3d) as d3Selection<SVGPathElement>;
    yzGrid
      .enter()
      .append("path")
      .classed("yzGrid _3d", true)
      .merge(yzGrid)
      .attr("stroke", "black")
      .attr("stroke-width", 0.1)
      .attr("fill-opacity", 0)
      .attr("d", this.yzGrid3d.draw);
    yzGrid.exit().remove();
  }

  private appendCubes({ cubes3D }: IChartData): { cubes: any; ce: any } {
    const cubes = this.cubesGroup
      .selectAll("g.cube")
      .data(cubes3D, (d: II3dCubeData) => d.id) as d3Selection<SVGGElement>;
    const ce = cubes
      .enter()
      .append("g")
      .attr("class", "cube _3d")
      .attr("fill", (d: II3dCubeData) => this.color(d.id))
      .attr(
        "stroke",
        (d: II3dCubeData) => d3.color(this.color(d.id)).darker(2) as any
      )
      .attr("data-x0", (d: II3dCubeData) => d.x0)
      .attr("data-x1", (d: II3dCubeData) => d.x1)
      .attr("data-y0", (d: II3dCubeData) => d.y0)
      .attr("data-y1", (d: II3dCubeData) => d.y1)
      .attr("data-z0", (d: II3dCubeData) => d.z0)
      .attr("data-z1", (d: II3dCubeData) => d.z1)
      .attr("data-fill", (d: II3dCubeData) => this.color(d.id))
      .attr(
        "data-stroke",
        (d: II3dCubeData) => d3.color(this.color(d.id)).darker(2) as any
      )
      .merge(cubes)
      .sort(this.cubes3D.sort);
    cubes.exit().remove();

    return { cubes, ce };
  }

  private appendFaces(cubes: any, ce: any, duration: number): void {
    const faces = cubes
      .merge(ce)
      .selectAll("path.face")
      .data(
        (d: II3dCubeData) => d.faces,
        (d: I3dFace) => d.face
      );
    faces
      .enter()
      .append("path")
      .attr("class", "face")
      .attr("fill-opacity", 0.8)
      .classed("_3d", true)
      .merge(faces)
      .transition()
      .duration(duration)
      .attr("d", this.cubes3D.draw);
    faces.exit().remove();
  }

  private appendCubeText(cubes: any, ce: any, duration: number): void {
    const texts = cubes
      .merge(ce)
      .selectAll("text.text")
      .data((d: II3dCubeData) => {
        const _t = d.faces.filter((d: any) => d.face === "top");
        return [
          {
            height: d.height,
            centroid: _t[0].centroid,
            projected: _t[0][0].projected,
            rotated: _t[0][0].rotated
          }
        ];
      });

    texts
      .enter()
      .append("text")
      .attr("class", "text")
      .attr("dy", "-.7em")
      .attr("font-weight", "bolder")
      .classed("_3d", true)
      .merge(texts)
      .transition()
      .duration(duration)
      .attr("fill", "black")
      .attr("stroke", "none")
      .each((d: any) => {
        d.centroid = { x: d.rotated.x, y: d.rotated.y, z: d.rotated.z };
      })
      .attr("x", (d: any) => d.projected.x)
      .attr("y", (d: any) => d.projected.y)
      .tween("text", function (d: any) {
        const that = d3.select(this);
        const i = d3.interpolateNumber(+that.text(), Math.abs(d.height));
        return (t: number) => {
          that.text(i(t).toFixed(1));
        };
      });

    texts.exit().remove();
  }

  private sortCubeFacesText(ce: any): void {
    ce.selectAll("._3d").sort(_3d().sort);
  }

  private appendTraceCircles({ points3d }: IChartData, duration: number): void {
    const self = this;
    const trace = this.traceGroup
      .selectAll("circle")
      .data(
        points3d,
        (d: II3dCubeData) => d.id
      ) as d3Selection<SVGCircleElement>;

    const isPointOutOfRange = ({ x, y, z }: TracePoint) =>
      isEqual(x, 5) ||
      isEqual(x, 0) ||
      isEqual(y, -5) ||
      isEqual(y, 0) ||
      isEqual(z, 5) ||
      isEqual(z, 0);

    trace
      .enter()
      .append("circle")
      .attr("class", (d: TracePoint) =>
        !isPointOutOfRange(d) ? "_3d pointIsNotOutOfRange" : "_3d"
      )
      .attr("opacity", 0)
      .attr("cx", (d: any) => d.projected.x)
      .attr("cy", (d: any) => d.projected.y)
      .attr("data-tracepoint-index", (d: I3dDataCoords, index: number) => index)
      .on("mouseover", function (d: I3dDataCoords) {
        const selection = d3.select(this);
        self.handleOnTracePointMouseOver((d3 as any).event, d, selection);
      })
      .on("mouseout", () => this.handleOnTracepointMouseOut())
      .merge(trace)
      .transition()
      .duration(duration)
      .attr("r", 5)
      .attr("fill", (point: TracePoint) => {
        if (isPointOutOfRange(point)) {
          const pointIsOutOfRangeColor = "#c3c3c3";

          return pointIsOutOfRangeColor;
        } else {
          const rgb = this.getTraceColor();
          const color = this.state.traceColor;

          color[0] -= 10;
          color[1] -= 15;
          color[2] -= 10;
          this.setState({ traceColor: color });

          return rgb;
        }
      })
      .attr("opacity", 1)
      .attr("cx", (d: any) => d.projected.x)
      .attr("cy", (d: any) => d.projected.y);
    trace.exit().remove();
  }

  private makeLastTracePointBlack(): void {
    this.traceGroup
      .selectAll("circle.pointIsNotOutOfRange")
      .filter(
        (d: I3dDataCoords, i: number, list: any[]) => i === list.length - 1
      )
      .style("fill", "#000");
  }

  private appendXScale({ xScale }: IChartData): void {
    const xScalePath = this.scaleGroup
      .selectAll("path.xScale")
      .data(xScale) as d3Selection<SVGPathElement>;
    xScalePath
      .enter()
      .append("path")
      .attr("class", "_3d xScale")
      .merge(xScalePath)
      .attr("stroke", "black")
      .attr("stroke-width", 0.5)
      .attr("d", this.xScale3d.draw);
    xScalePath.exit().remove();
  }

  private appendXScaleText({ xScale }: IChartData): void {
    const xText = this.scaleGroup
      .selectAll("text.xText")
      .data(xScale[0]) as d3Selection<SVGTextElement>;
    xText
      .enter()
      .append("text")
      .attr("class", "_3d xText")
      .attr("dx", ".3em")
      .merge(xText)
      .each((d: any) => {
        d.centroid = { x: d.rotated.x, y: d.rotated.y, z: d.rotated.z };
      })
      .attr("x", (d: any) => d.projected.x)
      .attr("y", (d: any) => d.projected.y)
      .text((d: any) => {
        let str = "";
        if (d[0] <= this.ticksNumber) str += " - " + d[0];
        return str;
      });
    xText.exit().remove();
  }

  private appendYScale({ yScale }: IChartData): void {
    const yScalePath = this.scaleGroup
      .selectAll("path.yScale")
      .data(yScale) as d3Selection<SVGPathElement>;
    yScalePath
      .enter()
      .append("path")
      .attr("class", "_3d yScale")
      .merge(yScalePath)
      .attr("stroke", "black")
      .attr("stroke-width", 0.5)
      .attr("d", this.yScale3d.draw);
    yScalePath.exit().remove();
  }

  private appendYScaleText({ yScale }: IChartData): void {
    const yText = this.scaleGroup
      .selectAll("text.yText")
      .data(yScale[0]) as d3Selection<SVGTextElement>;
    yText
      .enter()
      .append("text")
      .attr("class", "_3d yText")
      .attr("dx", ".3em")
      .merge(yText)
      .each((d: any) => {
        d.centroid = { x: d.rotated.x, y: d.rotated.y, z: d.rotated.z };
      })
      .attr("x", (d: any) => d.projected.x - 40)
      .attr("y", (d: any) => d.projected.y)
      .text((d: any) => {
        let str = "";
        if (d[1] < 0) str += Math.abs(d[1]) + " - ";
        return str;
      });
    yText.exit().remove();
  }

  private appendZScale({ zScale }: IChartData): void {
    const zScalePath = this.scaleGroup
      .selectAll("path.zScale")
      .data(zScale) as d3Selection<SVGPathElement>;
    zScalePath
      .enter()
      .append("path")
      .attr("class", "_3d zScale")
      .merge(zScalePath)
      .attr("stroke", "black")
      .attr("stroke-width", 0.5)
      .attr("d", this.zScale3d.draw);
    zScalePath.exit().remove();
  }

  private appendZScaleText({ zScale }: IChartData): void {
    const zText = this.scaleGroup
      .selectAll("text.zText")
      .data(zScale[0]) as d3Selection<SVGTextElement>;
    zText
      .enter()
      .append("text")
      .attr("class", "_3d zText")
      .attr("dx", ".3em")
      .merge(zText)
      .each((d: any) => {
        d.centroid = { x: d.rotated.x, y: d.rotated.y, z: d.rotated.z };
      })
      .attr("x", (d: any) => d.projected.x - 40)
      .attr("y", (d: any) => d.projected.y)
      .text((d: any) => {
        let str = "";
        if (d[2] <= this.ticksNumber) str += d[2] + " - ";
        return str;
      });
    zText.exit().remove();
  }

  private appendXScaleTitle({ yScale_forX }: IChartData): void {
    const yScaleForXPath = this.scaleGroup
      .selectAll("path.yScale_forX")
      .data(yScale_forX) as d3Selection<SVGPathElement>;

    yScaleForXPath
      .enter()
      .append("path")
      .attr("class", "_3d yScale_forX")
      .merge(yScaleForXPath)
      .attr("d", this.yScale3d_forX.draw);
    yScaleForXPath.exit().remove();

    const xScaleBBox = (d3.select("path.xScale") as d3Selection<SVGPathElement>)
      .node()
      .getBBox();

    const display = this.state.orient2 ? "none" : "block";
    const xTitle = this.scaleGroup
      .selectAll("text.xTitle")
      .data(yScale_forX) as d3Selection<SVGTextElement>;

    xTitle
      .enter()
      .append("text")
      .attr("class", "_3d xTitle")
      .merge(xTitle)
      .attr("x", xScaleBBox.x + xScaleBBox.width / 2 + 100)
      .attr("y", xScaleBBox.y + xScaleBBox.height / 2)
      .text("\xa0\xa0\xa0\xa0 CH\u2084 / H\u2082")
      .style("display", display);
    xTitle.exit().remove();
  }

  private appendYScaleTitle({ yScale }: IChartData): void {
    const yScaleBBox = (d3.select(".yScale").node() as any).getBBox();

    const display = this.state.orient3 ? "none" : "block";
    const yTitlePath = this.scaleGroup
      .selectAll("text.yTitle")
      .data(yScale) as d3Selection<SVGTextElement>;

    yTitlePath
      .enter()
      .append("text")
      .attr("class", "_3d yTitle")
      .merge(yTitlePath)
      .attr("x", yScaleBBox.x - 150)
      .attr("y", yScaleBBox.y + yScaleBBox.height / 2)
      .text("C\u2082H\u2082 / C\u2082H\u2084 \xa0\xa0\xa0\xa0")
      .style("display", display);
    yTitlePath.exit().remove();
  }

  private appendZScaleTitle({ yScale_forZ }: IChartData): void {
    const yScaleForZPath = this.scaleGroup
      .selectAll("path.yScale_forZ")
      .data(yScale_forZ) as d3Selection<SVGPathElement>;

    yScaleForZPath
      .enter()
      .append("path")
      .attr("class", "_3d yScale_forZ")
      .merge(yScaleForZPath)
      .attr("d", this.yScale3d_forZ.draw)
      .exit()
      .remove();

    const zScaleBBox = (d3.select("path.zScale") as d3Selection<SVGPathElement>)
      .node()
      .getBBox();

    const display = this.state.orient1 ? "none" : "block";
    const zTitle = this.scaleGroup
      .selectAll("text.zTitle")
      .data(yScale_forZ) as d3Selection<SVGTextElement>;

    zTitle
      .enter()
      .append("text")
      .attr("class", "_3d zTitle")
      .merge(zTitle)
      .attr("x", zScaleBBox.x + zScaleBBox.width / 2 - 150)
      .attr("y", zScaleBBox.y + zScaleBBox.height / 2)
      .text("C\u2082H\u2084 / C\u2082H\u2086 \xa0\xa0\xa0\xa0")
      .style("display", display);
    zTitle.exit().remove();
  }

  private buildTrace() {
    const { tracePoints, showOfflineOnly } = this.props;
    this.traceData = [];

    (showOfflineOnly
      ? tracePoints.OfflinePoints
      : tracePoints.OnlinePoints
    ).forEach((point: TracePoint) => {
      const tracePoint = this.calculateTracePointsCoords(point);

      this.traceData.push(tracePoint);
    });
  }

  private calculateTracePointsCoords(
    point: TracePoint
  ): ICalculatedTracePointCoords {
    const tracePoint: ICalculatedTracePointCoords = { ...point };
    const maxAxisValue: number = 5;
    const minAxisValue: number = 0;

    const setValueDependingOnAxis = (axis: string, value: number | string) =>
      isEqual(axis, "y") ? -value : value;

    for (const [pointAxis, pointAxisValue] of Object.entries(point)) {
      if (pointAxisValue > maxAxisValue) {
        tracePoint[pointAxis] = setValueDependingOnAxis(
          pointAxis,
          maxAxisValue
        );
      } else if (pointAxisValue < minAxisValue) {
        tracePoint[pointAxis] = minAxisValue;
      } else {
        tracePoint[pointAxis] = setValueDependingOnAxis(
          pointAxis,
          pointAxisValue
        );
      }
    }

    return tracePoint;
  }

  private orient1 = (): void => {
    this.setState({ orient1: true, orient2: false, orient3: false });
    const y = 0;
    const x = 0;
    const data = {
      xzGrid3d: this.xzGrid3d.rotateY(y).rotateX(x)(this.xzGrid),
      xyGrid3d: this.xyGrid3d.rotateY(y).rotateX(x)(this.xyGrid),
      yzGrid3d: this.yzGrid3d.rotateY(y).rotateX(x)(this.yzGrid),
      cubes3D: this.cubes3D.rotateY(y).rotateX(x)(this.cubesData),
      points3d: this.points3d.rotateY(y).rotateX(x)(this.traceData),
      xScale: this.xScale3d.rotateY(y).rotateX(x)([this.xLine]),
      yScale: this.yScale3d.rotateY(y).rotateX(x)([this.yLine]),
      zScale: this.zScale3d.rotateY(y).rotateX(x)([this.zLine]),
      yScale_forX: this.yScale3d_forX.rotateY(y).rotateX(x)([this.yLine_forX]),
      yScale_forZ: this.yScale3d_forZ.rotateY(y).rotateX(x)([this.yLine_forZ])
    };
    this.processData(data, 0);
  };

  private orient2 = (): void => {
    this.setState({ orient1: false, orient2: true, orient3: false });
    const y = 1.5708; // 90deg in radians
    const x = 0;
    const data = {
      xzGrid3d: this.xzGrid3d.rotateY(y).rotateX(x)(this.xzGrid),
      xyGrid3d: this.xyGrid3d.rotateY(y).rotateX(x)(this.xyGrid),
      yzGrid3d: this.yzGrid3d.rotateY(y).rotateX(x)(this.yzGrid),
      cubes3D: this.cubes3D.rotateY(y).rotateX(x)(this.cubesData),
      points3d: this.points3d.rotateY(y).rotateX(x)(this.traceData),
      xScale: this.xScale3d.rotateY(y).rotateX(x)([this.xLine]),
      yScale: this.yScale3d.rotateY(y).rotateX(x)([this.yLine]),
      zScale: this.zScale3d.rotateY(y).rotateX(x)([this.zLine]),
      yScale_forX: this.yScale3d_forX.rotateY(y).rotateX(x)([this.yLine_forX]),
      yScale_forZ: this.yScale3d_forZ.rotateY(y).rotateX(x)([this.yLine_forZ])
    };
    this.processData(data, 0);
  };

  private orient3 = (): void => {
    this.setState({ orient1: false, orient2: false, orient3: true });
    const y = 0;
    const x = 1.5708; // 90deg in radians;
    const data = {
      xzGrid3d: this.xzGrid3d.rotateY(y).rotateX(x)(this.xzGrid),
      xyGrid3d: this.xyGrid3d.rotateY(y).rotateX(x)(this.xyGrid),
      yzGrid3d: this.yzGrid3d.rotateY(y).rotateX(x)(this.yzGrid),
      cubes3D: this.cubes3D.rotateY(y).rotateX(x)(this.cubesData),
      points3d: this.points3d.rotateY(y).rotateX(x)(this.traceData),
      xScale: this.xScale3d.rotateY(y).rotateX(x)([this.xLine]),
      yScale: this.yScale3d.rotateY(y).rotateX(x)([this.yLine]),
      zScale: this.zScale3d.rotateY(y).rotateX(x)([this.zLine]),
      yScale_forX: this.yScale3d_forX.rotateY(y).rotateX(x)([this.yLine_forX]),
      yScale_forZ: this.yScale3d_forZ.rotateY(y).rotateX(x)([this.yLine_forZ])
    };
    this.processData(data, 0);
  };

  private handleOnTracePointMouseOver = (
    { pageX, pageY }: any,
    d: I3dDataCoords,
    selection: any
  ) => {
    const indexTracePoint = selection.attr("data-tracepoint-index");
    const tracePoint = this.getTracepointByIndex(indexTracePoint);
    const tracePointCoords = this.getTracepointCoordsByIndex(indexTracePoint);

    this.resetRatioZonesActivity();
    this.markRatioZoneActive(tracePointCoords);

    this.tooltipDiv.transition().duration(200).style("display", "");
    this.tooltipDiv
      .html(this.getHtmlForTracePoint(tracePoint))
      .style("left", pageX + 15 + "px")
      .style("top", pageY + 15 + "px");
  };

  private markRatioZoneActive({ x, y, z }: ITracePointCoords): void {
    const self = this;
    this.cubesGroup.selectAll(".cube._3d").each(function () {
      const selection = d3.select(this) as d3Selection<SVGGElement>;
      const { x0, x1, y0, y1, z0, z1 } =
        self.parseCubeDataAttributes(selection);
      if (x >= x0 && x <= x1 && -y >= y0 && -y <= y1 && z >= z0 && z <= z1) {
        selection.attr("fill", "#ABC3FF");
        self.setState({
          ...self.state
        });
      } else {
        selection.attr("fill", "#BABABA");
      }
    });
  }

  private parseCubeDataAttributes(
    cubeGroupingElementSelection: d3Selection<SVGGElement>
  ): {
    x0: Number;
    x1: Number;
    y0: Number;
    y1: Number;
    z0: Number;
    z1: Number;
  } {
    return {
      x0: Number(cubeGroupingElementSelection.attr("data-x0")),
      x1: Number(cubeGroupingElementSelection.attr("data-x1")),
      y0: Number(cubeGroupingElementSelection.attr("data-y0")),
      y1: Number(cubeGroupingElementSelection.attr("data-y1")),
      z0: Number(cubeGroupingElementSelection.attr("data-z0")),
      z1: Number(cubeGroupingElementSelection.attr("data-z1"))
    };
  }

  private resetRatioZonesActivity() {
    this.cubesGroup.selectAll(".cube._3d").each(function () {
      const selection = d3.select(this) as d3Selection<SVGGElement>;
      const originalStroke = selection.attr("data-stroke");
      const orignalFill = selection.attr("data-fill");
      selection.attr("stroke", originalStroke);
      selection.attr("fill", orignalFill);
    });
  }

  private getHtmlForTracePoint({ date, x, y, z }: TracePoint): string {
    return `
    <div class="rogers-ratio-tooltip__header">${this.props.intl.formatDate(
      date
    )}</div>
    <table>
      <tbody>
        <tr>
          <td class="rogers-ratio-tooltip__left">CH4/H2</td>
          <td class="rogers-ratio-tooltip__right">${x.toFixed(2)}</td>
        </tr>
        <tr>
          <td class="rogers-ratio-tooltip__left">C2H4/C2H6</td>
          <td class="rogers-ratio-tooltip__right">${z.toFixed(2)}</td>
        </tr>
        <tr>
          <td class="rogers-ratio-tooltip__left">C2H2/C2H4</td>
          <td class="rogers-ratio-tooltip__right">${y.toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
    `;
  }

  private getTracepointByIndex(index: number): TracePoint {
    const { tracePoints, showOfflineOnly } = this.props;

    return showOfflineOnly
      ? tracePoints.OfflinePoints[index]
      : tracePoints.OnlinePoints[index];
  }

  private getTracepointCoordsByIndex(index: number): ITracePointCoords {
    return this.traceData[index];
  }

  private handleOnTracepointMouseOut(): void {
    this.resetRatioZonesActivity();
    this.hideTooltip();
  }

  private handleDragStart = (): void => {
    this.hideTooltip();
    this.mx = (d3 as any).event.x;
    this.my = (d3 as any).event.y;
  };

  private handleDragged = (): void => {
    this.setState({ orient1: false });
    this.setState({ orient2: false });
    this.setState({ orient3: false });
    this.mouseX = this.mouseX || 0;
    this.mouseY = this.mouseY || 0;
    this.beta = (((d3 as any).event.x - this.mx + this.mouseX) * Math.PI) / 230;
    this.alpha =
      ((((d3 as any).event.y - this.my + this.mouseY) * Math.PI) / 230) * -1;
    if (this.alpha > 0.525)
      // upper drag limit
      this.alpha = 0.525;
    else if (this.alpha < -1.05)
      // lower drag limit
      this.alpha = -1.05;
    const data = {
      xzGrid3d: this.xzGrid3d
        .rotateY(this.beta + this.startAngle)
        .rotateX(this.alpha - this.startAngle)(this.xzGrid),
      xyGrid3d: this.xyGrid3d
        .rotateY(this.beta + this.startAngle)
        .rotateX(this.alpha - this.startAngle)(this.xyGrid),
      yzGrid3d: this.yzGrid3d
        .rotateY(this.beta + this.startAngle)
        .rotateX(this.alpha - this.startAngle)(this.yzGrid),
      cubes3D: this.cubes3D
        .rotateY(this.beta + this.startAngle)
        .rotateX(this.alpha - this.startAngle)(this.cubesData),
      points3d: this.points3d
        .rotateY(this.beta + this.startAngle)
        .rotateX(this.alpha - this.startAngle)(this.traceData),
      xScale: this.xScale3d
        .rotateY(this.beta + this.startAngle)
        .rotateX(this.alpha - this.startAngle)([this.xLine]),
      yScale: this.yScale3d
        .rotateY(this.beta + this.startAngle)
        .rotateX(this.alpha - this.startAngle)([this.yLine]),
      zScale: this.zScale3d
        .rotateY(this.beta + this.startAngle)
        .rotateX(this.alpha - this.startAngle)([this.zLine]),
      yScale_forX: this.yScale3d_forX
        .rotateY(this.beta + this.startAngle)
        .rotateX(this.alpha - this.startAngle)([this.yLine_forX]),
      yScale_forZ: this.yScale3d_forZ
        .rotateY(this.beta + this.startAngle)
        .rotateX(this.alpha - this.startAngle)([this.yLine_forZ])
    };
    this.processData(data, 0);
    const angle = 90;
    if (this.mouseY > angle) this.mouseY = angle;
    else if (this.mouseY < -angle) this.mouseY = -angle;
  };

  private handleDragEnd = (): void => {
    this.mouseX = ((d3 as any).event as any).x - this.mx + this.mouseX;
    this.mouseY = ((d3 as any).event as any).y - this.my + this.mouseY;
  };

  private formCube(l: RogerRatioCoords): Array<ICubeCoords> {
    return [
      // bottom square:
      { x: l.minX, y: -l.minY, z: l.minZ },
      { x: l.maxX, y: -l.minY, z: l.minZ },
      { x: l.maxX, y: -l.minY, z: l.maxZ },
      { x: l.minX, y: -l.minY, z: l.maxZ },
      // top square:
      { x: l.minX, y: -l.maxY, z: l.minZ },
      { x: l.maxX, y: -l.maxY, z: l.minZ },
      { x: l.maxX, y: -l.maxY, z: l.maxZ },
      { x: l.minX, y: -l.maxY, z: l.maxZ }
    ];
  }

  private getTraceColor(): string {
    return (
      "rgb(" +
      this.state.traceColor[0] +
      ", " +
      this.state.traceColor[1] +
      ", " +
      this.state.traceColor[2] +
      ")"
    );
  }

  private resetTraceColor(): void {
    this.setState(() => ({
      traceColor: [75, 136, 255]
    }));
  }

  private appendTooltipDiv(): void {
    this.tooltipDiv = d3
      .select(this.divRef)
      .append("div")
      .attr("class", "rogers-ratio-tooltip")
      .style("display", "none");
  }

  private hideTooltip(): void {
    this.tooltipDiv.transition().duration(500).style("display", "none");
  }
}

export default injectIntl(RogerRatio);
