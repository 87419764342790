// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Action, Dispatch } from "redux";

import { IRawQueryParams } from "core/app/reducers/LocationReducer";

export enum Actions {
  Location_SetQueryParams = "Location_SetQueryParams"
}

export interface IActionSetQueryParams extends Action {
  rawQueryParams: IRawQueryParams;
}

const setQueryParamsAction = (
  rawQueryParams: IRawQueryParams
): IActionSetQueryParams => {
  const action: IActionSetQueryParams = {
    type: Actions.Location_SetQueryParams,
    rawQueryParams
  };
  return action;
};

export const setQueryParams = (
  dispatch: Dispatch<Action>,
  rawQueryParams: IRawQueryParams
) => {
  dispatch(setQueryParamsAction(rawQueryParams));
};
