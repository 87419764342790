// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import {
  ILine,
  IParameter,
  IRegion,
  IThresholds
} from "@apm/widgets/build/widgets/ParametersTrend";
import Data, { Statuses } from "core/data/models/Data";
import EndpointService from "core/data/services/EndpointService";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import IAssetParameter from "../models/IAssetParameter";
import ISelectedParameter from "../models/ISelectedParameter";
import getParameterUrl from "../utils/getParameterUrl";

interface IParameterInfo {
  parameter: IParameter;
  thresholds: IThresholds;
}

interface ParameterData {
  parameterName: string;
  data: Data<IParameterInfo>;
}

interface IUpdateParameterOptions {
  parameterName: string;
  request: JQuery.jqXHR;
  parameter?: IParameter;
  thresholds?: IThresholds;
}

const useParametersChart = (
  assetId: string,
  selectedParameters: ISelectedParameter[] | null | undefined
) => {
  const intl = useIntl();
  const parametersData = useRef<ParameterData[]>([]);
  const [parameters, setParameters] = useState<Data<IParameterInfo>[]>();

  const formatXTick = useCallback(
    (date: Date) => intl.formatDate(date),
    [intl]
  );

  const formatYTick = useCallback(
    (value: number) => intl.formatNumber(value),
    [intl]
  );

  const formatTooltipTitle = useCallback(
    (date: Date) =>
      intl.formatDate(date, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      }),
    [intl]
  );

  const formatTooltipValue = useCallback(
    (value: number, unit: string | null | undefined) => {
      return !!unit
        ? intl.formatMessage(
            {
              defaultMessage: "{value} {unit}",
              id: "detailpage.parameter_info.value"
            },
            {
              value: intl.formatNumber(value),
              unit
            }
          )
        : intl.formatNumber(value);
    },
    [intl]
  );

  const updateParameters = useCallback(
    (
      parametersData: ParameterData[],
      selectedParameters: ISelectedParameter[] | null | undefined
    ) => {
      const currentParameters = parametersData
        ?.filter(
          (p) =>
            selectedParameters?.findIndex((s) => s.name === p.parameterName) >=
            0
        )
        ?.map((p) => p.data);
      setParameters(currentParameters);
    },
    []
  );

  const status = useMemo(() => {
    const statuses = parameters?.map((x) => x.status);
    const hasLoading = statuses?.findIndex((s) => s === Statuses.Loading) >= 0;
    const hasSucceeded =
      statuses?.findIndex((s) => s === Statuses.Succeeded) >= 0;
    const hasFailed = statuses?.findIndex((s) => s === Statuses.Failed) >= 0;

    return hasLoading
      ? Statuses.Loading
      : hasFailed
      ? Statuses.Failed
      : hasSucceeded
      ? Statuses.Succeeded
      : null;
  }, [parameters]);

  const emptyTranslation = useMemo(
    () =>
      intl.formatMessage({
        id: "global.chart.no_data_selected",
        defaultMessage: "No data selected"
      }),
    [intl]
  );

  const titleTranslation = useMemo(
    () =>
      intl.formatMessage({
        id: "detail_page.widgets.parameters.parameters_chart_modal.header",
        defaultMessage: "Parameter Trending"
      }),
    [intl]
  );

  useEffect(() => {
    const updateParametersData = ({
      parameterName,
      request,
      parameter,
      thresholds
    }: IUpdateParameterOptions) => {
      const i = parametersData.current?.findIndex(
        (p) => p.parameterName === parameterName
      );
      const newParameters = parametersData ? [...parametersData.current] : [];

      if (i < 0) {
        newParameters.push({
          parameterName: parameterName,
          data: new Data(request, {
            parameter,
            thresholds
          })
        });
      } else {
        newParameters[i] = {
          ...newParameters[i],
          data: new Data(request, {
            parameter,
            thresholds
          })
        };
      }
      parametersData.current = newParameters;
    };

    const onFullfiled = (results: IUpdateParameterOptions[]) => {
      results.forEach((r) => {
        updateParametersData(r);
      });
      updateParameters(parametersData.current, selectedParameters);
    };

    const promises: Promise<IUpdateParameterOptions>[] = [];
    selectedParameters?.forEach((parameter) => {
      const shouldBeLoaded = !(
        parametersData.current?.findIndex(
          (p) => p.parameterName === parameter.name
        ) >= 0
      );

      if (shouldBeLoaded) {
        const promise = new Promise<IUpdateParameterOptions>((resolve) => {
          const url = getParameterUrl(
            assetId,
            parameter.name,
            parameter.parameterSource
          );
          const request = EndpointService.getJson<IAssetParameter>(
            url,
            (request, data) => {
              resolve({
                parameterName: parameter.name,
                request,
                parameter: {
                  name: parameter.name,
                  displayName: parameter.displayName,
                  trend: data.Trend,
                  unit: parameter.unit
                },
                thresholds: {
                  lines: data.Lines?.map<ILine>((l) => ({
                    name: l.Name,
                    displayName: l.Name,
                    value: l.Value,
                    color: l.Color
                  })),
                  regions: data.Regions?.map<IRegion>((r) => ({
                    name: r.Name,
                    displayName: r.Name,
                    start: r.Start,
                    end: r.End,
                    color: r.Color
                  }))
                }
              });
            },
            (request) => {
              resolve({
                parameterName: parameter.name,
                request
              });
            }
          );

          updateParametersData({
            parameterName: parameter.name,
            request
          });
        });
        promises.push(promise);
      }
    });

    updateParameters(parametersData.current, selectedParameters);
    Promise.all(promises).then(onFullfiled);
  }, [assetId, selectedParameters, updateParameters]);

  return {
    formatXTick,
    formatYTick,
    formatTooltipTitle,
    formatTooltipValue,
    emptyTranslation,
    status,
    parameters,
    titleTranslation
  };
};

export default useParametersChart;
