// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { ITrendChart } from "common/chart/components/TrendLineChart";
import { isEmpty } from "lodash";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import DielectricContext from "../../../contexts/DielectricContext";
import { IModelDetails } from "../../DielectricTab/DielectricTab";
import loadDielectricTimedPressure from "../actions/loadDielectricTimedPressure";
import getDielectricTimedPressureSelector from "../selectors/getDielectricTimedPressureSelector";
import useSF6PressureChartProps from "./useSF6PressureChartProps";
import useSF6PressureTranslations from "./useSF6PressureTranslations";

const useSF6PressureTimedChart = (model: IModelDetails) => {
  const { emptyTranslation } = useSF6PressureTranslations();
  const { assetId } = useContext(DielectricContext);

  const dispatch = useDispatch();

  const dielectric = useSelector(getDielectricTimedPressureSelector);

  const trendChart = useMemo(() => {
    const { data } = dielectric;
    const reading: ITrendChart = {
      DataSeries: {},
      Regions: [],
      Lines: null,
      Unit: ""
    };

    if (!isEmpty(data)) {
      const { DataSeries, Lines, Regions, Unit } = data;
      reading.DataSeries = DataSeries || {};
      reading.Lines = Lines || [];
      reading.Regions = Regions || [];
      reading.Unit = Unit || "";
    }

    return reading;
  }, [dielectric]);

  const loadData = useCallback(async () => {
    await loadDielectricTimedPressure(dispatch, assetId);
  }, [dispatch, assetId]);

  const {
    formatTooltipTitle,
    formatXTick,
    height,
    lines,
    regions,
    series,
    title
  } = useSF6PressureChartProps(model, trendChart);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dielectric,
    series,
    regions,
    lines,
    formatXTick,
    formatTooltipTitle,
    title,
    height,
    emptyTranslation
  };
};

export default useSF6PressureTimedChart;
