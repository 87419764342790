// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { DatePicker, Form } from "antd";
import { TypedValue } from "features/detailpage/models/IParameter";
import { isNil } from "lodash";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { FormattedDate, useIntl } from "react-intl";
import styled from "styled-components";
import {
  spacingMedium,
  spacingSmall,
  spacingXLarge
} from "styles/StyleVariables";
import isValidDateString from "utils/isValidDateString";
import AssetModalContext from "../contexts/AssetModalContext";
import FormContext from "../contexts/FormContext";
import { IFormItem } from "../hooks/useForms";
import IField from "../models/IField";
import IFormValues from "../models/IFormValues";
import { dateFormat, disableFutureDates } from "../utils/datePicker";
import CustomSections from "./CustomSections";

interface IPreloadedInspectionProps {
  className?: string;
  formInfo: IFormItem;
}

const PreloadedInspection = ({
  className,
  formInfo
}: IPreloadedInspectionProps) => {
  const intl = useIntl();

  const {
    getFormInfo,
    registerForm,
    updateFormItemTouchedInfo,
    parameters,
    validateMessages,
    changeInspectionField,
    changeInspectionDateForFields
  } = useContext(AssetModalContext);
  const form = Form.useForm<IFormValues>(
    getFormInfo(formInfo.formKey)?.form
  )[0];

  const inspectionDateKey = useMemo(() => {
    return `DateOfInspection_${formInfo.formKey}`;
  }, [formInfo.formKey]);

  useEffect(() => {
    registerForm(formInfo.formKey, form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    if (formInfo.invalid) {
      form.validateFields();
    }
  }, [form, formInfo.invalid]);

  const sectionFields = useMemo(() => {
    const fields: IField[] = [];
    formInfo.formConfiguration.sections.forEach((sectionElement) => {
      if (sectionElement.fields) fields.push(...sectionElement.fields);
      if (sectionElement.sectionList)
        sectionElement.sectionList.forEach((section) =>
          fields.push(...section.fields)
        );
    });
    return fields;
  }, [formInfo.formConfiguration.sections]);

  const dataTypes: { [name: string]: TypedValue } = useMemo(() => {
    const types: { [name: string]: TypedValue } = {};
    sectionFields.forEach((field) => {
      types[field.fieldKey] = field.dataType;
    });
    return types;
  }, [sectionFields]);

  const handleValuesChange = useCallback(
    (changedValues: Partial<IFormValues>, values: IFormValues) => {
      Object.keys(changedValues || {}).forEach((key) => {
        if (key === inspectionDateKey) {
          changeInspectionDateForFields(
            changedValues[key] as Date,
            Object.keys(values).filter((k) => k !== inspectionDateKey)
          );
        } else {
          changeInspectionField(
            key,
            dataTypes[key] === "Bool" && !isNil(changedValues[key])
              ? changedValues[key] === "true"
              : changedValues[key],
            dataTypes[key],
            values[inspectionDateKey] as Date
          );
        }
        updateFormItemTouchedInfo(formInfo.formKey);
      });
    },
    [
      changeInspectionDateForFields,
      changeInspectionField,
      updateFormItemTouchedInfo,
      formInfo.formKey,
      inspectionDateKey,
      dataTypes
    ]
  );

  const maxDate = useMemo(() => {
    if (!isNil(parameters)) {
      const dateValues = Object.values(parameters)
        .filter((p) =>
          sectionFields.map((f) => f.fieldKey).includes(p.InputName)
        )
        .filter((p) => !isNil(p) && !isNil(p.Timestamp))
        .map((p) => new Date(p.Timestamp));

      if (dateValues.length > 0)
        return new Date(Math.max.apply(null, dateValues));
    }
  }, [parameters, sectionFields]);

  const fieldIsRequired = useCallback(
    (fieldName: string) => {
      return !isNil(parameters) &&
        !isNil(parameters[fieldName]) &&
        parameters[fieldName].Value
        ? true
        : false;
    },
    [parameters]
  );

  const getInitialValue = useCallback(
    (parameterName: string) => {
      if (isNil(parameters) || isNil(parameters[parameterName])) return null;

      if (dataTypes[parameterName] === "Bool") {
        return parameters[parameterName].Value.toString();
      } else if (dataTypes[parameterName] === "DateTime") {
        if (isValidDateString(parameters[parameterName].Value))
          return moment(parameters[parameterName].Value.toString());
        else return null;
      }

      return parameters[parameterName].Value;
    },
    [parameters, dataTypes]
  );

  const datePickerModificationDate =
    formInfo.formConfiguration.datePickerLabel?.defaultMessage &&
    formInfo.formConfiguration.datePickerLabel?.id &&
    formInfo.formConfiguration.showLastModificationDate &&
    "date-picker-modification-date";

  return (
    <div className={className}>
      {formInfo.formConfiguration.showLastModificationDate && (
        <div className={`labeled-row ${datePickerModificationDate}`}>
          <span className="labeled-label">
            {intl.formatMessage({
              id: "configuration_tool.label.last_modification_date",
              defaultMessage: "Date of last modification"
            })}
            :
          </span>
          <span className="value">
            {maxDate && (
              <FormattedDate
                value={maxDate}
                day="numeric"
                month="numeric"
                year="numeric"
                hour="numeric"
                minute="numeric"
                second="numeric"
              />
            )}
          </span>
        </div>
      )}
      <Form
        form={form}
        layout="horizontal"
        onValuesChange={handleValuesChange}
        colon={false}
        requiredMark={false}
        validateMessages={validateMessages}
      >
        {formInfo.formConfiguration.datePickerLabel?.id &&
          formInfo.formConfiguration.datePickerLabel?.defaultMessage && (
            <Form.Item
              label={intl.formatMessage({
                ...formInfo.formConfiguration.datePickerLabel
              })}
              className="date-of-inspection"
              name={inspectionDateKey}
              rules={[{ required: true }]}
            >
              <DatePicker
                disabledDate={disableFutureDates}
                format={dateFormat(intl)}
                data-qa="InspectionDate"
              />
            </Form.Item>
          )}
        <FormContext.Provider value={form}>
          <CustomSections
            configuration={formInfo.formConfiguration.sections}
            fieldIsRequired={fieldIsRequired}
            getInitialValue={getInitialValue}
          />
        </FormContext.Provider>
      </Form>
    </div>
  );
};

const StyledPreloadedInspection = styled(PreloadedInspection)`
  .labeled-row {
    text-align: right;
    margin-right: ${spacingMedium};
    margin-bottom: ${spacingXLarge};
  }

  .date-picker-modification-date {
    margin-top: ${spacingSmall};
    width: 400px;
    float: right;
  }

  .labeled-label {
    text-transform: uppercase;
  }
`;

export default StyledPreloadedInspection;
