// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { IRoutableTab } from "common/RoutableTabs";

import BatteryConductanceTab from "features/detailpage/features/batteryconductance/components/BatteryConductanceTab";
import BatteryVoltageTab from "features/detailpage/features/batteryvoltage/components/BatteryVoltageTab";

import {
  IVisualizationInfoDictionary,
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";

interface IUseBatteryTabsOptions {
  batteryAvailable: boolean;
  assetId: string;
}

const useBatteryTabs = ({
  batteryAvailable,
  assetId
}: IUseBatteryTabsOptions) => {
  const intl = useIntl();
  const { getAvailable } = useFeature();

  const visualizations: IVisualizationInfoDictionary<IRoutableTab> = useMemo(() => {
    return {
      [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Battery_Conductance]: {
        Content: () => <BatteryConductanceTab assetId={assetId} />,
        id: "Conductance",
        name: intl.formatMessage({
          id: "detail_page.widgets.conductance",
          defaultMessage: "Conductance"
        })
      },
      [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Battery_Voltage]: {
        Content: () => <BatteryVoltageTab assetId={assetId} />,
        id: "Voltage",
        name: intl.formatMessage({
          id: "detail_page.widgets.voltage",
          defaultMessage: "Voltage"
        })
      }
    };
  }, [assetId, intl]);

  const batteryTabs: IRoutableTab[] = useMemo(() => {
    if (batteryAvailable) {
      return getAvailable(visualizations);
    }

    return [];
  }, [batteryAvailable, visualizations, getAvailable]);

  return batteryTabs;
};

export default useBatteryTabs;
