// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";

type ContainerWrapperProps = {
  children: React.ReactNode;
};

const ContainerWrapper = ({ children }: ContainerWrapperProps): JSX.Element => (
  <div className="asset-risk asset-risk-dashboard-page">
    <div className="container">{children}</div>
  </div>
);

export default ContainerWrapper;
