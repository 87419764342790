// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import getNotificationsFormSelector, {
  NotificationsFormFields
} from "./getNotificationsFormSelector";

const hasValidDataSelector = createSelector(
  getNotificationsFormSelector,
  form => {
    if (form.inputs.size) {
      const degradedToRed: boolean = form.getIn([
        "inputs",
        NotificationsFormFields.degradesRed,
        "value"
      ]);
      const degradedToYellow: boolean = form.getIn([
        "inputs",
        NotificationsFormFields.degradesYellow,
        "value"
      ]);
      const improvedToGreen: boolean = form.getIn([
        "inputs",
        NotificationsFormFields.improvesGreen,
        "value"
      ]);
      const improvedToYellow: boolean = form.getIn([
        "inputs",
        NotificationsFormFields.improvesYellow,
        "value"
      ]);

      const organizations: string[] = form.getIn([
        "inputs",
        NotificationsFormFields.organizations,
        "value"
      ]);

      const assetTypes: string[] = form.getIn([
        "inputs",
        NotificationsFormFields.assetTypes,
        "value"
      ]);

      return (
        (degradedToRed ||
          degradedToYellow ||
          improvedToGreen ||
          improvedToYellow) &&
        organizations.length > 0 &&
        assetTypes.length > 0
      );
    }

    return false;
  }
);

export default hasValidDataSelector;
