// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

export const dataKey = `MainDashboard_AssetRiskTrend`;

const loadAssetRiskTrend = () => {
  const url = UrlService.getApiUrl(config.api.homepage.assetRiskTrend);
  return loadData({ key: dataKey, url, method: "get" });
};

export default loadAssetRiskTrend;
