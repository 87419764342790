// Copyright 2016-2021 Hitachi Power Grids. All rights reserved.
import { DatePicker } from "antd";
import { PickerProps } from "antd/lib/date-picker/generatePicker";
import { CommonPickerMethods } from "antd/lib/date-picker/generatePicker/interface";
import Form from "antd/lib/form";
import IField from "features/ConfigurationTool/models/IField";
import {
  dateFormat,
  disableFutureDates
} from "features/ConfigurationTool/utils/datePicker";
import { Moment } from "moment";
import React, { Component, useEffect } from "react";
import { useIntl } from "react-intl";

interface IDatePickerFieldProps {
  configuration: IField;
  toggleEdit: () => void;
  getValidationErrors: (fieldKey: string) => Promise<void>;
  getInitialValue?: (
    fieldName: string
  ) => string | number | boolean | Date | Moment;
}

const DatePickerField = ({
  configuration,
  toggleEdit,
  getValidationErrors,
  getInitialValue
}: IDatePickerFieldProps) => {
  const intl = useIntl();

  const ref = React.createRef<
    Component<PickerProps<Moment>, unknown, any> & CommonPickerMethods
  >();

  useEffect(() => {
    ref.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Item
      name={configuration.fieldKey}
      rules={configuration.rules}
      initialValue={
        getInitialValue ? getInitialValue(configuration.fieldKey) : null
      }
    >
      <DatePicker
        ref={ref}
        onBlur={toggleEdit}
        onChange={() => getValidationErrors(configuration.fieldKey)}
        format={dateFormat(intl)}
        defaultOpen
        disabledDate={
          configuration.disableFutureDates ? disableFutureDates : null
        }
      />
    </Form.Item>
  );
};

export default DatePickerField;
