// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { FilterNames } from "@pg/common/build/components/FilterPanel";
import { isNil } from "lodash";
import { IntlShape } from "react-intl";

const getLevelName = (level: number) => {
  switch (level) {
    case 0:
      return "organization";
    case 1:
      return "region";
    case 2:
      return "location";
    default:
      return "unknown";
  }
};

const translateOptionDisplayName = (
  filterName: FilterNames,
  optionId: string,
  optionDisplayName: string,
  level: number,
  intl: IntlShape
) => {
  if (
    optionDisplayName === null &&
    filterName === "AssetOrganizationWithRegionAndLocation"
  )
    return intl.formatMessage({
      defaultMessage: "Unknown",
      id: getLevelName(level) + ".unknown"
    });

  if (optionDisplayName === null && filterName === "CompanyHierarchy")
    return intl.formatMessage({
      defaultMessage: "Unknown",
      id: `filter_panel.filters.select.${filterName}.Unknown`
    });

  if (
    [
      "AssetAge",
      "AssetVoltage",
      "AssetStatus",
      "AssetRisk",
      "AssetImportance",
      "IssueStatus",
      "AlarmType"
    ].indexOf(filterName) < 0
  )
    return optionDisplayName;

  if (!isNil(optionId)) {
    return intl.formatMessage({
      defaultMessage: optionDisplayName,
      id: `filter_panel.filters.select.${filterName}.${optionId}`
    });
  }

  return optionDisplayName;
};

export default translateOptionDisplayName;
