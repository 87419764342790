// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Typography } from "antd";
import NameplateIcons from "features/detailpage/components/nameplateIcons/NameplateIcons";
import { isNil } from "lodash";
import { useContext, useMemo } from "react";
import styled from "styled-components";
import AssetRibbonContext from "../contexts/AssetRibbonContext";

const { Paragraph } = Typography;

interface IDetailsProps {
  className?: string;
  parentId?: string;
  name?: string;
  modelId?: string;
  assetType?: string;
}

const Details = ({
  className,
  parentId,
  name,
  modelId,
  assetType
}: IDetailsProps) => {
  const { assetId } = useContext(AssetRibbonContext);
  const assetLabel = useMemo(() => {
    if (name) {
      return `${assetId} - ${name}`;
    }

    return assetId;
  }, [assetId, name]);

  return (
    <div className={`${className} asset-details asset-ribbon-section`}>
      <div className="asset-details-labels">
        {!isNil(parentId) && (
          <Paragraph
            strong={true}
            className="asset-parent-id"
            data-qa="header-parent-id"
            ellipsis={true}
          >
            {parentId}
          </Paragraph>
        )}
        <Paragraph
          className="asset-label"
          data-qa="header-asset-id-with-name"
          ellipsis={{ rows: 2 }}
        >
          {assetLabel}
        </Paragraph>
      </div>
      <NameplateIcons
        assetId={assetId}
        modelId={modelId}
        assetType={assetType}
      />
    </div>
  );
};

const StyledDetails = styled(Details)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .asset-details-labels {
    display: flex;
    flex-direction: column;
    font-size: 9pt;

    .ant-typography {
      margin-bottom: 0;
    }
  }

  &.asset-ribbon-section {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
`;

export default StyledDetails;
