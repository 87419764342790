// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { notifications } from "@pg/common/build/components/Notifications";
import UrlService from "core/data/services/UrlService";
import { IntlShape } from "react-intl";
import { config } from "utils/AppConfig";
import EndpointService from "../../../core/data/services/EndpointService";
import IThresholdCorrection from "../models/IThresholdCorrection";

interface IAdjustExpectedRangeParams {
  intl: IntlShape;
  assetId: string;
  parameterName: string;
  success?: (correction: IThresholdCorrection) => void;
  error?: () => void;
}

const adjustExpectedRange = ({
  assetId,
  error,
  intl,
  parameterName,
  success
}: IAdjustExpectedRangeParams) => {
  const url = UrlService.getApiUrl(
    config.api.detailPage.parametersMechanicalAdjustUrl,
    {
      assetId,
      parameterName
    }
  );

  EndpointService.postJson<IThresholdCorrection>(
    url,
    (request, data) => {
      if (success) success(data);
    },
    (request) => {
      if (request.status === 400 && request.responseText) {
        const parsedResponseText = JSON.parse(request.responseText);
        notifications.error({
          message: intl.formatMessage({
            id: `detail_page.adjust_expected_range.failed.${parsedResponseText}`,
            defaultMessage: `Cannot adjust expected range ${parsedResponseText}`
          })
        });
      } else {
        notifications.error({
          message: intl.formatMessage({
            id: "detail_page.adjust_expected_range.failed",
            defaultMessage: "Cannot adjust expected range"
          })
        });
      }

      if (error) error();
    }
  );
};

export default adjustExpectedRange;
