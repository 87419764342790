// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getRiskMatrixConfigurationSelector = createSelector(
  (state: IState) => state.detailPage.riskMatrix.riskMatrixConfiguration,
  riskMatrixConfiguration => riskMatrixConfiguration
);

export default getRiskMatrixConfigurationSelector;
