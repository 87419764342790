// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";

import { dataKey } from "../actions/loadDielectricPressure";
import IDielectricData from "../../../models/IDielectricData";

const getDielectricPressureSelector = createSelector<
  IState,
  Data<IDielectricData>,
  Data<IDielectricData>
>(
  (state: IState) => state.data[dataKey],
  (pressure) => pressure || new Data()
);

export default getDielectricPressureSelector;
