// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import Data from "../../../../../core/data/models/Data";
import { IState } from "../../../../../reducers/Index";

import { getDataKey } from "../actions/loadHeaterStatus";

import IHeaterStatus from "../models/IHeaterStatus";

const getHeaterStatusSelector = createSelector<
  IState,
  (assetId: string) => Data<IHeaterStatus>,
  (assetId: string) => Data<IHeaterStatus>
>(
  (state: IState) => (assetId: string) => state.data[getDataKey(assetId)],
  (heaterStatus) => heaterStatus
);

export default getHeaterStatusSelector;
