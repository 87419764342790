// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import styled from "styled-components";

const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default CenteredWrapper;
