// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";

import { getDataKey } from "../actions/loadMotorStarts";

import IMotorStarts from "../models/IMotorStarts";

const getOperatingMechanismMotorSelector = createSelector<
  IState,
  (assetId: string) => Data<IMotorStarts>,
  (assetId: string) => Data<IMotorStarts>
>(
  (state: IState) => (assetId: string) => state.data[getDataKey(assetId)],
  (motorStarts) => motorStarts
);

export default getOperatingMechanismMotorSelector;
