// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import Popover from "common/popover/Popover";
import {
  loadReplacementScoreFamilyInfoAction,
  resetFamilyInfoAction
} from "features/detailpage/features/familyinfo/actions/FamilyInfoActions";
import FamilyInfoContainer from "features/detailpage/features/familyinfo/containers/FamilyInfoContainer";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "thunk";

export interface IFamilyInfoReplacementScoreIconActions {
  loadReplacementScoreFamilyInfoAction: () => void;
  resetFamilyInfo: () => void;
}

export interface IFamilyInfoReplacementScoreIconOwnProps {
  assetId: string;
  isDisabled: boolean;
}

interface IFamilyInfoReplacementScoreIconProps
  extends IFamilyInfoReplacementScoreIconActions,
    IFamilyInfoReplacementScoreIconOwnProps {}

const ReplacementScoreFamilyIcon = ({
  assetId,
  isDisabled,
  loadReplacementScoreFamilyInfoAction,
  resetFamilyInfo
}: IFamilyInfoReplacementScoreIconProps) => {
  const disabledClass = isDisabled ? "family-info-popover-off" : "";
  return (
    <Popover
      placement="bottomRight"
      content={
        <FamilyInfoContainer
          assetId={assetId}
          familyAnalyticsDataType={"ReplacementScore"}
          familyAnalyticsSeriesName={"ReplacementScore"}
        />
      }
      onShow={loadReplacementScoreFamilyInfoAction}
      onHide={resetFamilyInfo}
      overlayClassName="family-info-replacement-popover family-info-popover"
      stopPropagation={true}
    >
      <span>
        <Icon
          className={`family-info-popover-icon ${disabledClass}`}
          dataQa="header-replacement-family-icon"
          name="speed"
          size="sm"
        />
      </span>
    </Popover>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<Action>,
  ownProps: IFamilyInfoReplacementScoreIconOwnProps
): IFamilyInfoReplacementScoreIconActions => {
  return {
    loadReplacementScoreFamilyInfoAction: () =>
      dispatch(loadReplacementScoreFamilyInfoAction(ownProps.assetId)),
    resetFamilyInfo: () => dispatch(resetFamilyInfoAction())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReplacementScoreFamilyIcon);
