// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import DataGrid from "common/datagrid/DataGrid";
import { SelectedFilters, StatusBar } from "common/FilterBar";
import SectionName from "components/common/SectionName";
import { getUserSelector } from "core/app/reducers/AppReducer";
import { IUser } from "core/app/reducers/settings/UserReducer";
import Data from "core/data/models/Data";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import useIssueGrid from "../hooks/useIssueGrid";
import {
  IOnMaintenancePriorityClickOptions,
  IOnNumberOfActionsClickOptions
} from "../hooks/useIssuesGridColumnsConfig";

interface IIssueGridState {
  user: Data<IUser>;
}

interface IIssueGridOwnProps {
  filters: SelectedFilters;
  onMaintenancePriorityClick?: (
    options: IOnMaintenancePriorityClickOptions
  ) => void;
  onNumberOfActionsClick?: (options: IOnNumberOfActionsClickOptions) => void;
}

type IIssueGridProps = IIssueGridOwnProps & IIssueGridState;

const IssuesGrid = ({
  filters,
  onMaintenancePriorityClick,
  onNumberOfActionsClick,
  user
}: IIssueGridProps) => {
  const { actions, dataEndpoint, rowsTotal, columns } = useIssueGrid({
    filters,
    onMaintenancePriorityClick,
    onNumberOfActionsClick,
    user: user.data
  });
  return (
    <div className="grid-container data-grid__parent">
      <div className="data-grid__scroll">
        <div className="container">
          <div className="bootstrap-row">
            <div className="col-24 col-lg-20 header">
              {rowsTotal !== undefined && rowsTotal != null ? (
                <SectionName
                  messageDefault="Issues / {numberOfIssues, number} items"
                  messageId="issues_page.header.issues_total"
                  messageValues={{
                    numberOfIssues: rowsTotal
                  }}
                />
              ) : (
                <SectionName
                  messageDefault="Issues"
                  messageId="issues_page.header.issues"
                />
              )}
              <StatusBar />
            </div>
          </div>
          <div className="bootstrap-row">
            <div className="col-24 col-lg-20">
              <DataGrid
                actions={actions}
                columns={columns}
                dataEndpoint={dataEndpoint}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IState): IIssueGridState => ({
  user: getUserSelector(state)
});
const IssuesGridContainer = connect(mapStateToProps)(IssuesGrid);
export default IssuesGridContainer;
