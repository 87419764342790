// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import AntdProviderContainer from "core/app/containers/AntdProviderContainer";
import IntlProviderContainer from "core/app/containers/IntlProviderContainer";
import RouterProviderContainer from "core/app/containers/RouterProviderContainer";
import NotificationsProvider from "./NotificationsProvider";
import SiteContent from "./SiteContent";

const Site = () => {
  return (
    <AntdProviderContainer>
      <IntlProviderContainer>
        <NotificationsProvider>
          <RouterProviderContainer
            Content={(props) => <SiteContent {...props} />}
          />
        </NotificationsProvider>
      </IntlProviderContainer>
    </AntdProviderContainer>
  );
};

export default Site;
