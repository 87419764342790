// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useMemo } from "react";
import { useIntl } from "react-intl";

const useSF6PressureTranslations = () => {
  const intl = useIntl();

  const emptyTranslation = useMemo(
    () =>
      intl.formatMessage({
        id: "global.chart.no_data_available",
        defaultMessage: "No data available"
      }),
    [intl]
  );

  return {
    emptyTranslation
  };
};

export default useSF6PressureTranslations;
