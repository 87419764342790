import Icon from "@pg/common/build/components/Icon";
import { Button, Col, Form, Layout, Modal, Row, Typography } from "antd";
import AssetModalContext from "features/ConfigurationTool/contexts/AssetModalContext";
import IConfigurableConnectedDevice from "features/ConfigurationTool/models/connectedDevices/IConfigurableConnectedDevice";
import IRegisterConfiguration from "features/ConfigurationTool/models/connectedDevices/IRegisterConfiguration";
import IField from "features/ConfigurationTool/models/IField";
import { FC, useCallback, useContext } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { colorGray0, colorWidgetBorder } from "styles/ColorVariables";
import {
  spacingLarge,
  spacingMedium,
  spacingXXLarge
} from "styles/StyleVariables";
import CustomField from "../CustomField";

const { Header, Footer, Content } = Layout;
const { Title } = Typography;

interface IAddNewParameterProps {
  visible: boolean;
  activeConnectedDeviceData?: IConfigurableConnectedDevice;
  onCancel?: () => void;
  onSave?: (values: IRegisterConfiguration) => void;
  fieldsConfig?: IField[];
  className?: string;
}

const AddNewParameter: FC<IAddNewParameterProps> = ({
  visible,
  onCancel,
  onSave,
  fieldsConfig,
  className
}) => {
  const [form] = Form.useForm();

  const { validateMessages } = useContext(AssetModalContext);

  const { formatMessage } = useIntl();

  const handleCancel = useCallback(() => {
    onCancel();
    form.resetFields();
  }, [form, onCancel]);

  const handleSubmit = useCallback(
    (values: IRegisterConfiguration) => {
      onSave(values);
      onCancel();
      form.resetFields();
    },
    [form, onCancel, onSave]
  );

  return (
    <Modal
      visible={visible}
      centered={true}
      width="680px"
      destroyOnClose={true}
      onCancel={onCancel}
      footer={null}
      data-qa="AddNewParameterModal"
      className={className}
    >
      <Form<IRegisterConfiguration>
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        colon={false}
        validateMessages={validateMessages}
      >
        <Layout className="add-new-parameter-layout">
          <Header className="add-new-parameter-header">
            <Title level={5}>
              {formatMessage({
                id: "configuration_tool.tab.connected_devices_add_parameter",
                defaultMessage: "Add Parameter"
              })}
            </Title>
          </Header>
          <Content className="add-new-parameter-content">
            <Row gutter={16} style={{ margin: 0 }}>
              {fieldsConfig.map((fieldConfig) => (
                <Col span={12} key={fieldConfig.fieldKey}>
                  <CustomField
                    configuration={fieldConfig}
                    getInitialValue={undefined}
                  />
                </Col>
              ))}
            </Row>
          </Content>
        </Layout>

        <Footer className="add-new-parameter-footer">
          <Button type="default" className="btn-cancel" onClick={handleCancel}>
            <Icon name="close" size="sm" />
            {formatMessage({
              defaultMessage: "Cancel",
              id: "configuration_tool.action.cancel"
            })}
          </Button>

          <Button type="primary" htmlType="submit">
            <Icon name="done" size="sm" />
            {formatMessage({
              defaultMessage: "Save",
              id: "configuration_tool.action.save"
            })}
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
};

const StyledAddNewParameter = styled(AddNewParameter)`
  .add-new-parameter-layout,
  .add-new-parameter-header,
  .add-new-parameter-footer {
    background-color: ${colorGray0};
  }

  .add-new-parameter-header {
    padding: 0px 0px ${spacingXXLarge} 0px;
  }

  .add-new-parameter-content {
    display: flex;
    flex-direction: column;
  }

  .add-new-parameter-footer {
    border-top: 1px solid ${colorWidgetBorder};
    display: flex;
    column-gap: ${spacingLarge};
    align-items: center;
    justify-content: flex-end;
    padding-right: 0;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: ${spacingMedium};
    }
  }
`;

export default StyledAddNewParameter;
