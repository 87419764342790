// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { Action, Dispatch } from "redux";
import { ThunkAction } from "thunk";
import { config } from "utils/AppConfig";
import { INumberOfAssets, Page } from "../reducers/NumberOfAssetsReducer";

// #region Constants

export enum Actions {
  NumberOfAssets_SetPage = "NumberOfAssets_SetPage",
  NumberOfAssets_LoadNumberOfAssetsByType = "NumberOfAssets_LoadNumberOfAssetsByType",
  NumberOfAssets_LoadNumberOfAssetsByOrganizations = "NumberOfAssets_LoadNumberOfAssetsByOrganizations"
}

// #endregion Constants

// #region Actions

export interface ISetPageAction {
  type: string;
  page: Page;
}

const setNumberOfAssetsByType = (
  request: JQuery.jqXHR,
  data?: INumberOfAssets
): IAjaxAction<INumberOfAssets> => ({
  type: Actions.NumberOfAssets_LoadNumberOfAssetsByType,
  request,
  data
});

export const loadNumberOfAssetsByType = (): ThunkAction<void, Action> => {
  return (dispatch: Dispatch<Action>) => {
    const url: string = UrlService.getApiUrl(
      config.api.homepage.numberOfAssetsByType
    );

    const request = EndpointService.getJson<INumberOfAssets>(
      url,
      (request, data) => {
        dispatch(setNumberOfAssetsByType(request, data));
      },
      (request) => dispatch(setNumberOfAssetsByType(request))
    );

    dispatch(setNumberOfAssetsByType(request));
  };
};

const setNumberOfAssetsByOrganization = (
  request: JQuery.jqXHR,
  data?: INumberOfAssets
): IAjaxAction<INumberOfAssets> => ({
  type: Actions.NumberOfAssets_LoadNumberOfAssetsByOrganizations,
  request,
  data
});

export const loadNumberOfAssetsByOrganizations = (): ThunkAction<
  void,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    const url: string = UrlService.getApiUrl(
      config.api.homepage.numberOfAssetsByOrganization
    );

    const request = EndpointService.getJson<INumberOfAssets>(
      url,
      (request, data) => {
        dispatch(setNumberOfAssetsByOrganization(request, data));
      },
      (request) => dispatch(setNumberOfAssetsByOrganization(request))
    );

    dispatch(setNumberOfAssetsByOrganization(request));
  };
};

export const setPage = (page: Page): ISetPageAction => ({
  type: Actions.NumberOfAssets_SetPage,
  page
});

// #endregion Actions
