// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";

import { dataKey } from "../actions/StationRiskActions";
import ILocationRisk from "../models/ILocationRisk";

const getStationRiskSelector = createSelector<
  IState,
  Data<ILocationRisk[]>,
  Data<ILocationRisk[]>
>(
  (state: IState) => state.data[dataKey],
  (stationRisk) => stationRisk
);

export default getStationRiskSelector;
