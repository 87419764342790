// Copyright 2016-2022 Hitachi Energy. All rights reserved.
declare global {
  interface Object {
    deepClone<T>(obj: T): T;
  }
  interface String {
    linkify(): JSX.Element[];
  }
}

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "extensions/Console";
import "extensions/JQuery";
import "extensions/Object";
import "extensions/String";

import * as ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";

import AppContainer from "core/app/containers/AppContainer";
import SettingsProviderContainer from "core/app/containers/SettingsProviderContainer";
import TranslationsProviderContainer from "core/app/containers/TranslationsProviderContainer";
import VersionProviderContainer from "core/app/containers/VersionProviderContainer";
import AuthorizationService from "core/app/services/AuthorizationService";
import CsrfService from "core/app/services/CsrfService";
import store from "stores/Index";

import "./styles/less/index.less";

AuthorizationService.setupAuthorization();

CsrfService.setupCsrfProtection().then(() => {
  ReactDOM.render(
    <ReduxProvider store={store}>
      <SettingsProviderContainer>
        <TranslationsProviderContainer>
          <VersionProviderContainer>
            <AppContainer />
          </VersionProviderContainer>
        </TranslationsProviderContainer>
      </SettingsProviderContainer>
    </ReduxProvider>,
    document.getElementById("root")
  );
});
