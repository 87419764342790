// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Action, Dispatch } from "redux";

import { IUser } from "core/app/reducers/settings/UserReducer";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { config } from "utils/AppConfig";

export enum Actions {
  Settings_SetUser = "Settings_SetUser",
  Settings_SetUserSucceeded = "Settings_SetUserSucceeded"
}

const setUserAction = (
  request: JQuery.jqXHR,
  data?: IUser
): IAjaxAction<IUser> => {
  const action: IAjaxAction<IUser> = {
    type: Actions.Settings_SetUser,
    request,
    data
  };
  return action;
};

export const loadUser = (dispatch: Dispatch<Action>) => {
  const url = UrlService.getApiUrl(config.api.user.userUrl);
  const request = EndpointService.getJson<IUser>(
    url,
    (request, data) => {
      dispatch(setUserAction(request, data));
    },
    request => {
      dispatch(setUserAction(request));
    }
  );

  dispatch(setUserAction(request));
};
