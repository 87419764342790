// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { dataKey } from "../actions/loadTrends";
import IAssetRiskTrend from "../models/IAssetRiskTrend";

const getTrendsSelector = createSelector<
  IState,
  Data<IAssetRiskTrend[]>,
  Data<IAssetRiskTrend[]>
>(
  (state: IState) => state.data[dataKey],
  (trends) => trends
);

export default getTrendsSelector;
