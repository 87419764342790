// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React, { memo } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { fontSizeMedium } from "styles/StyleVariables";

const StyledDiv = styled.div`
  font-size: ${fontSizeMedium};
  margin-left: 5px;
`;

const WidgetHeaderTitle = memo(() => {
  return (
    <StyledDiv>
      <FormattedMessage
        id="detail_page.widgets.health_score_widget.title"
        defaultMessage="Condition Trend"
      />
    </StyledDiv>
  );
});

export default WidgetHeaderTitle;
