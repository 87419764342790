// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { connect } from "react-redux";
import { Action } from "redux";

import { IState } from "reducers/Index";
import SubfactorsGrid, {
  ISubfactorsGridActions,
  ISubfactorsGridStateProps
} from "features/detailpage/features/subfactorsgrid/components";
import {
  getAssetSubfactorsSelector,
  getAssetSubfactorsCountSelector,
  getPreviousValueDateSelector,
  getCurrentValueDateSelector
} from "features/detailpage/features/subfactorsgrid/reducers/SubfactorsGridReducer";
import { loadAssetSubfactors } from "features/detailpage/features/subfactorsgrid/actions/SubfactorsGridActions";
import { ThunkDispatch } from "thunk";

const mapStateToProps = (state: IState): ISubfactorsGridStateProps => ({
  assetSubfactors: getAssetSubfactorsSelector(state),
  assetSubfactorsCount: getAssetSubfactorsCountSelector(state),
  previousDate: getPreviousValueDateSelector(state),
  currentDate: getCurrentValueDateSelector(state)
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<Action>
): ISubfactorsGridActions => ({
  loadAssetSubfactors: (assetId: string) =>
    dispatch(loadAssetSubfactors(assetId))
});

export default connect(mapStateToProps, mapDispatchToProps)(SubfactorsGrid);
