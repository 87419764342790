// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";

import { FormattedMessage } from "react-intl";

const DuvalTriangleType4Note = () => {
  return (
    <div className="duval-triangle-note">
      <span>
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.DuvalTriangle.important_note.type4.text1"
          defaultMessage="The Duval triangle 4 for low temperature faults in mineral oils uses gases H2, CH4 and C2H6."
        />
      </span>
      <span>
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.DuvalTriangle.important_note4.type4.text2"
          defaultMessage="The Duval Triangle 4 is used to get more information about faults identified as low temperature faults (PD, T1 or T2) with Duval Triangle 1 for mineral oils (using CH4, C2H4 and C2H2)."
        />
      </span>
      <span>
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.DuvalTriangle.important_note4.type4.text3"
          defaultMessage="Do not use the Duval Triangle 4 for faults D1, D2 or T3."
        />
      </span>
      <span>
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.DuvalTriangle.important_note4.type4.text4"
          defaultMessage="If Triangle 4 and Triangle 5 do not agree, this probably indicates a mixture of faults."
        />
      </span>
      <span>
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.DuvalTriangle.important_note4.type4.text5"
          defaultMessage="Some new oils on the market may produce stray gassing inside zone PD. Verify with stray gassing tests in the laboratory. In zone C, the probability of having carbonization of paper is 80%, not 100%."
        />
      </span>
    </div>
  );
};

export default DuvalTriangleType4Note;
