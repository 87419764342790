// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import AnalyticsChartLegend from "features/detailpage/components/analytics/AnalyticsChartLegend";
import BushingOfflineChartStaticLegend from "features/detailpage/features/bushingsoffline/components/BushingOfflineChartLegend/BushingOfflineChartStaticLegend";
import BushingOfflineChartService from "features/detailpage/features/bushingsoffline/services/BushingOfflineChartService";
import { useIntl } from "react-intl";
import "./BushingOfflineChartLegend.less";

export interface IBushingOfflineChartLegendProps {
  series: string;
  focusPlotSeries?: (series: string, plot: string) => void;
  revertPlotSeries?: (series: string) => void;
  togglePlotSeries?: (series: string, plot: string) => void;
}

const BushingOfflineChartLegend = ({
  series,
  focusPlotSeries,
  revertPlotSeries,
  togglePlotSeries
}: IBushingOfflineChartLegendProps) => {
  const intl = useIntl();
  const settings = BushingOfflineChartService.getBushingChartsSettings();

  return (
    <div className="bushing-offline-legend">
      <AnalyticsChartLegend
        plots={BushingOfflineChartService.getPlots(intl)}
        series={series}
        onFocus={focusPlotSeries}
        onRevert={revertPlotSeries}
        onToggle={togglePlotSeries}
      />
      <BushingOfflineChartStaticLegend thresholds={settings.References} />
    </div>
  );
};

export default BushingOfflineChartLegend;
