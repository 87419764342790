// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Action, Reducer } from "redux/index";

import {
  Actions,
  IActionSetQueryParams
} from "core/app/actions/LocationActions";
import LocationService from "core/app/services/LocationService";

export interface IRawQueryParams {
  [name: string]: string[] | null;
}

export interface INamedQueryParams {
  disableBingMaps: boolean | null;
  experimental: boolean | null;
  locale: string | null;
}

export type QueryParams = INamedQueryParams;

export interface ILocation {
  queryParams: QueryParams;
}

export interface IState {
  location: ILocation;
}

const locationReducer: Reducer<IState> = (state: IState, action: Action) => {
  switch (action.type) {
    case Actions.Location_SetQueryParams:
      return LocationService.setQueryParamsToState(
        state,
        action as IActionSetQueryParams
      );
    default:
      return state;
  }
};

export default locationReducer;
