// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import {
  SelectedFilterOption,
  SelectedFilters,
  SelectedFilterSelect
} from "common/FilterBar";
import update from "immutability-helper";
import { isEmpty, isNil } from "lodash";

const createOption = (
  filterValue: string[],
  i: number
): SelectedFilterOption => {
  const optionId = filterValue[i] || null;

  return {
    Id: optionId,
    Options:
      filterValue.length > i + 1
        ? [createOption(filterValue, i + 1)]
        : undefined
  };
};

const getOptions = (
  options: SelectedFilterOption[] | undefined,
  filterValue: string[],
  i: number
): SelectedFilterOption[] => {
  if (filterValue.length < i + 1) return options;
  if (isNil(options) || isEmpty(options)) return [createOption(filterValue, i)];

  const optionId = filterValue[i] || null;
  const j = options.findIndex((o) => o.Id === optionId);
  if (j < 0) return [createOption(filterValue, i)];

  const result = getOptions(options[j].Options, filterValue, i + 1);
  return [
    {
      Id: optionId,
      Options: result
    }
  ];
};

const getSelects = (
  selects: SelectedFilterSelect[] | undefined,
  filterName: string,
  filterValue: string[]
) => {
  if (isNil(selects)) return undefined;

  const i = selects.findIndex((s) => s.Id === filterName);

  if (i < 0) {
    return [
      ...selects,
      {
        Id: filterName,
        Options: [createOption(filterValue, 0)]
      }
    ];
  } else {
    if (filterValue.length > 0) {
      const options = getOptions(selects[i].Options, filterValue, 0);
      return update(selects, {
        [i]: {
          Options: { $set: options }
        }
      });
    } else {
      return selects;
    }
  }
};

const setupFilter = (
  filters: SelectedFilters,
  filterName: string,
  filterValue: string[]
) => {
  const result = update(filters, {
    selects: {
      $apply: (selects: SelectedFilterSelect[]) => {
        const newSelects = getSelects(selects, filterName, filterValue);
        return newSelects;
      }
    }
  });

  return result;
};

export default setupFilter;
