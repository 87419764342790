// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useDispatch } from "react-redux";
import { useCallback } from "react";

import { setAllOrganizationCheckedAction } from "../actions/NotificationsActions";

const useAllOrganizationsChecked = () => {
  const dispatch = useDispatch();
  const setAllOrganizationChecked = useCallback(
    (value: boolean) => {
      dispatch(setAllOrganizationCheckedAction(value));
    },
    [dispatch]
  );

  return { setAllOrganizationChecked };
};

export default useAllOrganizationsChecked;
