// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import { routesConfig } from "core/app/components/router/RouterProvider";
import { NavLink as Link } from "react-router-dom";
import IKeyRiskChangesInfo from "../../models/IKeyRiskChangesInfo";

interface IKeyRiskItem {
  item: IKeyRiskChangesInfo;
}

const KeyRiskItem = ({ item }: IKeyRiskItem) => {
  const assetDisplayedName: string =
    item.AssetId +
    (item.AssetName && item.AssetName !== "" ? " - " + item.AssetName : "");

  const searchParams = new SearchParams({ assetId: item.AssetId });

  return (
    <div className="key-risk-item">
      <div
        className={
          "border-left asset-risk-border " + item.AssetRisk.toLowerCase()
        }
      >
        <div className="asset-id" title={assetDisplayedName}>
          <Link
            className="explore anchor"
            data-qa="link"
            to={{
              pathname: routesConfig.detailPage,
              search: searchParams.toString()
            }}
          >
            {assetDisplayedName}
          </Link>
        </div>
        <div className="asset-details">
          <span>{item.Location}</span>
          <span> / </span>
          <span>{item.AssetType}</span>
        </div>
      </div>
    </div>
  );
};

interface IKeyRiskItemsGroupProps {
  itemsData: IKeyRiskChangesInfo[];
}

const KeyRiskItemsGroup = ({ itemsData }: IKeyRiskItemsGroupProps) => (
  <>
    {itemsData.map((item, index) => (
      <KeyRiskItem item={item} key={index} />
    ))}
  </>
);

export default KeyRiskItemsGroup;
