// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data from "core/data/models/Data";
import { Actions } from "features/detailpage/features/replacementscore/actions/ReplacementScoreActions";
import { IAjaxAction } from "reducers/Index";
import { Reducer } from "redux";
import IReplacementPriorityScore from "../models/IReplacementPriorityScore";

export interface IState {
  replacementScore: {
    scoreCalculation: Data<IReplacementPriorityScore[]>;
  };
}

export const defaultState: IState = {
  replacementScore: {
    scoreCalculation: new Data()
  }
};

// #region Reducer

const reducer: Reducer<IState> = (
  state = defaultState,
  action: IAjaxAction<IReplacementPriorityScore[]>
): IState => {
  switch (action.type) {
    case Actions.DetailPage_ReplacementScore_SetScoreCalculation:
      return {
        ...state,
        replacementScore: {
          ...state.replacementScore,
          scoreCalculation: new Data(
            (action as IAjaxAction<IReplacementPriorityScore[]>).request,
            (action as IAjaxAction<IReplacementPriorityScore[]>).data
          )
        }
      };
    default:
      return state;
  }
};

export default reducer;
