// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Action, Dispatch } from "redux";

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAction } from "features/issues/reducers/IssuesReducer";
import { IAjaxAction } from "reducers/Index";
import { config } from "utils/AppConfig";

export enum Actions {
  Issues_SetCloseIssueResult = "Issues_SetCloseIssueResult",
  Issues_SetActions = "Issues_SetActions"
}

export const setActionsAction = (
  request: JQueryXHR,
  data?: IAction[]
): IAjaxAction<IAction[]> => {
  const action: IAjaxAction<IAction[]> = {
    type: Actions.Issues_SetActions,
    request,
    data
  };
  return action;
};

export const setCloseIssueAction = (request: JQueryXHR): IAjaxAction<void> => {
  const action: IAjaxAction<void> = {
    type: Actions.Issues_SetCloseIssueResult,
    request,
    data: undefined
  };
  return action;
};

export const closeIssue = (
  dispatch: Dispatch<Action>,
  issueId: string
): Promise<JQuery.jqXHR<any>> => {
  return new Promise((resolve, reject) => {
    const closedStatus = "Closed";
    const url = UrlService.getApiUrl(
      config.api.watchlist.updateIssueStatusUrl,
      {
        issueId
      }
    );

    const request = EndpointService.put(
      url,
      (request) => {
        dispatch(setCloseIssueAction(request));
        resolve(request);
      },
      (request) => {
        dispatch(setCloseIssueAction(request));
        reject(request);
      },
      null,
      closedStatus,
      "application/json; charset=utf-8"
    );

    dispatch(setCloseIssueAction(request));
  });
};

export const loadActions = (dispatch: Dispatch<Action>, issueId: string) => {
  const url = UrlService.getApiUrl(config.api.watchlist.actionsUrl, {
    issueId
  });

  const request = EndpointService.getJson<IAction[]>(
    url,
    (request, data) => {
      dispatch(setActionsAction(request, data));
    },
    (request) => {
      dispatch(setActionsAction(request));
    }
  );

  dispatch(setActionsAction(request));
};
