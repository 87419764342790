// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Action, Dispatch } from "redux";
import { ThunkAction, ThunkDispatch } from "thunk";

import IReplacementPriorityScore from "../models/IReplacementPriorityScore";

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { config } from "utils/AppConfig";

export enum Actions {
  DetailPage_ReplacementScore_SetScoreCalculation = "DetailPage_ReplacementScore_SetScoreCalculation"
}

// #region Actions creators

export const setScoreCalculationAction = (
  request: JQuery.jqXHR,
  data?: IReplacementPriorityScore[]
): IAjaxAction<IReplacementPriorityScore[]> => ({
  type: Actions.DetailPage_ReplacementScore_SetScoreCalculation,
  request,
  data
});

// #region Actions

export const loadReplacementPriorityScoreCalculationAction =
  (assetId: string): ThunkAction<Promise<void>, Action> =>
  (dispatch: ThunkDispatch<Action>) =>
    loadReplacementPriorityScoreCalculation(dispatch, assetId);

function loadReplacementPriorityScoreCalculation(
  dispatch: Dispatch<Action>,
  assetId: string
): Promise<any> {
  return new Promise<void>((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.replacementPriorityScoreCalculationUrl,
      [
        {
          name: "assetId",
          value: assetId
        }
      ]
    );

    const request = EndpointService.getJson<IReplacementPriorityScore[]>(
      url,
      (request, data) => {
        dispatch(setScoreCalculationAction(request, data));
        resolve();
      },
      (request) => {
        dispatch(setScoreCalculationAction(request));
        reject();
      }
    );

    dispatch(setScoreCalculationAction(request));
  });
}
