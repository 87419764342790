import { Button } from "antd";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { IColumn, IExportToCsv } from "./DataGrid";

interface IExportToCsvButtonProps {
  exportToCsv?: IExportToCsv;
  columns: IColumn[];
}

const ExportToCsvButton = ({
  exportToCsv,
  columns
}: IExportToCsvButtonProps) =>{
  const handleExportCsvClick = useCallback(() => {
    exportToCsv?.onClick(columns);
  }, [columns, exportToCsv]);;

  return(
    <Button
      className="light"
      type="link"
      onClick={handleExportCsvClick}
      data-qa="export-csv-button"
    >
      <FormattedMessage
        defaultMessage="Csv"
        id="data_grid.footer.export.csv"
      />
    </Button>
  );
};

export default ExportToCsvButton;