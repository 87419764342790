// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data from "core/data/models/Data";
import { Actions } from "features/detailpage/features/subfactorsgrid/actions/SubfactorsGridActions";
import ISubfactorScore from "features/detailpage/features/subfactorsgrid/models/ISubfactorScore";
import { first, isEmpty } from "lodash";
import { IAjaxAction, IState as IRootState } from "reducers/Index";
import { Reducer } from "redux";

export interface IState {
  subfactorsGrid: {
    assetSubfactors: Data<Array<ISubfactorScore>>;
  };
}

export const defaultState: IState = {
  subfactorsGrid: {
    assetSubfactors: new Data()
  }
};

const reducer: Reducer<IState> = (
  state = defaultState,
  action: IAjaxAction<Array<ISubfactorScore>>
) => {
  switch (action.type) {
    case Actions.DetailPage_Subfactors_SetAssetSubfactors: {
      return {
        ...state,
        subfactorsGrid: {
          ...state.subfactorsGrid,
          assetSubfactors: new Data(action.request, action.data)
        }
      };
    }
    case Actions.DetailPage_Subfactors_ClearAssetSubfactors: {
      return {
        ...state,
        subfactorsGrid: {
          ...state.subfactorsGrid,
          assetSubfactors: new Data()
        }
      };
    }
    default:
      return state;
  }
};

export default reducer;

// #region Selectors

export const getAssetSubfactorsSelector = (state: IRootState) =>
  state.detailPage.subfactorsGrid.assetSubfactors;

export const getAssetSubfactorsCountSelector = (state: IRootState) => {
  const subfactors = getAssetSubfactorsSelector(state);
  let count = 0;

  if (subfactors && !isEmpty(subfactors.data)) {
    const getSubfactorsCount = (subfactors: Array<ISubfactorScore>) => {
      count += isEmpty(subfactors) ? 0 : subfactors.length;
      subfactors.forEach((s) => {
        if (!isEmpty(s.SubFactors)) {
          getSubfactorsCount(s.SubFactors);
        }
      });
    };

    getSubfactorsCount(subfactors.data);
  }

  return count;
};

export const getPreviousValueDateSelector = (state: IRootState) => {
  const subfactors = getAssetSubfactorsSelector(state);
  if (!isEmpty(subfactors.data) && subfactors.data.length > 0) {
    const { PreviousDate } = first(subfactors.data);
    return PreviousDate;
  }

  return null;
};

export const getCurrentValueDateSelector = (state: IRootState) => {
  const subfactors = getAssetSubfactorsSelector(state);
  if (!isEmpty(subfactors.data) && subfactors.data.length > 0) {
    const { CurrentDate } = first(subfactors.data);
    return CurrentDate;
  }

  return null;
};

// #endregion
