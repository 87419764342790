// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { loadActions } from "features/issues/actions/IssuesActions";

const useLoadActions = () => {
  const dispatch = useDispatch();

  return useCallback(
    (assetIssueId: string) => loadActions(dispatch, assetIssueId),
    [dispatch]
  );
};

export default useLoadActions;
