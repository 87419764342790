// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import { useEffect } from "react";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { ThunkDispatch } from "thunk";
import loadAssetRiskIndicators from "../actions/loadAssetRiskIndicators";
import loadAssetRiskTrend from "../actions/loadAssetRiskTrend";
import removeAssetRiskIndicators from "../actions/removeAssetRiskIndicators";
import removeAssetRiskTrend from "../actions/removeAssetRiskTrend";
import IAssetRiskIndicators from "../models/IAssetRiskIndicators";
import IAssetRiskTrendItem from "../models/IAssetRiskTrendItem";
import getAssetRiskIndicatorsSelector from "../selectors/getAssetRiskIndicatorsSelector";
import getAssetRiskTrendSelector from "../selectors/getAssetRiskTrendSelector";
import AssetRiskTrend from "./AssetRiskTrend";

export interface IAssetRiskChartOwnProps {}

export interface IAssetRiskChartActions {
  loadAssetRiskIndicators: () => void;
  loadAssetRiskTrend: () => void;
  removeAssetRiskIndicators: () => void;
  removeAssetRiskTrend: () => void;
}

export interface IAssetRiskChartState {
  assetRiskTrend: Data<IAssetRiskTrendItem[]>;
  assetRiskIndicators: Data<IAssetRiskIndicators>;
}

export interface IAssetRiskChartProps
  extends IAssetRiskChartOwnProps,
    IAssetRiskChartActions,
    IAssetRiskChartState {}

const AssetRiskChart = ({
  assetRiskIndicators,
  assetRiskTrend,
  loadAssetRiskIndicators,
  loadAssetRiskTrend,
  removeAssetRiskIndicators,
  removeAssetRiskTrend
}: IAssetRiskChartProps) => {
  useEffect(() => {
    loadAssetRiskTrend();
    loadAssetRiskIndicators();
    return () => {
      removeAssetRiskTrend();
      removeAssetRiskIndicators();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="open-issues">
      <div className="tile" data-qa="asset-risk-tile">
        <div className="tile-content">
          {(assetRiskTrend?.status === Statuses.Loading ||
            assetRiskIndicators?.status === Statuses.Loading) && <Processing />}
          {assetRiskTrend?.status === Statuses.Succeeded &&
            assetRiskIndicators?.status === Statuses.Succeeded && (
              <AssetRiskTrend
                assetRiskIndicators={assetRiskIndicators?.data}
                assetRiskTrend={assetRiskTrend?.data}
              />
            )}
          {(assetRiskTrend?.status === Statuses.Failed ||
            assetRiskIndicators?.status === Statuses.Failed) && (
            <WidgetErrorMessage />
          )}
          {(assetRiskTrend?.status === Statuses.NotFound ||
            assetRiskIndicators?.status === Statuses.NotFound) && (
            <WidgetNoDataMessage />
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state: IState): IAssetRiskChartState => ({
    assetRiskTrend: getAssetRiskTrendSelector(state),
    assetRiskIndicators: getAssetRiskIndicatorsSelector(state)
  }),
  (dispatch: ThunkDispatch<any>): IAssetRiskChartActions => ({
    loadAssetRiskIndicators: () => dispatch(loadAssetRiskIndicators()),
    loadAssetRiskTrend: () => dispatch(loadAssetRiskTrend()),
    removeAssetRiskIndicators: () => dispatch(removeAssetRiskIndicators()),
    removeAssetRiskTrend: () => dispatch(removeAssetRiskTrend())
  })
)(AssetRiskChart);
