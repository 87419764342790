// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { FormInstance } from "antd";
import IAssetNameplate from "features/detailpage/models/IAssetNameplate";
import { TypedValue } from "features/detailpage/models/IParameter";
import { ValidateMessages } from "rc-field-form/lib/interface";
import { createContext } from "react";
import { IParametersData } from "../actions/loadParameters";
import { IMenuItem } from "../components/AssetModalMenu";
import { IFormItem } from "../hooks/useForms";
import IConfigurableConnectedDevice from "../models/connectedDevices/IConfigurableConnectedDevice";
import IConnectedDevice from "../models/connectedDevices/IConnectedDevice";
import IConnectedDevicesData from "../models/connectedDevices/IConnectedDevicesData";
import DataTypes from "../models/DataTypes";
import IField from "../models/IField";
import IFormValues from "../models/IFormValues";
import { IParameter } from "../models/IParameter";
import { ITabInfo } from "../models/ITabInfo";

export interface IAssetModalContext {
  isEditMode?: boolean;
  assetId?: string;
  assetNameplate?: IAssetNameplate;
  inspection?: IParameter[];
  parameters?: IParametersData;
  configuredParameterNames?: string[];
  changeNameplateField?: (
    fieldName: string,
    fieldValue: number | boolean | string | Date
  ) => void;
  changeInspectionField?: (
    fieldName: string,
    fieldValue: string | number | boolean | Date,
    fieldType: TypedValue,
    date?: Date,
    oldFieldName?: string
  ) => void;
  changeInspectionDateForFields?: (date: Date, keys: string[]) => void;
  addAttribute?: (attributeName: string, attributeValue: string) => void;
  editAttribute?: (
    previousAttributeName: string,
    attributeName: string,
    attributeValue: string
  ) => void;
  deleteAttribute?: (attributeName: string) => void;
  saveAssetNameplate?: () => Promise<string>;
  saveInspection?: () => Promise<string>;
  resetData?: () => void;
  menuItems?: IMenuItem[];
  selectedMenu?: string;
  blockActions?: boolean;
  validateMessages?: ValidateMessages;
  getFormInfo?: (formKey: string) => IFormItem;
  registerForm?: (
    formKey: string,
    form: FormInstance<IFormValues>,
    tabs?: ITabInfo[]
  ) => void;
  changeSelectedMenu?: (key: string, keyPath: string[]) => void;
  updateFormItemTouchedInfo?: (formKey: string, value?: boolean) => void;
  updateFormItemInvalidInfo?: (formKey: string, value?: boolean) => void;
  updateFormItemValidationInfo?: ({
    formKey,
    invalid,
    touched
  }: {
    formKey: string;
    invalid: boolean;
    touched: boolean;
  }) => void;
  isDataTypeTouched?: (formDataType: DataTypes) => boolean;
  validForms?: () => Promise<boolean>;
  initialAttributeKeys?: string[];
  changeTabsInfo?: (formKey: string, tabInfo: ITabInfo[]) => void;
  customParameters?: IField[];
  addParameter?: (parameterName: string, parameterType: TypedValue) => void;
  editParameter?: (
    previousParameterName: string,
    parameterName: string,
    parameterType: TypedValue
  ) => void;
  deleteParameter?: (parameterName: string) => void;
  connectedDevices?: IConfigurableConnectedDevice[];
  setConnectedDevices?: (
    val:
      | IConfigurableConnectedDevice[]
      | ((
          prevValue: IConfigurableConnectedDevice[]
        ) => IConfigurableConnectedDevice[])
  ) => void;
  connectedDeviceLastUpdatedDate?: IConnectedDevicesData["LastUpdatedDate"];
  connectedDeviceParameterNames?: IConnectedDevicesData["AssetParameterNames"];
  changeConnectedDeviceGeneralInformationField?: (config: {
    fieldName: IParameter["InputName"];
    fieldValue: IParameter["Value"];
  }) => void;
  saveConnectedDevices?: () => Promise<string>;
  connectedDeviceAction?: "add" | "delete" | "upload";
  setConnectedDeviceAction?: (newAction: "add" | "delete" | "upload") => void;
  mapToConnectedDevicesPayload?: (
    connectedDevices: IConfigurableConnectedDevice[]
  ) => IConnectedDevice[];
  mapToConfigurableConnectedDevices?: (config: {
    connectedDevices: IConnectedDevice[];
    lastDeviceOrder?: number;
    imported?: boolean;
  }) => IConfigurableConnectedDevice[];
}

const AssetModalContext = createContext<IAssetModalContext>({});

export default AssetModalContext;
