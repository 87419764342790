// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { ParameterService } from "common/formatters/ParameterName";
import { Statuses } from "core/data/models/Data";

import getRiskTrendSimulationConfigSelector from "../../../selectors/getRiskTrendSimulationConfigSelector";

import "./SimulationLabel.less";

interface ISimulationLabelProps {
  modelId: string;
  implementationId: string;
}

const SimulationLabel = ({ modelId, implementationId }: ISimulationLabelProps) => {
  const intl = useIntl();
  const riskTrendSimulationConfig = useSelector(
    getRiskTrendSimulationConfigSelector
  );

  const configSucceeded =
    riskTrendSimulationConfig &&
    riskTrendSimulationConfig.status === Statuses.Succeeded;

  return (
    <div className="simulation-summary-label">
      {configSucceeded ? (
        <FormattedMessage
          defaultMessage="{conditionTrend} and {prognosis} (at {beginValue} - {endValue} {unit} {parameterName})"
          id="detail_page.risk_trend.risk_simulation_summary.chart_label"
          values={{
            conditionTrend: (
              <FormattedMessage
                defaultMessage="Condition Trend"
                id="detail_page.risk_trend.risk_simulation_summary.chart_label.condition_trend"
              />
            ),
            prognosis: (
              <FormattedMessage
                defaultMessage="Prognosis"
                id="detail_page.risk_trend.risk_simulation_summary.chart_label.prognosis"
              />
            ),
            beginValue: riskTrendSimulationConfig.data.DefaultBeginValue,
            endValue: riskTrendSimulationConfig.data.DefaultEndValue,
            unit: riskTrendSimulationConfig.data.Unit,
            parameterName: ParameterService.format(
              riskTrendSimulationConfig.data.ParameterName,
              modelId,
              implementationId,
              intl
            )
          }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="{conditionTrend} and {prognosis}"
          id="detail_page.risk_trend.risk_simulation_summary.chart_label_simple"
          values={{
            conditionTrend: (
              <FormattedMessage
                defaultMessage="Condition Trend"
                id="detail_page.risk_trend.risk_simulation_summary.chart_label.condition_trend"
              />
            ),
            prognosis: (
              <FormattedMessage
                defaultMessage="Prognosis"
                id="detail_page.risk_trend.risk_simulation_summary.chart_label.prognosis"
              />
            )
          }}
        />
      )}
    </div>
  );
};

export default SimulationLabel;
