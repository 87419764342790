// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { IntlShape } from "react-intl";
import { renderToStaticMarkup } from "react-dom/server";

interface INumberOfAssetsTooltipProps {
  intl: IntlShape;
  dataPoints: any[];
  colors: { [level: string]: string };
  title: string;
}

const NumberOfAssetsTooltip = ({
  intl,
  dataPoints,
  colors,
  title
}: INumberOfAssetsTooltipProps) => {
  return (
    <table className="c3-tooltip">
      <tr>
        <th colSpan={2}>{title}</th>
      </tr>
      {dataPoints.map((d) =>
        d.value !== null ? (
          <tr key={d.id}>
            <td>
              <span style={{ backgroundColor: colors[d.id] }}></span>
              {intl.formatMessage({
                id: `risk.${d.name.charAt(0).toUpperCase() + d.name.slice(1)}`,
                defaultMessage: d.name.charAt(0).toUpperCase() + d.name.slice(1)
              })}
            </td>
            <td>{d.value}</td>
          </tr>
        ) : null
      )}
    </table>
  );
};

export default NumberOfAssetsTooltip;

export const getStaticMarkup = (props: INumberOfAssetsTooltipProps) =>
  renderToStaticMarkup(<NumberOfAssetsTooltip {...props} />);
