// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { FormattedMessage } from "react-intl";

import "./BushingWeeklyInfo.less";

interface IBushingWeeklyInfoProps {
  warningColor: string;
  alertColor: string;
}

const BushingWeeklyInfo = ({
  warningColor,
  alertColor
}: IBushingWeeklyInfoProps) => (
  <div className="bushing-weekly-info">
    <div className="chart-legend__item-text">
      <span className="indicator" style={{ background: warningColor }}>
        <div className="dashed-line-warning-icon" />
      </span>
      <FormattedMessage
        id="detail_page.parameters.bushing_visualization.warning"
        defaultMessage="Warning"
      />
    </div>
    <div className="chart-legend__item-text">
      <span className="indicator" style={{ background: alertColor }}>
        <div className="dashed-line-alert-icon" />
      </span>
      <FormattedMessage
        id="detail_page.parameters.bushing_visualization.alert"
        defaultMessage="Alert"
      />
    </div>
  </div>
);

export default BushingWeeklyInfo;
