// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { select } from "d3";

import tiers from "../../../utils/tiersConfiguration";

const useAssetRiskDotsResize = () => {
  const resizeCurrentAssetDot = (container: HTMLDivElement) => {
    select(container)
      .select(".c3-target-current-asset .c3-circle")
      .attr("r", 8.5);
  };

  const resizeClusterDots = (container: HTMLDivElement) => {
    const selection = select(container);

    tiers.forEach(({ id, r }) => {
      selection.selectAll(`.c3-target-cluster-${id} .c3-circle`).attr("r", r);
    });
  };

  const resizePrognosticDots = (container: HTMLDivElement) => {
    select(container)
      .selectAll(".c3-target-prognostic .c3-circle")
      .attr("r", 8.5);
  };

  const resizeDots = (container: HTMLDivElement) => {
    resizeCurrentAssetDot(container);
    resizeClusterDots(container);
    resizePrognosticDots(container);
  };

  return { resizeDots };
};

export default useAssetRiskDotsResize;
