// Copyright 2016-2022 Hitachi Energy. All rights reserved.
const oilPreservationSystems = [
  "cops_open",
  "cops_sealed",
  "gas_space_air",
  "n2_blanket",
  "sealed",
  "unknown"
];

export default oilPreservationSystems;
