// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import IThreshold from "@apm/widgets/build/components/Parameters/models/IThreshold";
import { isNil } from "lodash";
import IParameterThreshold from "../models/IParameterThreshold";

const mapToThreshold = (
  threshold: IParameterThreshold | undefined
): IThreshold => {
  if (isNil(threshold)) return undefined;

  return {
    alarming: !threshold.IsNotAlarming,
    start: threshold.Start,
    end: threshold.End,
    state: threshold.State
  };
};

export default mapToThreshold;
