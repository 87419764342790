// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import { FormattedMessage } from "react-intl";
import "./EmptyState.less";

interface IEmptyProps {
  className?: string;
  defaultMessage?: string;
  id: string;
  iconName: string;
  iconClassName?: string;
  values?: Record<string, any>;
}

const EmptyState = ({
  className,
  defaultMessage = "Empty Data",
  id,
  iconName,
  iconClassName,
  values
}: IEmptyProps) => {
  return (
    <div className={`empty ${className}`}>
      <div className="empty-icon">
        <Icon className={iconClassName} name={iconName} />
      </div>
      <div className="empty-description">
        <FormattedMessage
          defaultMessage={defaultMessage}
          id={id}
          values={values}
        />
      </div>
    </div>
  );
};

export default EmptyState;
