// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IColumn, IRow } from "common/datagrid/DataGrid";
import * as React from "react";
import { FormattedNumber } from "react-intl";

const warnings: { [name: string]: boolean } = {};

export interface IBodyCellProps {
  column: IColumn;
  gridId: string;
  row: IRow;
  rows: IRow[];
}

const BodyCell = (props: IBodyCellProps) => {
  const { column, gridId, row, rows } = props;

  const value = row.data[column.config.id];
  const hasCustomComponent =
    column.config.component !== undefined && column.config.component != null;

  let formattedValue;
  if (!hasCustomComponent) {
    const isNumber = typeof value === "number";
    const isString = typeof value === "string";
    const isNullOrUndefined = value === undefined || value == null;

    if (isNumber) {
      formattedValue = <FormattedNumber value={value as number} />;
    } else if (isString || isNullOrUndefined) {
      formattedValue = value;
    } else if (!warnings[`${gridId}-${column.config.id}`]) {
      console.warn(
        `Column ${column.config.id}: Data type is not supported. Create custom component to display the data.`
      );
      warnings[`${gridId}-${column.config.id}`] = true;
    }
  }

  const styles = {
    width: column.state.calculatedWidth,
    minWidth: column.state.calculatedWidth,
    maxWidth: column.state.calculatedWidth
  };

  return (
    <div
      className={`
        grid-cell
        ${column.config.frozen && "frozen"}
      `}
      style={styles}
    >
      {hasCustomComponent ? (
        column.config.component(value, row, rows)
      ) : (
        <div className="grid-cell-content">{formattedValue}</div>
      )}
    </div>
  );
};

export default BodyCell;
