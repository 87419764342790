// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React, { useEffect, MutableRefObject } from "react";

import ChartLegendItem from "./ChartLegendItem";
import useLegend, { LegendItem } from "./hooks/useLegend";

import "./ChartLegend.less";

export interface IChartLegendProps {
  apiRef?: MutableRefObject<IChartLegendRef>;
  items: LegendItem[];
  itemClassName?: string;
  itemMessageIdPrefix: string;
  messageIdToPascalCase?: boolean;
  onFocus?: (itemId: string) => void;
  onRevert?: () => void;
  onToggle?: (itemId: string) => void;
}

export interface IChartLegendRef {
  reset: () => void;
}

const ChartLegend: React.FC<IChartLegendProps> = ({
  apiRef,
  items,
  itemClassName,
  itemMessageIdPrefix,
  messageIdToPascalCase,
  onFocus,
  onRevert,
  onToggle
}) => {
  const { statuses, focus, reset, revert, toggle } = useLegend(items || []);

  useEffect(() => {
    if (apiRef)
      apiRef.current = {
        reset: () => {
          reset();
        }
      };
  }, [apiRef, reset]);

  return (
    <div className="chart-legend">
      {(items || []).map((item: LegendItem, i: number) => (
        <ChartLegendItem
          className={itemClassName}
          index={i}
          item={item}
          key={`${item.id}_${i}`}
          messageIdPrefix={itemMessageIdPrefix}
          messageIdToPascalCase={messageIdToPascalCase}
          onMouseOver={() => {
            focus(item.id);
            if (onFocus) onFocus(item.id);
          }}
          onMouseOut={() => {
            revert();
            if (onRevert) onRevert();
          }}
          onClick={() => {
            toggle(item.id);
            if (onToggle) onToggle(item.id);
          }}
          status={statuses[item.id]}
        />
      ))}
    </div>
  );
};

export default ChartLegend;
