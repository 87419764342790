// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { getDataKey } from "../actions/loadSimulations";
import ISimulationSummary from "../models/ISimulationSummary";
import IValues from "../models/IValues";

const getSimulationsSelector = createSelector<
  IState,
  (assetId: string) => Data<IValues<ISimulationSummary>>,
  (assetId: string) => Data<IValues<ISimulationSummary>>
>(
  (state: IState) => (assetId: string) => state.data[getDataKey(assetId)],
  (simulationsData) => simulationsData
);

export default getSimulationsSelector;
