// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useThunkMiddleware } from "thunk";

import getNotificationsModelInputSelector from "../selectors/getNotificationsModelInputSelector";

import {
  loadNotificationsSettingsAction,
  resetToDefaultStateAction
} from "../actions/NotificationsActions";

import Notifications from "../components/Notifications";

const NotificationsContainer = () => {
  const dispatch = useDispatch();
  const modelInput = useSelector(getNotificationsModelInputSelector);

  const loadNotificationsSettings = useThunkMiddleware(
    loadNotificationsSettingsAction()
  );

  useEffect(() => {
    loadNotificationsSettings();
    return () => {
      dispatch(resetToDefaultStateAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Notifications modelInput={modelInput} />;
};

export default NotificationsContainer;
