// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { isEmpty } from "lodash";
import { useThunkMiddleware } from "thunk";

import useAgingContactWear from "features/detailpage/features/aging/hooks/useAgingContactWear";
import { loadContactWearAction } from "features/detailpage/features/aging/actions/AgingContactWearActions";

import AgingContactWear from "features/detailpage/features/aging/components/AgingContactWear";
import { Statuses } from "core/data/models/Data";

interface IAgingContactWearContainerProps {
  assetId: string;
}

export default ({ assetId }: IAgingContactWearContainerProps) => {
  const { agingContactWear } = useAgingContactWear();

  const loadContactWear = useThunkMiddleware(loadContactWearAction(assetId));

  React.useEffect(() => {
    if (
      agingContactWear.status !== Statuses.Succeeded ||
      isEmpty(agingContactWear.data)
    ) {
      loadContactWear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AgingContactWear agingContactWear={agingContactWear} />;
};
