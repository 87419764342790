// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useState, useMemo } from "react";

export enum Statuses {
  Active,
  NoActive,
  Disabled
}

export type LegendItem = {
  id: string;
  name?: string;
  color?: string;
  defaultSelected?: boolean;
};

type UseLegend = {
  [name: string]: UseLegendItem;
};

type UseLegendItem = {
  focused: boolean;
  selected: boolean;
};

type UseLegendStatuses = {
  [name: string]: Statuses;
};

const useLegend = (items: LegendItem[]) => {
  const getDefaultLegend = () => {
    const defaultLegend: UseLegend = {};
    items.forEach((i) => {
      defaultLegend[i.id] = {
        focused: false,
        selected: i.defaultSelected === false ? false : true
      };
    });
    return defaultLegend;
  };
  const [legend, setLegend] = useState(getDefaultLegend);

  const getStatuses = () => {
    const statuses: UseLegendStatuses = {};
    const numberOfFocused = Object.entries(legend)
      .map((i) => i[1])
      .filter((i) => i.focused).length;

    Object.keys(legend).forEach((id) => {
      let status: Statuses;

      if (numberOfFocused > 0) {
        if (legend[id].focused) {
          status = legend[id].selected ? Statuses.Active : Statuses.Disabled;
        } else {
          status = legend[id].selected ? Statuses.NoActive : Statuses.Disabled;
        }
      } else {
        status = legend[id].selected ? Statuses.Active : Statuses.Disabled;
      }

      statuses[id] = status;
    });

    return statuses;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const statuses = useMemo(() => getStatuses(), [legend]);

  const allActive = useMemo(
    () => !Object.values(legend).some((x) => !x.selected),
    [legend]
  );

  const focus = (itemId: string) => {
    const newLegend = Object.assign({}, legend);
    Object.keys(newLegend).forEach((id) => {
      newLegend[id] = {
        ...legend[id],
        focused: id === itemId ? true : legend[id].focused
      };
    });

    setLegend(newLegend);
  };

  const reset = () => {
    const defaultLegend = getDefaultLegend();
    setLegend(defaultLegend);
  };

  const revert = () => {
    const newLegend = Object.assign({}, legend);
    Object.keys(newLegend).forEach((id) => {
      newLegend[id] = {
        ...legend[id],
        focused: false
      };
    });

    setLegend(newLegend);
  };

  const toggle = (itemId: string) => {
    const newLegend = Object.assign({}, legend);
    Object.keys(newLegend).forEach((id) => {
      newLegend[id] = {
        ...legend[id],
        selected: id === itemId ? !legend[id].selected : legend[id].selected
      };
    });

    setLegend(newLegend);
  };

  const toggleAll = (selected: boolean) => {
    const newLegend = Object.assign({}, legend);
    Object.keys(newLegend).forEach((id) => {
      newLegend[id] = {
        ...legend[id],
        selected
      };
    });

    setLegend(newLegend);
  };

  return {
    statuses,
    allActive,
    focus,
    reset,
    revert,
    toggle,
    toggleAll
  };
};

export default useLegend;
