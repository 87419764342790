// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import loadData from "core/data/actions/loadData";

export const dataKey = "DuvalPentagons_Data";

export const loadDuvalPentagons = (assetId: string) => {
  const url = UrlService.getApiUrl(
    config.api.detailPage.assetDuvalPentagonsGasesConcentrationUrl,
    { assetId }
  );

  return loadData({ key: dataKey, url, method: "get" });
};
