// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Popover, Typography } from "antd";
import ReplacementScoreFormatter from "common/formatters/ReplacementScore";
import { ReplacementScoreTooltip } from "common/ReplacementScore";
import * as React from "react";
import { useMemo } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import styled from "styled-components";
import { colorStatusOrange } from "styles/ColorVariables";

const { Text } = Typography;

interface IReplacementScoreProps {
  isWarning: boolean;
  replacementScore: number;
  assetType: string;
  rank?: number;
  total?: number;
  className?: string;
}

const ReplacementScore = ({
  isWarning,
  replacementScore,
  rank,
  total,
  assetType,
  className
}: IReplacementScoreProps) => {
  const showRank = useMemo(() => rank != null && total != null, [rank, total]);

  return (
    <div
      className={`${className} 
          column
          replacement-score
          ${isWarning && "warning"}
        `}
    >
      {showRank && (
        <Popover
          content={
            <ReplacementScoreTooltip
              rank={rank}
              total={total}
              scoreValue={replacementScore}
              assetType={assetType}
            />
          }
        >
          <div>
            <Text strong className="replacement-score-rank">
              <FormattedMessage
                id="asset_detail_page.replacement_score.rank.selector"
                defaultMessage="#"
              />
              <FormattedNumber value={rank} />
            </Text>
            <br></br>
            <ReplacementScoreFormatter value={replacementScore} />
          </div>
        </Popover>
      )}
      {!showRank && <ReplacementScoreFormatter value={replacementScore} />}
    </div>
  );
};
const defaultGridPadding = "10px";

export default styled(ReplacementScore)`
  padding: ${defaultGridPadding};

  &.warning,
  &.warning .replacement-score-rank {
    color: ${colorStatusOrange};
  }
`;
