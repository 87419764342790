// Copyright 2016-2022 Hitachi Energy. All rights reserved.
// components
import Processing from "components/common/Processing";
import * as React from "react";

const AssetRiskDashboardLoading = (): JSX.Element => (
  <div className="bootstrap-row asset-risk-dashboard__row--center">
    <div className="tile">
      <div className="tile-content processing">
        <Processing className="spinner small dark" />
      </div>
    </div>
  </div>
);

export default AssetRiskDashboardLoading;
