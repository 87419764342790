// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { moisture } from "./StandardOilTests/StandardOilTestsTabService";
import isThreshold from "./isThreshold";

import {
  colorBlue60,
  colorTeal40,
  colorTeal60,
  colorTeal80,
  colorTeal100
} from "styles/ColorVariables";

const getThresholdColor = (name: string) => {
  if (name.endsWith("_min")) {
    return colorTeal40;
  } else if (name.endsWith("_max")) {
    return colorTeal60;
  } else if (name.endsWith("_poor")) {
    return colorTeal80;
  } else {
    return colorTeal100;
  }
};

const getSeriesColor = (name: string, isLeftAxis: boolean) => {
  if (name === `${moisture}_offline`) return "black";
  if (isThreshold(name)) return getThresholdColor(name);
  if (isLeftAxis) return colorBlue60;
  return "#F2AC66";
};

export default getSeriesColor;
