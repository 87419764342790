// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import IssueCompact, {
  IIssueCompactActions,
  IIssueCompactOwnProps
} from "features/detailpage/features/issues/components/IssueCompact";
import { IntlShape } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { Action, Dispatch } from "redux";
import { editIssueStatus, updateActiveIssue } from "../actions/IssuesActions";
import { IssueStatuses } from "../models/IssueStatuses";

export function mapStateToProps(state: IState): {} {
  return {};
}

export function mapDispatchToProps(
  dispatch: Dispatch<Action>,
  ownProps: IIssueCompactOwnProps
): IIssueCompactActions {
  return {
    editIssueStatus: (
      intl: IntlShape,
      issueId: string,
      status: IssueStatuses
    ) => editIssueStatus(dispatch, intl, issueId, status),
    updateActiveIssue: (issueId) => updateActiveIssue(dispatch, issueId)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueCompact);
