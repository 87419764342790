// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Reducer } from "redux";

import { Actions as AgingTotalOperationsActions } from "features/detailpage/features/aging/actions/AgingOperationsActions";
import { Actions as AgingContactWearActions } from "features/detailpage/features/aging/actions/AgingContactWearActions";
import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";

import { IAgingOperations } from "features/detailpage/features/aging/models/IAgingOperations";
import { IAgingContactWear } from "../models/IAgingContactWear";

export interface IState {
  agingOperations: Data<IAgingOperations>;
  agingContactWear: Data<IAgingContactWear>;
}

export const defaultState: IState = {
  agingOperations: new Data(),
  agingContactWear: new Data()
};

const reducer: Reducer<IState> = (
  state = defaultState,
  action: IAjaxAction<IAgingOperations | IAgingContactWear>
) => {
  switch (action.type) {
    case AgingTotalOperationsActions.DetailPage_Aging_SetOperations:
      return {
        ...state,
        agingOperations: new Data(
          (action as IAjaxAction<IAgingOperations>).request,
          (action as IAjaxAction<IAgingOperations>).data
        )
      };
    case AgingContactWearActions.DetailPage_Aging_SetContactWear:
      return {
        ...state,
        agingContactWear: new Data(
          (action as IAjaxAction<IAgingContactWear>).request,
          (action as IAjaxAction<IAgingContactWear>).data
        )
      };
    default:
      return state;
  }
};

export default reducer;
