// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import { IState } from "reducers/Index";

import getRiskMatrixConfigurationSelector from "./getRiskMatrixConfigurationSelector";

const getRiskMatrixLevelConfigurationSelector = createSelector(
  getRiskMatrixConfigurationSelector,
  (state: IState) => state.detailPage.riskMatrix.isComponent,
  (riskMatrixConfiguration, isComponent) => {
    if (riskMatrixConfiguration && riskMatrixConfiguration.data) {
      return isComponent
        ? riskMatrixConfiguration.data.ComponentConfiguration
        : riskMatrixConfiguration.data.AssetConfiguration;
    }

    return undefined;
  }
);

export default getRiskMatrixLevelConfigurationSelector;
