// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

export const getDataKey = (assetId: string) =>
  `DetailPage_Parameters_OperationsEvents-${assetId}`;

const loadEvents = (assetId: string) => {
  const url = UrlService.getApiUrl(
    config.api.detailPage.parametersOperationsEventsUrl,
    { assetId }
  );

  return loadData({ key: getDataKey(assetId), url, method: "get" });
};

export default loadEvents;
