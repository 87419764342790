// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SF6Chart from "@apm/widgets/build/widgets/SF6Chart";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import { Statuses } from "core/data/models/Data";
import { isEmpty } from "lodash";
import { useIntl } from "react-intl";
import { IModelDetails } from "../../DielectricTab/DielectricTab";
import useSF6PressureTimedChart from "../hooks/useSF6PressureTimedChart";

interface ISF6PressureTimedChartProps {
  model: IModelDetails;
  showAdjustToThresholdsSwitch?: boolean;
}

const SF6PressureTimedChart = ({
  model,
  showAdjustToThresholdsSwitch
}: ISF6PressureTimedChartProps) => {
  const intl = useIntl();

  const {
    dielectric,
    series,
    regions,
    lines,
    formatXTick,
    formatTooltipTitle,
    title,
    height,
    emptyTranslation
  } = useSF6PressureTimedChart(model);

  switch (dielectric.status) {
    case Statuses.Loading:
      return <Processing className="spinner small dark" />;
    case Statuses.Succeeded:
      return isEmpty(dielectric.data) ? (
        <WidgetNoDataMessage />
      ) : (
        <SF6Chart
          title={title}
          series={series}
          regions={regions}
          lines={lines}
          formatXTick={formatXTick}
          formatTooltipTitle={formatTooltipTitle}
          adjustYSizeToThresholds={true}
          height={height}
          showAdjustToThresholdsSwitch={showAdjustToThresholdsSwitch}
          translations={{
            adjustToThresholds: intl.formatMessage({
              defaultMessage: "Adjust to thresholds",
              id: "detail_page.adjust_to_thresholds"
            }),
            empty: emptyTranslation
          }}
        ></SF6Chart>
      );
    case Statuses.NotFound:
      return <WidgetNoDataMessage />;
    case Statuses.Failed:
      return <WidgetErrorMessage />;
    default:
      return (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      );
  }
};

export default SF6PressureTimedChart;
