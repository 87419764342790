// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useCallback, useMemo } from "react";
import { ChartAPI } from "c3";
import BushingOfflineChartService from "features/detailpage/features/bushingsoffline/services/BushingOfflineChartService";

interface IChartsRef {
  [id: string]: ChartAPI;
}

const useBushingOfflineCharts = () => {
  const settings = BushingOfflineChartService.getBushingChartsSettings();

  const chartsRef = useMemo((): IChartsRef => ({}), []);

  const onChartRendered = useCallback(
    (chartApi: ChartAPI, id: string) => {
      chartsRef[id] = chartApi;
    },
    [chartsRef]
  );

  const focusPlotSeries = useCallback(
    (series: string, plotName: string) => {
      const chartsIds = series.split(",");
      chartsIds.forEach((chartId) => {
        if (chartsRef && chartsRef[chartId]) chartsRef[chartId].focus(plotName);
      });
    },
    [chartsRef]
  );

  const revertPlotSeries = useCallback(
    (series: string) => {
      const chartsIds = series.split(",");
      chartsIds.forEach((chartId) => {
        if (chartsRef && chartsRef[chartId]) chartsRef[chartId].revert();
      });
    },
    [chartsRef]
  );

  const togglePlotSeries = useCallback(
    (series: string, plotName: string) => {
      const chartsIds = series.split(",");
      chartsIds.forEach((chartId) => {
        if (chartsRef && chartsRef[chartId])
          chartsRef[chartId].toggle(plotName);
      });
    },
    [chartsRef]
  );

  return {
    focusPlotSeries,
    revertPlotSeries,
    togglePlotSeries,
    onChartRendered,
    settings
  };
};

export default useBushingOfflineCharts;
