// Copyright 2016-2022 Hitachi Energy. All rights reserved.
/// <reference path="../types/intl-datetimeformat.d.ts" />

import { shouldPolyfill } from "@formatjs/intl-datetimeformat/should-polyfill";
import moment from "moment-timezone";

export const setDefaultTomeZone = (timeZone: string) => {
  if ("__setDefaultTimeZone" in Intl.DateTimeFormat) {
    try {
      console.log(`Set default time zone to: ${timeZone}.`);
      (Intl.DateTimeFormat as any).__setDefaultTimeZone(timeZone);
    } catch {
      console.log(`Cannot set default time zone.`);
    }
  }
};

const polyfillDateTimeFormat = (locale: string): Promise<any> => {
  if (!shouldPolyfill()) return Promise.resolve();
  return Promise.all([
    import("@formatjs/intl-datetimeformat/polyfill"),
    import("@formatjs/intl-datetimeformat/add-all-tz"),
    import("@formatjs/intl-datetimeformat/locale-data/es"),
    import("@formatjs/intl-datetimeformat/locale-data/pl"),
    import("@formatjs/intl-datetimeformat/locale-data/zh"),
    import("@formatjs/intl-datetimeformat/locale-data/ja"),
    import("@formatjs/intl-datetimeformat/locale-data/pt"),
    import("@formatjs/intl-datetimeformat/locale-data/de"),
    import("@formatjs/intl-datetimeformat/locale-data/en")
  ]).then(() => {
    const timeZone = moment.tz.guess();
    setDefaultTomeZone(timeZone);
  });
};

export default polyfillDateTimeFormat;
