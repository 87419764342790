// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getNotificationsEnabledSelector = createSelector(
  (state: IState) => state.notifications.notificationsEnabled,
  notificationsEnabled => notificationsEnabled
);

export default getNotificationsEnabledSelector;
