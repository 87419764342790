// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

import PbiReportTab from "../components/PbiReportTab";
import ReportsHeaderTab from "../components/ReportsHeaderTab";

import { IRoutableTab } from "common/RoutableTabs";
import { UserRoles } from "core/app/components/auth/Authorization";
import {
  IVisualizationInfoDictionary,
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";

interface IUseReportsTabsOptions {
  assetType: string;
  assetId: string;
  loadPbiReports: (assetType: string) => void;
  user: IUser;
}

const useReportsTabs = ({
  assetType,
  assetId,
  loadPbiReports,
  user
}: IUseReportsTabsOptions) => {
  const intl = useIntl();
  const { getAvailable } = useFeature();
  const isAuthorized = useMemo(() => {
    return AuthorizationService.isAuthorized(user, [
      UserRoles.Administrator,
      UserRoles.Engineer,
      UserRoles.ViewerEngineer
    ]);
  }, [user]);

  const visualizations: IVisualizationInfoDictionary<IRoutableTab> =
    useMemo(() => {
      return {
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Reports]: {
          Content: () => <PbiReportTab />,
          HeaderContent: () => (
            <ReportsHeaderTab assetId={assetId} assetType={assetType} />
          ),
          id: "EmbeddedReports",
          name: intl.formatMessage({
            defaultMessage: "Embedded reports",
            id: "detail_page.widgets.embedded_reports"
          }),
          noLink: true,
          className: "pbi-report-tab-body"
        }
      };
    }, [assetId, assetType, intl]);

  const reportsTabs: IRoutableTab[] = useMemo(() => {
    if (isAuthorized) {
      return getAvailable(visualizations);
    }

    return [];
  }, [isAuthorized, visualizations, getAvailable]);

  useEffect(() => {
    if (isAuthorized) {
      loadPbiReports(assetType);
    }
  }, [isAuthorized, assetType, loadPbiReports]);

  return reportsTabs;
};

export default useReportsTabs;
