// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";

import DegradationScore from "common/formatters/DegradationScore";

import "./Condition.less";

interface IConditionProps {
  value: number;
}

const Condition = ({ value }: IConditionProps) => {
  return (
    <div className="column condition">
      <DegradationScore value={value} />
    </div>
  );
};

export default Condition;
