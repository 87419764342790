// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { FormattedMessage } from "react-intl";

import "./PhasesInfo.less";

interface IPhasesInfoProps {
  phaseAColor?: string;
  phaseBColor?: string;
  phaseCColor?: string;
}

const PhasesInfo = ({
  phaseAColor,
  phaseBColor,
  phaseCColor
}: IPhasesInfoProps) => (
  <div className="phases-info">
    <div className="chart-legend__item-text">
      <span className="indicator" style={{ background: phaseAColor }} />
      <FormattedMessage
        id="detail_page.parameters.bushing_visualization.phase_a"
        defaultMessage="Phase A"
      />
    </div>
    <div className="chart-legend__item-text">
      <span className="indicator" style={{ background: phaseBColor }} />
      <FormattedMessage
        id="detail_page.parameters.bushing_visualization.phase_b"
        defaultMessage="Phase B"
      />
    </div>
    <div className="chart-legend__item-text">
      <span className="indicator" style={{ background: phaseCColor }} />
      <FormattedMessage
        id="detail_page.parameters.bushing_visualization.phase_c"
        defaultMessage="Phase C"
      />
    </div>
  </div>
);

export default PhasesInfo;
