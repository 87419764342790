// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";
import { MessageDescriptor, useIntl } from "react-intl";

import Guid from "core/guid/Guid";

import ConfigurationBoxHeader from "./ConfigurationBoxHeader";
import { ITreeNode } from "common/form/components/Tree";
import TreeInputContainer from "common/form/containers/TreeInputContainer";

import { formName } from "features/notifications/selectors/getNotificationsFormSelector";

import "./ConfigurationBox.less";

export interface IConfigurationBoxActionProps {
  handleOnTreeInputChange?: (
    checkedKeys: string[],
    allChecked?: boolean
  ) => void;
}

export interface IConfigurationBoxProps {
  headerMessage: MessageDescriptor;
  options: ITreeNode[];
  inputName: string;
  allChecked: boolean;
  defaultCheckedKeys?: string[];
  disabled?: boolean;
  dataQa?: string;
  handleOnAllChecked?: (checked: boolean) => void;
  handleAllKeysCheckedChanged?: (allKeysChecked: boolean) => void;
}

const ConfigurationBox = ({
  headerMessage,
  options,
  disabled,
  defaultCheckedKeys,
  inputName,
  allChecked,
  dataQa,
  handleOnTreeInputChange,
  handleOnAllChecked,
  handleAllKeysCheckedChanged
}: IConfigurationBoxProps & IConfigurationBoxActionProps) => {
  const intl = useIntl();
  const expandedKeys = React.useMemo(
    () => options.filter((o) => o.options).map((i) => i.id),
    [options]
  );

  const [key, setKey] = React.useState(Guid.getUniqGuid());
  React.useEffect(() => {
    if (allChecked !== undefined) {
      setKey(Guid.getUniqGuid());
    }
  }, [allChecked]);

  return (
    <div className="configuration-box" data-qa={dataQa}>
      <ConfigurationBoxHeader
        headerName={intl.formatMessage(headerMessage)}
        disabled={disabled}
        checked={allChecked}
        onChange={handleOnAllChecked}
      />
      <div className="tree-input-options" key={key}>
        <TreeInputContainer
          formName={formName}
          inputName={inputName}
          disabled={disabled}
          defaultValue={defaultCheckedKeys}
          items={options}
          expandedKeys={expandedKeys}
          selectedKeys={[]}
          onChange={handleOnTreeInputChange}
          onAllKeysCheckedChanged={handleAllKeysCheckedChanged}
        />
      </div>
    </div>
  );
};

export default ConfigurationBox;
