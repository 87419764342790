// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { useSelector } from "react-redux";

import getNotificationsEnabledSelector from "../selectors/getNotificationsEnabledSelector";
import getAssetTypesOptionsSelector from "../selectors/getAssetTypesOptionsSelector";
import getAssetTypesCheckedKeys from "../selectors/getAssetTypesCheckedKeys";
import getAllAssetTypesCheckedSelector from "../selectors/getAllAssetTypesCheckedSelector";

import AssetTypes from "../components/AssetTypes";

const AssetTypesContainer = () => {
  const notificationsEnabled = useSelector(getNotificationsEnabledSelector);
  const items = useSelector(getAssetTypesOptionsSelector);
  const defaultCheckedKeys = useSelector(getAssetTypesCheckedKeys);
  const allAssetTypesChecked = useSelector(getAllAssetTypesCheckedSelector);
  return (
    <AssetTypes
      notificationsEnabled={notificationsEnabled}
      items={items}
      defaultCheckedKeys={defaultCheckedKeys}
      allAssetTypesChecked={allAssetTypesChecked}
    />
  );
};

export default AssetTypesContainer;
