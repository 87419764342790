// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import TileErrorMessage from "common/tile/TileErrorMessage";
import Processing from "components/common/Processing";
import { Statuses } from "core/data/models/Data";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import useOpenIssues from "../hooks/useOpenIssues";
import "./OpenIssues.less";
import OpenIssuesChart from "./OpenIssuesChart";
import OpenIssuesFooter from "./OpenIssuesFooter";
import OpenIssuesGrid from "./OpenIssuesGrid";

const OpenIssues = () => {
  const { openIssues } = useOpenIssues();
  let component: JSX.Element;

  switch (openIssues.status) {
    case Statuses.Loading: {
      component = (
        <div className="tile">
          <div className="tile-content processing">
            <Processing className="spinner small dark" />
          </div>
        </div>
      );
      break;
    }
    case Statuses.Succeeded: {
      component = (
        <div className="tile" data-qa="open-issues-tile">
          <div className="tile-content">
            <div className="title" data-qa="title">
              <FormattedMessage
                id="homepage.opened_issues"
                defaultMessage="Issues"
              />
            </div>
            <OpenIssuesChart openIssues={openIssues.data} />
            <OpenIssuesGrid issues={openIssues.data?.MostImportantIssues} />
          </div>
          <OpenIssuesFooter />
        </div>
      );
      break;
    }
    case Statuses.Failed: {
      component = (
        <TileErrorMessage
          messageId="homepage.server_error"
          defaultMessage="Sorry, we've got problems with server connection."
          className="open-issues"
        />
      );
      break;
    }
    default: {
      component = (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      );
    }
  }

  return <div className="open-issues">{component}</div>;
};

export default OpenIssues;
