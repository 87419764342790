// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { createInput, setInputValue } from "../actions/FormActions";
import { IState } from "reducers/Index";

import SelectInput, {
  ISelectInputOwnProps,
  ISelectInputData,
  ISelectInputActions
} from "../components/SelectInput";

import IValidator from "../validators/IValidator";
import RequiredValidator from "../validators/RequiredValidator";
import Guid from "core/guid/Guid";

export function mapStateToProps(
  state: IState,
  ownProps: ISelectInputOwnProps
): ISelectInputData {
  const form = state.common.forms && state.common.forms.get(ownProps.formName);
  const input = form && form.inputs && form.inputs.get(ownProps.inputName);
  const errorMessages = input && input.errorMessages;
  const guid = input ? input.guid : Guid.getUniqGuid();

  return {
    validationMessages: errorMessages && errorMessages.toArray(),
    guid
  };
}

export function mapDispatchToProps(
  dispatch: Dispatch<Action>,
  ownProps: ISelectInputOwnProps
): ISelectInputActions {
  return {
    createInput: () =>
      createInput(
        dispatch,
        ownProps.formName,
        ownProps.inputName,
        ownProps.defaultValue,
        ownProps.defaultValue
      ),
    setInputValue: (value: any) => {
      const requiredValidator: IValidator<string> = new RequiredValidator();
      const valueIsRequired = ownProps.required === true;
      const computedValidators = valueIsRequired
        ? [requiredValidator].concat(ownProps.validators || [])
        : ownProps.validators;

      setInputValue(
        dispatch,
        ownProps.formName,
        ownProps.inputName,
        value,
        computedValidators
      );
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectInput);
