import ISelectOption from "../models/ISelectOption";

const dataTypeOptions: ISelectOption[] = [
  {
    value: "Int",
    label: "Int"
  },
  {
    value: "Float",
    label: "Float"
  },
  {
    value: "String",
    label: "String"
  },
  {
    value: "Undefined",
    label: "Undefined"
  }
];

export default dataTypeOptions;
