// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import ChartLegend, { LegendItem } from "common/ChartLegend";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import useChartId from "../hooks/useChartId";
import useChartRef from "../hooks/useChartRef";
import useChartSize from "../hooks/useChartSize";
import IConfiguration from "../models/IConfiguration";
import ISeriesState from "../models/ISeriesState";
import AxesService from "../services/AxesService";
import ChartService from "../services/ChartService";
import GridlineService from "../services/GridlineService";
import SeriesService from "../services/SeriesService";
import SvgService from "../services/SvgService";
import "./PhaseAngleChart.less";
import PhasesInfo from "./PhasesInfo";
import RecentReadingInfo from "./RecentReadingInfo";

export interface ILegendState {
  [name: string]: {
    focused: boolean;
    selected: boolean;
  };
}

export interface IPhaseAngleChartProps {
  configuration: IConfiguration;
}

const phaseAColor = "rgba(194, 114, 35, 0.2)";
const phaseBColor = "rgba(252, 201, 50, 0.2)";
const phaseCColor = "rgba(76, 133, 255, 0.2)";

const PhaseAngleChart = ({ configuration }: IPhaseAngleChartProps) => {
  const intl = useIntl();
  const axes = [
    "0",
    "30",
    "60",
    "90",
    "120",
    "150",
    "180",
    "210",
    "240",
    "270",
    "300",
    "330"
  ].map((v) =>
    intl.formatMessage({
      defaultMessage: v,
      id: `detail_page.parameters.bushing_visualization.phase_chart.${v}`
    })
  );

  const chartRef = useChartRef();
  const tooltipRef = useRef<HTMLDivElement>();
  const [chartId] = useChartId();
  const [chartSize, setChartSize] = useChartSize();

  const [legendState, setLegendState] = useState<ILegendState>();

  useEffect(() => {
    const chartWidth = chartRef.current.getBoundingClientRect().width;

    setChartSize({
      height: chartWidth,
      width: chartWidth
    });
  }, [chartRef, setChartSize]);

  useEffect(() => {
    const legendState: ILegendState = {};
    if (configuration && configuration.series && configuration.series) {
      configuration.series.forEach((s) => {
        legendState[s.id] = {
          focused: false,
          selected: true
        };
      });
    }

    setLegendState(legendState);
  }, [configuration]);

  useEffect(() => {
    const { drawAxes } = AxesService;
    const { drawBackground, getScale, getTicks } = ChartService;
    const { drawGridlines } = GridlineService;
    const { drawSeries } = SeriesService;
    const { createSvg, removeSvg } = SvgService;

    removeSvg(chartId);

    if (chartSize && configuration && configuration.series) {
      const labels = axes.map((a) => a.toString());
      const ticks = getTicks(
        configuration.series,
        configuration.alertValue,
        configuration.warningValue
      );
      const range = Math.max(...ticks.map((t) => t.value));
      const svg = createSvg(chartId, chartSize.width, chartSize.height);
      const scale = getScale(chartSize.width, chartSize.height, ticks);
      drawBackground(
        svg,
        chartSize.width,
        chartSize.height,
        ticks,
        scale,
        phaseAColor,
        phaseBColor,
        phaseCColor
      );
      drawAxes(svg, chartSize.width, chartSize.height, labels, range, scale);
      drawGridlines(svg, chartSize.width, chartSize.height, ticks, scale);
      drawSeries(
        svg,
        tooltipRef.current,
        intl,
        chartSize.width,
        chartSize.height,
        scale,
        configuration.series
      );
    }
  }, [chartRef, chartId, chartSize, configuration, axes, intl]);

  useEffect(() => {
    const { updateSeries } = SeriesService;
    const seriesState =
      legendState &&
      Object.keys(legendState).map<ISeriesState>((k) => ({
        id: k,
        focused: legendState[k].focused,
        selected: legendState[k].selected
      }));

    updateSeries(chartId, seriesState);
  }, [chartId, legendState]);

  const legendItems = useMemo(
    () =>
      configuration &&
      configuration.series &&
      configuration.series.map<LegendItem>((s) => ({
        id: s.id,
        name: s.id,
        color: s.color
      })),
    [configuration]
  );

  return (
    <div className="phase-angle-chart">
      <div id={chartId} ref={chartRef} />
      <div className="phase-angle-chart-legend">
        <ChartLegend
          items={legendItems}
          itemMessageIdPrefix="detail_page.parameters.bushing_visualization"
          onFocus={(itemId) => {
            const newLegendState = { ...legendState };
            newLegendState[itemId].focused = true;
            setLegendState(newLegendState);
          }}
          onRevert={() => {
            const newLegendState = { ...legendState };
            Object.keys(newLegendState).forEach((k) => {
              newLegendState[k].focused = false;
            });
            setLegendState(newLegendState);
          }}
          onToggle={(itemId) => {
            const newLegendState = { ...legendState };
            newLegendState[itemId].selected = !newLegendState[itemId].selected;
            setLegendState(newLegendState);
          }}
        />
        <RecentReadingInfo />
        <PhasesInfo
          phaseAColor={phaseAColor}
          phaseBColor={phaseBColor}
          phaseCColor={phaseCColor}
        />
      </div>
      <div className="phase-angle-tooltip" ref={tooltipRef} />
    </div>
  );
};

export default PhaseAngleChart;
