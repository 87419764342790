// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data from "core/data/models/Data";
import { IAjaxAction, IState as RootState } from "reducers/Index";
import { Reducer } from "redux";
import { Actions, ISetPageAction } from "../actions/NumberOfAssetsActions";

// #region Interfaces

export enum Page {
  ByType,
  ByOrganization
}

export interface INumberOfAssets {
  [name: string]: {
    HighRisk: number;
    MediumRisk: number;
    LowRisk: number;
    UnknownRisk: number;
  };
}

export interface INumberOfAssetsStateProps {
  page: Page;
  numberOfAssetsByType: Data<INumberOfAssets | null>;
  numberOfAssetsByOrganization: Data<INumberOfAssets | null>;
}

export interface INumberOfAssetsState {
  numberOfAssets: INumberOfAssetsStateProps;
}

// #endregion Interfaces

// #region Reducer

interface INumberOfAssetsAction
  extends IAjaxAction<INumberOfAssets>,
    ISetPageAction {}

export const defaultState: INumberOfAssetsState = {
  numberOfAssets: {
    page: Page.ByType,
    numberOfAssetsByType: new Data(),
    numberOfAssetsByOrganization: new Data()
  }
};

const reducer: Reducer<INumberOfAssetsState> = (
  state: INumberOfAssetsState = defaultState,
  action: INumberOfAssetsAction
) => {
  switch (action.type) {
    case Actions.NumberOfAssets_LoadNumberOfAssetsByType:
      return {
        ...state,
        numberOfAssets: {
          ...state.numberOfAssets,
          numberOfAssetsByType: new Data(action.request, action.data)
        }
      };
    case Actions.NumberOfAssets_LoadNumberOfAssetsByOrganizations:
      return {
        ...state,
        numberOfAssets: {
          ...state.numberOfAssets,
          numberOfAssetsByOrganization: new Data(action.request, action.data)
        }
      };
    case Actions.NumberOfAssets_SetPage:
      return {
        ...state,
        numberOfAssets: {
          ...state.numberOfAssets,
          page: action.page
        }
      };
    default:
      return state;
  }
};

export default reducer;

// #endregion Reducer

// #region Selectors

export const getNumberOfAssetsByTypeSelector = (state: RootState) =>
  state.mainDashboard.numberOfAssets.numberOfAssetsByType;

export const getNumberOfAssetsByOrganizationSelector = (state: RootState) =>
  state.mainDashboard.numberOfAssets.numberOfAssetsByOrganization;

export const getPageSelector = (state: RootState) =>
  state.mainDashboard.numberOfAssets.page;

// #endregion
