// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Map } from "immutable";
import * as React from "react";

import IIssue from "features/detailpage/features/issues/models/IIssue";
import IssueMeta from "features/detailpage/features/issues/models/IssueMeta";
import IssueModes from "features/detailpage/features/issues/models/IssueModes";
import Issue from "./Issue";

interface IListProps {
  currentIssueId: string;
  className: string;
  disabledIfView?: boolean;
  issues: IIssue[];
  metas: Map<string, IssueMeta>;
  onIssueClick: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    issueId: string
  ) => void;
  onIssueSaved: (issueId: string) => void;
  onIssueStatusSaved: (issueId: string) => void;
  readonly?: boolean;
  urgencies: string[];
}

const IssueList = ({
  currentIssueId,
  className,
  disabledIfView,
  issues,
  metas,
  onIssueClick,
  onIssueSaved,
  onIssueStatusSaved,
  readonly,
  urgencies
}: IListProps) => {
  return (
    <div className={`list ${className}`}>
      {issues
        ? issues.map((i) => {
            const meta = metas.get(i.Id);

            return (
              <Issue
                currentIssueId={currentIssueId}
                disabled={disabledIfView && meta.mode === IssueModes.View}
                issue={i}
                key={i.Id}
                meta={meta}
                onClick={(e) => {
                  if (onIssueClick) onIssueClick(e, i.Id);
                }}
                onSaved={() => {
                  if (onIssueSaved) onIssueSaved(i.Id);
                }}
                onStatusSaved={() => {
                  if (onIssueStatusSaved) onIssueStatusSaved(i.Id);
                }}
                readonly={readonly}
                urgencies={urgencies}
              />
            );
          })
        : null}
    </div>
  );
};

export default IssueList;
