// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import * as React from "react";
import { FormattedMessage } from "react-intl";

const ActionsFailed = () => (
  <div className="slide-panel-content failed">
    <Icon name="warning" />
    <FormattedMessage
      defaultMessage="Getting actions data failed."
      id="issues_page.slide_panel.actions.loading_failed"
    />
  </div>
);

export default ActionsFailed;
