// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Drawer, Typography } from "antd";
import { IRow, IRowData } from "common/datagrid/DataGrid";
import Switch, { ISwitchItem } from "common/form/components/Switch";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { defaultDrawerWidth, spacingMedium } from "styles/StyleVariables";
import LocationsPanelContentSwitchOption from "../models/LocationsPanelContentSwitchOption";
import AssetsGrid from "./AssetsGrid";
import IssuesList from "./IssuesList";

const { Text } = Typography;

interface ILocationsPanelProps {
  className?: string;
  row: IRow<IRowData>;
  isLocationsPanelVisible: boolean;
  handleLocationsPanelClose: () => void;
  selectedContent: LocationsPanelContentSwitchOption;
  setSelectedContent: (option: LocationsPanelContentSwitchOption) => void;
  contentItems: ISwitchItem[];
}

const LocationsPanel = ({
  className,
  row,
  isLocationsPanelVisible,
  handleLocationsPanelClose,
  selectedContent,
  setSelectedContent,
  contentItems
}: ILocationsPanelProps) => {
  return (
    <Drawer
      placement="right"
      width={defaultDrawerWidth}
      onClose={handleLocationsPanelClose}
      visible={isLocationsPanelVisible}
      getContainer={false}
      destroyOnClose={true}
      mask={false}
      className={className}
      zIndex={900}
    >
      {row && (
        <>
          <div className="locations-summary">
            <div className="location-details">
              <Text strong>{row.data["Location"]}</Text>
              <FormattedMessage
                defaultMessage="{organization} / {region}"
                id="locations_page.grid.columns.organization_region"
                values={{
                  organization: row.data["Organization"],
                  region: row.data["Region"]
                }}
              />
            </div>
            <Switch
              defaultSelected={selectedContent}
              items={contentItems}
              onChange={setSelectedContent}
            />
          </div>

          <div className="panel-content">
            {selectedContent === "Assets" && <AssetsGrid row={row} />}
            {selectedContent === "Issues" && (
              <IssuesList
                organization={row.data["Organization"]}
                region={row.data["Region"]}
                location={row.data["Location"]}
              />
            )}
          </div>
        </>
      )}
    </Drawer>
  );
};

export default styled(LocationsPanel)`
  position: absolute;

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .locations-summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: ${spacingMedium};

    .location-details {
      display: flex;
      flex-direction: column;
    }
  }
  .panel-content {
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
  }
`;
