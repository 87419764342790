// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import { Checkbox } from "antd";
import DataGrid, { IColumnConfig, IRowData } from "common/datagrid/DataGrid";
import { ModelMessageService } from "common/formatters/ModelMessage";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import {
  IMessage,
  IMessages,
  MessageTypes
} from "features/detailpage/features/messages/reducers/MessagesReducer";
import IAssetDetails from "features/detailpage/models/IAssetDetails";
import * as React from "react";
import {
  FormattedDate,
  FormattedMessage,
  injectIntl,
  IntlShape
} from "react-intl";
import "./Messages.less";

export interface IMessagesData {
  messages: Data<IMessages>;
  assetDetails: Data<IAssetDetails>;
}

export interface IMessagesActions {
  loadMessages: (intl: IntlShape, assetId: string) => void;
}

export interface IMessagesOwnProps {
  assetId: string;
}

export type MessagesProps = IMessagesData &
  IMessagesActions &
  IMessagesOwnProps & {
    intl: IntlShape;
  };

export interface IMessagesState {
  showErrors: boolean;
}

class Messages extends React.Component<MessagesProps, IMessagesState> {
  constructor(props: MessagesProps) {
    super(props);

    this.state = {
      showErrors: false
    };
  }

  render() {
    const { handleShowErrorsChanged } = this;
    const { messages, assetDetails } = this.props;
    const { showErrors } = this.state;

    if (messages?.status === Statuses.Loading) {
      return <MessagesLoading />;
    } else if (messages?.status === Statuses.NotFound) {
      return <MessagesNoData />;
    } else if (messages?.status === Statuses.Succeeded) {
      const allMessages = messages.data.Messages || [];
      const filteredMessages = showErrors
        ? allMessages
        : allMessages
            .filter((m) => m.MessageType !== "Error")
            .filter((m) => m.MessageType !== "DataQualityError");

      return (
        <MessagesSucceededWithIntl
          implementationId={
            assetDetails?.data?.NameplateWithModelInfo?.ModelImplementationId
          }
          modelId={assetDetails?.data?.NameplateWithModelInfo?.ModelId}
          messages={filteredMessages}
          onShowErrorsChanged={(v) => {
            handleShowErrorsChanged(v);
          }}
        />
      );
    } else if (messages?.status === Statuses.Failed) {
      return <MessagesFailed errorMessage={messages.message} />;
    }

    return null;
  }

  componentDidMount() {
    const { intl } = this.props;
    const { loadMessages, messages, assetId } = this.props;
    if (messages?.status !== Statuses.Succeeded) {
      loadMessages(intl, assetId);
    }
  }

  private handleShowErrorsChanged = (checked: boolean) => {
    this.setState((prevState, props) =>
      Object.assign({}, prevState, {
        showErrors: checked
      })
    );
  };
}

const MessagesLoading = () => <Processing />;

const MessagesNoData = () => (
  <div className="messages no-data">
    <WidgetNoDataMessage />
  </div>
);

interface IMessagesSucceeded {
  intl: IntlShape;
  messages: IMessage[];
  modelId: string;
  implementationId: string;
  onShowErrorsChanged: (checked: boolean) => void;
}

const MessagesSucceeded = ({
  intl,
  messages,
  implementationId,
  modelId,
  onShowErrorsChanged
}: IMessagesSucceeded) => {
  const columns: IColumnConfig[] = [
    {
      component: (value) => (
        <ColumnTypeWithIntl messageType={value as MessageTypes} />
      ),
      frozen: true,
      id: "type",
      message: { id: "detail_page.messages.grid.type", defaultMessage: "Type" },
      width: 70
    },
    {
      compareFunction: (r1: IRowData, r2: IRowData) => {
        const r1Text = ModelMessageService.format(
          {
            TextId: r1["message"],
            TextValues: r1
          },
          modelId,
          implementationId,
          intl
        );
        const r2Text = ModelMessageService.format(
          {
            TextId: r2["message"],
            TextValues: r2
          },
          modelId,
          implementationId,
          intl
        );

        return r1Text.localeCompare(r2Text);
      },
      component: (value) => <ColumnMessage message={value} />,
      frozen: true,
      id: "message",
      message: {
        id: "detail_page.messages.grid.message",
        defaultMessage: "Message"
      },
      weight: 14
    },
    {
      compareFunction: (r1: IRowData, r2: IRowData) =>
        new Date(r1["date"]).getTime() - new Date(r2["date"]).getTime(),
      component: (value) => <ColumnDate date={value as string} />,
      id: "date",
      message: {
        id: "detail_page.messages.grid.date",
        defaultMessage: "Initially Identified"
      },
      weight: 4
    },
    {
      id: "algorithm",
      message: {
        id: "detail_page.messages.grid.algorithm",
        defaultMessage: "Algorithm"
      },
      weight: 4
    }
  ];

  const data = messages.map<IRowData>((m) => ({
    type: m.MessageType,
    message: m.MessageContent,
    date: m.CreationDate,
    algorithm: m.MessageSource
  }));

  return (
    <div className="messages">
      <div className="header">
        <Checkbox
          onChange={(v) => {
            if (onShowErrorsChanged) onShowErrorsChanged(v.target.checked);
          }}
        >
          <FormattedMessage
            defaultMessage="Show errors"
            id="detail_page.messages.show_errors"
          />
        </Checkbox>
      </div>
      <DataGrid
        columns={columns}
        data={data}
        footerComponent={(rows, rowsTotal) => (
          <Footer numberOfRows={rowsTotal} />
        )}
      />
    </div>
  );
};

const MessagesSucceededWithIntl = injectIntl(MessagesSucceeded);

interface IMessagesFailedProps {
  errorMessage: string;
}

const MessagesFailed = (props: IMessagesFailedProps) => (
  <WidgetErrorMessage
    messageId="global.empty"
    messageDefault={props.errorMessage}
  />
);

interface IColumnTypeProps {
  intl: IntlShape;
  messageType: MessageTypes;
}

const ColumnType = (props: IColumnTypeProps) => {
  const { intl, messageType } = props;
  let icon: JSX.Element;
  let titleKey: string;

  switch (messageType) {
    case "Alert":
      icon = <Icon className="icon message-alert" name="pg-alert" />;
      titleKey = "alert";
      break;
    case "Warning":
      icon = <Icon className="icon warning" name="pg-alert-octagon" />;
      titleKey = "warning";
      break;
    case "Informational":
      icon = <Icon className="icon informational" name="pg-information" />;
      titleKey = "informational";
      break;
    case "Error":
      icon = <Icon className="icon error" name="pg-close-octagon" />;
      titleKey = "error";
      break;
    case "Predictive":
      icon = (
        <Icon
          className="icon predictive"
          name="pg-chart-timeline-variant-shimmer"
        />
      );
      titleKey = "predictive";
      break;
    case "Verification":
      icon = <Icon className="icon verification" name="pg-text-box-check" />;
      titleKey = "verification";
      break;
    case "Action":
      icon = <Icon className="icon _action" name="pg-progress-wrench" />;
      titleKey = "action";
      break;
    case "TimeFrame":
      icon = <Icon className="icon timeframe" name="pg-timetable" />;
      titleKey = "timeframe";
      break;
    case "Diagnosis":
      icon = <Icon className="icon diagnosis" name="pg-text-box-search" />;
      titleKey = "diagnosis";
      break;
    case "Solution":
      icon = <Icon className="icon solution" name="pg-lightbulb-on" />;
      titleKey = "solution";
      break;
    case "Urgency":
      icon = <Icon className="icon urgency" name="pg-calendar-alert" />;
      titleKey = "urgency";
      break;
    case "Unknown":
      icon = <Icon className="icon unknown" name="pg-help-rhombus" />;
      titleKey = "unknown";
      break;
    case "DataQualityError":
      icon = <Icon className="icon error" name="pg-database-remove" />;
      titleKey = "data_quality_error";
      break;
    default:
      icon = null;
      titleKey = "";
  }

  return (
    <div
      className="column-type c-tooltip"
      data-tooltip={intl.formatMessage({
        defaultMessage: titleKey,
        id: `detail_page.messages.grid.tooltip.${titleKey}`
      })}
    >
      {icon}
    </div>
  );
};

const ColumnTypeWithIntl = injectIntl(ColumnType);

interface IColumnMessageProps {
  message: string;
}

const ColumnMessage = ({ message }: IColumnMessageProps) => {
  return <div className="column-message">{message}</div>;
};

interface IColumnDateProp {
  date: string;
}

const ColumnDate = (props: IColumnDateProp) => {
  const { date } = props;

  return (
    <div className="column-date">
      <FormattedDate value={new Date(date)} />
    </div>
  );
};

interface IFooterProps {
  numberOfRows: number;
}

const Footer = (props: IFooterProps) => {
  const { numberOfRows } = props;

  return (
    <div className="footer default-grey-label">
      <FormattedMessage
        defaultMessage="Number of rows: {numberOfRows}"
        id="data_grid.footer.number_of_rows"
        values={{
          numberOfRows: numberOfRows
        }}
      />
    </div>
  );
};

export default injectIntl(Messages);
