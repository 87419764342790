// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IRoutableTab } from "common/RoutableTabs";
import IDuvalTabConfiguration from "features/detailpage/components/analytics/DuvalTriangle/models/IDuvalTabConfiguration";
import isKinectricsNetworkTransformerModel from "features/detailpage/utils/isKinectricsNetworkTransformerModel";
import isKinectricsNetworkTransformerSiliconeModel from "features/detailpage/utils/isKinectricsNetworkTransformerSiliconeModel";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import DuvalTrianglesTab from "../../../components/analytics/DuvalTriangle/DuvalTrianglesTab";
import DuvalPentagonTab from "../components/DuvalPentagonTab";
import type1Default from "../constants/type1Default";
import type3Default from "../constants/type3Default";
import type4Default from "../constants/type4Default";
import type5Default from "../constants/type5Default";

const useDuvalTabs = (
  assetId: string,
  implementationId: string
): IRoutableTab[] => {
  const intl = useIntl();

  const isKinectricsModel = useMemo<boolean>(() => {
    return isKinectricsNetworkTransformerModel(implementationId);
  }, [implementationId]);

  const isKinectricsSiliconeModel = useMemo<boolean>(() => {
    return isKinectricsNetworkTransformerSiliconeModel(implementationId);
  }, [implementationId]);

  const showPentagonsTab = useMemo<boolean>(() => {
    return !isKinectricsModel;
  }, [isKinectricsModel]);

  const duvalTabConfiguration = useMemo<IDuvalTabConfiguration>(() => {
    return {
      type1: isKinectricsSiliconeModel ? [] : type1Default,
      type3: isKinectricsSiliconeModel ? type3Default : [],
      type4: isKinectricsSiliconeModel ? [] : type4Default,
      type5: isKinectricsModel ? [] : type5Default
    };
  }, [isKinectricsModel, isKinectricsSiliconeModel]);

  const tabs: IRoutableTab[] = useMemo(
    () => [
      {
        Content: () => (
          <DuvalTrianglesTab
            assetId={assetId}
            duvalConfiguration={duvalTabConfiguration}
          />
        ),
        id: "Triangles",
        name: intl.formatMessage({
          defaultMessage: "Triangles",
          id: "detail_page.widgets.analytics.transformers.duval_triangles"
        })
      },
      ...(showPentagonsTab
        ? [
            {
              Content: () => <DuvalPentagonTab assetId={assetId} />,
              id: "Pentagons",
              name: intl.formatMessage({
                defaultMessage: "Pentagons",
                id: "detail_page.widgets.analytics.transformers.duval_pentagons"
              })
            }
          ]
        : [])
    ],
    [assetId, duvalTabConfiguration, intl, showPentagonsTab]
  );

  return tabs;
};

export default useDuvalTabs;
