// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { Button } from "antd";

import Popover from "common/popover/Popover";

import SaveButtonDisabledTooltip from "./SaveButtonDisabledTooltip";

interface ISaveButtonProps {
  hasValidData: boolean;
  textMessageDescriptor: MessageDescriptor;
  onClick: () => void;
}

const SaveButton = ({
  hasValidData,
  textMessageDescriptor: { id, defaultMessage },
  onClick
}: ISaveButtonProps) => {
  return hasValidData ? (
    <Button
      type="primary"
      disabled={!hasValidData}
      onClick={onClick}
      htmlType="submit"
    >
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
    </Button>
  ) : (
    <Popover
      content={<SaveButtonDisabledTooltip />}
      trigger="hover"
      placement="left"
    >
      <Button
        type="primary"
        onClick={onClick}
        disabled={!hasValidData}
        htmlType="submit"
      >
        <FormattedMessage id={id} defaultMessage={defaultMessage} />
      </Button>
    </Popover>
  );
};

export default SaveButton;
