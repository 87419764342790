// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import IOilSettings, { OilTest } from "../models/IOilSettings";

const chartSettings: { [key in OilTest]: IOilSettings } = {
  AcidNumber: { unit: "mg KOH/g", reversedThresholds: true },
  Color: { unit: "", reversedThresholds: true },
  DielectricStrength: { unit: "kV", reversedThresholds: false },
  DissipationFactor: { unit: "%", reversedThresholds: true },
  InterfacialTension: {
    unit: "mN/m",
    reversedThresholds: false
  },
  WaterContent: { unit: "mg/kg", reversedThresholds: true }
};

export default chartSettings;
