// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import BodyCell from "common/datagrid/BodyCell";
import BodyRowActions from "common/datagrid/BodyRowActions";
import { IAction, IColumn, IRow } from "common/datagrid/DataGrid";
import React, { useRef } from "react";

export const dragOverlapMargin = 2;
export const checkedLimit = 10;

export interface IBodyRowProps {
  actions: IAction[];
  columns: IColumn[];
  gridId: string;
  onRowClick?: (e: React.MouseEvent<HTMLDivElement>, row: IRow) => void;
  row: IRow;
  rows: IRow[];
  showCheckboxColumn?: boolean;
  onCheckboxChange?: (e: CheckboxChangeEvent, row: IRow) => void;
  isCheckboxDisabled?: (row: IRow, checkedRows: IRow[]) => boolean;
  checkedRows?: IRow[];
  checked?: (row: IRow) => boolean;
}

interface IPosition {
  x: number;
  y: number;
}

const BodyRow = (props: IBodyRowProps) => {
  const {
    actions,
    columns,
    gridId,
    onRowClick,
    row,
    rows,
    showCheckboxColumn,
    onCheckboxChange,
    isCheckboxDisabled,
    checkedRows,
    checked
  } = props;

  const mouseDownPositionRef = useRef<IPosition>();
  const mouseUpPositionRef = useRef<IPosition>();

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    mouseDownPositionRef.current = {
      x: e.clientX,
      y: e.clientY
    };
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    mouseUpPositionRef.current = {
      x: e.clientX,
      y: e.clientY
    };
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (
      onRowClick &&
      Math.abs(mouseDownPositionRef.current.x - mouseUpPositionRef.current.x) <
        dragOverlapMargin &&
      Math.abs(mouseDownPositionRef.current.y - mouseUpPositionRef.current.y) <
        dragOverlapMargin
    ) {
      onRowClick(e, row);
    }
  };

  const handleActionsClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const cells = columns.map((c, i) => (
    <BodyCell
      column={c}
      gridId={gridId}
      key={c.config.id}
      row={row}
      rows={rows}
    />
  ));

  const checkboxColumnBody = showCheckboxColumn && (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`
    grid-cell checkbox-column-body
  `}
    >
      <div className="grid-cell-content">
        <Checkbox
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            if (onCheckboxChange) onCheckboxChange(e, row);
          }}
          disabled={isCheckboxDisabled && isCheckboxDisabled(row, checkedRows)}
          checked={checked(row)}
        />
      </div>
    </div>
  );

  return (
    <div className="grid-row-container">
      <div
        className={`
          grid-row
          ${onRowClick && "clickable"}
          ${row.state.selected && "selected"}
          ${checked(row) && "checked"}
          
          `}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {checkboxColumnBody}
        {cells}
        <BodyRowActions
          actions={actions}
          row={row}
          onClick={handleActionsClick}
        />
      </div>
    </div>
  );
};

export default BodyRow;
