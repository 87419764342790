// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import Data from "../../../../../core/data/models/Data";
import { IState } from "../../../../../reducers/Index";

import { getDataKey } from "../actions/loadWear";

import IWearData from "../models/IWearData";

const getWearSelector = createSelector<
  IState,
  (assetId: string) => Data<IWearData>,
  (assetId: string) => Data<IWearData>
>(
  (state: IState) => (assetId: string) => state.data[getDataKey(assetId)],
  (wear) => wear
);

export default getWearSelector;
