// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { FC } from "react";
import { IntlShape, useIntl } from "react-intl";

import { NumericalValueFormatter } from "common/formatters/NumericalValue";

export interface IDegradationScoreProps {
  value: number;
}

const DegradationScore: FC<IDegradationScoreProps> = ({ value }) => {
  const intl = useIntl();
  const formattedValue = DegradationScoreService.format(value, intl);
  return <span>{formattedValue}</span>;
};

export class DegradationScoreService {
  static format(value: number, intl: IntlShape): string {
    const formatter = new NumericalValueFormatter("-");
    return formatter.format(value, intl);
  }
}

export default DegradationScore;
