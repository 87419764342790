// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import ICustomer from "core/app/models/ICustomer";
import IVisualizationsAssetGrid from "core/app/models/IVisualizationsAssetGrid";
import IVisualizationsConfiguration from "core/app/models/IVisualizationsConfiguration";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { Action, Dispatch } from "redux";
import { config } from "utils/AppConfig";

export enum Actions {
  Settings_SetCustomer = "Settings_SetCustomer",
  Settings_SetCustomerVisualizations = "Settings_SetCustomerVisualizations",
  Settings_SetCustomerVisualizationsAssetGrid = "Settings_SetCustomerVisualizationsAssetGrid"
}

const setCustomerAction = (
  request: JQueryXHR,
  data?: ICustomer
): IAjaxAction<ICustomer> => {
  const action: IAjaxAction<ICustomer> = {
    type: Actions.Settings_SetCustomer,
    data,
    request
  };
  return action;
};

const setCustomerVisualizationsAction = (
  request: JQueryXHR,
  data?: IVisualizationsConfiguration
): IAjaxAction<IVisualizationsConfiguration> => {
  const action: IAjaxAction<IVisualizationsConfiguration> = {
    type: Actions.Settings_SetCustomerVisualizations,
    data,
    request
  };
  return action;
};

const setCustomerVisualizationsAssetGridAction = (
  request: JQueryXHR,
  data?: IVisualizationsAssetGrid
): IAjaxAction<IVisualizationsAssetGrid> => {
  const action: IAjaxAction<IVisualizationsAssetGrid> = {
    type: Actions.Settings_SetCustomerVisualizationsAssetGrid,
    data,
    request
  };
  return action;
};

export const loadCustomer = (dispatch: Dispatch<Action>) => {
  const url = UrlService.getApiUrl(config.api.user.customerUrl);
  const request = EndpointService.getJson<ICustomer>(
    url,
    (request, data) => {
      dispatch(setCustomerAction(request, data));
    },
    (request) => {
      dispatch(setCustomerAction(request));
    }
  );

  dispatch(setCustomerAction(request));
};

export const loadCustomerVisualizations = (dispatch: Dispatch<Action>) => {
  const url = UrlService.getApiUrl(config.api.user.visualizationsUrl);
  const request = EndpointService.getJson<IVisualizationsConfiguration>(
    url,
    (request, data) => {
      dispatch(setCustomerVisualizationsAction(request, data));
    },
    (request) => {
      dispatch(setCustomerVisualizationsAction(request));
    }
  );

  dispatch(setCustomerVisualizationsAction(request));
};

export const loadCustomerVisualizationsAssetGrid = (
  dispatch: Dispatch<Action>
) => {
  const url = UrlService.getApiUrl(config.api.user.visualizationsAssetGridUrl);
  const request = EndpointService.getJson<IVisualizationsAssetGrid>(
    url,
    (request, data) => {
      dispatch(setCustomerVisualizationsAssetGridAction(request, data));
    },
    (request) => {
      dispatch(setCustomerVisualizationsAssetGridAction(request));
    }
  );

  dispatch(setCustomerVisualizationsAssetGridAction(request));
};
