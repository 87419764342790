// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import IScoreTrend from "../../../models/IScoreTrend";
import { getDataKey } from "../actions/loadSimulationTrend";
import IValues from "../models/IValues";

const getSimulationTrendsSelector = createSelector<
  IState,
  (simulationId: string) => Data<IValues<IScoreTrend>>,
  (simulationId: string) => Data<IValues<IScoreTrend>>
>(
  (state: IState) => (simulationId: string) =>
    state.data[getDataKey(simulationId)],
  (simulationTrendData) => simulationTrendData
);

export default getSimulationTrendsSelector;
