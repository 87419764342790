// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IRoutableTab } from "common/RoutableTabs";
import {
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import { IUser } from "core/app/reducers/settings/UserReducer";
import ParametersTab from "features/detailpage/features/ParametersTab";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import useBatteryTabs from "./useBatteryTabs";
import useCircuitBreakerTabs from "./useCircuitBreakerTabs";
import useDCTransformerTabs from "./useDCTransformerTabs";
import useKinectricsNetworkTransformerTabs from "./useKinectricsNetworkTransformerTabs";
import useReportsTabs from "./useReportsTabs";
import useTransformerTabs from "./useTransformerTabs";

interface IUseParametersTabsOptions {
  assetId: string;
  modelId: string;
  implementationId: string;
  assetType: string;
  cbkAvailable: boolean;
  analyticsAvailable: boolean;
  dcTransformerAvailable: boolean;
  kinectricsNetworkTransformerAvailable: boolean;
  batteryAvailable: boolean;
  loadPbiReports: (assetType: string) => void;
  user: IUser;
}

const useParametersTabs = ({
  assetId,
  assetType,
  cbkAvailable,
  analyticsAvailable,
  dcTransformerAvailable,
  kinectricsNetworkTransformerAvailable,
  batteryAvailable,
  modelId,
  implementationId,
  loadPbiReports,
  user
}: IUseParametersTabsOptions) => {
  const intl = useIntl();
  const { isAvailable } = useFeature();

  const ckbTabs = useCircuitBreakerTabs({
    assetId,
    cbkAvailable,
    modelId,
    implementationId
  });
  const transformerTabs = useTransformerTabs({
    assetId,
    analyticsAvailable,
    modelId,
    implementationId
  });
  const dcTransformerTabs = useDCTransformerTabs({
    assetId,
    dcTransformerAvailable
  });
  const kinectricsNetworkTransformerTabs = useKinectricsNetworkTransformerTabs({
    assetId,
    kinectricsNetworkTransformerAvailable,
    implementationId
  });
  const batteryTabs = useBatteryTabs({
    assetId,
    batteryAvailable
  });
  const reportsTabs = useReportsTabs({
    assetType,
    assetId,
    loadPbiReports,
    user
  });

  const tabs: IRoutableTab[] = useMemo(() => {
    const defaultTabs = isAvailable(
      VisualizationKeys.AssetDetailPage_Tabs_Parameters_Parameters
    )
      ? [
          {
            Content: () => (
              <ParametersTab
                assetId={assetId}
                modelId={modelId}
                implementationId={implementationId}
              />
            ),
            id: "Parameters",
            name: intl.formatMessage({
              id: "detail_page.widgets.parameters_list.title",
              defaultMessage: "Parameters"
            })
          }
        ]
      : [];

    return [
      ...defaultTabs,
      ...ckbTabs,
      ...transformerTabs,
      ...dcTransformerTabs,
      ...kinectricsNetworkTransformerTabs,
      ...batteryTabs,
      ...reportsTabs
    ];
  }, [
    isAvailable,
    intl,
    ckbTabs,
    transformerTabs,
    dcTransformerTabs,
    kinectricsNetworkTransformerTabs,
    batteryTabs,
    reportsTabs,
    assetId,
    modelId,
    implementationId
  ]);

  return tabs;
};

export default useParametersTabs;
