// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "lodash";

import Data, { Statuses } from "core/data/models/Data";
import Processing from "components/common/Processing";
import {
  WidgetNoDataMessage,
  WidgetErrorMessage
} from "components/common/widget/Widget";

import { IHistorySwitchingOperationsData } from "features/detailpage/features/ltc/models/IHistorySwitichingOperations";
import HistorySwitchingChart from "features/detailpage/features/ltc/components/HistorySwitchingChart";

import "./HistorySwitching.less";

interface IHistorySwitchingComponentProps {
  historySwitching: IHistorySwitchingOperationsData;
}

const HistorySwitchingComponent = ({
  historySwitching
}: IHistorySwitchingComponentProps) => (
  <div className="history-switching-chart-wrapper" data-qa="history-switching">
    <h2 className="history-switching-chart-wrapper__title">
      <FormattedMessage
        id="detail_page.widgets.analytics.transformers.ltc.history_operations_name"
        defaultMessage="History of Switching Operations"
      />
    </h2>
    <HistorySwitchingChart historySwitching={historySwitching} />
  </div>
);

interface IHistorySwitchingStateProps {
  historySwitching: Data<IHistorySwitchingOperationsData>;
}

interface IHistorySwitchingProps extends IHistorySwitchingStateProps {}

export default ({ historySwitching }: IHistorySwitchingProps) => {
  switch (historySwitching.status) {
    case Statuses.Loading:
      return <Processing className="spinner small dark" />;
    case Statuses.Succeeded:
      return isEmpty(historySwitching.data) ? (
        <WidgetNoDataMessage />
      ) : (
        <HistorySwitchingComponent historySwitching={historySwitching.data} />
      );
    case Statuses.NotFound:
      return <WidgetNoDataMessage />;
    case Statuses.Failed:
      return <WidgetErrorMessage />;
    default:
      return (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      );
  }
};
