// Copyright 2016-2022 Hitachi Power Grids. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import {
  AutoComplete,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Typography
} from "antd";
import update from "immutability-helper";
import { Moment } from "moment";
import { useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import boolValues from "../constants/boolValues";
import AssetNameplateContext from "../contexts/AssetModalContext";
import IField from "../models/IField";
import IListValues from "../models/IListValues";
import { isSelectOption } from "../models/typeGuards";
import { dateFormat, disableFutureDates } from "../utils/datePicker";

const { Text } = Typography;
const { Option } = Select;

interface ICustomFieldProps {
  configuration: IField;
  editAction?: () => void;
  fieldIsRequired?: (fieldName: string) => boolean;
  getInitialValue?: (
    fieldName: string
  ) => string | number | boolean | Date | Moment;
  className?: string;
}

const CustomField = ({
  configuration,
  editAction,
  fieldIsRequired,
  getInitialValue,
  className
}: ICustomFieldProps) => {
  const intl = useIntl();
  const { isEditMode } = useContext(AssetNameplateContext);

  const validationRules = useMemo(() => {
    if (fieldIsRequired && fieldIsRequired(configuration.fieldKey)) {
      return update(configuration.rules ?? [], { $push: [{ required: true }] });
    } else {
      return configuration.rules;
    }
  }, [configuration.fieldKey, configuration.rules, fieldIsRequired]);
  const disableField = useMemo(() => {
    return (
      configuration.disabled || (isEditMode && configuration.disabledInEditMode)
    );
  }, [configuration?.disabled, configuration?.disabledInEditMode, isEditMode]);

  const initialValue = getInitialValue
    ? getInitialValue(configuration?.fieldKey)
    : null;

  const fieldName = useMemo(
    () =>
      configuration?.guid
        ? `${configuration.fieldKey}-${configuration.guid}`
        : configuration.fieldKey,
    [configuration?.guid, configuration?.fieldKey]
  );

  return (
    <div className={`${className} form-field`}>
      <Form.Item
        className={configuration.unit ? "with-unit" : ""}
        label={intl.formatMessage({ ...configuration.fieldName })}
        tooltip={
          configuration.tooltip
            ? intl.formatMessage({ ...configuration.tooltip })
            : undefined
        }
      >
        {editAction && (
          <div className="field-actions">
            <Icon name="border_color" onClick={editAction} variant="outlined" />
          </div>
        )}
        {configuration.inputType === "autoComplete" && (
          <Form.Item
            noStyle={true}
            name={fieldName}
            initialValue={initialValue}
            rules={validationRules}
          >
            <AutoComplete
              allowClear={true}
              showSearch={true}
              showArrow={true}
              options={configuration.autoCompleteOptions || []}
              filterOption={(input, option) =>
                option.label
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        )}

        {configuration.inputType === "list" && (
          <Form.Item
            noStyle
            name={fieldName}
            initialValue={initialValue}
            rules={validationRules}
          >
            {Array.isArray(configuration.listValues) ? (
              <Select
                showSearch={true}
                disabled={configuration.disabled}
                allowClear={true}
                filterOption={(input, option) => {
                  const optionValue = option.label ?? option.value;

                  return optionValue
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {isSelectOption(configuration.listValues)
                  ? configuration.listValues.map(({ value, label }) => {
                      return (
                        <Option key={value} value={value} label={label}>
                          {label}
                        </Option>
                      );
                    })
                  : configuration.listValues.map((value) => (
                      <Option key={value.toString()} value={value.toString()}>
                        {value.toString()}
                      </Option>
                    ))}
              </Select>
            ) : (
              <Select
                showSearch
                disabled={configuration.disabled}
                allowClear
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {Object.keys(configuration.listValues).map((value) => (
                  <Option key={value} value={value}>
                    {(configuration.listValues as IListValues)[value]}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
        {configuration.inputType === "Decimal" && (
          <Form.Item
            noStyle
            name={fieldName}
            initialValue={initialValue}
            rules={validationRules}
          >
            <InputNumber disabled={disableField} />
          </Form.Item>
        )}
        {configuration.inputType === "DateTime" && (
          <Form.Item
            noStyle
            name={fieldName}
            initialValue={initialValue}
            rules={validationRules}
          >
            <DatePicker
              disabledDate={
                configuration.disableFutureDates ? disableFutureDates : null
              }
              disabled={disableField}
              format={dateFormat(intl)}
            />
          </Form.Item>
        )}
        {configuration.inputType === "Bool" && (
          <Form.Item
            noStyle={true}
            name={fieldName}
            initialValue={initialValue}
            rules={validationRules}
          >
            <Select showSearch disabled={disableField}>
              {boolValues.map((value) => (
                <Option key={value.toString()} value={value.toString()}>
                  {value.toString()}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {configuration.inputType === "String" && (
          <Form.Item
            noStyle
            name={fieldName}
            initialValue={initialValue}
            rules={validationRules}
          >
            <Input disabled={disableField} />
          </Form.Item>
        )}
        {configuration.unit && (
          <Text type="secondary" className="unit">
            {configuration.unit}
          </Text>
        )}
      </Form.Item>
    </div>
  );
};

export default styled(CustomField)`
  .ant-input-number {
    width: 100%;
  }
`;
