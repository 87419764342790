// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { loadMapProvider } from "core/app/actions/MapProviderActions";
import {
  loadCustomer,
  loadCustomerVisualizations,
  loadCustomerVisualizationsAssetGrid
} from "core/app/actions/settings/CustomerActions";
import { loadEnvironment } from "core/app/actions/settings/EnvironmentActions";
import { loadTenantList } from "core/app/actions/settings/TenantActions";
import { loadUser } from "core/app/actions/settings/UserActions";
import SettingsProvider, {
  ISettingsProviderActions
} from "core/app/components/SettingsProvider";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { Action, Dispatch } from "redux";

function mapStateToProps(state: IState): {} {
  return {};
}

function mapDispatchToProps(
  dispatch: Dispatch<Action>
): ISettingsProviderActions {
  return {
    loadCustomer: () => loadCustomer(dispatch),
    loadEnvironment: () => loadEnvironment(dispatch),
    loadMapProvider: () => loadMapProvider(dispatch),
    loadTenantList: () => loadTenantList(dispatch),
    loadUser: () => loadUser(dispatch),
    loadCustomerVisualizations: () => loadCustomerVisualizations(dispatch),
    loadCustomerVisualizationsAssetGrid: () =>
      loadCustomerVisualizationsAssetGrid(dispatch)
  };
}

const SettingsProviderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsProvider);

export default SettingsProviderContainer;
