// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";
import { MessageDescriptor } from "react-intl";

import { ToggleModes } from "common/form/components/Toggle";
import ToggleInputContainer from "common/form/containers/ToggleInputContainer";

import { formName } from "../../selectors/getNotificationsFormSelector";

interface IToggleItemProps {
  notificationsEnabled: boolean;
  toggleValue: boolean;
  inputName: string;
  message: MessageDescriptor;
  onChange?: (value: boolean) => void;
}

const ToggleItem = ({
  message,
  notificationsEnabled,
  inputName,
  toggleValue,
  onChange
}: IToggleItemProps) => {
  return (
    <div className="item">
      <ToggleInputContainer
        formName={formName}
        inputName={inputName}
        className="notification-toggle-item-toggle"
        defaultValue={toggleValue}
        disabled={!notificationsEnabled}
        label={message}
        mode={ToggleModes.LabelOnLeft}
        onChange={onChange}
      />
    </div>
  );
};

export default ToggleItem;
