// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";
import IScoreFactor from "../../../models/IScoreFactor";

interface IFactorProps {
  factor: IScoreFactor;
}

const Factor = ({ factor }: IFactorProps) => (
  <div className="factor">
    <div className="factor-name">{factor.TranslatedName}</div>
    {factor.Subfactors && (
      <div className="subfactors">
        {factor.Subfactors.map((s) => (
          <Factor factor={s} key={s.Name} />
        ))}
      </div>
    )}
  </div>
);

interface IColumnFactorsTooltipProps {
  factors: IScoreFactor[];
}

const ColumnFactorsTooltip = ({ factors }: IColumnFactorsTooltipProps) => (
  <div className="column-factor-tooltip">
    {factors.map((f) => (
      <Factor factor={f} key={f.Name} />
    ))}
  </div>
);

export default ColumnFactorsTooltip;
