// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { flatten } from "lodash";

import { IActionSetQueryParams } from "core/app/actions/LocationActions";
import {
  ILocation,
  INamedQueryParams,
  IRawQueryParams,
  IState,
  QueryParams
} from "core/app/reducers/LocationReducer";

export const disableBingMapsParamName = "disableBingMaps";
export const experimentalParamName = "experimental";
export const localeParamName = "locale";

function parseBoolean(value: string): boolean | null {
  if (value === "true") return true;
  if (value === "false") return false;
  return null;
}

function parseString(value: boolean | null): string | null {
  if (value === true) return "true";
  if (value === false) return "false";
  return null;
}

function set<T>(value: T, setter: (value: T) => void) {
  if (value !== undefined && value != null) setter(value);
}

export default class LocationService {
  static getQueryParamAsStrings(path: string, paramName: string): string[] {
    const i = path.indexOf("?");
    if (i < 0) return [];
    const search = path.substr(i);
    const rawQueryParams = LocationService.getRawQueryParamsFromSearch(search);
    const values = rawQueryParams[paramName];
    return values || [];
  }

  static getFirstQueryParamAsString(
    path: string,
    paramName: string
  ): string | null {
    const values = LocationService.getQueryParamAsStrings(path, paramName);
    return values.length > 0 ? values[0] : null;
  }

  static getQueryParamAsBooleans(path: string, paramName: string): boolean[] {
    const values = LocationService.getQueryParamAsStrings(path, paramName);
    return values.map(v => parseBoolean(v));
  }

  static getFirstQueryParamAsBoolean(
    path: string,
    paramName: string
  ): boolean | null {
    const values = LocationService.getQueryParamAsBooleans(path, paramName);
    return values.length > 0 ? values[0] : null;
  }

  static getRawQueryParamsFromSearch(search: string): IRawQueryParams {
    const params: IRawQueryParams = {};
    if (!search) return params;

    const i = search.indexOf("?");
    if (i > -1) {
      search = search.substr(i + 1);
    }

    const searchParams = new URLSearchParams(search);
    (searchParams as any).forEach((value: string, key: string) => {
      params[key] = params[key] || [];
      params[key].push(value);
    });

    return params;
  }

  static getSearchFromRawQueryParams(
    rawQueryParams: IRawQueryParams
  ): string | null {
    if (rawQueryParams === undefined || rawQueryParams == null) return null;

    const searchParts = Object.keys(rawQueryParams).map(k =>
      rawQueryParams[k].map(v => `${k}=${encodeURIComponent(v)}`)
    );

    return flatten(searchParts).join("&");
  }

  static mapNamedQueryParamsToRaw(
    namedQueryParams: INamedQueryParams
  ): IRawQueryParams {
    const rawQueryParams: IRawQueryParams = {};

    const disableBingMaps = parseString(namedQueryParams.disableBingMaps);
    set(disableBingMaps, v => {
      rawQueryParams[disableBingMapsParamName] = [v];
    });
    const experimental = parseString(namedQueryParams.experimental);
    set(experimental, v => {
      rawQueryParams[experimentalParamName] = [v];
    });
    const locale = namedQueryParams.locale;
    set(locale, v => {
      rawQueryParams[localeParamName] = [v];
    });

    return rawQueryParams;
  }

  static mapRawQueryParamsToNamed(
    rawQueryParams: IRawQueryParams
  ): INamedQueryParams {
    const disableBingMapsParams =
      rawQueryParams[disableBingMapsParamName] || [];
    const experimentalParams = rawQueryParams[experimentalParamName] || [];
    const localeParams = rawQueryParams[localeParamName] || [];

    const namedQueryParams: INamedQueryParams = {
      disableBingMaps: parseBoolean(disableBingMapsParams[0]),
      experimental: parseBoolean(experimentalParams[0]),
      locale: localeParams[0]
    };

    return namedQueryParams;
  }

  static setQueryParamsToState(
    state: IState,
    action: IActionSetQueryParams
  ): IState {
    if (LocationService.hasAnyChanged(state, action)) {
      const prevQueryParams = state.location.queryParams;
      const nextQueryParams = action.rawQueryParams;
      const nextNamedQueryParams = LocationService.mapRawQueryParamsToNamed(
        action.rawQueryParams
      );

      const queryParams = {
        ...nextQueryParams,
        disableBingMaps: LocationService.hasDisableBingMapsChanged(
          state,
          action
        )
          ? nextNamedQueryParams.disableBingMaps
          : prevQueryParams.disableBingMaps,
        experimental: LocationService.hasExperimentalChanged(state, action)
          ? nextNamedQueryParams.experimental
          : prevQueryParams.experimental,
        locale: LocationService.hasLocaleChanged(state, action)
          ? nextNamedQueryParams.locale
          : prevQueryParams.locale
      } as QueryParams;

      state = Object.assign({}, state, {
        location: Object.assign({}, state.location, {
          queryParams
        } as ILocation)
      } as IState);
    }
    return state;
  }

  private static hasAnyChanged(
    state: IState,
    action: IActionSetQueryParams
  ): boolean {
    return (
      LocationService.hasDisableBingMapsChanged(state, action) ||
      LocationService.hasExperimentalChanged(state, action) ||
      LocationService.hasLocaleChanged(state, action)
    );
  }

  private static hasDisableBingMapsChanged(
    state: IState,
    action: IActionSetQueryParams
  ): boolean {
    const queryParams = action.rawQueryParams[disableBingMapsParamName] || [];
    return LocationService.hasChanged(
      state.location.queryParams.disableBingMaps,
      parseBoolean(queryParams[0])
    );
  }

  private static hasExperimentalChanged(
    state: IState,
    action: IActionSetQueryParams
  ): boolean {
    const queryParams = action.rawQueryParams[experimentalParamName] || [];
    return LocationService.hasChanged(
      state.location.queryParams.experimental,
      parseBoolean(queryParams[0])
    );
  }

  private static hasLocaleChanged(
    state: IState,
    action: IActionSetQueryParams
  ): boolean {
    const queryParams = action.rawQueryParams[localeParamName] || [];
    return LocationService.hasChanged(
      state.location.queryParams.locale,
      queryParams[0]
    );
  }

  private static hasChanged<T>(prevValue: T, nextValue: T): boolean {
    return nextValue !== undefined && prevValue !== nextValue;
  }
}
