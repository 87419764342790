// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React, { ReactNode, useMemo, useState } from "react";
import { matchPath, useLocation, useRouteMatch } from "react-router-dom";

import DetailPageWidgetContext from "features/detailpage/contexts/DetailPageWidgetContext";
import WidgetsRoutes from "features/detailpage/models/WidgetsRoutes";

interface IDetailPageWidgetContextProvider {
  children?: ReactNode;
}

const DetailPageWidgetContextProvider = ({
  children
}: IDetailPageWidgetContextProvider) => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const isParametersListWidgetVisible = useMemo(
    () => matchPath(pathname, `${url}/${WidgetsRoutes.Parameters}`) !== null,
    [pathname, url]
  );

  const [
    isAssetNameplateWidgetVisible,
    setIsAssetNameplateWidgetVisible
  ] = useState<boolean>(false);

  return (
    <DetailPageWidgetContext.Provider
      value={{
        isParametersListWidgetVisible,
        isAssetNameplateWidgetVisible,
        setIsAssetNameplateWidgetVisible
      }}
    >
      {children}
    </DetailPageWidgetContext.Provider>
  );
};

export default DetailPageWidgetContextProvider;
