// Copyright 2016-2022 Hitachi Energy. All rights reserved.
export const models = [
  "a",
  "ab",
  "b",
  "bd",
  "be",
  "c",
  "cgi",
  "cota",
  "ctf",
  "ctkf",
  "ctrf",
  "ctxf",
  "d",
  "dmb_oa",
  "easydry",
  "erc",
  "f",
  "fs",
  "gk",
  "goa",
  "gob",
  "goc",
  "goe",
  "goek",
  "gog",
  "goh",
  "gom",
  "gos",
  "gsa",
  "gsa_oa",
  "gsa_oo",
  "gsb",
  "gsbk",
  "l",
  "lc",
  "lcrj",
  "lk",
  "lk_a",
  "o",
  "o_a1",
  "o_plus",
  "o_plus_c",
  "o_plus_dry",
  "oc",
  "odof",
  "odof_g",
  "odof_l",
  "of",
  "os",
  "otxf",
  "pa",
  "poc",
  "poc_a",
  "prc",
  "prc_a",
  "raf",
  "rakf",
  "rj",
  "rmf",
  "rmff",
  "rmi",
  "rtak",
  "rtf",
  "rgi",
  "rtgf",
  "rti",
  "rtim",
  "rtkf",
  "rtkg",
  "rtkk",
  "rtrf",
  "rtrk",
  "rtxf",
  "rtxx",
  "rtzf",
  "s",
  "s_f",
  "s_df",
  "s_ef",
  "solid",
  "t",
  "u",
  "umf",
  "umff",
  "umi",
  "utf",
  "utkf",
  "utrf",
  "utxf",
  "wtrf",
  "wtxf",
  "wtxg",
  "other"
];
