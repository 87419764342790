// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import { loadOnlineBushingData } from "../actions/BushingVisualizationActions";
import BushingVisualization from "../components/BushingVisualization";
import selectOnlineBushingData from "../selectors/selectOnlineBushingData";

interface IBushingVisualizationContainerProps {
  assetId: string;
}

const BushingVisualizationContainer: React.FC<IBushingVisualizationContainerProps> = ({
  assetId
}) => {
  const dispatch = useDispatch();
  const onlineBushingData = useSelector(selectOnlineBushingData);
  const memoizedLoadOnlineBushingData = useCallback(
    () => loadOnlineBushingData(dispatch as Dispatch, assetId),
    [assetId, dispatch]
  );

  React.useEffect(() => {
    memoizedLoadOnlineBushingData();
  }, [memoizedLoadOnlineBushingData]);

  return <BushingVisualization onlineBushingData={onlineBushingData} />;
};

export default BushingVisualizationContainer;
