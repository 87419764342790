// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useSelector } from "react-redux";
import getScatterPlotYDataSelector from "../selectors/getScatterPlotYDataSelector";
import { IScatterPlotWidgetOwnProps } from "../components/ScatterPlotWidget";
import getScatterPlotXDataSelector from "../selectors/getScatterPlotXDataSelector";
import useScatterPlotWidget from "./useScatterPlotWidget";

const useFamilyContentsWidget = ({
  parameters
}: IScatterPlotWidgetOwnProps) => {
  const scatterPlotYData = useSelector(getScatterPlotYDataSelector);
  const scatterPlotXData = useSelector(getScatterPlotXDataSelector);

  const { getDataStatus } = useScatterPlotWidget(
    parameters,
    scatterPlotYData,
    scatterPlotXData
  );

  const status = getDataStatus();
  return { scatterPlotYData, scatterPlotXData, status };
};
export default useFamilyContentsWidget;
