// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IntlShape } from "react-intl";
import { FormatType } from "../AnalyticsLineChart";
import {
  acidNumber,
  dielectricStrength,
  inhibitorContent,
  interfacialTension,
  moisture,
  powerFactor
} from "./StandardOilTestsTabService";

const formatDefault = (x: any) => `${x}`;
const formatEmpty = () => "";
const formatPercent = (x: any) => `${x}%`;

export default class StandardOilTestsChartService {
  static getChartTitle = (
    standard: string,
    chartName: string,
    intl: IntlShape
  ) => {
    return intl.formatMessage({
      id: `detail_page.widgets.analytics.transformers.StandardOilTests.${standard}.${chartName}.Title`,
      defaultMessage: chartName
    });
  };

  static getChartUnit = (chartName: string): string => {
    const result: { [id: string]: string } = {
      [interfacialTension]: "mN/m",
      [moisture]: "ppm",
      [dielectricStrength]: "kV",
      [acidNumber]: "mg KOH/g"
    };

    return result[chartName];
  };

  static getChartType = (chartId: string): FormatType => {
    const result: { [id: string]: FormatType } = {
      [interfacialTension]: FormatType.InterfacialTension,
      [moisture]: FormatType.Moisture,
      [dielectricStrength]: FormatType.DielectricStrength,
      [acidNumber]: FormatType.AcidNumber,
      [powerFactor]: FormatType.PowerFactor,
      [inhibitorContent]: FormatType.InhibitorContent
    };

    return result[chartId];
  };

  static getTickFormatValuesForY(formatType: FormatType): any {
    return formatType === FormatType.PowerFactor ||
      formatType === FormatType.InhibitorContent
      ? formatPercent
      : formatDefault;
  }

  static getTickFormatValuesForY2(formatType: FormatType): any {
    return formatType === FormatType.PowerFactor ? formatPercent : formatEmpty;
  }

  static getTickCountValuesForY2(formatType: FormatType): any {
    return formatType === FormatType.PowerFactor ? undefined : 1;
  }

  static getTooltipFormatDate(formatType: FormatType, intl: IntlShape): any {
    return formatType === FormatType.Moisture
      ? (d: Date) =>
          intl.formatDate(d, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
          })
      : (d: Date) =>
          intl.formatDate(d, {
            year: "numeric",
            month: "numeric",
            day: "numeric"
          });
  }
}
