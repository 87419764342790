// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import BodyRow from "common/datagrid/BodyRow";
import { GridStatuses, IAction, IColumn, IRow } from "common/datagrid/DataGrid";
import MessageRow from "common/datagrid/MessageRow";
import SkeletonRow from "common/datagrid/SkeletonRow";
import Processing from "components/common/Processing";
import { WidgetNoDataMessage } from "components/common/widget/Widget";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export interface IGridBodyProps {
  actions: IAction[];
  columns: IColumn[];
  gridId: string;
  gridStatus: GridStatuses;
  onReloadClick?: () => void;
  onRowClick?: (e: React.MouseEvent<HTMLDivElement>, row: IRow) => void;
  rows: IRow[];
  showCheckboxColumn?: boolean;
  onCheckboxChange?: (e: CheckboxChangeEvent, row: IRow) => void;
  isCheckboxDisabled?: (row: IRow, checkedRows: IRow[]) => boolean;
  checkedRows?: IRow[];
  checked?: (row: IRow) => boolean;
}

export default class GridBody extends React.Component<IGridBodyProps> {
  render() {
    const {
      actions,
      columns,
      gridId,
      gridStatus,
      onRowClick,
      onReloadClick,
      rows,
      showCheckboxColumn,
      onCheckboxChange,
      isCheckboxDisabled,
      checkedRows,
      checked
    } = this.props;

    const showProcessing =
      gridStatus === GridStatuses.Processing ||
      gridStatus === GridStatuses.Loading;

    const showSucceeded =
      rows &&
      rows.length &&
      (gridStatus === GridStatuses.Updating ||
        gridStatus === GridStatuses.Succeeded ||
        gridStatus === GridStatuses.Failed);

    const showFailed =
      (!rows || !rows.length) && gridStatus === GridStatuses.Failed;

    const showNoData =
      (!rows || !rows.length) && gridStatus === GridStatuses.Succeeded;

    if (showProcessing) {
      return <BodyProcessing />;
    } else if (showSucceeded) {
      return (
        <BodySucceeded
          actions={actions}
          columns={columns}
          gridId={gridId}
          gridStatus={gridStatus}
          onReloadClick={onReloadClick}
          onRowClick={onRowClick}
          rows={rows}
          showCheckboxColumn={showCheckboxColumn}
          onCheckboxChange={onCheckboxChange}
          isCheckboxDisabled={isCheckboxDisabled}
          checkedRows={checkedRows}
          checked={checked}
        />
      );
    } else if (showFailed) {
      return <BodyFailed onReloadClick={onReloadClick} />;
    } else if (showNoData) {
      return <WidgetNoDataMessage />;
    }

    return null;
  }
}

const BodyProcessing = () => (
  <div className="grid-body processing">
    <Processing />
  </div>
);

interface IBodySucceededProps {
  actions: IAction[];
  columns: IColumn[];
  gridId: string;
  gridStatus: GridStatuses;
  onReloadClick?: () => void;
  onRowClick?: (e: React.MouseEvent<HTMLDivElement>, row: IRow) => void;
  rows: IRow[];
  showCheckboxColumn?: boolean;
  onCheckboxChange?: (e: CheckboxChangeEvent, row: IRow) => void;
  isCheckboxDisabled?: (row: IRow, checkedRows: IRow[]) => boolean;
  checkedRows?: IRow[];
  checked?: (row: IRow) => boolean;
}

const BodySucceeded = (props: IBodySucceededProps) => {
  const {
    actions,
    columns,
    gridId,
    gridStatus,
    onReloadClick,
    onRowClick,
    rows,
    showCheckboxColumn,
    onCheckboxChange,
    isCheckboxDisabled,
    checkedRows,
    checked
  } = props;

  const bodyRows = rows.map((r) => (
    <BodyRow
      actions={actions}
      columns={columns}
      gridId={gridId}
      key={r.rowId}
      onRowClick={onRowClick}
      row={r}
      rows={rows}
      showCheckboxColumn={showCheckboxColumn}
      onCheckboxChange={onCheckboxChange}
      isCheckboxDisabled={isCheckboxDisabled}
      checkedRows={checkedRows}
      checked={checked}
    />
  ));

  if (gridStatus === GridStatuses.Updating) {
    bodyRows.push(<SkeletonRow columns={columns} key={`skeleton-row`} />);
  }

  if (gridStatus === GridStatuses.Failed) {
    bodyRows.push(
      <MessageRow onReloadClick={onReloadClick} key={`message-row`} />
    );
  }

  return <div className="grid-body">{bodyRows}</div>;
};

interface IBodyFailedProps {
  onReloadClick: () => void;
}

const BodyFailed = (props: IBodyFailedProps) => {
  const { onReloadClick } = props;
  return (
    <div className="grid-body failed">
      <Icon name="error" />
      <FormattedMessage
        defaultMessage={
          "Data loading failed. Click {reload} to try to get data again."
        }
        id={"data_grid.loading.failed"}
        values={{
          reload: (
            <a
              href="#/"
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                if (onReloadClick) onReloadClick();
              }}
            >
              <FormattedMessage
                defaultMessage="reload"
                id="data_grid.loading.failed.reload"
              />
            </a>
          )
        }}
      />
    </div>
  );
};
