// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as d3 from "d3";
import createDuvalPentagonConvertedCoordinates from "./createDuvalPentagonConvertedCoordinates";

interface IDuvalPentagonLabelsInterface {
  root: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  pentagons: d3Selection<SVGElement>[];
}

type d3Selection<T extends d3.BaseType> = d3.Selection<
  T,
  unknown,
  null,
  undefined
>;

const useDuvalPentagonLabels = (args: IDuvalPentagonLabelsInterface) => {
  const labels = {
    hydrogen: "H2",
    acetylene: "C2H2",
    ethylene: "C2H4",
    methane: "CH4",
    ethane: "C2H6"
  };
  drawLabels(args, labels);
};

function drawLabels(
  { root, pentagons }: IDuvalPentagonLabelsInterface,
  labels: { [name: string]: string }
) {
  drawLabel(
    labels["hydrogen"],
    createDuvalPentagonConvertedCoordinates({
      point: { x: -3, y: 42 }
    }),
    root
  );
  drawLabel(
    labels["acetylene"],
    createDuvalPentagonConvertedCoordinates({
      point: { x: 39, y: 11 }
    }),
    root
  );
  drawLabel(
    labels["ethylene"],
    createDuvalPentagonConvertedCoordinates({
      point: { x: 25, y: -32.4 }
    }),
    root
  );
  drawLabel(
    labels["methane"],
    createDuvalPentagonConvertedCoordinates({
      point: { x: -36, y: -32.4 }
    }),
    root
  );
  drawLabel(
    labels["ethane"],
    createDuvalPentagonConvertedCoordinates({
      point: { x: -49.5, y: 11 }
    }),
    root
  );

  pentagons.forEach((pentagon) => {
    root
      .append("text")
      .text(pentagon.attr("id"))
      .style("font-size", "12px")
      .attr("x", pentagon.attr("x"))
      .attr("y", pentagon.attr("y"));
  });
}

function drawLabel(
  label: string,
  points: { x: number; y: number },
  root: d3Selection<SVGElement>
) {
  root
    .append("text")
    .text(`${label}`)
    .attr("x", points.x)
    .attr("y", points.y)
    .attr("transform", `rotate(${0},${points.x},${points.y})`)
    .attr("class", "label")
    .style("font-size", "16px");
}

export default useDuvalPentagonLabels;
