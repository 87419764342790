// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Action } from "redux";

export enum Actions {
  DetailPage_Reset = "DetailPage_Reset"
}

export const reset = (): Action<string> => ({
  type: Actions.DetailPage_Reset
});
