// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import { useCallback } from "react";
import { useHistory } from "react-router";

const useUrlSearchParams = () => {
  const history = useHistory();

  const setUrlParam = useCallback(
    (key: string, value: string | null | undefined) => {
      const params = new SearchParams(history.location.search);

      if (value) {
        params.set(key, value);
      } else {
        params.delete(key);
      }

      history.replace({
        search: params.toString()
      });
    },
    [history]
  );

  const getUrlParam = useCallback(
    (key: string) => {
      const params = new SearchParams(history.location.search);
      return params.get(key);
    },
    [history.location.search]
  );

  return {
    setUrlParam,
    getUrlParam
  };
};

export default useUrlSearchParams;
