// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { setQueryParams } from "core/app/actions/LocationActions";
import RouterProvider, {
  IRouterProviderActions,
  IRouterProviderData
} from "core/app/components/router/RouterProvider";
import { IRawQueryParams } from "core/app/reducers/LocationReducer";
import { IState } from "reducers/Index";

function mapStateToProps(state: IState): IRouterProviderData {
  return {
    experimental: state.app.location.queryParams.experimental
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<Action>
): IRouterProviderActions {
  return {
    setQueryParams: (rawQueryParams: IRawQueryParams) => {
      setQueryParams(dispatch, rawQueryParams);
    }
  };
}

const RouterProviderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RouterProvider);

export default RouterProviderContainer;
