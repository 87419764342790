// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import SearchParams from "@pg/common/build/models/SearchParams";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";

import ButtonWithIcon from "common/button/ButtonWithIcon";
import LabeledRow from "components/common/LabeledRow";
import Processing from "components/common/Processing";
import { WidgetErrorMessage } from "components/common/widget/Widget";
import { routesConfig } from "core/app/components/router/RouterProvider";
import Data, { Statuses } from "core/data/models/Data";
import { BoxPlotData } from "features/detailpage/features/familyinfo/components/BoxPlot";
import BoxPlotChart from "features/detailpage/features/familyinfo/components/BoxPlotChart";
import { IFamilyInfo } from "features/detailpage/features/familyinfo/reducers/FamilyInfoReducer";
import { DataTypes as ScatterPlotDataTypes } from "features/familyanalytics/services/ScatterPlotWidgetService";

import "./FamilyInfo.less";

export interface IFamilyInfoData {
  familyInfo: Data<IFamilyInfo>;
}

export interface IFamilyInfoOwnProps {
  assetId: string;
  title?: string;
  familyAnalyticsDataType?: ScatterPlotDataTypes;
  familyAnalyticsSeriesName?: string;
}

export interface IFamilyInfoProps
  extends IFamilyInfoOwnProps,
    IFamilyInfoData {}

class FamilyInfo extends React.Component<
  IFamilyInfoProps & RouteComponentProps
> {
  render() {
    const { title, familyInfo, familyAnalyticsDataType } = this.props;

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
    };
    if (familyInfo.status === Statuses.Loading) {
      return <Processing />;
    } else if (familyInfo.status === Statuses.Succeeded) {
      return (
        <div
          className="family-info-popover-container"
          data-qa="family-info-popover-container"
          onClick={handleClick}
        >
          {title && <span className="large-content">{title}</span>}
          <LabeledRow
            label={{
              id: "asset.nameplate.manufacturer",
              defaultMessage: "Manufacturer"
            }}
            value={familyInfo.data.Manufacturer}
          />
          <LabeledRow
            label={{
              id: "asset.nameplate.manufacturer_model",
              defaultMessage: "Manufacturer model"
            }}
            value={familyInfo.data.ManufacturerModel}
          />
          <div className="labeled-row">
            <span className="label">
              <FormattedMessage
                id={"detail_page.family_info.family_count"}
                defaultMessage="Family count"
              />
            </span>
            <span className="value">
              <FormattedMessage
                id="detail_page.family_info.family_count_value"
                defaultMessage="{count} of {familyCount}"
                values={{
                  count: familyInfo.data.Count,
                  familyCount: familyInfo.data.FamilyCount
                }}
              />
            </span>
          </div>
          <BoxPlotChart data={this.getBoxPlotData(familyInfo.data)} />

          {familyAnalyticsDataType && (
            <div className="compare-button-container">
              <ButtonWithIcon
                type="default"
                className="compare-button"
                onClick={this.openFamilyAnalytics}
                icon={<Icon name="speed" />}
                dataQa="compare-button"
              >
                <FormattedMessage
                  id="detail_page.family_info.compare_to_family"
                  defaultMessage="Compare to Family"
                />
              </ButtonWithIcon>
            </div>
          )}
        </div>
      );
    } else if (
      familyInfo.status === Statuses.Failed ||
      familyInfo.status === Statuses.NotFound
    ) {
      return (
        <div className="error-message">
          <WidgetErrorMessage
            messageId="global.empty"
            messageDefault={familyInfo.message}
            messageValues={familyInfo.message}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  private openFamilyAnalytics = () => {
    const { familyAnalyticsDataType, familyAnalyticsSeriesName, assetId } =
      this.props;
    const { Manufacturer, ManufacturerModel } = this.props.familyInfo.data;

    const searchParams = new SearchParams({
      assetId,
      dataTypeY: familyAnalyticsDataType,
      seriesNameY: familyAnalyticsSeriesName
    });

    if (Manufacturer && ManufacturerModel)
      searchParams.append(`f_am`, `["${Manufacturer}","${ManufacturerModel}"]`);
    else if (Manufacturer) searchParams.append(`f_am`, `["${Manufacturer}"]`);
    searchParams.append(`f_as`, JSON.stringify(["InService"]));
    this.props.history.push({
      pathname: routesConfig.familyAnalytics,
      search: searchParams.toString()
    });
  };

  private getBoxPlotData = (familyInfo: IFamilyInfo) => {
    return new BoxPlotData(
      familyInfo.Value,
      familyInfo.Q1,
      familyInfo.Median,
      familyInfo.Q3,
      familyInfo.UpperExtreme,
      familyInfo.LowerExtreme
    );
  };
}

export default withRouter(FamilyInfo);
