// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { ChartAPI, ChartConfiguration, DataPoint } from "c3";
import { BaseType, select, Selection } from "d3";

import { routesConfig } from "core/app/components/router/RouterProvider";

import IOpenIssueInfo from "../models/IOpenIssuesInfo";

interface IUseOpenIssuesChartOptions {
  openIssues: IOpenIssueInfo;
}

interface ISeries {
  New: boolean;
  InProgress: boolean;
  Monitor: boolean;
}

type LabelSelection = Selection<BaseType, unknown, HTMLElement, any>;

const IssuesStatusColors = {
  New: "#3398e7",
  InProgress: "#21be89",
  Monitor: "#e87c5e",
  Closed: "#a5b7ba"
};

const useOpenIssuesChart = ({ openIssues }: IUseOpenIssuesChartOptions) => {
  const intl = useIntl();
  const { push } = useHistory();
  const nodeRef = useRef();
  const seriesToggleRef = useRef<ISeries>({
    InProgress: true,
    Monitor: true,
    New: true
  });
  const chartApiRef = useRef<ChartAPI>();

  const getCurrentAmountByLegendToggles = useCallback(() => {
    const { InProgress, Monitor, New } = seriesToggleRef.current;
    const { NewCount = 0, InProgressCount = 0, MonitorCount = 0 } =
      openIssues || {};

    let amount = New ? NewCount : 0;
    amount += InProgress ? InProgressCount : 0;
    amount += Monitor ? MonitorCount : 0;
    return amount;
  }, [openIssues]);

  const appendOpenIssuesAmountToChart = useCallback(
    (label: LabelSelection) => {
      const amount = intl.formatNumber(getCurrentAmountByLegendToggles());
      label
        .insert("tspan")
        .text(amount)
        .attr("dy", -5)
        .attr("x", 0)
        .attr("class", "chart-title");
    },
    [intl, getCurrentAmountByLegendToggles]
  );

  const appendOpenIssuesSubitleToChart = useCallback(
    (label: LabelSelection) => {
      const translatedText = intl.formatMessage({
        id: "homepage.opened_issues.chart.subtitle",
        defaultMessage: "open issues"
      });

      label
        .insert("tspan")
        .text(translatedText)
        .attr("dy", 30)
        .attr("x", 0)
        .attr("class", "chart-subtitle");
    },
    [intl]
  );

  const onC3ChartRendered = useCallback(() => {
    if (nodeRef.current) {
      const label = select(nodeRef.current).select(
        ".c3 text.c3-chart-arcs-title"
      );
      label.selectAll("*").remove();
      appendOpenIssuesAmountToChart(label);
      appendOpenIssuesSubitleToChart(label);
    }
  }, [appendOpenIssuesAmountToChart, appendOpenIssuesSubitleToChart]);

  const handleDonutClick = useCallback(
    (data: DataPoint) => {
      push({
        pathname: routesConfig.issues,
        search: `f_is=["${encodeURIComponent(data.id)}"]`
      });
    },
    [push]
  );

  const handleLegendClick = useCallback((id: string) => {
    (seriesToggleRef.current as any)[id] = !(seriesToggleRef.current as any)[
      id
    ];
    if (chartApiRef.current) {
      chartApiRef.current.toggle(id);
    }
  }, []);

  const handleChartRendered = useCallback((chartApi: ChartAPI) => {
    chartApiRef.current = chartApi;
  }, []);

  const c3Config = useMemo((): ChartConfiguration => {
    const { NewCount = 0, InProgressCount = 0, MonitorCount = 0 } =
      openIssues || {};
    return {
      onrendered: onC3ChartRendered,
      data: {
        columns: [
          ["New", NewCount],
          ["InProgress", InProgressCount],
          ["Monitor", MonitorCount]
        ],
        colors: IssuesStatusColors,
        names: {
          New: intl.formatMessage({
            id: "issue.status.New",
            defaultMessage: "New"
          }),
          InProgress: intl.formatMessage({
            id: "issue.status.InProgress",
            defaultMessage: "In Progress"
          }),
          Monitor: intl.formatMessage({
            id: "issue.status.Monitor",
            defaultMessage: "Monitor"
          })
        },
        type: "donut",
        onclick: handleDonutClick
      },
      donut: {
        label: {
          show: false
        }
      },
      legend: {
        item: {
          onclick: handleLegendClick
        }
      }
    };
  }, [
    openIssues,
    intl,
    onC3ChartRendered,
    handleDonutClick,
    handleLegendClick
  ]);

  useEffect(() => {
    onC3ChartRendered();
  }, [onC3ChartRendered]);

  return { c3Config, nodeRef, handleChartRendered };
};

export default useOpenIssuesChart;
