// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getSimulationModalVisibleSelector = createSelector(
  (state: IState) => state.detailPage.simulationModalVisible,
  simulationModalVisible => simulationModalVisible
);

export default getSimulationModalVisibleSelector;
