// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import { Button } from "antd";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";

import "core/app/components/router/RouteNotFound.less";

class RouteNotFound extends React.Component<RouteComponentProps> {
  private handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const { history } = this.props;
    history.push("/");
  };

  render() {
    return (
      <div className="route-not-found">
        <div className="content">
          <div className="warning-icon">
            <Icon name="warning" />
          </div>
          <div className="container">
            <div className="title">
              <FormattedMessage
                id="route_not_found.title"
                defaultMessage="Oh no"
              />
            </div>
            <div className="not-found-container">
              <div>
                <FormattedMessage
                  id="route_not_found.message_content"
                  defaultMessage="The page you were looking for {doesntExists} or {cannotBeAccessed}."
                  values={{
                    doesntExists: (
                      <strong>
                        <FormattedMessage
                          defaultMessage="doesn't exist"
                          id="route_not_found.message.doesnt_exists"
                        />
                      </strong>
                    ),
                    cannotBeAccessed: (
                      <strong>
                        <FormattedMessage
                          defaultMessage="cannot be accessed"
                          id="route_not_found.message.cannot_be_accessed"
                        />
                      </strong>
                    )
                  }}
                />
              </div>
              <div>
                <FormattedMessage
                  id="route_not_found.message_tip"
                  defaultMessage="If you think this is an error, contact your local administrator."
                />
              </div>
            </div>
            <Button
              type="primary"
              className="homepage-button"
              size="large"
              onClick={this.handleClick}
            >
              <Icon name="home" size="sm" />
              <FormattedMessage
                id="route_not_found.return_to_home_page"
                defaultMessage="Back to Homepage"
              />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RouteNotFound);
