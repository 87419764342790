// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import JellyBeans from "@apm/widgets/build/components/JellyBeans";
import { SearchParams } from "@pg/common";
import {
  colorRiskHigh,
  colorRiskMedium
} from "@pg/common/build/styles/ColorVariables";
import Container from "common/Container";
import DataGrid, {
  IActionConfig,
  IColumnConfig,
  IDataEndpoint,
  IRow,
  IRowData
} from "common/datagrid/DataGrid";
import SortOrders from "common/datagrid/models/SortOrders";
import { SelectedFilters, StatusBar } from "common/FilterBar";
import Processing from "components/common/Processing";
import SectionName from "components/common/SectionName";
import { routesConfig } from "core/app/components/router/RouterProvider";
import UrlService from "core/data/services/UrlService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import styled from "styled-components";
import { spacingXLarge, spacingXXLarge } from "styles/StyleVariables";
import { config } from "utils/AppConfig";
import { alarmsTypeSummaryRead } from "../actions/alarmsTypeSummaryRead";
import read from "../actions/read";
import { AlarmsTypeSummary } from "../models/AlarmsTypeSummary";
import AlarmTypeColumn from "./AlarmTypeColumn";
import AssetIdColumn from "./AssetIdColumn";
import DateColumn from "./DateColumn";
import DetectionColumn from "./DetectionColumn";
import LocationColumn from "./LocationColumn";

interface IAlarmsGridProps {
  className?: string;
  filters: SelectedFilters;
}

const AlarmTypeSummaryWrapper = styled.div`
  width: 300px;
  margin-left: auto;
`;

const AlarmsGrid = ({ className, filters }: IAlarmsGridProps) => {
  const history = useHistory();
  const [totalCount, setTotalCount] = useState<number>();
  const [newCount, setNewCount] = useState<number>();
  const [alarmsTypeSummary, setAlarmsTypeSummary] =
    useState<AlarmsTypeSummary>(null);
  const [alarmsTypeSummaryLoading, setAlarmsTypeSummaryLoading] =
    useState<boolean>(false);

  const { formatMessage, formatNumber } = useIntl();

  const actions = useMemo<IActionConfig[]>(
    () => [
      {
        id: "markAsRead",
        iconName: "mark_chat_read",
        display: (row) => row.data["IsNew"],
        callback: (row, onStarted, onSucceeded, onFailed) => {
          onStarted();
          read(row.data["MessageLogId"])
            .then(() => {
              onSucceeded();
            })
            .catch(() => {
              onFailed();
            });
        }
      }
    ],
    []
  );

  const columns = useMemo<IColumnConfig[]>(
    () => [
      {
        component: (value, row) => (
          <AssetIdColumn assetId={value} isNew={row.data["IsNew"]} />
        ),
        frozen: true,
        id: "AssetId",
        message: {
          defaultMessage: "Asset ID",
          id: "alarms.grid.columns.asset_id"
        },
        weight: 2
      },
      {
        frozen: true,
        id: "SerialNumber",
        message: {
          defaultMessage: "Serial Number",
          id: "alarms.grid.columns.serial_number"
        },
        weight: 1.5
      },
      {
        component: (value, row) => (
          <LocationColumn
            organization={row.data["Organization"]}
            region={row.data["Region"]}
            location={row.data["Location"]}
          />
        ),
        frozen: true,
        id: "Location",
        message: {
          defaultMessage: "Location",
          id: "alarms.grid.columns.location"
        },
        weight: 2
      },
      {
        component: (value, row) => <AlarmTypeColumn alarmType={value} />,
        id: "AlarmType",
        message: {
          defaultMessage: "Alarm Type",
          id: "alarms.grid.columns.alarm_type"
        },
        weight: 1.5
      },
      {
        component: (value, row) => (
          <DetectionColumn
            parameterName={row.data["ParameterName"]}
            parameterValue={row.data["Value"]}
            parameterSource={row.data["ParameterSource"]}
            implementationId={row.data["ImplementationId"]}
            modelId={row.data["ModelId"]}
          />
        ),
        id: "Detection",
        message: {
          defaultMessage: "Detection",
          id: "alarms.grid.columns.detection"
        },
        sortable: false,
        weight: 3
      },
      {
        component: (value) => <DateColumn date={value} />,
        id: "Date",
        defaultSortOrder: SortOrders.Desc,
        message: {
          defaultMessage: "Date",
          id: "alarms.grid.columns.date"
        },
        weight: 1.5
      }
    ],
    []
  );

  const dataEndpoint: IDataEndpoint = useMemo(
    () => ({
      url: UrlService.getApiUrl(config.api.alarms.alarms),
      type: "POST",
      content: {
        search: filters.search,
        filters: filters.selects,
        ranges: filters.ranges
      },
      onDataLoaded: (rowsTotal: number, rowsNew: number | null) => {
        setTotalCount(rowsTotal);
        setNewCount(rowsNew);
      }
    }),
    [filters]
  );

  const handleSummaryAlarmTypes = useCallback(() => {
    return alarmsTypeSummaryRead({
      search: filters.search,
      filters: filters.selects,
      ranges: filters.ranges
    });
  }, [filters]);

  const handleRowClick = useCallback(
    (row: IRow<IRowData>) => {
      const searchParams = new SearchParams({
        assetId: row.data["AssetId"]
      });

      history.push({
        pathname: routesConfig.detailPage,
        search: searchParams.toString()
      });
    },
    [history]
  );

  useEffect(() => {
    setAlarmsTypeSummaryLoading(true);
    handleSummaryAlarmTypes()
      .then((data) => {
        setAlarmsTypeSummary(data);
      })
      .finally(() => {
        setAlarmsTypeSummaryLoading(false);
      });
  }, [filters, handleSummaryAlarmTypes]);

  return (
    <div className={`${className} grid-container data-grid__parent`}>
      <div className="data-grid__scroll">
        <Container>
          <div className="row">
            <div className="header" data-qa="common-filter-header">
              <SectionName
                messageId="alarms.grid.title"
                messageDefault="Alarms / {totalCount} items / { newCount } new"
                messageValues={{
                  totalCount: totalCount || 0,
                  newCount: newCount || 0
                }}
              />
              <StatusBar />
              <AlarmTypeSummaryWrapper>
                {!alarmsTypeSummaryLoading ? (
                  <JellyBeans
                    formatValue={formatNumber}
                    jellyBeans={[
                      {
                        name: formatMessage({
                          id: "alarms",
                          defaultMessage: "ALARMS"
                        }),
                        values: [
                          {
                            name: "highRisk",
                            color: colorRiskHigh,
                            value: alarmsTypeSummary?.HighAlarms
                          },
                          {
                            name: "mediumRisk",
                            color: colorRiskMedium,
                            value: alarmsTypeSummary?.MediumAlarms
                          }
                        ]
                      }
                    ]}
                  />
                ) : (
                  <Processing className="alarms-type-summary-loader" />
                )}
              </AlarmTypeSummaryWrapper>
            </div>
            <DataGrid
              actions={actions}
              columns={columns}
              dataEndpoint={dataEndpoint}
              multiColumnSorting={true}
              onRowClick={handleRowClick}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default styled(AlarmsGrid)`
  height: 100%;
  overflow: hidden;

  > div {
    height: 100%;
    padding-bottom: ${spacingXLarge};
  }

  .header {
    display: flex;
    align-items: center;

    .status-panel {
      margin-left: ${spacingXXLarge};
    }
  }

  .alarms-type-summary-loader {
    margin-top: 0;
  }

  .jelly-bean-name {
    text-transform: uppercase;
  }
`;
