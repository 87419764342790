// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { isEmpty } from "lodash";
import { useThunkMiddleware } from "thunk";

import DGAOperations from "features/detailpage/features/ltc/components/DGAOperations";

import { useDGAOperations } from "features/detailpage/features/ltc/reducers/LTCReducer";
import { loadDgaOperationsAction } from "features/detailpage/features/ltc/actions/DgaOperationsActions";
import { Statuses } from "core/data/models/Data";

interface IDGAOperationsContainerProps {
  assetId: string;
  modelId: string;
  implementationId: string;
}

const DGAOperationsContainer = ({
  assetId,
  modelId,
  implementationId
}: IDGAOperationsContainerProps) => {
  const { dgaOperations } = useDGAOperations();
  const loadDgaOperations = useThunkMiddleware(
    loadDgaOperationsAction(assetId)
  );

  React.useEffect(() => {
    if (
      dgaOperations.status !== Statuses.Succeeded ||
      isEmpty(dgaOperations.data)
    ) {
      loadDgaOperations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DGAOperations
      dgaOperations={dgaOperations}
      modelId={modelId}
      implementationId={implementationId}
    />
  );
};

export default DGAOperationsContainer;
