// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { notifications } from "@pg/common/build/components/Notifications";
import { Statuses } from "core/data/models/Data";
import UrlService from "core/data/services/UrlService";
import $ from "jquery";
import { isEmpty } from "lodash";
import { IntlShape } from "react-intl";
import { BaseStore } from "stores/BaseStore";
import { config } from "utils/AppConfig";
import { IData } from "utils/WebService";
import validator from "validator";

const newModelId = "New.Model";

enum ModelMode {
  View,
  Add,
  Edit
}

enum ParameterMode {
  View,
  Add,
  Edit
}

class ModelType {
  public static readonly Standard = "Standard";
  public static readonly Rest = "Rest";
}

export interface IModelPossibleValues {
  PossibleValues: { [sourceName: string]: string[] };
}

export interface IModelParameter {
  _Mode: ParameterMode;
  ModelParameter: string;
  Source: string;
  Input: string;
}

export interface IDirectionalLine {
  Slope: number | null | undefined;
  Intercept: number | null | undefined;
}

export interface IRevision {
  Revision: string;
}

export type ApiVersion = "Unknown" | "V1" | "V2" | "V3" | "V4" | "V5";

export interface IModelConfig {
  ModelId: string;
  ModelParameters: IModelParameter[];
  ModelUrl: string;
  Metadata: IRevision | null;
  RestModelApiVersion?: ApiVersion;
  LowToMediumRiskLineThreshold?: IDirectionalLine | null | undefined;
  MediumToHighRiskLineThreshold?: IDirectionalLine | null | undefined;
  IsThresholdEditable: Boolean;
}

export interface IModel {
  _Collector: IData<any>;
  _Mode: ModelMode;
  _Update: IData<any>;
  ModelId: string;
  ImplementationId: string;
  ModelType: string;
  ModelConfig: IData<IModelConfig>;
  ModelConfigBackup: IData<IModelConfig>;
}

class DataIntegrationStore extends BaseStore {
  private xhrLoadModelConfig: JQueryXHR;
  private xhrLoadPossibleValues: JQueryXHR;

  private modelPossibleValues: IData<IModelPossibleValues>;
  private models: IData<IModel[]>;
  private selectedModel: IModel;
  private sources: IData<string[]>;

  public initStore() {
    this.initSelectedModel();
    this.models = {
      status: null,
      data: null,
      message: null
    };
    this.sources = {
      status: null,
      data: null,
      message: null
    };

    this.emitChange();
  }

  public loadModels(intl: IntlShape) {
    const self = this;
    const url = UrlService.getApiUrl(config.api.dataIntegration.modelsUrl);
    this.models.status = Statuses.Loading;
    this.emitChange();
    $.ajax({
      url: url,
      type: "GET",
      dataType: "json",
      cache: false,
      success: function (
        data: {
          ModelId: string;
          ImplementationId: string;
          ModelType: string;
        }[]
      ) {
        self.models.status = Statuses.Succeeded;
        self.models.data = data.map<IModel>((d) => ({
          _Collector: {
            status: null,
            data: null,
            message: null
          },
          _Mode: ModelMode.View,
          _Update: {
            status: null,
            data: null,
            message: null
          },
          ModelId: d.ModelId,
          ImplementationId: d.ImplementationId,
          ModelType: d.ModelType,
          ModelConfig: {
            status: null,
            data: null,
            message: null
          },
          ModelConfigBackup: null
        }));
        self.models.message = null;

        if (data && data.length > 0) {
          self.selectModel(data[0].ModelId, intl);
        }

        self.emitChange();
      },
      error: function (xhr: JQueryXHR, status: any, err: any) {
        self.models.status = Statuses.Failed;
        self.models.data = null;
        self.models.message = `${xhr} ${status} ${err}`;
        self.selectedModel = null;
        self.emitChange();

        notifications.error({
          message: intl.formatMessage({
            id: "data_integration.load_models.failed",
            defaultMessage: "Loading model list failed"
          })
        });
      }
    });
  }

  public loadSources() {
    const self = this;
    const url = UrlService.getApiUrl(
      config.api.settings.parameter.modelParameterSourcesUrl
    );

    this.sources.status = Statuses.Loading;
    this.emitChange();

    $.ajax({
      url: url,
      type: "GET",
      dataType: "json",
      cache: false,
      success: function (data: string[]) {
        self.sources.status = Statuses.Succeeded;
        self.sources.data = data;
        self.sources.message = null;
        self.emitChange();
      },
      error: function (xhr: JQueryXHR, status: string, err: string) {
        self.sources.status = Statuses.Failed;
        self.sources.data = null;
        self.sources.message = `${xhr} ${status} ${err}`;
        self.emitChange();
      }
    });
  }

  public dropModel(intl: IntlShape) {
    if (this.selectedModel) {
      notifications.confirm({
        title: intl.formatMessage({
          id: "data_integration.drop_model.drop_question",
          defaultMessage: "Do you want to delete model?"
        }),
        onOk: () => {
          const self = this;
          const url = UrlService.getApiUrl(
            config.api.settings.parameter.dropModelUrl,
            [
              {
                name: "modelId",
                value: this.selectedModel.ModelId
              }
            ]
          );

          const model = this.selectedModel;
          model._Update.status = Statuses.Loading;
          this.emitChange();

          $.ajax({
            url: url,
            type: "DELETE",
            cache: false,
            success: function () {
              let key = null;
              for (let i = 0; i < self.models.data.length; i++) {
                if (
                  self.models.data[i].ModelId === self.selectedModel.ModelId
                ) {
                  key = i;
                  break;
                }
              }
              if (key != null) {
                self.models.data.splice(key, 1);
              }

              model._Update.status = Statuses.Succeeded;
              model._Update.message = null;
              self.emitChange();

              if (self.models.data.length > key) {
                self.selectModel(self.models.data[key].ModelId, intl);
              } else if (self.models.data.length > 0) {
                self.selectModel(
                  self.models.data[self.models.data.length - 1].ModelId,
                  intl
                );
              }

              notifications.success({
                message: intl.formatMessage({
                  id: "data_integration.drop_model.succeeded",
                  defaultMessage: "Model was deleted"
                })
              });
            },
            error: function (xhr: JQueryXHR, status: string, err: string) {
              model._Update.status = Statuses.Failed;
              model._Update.message = `${xhr} ${status} ${err}`;
              self.emitChange();

              notifications.error({
                message: intl.formatMessage({
                  id: "data_integration.drop_model.failed",
                  defaultMessage: "Deleting model failed"
                })
              });
            }
          });
        }
      });
    }
  }

  public storeModel(intl: IntlShape) {
    if (
      this.selectedModel &&
      this.isAllParametersValid(
        this.selectedModel.ModelConfig.data.ModelParameters
      )
    ) {
      if (this.selectedModel._Mode === ModelMode.Add) {
        this.storeNewModel(intl, this.selectedModel.ModelType);
      } else if (
        this.selectedModel._Mode === ModelMode.Edit &&
        this.selectedModel.ModelType === ModelType.Rest
      ) {
        this.storeExistingPredefinedModel(intl);
      } else if (
        this.selectedModel._Mode === ModelMode.Edit &&
        this.selectedModel.ModelType === ModelType.Standard
      ) {
        this.storeExistingModel(intl);
      }
    }
  }

  public addNewModel(modelType: string, intl: IntlShape) {
    const prepareModel = () => {
      const model = {
        _Collector: {
          status: null,
          data: null,
          message: null
        },
        _Mode: ModelMode.Add,
        _Update: {
          status: null,
          data: null,
          message: null
        },
        ModelId: newModelId,
        ModelType: modelType,
        ModelConfig: {
          status: null,
          data: null,
          message: null
        },
        ModelConfigBackup: null
      } as IModel;

      if (!this.models.data) this.models.data = [];
      this.models.data.unshift(model);

      this.setSelectedModel(model);
      this.emitChange();
    };

    if (this.selectedModel && this.selectedModel._Mode === ModelMode.Edit) {
      notifications.confirm({
        title: intl.formatMessage({
          id: "data_integration.select_model.model_in_add_edit_mode",
          defaultMessage: "The model is not saved. Do you want to leave?"
        }),
        onOk: () => {
          this.cancelModelEditing();
          prepareModel();
        }
      });
    } else if (
      this.selectedModel &&
      this.selectedModel._Mode === ModelMode.View
    ) {
      prepareModel();
    } else if (!this.selectedModel) {
      prepareModel();
    }
  }

  public addNewParameter() {
    if (this.selectedModel && this.selectedModel.ModelConfig.data) {
      if (!this.selectedModel.ModelConfig.data.ModelParameters)
        this.selectedModel.ModelConfig.data.ModelParameters = [];

      if (
        this.selectedModel.ModelConfig.data.ModelParameters.filter(
          (p) =>
            p.ModelParameter === undefined ||
            p.ModelParameter == null ||
            p.ModelParameter === ""
        ).length === 0
      ) {
        this.setModelToEditMode();
        this.resetAddParametersMode();
        this.resetEditParametersMode();
        this.selectedModel.ModelConfig.data.ModelParameters.push({
          _Mode: ParameterMode.Add,
          ModelParameter: null,
          Source: null,
          Input: null
        });
      }

      this.emitChange();
    }
  }

  public selectModel(modelId: string, intl: IntlShape) {
    if (this.selectedModel && this.selectedModel.ModelId !== modelId) {
      if (
        this.selectedModel._Mode === ModelMode.Add ||
        this.selectedModel._Mode === ModelMode.Edit
      ) {
        notifications.confirm({
          title: intl.formatMessage({
            id: "data_integration.select_model.model_in_add_edit_mode",
            defaultMessage: "The model is not saved. Do you want to leave?"
          }),
          onOk: () => {
            const model = this.getModel(modelId);
            if (model) {
              this.cancelModelEditing();
              this.setSelectedModel(model);
              this.emitChange();
            }
          }
        });
      } else {
        const model = this.getModel(modelId);
        if (model) {
          this.setSelectedModel(model);
          this.emitChange();
        }
      }
    } else {
      const model = this.getModel(modelId);
      if (model) {
        this.setSelectedModel(model);
        this.emitChange();
      }
    }
  }

  public setParameterToEditMode(i: number) {
    if (this.selectedModel) {
      const parameter = this.getParameter(i);
      if (parameter && !parameter._Mode) {
        this.setModelToEditMode();
        this.resetAddParametersMode();
        this.resetEditParametersMode();
        if (
          this.selectedModel._Mode === ModelMode.Add ||
          this.selectedModel.ModelConfigBackup.data.ModelParameters.filter(
            (p) => p.ModelParameter === parameter.ModelParameter
          ).length === 0
        ) {
          parameter._Mode = ParameterMode.Add;
        } else if (this.selectedModel._Mode === ModelMode.Edit) {
          parameter._Mode = ParameterMode.Edit;
        }
        this.emitChange();
      }
    }
  }

  public setModelToEditMode() {
    if (this.selectedModel && !this.selectedModel._Mode) {
      this.selectedModel._Mode = ModelMode.Edit;
      this.selectedModel.ModelConfigBackup = Object.deepClone(
        this.selectedModel.ModelConfig
      );
      this.emitChange();
    }
  }

  public cancelEditing() {
    if (this.selectedModel) {
      if (this.selectedModel._Mode === ModelMode.Add) {
        this.cancelModelEditing();
        if (this.models && this.models.data && this.models.data.length > 0) {
          this.setSelectedModel(this.models.data[0]);
        }
      } else {
        this.cancelModelEditing();
      }
      this.emitChange();
    }
  }

  public setModelId(modelId: string) {
    if (this.selectedModel) {
      this.setModelToEditMode();
      this.selectedModel.ModelConfig.data.ModelId = modelId;
      this.emitChange();
    }
  }

  public setUrl(url: string) {
    if (this.selectedModel && this.selectedModel.ModelConfig.data) {
      if (this.selectedModel.ModelConfig.data.ModelUrl !== url) {
        this.setModelToEditMode();
        this.selectedModel.ModelConfig.data.ModelUrl = url;
        this.emitChange();
      }
    }
  }

  public setApiVersion(version: ApiVersion) {
    if (this.selectedModel && this.selectedModel.ModelConfig.data) {
      if (this.selectedModel.ModelConfig.data.RestModelApiVersion !== version) {
        this.setModelToEditMode();
        this.selectedModel.ModelConfig.data.RestModelApiVersion = version;
        this.emitChange();
      }
    }
  }

  public setAssetRiskConfig(
    LowToMediumRiskLineThreshold: IDirectionalLine | undefined | null,
    MediumToHighRiskLineThreshold: IDirectionalLine | undefined | null
  ) {
    if (this.selectedModel && this.selectedModel.ModelConfig.data) {
      this.setModelToEditMode();
      this.selectedModel.ModelConfig.data.LowToMediumRiskLineThreshold =
        LowToMediumRiskLineThreshold;
      this.selectedModel.ModelConfig.data.MediumToHighRiskLineThreshold =
        MediumToHighRiskLineThreshold;
      this.emitChange();
    }
  }

  public setParameterId(i: number, value: string) {
    if (this.selectedModel && this.selectedModel.ModelConfig.data) {
      if (this.selectedModel.ModelConfig.data.ModelParameters) {
        this.selectedModel.ModelConfig.data.ModelParameters[i].ModelParameter =
          value;
        this.emitChange();
      }
    }
  }

  public setParameterInput(i: number, input: string) {
    if (this.selectedModel && this.selectedModel.ModelConfig.data) {
      if (this.selectedModel.ModelConfig.data.ModelParameters) {
        this.selectedModel.ModelConfig.data.ModelParameters[i].Input = input;
        this.emitChange();
      }
    }
  }

  public setParameterSource(i: number, source: string) {
    if (this.selectedModel && this.selectedModel.ModelConfig.data) {
      if (this.selectedModel.ModelConfig.data.ModelParameters) {
        this.selectedModel.ModelConfig.data.ModelParameters[i].Source = source;
        this.emitChange();
      }
    }
  }

  public getSelectedModel(): IModel {
    return this.selectedModel;
  }

  public getModelPossibleValues(): IData<IModelPossibleValues> {
    return this.modelPossibleValues;
  }

  public getModels(): IData<IModel[]> {
    return this.models;
  }

  public getSources(): IData<string[]> {
    return this.sources;
  }

  public isModelValid(model: IModel): boolean {
    return (
      (model.ModelType === ModelType.Standard ||
        (model.ModelType === ModelType.Rest &&
          this.isModelIdValid(model.ModelConfig.data.ModelId) &&
          this.isModelUrlValid(model.ModelConfig.data.ModelUrl) &&
          this.isModelRestApiVersionValid(
            model.ModelConfig.data.RestModelApiVersion
          ))) &&
      this.isAllParametersValid(model.ModelConfig.data.ModelParameters) &&
      this.isRiskThresholdsValid(model.ModelConfig.data)
    );
  }

  public isModelIdValid(modelId: string): boolean {
    const matchRegExp = /^[a-zA-Z][a-zA-Z0-9.]*$/;
    return validator.matches(modelId || "", matchRegExp);
  }

  public isModelIdUnique(modelId: string): boolean {
    return this.models.data.findIndex((x) => x.ModelId === modelId) === -1;
  }

  public modelIdStartsWithLetter(modelId: string): boolean {
    return validator.matches(modelId || "", /^[a-zA-Z]/);
  }

  public isModelUrlValid(modelUrl: string): boolean {
    return (
      !validator.isEmpty(modelUrl || "") &&
      validator.isURL(modelUrl || "") &&
      validator.matches(modelUrl, /^(https?:\/\/){1}/)
    );
  }

  public isModelRestApiVersionValid(version: ApiVersion): boolean {
    return !isEmpty(version) && version !== "Unknown";
  }

  public isAllParametersValid(modelParameters: IModelParameter[]): boolean {
    for (const modelParameter of modelParameters) {
      if (!this.isParameterValid(modelParameter)) return false;
    }
    return true;
  }

  public isParameterValid(modelParameter: IModelParameter): boolean {
    return (
      this.isParameterIdValid(modelParameter.ModelParameter) &&
      this.isParameterSourceValid(
        modelParameter.Source,
        modelParameter.Input
      ) &&
      this.isParameterInputValid(modelParameter.Source, modelParameter.Input)
    );
  }

  public isParameterIdValid(parameterId: string): boolean {
    return !validator.isEmpty(parameterId || "");
  }

  public isParameterSourceValid(source: string, input: string): boolean {
    if (!validator.isEmpty(source || "")) {
      return true;
    } else if (
      validator.isEmpty(source || "") &&
      validator.isEmpty(input || "")
    ) {
      return true;
    } else {
      return false;
    }
  }

  public isParameterInputValid(source: string, input: string): boolean {
    if (
      !validator.isEmpty(input || "") &&
      validator.matches(input || "", /^[a-zA-Z0-9_.]+( [a-zA-Z0-9_.]+)*$/)
    ) {
      return true;
    } else if (
      validator.isEmpty(source || "") &&
      validator.isEmpty(input || "")
    ) {
      return true;
    } else {
      return false;
    }
  }

  public isRiskThresholdsValid(modelConfig: IModelConfig): boolean {
    if (!modelConfig) return false;
    return (
      this.isRiskThresholdLineValid(modelConfig.LowToMediumRiskLineThreshold) &&
      this.isRiskThresholdLineValid(modelConfig.MediumToHighRiskLineThreshold)
    );
  }

  public isRiskThresholdLineValid(
    line: IDirectionalLine | null | undefined
  ): boolean {
    if (!line) return false;
    if (line.Slope === undefined || line.Slope === null || line.Slope > 0.0)
      return false;
    if (line.Intercept === undefined || line.Intercept === null) return false;
    return true;
  }

  private initSelectedModel() {
    this.selectedModel = null;
    this.modelPossibleValues = {
      status: null,
      data: null,
      message: null
    };
  }

  private loadModelConfig() {
    const self = this;
    const model = this.selectedModel;
    const url = UrlService.getApiUrl(
      config.api.settings.parameter.modelConfigUrl,
      [
        {
          name: "modelId",
          value: model.ModelId
        }
      ]
    );

    if (this.xhrLoadModelConfig) this.xhrLoadModelConfig.abort();

    model.ModelConfig.status = Statuses.Loading;
    this.emitChange();

    this.xhrLoadModelConfig = $.ajax({
      url: url,
      type: "GET",
      dataType: "json",
      cache: false,
      success: function (data: IModelConfig) {
        model.ModelConfig.status = Statuses.Succeeded;
        model.ModelConfig.data = data;
        model.ModelConfig.message = null;
        self.emitChange();
      },
      error: function (xhr: JQueryXHR, status: string, err: string) {
        model.ModelConfig.status = Statuses.Failed;
        model.ModelConfig.data = null;
        model.ModelConfig.message = `${xhr} ${status} ${err}`;
        self.emitChange();
      }
    });
  }

  private loadModelPossibleValues() {
    const self = this;
    const url = UrlService.getApiUrl(
      config.api.settings.parameter.modelParameterInputPossibleValuesUrl,
      [
        {
          name: "modelId",
          value: this.selectedModel.ModelId
        }
      ]
    );

    if (this.xhrLoadPossibleValues) this.xhrLoadPossibleValues.abort();

    this.modelPossibleValues.status = Statuses.Loading;
    this.emitChange();

    this.xhrLoadPossibleValues = $.ajax({
      url: url,
      type: "GET",
      dataType: "json",
      cache: false,
      success: function (data: IModelPossibleValues) {
        self.modelPossibleValues.status = Statuses.Succeeded;
        self.modelPossibleValues.data = data;
        self.modelPossibleValues.message = null;
        self.emitChange();
      },
      error: function (xhr: JQueryXHR, status: string, err: string) {
        self.modelPossibleValues.status = Statuses.Failed;
        self.modelPossibleValues.data = null;
        self.modelPossibleValues.message = `${xhr} ${status} ${err}`;
        self.emitChange();
      }
    });
  }

  private storeNewModel(intl: IntlShape, modelType: string) {
    const self = this;
    const url = UrlService.getApiUrl(
      config.api.settings.parameter.createModelConfigUrl,
      [
        {
          name: "modelType",
          value: modelType
        }
      ]
    );

    this.selectedModel._Update.status = Statuses.Loading;
    this.emitChange();

    $.ajax({
      url: url,
      type: "PUT",
      contentType: "application/json; charset=UTF-8",
      data: JSON.stringify(this.selectedModel.ModelConfig.data),
      success: function () {
        self.selectedModel._Mode = ModelMode.View;
        self.selectedModel._Update.status = Statuses.Succeeded;
        self.selectedModel._Update.message = null;
        self.selectedModel.ModelId =
          self.selectedModel.ModelConfig.data.ModelId;
        self.resetAddParametersMode();
        self.resetEditParametersMode();
        self.emitChange();

        notifications.success({
          message: intl.formatMessage({
            id: "data_integration.store_new_rest_model.succeed",
            defaultMessage: "New model was created"
          })
        });
      },
      error: function (xhr: JQueryXHR, status: any, err: any) {
        self.selectedModel._Update.status = Statuses.Failed;
        self.selectedModel._Update.message = `${xhr} ${status} ${err}`;
        self.emitChange();

        const messageId = DataIntegrationStore.safeJsonParse(xhr?.responseText);
        notifications.error({
          message: intl.formatMessage({
            id:
              typeof messageId === "string"
                ? `data_integration.model_config.save.failed.${messageId}`
                : "data_integration.store_new_rest_model.failed",
            defaultMessage: "Model creation process failed"
          })
        });
      }
    });
  }

  private storeExistingPredefinedModel(intl: IntlShape) {
    const self = this;
    const url = UrlService.getApiUrl(
      config.api.settings.parameter.updateExternalModelConfigUrl
    );

    this.selectedModel._Update.status = Statuses.Loading;
    this.emitChange();

    $.ajax({
      url: url,
      type: "PUT",
      contentType: "application/json; charset=UTF-8",
      data: JSON.stringify(this.selectedModel.ModelConfig.data),
      success: function () {
        self.selectedModel._Mode = ModelMode.View;
        self.selectedModel._Update.status = Statuses.Succeeded;
        self.selectedModel._Update.message = null;
        self.resetAddParametersMode();
        self.resetEditParametersMode();
        self.emitChange();

        notifications.success({
          message: intl.formatMessage({
            id: "data_integration.update_new_rest_model.succeed",
            defaultMessage: "Model was updated"
          })
        });
      },
      error: function (xhr: JQueryXHR, status: any, err: any) {
        self.selectedModel._Update.status = Statuses.Failed;
        self.selectedModel._Update.message = `${xhr} ${status} ${err}`;
        self.emitChange();

        const messageId = DataIntegrationStore.safeJsonParse(xhr?.responseText);
        notifications.error({
          message: intl.formatMessage({
            id:
              typeof messageId === "string"
                ? `data_integration.model_config.save.failed.${messageId}`
                : "data_integration.update_new_rest_model.failed",
            defaultMessage: "Model updating failed"
          })
        });
      }
    });
  }

  private storeExistingModel(intl: IntlShape) {
    const self = this;
    const url = UrlService.getApiUrl(
      config.api.settings.parameter.updateModelConfigUrl
    );

    this.selectedModel._Update.status = Statuses.Loading;
    this.emitChange();

    $.ajax({
      url: url,
      type: "PUT",
      contentType: "application/json; charset=UTF-8",
      data: JSON.stringify(this.selectedModel.ModelConfig.data),
      success: function () {
        self.selectedModel._Mode = ModelMode.View;
        self.selectedModel._Update.status = Statuses.Succeeded;
        self.selectedModel._Update.message = null;
        self.resetAddParametersMode();
        self.resetEditParametersMode();
        self.emitChange();

        notifications.success({
          message: intl.formatMessage({
            id: "data_integration.store_existing_model.succeed",
            defaultMessage: "Model was updated"
          })
        });
      },
      error: function (xhr: JQueryXHR, status: any, err: any) {
        self.selectedModel._Update.status = Statuses.Failed;
        self.selectedModel._Update.message = `${xhr} ${status} ${err}`;
        self.emitChange();

        const messageId = DataIntegrationStore.safeJsonParse(xhr?.responseText);
        notifications.error({
          message: intl.formatMessage({
            id:
              typeof messageId === "string"
                ? `data_integration.model_config.save.failed.${messageId}`
                : "data_integration.store_existing_model.failed",
            defaultMessage: "Model unpading process failed"
          })
        });
      }
    });
  }

  private cancelModelEditing() {
    if (this.selectedModel._Mode === ModelMode.Edit) {
      this.selectedModel._Mode = ModelMode.View;
      this.selectedModel.ModelConfig = this.selectedModel.ModelConfigBackup;
    } else if (this.selectedModel._Mode === ModelMode.Add) {
      this.removeModel(this.selectedModel.ModelId);
    }
  }

  private getModel(modelId: string): IModel {
    const models = (this.models.data || []).filter(
      (m) => m.ModelId === modelId
    );
    return models.length > 0 ? models[0] : null;
  }

  private removeModel(modelId: string): number {
    let key: number = null;
    for (let i = 0; i < this.models.data.length; i++) {
      if (this.models.data[i].ModelId === modelId) {
        key = i;
        break;
      }
    }

    if (key != null) this.models.data.splice(key, 1);
    return key;
  }

  private setSelectedModel(model: IModel) {
    this.selectedModel = model;
    this.loadModelPossibleValues();
    if (model._Mode === ModelMode.View) {
      this.loadModelConfig();
    } else if (model._Mode === ModelMode.Add) {
      this.addNewModelConfig();
      this.addNewParameter();
    }
  }

  private addNewModelConfig() {
    this.selectedModel.ModelConfig = {
      status: Statuses.Succeeded,
      data: {
        ModelId: null,
        ModelParameters: [],
        ModelUrl: null,
        Metadata: null,
        RestModelApiVersion: null,
        LowToMediumRiskLineThreshold: {
          Slope: -1,
          Intercept: 33
        },
        MediumToHighRiskLineThreshold: {
          Slope: -1,
          Intercept: 67
        },
        IsThresholdEditable: true
      },
      message: null
    };
    this.emitChange();
  }

  private getParameter(i: number): IModelParameter {
    if (
      this.selectedModel &&
      this.selectedModel.ModelConfig.data &&
      this.selectedModel.ModelConfig.data.ModelParameters &&
      this.selectedModel.ModelConfig.data.ModelParameters.length > 0
    ) {
      return this.selectedModel.ModelConfig.data.ModelParameters[i];
    }
    return null;
  }

  private resetAddParametersMode() {
    const parameters =
      this.selectedModel.ModelConfig.data.ModelParameters.filter(
        (p) => p._Mode === ParameterMode.Add
      );
    for (const parameter of parameters) {
      parameter._Mode = ParameterMode.View;
    }
  }

  private resetEditParametersMode() {
    const parameters =
      this.selectedModel.ModelConfig.data.ModelParameters.filter(
        (p) => p._Mode === ParameterMode.Edit
      );
    for (const parameter of parameters) {
      parameter._Mode = ParameterMode.View;
    }
  }

  private static safeJsonParse(text: string): any {
    try {
      return JSON.parse(text);
    } catch (e) {}
    return null;
  }
}

const dataIntegrationStore = new DataIntegrationStore();

export default dataIntegrationStore;
export { ModelMode, ParameterMode, ModelType };
