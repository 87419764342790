// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { UserRoles } from "core/app/components/auth/Authorization";
import {
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import { getUserSelector } from "core/app/reducers/AppReducer";
import AuthorizationService from "core/app/services/AuthorizationService";
import { useSelector } from "react-redux";

const useConfigurationConfig = () => {
  const user = useSelector(getUserSelector);
  const { isAvailable } = useFeature();

  const isConfigurationVisible =
    isAvailable(VisualizationKeys.Configuration_Tool_Manage_Assets) &&
    AuthorizationService.isAuthorized(user.data, [UserRoles.Administrator]);

  return { isConfigurationVisible };
};

export default useConfigurationConfig;
