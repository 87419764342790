// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useMemo } from "react";
import { isEmpty } from "lodash";
import { ChartType, AxisName } from "c3";

import ClusterService from "common/AssetRiskMatrix/services/ClustersService";

import ITierCluster from "common/AssetRiskMatrix/models/ITierCluster";

interface IUseAssetRiskMatrixClusterTiersOptions {
  groupedClusters: ITierCluster;
}

const useAssetRiskMatrixClusterTiers = ({
  groupedClusters
}: IUseAssetRiskMatrixClusterTiersOptions) => {
  const clustersNamedSeries = useMemo(
    () =>
      !isEmpty(groupedClusters)
        ? ClusterService.getClustersNamesSeries(groupedClusters)
        : [],
    [groupedClusters]
  );

  const clustersXs = useMemo(
    () =>
      !isEmpty(groupedClusters)
        ? ClusterService.getClustersXs(groupedClusters)
        : {},
    [groupedClusters]
  );

  const clustersChartType = useMemo(
    () =>
      !isEmpty(groupedClusters)
        ? (ClusterService.getClustersC3PropertyDictionary(
            groupedClusters,
            "scatter"
          ) as { [key: string]: ChartType })
        : {},
    [groupedClusters]
  );

  const clustersAxes = useMemo(
    () =>
      !isEmpty(groupedClusters)
        ? (ClusterService.getClustersC3PropertyDictionary(
            groupedClusters,
            "y"
          ) as {
            [key: string]: AxisName;
          })
        : {},
    [groupedClusters]
  );

  const clustersClasses = useMemo(
    () =>
      !isEmpty(groupedClusters)
        ? ClusterService.getClustersClasses(groupedClusters)
        : {},
    [groupedClusters]
  );

  return {
    clustersNamedSeries,
    clustersXs,
    clustersChartType,
    clustersAxes,
    clustersClasses
  };
};

export default useAssetRiskMatrixClusterTiers;
