// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";

import { getDataKey } from "../actions/loadOperations";

import IOperation from "../models/IOperation";

const getOperationsSelector = createSelector<
  IState,
  (assetId: string, eventId: string) => Data<IOperation>,
  (assetId: string, eventId: string) => Data<IOperation>
>(
  (state: IState) => (assetId: string, eventId: string) =>
    state.data[getDataKey(assetId, eventId)],
  (operations) => operations
);

export default getOperationsSelector;
