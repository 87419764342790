// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import React from "react";
import { injectIntl, IntlShape } from "react-intl";
import ReactModal from "react-modal";

import ProcessingModal from "components/common/ProcessingModal";
import modalWindowStore, { IModal } from "stores/common/modal/ModalWindowStore";

import "components/common/modal/ModalWindow.less";

interface IModalWindowProps {
  modalId: string;
  modalTitle?: string;
  className?: string;
  children?: any;
  intl: IntlShape;
}

interface IModalWindowState extends IModal {}

class ModalWindow extends React.Component<
  IModalWindowProps,
  IModalWindowState
> {
  public static defaultProps: IModalWindowProps = {
    modalId: null,
    className: "modal-window-static",
    intl: null
  };

  constructor(props: IModalWindowProps) {
    super(props);

    this.state = {
      open: false,
      processing: false,
      closeOnOverlayClick: true,
      messages: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCloseModalClick = this.handleCloseModalClick.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  public componentDidMount() {
    modalWindowStore.addChangeListener(this.handleChange);
    modalWindowStore.initStore(this.props.modalId);
  }

  public componentWillUnmount() {
    modalWindowStore.removeChangeListener(this.handleChange);
  }

  public render() {
    return (
      <ReactModal
        isOpen={this.state.open}
        onAfterOpen={this.handleAfterOpen}
        onRequestClose={this.handleRequestClose}
        shouldCloseOnOverlayClick={this.state.closeOnOverlayClick}
        className={this.props.className}
        contentLabel="Modal"
        overlayClassName="modal-window-overlay"
      >
        <div className="modal-window">
          <div className="header">
            <a href="#/" onClick={this.handleCloseModalClick}>
              <Icon dataQa="close" name="close" />
            </a>
            <div className="c-label" data-qa="window-label">
              {this.props.modalTitle}
            </div>
          </div>
          {this.props.children}
          {this.state.processing ? (
            <ProcessingModal
              className="processing-screen absolute light"
              processingClassName="spinner large dark"
            />
          ) : null}
        </div>
      </ReactModal>
    );
  }

  private handleChange() {
    this.setState(modalWindowStore.getModalState(this.props.modalId));
  }

  private handleCloseModalClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    this.closeModal();
  }

  private handleRequestClose() {
    this.closeModal();
  }

  private handleAfterOpen() {
    document.body.style.overflow = "hidden";
  }

  private closeModal() {
    modalWindowStore.closeModal(this.props.modalId);
  }
}

export default injectIntl(ModalWindow);
