import IField from "./IField";
import ISelectOption from "./ISelectOption";

export const isSelectOption = (
  listValues: IField["listValues"]
): listValues is ISelectOption[] => {
  return (
    Array.isArray(listValues) &&
    listValues.some(
      (listValue) => typeof listValue === "object" && "label" in listValue
    )
  );
};
