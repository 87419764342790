// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { FormattedMessage } from "react-intl";

const SaveButtonDisabledTooltip = () => (
  <FormattedMessage
    id="notifications.save_button.disabled.tooltip"
    defaultMessage="Please provide all required data"
  />
);

export default SaveButtonDisabledTooltip;
