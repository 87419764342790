// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import { History } from "history";

export default class HistoryService {
  static getParameters(history: History): SearchParams {
    return new SearchParams(history.location.search);
  }

  static setParameters(history: History, params: SearchParams): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        history.replace({ search: params.toString() });
        resolve();
      });
    });
  }
}
