// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useState, useEffect } from "react";

import EndpointService from "../../../core/data/services/EndpointService";
import Data from "../../../core/data/models/Data";

const useLicenses = <T>(url: string) => {
  const [licenses, setLicenses] = useState(new Data<T>());

  useEffect(() => {
    EndpointService.getJson<T>(
      url,
      (request, data) => {
        setLicenses(new Data(request, data));
      },
      (request) => {
        setLicenses(new Data(request));
      }
    );

    return () => {
      licenses?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return [licenses];
};

export default useLicenses;
