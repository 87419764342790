// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import SearchParams from "@pg/common/build/models/SearchParams";
import Processing from "components/common/Processing";
import { routesConfig } from "core/app/components/router/RouterProvider";
import Data, { Statuses } from "core/data/models/Data";
import IAssetTree from "features/detailpage/features/assettree/models/IAssetTree";
import getAssetTreeSelector from "features/detailpage/features/assettree/selectors/getAssetTreeSelector";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IState } from "reducers/Index";
import "./SidePanel.less";

export interface ISidePanelActions {}

export interface ISidePanelRouteParams {
  assetId: string;
}

export interface ISidePanelData {
  assetTree?: Data<IAssetTree>;
}

export interface ISidePanelProps extends ISidePanelActions, ISidePanelData {}

const SidePanel = (props: ISidePanelProps) => {
  const location = useLocation();
  const history = useHistory();
  const isSelected = (componentId: string): boolean =>
    componentId === new SearchParams(location.search).get("assetId");

  const getDetailsNode = (
    assetId: string,
    assetName: string,
    risk: string,
    isComponent: boolean
  ): React.ReactNode => {
    return (
      <div
        className="details"
        onClick={(e) => {
          const currentAssetId = new SearchParams(location.search).get(
            "assetId"
          );
          if (currentAssetId !== assetId) {
            const searchParams = new SearchParams({ assetId });
            history.push({
              pathname: routesConfig.detailPage,
              search: searchParams.toString()
            });
          }
        }}
        style={{
          paddingLeft: isComponent ? 40 : 15,
          paddingRight: 20
        }}
      >
        <div className="name-icon">
          {!isComponent ? <Icon name="folder_open" size="lg" /> : null}
          <div className={`name ${isComponent ? "no-icon" : ""}`}>
            {assetId}
          </div>
        </div>
        <div className={`risk ${risk}`} hidden={!risk} />
      </div>
    );
  };

  const getComponentNode = (
    componentId: string,
    componentName: string,
    risk: string
  ) => {
    return (
      <div
        key={componentId}
        className={`component ${isSelected(componentId) ? "active" : ""}`}
      >
        {getDetailsNode(componentId, componentName, risk, true)}
      </div>
    );
  };

  const getProcessingNode = (): React.ReactNode => (
    <Processing className="spinner margin small light" />
  );

  const getAssetNode = (): React.ReactNode => {
    return (
      <div className="asset active">
        {getDetailsNode(
          props.assetTree.data.Id,
          props.assetTree.data.Name,
          null,
          false
        )}
        {props.assetTree.data.Components.map((c) =>
          getComponentNode(c.Id, c.Name, c.Risk)
        )}
      </div>
    );
  };

  const getErrorMessageNode = (): React.ReactNode => (
    <div className="error">
      <Icon name="error" />
      <FormattedMessage
        id="detail_page.side_panel.asset_tree.loading_failed"
        defaultMessage="Error"
      />
    </div>
  );

  let component: React.ReactNode = null;
  if (props.assetTree.status === Statuses.Loading)
    component = getProcessingNode();
  if (props.assetTree.status === Statuses.Succeeded) component = getAssetNode();
  if (props.assetTree.status === Statuses.Failed)
    component = getErrorMessageNode();

  return (
    <div className="detail-page-side-panel" data-qa="asset-tree">
      {component}
    </div>
  );
};

const mapStateToProps = (state: IState) => {
  const props: ISidePanelData = {
    assetTree: getAssetTreeSelector(state)
  };
  return props;
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
