// Copyright 2016-2021 Hitachi Power Grids. All rights reserved.
import { IConfigurationGridProps } from "@apm/widgets/build/widgets/ConfigurationGrid/components/ConfigurationGrid";
import { Col, Row, Typography } from "antd";
import { Moment } from "moment";
import React, { PropsWithChildren, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { colorStatusRed } from "styles/ColorVariables";
import ISection from "../models/ISection";
import ISpan from "../models/ISpan";
import { CustomSectionGrid } from "./CustomSectionGrid";
import CustomSectionList from "./CustomSectionList";
import CustomSectionTable from "./CustomSectionTable";

const { Title } = Typography;

export interface ICustomSectionsProps<T extends object = any> {
  className?: string;
  configuration: ISection[];
  attributes?: React.ReactNode;
  fieldIsRequired?: (fieldName: string) => boolean;
  getInitialValue?: (
    fieldName: string
  ) => string | number | boolean | Date | Moment;
  gridConfig?: IConfigurationGridProps<T>;
}

const CustomSections = <T extends object = any>({
  className,
  configuration,
  attributes,
  fieldIsRequired,
  getInitialValue,
  gridConfig
}: PropsWithChildren<ICustomSectionsProps<T>>) => {
  const popupContainerRef = React.createRef<HTMLDivElement>();

  const getSectionNode = useCallback(
    (section: ISection, key: string, overwriteSpan?: ISpan) =>
      section.sectionType !== "horizontalList" && (
        <Col
          xs={overwriteSpan?.xs ?? section.span?.xs ?? 24}
          sm={overwriteSpan?.sm ?? section.span?.sm}
          lg={overwriteSpan?.lg ?? section.span?.lg}
          key={key}
        >
          {section.sectionType === "table" && (
            <CustomSectionTable
              configuration={section}
              getPopupContainer={() => popupContainerRef.current}
              getInitialValue={getInitialValue}
            />
          )}
          {section.sectionType === "list" && (
            <CustomSectionList
              configuration={section}
              fieldIsRequired={fieldIsRequired}
              getInitialValue={getInitialValue}
            />
          )}

          {section.sectionType === "attributes" && attributes && (
            <div className="section section-list">
              <Title level={5}>
                {section.sectionName && (
                  <FormattedMessage {...section.sectionName} />
                )}
              </Title>
              {attributes}
            </div>
          )}
        </Col>
      ),
    [fieldIsRequired, getInitialValue, popupContainerRef, attributes]
  );

  const getSectionElementNode = useCallback(
    (sectionElement: ISection, key: string, overwriteSpan?: ISpan) => {
      if (sectionElement.fields) {
        return getSectionNode(sectionElement, key, overwriteSpan);
      }

      if (sectionElement.sectionList) {
        return (
          <Col
            xs={overwriteSpan?.xs ?? sectionElement.span?.xs ?? 24}
            sm={overwriteSpan?.sm ?? sectionElement.span?.sm}
            lg={overwriteSpan?.lg ?? sectionElement.span?.lg}
            key={key}
          >
            <Row gutter={16}>
              {sectionElement.sectionList.map((section, j) =>
                getSectionNode(section, `${key}|${j}`, {
                  xs: 24,
                  sm: 24,
                  lg: 24
                })
              )}
            </Row>
          </Col>
        );
      }
    },
    [getSectionNode]
  );

  const isSomeSection = useCallback(
    (sectionType: ISection["sectionType"]) => {
      return configuration.some((c) => {
        return c.sectionType === sectionType;
      });
    },
    [configuration]
  );

  const sectionNodes = useMemo(() => {
    const nodes: React.ReactNode[] = [];
    if (
      configuration?.length === 3 &&
      configuration?.every((p) => p?.span?.lg === 8) &&
      configuration?.every((p) => p?.span?.sm === 12)
    ) {
      const joinedColspan: ISpan = {
        xs: 24,
        sm: 24,
        lg: 12
      };
      nodes.push(
        <Col xs={24} sm={12} lg={16} key={"0"}>
          <Row gutter={16}>
            {getSectionElementNode(configuration[0], "1", joinedColspan)}
            {getSectionElementNode(configuration[1], "2", joinedColspan)}
          </Row>
        </Col>,
        getSectionElementNode(configuration[2], "3")
      );
    } else {
      configuration?.forEach((sectionElement, i) => {
        nodes.push(getSectionElementNode(sectionElement, i.toString()));
      });
    }
    return (
      <Row gutter={16} style={{ margin: 0 }}>
        {nodes}
      </Row>
    );
  }, [configuration, getSectionElementNode]);

  return (
    <div className={className}>
      {!isSomeSection("horizontalList") && !isSomeSection("grid")
        ? sectionNodes
        : configuration.map((section, idx) => {
            if (section.sectionType === "horizontalList") {
              return (
                <Row gutter={16} style={{ margin: 0 }} key={idx}>
                  <CustomSectionList
                    configuration={section}
                    fieldIsRequired={fieldIsRequired}
                    getInitialValue={getInitialValue}
                    horizontally={true}
                  />
                </Row>
              );
            }

            if (section.sectionType === "grid") {
              return (
                <CustomSectionGrid<T>
                  gridConfig={gridConfig}
                  key={section.guid}
                />
              );
            }

            return null;
          })}

      <div ref={popupContainerRef} />
    </div>
  );
};

const StyledCustomSections = styled(CustomSections)`
  .table-cell-validation-popover {
    &.popover-no-content {
      display: none;
    }
    .ant-popover-inner-content {
      color: ${colorStatusRed};
    }
  }
` as typeof CustomSections;

export default StyledCustomSections;
