// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Dispatch } from "redux";
import { IAjaxAction } from "reducers/Index";

import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import IVoltageData from "../models/IVoltageData";
import { config } from "utils/AppConfig";

export enum Actions {
  DetailPage_BatterVoltage_SetVoltageData = "DetailPage_BushingVisualization_SetOnlineBushingData"
}

export const setVoltageDataAction = (
  request: JQueryXHR,
  data?: IVoltageData
): IAjaxAction<IVoltageData> => ({
  type: Actions.DetailPage_BatterVoltage_SetVoltageData,
  request,
  data
});

export const loadVoltageData = (
  dispatch: Dispatch,
  assetId?: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(config.api.detailPage.batteryVoltageUrl, {
      assetId
    });

    const request = EndpointService.getJson<IVoltageData>(
      url,
      (request, data) => {
        dispatch(setVoltageDataAction(request, data));
        resolve();
      },
      request => {
        dispatch(setVoltageDataAction(request));
        reject();
      }
    );

    dispatch(setVoltageDataAction(request));
  });
};
