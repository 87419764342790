// Copyright 2016-2022 Hitachi Energy. All rights reserved.
const constructionTypes = [
  "din",
  "hvdc",
  "oip",
  "resin_bonded",
  "rip",
  "sf6",
  "unknown"
];

export default constructionTypes;
