// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import ColumnAsset from "common/columns/Asset";
import ColumnReplacementScore from "common/columns/ReplacementScore";
import Risk from "common/columns/Risk";
import { IColumnConfig } from "common/datagrid/DataGrid";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

const useLocationsAssetGridColumnsConfig = () => {
  const columns: IColumnConfig[] = useMemo(
    () => [
      {
        component: (_, row) => (
          <ColumnAsset
            assetId={row.data["AssetId"]}
            assetName={row.data["AssetName"]}
            assetType={row.data["AssetType"]}
            location={row.data["Location"]}
          />
        ),
        id: "AssetId",
        message: {
          defaultMessage: "Asset",
          id: "locations_page.side_panel.asset_grid.columns.asset"
        },
        weight: 3
      },
      {
        component: (_, row) => (
          <ColumnReplacementScore
            isWarning={row.data["IsReplacementScoreWarning"]}
            replacementScore={row.data["ReplacementScore"]}
            assetType={row.data["AssetType"]}
            rank={row.data["Rank"]}
            total={row.data["Total"]}
          />
        ),
        id: "ReplacementScore",
        message: {
          defaultMessage: "RS Rank",
          id: "locations_page.side_panel.asset_grid.columns.replacement_score"
        },
        weight: 1,
        HeaderTooltip: () => (
          <FormattedMessage
            defaultMessage="Replacement Rank"
            id="locations_page.side_panel.asset_grid.columns.replacement_score.tooltip"
          />
        )
      },
      {
        component: (value) => <Risk risk={value} />,
        id: "AssetRisk",
        message: {
          defaultMessage: "Risk",
          id: "locations_page.side_panel.asset_grid.columns.risk"
        }
      }
    ],
    []
  );

  return columns;
};

export default useLocationsAssetGridColumnsConfig;
