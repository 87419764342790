// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import dataKey from "../constants/dgaDataKey";

const loadDGAData = (assetId: string) => {
  const url = UrlService.getApiUrl(
    config.api.detailPage.dissolvedGasAnalysisUrl,
    [
      {
        name: "assetId",
        value: assetId
      }
    ]
  );

  return loadData({ key: dataKey(assetId), url });
};

export default loadDGAData;
