// Copyright 2016-2021 Hitachi Power Grids. All rights reserved.
import { InputNumber } from "antd";
import Form from "antd/lib/form";
import IField from "features/ConfigurationTool/models/IField";
import { Moment } from "moment";
import React, { useEffect } from "react";

interface IInputNumberFieldProps {
  configuration: IField;
  toggleEdit: () => void;
  getValidationErrors: (fieldKey: string) => Promise<void>;
  getInitialValue?: (
    fieldName: string
  ) => string | number | boolean | Date | Moment;
}

const InputNumberField = ({
  configuration,
  toggleEdit,
  getValidationErrors,
  getInitialValue
}: IInputNumberFieldProps) => {
  const ref = React.createRef<HTMLInputElement>();

  useEffect(() => {
    ref.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Item
      name={configuration.fieldKey}
      rules={configuration.rules}
      initialValue={
        getInitialValue ? getInitialValue(configuration.fieldKey) : null
      }
    >
      <InputNumber
        ref={ref}
        onPressEnter={toggleEdit}
        onBlur={toggleEdit}
        onChange={() => getValidationErrors(configuration.fieldKey)}
      />
    </Form.Item>
  );
};

export default InputNumberField;
