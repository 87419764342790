// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { closeIssue as closeIssueAction } from "features/issues/actions/IssuesActions";

const useCloseIssueAction = () => {
  const dispatch = useDispatch();

  const closeIssue = useCallback(
    (issueId) => closeIssueAction(dispatch, issueId),
    [dispatch]
  );

  return closeIssue;
};

export default useCloseIssueAction;
