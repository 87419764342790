// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import loadData from "core/data/actions/loadData";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import ChartNames from "../models/ChartNames";
import ICircleChartConfiguration from "../models/ICircleChartConfiguration";

export const getDataKey = (assetId: string, chartName: ChartNames) =>
  `DetailPage_Parameters_DC-${assetId}-${chartName}`;

const mapToApiParameters = (parameters: {
  [name: string]: ICircleChartConfiguration;
}) => {
  const keys = Object.keys(parameters);
  const apiParameters: { [name: string]: any } = {};

  keys.forEach((k) => {
    apiParameters[k] = {
      StartingPointXParameter: parameters[k].startingPointXParameter,
      StartingPointYParameter: parameters[k].startingPointYParameter,
      YellowThresholdSizeParameter: parameters[k].yellowThresholdSizeParameter,
      RedThresholdSizeParameter: parameters[k].redThresholdSizeParameter,
      ReadingXParameter: parameters[k].readingXParameter,
      ReadingYParameter: parameters[k].readingYParameter
    };
  });

  return apiParameters;
};

const loadRollingAverageForAsset = (
  assetId: string,
  chartConfiguration: { [key: string]: ICircleChartConfiguration },
  chartName: ChartNames
) => {
  const url = UrlService.getApiUrl(
    config.api.detailPage.dcRollingAverageForAssetUrl
  );

  return loadData({
    key: getDataKey(assetId, chartName),
    url,
    content: {
      AssetId: assetId,
      Parameters: mapToApiParameters(chartConfiguration)
    },
    method: "post"
  });
};

export default loadRollingAverageForAsset;
