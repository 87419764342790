// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useExportToCsv from "../hooks/useExportToCsv";
import useExportToExcel from "../hooks/useExportToExcel";

interface IExportToFileProps {
  assetId: string;
  canExportToExcel: boolean;
}

interface IExcelItemProps {
  canExportToExcel: boolean;
}

const ExcelExportItem = ({ canExportToExcel }: IExcelItemProps) => {
  const intl = useIntl();
  const excelLabel = intl.formatMessage({
    id: "data_grid.footer.export.excel",
    defaultMessage: "Excel (.xlsx)"
  });

  const warningMessage = intl.formatMessage({
    id: "data_grid.footer.export.excel.warning",
    defaultMessage:
      "Excel export is unavailable due to the parameter count limit being exceeded."
  });

  return canExportToExcel ? (
    <span>{excelLabel}</span>
  ) : (
    <Tooltip placement="bottomRight" title={warningMessage}>
      {excelLabel}
    </Tooltip>
  );
};

const ExportToFile = ({ 
  assetId, 
  canExportToExcel 
}: IExportToFileProps) => {
  const { handleExportClick } = useExportToExcel();
  const { handleExportCsvClick } = useExportToCsv();

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => handleExportClick(assetId)}
        disabled={!canExportToExcel}
      >
        <ExcelExportItem canExportToExcel={canExportToExcel} />
      </Menu.Item>
      <Menu.Item onClick={() => handleExportCsvClick(assetId)}>
        <FormattedMessage
          defaultMessage="CSV"
          id="data_grid.footer.export.csv"
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button type="link" className="export-button">
        <Icon name="file_download" size="sm" />
        <FormattedMessage
          defaultMessage="Export"
          id="data_grid.footer.export"
        />
        <Icon name="expand_more" />
      </Button>
    </Dropdown>
  );
};

export default ExportToFile;
