// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import RecalculateButton from "common/RecalculateButton";
import { TooltipPosition } from "common/tooltipwrapper/TooltipWrapper";
import { UserRoles } from "core/app/components/auth/Authorization";
import {
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import AuthorizationContainer from "core/app/containers/AuthorizationContainer";
import { getUserSelector } from "core/app/reducers/AppReducer";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";
import Data from "core/data/models/Data";
import EditAssetButton from "features/ConfigurationTool/components/EditAssetButton";
import DetailPageWidgetContext from "features/detailpage/contexts/DetailPageWidgetContext";
import ReportsContainer from "features/detailpage/features/reports/containers/ReportsContainer";
import { ReportsModes } from "features/detailpage/features/reports/models/ReportsModes";
import { memo, useCallback, useContext, useMemo } from "react";
import { injectIntl, useIntl } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { config } from "utils/AppConfig";
import { ExportToExcelIcon } from "./ExportToExcelIcon";
import "./NameplateIcons.less";

interface INameplateIconContextProps {
  setIsAssetNameplateWidgetVisible: (value: boolean) => void;
  isAssetNameplateWidgetVisible: boolean;
}

const NameplateIconWithContext = memo(
  ({
    isAssetNameplateWidgetVisible,
    setIsAssetNameplateWidgetVisible
  }: INameplateIconContextProps) => {
    const intl = useIntl();
    const handleNameplateIconClick = useCallback(() => {
      setIsAssetNameplateWidgetVisible(!isAssetNameplateWidgetVisible);
    }, [isAssetNameplateWidgetVisible, setIsAssetNameplateWidgetVisible]);

    return (
      <div
        className="c-tooltip"
        data-tooltip={intl.formatMessage({
          id: "detail_page.widgets.asset_nameplate_widget.title",
          defaultMessage: "Nameplate"
        })}
      >
        <Icon
          name="info"
          onClick={handleNameplateIconClick}
          dataQa="asset-nameplate-icon"
        />
      </div>
    );
  }
);

const NameplateIcon = () => {
  const { setIsAssetNameplateWidgetVisible, isAssetNameplateWidgetVisible } =
    useContext(DetailPageWidgetContext);
  return (
    <NameplateIconWithContext
      setIsAssetNameplateWidgetVisible={setIsAssetNameplateWidgetVisible}
      isAssetNameplateWidgetVisible={isAssetNameplateWidgetVisible}
    />
  );
};

const ReportsHeader = injectIntl(({ intl }) => (
  <div
    className="c-tooltip"
    data-tooltip={intl.formatMessage({
      id: "homepage.analytics.external_links",
      defaultMessage: "External links"
    })}
  >
    <Icon dataQa="external-links-icon" name="link" />
  </div>
));

interface INameplateIconsOwnProps {
  assetId: string;
  modelId: string;
  assetType: string;
}

interface INameplateIconContextTabsDataProps {
  user: Data<IUser>;
}

type INameplateIconsProps = INameplateIconsOwnProps &
  INameplateIconContextTabsDataProps;

const NameplateIcons = (props: INameplateIconsProps) => {
  const { isAvailable } = useFeature();
  const isRecalculateButtonVisible = useMemo(() => {
    return AuthorizationService.isAuthorized(props.user.data, [
      UserRoles.Administrator,
      UserRoles.Engineer,
      UserRoles.LimitedEngineer
    ]);
  }, [props.user.data]);

  return (
    <div className="nameplate-icons">
      <div className="nameplate-icons-group">
        {isRecalculateButtonVisible && (
          <RecalculateButton
            modelId={props.modelId}
            assetId={props.assetId}
            tooltipPosition={TooltipPosition.BottomRight}
            type="link"
          />
        )}
        {isAvailable(VisualizationKeys.Configuration_Tool_Manage_Assets) &&
          props.assetType === "Transformer" && (
            <AuthorizationContainer roles={[UserRoles.Administrator]}>
              <EditAssetButton assetId={props.assetId} />
            </AuthorizationContainer>
          )}
      </div>
      <div className="nameplate-icons-group">
        <ExportToExcelIcon
          exportUrl={config.api.detailPage.assetDetailsExcelExportUrl}
          assetId={props.assetId}
          templateName={`${props.assetId}_nameplate`}
        />
        <NameplateIcon />
        <ReportsContainer
          Header={() => <ReportsHeader />}
          showDropdownChevron={false}
          emptyReportsTranslationId="asset_detail_page.external_links.no_reports"
          emptyReportsDefaultText="No external links"
          assetId={props.assetId}
          reportsMode={ReportsModes.ExternalReports}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (
  state: IState
): INameplateIconContextTabsDataProps => ({
  user: getUserSelector(state)
});

const NameplateIconsContainer = connect(mapStateToProps)(NameplateIcons);
export default NameplateIconsContainer;
