// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useSimulationModal = (setModalVisible: (value: boolean) => void) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const searchParams = new SearchParams(location.search);
    if (searchParams.get("simulationId")) {
      setModalVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleCancel = useCallback(() => {
    setModalVisible(false);
    const searchParams = new SearchParams(history.location.search);
    searchParams.delete("simulationId");
    history.replace({
      search: searchParams.toString()
    });
  }, [history, setModalVisible]);

  return {
    handleCancel
  };
};

export default useSimulationModal;
