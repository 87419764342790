// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import moment, { Moment } from "moment";
import { IntlShape } from "react-intl";

export const disableFutureDates = (current: Moment) => {
  return current && current >= moment().endOf("day");
};

export const dateFormat = (intl: IntlShape) => (value: Moment) => {
  return `${intl.formatDate(value.toDate(), {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  })}`;
};
