// Copyright 2016-2021 Hitachi Power Grids. All rights reserved.
import ConfigurationGrid, {
  IConfigurationGridProps
} from "@apm/widgets/build/widgets/ConfigurationGrid/components/ConfigurationGrid";
import { PropsWithChildren } from "react";
import { useIntl } from "react-intl";

export interface ICustomSectionGridProps<T extends object> {
  gridConfig: IConfigurationGridProps<T>;
}

export const CustomSectionGrid = <T extends object>({
  gridConfig
}: PropsWithChildren<ICustomSectionGridProps<T>>) => {
  const {
    columns,
    data,
    configurableButtons,
    onRowDelete,
    onCellChanged,
    highlightAfterUpdatedRows,
    dataQa = ""
  } = gridConfig;

  const { formatMessage } = useIntl();

  return (
    <ConfigurationGrid<T>
      columns={columns}
      data={data}
      gridHeader={formatMessage({
        id: "configuration_tool.tab.connected_devices_parameter_mapping",
        defaultMessage: "Parameter mapping"
      })}
      gridHeight="340px"
      gridWidth="100%"
      configurableButtons={configurableButtons}
      onRowDelete={onRowDelete}
      onCellChanged={onCellChanged}
      highlightAfterUpdatedRows={highlightAfterUpdatedRows}
      dataQa={dataQa}
      noRowsMessage={formatMessage({
        id: "data_grid.no_data_found",
        defaultMessage: "No data available"
      })}
    />
  );
};
