// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Typography } from "antd";
import ParameterName from "common/formatters/ParameterName";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  fontSizeSmall,
  spacingBase,
  spacingLarge,
  spacingXLarge,
  spacingXSmall
} from "styles/StyleVariables";
import { prognosisColorsPalette } from "../../../utils/colors";
const { Text } = Typography;

interface ISimulationStaticLegendProps {
  className?: string;
  modelId: string;
  implementationId: string;
  parameterName: string;
  defaultBeginValue: number;
  defaultEndValue: number;
  maxValue: number;
  minValue: number;
  unit: string;
}

const SimulationStaticLegend = ({
  className,
  modelId,
  implementationId,
  parameterName,
  minValue,
  maxValue,
  defaultBeginValue,
  defaultEndValue,
  unit
}: ISimulationStaticLegendProps) => {
  return (
    <div className={className}>
      <div data-qa="simulation-legend-name">
        <Text strong>
          <ParameterName
            nameId={parameterName}
            modelId={modelId}
            implementationId={implementationId}
          />
        </Text>
        :
      </div>
      <div className="simulation-legend-box" data-qa="legend-operating">
        <div className="simulation-legend-box-item operating">
          <svg viewBox="0 0 36 3">
            <line
              x1="0"
              y1="1"
              x2="36"
              y2="1"
              stroke={prognosisColorsPalette[0]}
            />
          </svg>
        </div>
        <Text>
          <FormattedMessage
            id="detail_page.risk_trend.simulation_modal.legend.operating"
            defaultMessage="{value} (operating)"
            values={{
              value: (
                <Text strong>
                  <FormattedMessage
                    id="detail_page.risk_trend.simulation_modal.legend.min_max_unit"
                    defaultMessage="{min} - {max} {unit}"
                    values={{
                      min: defaultBeginValue,
                      max: defaultEndValue,
                      unit: unit
                    }}
                  />
                </Text>
              )
            }}
          />
        </Text>
      </div>
      <div className="simulation-legend-box" data-qa="legend-simulation">
        <div className="simulation-legend-box-item simulation">
          <svg viewBox="0 0 36 3">
            <line
              x1="0"
              y1="1"
              x2="36"
              y2="1"
              stroke={prognosisColorsPalette[0]}
            />
          </svg>
        </div>
        <Text>
          <FormattedMessage
            id="detail_page.risk_trend.simulation_modal.legend.simulation"
            defaultMessage="{value} (simulation)"
            values={{
              value: (
                <Text strong>
                  <FormattedMessage
                    id="detail_page.risk_trend.simulation_modal.legend.min_max_unit"
                    defaultMessage="{min} - {max} {unit}"
                    values={{ min: minValue, max: maxValue, unit: unit }}
                  />
                </Text>
              )
            }}
          />
        </Text>
      </div>
    </div>
  );
};

const StyledSimulationStaticLegend = styled(SimulationStaticLegend)`
  padding-top: ${spacingXLarge};
  padding-left: ${spacingLarge};
  font-size: ${fontSizeSmall};
  display: flex;
  align-items: center;

  .simulation-legend-box {
    display: flex;
    align-items: center;
    padding-left: ${spacingLarge};
    padding-right: ${spacingLarge};

    .simulation-legend-box-item {
      display: flex;
      margin-right: ${spacingBase};
      svg {
        width: 36px;
        vertical-align: middle;
        line {
          stroke-width: ${spacingXSmall};
        }
      }

      &.operating {
        line {
          stroke-dasharray: 2, 2;
        }
      }

      &.simulation {
        line {
          stroke-dasharray: 7, 7;
        }
      }
    }
  }
`;

export default StyledSimulationStaticLegend;
