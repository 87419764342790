// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import { IScatterPlotData } from "../reducers/FamilyAnalyticsReducer";
import { IRowData, IRow, IColumnConfig } from "common/datagrid/DataGrid";
import { routesConfig } from "core/app/components/router/RouterProvider";
import { useHistory } from "react-router";
import { useCallback } from "react";
import { IDataPoint } from "../components/ScatterPlot";

const useFamilyContents = (
  scatterPlotXData: IScatterPlotData,
  scatterPlotYData: IScatterPlotData
) => {
  const history = useHistory();

  let dataPoints: IDataPoint[];
  if (
    scatterPlotXData.dataPoints.data !== null &&
    scatterPlotYData.dataPoints.data === null
  ) {
    dataPoints = filterDataPoints(scatterPlotXData);
  } else if (
    scatterPlotXData.dataPoints.data === null &&
    scatterPlotYData.dataPoints.data !== null
  ) {
    dataPoints = filterDataPoints(scatterPlotYData);
  } else if (
    scatterPlotXData.dataPoints.data !== null &&
    scatterPlotYData.dataPoints.data !== null
  ) {
    dataPoints = filterCommonDataPoints(scatterPlotYData, scatterPlotXData);
  } else {
    dataPoints = [];
  }

  const data: IRowData[] = [];
  dataPoints.map((p) => data.push({ AssetId: p.AssetId }));

  const handleRowClick = useCallback(
    (row: IRow) => {
      const searchParams = new SearchParams({ assetId: row.data["AssetId"] });

      history.push({
        pathname: routesConfig.detailPage,
        search: searchParams.toString()
      });
    },
    [history]
  );

  const columns: IColumnConfig[] = [
    {
      frozen: true,
      id: "AssetId",
      message: {
        defaultMessage: "Asset ID",
        id: "dashboard.filters.asset_id_label"
      },
      weight: 0
    }
  ];

  return {
    columns,
    dataPoints,
    data,
    handleRowClick
  };
};

const filterDataPoints = (dataPoints: IScatterPlotData): IDataPoint[] => {
  return dataPoints.dataPoints.data.DataPoints.filter(
    (d) => d.Date != null && d.Value != null
  );
};

const filterCommonDataPoints = (
  pointsY: IScatterPlotData,
  pointsX: IScatterPlotData
): IDataPoint[] => {
  const mergedPoints = pointsY.dataPoints.data.DataPoints.map((yPoint) => {
    const haveEqualAssetId = (xPoint: IDataPoint) =>
      xPoint.AssetId === yPoint.AssetId;
    const xPoint = pointsX.dataPoints.data.DataPoints.find(haveEqualAssetId);
    return {
      AssetId: yPoint.AssetId,
      valueX: xPoint ? +xPoint.Value : null,
      valueY: yPoint ? +yPoint.Value : null,
      Date: "",
      Risk: yPoint ? yPoint.Risk ?? "" : "",
      Value: xPoint ? +xPoint.Value : +yPoint.Value
    };
  });
  return mergedPoints.filter((p) => p.valueX != null && p.valueY != null);
};
export default useFamilyContents;
