// Copyright 2016-2022 Hitachi Energy. All rights reserved.
const typical = [
  "high_constant",
  "high_peak",
  "low_constant",
  "low_peak",
  "medium_constant",
  "medium_peak",
  "unknown"
];

export default typical;
