// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { connect } from "react-redux";

import { loadTranslations } from "core/app/actions/TranslationsActions";
import TranslationsProvider, {
  ITranslationsProviderActions,
  ITranslationsProviderData
} from "core/app/components/TranslationsProvider";
import { IState } from "reducers/Index";
import { Action, Dispatch } from "redux";

function mapStateToProps(state: IState): ITranslationsProviderData {
  return {
    locale: state.app.location.queryParams.locale
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<Action>
): ITranslationsProviderActions {
  return {
    loadTranslations: (locale: string) => {
      loadTranslations(dispatch, locale);
    }
  };
}

const TranslationsProviderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationsProvider);

export default TranslationsProviderContainer;
