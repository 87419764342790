// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useSelector } from "react-redux";

import getScoreCalucationSelector from "features/detailpage/features/replacementscore/selectors/getScoreCalculationSelector";

const useReplacementScoreReducer = () => {
  const scoreCalculation = useSelector(getScoreCalucationSelector);

  return {
    scoreCalculation
  };
};

export default useReplacementScoreReducer;
