// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";

import SubscoreName from "common/formatters/SubscoreName";
import { FormattedMessage } from "react-intl";

interface INameColumnProps {
  nameId: string;
  modelId: string;
  implementationId: string;
  paddingLeft: number;
  hasChild: boolean;
}

const NameColumn = ({
  nameId,
  modelId,
  implementationId,
  hasChild,
  paddingLeft
}: INameColumnProps) => (
  <div
    className={`grid-cell name ${hasChild ? "parent" : ""}`}
    style={{ paddingLeft }}
  >
    {nameId === "Score" ? (
      <FormattedMessage
        id="detail_page.widgets.subfactors_grid.score"
        defaultMessage="Score"
      />
    ) : (
      <SubscoreName nameId={nameId} modelId={modelId} implementationId={implementationId}/>
    )}
  </div>
);

export default NameColumn;
