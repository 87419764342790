// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import TileErrorMessage from "common/tile/TileErrorMessage";
import Processing from "components/common/Processing";
import Data, { Statuses } from "core/data/models/Data";
import RogersRatioChartWrapper from "features/detailpage/features/rogersratio/components/RogersRatioChartWrapper";
import RogersRatioDataSourceSwitch from "features/detailpage/features/rogersratio/components/RogersRatioDataSourceSwitch";
import { RogersRatioDataFetched } from "features/detailpage/features/rogersratio/hooks/useProcessRogersRatioData";
import useBoolean from "hooks/useBoolean";
import { isEqual } from "lodash";
import * as React from "react";

type RogersRatioProps = {
  rogersRatioData: Data<RogersRatioDataFetched>;
};

const RogersRatio: React.FC<RogersRatioProps> = ({ rogersRatioData }) => {
  const { data, status } = rogersRatioData;
  const { Loading, Succeeded, Failed } = Statuses;

  const {
    value: showOfflineOnly,
    toggle: toggleShowOfflineOnly,
    setValue: setShowOfflineOnly
  } = useBoolean(true);

  const toggleData = () => {
    toggleShowOfflineOnly();
  };

  return (
    <div className="rogers-ratio__wrapper">
      <RogersRatioDataSourceSwitch toggleShowOfflineOnly={toggleData} />
      {isEqual(status, Loading) ? (
        <Processing className="spinner small dark" />
      ) : isEqual(status, Succeeded) ? (
        <RogersRatioChartWrapper
          rogersRatioData={data}
          showOfflineOnly={showOfflineOnly}
          setShowOfflineOnly={setShowOfflineOnly}
        />
      ) : isEqual(status, Failed) ? (
        <TileErrorMessage
          messageId="global.server_error"
          defaultMessage="Sorry, we've got problems with server connection."
        />
      ) : (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      )}
    </div>
  );
};

export default RogersRatio;
