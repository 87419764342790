// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import RouteManager from "core/app/components/router/RouteManager";
import RouteNotFound from "core/app/components/router/RouteNotFound";
import { getUserSelector } from "core/app/reducers/AppReducer";
import { IRawQueryParams } from "core/app/reducers/LocationReducer";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";
import Alarms from "features/Alarms";
import AssetQuery from "features/AssetQuery";
import AssetRiskChanges from "features/AssetRiskChanges";
import AssetRiskDashboard from "features/assetriskdashboard/components/AssetRiskDashboard";
import AssetRiskSummary from "features/AssetRiskSummary";
import DataIntegration from "features/dataintegration/DataIntegration";
import DetailPage from "features/detailpage/components/DetailPage";
import FamilyAnalytics from "features/familyanalytics/components/FamilyAnalytics";
import Issues from "features/issues/components/Issues";
import Licenses from "features/Licenses";
import LicensesFile from "features/LicensesFile";
import Locations from "features/Locations";
import MainDashboard from "features/MainDashboard";
import NotificationsContainer from "features/notifications/containers/NotificationsContainer";
import Reports from "features/Reports";
import RiskMapDashboard from "features/RiskMapDashboard";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { UserRoles } from "../auth/Authorization";
import { useFeature, VisualizationKeys } from "../FeatureToggle";

interface IContentProps {
  switch: JSX.Element;
}

export interface IRouterProviderActions {
  setQueryParams: (rawQueryParams: IRawQueryParams) => void;
}

export interface IRouterProviderData {
  experimental: boolean;
}

export interface IRouterProviderProps
  extends IRouterProviderActions,
    IRouterProviderData {
  Content: (props: IContentProps) => JSX.Element;
}

export const routesConfig = {
  dashboard: "/Dashboard",
  dashboards: {
    mainDashboard: "/Dashboard/MainDashboard",
    assetRiskSummary: "/Dashboard/AssetRiskSummary",
    assetRiskChanges: "/Dashboard/AssetRiskChanges",
    assetRiskDashboard: "/Dashboard/AssetRiskDashboard",
    spatialView: "/Dashboard/SpatialView"
  },
  assets: "/Assets",
  detailPage: "/Assets/DetailPage",
  detailPageDeprecated: "/Assets/:assetId",
  locations: "/Locations",
  alarms: "/Alarms",
  issues: "/Issues",
  familyAnalytics: "/FamilyAnalytics",
  modelAdmin: "/ModelAdmin",
  notifications: "/Notifications",
  licenses: "/Licenses",
  licensesFile: "/Licenses/:licenseType/:fileName",
  reportsWithName: "/Reports/:reportName"
};

const RouterProvider = ({ Content, setQueryParams }: IRouterProviderProps) => {
  const user = useSelector(getUserSelector);

  const userHasAccess = useMemo(
    () =>
      AuthorizationService.isAuthorized(user.data as IUser, [
        UserRoles.Administrator,
        UserRoles.Engineer,
        UserRoles.LimitedEngineer,
        UserRoles.ViewerEngineer
      ]),
    [user.data]
  );

  const userHasAccessToDataIntegration = useMemo(
    () =>
      AuthorizationService.isAuthorized(user.data as IUser, [
        UserRoles.Administrator,
        UserRoles.Engineer
      ]),
    [user.data]
  );

  const defaultRoute = useMemo(
    () => getDefaultRoute(userHasAccess),
    [userHasAccess]
  );

  const { isAvailable } = useFeature();

  return (
    <HashRouter>
      <RouteManager setQueryParams={setQueryParams}>
        <Content
          switch={
            <Switch>
              <Route
                exact
                path={routesConfig.dashboards.mainDashboard}
                component={userHasAccess ? MainDashboard : RouteNotFound}
              />
              <Route
                exact
                path={routesConfig.dashboards.spatialView}
                component={userHasAccess ? RiskMapDashboard : RouteNotFound}
              />
              <Route
                exact
                path={routesConfig.dashboards.assetRiskChanges}
                component={userHasAccess ? AssetRiskChanges : RouteNotFound}
              />
              <Route
                exact
                path={routesConfig.dashboards.assetRiskDashboard}
                component={userHasAccess ? AssetRiskDashboard : RouteNotFound}
              />
              <Route
                exact
                path={routesConfig.dashboards.assetRiskSummary}
                component={userHasAccess ? AssetRiskSummary : RouteNotFound}
              />
              <Route
                exact
                path={routesConfig.notifications}
                component={NotificationsContainer}
              />
              <Route
                exact
                path={routesConfig.modelAdmin}
                component={
                  userHasAccessToDataIntegration
                    ? DataIntegration
                    : RouteNotFound
                }
              />
              <Route path={routesConfig.detailPage} component={DetailPage} />
              <Route exact path={routesConfig.assets} component={AssetQuery} />
              <Route
                exact
                path={routesConfig.locations}
                component={Locations}
              />
              {isAvailable(VisualizationKeys.AlarmsPage_Tab) && (
                <Route exact path={routesConfig.alarms} component={Alarms} />
              )}
              <Route exact path={routesConfig.issues} component={Issues} />
              <Route
                exact
                path={routesConfig.familyAnalytics}
                component={FamilyAnalytics}
              />
              <Route
                exact
                path={routesConfig.reportsWithName}
                component={Reports}
              />
              <Route exact path={routesConfig.licenses} component={Licenses} />
              <Route
                exact
                path={routesConfig.licensesFile}
                component={LicensesFile}
              />
              <Redirect exact path="/" to={defaultRoute} />
              <Redirect
                exact
                path={routesConfig.detailPageDeprecated}
                to={`${routesConfig.detailPage}?assetId=:assetId`}
              />
              <Route component={RouteNotFound} />
            </Switch>
          }
        />
      </RouteManager>
    </HashRouter>
  );
};

const getDefaultRoute = (userHasAccess: boolean) => {
  if (userHasAccess) return routesConfig.dashboards.mainDashboard;

  return routesConfig.assets;
};

export default RouterProvider;
