// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { IState } from "reducers/Index";
import IssueStatusSelect, {
  IIssueStatusSelectOwnProps,
  IIssueStatusSelectData
} from "features/detailpage/features/issues/components/IssueStatusSelect";

export function mapStateToProps(state: IState): IIssueStatusSelectData {
  return {
    statuses: state.detailPage.issues.statuses.data
  };
}

export function mapDispatchToProps(
  dispatch: Dispatch<Action>,
  ownProps: IIssueStatusSelectOwnProps
): {} {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueStatusSelect);
