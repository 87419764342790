// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import DataGrid, { IRow, IRowData } from "common/datagrid/DataGrid";
import styled from "styled-components";
import { spacingXXLarge } from "styles/StyleVariables";
import useLocationsAssetGrid from "../hooks/useLocationsAssetGrid";
import AssetRisks from "./AssetRisks";
import ViewInAssets from "./ViewInAssets";

interface IAssetsGridProps {
  className?: string;
  row: IRow<IRowData>;
}

const AssetsGrid = ({ className, row }: IAssetsGridProps) => {
  const { dataEndpoint, columns } = useLocationsAssetGrid({
    organization: row.data["Organization"],
    region: row.data["Region"],
    location: row.data["Location"]
  });
  return (
    <div className={className}>
      <div className="asset-risk-container">
        <AssetRisks row={row} />
      </div>
      <div className="data-grid-container">
        <div className="data-grid__parent">
          <div className="data-grid__scroll">
            <DataGrid columns={columns} dataEndpoint={dataEndpoint} />
          </div>
        </div>
      </div>
      <ViewInAssets
        organization={row.data["Organization"]}
        region={row.data["Region"]}
        location={row.data["Location"]}
      />
    </div>
  );
};

const defaultGridPadding = "10px";

export default styled(AssetsGrid)`
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;

  .asset-risk-container {
    margin: ${spacingXXLarge} 0;
  }

  .data-grid-container {
    height: 100%;
    position: relative;

    .data-grid__parent {
      overflow: hidden;
      position: absolute;
      top: 0;
      border: 0;
      left: 0;
      right: 0;
      height: 100%;
    }

    .column.asset {
      padding: ${defaultGridPadding};
    }
  }
`;
