// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import IKeyRiskChangesInfo from "../models/IKeyRiskChangesInfo";

export default class KeyRiskChangesService {
  public static filterIncreasedItemsData(data: IKeyRiskChangesInfo[]) {
    return data.filter((x) => x.DegradationScoreChange > 0);
  }

  public static filterDecreasedItemsData(data: IKeyRiskChangesInfo[]) {
    return data.filter((x) => x.DegradationScoreChange < 0);
  }
}
