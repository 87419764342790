// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";

import { dataKey } from "../actions/loadDielectricMoisture";
import IDielectricData from "../../../models/IDielectricData";

const getDielectricMoistureSelector = createSelector<
  IState,
  Data<IDielectricData>,
  Data<IDielectricData>
>(
  (state: IState) => state.data[dataKey],
  (moisture) => moisture || new Data()
);

export default getDielectricMoistureSelector;
