// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React, { useMemo } from "react";

import { IRow } from "common/datagrid/DataGrid";
import isClickable from "../utils/isClickable";
import IParameterInfo from "../../../models/IParameterInfo";

const ColumnUnit = (unit: string, row: IRow<IParameterInfo>) => {
  const clickable = useMemo(() => isClickable(row), [row]);

  return (
    <div className={`column unit ${clickable ? "" : "non-clickable"}`}>
      {unit}
    </div>
  );
};

export default ColumnUnit;
