// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data from "core/data/models/Data";
import update from "immutability-helper";
import { isNil } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import useParametersTranslations from "../../../hooks/useParametersTranslations";
import IThresholdCorrection from "../../../models/IThresholdCorrection";
import IWearData from "../models/IWearData";
import WearParametersMapper from "../services/WearParametersMapper";

const useContactWearParameters = (
  assetId: string,
  wear: Data<IWearData> | undefined,
  adjustExpectedRange: (
    intl: IntlShape,
    parameterName: string,
    success?: (correction: IThresholdCorrection) => void,
    error?: () => void
  ) => void,
  acknowledge: (
    intl: IntlShape,
    parameterName: string,
    success?: (correction: IThresholdCorrection) => void,
    error?: () => void
  ) => void,
  loadWear: () => void,
  removeWear: () => void
) => {
  const intl = useIntl();
  const [processing, setProcessing] = useState(false);
  const { translations } = useParametersTranslations();
  const [thresholdsCorrections, setThresholdsCorrections] = useState<
    {
      parameterName: string;
      correction: IThresholdCorrection;
    }[]
  >([]);

  const wearStatus = useMemo(() => wear?.status, [wear?.status]);

  const wearData = useMemo(() => {
    if (isNil(wear?.data)) return undefined;
    if (!thresholdsCorrections.length) return wear.data;

    const updates: {
      parameterId: number;
      valueId: number;
      correction: IThresholdCorrection;
    }[] = [];

    wear.data.Parameters?.forEach((parameter, i) => {
      parameter.Values?.forEach((value, j) => {
        const k = thresholdsCorrections.findIndex(
          (c) => c.parameterName === value?.ExternalId
        );

        if (k >= 0) {
          updates.push({
            parameterId: i,
            valueId: j,
            correction: thresholdsCorrections[k].correction
          });
        }
      });
    });

    let newValue = wear.data;
    updates.forEach((u) => {
      newValue = update(newValue, {
        Parameters: {
          [u.parameterId]: {
            Values: {
              [u.valueId]: {
                UserThresholdCorrection: { $set: u.correction }
              }
            }
          }
        }
      });
    });

    return newValue;
  }, [thresholdsCorrections, wear?.data]);

  const wearMapped = useMemo(
    () => (wearData ? new WearParametersMapper(intl).map(wearData) : undefined),
    [intl, wearData]
  );

  const formatValue = (value: number | string | null) => {
    if (typeof value === "number") {
      return intl.formatNumber(value, { maximumFractionDigits: 1 });
    } else if (value === null || value === undefined) {
      return "-";
    }

    return value;
  };

  const updateThresholdsCorrections = useCallback(
    (parameterName: string, correction: IThresholdCorrection) => {
      const i = thresholdsCorrections.findIndex(
        (c) => c.parameterName === parameterName
      );
      if (i < 0) {
        const newValue = update(thresholdsCorrections, {
          $push: [
            {
              parameterName,
              correction
            }
          ]
        });

        setThresholdsCorrections(newValue);
      } else {
        const newValue = update(thresholdsCorrections, {
          [i]: { correction: { $set: correction } }
        });

        setThresholdsCorrections(newValue);
      }
    },
    [thresholdsCorrections]
  );

  const handleAdjustExpectedRangeClick = useCallback(
    (parameterName: string, success?: () => void, error?: () => void) => {
      setProcessing(true);
      adjustExpectedRange(
        intl,
        parameterName,
        (correction: IThresholdCorrection) => {
          updateThresholdsCorrections(parameterName, correction);
          setProcessing(false);
          if (success) success();
        },
        () => {
          setProcessing(false);
          if (error) error();
        }
      );
    },
    [adjustExpectedRange, intl, updateThresholdsCorrections]
  );

  const handleAcknowledgeClick = useCallback(
    (parameterName: string, success?: () => void, error?: () => void) => {
      setProcessing(true);
      acknowledge(
        intl,
        parameterName,
        (correction: IThresholdCorrection) => {
          updateThresholdsCorrections(parameterName, correction);
          setProcessing(false);
          if (success) success();
        },
        () => {
          setProcessing(false);
          if (error) error();
        }
      );
    },
    [acknowledge, intl, updateThresholdsCorrections]
  );

  useEffect(() => {
    loadWear();
    setThresholdsCorrections([]);
    return () => {
      removeWear();
    };
  }, [assetId, loadWear, removeWear]);

  return {
    wearStatus,
    wearData,
    wearMapped,
    formatValue,
    handleAdjustExpectedRangeClick,
    handleAcknowledgeClick,
    processing,
    translations
  };
};

export default useContactWearParameters;
