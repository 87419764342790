// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import ISubfactorScore from "features/detailpage/features/subfactorsgrid/models/ISubfactorScore";
import { isEmpty } from "lodash";
import * as React from "react";
import ConfidenceColumn from "./columns/ConfidenceColumn";
import CurrentValueColumn from "./columns/CurrentValueColumn";
import NameColumn from "./columns/NameColumn";
import ValueColumn from "./columns/ValueColumn";

interface ISubfactorsGridBodyProps {
  subfactors: Array<ISubfactorScore>;
  modelId: string;
  implementationId: string;
}

interface IGroupedRowProps {
  subfactorScore: ISubfactorScore;
  modelId: string;
  implementationId: string;
  paddingLeft: number;
}

interface IRowProps extends IGroupedRowProps {
  hasChild: boolean;
}

const Row = ({
  subfactorScore,
  modelId,
  implementationId,
  hasChild,
  paddingLeft
}: IRowProps) => (
  <div className="grid-row">
    <NameColumn
      nameId={subfactorScore.Name}
      modelId={modelId}
      implementationId={implementationId}
      paddingLeft={paddingLeft}
      hasChild={hasChild}
    />
    <ValueColumn value={subfactorScore.PreviousValue} />
    <CurrentValueColumn
      value={subfactorScore.CurrentValue}
      change={subfactorScore.Change}
    />
    <ConfidenceColumn value={subfactorScore.Confidence} />
  </div>
);

const GroupedRows = ({
  subfactorScore,
  modelId,
  implementationId,
  paddingLeft
}: IGroupedRowProps): JSX.Element => {
  const hasChild = !isEmpty(subfactorScore.SubFactors);
  return (
    <>
      <Row
        subfactorScore={subfactorScore}
        key={subfactorScore.Name}
        modelId={modelId}
        implementationId={implementationId}
        paddingLeft={paddingLeft}
        hasChild={hasChild}
      />
      {hasChild && (
        <>
          {subfactorScore.SubFactors.map((s) => (
            <GroupedRows
              subfactorScore={s}
              modelId={modelId}
              implementationId={implementationId}
              key={s.Name}
              paddingLeft={paddingLeft + 15}
            />
          ))}
        </>
      )}
    </>
  );
};

const Rows = ({
  subfactors,
  modelId,
  implementationId
}: ISubfactorsGridBodyProps): JSX.Element => (
  <>
    {subfactors.map((s) => (
      <GroupedRows
        subfactorScore={s}
        key={s.Name}
        modelId={modelId}
        implementationId={implementationId}
        paddingLeft={15}
      />
    ))}
  </>
);

const SubfactorsGridBody = ({
  subfactors,
  modelId,
  implementationId
}: ISubfactorsGridBodyProps) => (
  <div className="grid-body">
    <Rows
      subfactors={subfactors}
      modelId={modelId}
      implementationId={implementationId}
    />
  </div>
);

export default SubfactorsGridBody;
