// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React, { memo } from "react";
import { useHistory, useLocation } from "react-router";
import { History } from "history";

import PbiReport from "common/powerbi/PbiReport";

import usePbiReportTab from "../hooks/usePbiReport";
import { WidgetMessage } from "components/common/widget/Widget";

interface IPbiReportTabRouteProps {
  search: string;
  history: History;
}

const PbiReportTab = memo(({ search, history }: IPbiReportTabRouteProps) => {
  const { reportName, filters } = usePbiReportTab(search, history);
  return reportName ? (
    <div className="pbiembed-wrapper">
      <PbiReport reportName={reportName} filters={filters} />
    </div>
  ) : (
    <div className="select-report-message">
      <WidgetMessage
        iconName="info"
        iconClassName="select-report"
        messageId="detail_page.widgets.reports.select_report"
        messageDefault="Please select report to display"
      />
    </div>
  );
});

export default () => {
  const { search } = useLocation();
  const history = useHistory();
  return <PbiReportTab search={search} history={history} />;
};
