// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import getOpenIssuesSelector from "../selectors/getOpenIssuesSelector";
import { loadOpenIssuesInfo } from "../actions/OpenIssuesActions";

const useOpenIssues = () => {
  const openIssues = useSelector(getOpenIssuesSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    loadOpenIssuesInfo(dispatch);
  }, [dispatch]);

  return { openIssues };
};

export default useOpenIssues;
