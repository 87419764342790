// Copyright 2016-2022 Hitachi Energy. All rights reserved.
// https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
export enum ReadyStates {
  Unset = 0,
  Opened = 1,
  HeadersReceived = 2,
  Loading = 3,
  Done = 4
}

export enum Statuses {
  Loading,
  Succeeded,
  Unauthorized,
  Forbidden,
  Failed,
  NotFound,
  Aborted
}

export interface IRequest {
  readyState: number;
  status: number;
  statusText: string;
  responseText: string;
  responseJSON: string;
}

export default class Data<T> {
  static mapHttpStatusToStatus(
    httpStatus: number,
    statusText?: string
  ): Statuses | null {
    if (httpStatus === 0 && statusText === "abort") return Statuses.Aborted;
    if (httpStatus === 200) return Statuses.Succeeded;
    if (httpStatus === 400) return Statuses.Failed;
    if (httpStatus === 401) return Statuses.Unauthorized;
    if (httpStatus === 403) return Statuses.Forbidden;
    if (httpStatus === 404) return Statuses.NotFound;
    if (httpStatus === 500) return Statuses.Failed;
    if (httpStatus === 502) return Statuses.Failed;
    return null;
  }

  constructor(request?: JQuery.jqXHR | IRequest | null, data?: T | null) {
    if (request) {
      this._readyState = request.readyState;
      this._status = request.status;
      this._statusText = request.statusText;
      this._responseText = request.responseText;
      this._responseJSON = request.responseJSON;

      if (Data.isJqXHR(request)) {
        this._abort = request.abort;
      }
    }

    this._data = data || null;
  }

  setRequest(request?: JQuery.jqXHR | null): Data<T> {
    return new Data(request, this._data);
  }

  setData(data?: T | null): Data<T> {
    return new Data(
      {
        readyState: this._readyState,
        status: this._status,
        statusText: this._statusText,
        responseText: this._responseText,
        responseJSON: this._responseJSON
      },
      data
    );
  }

  abort() {
    if (this._abort && this.status === Statuses.Loading) this._abort();
  }

  private _data: T | null;
  get data(): T | null {
    return this._data;
  }

  private _readyState: number;
  private _status: number;
  private _statusText: string;
  private _responseText: string;
  private _responseJSON: string;
  private _abort: () => void;

  get status(): Statuses | null {
    if (this._readyState === undefined || this._readyState == null) return null;

    if (this._readyState !== ReadyStates.Done) {
      return Statuses.Loading;
    } else {
      return Data.mapHttpStatusToStatus(this._status);
    }
  }

  get message(): string | null {
    return this._statusText !== undefined && this._statusText != null
      ? this._statusText
      : null;
  }

  get responseText(): string | null {
    return this._responseText !== undefined && this._responseText != null
      ? this._responseText
      : null;
  }

  get responseJSON(): string | null {
    return this._responseJSON !== undefined && this._responseJSON != null
      ? this._responseJSON
      : null;
  }

  private static isJqXHR(arg: any): arg is JQuery.jqXHR {
    return arg.abort !== undefined;
  }
}
