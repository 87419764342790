// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "thunk";

import { IAjaxAction } from "reducers/Index";
import { IHistorySwitchingOperationsData } from "features/detailpage/features/ltc/models/IHistorySwitichingOperations";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import EndpointService from "core/data/services/EndpointService";

export enum Actions {
  DetailPage_Ltc_SetHistorySwitchingOperations = "DetailPage_Ltc_SetHistorySwitchingOperations"
}

export const setSwitchingHistoryOperationsAction = (
  request: JQuery.jqXHR,
  data?: IHistorySwitchingOperationsData
): IAjaxAction<IHistorySwitchingOperationsData> => ({
  request,
  data,
  type: Actions.DetailPage_Ltc_SetHistorySwitchingOperations
});

export const loadHistorySwitchingOperationsAction = (
  assetId: string
): ThunkAction<Promise<void>, Action> => (dispatch: ThunkDispatch<Action>) =>
  fetchSwitchingHistoryOperations(dispatch, assetId);

function fetchSwitchingHistoryOperations(
  dispatch: ThunkDispatch<Action>,
  assetId: string
): Promise<void> {
  return new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.ltcSwitchOperationsHistoryUrl,
      [
        {
          name: "assetId",
          value: assetId
        }
      ]
    );
    const request = EndpointService.getJson<IHistorySwitchingOperationsData>(
      url,
      (request, data) => {
        dispatch(setSwitchingHistoryOperationsAction(request, data));
        resolve();
      },
      (request) => {
        dispatch(setSwitchingHistoryOperationsAction(request));
        reject();
      }
    );

    dispatch(setSwitchingHistoryOperationsAction(request));
  });
}
