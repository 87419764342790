// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import ParameterChartsWidget from "@apm/widgets/build/widgets/ParameterCharts";
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { ThunkDispatch } from "thunk";
import loadSOTData from "../actions/loadSOTData";
import removeSOTData from "../actions/removeSOTData";
import useSOT from "../hooks/useSOT";
import ISOTDataModel from "../models/ISOTData";
import getSOTDataSelector from "../selectors/getSOTDataSelector";
import getLegend from "../utils/getLegend";

interface ISOTActions {
  loadSOTData: (assetId: string) => void;
  removeSOTData: (assetId: string) => void;
}

interface ISOTData {
  sotData: Data<ISOTDataModel>;
}

interface ISOTOwnProps {
  assetId: string;
  dataSources: string[];
  defaultDataSource?: string;
}

interface ISOTProps extends ISOTActions, ISOTData, ISOTOwnProps {}

const SOT = ({
  assetId,
  sotData,
  dataSources,
  defaultDataSource,
  loadSOTData,
  removeSOTData
}: ISOTProps) => {
  const intl = useIntl();

  const { oils } = useSOT(
    assetId,
    loadSOTData,
    removeSOTData,
    sotData?.data?.Charts
  );

  const dataSourceConfig = useMemo(
    () => ({
      dataSources: dataSources,
      defaultDataSource: defaultDataSource
    }),
    [dataSources, defaultDataSource]
  );

  return (
    <>
      {sotData?.status === Statuses.Loading && <Processing />}
      {sotData?.status === Statuses.Succeeded && (
        <ParameterChartsWidget
          chartGroups={[
            {
              name: "oils",
              charts: oils
            }
          ]}
          dataSourceConfig={dataSourceConfig}
          getLegend={getLegend(sotData.data.OilStandard, intl)}
          formatTooltipTitle={(date) =>
            intl.formatDate(date, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric"
            })
          }
          formatXTick={(date) => intl.formatDate(date)}
          translations={{
            series: (seriesName) =>
              intl.formatMessage({
                defaultMessage: seriesName,
                id: `detail_page.widgets.analytics.transformers.sot.${seriesName}`
              }),
            empty: intl.formatMessage({
              defaultMessage: "No data available",
              id: "global.chart.no_data_available"
            })
          }}
        />
      )}
      {sotData?.status === Statuses.NotFound && (
        <div>
          <WidgetNoDataMessage />
        </div>
      )}
      {sotData?.status === Statuses.Failed && (
        <div>
          <WidgetErrorMessage
            messageId="global.empty"
            messageDefault={sotData?.message}
          />
        </div>
      )}
    </>
  );
};

export default connect(
  (state: IState, ownProps: ISOTOwnProps): ISOTData => ({
    sotData: getSOTDataSelector(state)(ownProps.assetId)
  }),
  (dispatch: ThunkDispatch<any>): ISOTActions => ({
    loadSOTData: (assetId: string) => dispatch(loadSOTData(assetId)),
    removeSOTData: (assetId: string) => dispatch(removeSOTData(assetId))
  })
)(SOT);
