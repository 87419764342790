// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useDispatch } from "react-redux";

import { loadAssetRiskConfig } from "features/detailpage/features/riskmatrix/actions/RiskMatrixActions";
import { useCallback } from "react";

const useLoadAssetRiskConfig = () => {
  const dispatch = useDispatch();
  return useCallback(
    (modelId: string) => loadAssetRiskConfig(dispatch, modelId),
    [dispatch]
  );
};

export default useLoadAssetRiskConfig;
