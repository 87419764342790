// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import DataTypes from "../models/DataTypes";
import FormTypes from "../models/FormTypes";
import IMenuItem from "../models/IMenuItem";

export const menuConfiguration: IMenuItem[] = [
  {
    key: "general",
    title: {
      id: "configuration_tool.section.general",
      defaultMessage: "General"
    },
    children: [
      {
        key: "nameplate",
        title: {
          id: "configuration_tool.tab.nameplate",
          defaultMessage: "Nameplate"
        },
        iconName: "info",
        formType: FormTypes.Nameplate,
        dataType: DataTypes.Nameplate
      },
      {
        key: "details",
        title: {
          id: "configuration_tool.tab.details",
          defaultMessage: "Details"
        },
        iconName: "mdi-folder-information-outline",
        formType: FormTypes.PreloadedInspection,
        dataType: DataTypes.Inspection
      },
      {
        key: "factoryTestData",
        title: {
          id: "configuration_tool.tab.factory_test_data",
          defaultMessage: "Factory Test Data"
        },
        iconName: "mdi-beaker-check-outline",
        formType: FormTypes.PreloadedInspection,
        dataType: DataTypes.Inspection
      }
    ]
  },
  {
    key: "inspection",
    title: {
      id: "configuration_tool.section.inspection",
      defaultMessage: "Inspection"
    },
    children: [
      {
        key: "dga",
        title: {
          id: "configuration_tool.tab.dga",
          defaultMessage: "DGA"
        },
        iconName: "mdi-molecule-co2",
        formType: FormTypes.Inspection,
        dataType: DataTypes.Inspection
      },
      {
        key: "visualInspection",
        title: {
          id: "configuration_tool.tab.visual_inspection",
          defaultMessage: "Visual Inspection"
        },
        iconName: "pg-visual-inspection",
        formType: FormTypes.TabbedInspection,
        dataType: DataTypes.Inspection
      },
      {
        key: "ltc",
        title: {
          id: "configuration_tool.tab.ltc",
          defaultMessage: "LTC"
        },
        iconName: "pg-ltc",
        formType: FormTypes.Inspection,
        dataType: DataTypes.Inspection
      },
      {
        key: "sot",
        title: {
          id: "configuration_tool.tab.sot",
          defaultMessage: "SOT"
        },
        iconName: "mdi-oil-level",
        formType: FormTypes.Inspection,
        dataType: DataTypes.Inspection
      },
      {
        key: "bushings",
        title: {
          id: "configuration_tool.tab.bushings",
          defaultMessage: "Bushings"
        },
        iconName: "pg-bushing",
        formType: FormTypes.Inspection,
        dataType: DataTypes.Inspection
      },
      {
        key: "insulation",
        title: {
          id: "configuration_tool.tab.insulation",
          defaultMessage: "Insulation"
        },
        iconName: "pg-insulation",
        formType: FormTypes.Inspection,
        dataType: DataTypes.Inspection
      },
      {
        key: "electricalTests",
        title: {
          id: "configuration_tool.tab.electrical_tests",
          defaultMessage: "Electrical tests"
        },
        iconName: "pg-electrical-test",
        formType: FormTypes.Inspection,
        dataType: DataTypes.Inspection
      },
      {
        key: "modelFactors",
        title: {
          id: "configuration_tool.tab.model_factors",
          defaultMessage: "Model factors"
        },
        iconName: "pg-model-factors",
        formType: FormTypes.Inspection,
        dataType: DataTypes.Inspection
      },
      {
        key: "customParameters",
        title: {
          id: "configuration_tool.tab.custom_parameters",
          defaultMessage: "Custom Parameters"
        },
        iconName: "tune",
        formType: FormTypes.CustomParameters,
        dataType: DataTypes.Inspection
      }
    ]
  },
  {
    key: "assetDevices",
    title: {
      id: "configuration_tool.section.asset_devices",
      defaultMessage: "Asset devices"
    },
    children: [
      {
        key: "connectedDevices",
        title: {
          id: "configuration_tool.tab.connected_devices",
          defaultMessage: "Connected devices"
        },
        iconName: "cable",
        formType: FormTypes.ConnectedDevices,
        dataType: DataTypes.ConnectedDevices
      }
    ]
  }
];
