// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import { Spin } from "antd";
import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "thunk";

import ParameterHistogram, {
  loadParameterHistogram
} from "../../ParameterHistogram";
import IParameterHistogram from "../../ParameterHistogram/models/IParameterHistogram";
import getParameterHistogramSelector from "../../ParameterHistogram/selectors/getParameterHistogramSelector";
import Popover from "common/popover/Popover";
import Data, { Statuses } from "core/data/models/Data";
import ParameterSources from "models/ParameterSources";
import { IState } from "reducers/Index";

interface IActionHistogramActionsData {
  histogramData: Data<IParameterHistogram[]>;
}

interface IActionHistogramActionsAction {
  loadParameterHistogram: (
    assetId: string,
    parameterName: string,
    parameterSource: ParameterSources
  ) => void;
}
interface IActionHistogramOwnProps {
  assetId: string;
  parameterName: string;
  parameterSource: ParameterSources;
  translatedParameterName: string;
  unit: string | null | undefined;
}

type IActionHistogramProps = IActionHistogramActionsData &
  IActionHistogramActionsAction &
  IActionHistogramOwnProps;

const ActionHistogram = ({
  assetId,
  parameterName,
  parameterSource,
  translatedParameterName,
  unit,
  histogramData,
  loadParameterHistogram
}: IActionHistogramProps) => {
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleActionClick = useCallback(() => {
    if (!popoverVisible) {
      Promise.all([
        loadParameterHistogram(assetId, parameterName, parameterSource)
      ]).then(() => {
        setPopoverVisible(true);
      });
    }
  }, [
    assetId,
    loadParameterHistogram,
    parameterName,
    parameterSource,
    popoverVisible
  ]);

  const handlePopoverHide = useCallback(() => {
    setPopoverVisible(false);
  }, []);

  return (
    <Popover
      content={
        <ParameterHistogram
          histogramData={histogramData}
          title={translatedParameterName}
          unit={unit}
        />
      }
      onHide={handlePopoverHide}
      overlayClassName="histogram-parameters-popover"
      placement="topRight"
      visible={popoverVisible}
    >
      <span
        className="action-histogram-popover-icon"
        onClick={handleActionClick}
      >
        {histogramData?.status === Statuses.Loading ? (
          <Spin className="icon" />
        ) : (
          <Icon name="bar_chart" />
        )}
      </span>
    </Popover>
  );
};

const mapStateToProps = (state: IState): IActionHistogramActionsData => ({
  histogramData: getParameterHistogramSelector(state)
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any>
): IActionHistogramActionsAction => ({
  loadParameterHistogram: (
    assetId: string,
    parameterName: string,
    parameterSource: ParameterSources
  ) => dispatch(loadParameterHistogram(assetId, parameterName, parameterSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionHistogram);
