// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { EventEmitter } from "events";

const changeEvent = "change";

export interface IWidget {
  id: string;
  expanded: boolean;
}

class WidgetStore extends EventEmitter {
  private widgets: IWidget[] = [];

  addChangeListener(callback: () => void) {
    this.on(changeEvent, callback);
  }

  removeChangeListener(callback: () => void) {
    this.removeListener(changeEvent, callback);
  }

  emitChange() {
    this.emit(changeEvent);
  }

  initStore(widgetId: string) {
    let widget = this.getWidget(widgetId);
    if (widget == null) {
      widget = {
        id: widgetId,
        expanded: false
      } as IWidget;
      this.widgets.push(widget);
    } else {
      widget.expanded = false;
    }
  }

  clearWidgets() {
    if (this.widgets.length > 0) {
      this.widgets = [];
      this.emitChange();
    }
  }

  getWidget(widgetId: string): IWidget {
    const widgets = this.widgets.filter((w) => w.id === widgetId);
    return widgets.length > 0 ? widgets[0] : null;
  }

  toggleExpand(widgetId: string) {
    const widget = this.getWidget(widgetId);
    if (widget) {
      widget.expanded = !widget.expanded;
      this.emitChange();
    }
  }

  getWidgetsIds(): string[] {
    return this.widgets.map((v, i, a) => {
      return v.id;
    });
  }
}

const widgetStore = new WidgetStore();

export default widgetStore;
export { changeEvent };
