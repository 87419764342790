// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";

import AgingOperationsService from "features/detailpage/features/aging/services/AgingOperationsService";

interface IUseAgingTotalOperationsConfigurationPayload {
  agingOperations: number;
  warningThreshold: number;
  alarmThreshold: number;
}

const useAgingOperationsConfiguration = ({
  agingOperations,
  warningThreshold,
  alarmThreshold
}: IUseAgingTotalOperationsConfigurationPayload) => {
  const configuration = React.useMemo(
    () =>
      AgingOperationsService.getChartConfiguration(
        agingOperations,
        warningThreshold,
        alarmThreshold
      ),
    [agingOperations, warningThreshold, alarmThreshold]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const legendData = React.useMemo(
    AgingOperationsService.getChartLegendData,
    []
  );

  return {
    configuration,
    legendData
  };
};

export default useAgingOperationsConfiguration;
