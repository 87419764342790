// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import { useMemo } from "react";
import { IBasicFilter } from "powerbi-models";
import { History } from "history";

const usePbiReportTab = (search: string, history: History) => {
  const searchParams = useMemo(() => new SearchParams(search), [search]);
  const reportName = useMemo(() => searchParams.get("pbi_n"), [searchParams]);
  const filters = useMemo(
    (): IBasicFilter[] => JSON.parse(searchParams.get("pbi_f")),
    [searchParams]
  );

  return {
    reportName,
    filters
  };
};

export default usePbiReportTab;
