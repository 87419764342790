// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import Switch, { ISwitchItem } from "common/form/components/Switch";

const itemActiveId = "active";
const itemHistoryId = "history";
const items: ISwitchItem[] = [
  {
    id: itemActiveId,
    message: {
      id: "detail_page.issues.issue_status.active",
      defaultMessage: "Active"
    }
  },
  {
    id: itemHistoryId,
    message: {
      id: "detail_page.issues.issue_status.history",
      defaultMessage: "History"
    }
  }
];

interface IIssueTypeSwitchProps {
  disabled?: boolean;
  onActiveSelected?: () => void;
  onHistorySelected?: () => void;
}

const IssueTypeSwitch = ({
  disabled,
  onActiveSelected,
  onHistorySelected
}: IIssueTypeSwitchProps) => {
  const handleSwitchChanged = (id: string) => {
    if (id === itemActiveId && onActiveSelected) {
      onActiveSelected();
    }
    if (id === itemHistoryId && onHistorySelected) {
      onHistorySelected();
    }
  };

  return (
    <Switch
      defaultSelected={itemActiveId}
      className="issue-type-switch"
      disabled={disabled}
      items={items}
      onChange={handleSwitchChanged}
    />
  );
};

export default IssueTypeSwitch;
