// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useSelector } from "react-redux";
import { useCallback, useMemo } from "react";

import { Statuses } from "core/data/models/Data";

import getAssetRiskConfigurationSelector from "../../../selectors/getAssetRiskConfigurationSelector";
import getRiskMatrixClustersSelector from "../../../selectors/getRiskMatrixClustersSelector";

const useRiskMatrixChartWrapper = () => {
  const clusters = useSelector(getRiskMatrixClustersSelector);
  const assetRiskConfiguration = useSelector(getAssetRiskConfigurationSelector);

  const hasStatus = useCallback(
    (status: Statuses) =>
      assetRiskConfiguration.status === status || clusters.status === status,
    [clusters, assetRiskConfiguration]
  );

  const errorMessage = useMemo(() => {
    if (assetRiskConfiguration.status === Statuses.Failed)
      return assetRiskConfiguration.message;
    else if (clusters.status === Statuses.Failed) return clusters.message;

    return "";
  }, [assetRiskConfiguration, clusters]);

  return {
    assetRiskConfiguration,
    errorMessage,
    hasStatus
  };
};

export default useRiskMatrixChartWrapper;
