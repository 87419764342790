// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { ISelectedFilterOption } from "@pg/common/build/components/FilterPanel";
import ApiSelectedFilterOption from "../models/ISelectedFilterOption";

const mapToOption = (o: ISelectedFilterOption): ApiSelectedFilterOption => ({
  Id: o.id,
  Options: o.options?.map((o) => mapToOption(o))
});

export default mapToOption;
