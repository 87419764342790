// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import getCanBeSavedSelector from "../selectors/getCanBeSavedSelector";

import { setCanBeSavedAction } from "../actions/NotificationsActions";

const useSetCanBeSaved = () => {
  const canBeSaved = useSelector(getCanBeSavedSelector);

  const dispatch = useDispatch();
  const setCanBeSaved = useCallback(
    (value: boolean) => {
      if (canBeSaved !== value) {
        dispatch(setCanBeSavedAction(value));
      }
    },
    [canBeSaved, dispatch]
  );

  return { setCanBeSaved };
};

export default useSetCanBeSaved;
