// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";

import { getDataKey } from "../actions/loadAssetDetails";

import IAssetDetails from "../models/IAssetDetails";

const getAssetDetailsSelector = createSelector<
  IState,
  (assetId: string) => Data<IAssetDetails>,
  (assetId: string) => Data<IAssetDetails>
>(
  (state: IState) => (assetId: string) => state.data[getDataKey(assetId)],
  (assetDetails) => assetDetails
);

export default getAssetDetailsSelector;
