// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { notifications } from "@pg/common/build/components/Notifications";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { saveNotificationsSettingsAction } from "../actions/NotificationsActions";
import { INotificationsModelOutput } from "../models/INotificationsModelOutput";

const useSaveModel = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const saveNotifications = useCallback(
    async (payload: INotificationsModelOutput) => {
      try {
        await saveNotificationsSettingsAction(dispatch, payload);
        notifications.success({
          message: intl.formatMessage({
            id: "notifications.navbar_container.notification_have_been_saved_successfully",
            defaultMessage:
              "Notification preferences have been saved successfully"
          })
        });
      } catch (e) {
        notifications.error({
          message: intl.formatMessage({
            id: "notifications.navbar_container.notifications_data_save_failed",
            defaultMessage: "Notifications data save failed."
          })
        });

        throw e;
      }
    },
    [dispatch, intl]
  );

  return { saveNotifications };
};

export default useSaveModel;
