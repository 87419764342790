// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Popover from "antd/lib/popover";
import React, { ReactNode, useMemo } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";

import { IRow } from "common/datagrid/DataGrid";
import isClickable from "../utils/isClickable";
import IParameterInfo from "../../../models/IParameterInfo";
import ITypedValue from "../../../models/ITypedValue";

import useIsEllipsisActive from "../hooks/useIsEllipsisActive";

const BooleanValue = ({ value }: { value: boolean }) =>
  value ? (
    <FormattedMessage id="global.messages.yes" defaultMessage="YES" />
  ) : (
    <FormattedMessage id="global.messages.no" defaultMessage="NO" />
  );

const DateTimeValue = ({ value }: { value: string }) => (
  <FormattedDate
    value={new Date(value)}
    day="numeric"
    month="numeric"
    year="numeric"
    hour="numeric"
    minute="numeric"
    second="numeric"
  />
);

const DecimalValue = ({ value }: { value: number }) => (
  <FormattedNumber
    value={value}
    minimumFractionDigits={1}
    maximumFractionDigits={3}
  />
);

interface IValueProps {
  children?: ReactNode;
  value: ITypedValue;
  row: IRow<IParameterInfo>;
  color?: string;
}

const Value = ({ children, color, value, row }: IValueProps) => {
  const clickable = useMemo(() => isClickable(row), [row]);
  const { divRef, isEllipsisActive } = useIsEllipsisActive();
  const content = useMemo(
    () => (
      <div
        className={`column value ${clickable ? "" : "non-clickable"}`}
        style={{
          backgroundColor: color ? "white" : "unset",
          borderBottom: color ? `solid 3px ${color}` : undefined
        }}
        ref={divRef}
      >
        {color && (
          <div
            className="background"
            style={{
              backgroundColor: color
            }}
          />
        )}
        {value && value.Type === "Decimal" && (
          <DecimalValue value={value.Value as number} />
        )}
        {value && value.Type === "DateTime" && (
          <DateTimeValue value={value.Value as string} />
        )}
        {value && value.Type === "String" && (
          <span>{value.Value as string}</span>
        )}
        {value && value.Type === "Bool" && (
          <BooleanValue value={value.Value as boolean} />
        )}
        {children}
      </div>
    ),
    [children, clickable, color, divRef, value]
  );

  return isEllipsisActive ? (
    <Popover content={content} destroyTooltipOnHide={true}>
      {content}
    </Popover>
  ) : (
    content
  );
};

export default Value;
