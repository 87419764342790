// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import { routesConfig } from "core/app/components/router/RouterProvider";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";

const OpenIssuesFooter = () => (
  <div className="tile-footer">
    <NavLink
      className="explore anchor"
      data-qa="link"
      to={{
        pathname: routesConfig.issues,
        search: `f_is=["New"]&f_is=["InProgress"]&f_is=["Monitor"]`
      }}
    >
      <FormattedMessage
        id="homepage.opened_issues.explore"
        defaultMessage="Explore"
      />
      <Icon name="arrow_right_alt" />
    </NavLink>
  </div>
);

export default OpenIssuesFooter;
