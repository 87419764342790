// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { default as IParameterRiskGrid } from "@apm/widgets/build/widgets/PrognosticRiskGrid/models/IIndicativeParameter";
import IParameter from "features/detailpage/models/IParameter";

export default class PrognosticRiskGridParametersMapper {
  public map(parameters: IParameter[]): IParameterRiskGrid[] {
    return parameters.map((p) => {
      return {
        unit: p.Unit,
        name: p.Name,
        parameterSource: p.ParameterSource
      };
    });
  }
}
