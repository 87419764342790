// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Button } from "antd";
import * as React from "react";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { setSimulationModalVisibleAction } from "../../../actions/RiskSimulationSummaryActions";

const OpenButton = () => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(setSimulationModalVisibleAction(true));
  }, [dispatch]);

  return (
    <Button
      className="open-button"
      type="primary"
      data-qa="open-button"
      onClick={handleClick}
    >
      <FormattedMessage
        defaultMessage="Simulation"
        id="detail_page.risk_trend.risk_simulation_summary.open"
      />
    </Button>
  );
};

export default OpenButton;
