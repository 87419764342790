// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { useThunkMiddleware } from "thunk";

import HistorySwitching from "features/detailpage/features/ltc/components/HistorySwitching";

import { useHistorySwitchingOperations } from "features/detailpage/features/ltc/reducers/LTCReducer";
import { loadHistorySwitchingOperationsAction } from "features/detailpage/features/ltc/actions/HistorySwitchingActions";
import { Statuses } from "core/data/models/Data";

interface IHistorySwitchingContainerProps {
  assetId: string;
}

const HistorySwitchingContainer = ({
  assetId
}: IHistorySwitchingContainerProps) => {
  const { historySwitching } = useHistorySwitchingOperations();
  const loadHistorySwitchingOperations = useThunkMiddleware(
    loadHistorySwitchingOperationsAction(assetId)
  );

  useEffect(() => {
    if (
      historySwitching.status !== Statuses.Succeeded ||
      isEmpty(historySwitching.data)
    ) {
      loadHistorySwitchingOperations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <HistorySwitching historySwitching={historySwitching} />;
};

export default HistorySwitchingContainer;
