// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IRow, IRowData } from "common/datagrid/DataGrid";
import { ISwitchItem } from "common/form/components/Switch";
import { useCallback, useState } from "react";
import LocationsPanelContentSwitchOption from "../models/LocationsPanelContentSwitchOption";

const contentItems: ISwitchItem[] = [
  {
    id: "Assets",
    message: {
      defaultMessage: "Assets",
      id: "locations_page.side_panel.assets"
    }
  },
  {
    id: "Issues",
    message: {
      defaultMessage: "Issues",
      id: "locations_page.side_panel.issues"
    }
  }
];

const useLocationsPanel = () => {
  const [isLocationPanelVisible, setIsLocationPanelVisible] =
    useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<IRow<IRowData>>();

  const [selectedContent, setSelectedContent] = useState(
    contentItems[0].id as LocationsPanelContentSwitchOption
  );

  const handleLocationPanelOpen = useCallback(
    (
      selectedRow: IRow<IRowData>,
      selectedContent: LocationsPanelContentSwitchOption
    ) => {
      setSelectedRow(selectedRow);
      setSelectedContent(selectedContent);
      setIsLocationPanelVisible(true);
    },
    [setSelectedRow, setSelectedContent, setIsLocationPanelVisible]
  );

  const handleLocationPanelClose = useCallback(() => {
    setSelectedRow(null);
    setSelectedContent(null);
    setIsLocationPanelVisible(false);
  }, [setSelectedRow, setSelectedContent, setIsLocationPanelVisible]);

  return {
    isLocationPanelVisible,
    handleLocationPanelOpen,
    handleLocationPanelClose,
    selectedRow,
    selectedContent,
    setSelectedContent,
    contentItems
  };
};

export default useLocationsPanel;
