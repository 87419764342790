// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { useSelector } from "react-redux";

import getNotifcationsEnabledSelector from "../selectors/getNotificationsEnabledSelector";
import getDegradedToRedSelector from "../selectors/getDegradedToRedSelector";
import getDegradedToYellowSelector from "../selectors/getDegradedToYellowSelector";
import getImprovedToGreenSelector from "../selectors/getImprovedToGreenSelector";
import getImprovedToYellowSelector from "../selectors/getImprovedToYellowSelector";
import getAllDegradedCheckedSelector from "../selectors/getAllDegradedCheckedSelector";

import useSetCanBeSaved from "../hooks/useSetCanBeSaved";
import useSetAllDegradedChecked from "../hooks/useSetAllDegradedChecked";

import DegradedToggleView from "../components/DegradedToggleView/DegradedToggleView";

const DegradedToggleViewContainer = () => {
  const notificationsEnabled = useSelector(getNotifcationsEnabledSelector);
  const degradedToRed = useSelector(getDegradedToRedSelector);
  const degradedToYellow = useSelector(getDegradedToYellowSelector);
  const improvedToGreen = useSelector(getImprovedToGreenSelector);
  const improvedToYellow = useSelector(getImprovedToYellowSelector);
  const allDegradedChecked = useSelector(getAllDegradedCheckedSelector);

  const { setCanBeSaved } = useSetCanBeSaved();
  const { setAllDegradedChecked } = useSetAllDegradedChecked();
  const onDegradedToggleChanged = React.useCallback(() => {
    setCanBeSaved(true);
    setAllDegradedChecked(undefined);
  }, [setAllDegradedChecked, setCanBeSaved]);

  return (
    <DegradedToggleView
      notificationsEnabled={notificationsEnabled}
      degradedToRed={degradedToRed}
      degradedToYellow={degradedToYellow}
      improvedToGreen={improvedToGreen}
      improvedToYellow={improvedToYellow}
      allDegradedChecked={allDegradedChecked}
      onDegradedToggleChanged={onDegradedToggleChanged}
    />
  );
};

export default DegradedToggleViewContainer;
