// Copyright 2016-2022 Hitachi Energy. All rights reserved.
export const colorBlue10 = "#DEF4FA";
export const colorBlue20 = "#B3E6FF";
export const colorBlue30 = "#6CD5FF";
export const colorBlue40 = "#2FC8F7";
export const colorBlue50 = "#00ACEC";
export const colorBlue60 = "#1B7AC2";
export const colorBlue70 = "#005CA3";
export const colorBlue80 = "#004480";
export const colorBlue90 = "#00284A";
export const colorBlue100 = "#031324";

export const colorTeal10 = "#E4F6F9";
export const colorTeal20 = "#BDEAF1";
export const colorTeal30 = "#96DCE8";
export const colorTeal40 = "#72CEDE";
export const colorTeal50 = "#5EC3D7";
export const colorTeal60 = "#53BAD1";
export const colorTeal70 = "#4BAABE";
export const colorTeal80 = "#4195A5";
export const colorTeal90 = "#38818D";
export const colorTeal100 = "#275F63";

export const colorGray0 = "#FFFFFF";
export const colorGray10 = "#EEEFF1";
export const colorGray20 = "#D4D7DC";
export const colorGray30 = "#B8BCC5";
export const colorGray40 = "#9BA2AF";
export const colorGray50 = "#858D9D";
export const colorGray60 = "#707A8C";
export const colorGray70 = "#626B7B";
export const colorGray80 = "#515865";
export const colorGray90 = "#404550";
export const colorGray100 = "#2D3138";

export const colorStatusYellow = "#FFBF00";
export const colorStatusOrange = "#EE7A30";
export const colorStatusRed = "#CC0022";
export const colorStatusMagenta = "#BB40CE";
export const colorStatusGreen = "#4CAF50";
export const colorStatusBlue = "#1671D9";

export const colorAppBackgroundLight = colorGray10;
export const colorAppBackgroundDark = colorGray100;
export const colorContentAreaLight = colorGray0;
export const colorContentAreaDark = colorGray90;
export const colorPrimary = colorTeal90;
export const colorPrimaryLight = colorTeal60;
export const colorPrimaryDark = colorTeal100;
export const colorTextPrimaryOnBlack = "#FAFAFA";
export const colorTextPrimaryOnDark = colorGray0;
export const colorTextPrimaryOnLight = "#414141";
export const colorTextSecondaryOnDark = colorGray20;
export const colorTextSecondaryOnLight = "#999999";

export const colorIconAccent = colorPrimary;
export const colorIconHoverFocus = colorPrimary;
export const colorIconActive = colorPrimaryDark;

export const colorRiskUnknown = colorGray40;
export const colorRiskLow = colorStatusGreen;
export const colorRiskMedium = colorStatusYellow;
export const colorRiskHigh = colorStatusRed;

export const colorsChartSeriesDefault = [
  "#2E92FA",
  "#6C46AA",
  "#973E82",
  "#CB6745",
  "#CBAF49",
  "#329A5D",
  "#28B1B5",
  "#80A0A6"
];

export const colorsChartSeriesLight = [
  "#ABD4FD",
  "#A288CE",
  "#C675B3",
  "#ECA88F",
  "#F1DD8E",
  "#92DEB1",
  "#90E4E6",
  "#BCD0D4"
];

export const colorsChartGreyScale = [
  colorGray100,
  colorGray80,
  colorGray60,
  colorGray40,
  colorGray20
];

export const colorsChartSeries = [
  ...colorsChartSeriesDefault,
  ...colorsChartSeriesLight
];

export const colorAppBackground = "#F0F0F0";
export const colorWidgetBackground = "#FBFCFC";
export const colorWidgetBorder = "#ccced0";
