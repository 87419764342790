// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Typography } from "antd";
import { DegradationScoreService } from "common/formatters/DegradationScore";
import { ParameterService } from "common/formatters/ParameterName";
import ParameterSources from "models/ParameterSources";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

interface IDetectionColumnProps {
  className?: string;
  parameterName: string;
  parameterValue: number;
  modelId: string;
  implementationId: string;
  parameterSource: ParameterSources;
}

const DetectionColumn = ({
  className,
  parameterName,
  parameterValue,
  modelId,
  implementationId,
  parameterSource
}: IDetectionColumnProps) => {
  const intl = useIntl();

  const formattedValue = useMemo(
    () => DegradationScoreService.format(parameterValue, intl),
    [parameterValue, intl]
  );

  return (
    <Typography.Text className={className}>
      <FormattedMessage
        defaultMessage="{parameterName}: {parameterValue}"
        id="alarms.grid.columns.detection.value"
        values={{
          parameterName: ParameterService.format(
            parameterName,
            modelId,
            implementationId,
            intl,
            parameterSource
          ),
          parameterValue: formattedValue
        }}
      />
    </Typography.Text>
  );
};

const columnPadding = "10px";

export default styled(DetectionColumn)`
  display: block;
  padding: ${columnPadding};
`;
