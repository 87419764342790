// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { IStation } from "@apm/widgets/build/widgets/SpatialView";

import { SelectedFilters } from "common/FilterBar";
import { routesConfig } from "core/app/components/router/RouterProvider";
import getMapProviderSelector from "core/selectors/getMapProviderSelector";

import {
  loadStationRisk,
  removeStationRisk
} from "../actions/StationRiskActions";
import getStationRiskSelector from "../selectors/getStationRiskSelector";

const formatParam = (value: string | null) =>
  isNil(value) ? null : `"${value}"`;

const useRiskMapDashboard = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<SelectedFilters>();
  const stationRisk = useSelector(getStationRiskSelector);
  const mapProvider = useSelector(getMapProviderSelector);

  const history = useHistory();

  useEffect(() => {
    if (filters) dispatch(loadStationRisk(filters));

    return () => {
      dispatch(removeStationRisk());
    };
  }, [dispatch, filters]);

  const handleFilter = (filters: SelectedFilters) => {
    setFilters(filters);
  };

  const handleSingleMarkerClick = (station: IStation) => {
    const search = new SearchParams(history.location.search);
    search.set(
      "f_ao",
      `[${formatParam(station.organization)},${formatParam(
        station.region
      )},${formatParam(station.location)}]`
    );
    search.append("f_as", `[${formatParam("InService")}]`);
    history.push({
      pathname: routesConfig.assets,
      search: search.toString()
    });
  };

  const handleClusterMarkerClick = (cluster: IStation[]) => {
    const search = new SearchParams(history.location.search);
    search.delete("f_ao");
    search.delete("f_as");
    cluster.forEach(
      (s) =>
        search.append(
          "f_ao",
          `[${formatParam(s.organization)},${formatParam(
            s.region
          )},${formatParam(s.location)}]`
        ),
      search.append("f_as", `[${formatParam("InService")}]`)
    );
    history.push({
      pathname: routesConfig.assets,
      search: search.toString()
    });
  };

  return {
    stationRisk,
    mapProvider,
    handleFilter,
    handleSingleMarkerClick,
    handleClusterMarkerClick
  };
};

export default useRiskMapDashboard;
