// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import ParametersTrend, {
  IParameter,
  IThresholds
} from "@apm/widgets/build/widgets/ParametersTrend";
import Processing from "components/common/Processing";
import { WidgetErrorMessage } from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import React, { useMemo } from "react";
import useParametersChart from "../hooks/useParametersChart";
import ISelectedParameter from "../models/ISelectedParameter";
import "./ParametersChart.less";

export interface IParametersChartProps {
  assetId: string;
  selectedParameters: ISelectedParameter[];
}

const ParametersChart = ({
  assetId,
  selectedParameters
}: IParametersChartProps) => {
  const {
    formatXTick,
    formatYTick,
    formatTooltipTitle,
    formatTooltipValue,
    status,
    parameters,
    emptyTranslation,
    titleTranslation
  } = useParametersChart(assetId, selectedParameters);

  return (
    <div className="parameters-chart">
      <ParametersChartContent
        emptyTranslation={emptyTranslation}
        titleTranslation={titleTranslation}
        formatTooltipTitle={formatTooltipTitle}
        formatTooltipValue={formatTooltipValue}
        formatXTick={formatXTick}
        formatYTick={formatYTick}
        parameters={parameters}
        status={status}
      />
    </div>
  );
};

interface IParametersChartContentProps {
  formatXTick: (date: Date) => string;
  formatYTick: (value: number, unit: string) => string;
  formatTooltipTitle: (date: Date) => string;
  formatTooltipValue: (
    value: number,
    unit: string | null | undefined
  ) => string;
  emptyTranslation: string;
  titleTranslation: string;
  height?: number;
  parameters: Data<{
    parameter: IParameter;
    thresholds: IThresholds;
  }>[];
  status: Statuses;
}

const ParametersChartContent = ({
  formatXTick,
  formatYTick,
  formatTooltipTitle,
  formatTooltipValue,
  emptyTranslation,
  titleTranslation,
  height,
  parameters,
  status
}: IParametersChartContentProps) => (
  <div className="parameters-chart">
    {status === null && (
      <ParametersChartNoData
        formatYTick={formatYTick}
        emptyTranslation={emptyTranslation}
        titleTranslation={titleTranslation}
        height={height}
      />
    )}
    {status === Statuses.Loading && <ParametersChartLoading />}
    {status === Statuses.Succeeded && (
      <ParametersChartSucceeded
        formatXTick={formatXTick}
        formatYTick={formatYTick}
        formatTooltipTitle={formatTooltipTitle}
        formatTooltipValue={formatTooltipValue}
        emptyTranslation={emptyTranslation}
        titleTranslation={titleTranslation}
        height={height}
        parameters={parameters?.map((p) => p.data.parameter).filter((x) => x)}
        thresholds={
          parameters.length === 1
            ? parameters?.map((p) => p.data.thresholds).filter((x) => x)[0]
            : null
        }
      />
    )}
    {status === Statuses.Failed && <ParametersChartFailed />}
  </div>
);

const ParametersChartLoading = () => (
  <div className="loading">
    <Processing />
  </div>
);

interface IParametersChartNoDataProps {
  formatYTick: (value: number, unit: string) => string;
  emptyTranslation: string;
  titleTranslation: string;
  height?: number;
}

const ParametersChartNoData = ({
  formatYTick,
  emptyTranslation,
  titleTranslation,
  height = 300
}: IParametersChartNoDataProps) => {
  return (
    <ParametersTrend
      chartDataQa="parameters-chart"
      formatYTick={formatYTick}
      height={height}
      parameters={null}
      showParametersInLegend={false}
      showWidgetCard={false}
      translations={{
        title: titleTranslation,
        empty: emptyTranslation
      }}
    />
  );
};

interface IParametersChartSucceededProps {
  formatXTick: (date: Date) => string;
  formatYTick: (value: number, unit: string) => string;
  formatTooltipTitle: (date: Date) => string;
  formatTooltipValue: (
    value: number,
    unit: string | null | undefined
  ) => string;
  height?: number;
  parameters: IParameter[];
  thresholds: IThresholds;
  emptyTranslation: string;
  titleTranslation: string;
}

const ParametersChartSucceeded = ({
  formatXTick,
  formatYTick,
  formatTooltipTitle,
  formatTooltipValue,
  height = 300,
  emptyTranslation,
  titleTranslation,
  parameters,
  thresholds
}: IParametersChartSucceededProps) => {
  const units = useMemo(() => {
    if (!parameters) return null;
    const units: string[] = [];
    parameters.forEach((p) => {
      if (!units.includes(p.unit)) units.push(p.unit);
    });
    return units;
  }, [parameters]);

  return (
    <ParametersTrend
      chartDataQa="parameters-chart"
      formatXTick={formatXTick}
      formatYTick={formatYTick}
      formatTooltipTitle={formatTooltipTitle}
      formatTooltipValue={formatTooltipValue}
      height={
        thresholds?.lines?.length || thresholds?.regions?.length
          ? height - 50
          : height
      }
      axisYLabel={units.length > 0 ? units[0] : null}
      axisY2Label={units.length > 1 ? units[1] : null}
      parameters={parameters}
      thresholds={thresholds}
      showParametersInLegend={false}
      showWidgetCard={false}
      translations={{
        title: titleTranslation,
        empty: emptyTranslation
      }}
    />
  );
};

const ParametersChartFailed = () => <WidgetErrorMessage />;

export default ParametersChart;
