// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Col, Row } from "antd";
import { IRoutableTab } from "common/RoutableTabs";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { IModelDetails } from "../../DielectricTab/DielectricTab";
import SF6Parameters from "../../SF6Parameters";
import { SF6PressureTimedChart } from "../../SF6PressureChart";

const useDielectricSF6Tab = (assetId: string, model: IModelDetails) => {
  const intl = useIntl();
  const tabs: IRoutableTab[] = useMemo(
    () => [
      {
        Content: () => (
          <Row className="dielectric-parameters-chart-tab">
            <Col span={8}>
              <SF6Parameters assetId={assetId} />
            </Col>
            <Col span={16}>
              <SF6PressureTimedChart
                model={model}
                showAdjustToThresholdsSwitch
              />
            </Col>
          </Row>
        ),
        id: "SF6Pressure",
        name: intl.formatMessage({
          id: "detail_page.widgets.circuit_breaker.dielectric.title.pressure",
          defaultMessage: "SF6 Pressure"
        })
      }
    ],
    [assetId, intl, model]
  );

  return { tabs };
};

export default useDielectricSF6Tab;
