// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import VisualDuvalTriangle, {
  DuvalDataPoint,
  DuvalTriangleLegend,
  ITriangleLabels
} from "./DuvalTriangle";
import "./DuvalTriangleChart.less";
import DuvalTriangleCollapse from "./DuvalTriangleCollapse";
import IDuvalRegion from "./models/IDuvalRegion";

interface IDuvalTriangleChartProps {
  intl: IntlShape;
  type: number;
  regions: IDuvalRegion[];
  version?: number;
  points: DuvalDataPoint[];
  note?: React.ReactNode;
  labels: ITriangleLabels;
}

class DuvalTriangleChart extends React.Component<IDuvalTriangleChartProps, {}> {
  private d3Root: SVGElement = null;
  private d3Tooltip: HTMLElement = null;
  private Legend: DuvalTriangleLegend = null;
  private duvalTriangle: VisualDuvalTriangle = null;
  constructor(props: IDuvalTriangleChartProps) {
    super(props);
    this.Legend = new DuvalTriangleLegend(props.type);
    this.drawTriangle = this.drawTriangle.bind(this);
  }

  componentDidMount(): void {
    this.duvalTriangle = new VisualDuvalTriangle(this.d3Root, this.d3Tooltip);
    this.drawTriangle();
  }

  componentDidUpdate(): void {
    this.drawTriangle();
  }

  private drawTriangle() {
    this.duvalTriangle.draw(
      {
        type: this.props.type,
        sortedPoints: this.props.points,
        regions: this.props.regions,
        labels: this.props.labels
      },
      this.props.intl
    );
  }

  render() {
    return (
      <div className="duval-triangle">
        <div className="duval-triangle-header">
          <FormattedMessage
            id="detail_page.widgets.analytics.transformers.DuvalTriangle.Type"
            values={{ type: this.props.type }}
            defaultMessage="Type {type}"
          />
        </div>
        <svg
          ref={(ref: SVGElement) => {
            this.d3Root = ref;
          }}
          style={{ width: "100%", height: "400px" }}
        ></svg>
        <div
          className="c-tooltip"
          ref={(ref: HTMLElement) => {
            this.d3Tooltip = ref;
          }}
        ></div>
        {this.props.note && <DuvalTriangleCollapse note={this.props.note} />}
        <table className="legend">
          <tbody>
            {Object.keys(this.Legend.labels).map((label, index) => (
              <tr key={index}>
                <td>{label}</td>
                <td>
                  ={" "}
                  <FormattedMessage
                    id={`detail_page.widgets.analytics.transformers.DuvalTriangle.${label}`}
                    defaultMessage={this.Legend.labels[label]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default injectIntl(DuvalTriangleChart);
