// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";

import { Themes, ISelectOption } from "common/form/components/Select";
import SelectInputContainer from "common/form/containers/SelectInputContainer";
import { IssueStatuses } from "features/detailpage/features/issues/models/IssueStatuses";

export interface IIssueStatusSelectData {
  statuses: IssueStatuses[];
}

export interface IIssueStatusSelectOwnProps {
  disabled?: boolean;
  formName: string;
  issueStatus: IssueStatuses;
  onChange?: (status: IssueStatuses) => void;
}

interface IIssueStatusSelectProps
  extends IIssueStatusSelectData,
    IIssueStatusSelectOwnProps {}

const IssueStatusSelect = ({
  disabled,
  formName,
  issueStatus,
  onChange,
  statuses
}: IIssueStatusSelectProps) => {
  const options = statuses.map<ISelectOption>(status => ({
    label: {
      defaultMessage: status,
      id: `detail_page.issues.statuses.${status}`
    },
    value: status,
    hidden: status === "New"
  }));

  return (
    <SelectInputContainer
      defaultValue={issueStatus}
      disabled={disabled}
      formName={formName}
      inputName="status"
      options={options}
      theme={Themes.Light}
      onChange={onChange}
    />
  );
};

export default IssueStatusSelect;
