// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import React, { memo, ReactNode } from "react";
import { Link, matchPath, useLocation, useRouteMatch } from "react-router-dom";

import WidgetsRoutes from "features/detailpage/models/WidgetsRoutes";

import "./RibbonToggle.less";

interface IRibbonToggleInjectedProps {
  active: boolean;
  url: string;
}

interface IRibbonToggleOwnProps {
  children?: ReactNode;
  dataQa?: string;
  to: WidgetsRoutes;
  assetId: string;
}

type RibbonToggleProps = IRibbonToggleInjectedProps & IRibbonToggleOwnProps;

const RibbonToggle = memo(
  ({ children, dataQa, to, active, url, assetId }: RibbonToggleProps) => {
    return (
      <Link
        to={{
          pathname: `${url}/${to}`,
          search: new SearchParams({ assetId }).toString()
        }}
      >
        <div
          className={`asset-ribbon-toggle${active ? " active" : ""}`}
          data-qa={dataQa}
        >
          {children}
        </div>
      </Link>
    );
  }
);

export default (props: IRibbonToggleOwnProps) => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const { to } = props;
  const active = matchPath(pathname, `${url}/${to}`) !== null;
  return <RibbonToggle {...props} active={active} url={url} />;
};
