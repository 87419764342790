// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import Processing from "components/common/Processing";
import Data, { Statuses } from "core/data/models/Data";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import IVoltageData from "../models/IVoltageData";
import "./BatteryVoltage.less";
import BatteryVoltageChart from "./BatteryVoltageChart";
import BatteryVoltageTitle from "./BatteryVoltageTitle";

interface IBatteryVoltageProps {
  voltageData: Data<IVoltageData>;
}

const BatteryVoltage = ({ voltageData }: IBatteryVoltageProps) => (
  <div className="battery-voltage container">
    <div className="bootstrap-row">
      <div className="col-24">
        {voltageData && voltageData.status === Statuses.Loading && (
          <BatteryVoltageProcessing />
        )}
        {voltageData && voltageData.status === Statuses.Succeeded && (
          <BatteryVoltageSucceeded voltageData={voltageData.data} />
        )}
        {voltageData && voltageData.status === Statuses.Failed && (
          <BatteryVoltageFailed />
        )}
        {voltageData && voltageData.status === Statuses.NotFound && (
          <BatteryVoltageNotFound />
        )}
      </div>
    </div>
  </div>
);

const BatteryVoltageProcessing = () => (
  <div className="battery-voltage container">
    <Processing />
  </div>
);

interface IBatteryVoltageSucceededProps {
  voltageData: IVoltageData;
}

const BatteryVoltageSucceeded = ({
  voltageData
}: IBatteryVoltageSucceededProps) => (
  <div className="chart">
    <BatteryVoltageTitle />
    <BatteryVoltageChart voltageData={voltageData} />
  </div>
);

const BatteryVoltageFailed = () => (
  <div className="battery-voltage container">
    <div className="failed">
      <Icon name="warning" />
      <FormattedMessage
        defaultMessage="Data loading failed"
        id="detail_page.parameters.battery_voltage.data_loading_failed"
      />
    </div>
  </div>
);

const BatteryVoltageNotFound = () => (
  <div className="battery-voltage container">
    <div className="no-data">
      <Icon name="warning" />
      <FormattedMessage
        defaultMessage="No data found"
        id="detail_page.parameters.battery_voltage.no_data_found"
      />
    </div>
  </div>
);

export default BatteryVoltage;
