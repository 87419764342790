// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";
import { IState } from "reducers/Index";

const getAllOrganizationsCheckedSelector = createSelector(
  (state: IState) => state.notifications.allOrganizationsChecked,
  allOrganizationsChecked => allOrganizationsChecked
);

export default getAllOrganizationsCheckedSelector;
