// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Action, Reducer } from "redux/index";

import IFormState from "common/form/models/IState";
import formReducer from "common/form/reducers/FormReducer";

export interface IState extends IFormState {}

const appReducer: Reducer<IState> = (
  state = {
    forms: null
  },
  action: Action
) => {
  state = formReducer(state, action) as IState;
  return state;
};

export default appReducer;
