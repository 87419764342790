// Copyright 2016-2022 Hitachi Energy. All rights reserved.

const iso8601DateRegex: RegExp =
  /^\d{4}-(0[1-9]|1[0-2])-([12]\d|0[1-9]|3[01])T([01]\d|2[0-3]):[0-5]\d:[0-5]\d(\.\d+)?([zZ]|[+-]([01]\d|2[0-3]):[0-5]\d)?$/;

const isValidDateString = (value: string | number | boolean | Date) => {
  return typeof value === "string" && iso8601DateRegex.test(value);
};

export default isValidDateString;
