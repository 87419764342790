// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { colorStatusRed } from "@pg/common/build/styles/ColorVariables";
import EmptyState from "common/empty/EmptyState";
import FilterBar, { SelectedFilters } from "common/FilterBar";
import { isNil } from "lodash";
import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { spacingXXXLarge } from "styles/StyleVariables";
import FamilyAnalyticsWidgetContainer from "../containers/FamilyAnalyticsWidgetContainer";
import "./FamilyAnalytics.less";

interface IFamilyAnalyticsProps {
  className?: string;
}

const FamilyAnalytics = ({ className }: IFamilyAnalyticsProps) => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>();
  const [errorCode, setErrorCode] = useState<string>();
  const [dataPointsLimit, setDataPointsLimit] = useState<number>();

  const showScatterPlot = useMemo(
    () =>
      !!selectedFilters?.selects.length &&
      !(
        selectedFilters?.selects.length === 1 &&
        selectedFilters?.selects[0].Id === "AssetStatus"
      ),
    [selectedFilters?.selects]
  );

  const showEmptyState = useMemo(
    () =>
      !selectedFilters?.selects.length ||
      (selectedFilters?.selects.length === 1 &&
        selectedFilters?.selects[0].Id === "AssetStatus"),
    [selectedFilters?.selects]
  );

  const showErrorMessage = useMemo(
    () => !isNil(errorCode) && !!selectedFilters?.selects.length,
    [errorCode, selectedFilters?.selects.length]
  );

  const handleFilter = useCallback((filters: SelectedFilters) => {
    setSelectedFilters(filters);
  }, []);

  const handleSuccess = useCallback(() => {
    setErrorCode(undefined);
    setDataPointsLimit(undefined);
  }, []);

  const handleError = useCallback(
    (errorCode: string, dataPointsLimit: number) => {
      setErrorCode(errorCode);
      setDataPointsLimit(dataPointsLimit);
    },
    []
  );

  return (
    <div className={`${className} family-analytics-page`}>
      <FilterBar
        onFilter={handleFilter}
        namespace="family-analytics"
        useClearAll
        useAssetOrganizationWithRegionAndLocation
        useAssetTypeWithSubType
        useAssetManufacturerWithModel
        useAssetVoltage
        useAssetAge
        useAssetStatus
      >
        <div className="container">
          {showScatterPlot && (
            <FamilyAnalyticsWidgetContainer
              onSuccess={handleSuccess}
              onError={handleError}
              selectedFilters={selectedFilters}
              showErrorMessage={showErrorMessage}
              errorCode={errorCode}
              dataPointsLimit={dataPointsLimit}
            />
          )}
          {showEmptyState && (
            <EmptyState
              id="family_analytics.empty_filters.label"
              iconName="speed"
              iconClassName="family-analytics-icon"
            />
          )}
        </div>
      </FilterBar>
    </div>
  );
};

export default styled(FamilyAnalytics)`
  .data-point-limit-exited {
    .empty-description {
      color: ${colorStatusRed};
      padding: 0 ${spacingXXXLarge} 0 ${spacingXXXLarge};
    }
  }
`;
