// Copyright 2016-2022 Hitachi Energy. All rights reserved.
export default class Guid {
  private static guids: string[] = [];

  static getGuid(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      const r = Math.floor(Math.random() * 16);
      /* tslint:disable-next-line:no-bitwise */
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  static getUniqGuid(): string {
    let i: number;
    let guid: string = null;
    do {
      guid = Guid.getGuid();
      i = Guid.guids.indexOf(guid);
      if (i < 0) Guid.guids.push(guid);
    } while (i > 0);

    return guid;
  }
}
