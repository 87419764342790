// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IChart, TimeFrames } from "@apm/widgets/build/widgets/ParameterCharts";
import SearchParams from "@pg/common/build/models/SearchParams";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IntlShape } from "react-intl";
import { config } from "utils/AppConfig";
import IGas from "../models/IGas";
import mapToChart from "./mapToChart";

const getChart = (
  intl: IntlShape,
  gasName: string,
  gasStandard: string,
  gasStandardRevision: string,
  timeframe: TimeFrames,
  search: string
) =>
  new Promise<IChart>((resolve, reject) => {
    const searchParams = new SearchParams(search);
    if (timeframe === "30days") searchParams.append("dgaDays", "30");
    if (timeframe === "10days") searchParams.append("dgaDays", "10");

    const url = UrlService.getApiUrl(
      config.api.detailPage.dissolvedGasAdvancedAnalysisUrl,
      { gasName },
      searchParams.toArray()
    );

    EndpointService.getJson<IGas>(
      url,
      (request, data) => {
        const chart = mapToChart(
          intl,
          data,
          gasStandard,
          gasStandardRevision,
          gasName
        );
        resolve(chart);
      },
      () => {
        reject();
      }
    );
  });

export default getChart;
