// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IStation } from "@apm/widgets/build/widgets/SpatialView";
import { notifications } from "@pg/common/build/components/Notifications";
import SearchParams from "@pg/common/build/models/SearchParams";
import { routesConfig } from "core/app/components/router/RouterProvider";
import Data from "core/data/models/Data";
import { isNil } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { config } from "utils/AppConfig";
import { UserRoles } from "../../../../core/app/components/auth/Authorization";
import ICustomer from "../../../../core/app/models/ICustomer";
import { IUser } from "../../../../core/app/reducers/settings/UserReducer";
import AuthorizationService from "../../../../core/app/services/AuthorizationService";

const formatParam = (value: string | null) =>
  isNil(value) ? null : `"${value}"`;

const useRiskMap = (
  loadStationRisk: () => void,
  removeStationRisk: () => void,
  customer: Data<ICustomer>,
  user: Data<IUser>
) => {
  const intl = useIntl();
  const history = useHistory();

  const isLimitedEngineer = useMemo(
    () =>
      AuthorizationService.isAuthorized(user.data as IUser, [
        UserRoles.LimitedEngineer
      ]),
    [user.data]
  );

  useEffect(() => {
    loadStationRisk();
    return () => {
      removeStationRisk();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStationClick = useCallback(
    (station: IStation) => {
      const isPbiAvailable = !!customer.data?.PowerBiEmbeddedPremiumWorkspaceId;

      if (!isPbiAvailable || isLimitedEngineer) {
        const searchParams = new SearchParams();
        searchParams.append(
          "f_ao",
          `[${formatParam(station.organization)},${formatParam(
            station.region
          )},${formatParam(station.location)}]`
        );
        searchParams.append("f_as", `[${formatParam("InService")}]`);
        history.push({
          pathname: routesConfig.assets,
          search: searchParams.toString()
        });
      } else {
        const searchParams = new SearchParams();
        if (!isNil(station.organization))
          searchParams.append("organization", station.organization);
        if (!isNil(station.region))
          searchParams.append("region", station.region);
        if (!isNil(station.location))
          searchParams.append("location", station.location);
        searchParams.append("f_as", `[${formatParam("InService")}]`);
        const reportName = config.UI.homepage.stationRisk.stationReportName;
        history.push({
          pathname: "/Reports/" + encodeURIComponent(reportName),
          search: searchParams.toString()
        });
      }
    },
    [customer.data, history, isLimitedEngineer]
  );

  const handleClusterClick = (cluster: IStation[]) => {
    const isPbiAvailable = !!customer.data?.PowerBiEmbeddedPremiumWorkspaceId;

    if (!isPbiAvailable) {
      const searchParams = new SearchParams();
      cluster.forEach(
        (s) =>
          searchParams.append(
            "f_ao",
            `[${formatParam(s.organization)},${formatParam(
              s.region
            )},${formatParam(s.location)}]`
          ),
        searchParams.append("f_as", `[${formatParam("InService")}]`)
      );
      history.push({
        pathname: routesConfig.assets,
        search: searchParams.toString()
      });
    } else {
      notifications.info({
        message: intl.formatMessage({
          id: "homepage.station_risk.map.cluster.drilling.disallowed",
          defaultMessage: "Drilling is allowed for single station only"
        })
      });
    }
  };

  return {
    handleStationClick,
    handleClusterClick
  };
};

export default useRiskMap;
