// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import { Button, Layout, Typography } from "antd";
import { UserRoles } from "core/app/components/auth/Authorization";
import { getUserSelector } from "core/app/reducers/AppReducer";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";
import Data from "core/data/models/Data";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { IState } from "reducers/Index";
import styled from "styled-components";
import { colorGray0, colorWidgetBackground } from "styles/ColorVariables";
import {
  spacingMedium,
  spacingXLarge,
  spacingXXLarge,
  spacingXXXLarge
} from "styles/StyleVariables";
import SimulationModalContext from "../contexts/SimulationModalContext";
import ISimulationSummary from "../models/ISimulationSummary";
import SimulationInfo from "./SimulationInfo";
import SimulationMessage from "./SimulationMessage";
import SimulationResults from "./SimulationResults";
import SimulationSettings from "./SimulationSettings";

const { Title } = Typography;
const { Footer, Sider, Content } = Layout;

interface ISimulationModalContentOwnProps {
  className?: string;
}

interface ISimulationModalState {
  user: Data<IUser>;
}

type ISimulationModalContentProps = ISimulationModalState &
  ISimulationModalContentOwnProps;

const SimulationModalContent = ({
  className,
  user
}: ISimulationModalContentProps) => {
  const { simulations, updateSearchParams, simulationConfig } = useContext(
    SimulationModalContext
  );
  const location = useLocation();

  const [activeSimulation, setActiveSimulation] = useState<string>(undefined);
  const [addMode, setAddMode] = useState<boolean>(false);

  const isReadOnlyMode = useMemo(() => {
    return !AuthorizationService.isAuthorized(user.data, [
      UserRoles.Administrator,
      UserRoles.Engineer,
      UserRoles.LimitedEngineer
    ]);
  }, [user.data]);

  useEffect(() => {
    const simulationIdFromUrl = new SearchParams(location.search).get(
      "simulationId"
    );
    if (simulationIdFromUrl)
      setActiveSimulation(
        simulations.some((s) => s.Id.toString() === simulationIdFromUrl)
          ? simulationIdFromUrl
          : undefined
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleTabChange = useCallback(
    (activeKey: string) => {
      updateSearchParams(activeKey);
    },
    [updateSearchParams]
  );

  const activeSimulationContent = useMemo(() => {
    const simulation = simulations.find(
      (s) => s.Id.toString() === activeSimulation
    );
    return (
      <>
        {simulations.length > 0 ? (
          <SimulationResults
            simulationId={activeSimulation}
            simulationName={simulation?.Name}
            status={simulation?.Status}
            beginValue={simulation?.LoadParameterRange?.BeginValue}
            endValue={simulation?.LoadParameterRange?.EndValue}
          />
        ) : (
          <SimulationMessage
            messages={[
              {
                defaultMessage: "No simulations available.",
                id: "detail_page.risk_trend.simulation_modal.message.no_simulations"
              },
              {
                defaultMessage: "Start by creating a new one.",
                id: "detail_page.risk_trend.simulation_modal.message.create_new_one"
              }
            ]}
          />
        )}
      </>
    );
  }, [activeSimulation, simulations]);

  const simulationsList = useMemo(() => {
    return (
      <>
        {simulations.map((item: ISimulationSummary, i) => (
          <SimulationInfo
            simulation={item}
            active={item.Id.toString() === activeSimulation}
            disabled={addMode}
            onClick={() => handleTabChange(item.Id.toString())}
            key={i}
            isReadOnlyMode={isReadOnlyMode}
          />
        ))}
      </>
    );
  }, [activeSimulation, addMode, handleTabChange, simulations, isReadOnlyMode]);

  return (
    <Layout className={className}>
      <Sider width={320}>
        <Layout className="simulation-list">
          <Title level={4} className="simulation-list-title">
            <FormattedMessage
              defaultMessage="Simulations"
              id="detail_page.risk_trend.simulation_modal.label.simulations"
            />
          </Title>

          <Content
            style={{
              overflow: "auto"
            }}
          >
            {simulationsList}
          </Content>
          {!isReadOnlyMode && (
            <Footer>
              <Button
                type="primary"
                onClick={() => setAddMode(true)}
                disabled={
                  addMode ||
                  simulations?.length >= simulationConfig.SimulationLimit
                }
                className="new-simulation-button"
              >
                <FormattedMessage
                  defaultMessage="+ New Simulation"
                  id="detail_page.risk_trend.simulation_modal.action.new_simulation"
                />
              </Button>
            </Footer>
          )}
        </Layout>
      </Sider>
      <Content className="main-content">
        {addMode ? (
          <SimulationSettings
            onCancel={() => setAddMode(false)}
            onSuccessfulRun={() => setAddMode(false)}
          />
        ) : (
          activeSimulationContent
        )}
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: IState): ISimulationModalState => ({
  user: getUserSelector(state)
});

const SimulationModalContentContainer = connect(mapStateToProps)(
  SimulationModalContent
);

const StyledSimulationModalContent = styled(SimulationModalContentContainer)`
  height: 100%;

  .ant-layout,
  .ant-layout-footer,
  .ant-layout-header,
  .ant-layout-content {
    background-color: transparent;
  }

  .simulation-list-title {
    padding: 0px ${spacingXLarge} ${spacingXLarge} ${spacingXXXLarge};
  }

  .simulation-list {
    height: 100%;
    background-color: ${colorWidgetBackground};
    padding: ${spacingXXLarge} 0px;

    .ant-layout-footer {
      padding: ${spacingMedium} ${spacingXXXLarge};

      .new-simulation-button {
        width: 100%;
      }
    }
  }

  .main-content {
    background-color: ${colorGray0};
    padding: ${spacingXXLarge};
    padding-right: ${spacingXXXLarge};
  }
`;

export default StyledSimulationModalContent;
