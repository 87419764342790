// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IChart } from "@apm/widgets/build/widgets/ParameterCharts";
import { ReactNode, useEffect, useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";
import chartSettings from "../constants/chartSettings";
import { OilTest } from "../models/IOilSettings";
import { IChartsCollection } from "../models/ISOTData";
import mapToChart from "../utils/mapToChart";

const getDisplayName = (intl: IntlShape, oilTest: OilTest): ReactNode => (
  <span>
    {intl.formatMessage({
      defaultMessage: `${oilTest}`,
      id: `detail_page.widgets.analytics.transformers.sot.${oilTest}`
    })}
  </span>
);

const useSOT = (
  assetId: string,
  loadSOTData: (assetId: string) => void,
  removeSOTData: (assetId: string) => void,
  chartsData: IChartsCollection
) => {
  const intl = useIntl();

  const oils = useMemo<IChart[]>(() => {
    if (!chartsData) return;

    const charts = Object.keys(chartSettings)
      .map((oilTest) =>
        mapToChart(
          chartsData[oilTest],
          oilTest as OilTest,
          getDisplayName(intl, oilTest as OilTest),
          intl
        )
      )
      .filter((p) => p.values);

    return charts;
  }, [chartsData, intl]);

  useEffect(() => {
    loadSOTData(assetId);
    return () => {
      removeSOTData(assetId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  return {
    oils
  };
};

export default useSOT;
