// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { FormattedMessage } from "react-intl";

interface IAuthorizationMessageProps {
  message: string | undefined;
}

const AuthorizationMessage = (props: IAuthorizationMessageProps) => {
  const { message } = props;

  return (
    <div>
      {message ? (
        message
      ) : (
        <FormattedMessage
          id="global.authorization_failed"
          defaultMessage="No permission to this content"
        />
      )}
    </div>
  );
};

export default AuthorizationMessage;