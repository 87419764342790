// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import BeginningOfMessages from "./BeginningOfMessages";

const Message = () => (
  <div className="message-box">
    <div className="right app-event question on-prem">
      <div className="img">
        <Icon name="info" />
      </div>
      <div data-qa="noEmailWarning">
        <BeginningOfMessages />
        <FormattedMessage
          id="notifications.noemail.message.on_premise"
          defaultMessage="Please configure your profile or ask for help from your administrator."
        />
      </div>
    </div>
  </div>
);

export default Message;
