// Copyright 2016-2022 Hitachi Energy. All rights reserved.
const deviceCondition = [
  "normal",
  "broken",
  "missing",
  "cannot see",
  "cannot interpret"
];

export default deviceCondition;
