// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { connect } from "react-redux";

import AntdProvider, {
  IAntdProviderData
} from "core/app/components/AntdProvider";
import { IState } from "reducers/Index";

function mapStateToProps(state: IState): IAntdProviderData {
  return {
    locale: state.app.locale
  };
}

function mapDispatchToProps(): {} {
  return {};
}

const AntdProviderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AntdProvider);

export default AntdProviderContainer;
