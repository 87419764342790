// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import moment from "moment";
import React from "react";
import { FormattedMessage, FormattedDate, useIntl } from "react-intl";

import { IAction } from "features/issues/reducers/IssuesReducer";
import LabeledRow from "./LabeledRow";

interface IActionsListProps {
  actions: IAction[];
}

const ActionsList = ({ actions }: IActionsListProps) => {
  const intl = useIntl();
  return (
    <>
      {(actions || []).map((a, i) => (
        <div key={i} className="actions-list-item">
          <LabeledRow
            message={{
              defaultMessage: "Status",
              id: "issues_page.slide_panel.actions.status"
            }}
          >
            {a.Status ? (
              <FormattedMessage
                defaultMessage={a.Status}
                id={`issue.action.status.${a.Status}`}
              />
            ) : (
              "-"
            )}
          </LabeledRow>
          <LabeledRow
            message={{
              defaultMessage: "Schedule date",
              id: "issues_page.slide_panel.actions.schedule_date"
            }}
          >
            {a.DueDate ? (
              <FormattedDate value={moment(a.DueDate).local().toDate()} />
            ) : (
              "-"
            )}
          </LabeledRow>
          {a.ExternalLink ? (
            <a
              className="anchor"
              title={intl.formatMessage({
                id: "issues_page.slide_panel.actions.go_to_eam",
                defaultMessage: "Go to EAM"
              })}
              href={a.ExternalLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              {a.Summary}
            </a>
          ) : (
            a.Summary
          )}
        </div>
      ))}
    </>
  );
};

export default ActionsList;
