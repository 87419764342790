// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import moment from "moment";
import { IntlShape, useIntl } from "react-intl";

export enum Formats {
  Short,
  Long
}

export interface IDateDifferenceProps {
  format?: Formats;
  value: moment.MomentInput;
}

const DateDifference = ({ format, value }: IDateDifferenceProps) => {
  const intl = useIntl();
  const formattedValue = DateDifferenceService.format(value, intl, format);
  return <span>{formattedValue}</span>;
};

export class DateDifferenceService {
  static format(
    value: moment.MomentInput,
    intl: IntlShape,
    format = Formats.Short,
    now: moment.Moment = moment()
  ): string {
    if (value === undefined || value == null) return "-";

    const date = moment(value);

    const units: {
      name: moment.unitOfTime.Diff;
      trigger: number;
    }[] = [
      { name: "years", trigger: 3 },
      { name: "months", trigger: 3 },
      { name: "weeks", trigger: 3 },
      { name: "days", trigger: 3 },
      { name: "hours", trigger: 3 },
      { name: "minutes", trigger: 1 },
      { name: "seconds", trigger: 0 }
    ];

    for (const unit of units) {
      const diff = now.diff(date, unit.name);
      if (diff >= unit.trigger) {
        const formattedNumber = intl.formatNumber(diff);
        return intl.formatMessage(
          {
            defaultMessage: `{value} ${unit.name}`,
            id:
              format === Formats.Short
                ? `formatters.date_difference.short.${unit.name}`
                : `formatters.date_difference.long.${unit.name}`
          },
          {
            value: formattedNumber
          }
        );
      }
    }

    return "-";
  }
}

export default DateDifference;
