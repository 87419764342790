// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Icon } from "@pg/common";
import { Button, Form, Input } from "antd";
import { TypedValue } from "features/detailpage/models/IParameter";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { spacingXSmall } from "styles/StyleVariables";
import isValidDateString from "utils/isValidDateString";
import AssetNameplateContext from "../contexts/AssetModalContext";
import FormContext from "../contexts/FormContext";
import { IFormItem } from "../hooks/useForms";
import FieldTypes from "../models/FieldTypes";
import IField from "../models/IField";
import IFormValues from "../models/IFormValues";
import CustomSections from "./CustomSections";
import NameplateAttribute from "./NameplateAttribute";

interface INameplateProps {
  className?: string;
  formInfo: IFormItem;
}

enum AttributeActions {
  AddAttribute = "AddAttribute",
  EditAttribute = "EditAttribute"
}

const Nameplate = ({ className, formInfo }: INameplateProps) => {
  const {
    assetId,
    assetNameplate,
    initialAttributeKeys,
    validateMessages,
    addAttribute,
    editAttribute,
    deleteAttribute,
    changeNameplateField,
    registerForm,
    updateFormItemTouchedInfo
  } = useContext(AssetNameplateContext);

  const form = Form.useForm<IFormValues>(formInfo?.form)[0];

  useEffect(() => {
    registerForm(formInfo.formConfiguration.formKey, form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const [nameplateAttributeModalVisible, setNameplateAttributeModalVisible] =
    useState<boolean>(false);
  const [editedAttributeName, setEditedAttributeName] = useState<string>(null);
  const [editedAttributeValue, setEditedAttributeValue] =
    useState<string>(null);
  const [isInitialAttribute, setIsInitialAttribute] = useState<boolean>(false);
  const [attributeAction, setAttributeAction] = useState<AttributeActions>(
    AttributeActions.AddAttribute
  );
  const [forbiddenAttributeNames, setForbiddenAttributeNames] =
    useState<string[]>();

  const intl = useIntl();

  useEffect(() => {
    if (formInfo.invalid) {
      form.validateFields();
    }
  }, [form, formInfo.invalid]);

  useEffect(() => {
    const forbiddenNames = Object.keys(assetNameplate);
    forbiddenNames.push("AssetId");
    forbiddenNames.push(...Object.keys(assetNameplate.Attributes));
    setForbiddenAttributeNames(forbiddenNames);
  }, [assetNameplate]);

  const handleNameplateChange = useCallback(
    async (changedValues: Partial<IFormValues>, values: IFormValues) => {
      if (!changedValues) return;
      Object.entries(changedValues).forEach((entry) => {
        changeNameplateField(entry[0], entry[1]);
      });
      updateFormItemTouchedInfo(formInfo.formConfiguration.formKey);
    },
    [
      changeNameplateField,
      updateFormItemTouchedInfo,
      formInfo.formConfiguration.formKey
    ]
  );

  const handleAttributeSave = useCallback(
    (previousAttributeName, attributeName, attributeValue) => {
      if (attributeAction === AttributeActions.AddAttribute)
        addAttribute(attributeName, attributeValue);
      else editAttribute(previousAttributeName, attributeName, attributeValue);
      form.setFieldsValue({ [attributeName]: attributeValue });
      updateFormItemTouchedInfo(formInfo.formConfiguration.formKey);
    },
    [
      addAttribute,
      editAttribute,
      attributeAction,
      updateFormItemTouchedInfo,
      form,
      formInfo.formConfiguration.formKey
    ]
  );

  const handleAttributeDelete = useCallback(
    (attributeName: string) => {
      deleteAttribute(attributeName);
      updateFormItemTouchedInfo(formInfo.formConfiguration.formKey);
    },
    [
      deleteAttribute,
      updateFormItemTouchedInfo,
      formInfo.formConfiguration.formKey
    ]
  );

  const handleAttributeModalOpen = useCallback(
    (
      attributeKey: string,
      attributeValue: string,
      action: AttributeActions
    ) => {
      setEditedAttributeName(attributeKey);
      setEditedAttributeValue(attributeValue);
      if (initialAttributeKeys.includes(attributeKey))
        setIsInitialAttribute(true);
      else setIsInitialAttribute(false);
      setAttributeAction(action);
      setNameplateAttributeModalVisible(true);
    },
    [initialAttributeKeys]
  );

  const handleModalClose = useCallback(() => {
    setNameplateAttributeModalVisible(false);
  }, []);
  const sectionFields = useMemo(() => {
    const fields: IField[] = [];
    formInfo.formConfiguration.sections.forEach((sectionElement) => {
      if (sectionElement.fields) fields.push(...sectionElement.fields);
      if (sectionElement.sectionList)
        sectionElement.sectionList.forEach((section) => {
          if (section.fields) fields.push(...section.fields);
        });
    });
    return fields;
  }, [formInfo.formConfiguration.sections]);

  const dataTypes: { [name: string]: TypedValue } = useMemo(() => {
    const types: { [name: string]: TypedValue } = {};
    sectionFields.forEach((field) => {
      types[field.fieldKey] = field.dataType;
    });
    return types;
  }, [sectionFields]);

  const fieldTypes: { [name: string]: FieldTypes } = useMemo(() => {
    const types: { [name: string]: FieldTypes } = {};
    sectionFields.forEach((field) => {
      types[field.fieldKey] = field.fieldType;
    });
    return types;
  }, [sectionFields]);

  const getInitialValue = useCallback(
    (fieldName: string) => {
      let fieldValue = null;

      if (fieldName === "AssetId") {
        fieldValue = assetId;
      } else {
        const entry = Object.entries(
          fieldTypes[fieldName] === FieldTypes.NameplateField
            ? assetNameplate
            : assetNameplate.Attributes
        ).find((f) => f[0] === fieldName);
        if (!entry || !entry[1]) return null;
        fieldValue = entry[1];
      }

      if (dataTypes[fieldName] === "Bool" && typeof fieldValue === "boolean") {
        return fieldValue.toString();
      }
      if (
        dataTypes[fieldName] === "DateTime" &&
        typeof fieldValue === "string"
      ) {
        return isValidDateString(fieldValue) ? moment(fieldValue) : null;
      } else if (
        dataTypes[fieldName] === "Decimal" &&
        typeof fieldValue === "number"
      ) {
        return fieldValue;
      } else if (
        dataTypes[fieldName] === "String" &&
        typeof fieldValue === "string"
      ) {
        return fieldValue;
      }

      return null;
    },
    [assetNameplate, fieldTypes, dataTypes, assetId]
  );

  const attributes = useMemo(() => {
    return (
      <div>
        {Object.entries(assetNameplate.Attributes).map((attribute) => {
          const [key, value] = attribute;
          return (
            <Form.Item
              label={intl.formatMessage({
                id: key,
                defaultMessage: key
              })}
              name={key}
              className="form-field"
              key={`${key}${value}`}
              initialValue={value}
            >
              <Input
                addonBefore={
                  <div className="attribute-actions">
                    <Icon
                      name="edit"
                      onClick={() => {
                        handleAttributeModalOpen(
                          key,
                          value as string,
                          AttributeActions.EditAttribute
                        );
                      }}
                    />
                  </div>
                }
                disabled
              />
            </Form.Item>
          );
        })}
        <Button
          type="text"
          onClick={() => {
            handleAttributeModalOpen(null, null, AttributeActions.AddAttribute);
          }}
          className="add-to-list"
        >
          <FormattedMessage
            defaultMessage="+ Add custom attribute"
            id="configuration_tool.action.add_custom_attribute_button"
          />
        </Button>
      </div>
    );
  }, [assetNameplate.Attributes, handleAttributeModalOpen, intl]);

  return (
    <>
      {assetNameplate && (
        <Form<IFormValues>
          form={form}
          onValuesChange={handleNameplateChange}
          layout="horizontal"
          colon={false}
          className={className}
          validateMessages={validateMessages}
          requiredMark={false}
        >
          <FormContext.Provider value={form}>
            <CustomSections
              configuration={formInfo.formConfiguration.sections}
              getInitialValue={getInitialValue}
              attributes={attributes}
            />
            <NameplateAttribute
              visible={nameplateAttributeModalVisible}
              forbiddenAttributeNames={forbiddenAttributeNames}
              onCancel={handleModalClose}
              onSave={handleAttributeSave}
              attributeName={editedAttributeName}
              attributeValue={editedAttributeValue}
              canEditAttributeName={!isInitialAttribute}
              onDelete={
                attributeAction === AttributeActions.EditAttribute &&
                !isInitialAttribute
                  ? handleAttributeDelete
                  : null
              }
            />
          </FormContext.Provider>
        </Form>
      )}
    </>
  );
};

const StyledNameplate = styled(Nameplate)`
  .attribute-actions {
    margin: ${spacingXSmall};
    display: flex;
    :hover {
      cursor: pointer;
    }
  }
`;

export default StyledNameplate;
