// Copyright 2016-2022 Hitachi Energy. All rights reserved.

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { dataKey } from "../actions/loadAssetRiskTrend";
import IAssetRiskTrendItem from "../models/IAssetRiskTrendItem";

const getAssetRiskTrendSelector = createSelector<
  IState,
  Data<IAssetRiskTrendItem[]>,
  Data<IAssetRiskTrendItem[]>
>(
  (state: IState) => state.data[dataKey],
  (assetRiskTrend) => assetRiskTrend
);

export default getAssetRiskTrendSelector;
