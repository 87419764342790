// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React, { ReactNode, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import "./LTCPercentageUsedInfo.less";

interface IPercentageWrapperBoxProps {
  colorClassName?: string;
  children?: ReactNode;
}

const PercentageWrapperBox = ({
  children,
  colorClassName
}: IPercentageWrapperBoxProps) => (
  <div className={`shadow-sm p-3 ${colorClassName} rounded`}>{children}</div>
);

interface ILTCPercentageUsedInfo {
  value: number;
  warningThreshold: number;
  alarmThreshold: number;
}

const LTCPercentageUsedInfo = ({
  value,
  warningThreshold,
  alarmThreshold
}: ILTCPercentageUsedInfo) => {
  const bgColorClass = useMemo(() => {
    if (value >= warningThreshold * 100 && value < alarmThreshold * 100) {
      return "ltc-bg-warning";
    } else if (value >= alarmThreshold * 100) {
      return "ltc-bg-alarm";
    }

    return "bg-white";
  }, [value, warningThreshold, alarmThreshold]);

  return (
    <div className="ltc-percentage-info">
      <div className="ltc-percentage-info__title">
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.ltc.percentage_used"
          defaultMessage="Percentage Used"
        />
      </div>
      <PercentageWrapperBox colorClassName={bgColorClass}>
        <div className="ltc-percentage-info__percent">
          <FormattedMessage
            defaultMessage="{value}%"
            id="detail_page.widgets.analytics.transformers.ltc.percentage_used_value"
            values={{
              value
            }}
          />
        </div>
      </PercentageWrapperBox>
    </div>
  );
};

export default LTCPercentageUsedInfo;
