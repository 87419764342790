// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";
import { IState as IRootState } from "reducers/Index";

const getAgingOperationsSelector = createSelector(
  (state: IRootState) => state.detailPage.agingOperations,
  agingOperations => agingOperations
);

export default getAgingOperationsSelector;
