// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import React from "react";
import { FormattedMessage } from "react-intl";

import "./DuvalTriangleNoteHeader.less";

const DuvalTriangleNoteHeader = () => {
  return (
    <div className="duval-triangles-note-header">
      <Icon className="duval-triangle-warning-icon" name="error" />
      <span>
        <FormattedMessage
          id="detail_page.widgets.analytics.transformers.DuvalTriangle.important_note.title"
          defaultMessage="Important Note"
        />
      </span>
    </div>
  );
};

export default DuvalTriangleNoteHeader;
