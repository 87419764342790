// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";

import Message from "core/app/components/Message";
import Site from "core/app/components/Site";

export enum AppStatuses {
  Authenticating,
  Error,
  Forbidden,
  Loading,
  Started
}

export interface IAppData {
  appStatus: AppStatuses;
}

export interface IAppProps extends IAppData {}

const App = ({ appStatus }: IAppProps) => {
  return (
    <div>
      {appStatus === AppStatuses.Authenticating && (
        <Message>Redirecting to logging page ...</Message>
      )}
      {appStatus === AppStatuses.Error && <Message>Application error</Message>}
      {appStatus === AppStatuses.Forbidden && (
        <Message>
          Access Forbidden
          <br />
          <i>please check user configuration (roles & groups)</i>
        </Message>
      )}
      {appStatus === AppStatuses.Loading && <Message>Loading ...</Message>}
      {appStatus === AppStatuses.Started && <Site />}
    </div>
  );
};

export default App;
