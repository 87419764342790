// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import TooltipWrapper from "common/tooltipwrapper/TooltipWrapper";

import "./Risk.less";

interface IRiskProps {
  risk: string;
  Tooltip?: React.ComponentType;
}

const Risk = (props: IRiskProps) => {
  const { risk, Tooltip } = props;
  const transformedRisk = risk.toLowerCase();

  return Tooltip
    ? getColumnWithTooltip(transformedRisk, Tooltip)
    : getColumn(transformedRisk);
};

export default Risk;

// #region Functions

function getColumn(transformedRisk: string): JSX.Element {
  return (
    <div className="column risk">
      <div
        className={`asset-risk-bg column-tooltip ${transformedRisk}`}
        data-qa="risk-color"
      />
    </div>
  );
}

function getColumnWithTooltip(
  transformedRisk: string,
  Tooltip: React.ComponentType
): JSX.Element {
  return (
    <div className="column risk">
      <TooltipWrapper Tooltip={() => <Tooltip />}>
        <div
          className={`asset-risk-bg column-tooltip ${transformedRisk}`}
          data-qa="risk-color"
        />
      </TooltipWrapper>
    </div>
  );
}

// #endregion
