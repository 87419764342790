// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as d3 from "d3";
import { useLayoutEffect, useRef } from "react";
import { useIntl } from "react-intl";
import IDuvalPoint from "../models/IDuvalPoint";
import createDuvalPentagonAxis from "../utils/createDuvalPentagonAxis";
import createDuvalPentagonLabels from "../utils/createDuvalPentagonLabels";
import createDuvalPentagonPoints from "../utils/createDuvalPentagonPoints";
import createDuvalPentagonRegions from "../utils/createDuvalPentagonRegions";

interface IUseDuvalPentagonProps {
  points: IDuvalPoint[];
  type: number;
}

const useDuvalPentagon = ({ points, type }: IUseDuvalPentagonProps) => {
  const svgRef = useRef<SVGSVGElement>();
  const tooltipRef = useRef<HTMLDivElement>();
  const intl = useIntl();

  useLayoutEffect(() => {
    const root = d3.select(svgRef.current);
    const tooltip = d3.select(tooltipRef.current);
    root.selectAll("*").remove();
    const pentagons = createDuvalPentagonRegions({
      type,
      root
    });
    createDuvalPentagonAxis({ root });
    createDuvalPentagonLabels({ root, pentagons });
    createDuvalPentagonPoints({ root, tooltip, points, intl });
  }, [points, type, intl, svgRef]);

  return { svgRef, tooltipRef };
};

export default useDuvalPentagon;
