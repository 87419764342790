// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { LegendItem } from "common/ChartLegend";
import useLegend from "common/ChartLegend/hooks/useLegend";
import ILTCOperationsChartLegendItem from "features/detailpage/features/ltc/models/ILTCOperationsChartLegendItem";
import * as React from "react";
import "./LTCOperationsBarChartLegend.less";
import LTCOperationsBarChartLegendItem from "./LTCOperationsBarChartLegendItem";

interface ILTCOperationsBarChartLegendProps {
  plots: ILTCOperationsChartLegendItem[];
  series: string;
  onFocus?: (series: string, plot: string) => void;
  onRevert?: (series: string) => void;
  onToggle?: (series: string, plot: string) => void;
}

const LTCOperationsBarChartLegend = ({
  plots,
  series,
  onFocus,
  onRevert,
  onToggle
}: ILTCOperationsBarChartLegendProps) => {
  const items = plots.map<LegendItem>((p) => ({
    id: p.id,
    name: p.name,
    color: p.color,
    defaultSelected: !p.isHidden
  }));

  const { statuses } = useLegend(items);

  return (
    <div className="container ltc-operations chart-legend">
      <div className="bootstrap-row">
        {plots.map((l: ILTCOperationsChartLegendItem, index: number) => (
          <div
            className={`${
              index === 0 ? "col col-sm-10 col-md-14" : "col text-right"
            }`}
            key={l.id}
          >
            <LTCOperationsBarChartLegendItem
              item={l}
              status={statuses[l.id]}
              key={`${series}_${l.id}_${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LTCOperationsBarChartLegend;
