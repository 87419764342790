// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data, { Statuses } from "core/data/models/Data";
import update from "immutability-helper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import isDataAvailable from "../../../../../core/data/utils/isDataAvailable";
import useParametersTranslations from "../../../hooks/useParametersTranslations";
import IThresholdCorrection from "../../../models/IThresholdCorrection";
import IHeaterStatus from "../models/IHeaterStatus";
import AuxilariesParametersMapper from "../services/AuxilariesParametersMapper";

interface IUseHeaterStatusParametersOptions {
  assetId: string;
  heaterStatus: Data<IHeaterStatus>;
  loadHeaterStatus: (assetId: string) => void;
  acknowledge: (
    intl: IntlShape,
    parameterName: string,
    success: (correction: IThresholdCorrection) => void,
    error: () => void
  ) => void;
}

const useHeaterStatusParameters = ({
  assetId,
  heaterStatus,
  loadHeaterStatus,
  acknowledge
}: IUseHeaterStatusParametersOptions) => {
  const intl = useIntl();
  const [processing, setProcessing] = useState(false);
  const { translations } = useParametersTranslations();
  const [thresholdsCorrections, setThresholdsCorrections] = useState<
    {
      parameterName: string;
      correction: IThresholdCorrection;
    }[]
  >([]);

  const parametersGroup = useMemo(() => {
    if (!heaterStatus) return null;
    if (heaterStatus.status !== Statuses.Succeeded) return null;

    if (!thresholdsCorrections.length)
      return new AuxilariesParametersMapper(intl).map(heaterStatus.data);

    const updates: {
      parameterId: number;
      valueId: number;
      correction: IThresholdCorrection;
    }[] = [];

    heaterStatus.data.Parameters.forEach((parameter, i) => {
      parameter.Values?.forEach((value, j) => {
        const l = thresholdsCorrections.findIndex(
          (c) => c.parameterName === value.ExternalId
        );

        if (l >= 0) {
          updates.push({
            parameterId: i,
            valueId: j,
            correction: thresholdsCorrections[l].correction
          });
        }
      });
    });

    let newValue = heaterStatus.data;
    updates.forEach((u) => {
      newValue = update(newValue, {
        Parameters: {
          [u.parameterId]: {
            Values: {
              [u.valueId]: { UserThresholdCorrection: { $set: u.correction } }
            }
          }
        }
      });
    });

    return new AuxilariesParametersMapper(intl).map(newValue);
  }, [heaterStatus, intl, thresholdsCorrections]);

  const thresholdCorrectionParams = useMemo(() => {
    if (!heaterStatus) return null;
    if (heaterStatus.status !== Statuses.Succeeded) return null;
    return heaterStatus.data.Parameters.filter((p) =>
      p.Values.find((v) => v.DataType === "dot")
    ).map((p) => p.Name);
  }, [heaterStatus]);

  const updateThresholdsCorrections = useCallback(
    (parameterName: string, correction: IThresholdCorrection) => {
      const i = thresholdsCorrections.findIndex(
        (c) => c.parameterName === parameterName
      );
      if (i < 0) {
        const newValue = update(thresholdsCorrections, {
          $push: [
            {
              parameterName,
              correction
            }
          ]
        });

        setThresholdsCorrections(newValue);
      } else {
        const newValue = update(thresholdsCorrections, {
          [i]: { correction: { $set: correction } }
        });

        setThresholdsCorrections(newValue);
      }
    },
    [thresholdsCorrections]
  );

  const handleAcknowledgeClick = useCallback(
    (parameterName: string) => {
      setProcessing(true);
      acknowledge(
        intl,
        parameterName,
        (correction) => {
          updateThresholdsCorrections(parameterName, correction);
          setProcessing(false);
        },
        () => {
          setProcessing(false);
        }
      );
    },
    [acknowledge, intl, updateThresholdsCorrections]
  );

  useEffect(() => {
    if (!isDataAvailable(heaterStatus)) loadHeaterStatus(assetId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleAcknowledgeClick,
    parametersGroup,
    processing,
    thresholdCorrectionParams,
    translations
  };
};

export default useHeaterStatusParameters;
