// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Reducer } from "redux";

import Data from "core/data/models/Data";
import { Actions } from "features/detailpage/features/bushingsoffline/actions/BushingOfflineActions";
import { IAjaxAction } from "reducers/Index";
import IBushingOfflineDataResult from "../models/IBushingOfflineDataResult";

export interface IState {
  bushingsoffline: {
    transformerParameters: Data<IBushingOfflineDataResult[]>
  };
}

export const defaultState: IState = {
  bushingsoffline: {
    transformerParameters: new Data()
  }
};

const reducer: Reducer<IState> = (
  state = defaultState,
  action: IAjaxAction<IBushingOfflineDataResult[]>
) => {
  switch (action.type) {
    case Actions.DetailPage_BushingOffline_SetTransformerParameters:
      return {
        ...state,
        bushingsoffline: {
          ...state.bushingsoffline,
          transformerParameters: new Data(
            (action as IAjaxAction<IBushingOfflineDataResult[]>).request,
            (action as IAjaxAction<IBushingOfflineDataResult[]>).data
          )
        }
      };
    default:
      return state;
  }
};

export default reducer;
