// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { FormattedMessage } from "react-intl";

import "./AgingVisualizationChartLegend.less";

const AgingVisualizationChartLegend = () => (
  <div className="aging-visualization-chart-legend">
    <div className="values-legend">
      <div>
        <span className="age-indicator"></span>
        <FormattedMessage
          defaultMessage="Age"
          id="detail_page.replacement_priority.aging_visualization.legend.age"
        ></FormattedMessage>
      </div>
      <div>
        <span className="adjusted-age-indicator"></span>
        <FormattedMessage
          defaultMessage="Adjusted Age"
          id="detail_page.replacement_priority.aging_visualization.legend.adjusted_age"
        ></FormattedMessage>
      </div>
    </div>
    <div className="life-expectancy-legend">
      <div className="bold">
        <FormattedMessage
          defaultMessage="Life Expectancy:"
          id="detail_page.replacement_priority.aging_visualization.legend.life_expectancy"
        ></FormattedMessage>
      </div>
      <div>
        <span className="customer-indicator"></span>
        <FormattedMessage
          defaultMessage="Customer"
          id="detail_page.replacement_priority.aging_visualization.legend.customer"
        ></FormattedMessage>
      </div>
      <div>
        <span className="manufacturer-indicator"></span>
        <FormattedMessage
          defaultMessage="Manufacturer"
          id="detail_page.replacement_priority.aging_visualization.legend.manufacturer"
        ></FormattedMessage>
      </div>
    </div>
  </div>
);

export default AgingVisualizationChartLegend;
