// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import OperationsParameters, {
  IGroup,
  IRowUpdated,
  IState as IOperationsParametersState
} from "@apm/widgets/build/widgets/OperationsParameters";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { poleDefaultColors } from "../utils/getParametersDefaultState";

interface IParametersOwnProps {
  group: IGroup[];
  onChange?: (rowUpdated: IRowUpdated) => void;
  parametersDefaultState?: IOperationsParametersState;
}

type ParametersProps = IParametersOwnProps;

const Parameters = ({
  group,
  onChange,
  parametersDefaultState
}: ParametersProps) => {
  const { formatMessage, formatNumber, formatDate } = useIntl();
  const formatValue = useCallback(
    (value: number) => formatNumber(value),
    [formatNumber]
  );

  return (
    <OperationsParameters
      animated={false}
      groups={group}
      onChange={onChange}
      defaultState={parametersDefaultState}
      translations={{
        slope: formatMessage({
          id: "detail_page.widgets.circuit_breaker.operations.slope",
          defaultMessage: "Slope"
        }),
        value: formatMessage({
          id: "detail_page.widgets.circuit_breaker.operations.value",
          defaultMessage: "Value"
        }),
        label: formatMessage({
          id: "detail_page.widgets.circuit_breaker.operations.label",
          defaultMessage: "Hide from chart"
        }),
        eventDateInfo: (eventDate: Date) => {
          const formattedDate = formatDate(eventDate, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
          });
          return formatMessage(
            {
              id: "detail_page.widgets.circuit_breaker.operations.event_date",
              defaultMessage: "Event date: {value}"
            },
            { value: formattedDate }
          );
        }
      }}
      formatValue={formatValue}
      defaultColors={poleDefaultColors}
    />
  );
};

export default Parameters;
