// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IRoutableTab } from "common/RoutableTabs";
import {
  IVisualizationInfoDictionary,
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import DGA from "features/detailpage/features/DGA";
import SOT from "features/detailpage/features/SOT";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import DuvalTabs from "../../duval/components/DuvalTabs";

interface IUseTransformerTabsOptions {
  kinectricsNetworkTransformerAvailable: boolean;
  assetId: string;
  implementationId: string;
}

const useKinectricsNetworkTransformerTabs = ({
  assetId,
  kinectricsNetworkTransformerAvailable,
  implementationId
}: IUseTransformerTabsOptions) => {
  const { getAvailable } = useFeature();
  const intl = useIntl();
  const visualizations: IVisualizationInfoDictionary<IRoutableTab> =
    useMemo(() => {
      return {
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Dga]: {
          Content: () => (
            <DGA
              assetId={assetId}
              dataSources={["offline"]}
              implementationId={implementationId}
            />
          ),
          id: "DGA",
          name: intl.formatMessage({
            defaultMessage: "DGA",
            id: "detail_page.widgets.analytics.transformers.dga"
          })
        },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Duval]: {
          Content: () => (
            <DuvalTabs assetId={assetId} implementationId={implementationId} />
          ),
          id: "Duval",
          name: intl.formatMessage({
            defaultMessage: "Duval",
            id: "detail_page.widgets.analytics.transformers.duval"
          })
        },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Standard_Oil_Tests]:
          {
            Content: () => <SOT assetId={assetId} dataSources={["offline"]} />,
            id: "StandardOilTests",
            name: intl.formatMessage({
              defaultMessage: "Standard Oil Tests",
              id: "detail_page.widgets.analytics.transformers.standard_oil_tests"
            })
          }
      };
    }, [assetId, intl, implementationId]);

  const transformerTabs: IRoutableTab[] = useMemo(() => {
    if (kinectricsNetworkTransformerAvailable) {
      return getAvailable<IRoutableTab>(visualizations);
    }

    return [];
  }, [kinectricsNetworkTransformerAvailable, visualizations, getAvailable]);

  return transformerTabs;
};

export default useKinectricsNetworkTransformerTabs;
