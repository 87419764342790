// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "thunk";

import { IAjaxAction } from "reducers/Index";
import { IAgingOperations } from "features/detailpage/features/aging/models/IAgingOperations";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import EndpointService from "core/data/services/EndpointService";

export enum Actions {
  DetailPage_Aging_SetOperations = "DetailPage_Aging_SetOperations"
}

export const setOperationsAction = (
  request: JQuery.jqXHR,
  data?: IAgingOperations
): IAjaxAction<IAgingOperations> => ({
  request,
  data,
  type: Actions.DetailPage_Aging_SetOperations
});

export const loadOperationsAction = (
  assetId: string
): ThunkAction<Promise<void>, Action> => (dispatch: ThunkDispatch<Action>) =>
  fetchOperations(dispatch, assetId);

function fetchOperations(
  dispatch: ThunkDispatch<Action>,
  assetId: string
): Promise<void> {
  return new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(config.api.detailPage.agingOperationsUrl, [
      {
        name: "assetId",
        value: assetId
      }
    ]);
    const request = EndpointService.getJson<IAgingOperations>(
      url,
      (request, data) => {
        dispatch(setOperationsAction(request, data));
        resolve();
      },
      request => {
        dispatch(setOperationsAction(request));
        reject();
      }
    );

    dispatch(setOperationsAction(request));
  });
}
