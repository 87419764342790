// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Reducer } from "redux/index";

import Data from "core/data/models/Data";
import { IAjaxAction, IState as IFamilyInfoState } from "reducers/Index";
import { Actions } from "features/detailpage/features/familyinfo/actions/FamilyInfoActions";

export interface IFamilyInfo {
  AssetId: string;
  Manufacturer: string;
  ManufacturerModel: string;
  ParameterName: string;
  Count: number;
  FamilyCount: number;
  Max: number;
  Min: number;
  Mean: number;
  Median: number;
  Q1: number;
  Q3: number;
  LowerExtreme: number;
  UpperExtreme: number;
  IsOutlier: number;
  Value: number;
}

export interface IState {
  familyInfo: Data<IFamilyInfo>;
}

const defaultState: IState = {
  familyInfo: new Data()
};

const familyInfoReducer: Reducer<IState> = (
  state = defaultState,
  action: IAjaxAction<IFamilyInfo>
) => {
  switch (action.type) {
    case Actions.Parameters_SetFamilyInfo:
    case Actions.DerivativeParameters_SetFamilyInfo:
    case Actions.DegradationScore_SetFamilyInfo:
    case Actions.ReplacementScore_SetFamilyInfo:
    case Actions.DegradationSubScore_SetFamilyInfo:
    case Actions.ImportanceScore_SetFamilyInfo:
      state = Object.assign({}, state, {
        familyInfo: new Data(action.request, action.data)
      } as IState);
      return state;
    case Actions.ResetFamilyInfo:
      return { ...defaultState };
    default:
      return state;
  }
};

export default familyInfoReducer;

export const getFamilyInfoSelector = (state: IFamilyInfoState) =>
  state.familyInfo.familyInfo;
