// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import LicensesColumn from "./LicensesColumn";
import ProjectWebsiteColumn from "./ProjectWebsiteColumn";
import ICommonLicense from "../models/ICommonLicense";
import IMavenLicense from "../models/IMavenLicense";
import INpmLicenses from "../models/INpmLicenses";
import INugetLicense from "../models/INugetLicense";
import DataGrid, { IColumnConfig, IRowData } from "common/datagrid/DataGrid";

interface ILicensesListProps {
  commonLicenses: ICommonLicense[];
  mavenLicenses: IMavenLicense[];
  npmLicenses: INpmLicenses;
  nugetLicenses: INugetLicense[];
}

const LicensesList = ({
  commonLicenses,
  mavenLicenses,
  npmLicenses,
  nugetLicenses
}: ILicensesListProps) => {
  const intl = useIntl();
  const columns: IColumnConfig[] = [
    {
      id: "library",
      message: {
        defaultMessage: "licenses.table.library",
        id: "licenses.table.library"
      },
      weight: 2
    },
    {
      id: "version",
      message: {
        id: "licenses.table.version",
        defaultMessage: "licenses.table.version"
      },
      weight: 1
    },
    {
      id: "licenses",
      message: {
        id: "licenses.table.licenses",
        defaultMessage: "licenses.table.licenses"
      },
      weight: 1,
      component: (value, row) => <LicensesColumn value={value} row={row} />
    },
    {
      id: "projectWebsite",
      message: {
        id: "licenses.table.project_website",
        defaultMessage: "licenses.table.project_website"
      },
      weight: 3,
      component: (value, row) => (
        <ProjectWebsiteColumn value={value} row={row} />
      )
    }
  ];

  const commonLicensesData = useMemo(() => {
    return commonLicenses.map<IRowData>((l) => ({
      library: "--",
      version: "--",
      licenses: l.Name,
      projectWebsite: l.Url !== "" ? l.Url : null,
      licensesUrl: null,
      fileName: l.FileName !== "" ? l.FileName : null,
      licenseType: "common"
    }));
  }, [commonLicenses]);

  const mavenLicensesData = useMemo(() => {
    return (mavenLicenses ?? []).map((l) => ({
      library: l.Name,
      version: l.Version,
      licenses:
        l.LicenseFileName != null || l.LicenseUrl != null
          ? intl.formatMessage({
              id: "licenses.license",
              defaultMessage: "License"
            })
          : null,
      projectWebsite: l.ProjectUrl !== "" ? l.ProjectUrl : null,
      licensesUrl: l.LicenseUrl !== "" ? l.LicenseUrl : null,
      fileName: l.LicenseFileName !== "" ? l.LicenseFileName : null,
      licenseType: "maven"
    }));
  }, [intl, mavenLicenses]);

  const npmLicensesData = useMemo(() => {
    return Object.keys(npmLicenses).map((k) => {
      const packageName = npmLicenses[k].packageName;
      const packageVersion = npmLicenses[k].packageVersion;
      const fileName = npmLicenses[k].fileName;
      const repositoryUrl = npmLicenses[k].repository
        ? npmLicenses[k].repository
            .replace(/^git\+ssh:\/\/git@/, "https://")
            .replace(/^git\+/, "")
        : "";
      const licenses =
        typeof npmLicenses[k].licenses === "string"
          ? npmLicenses[k].licenses
          : (npmLicenses[k].licenses as string[]).join(", ");

      return {
        library: packageName,
        version: packageVersion,
        licenses: licenses,
        projectWebsite: repositoryUrl !== "" ? repositoryUrl : null,
        licensesUrl: `/static/licenses/npm/${fileName}.txt`,
        fileName: null,
        licenseType: "npm"
      };
    });
  }, [npmLicenses]);

  const nugetLicensesData = useMemo(() => {
    return (nugetLicenses ?? []).map((l) => ({
      library: l.Name,
      version: l.Version,
      licenses:
        l.LicenseFileName != null || l.LicenseUrl != null
          ? intl.formatMessage({
              id: "licenses.license",
              defaultMessage: "License"
            })
          : null,
      projectWebsite: l.ProjectUrl !== "" ? l.ProjectUrl : null,
      licensesUrl: l.LicenseUrl !== "" ? l.LicenseUrl : null,
      fileName: l.LicenseFileName !== "" ? l.LicenseFileName : null,
      licenseType: "nuget"
    }));
  }, [intl, nugetLicenses]);

  const licensesData = useMemo(
    () => [
      ...commonLicensesData,
      ...npmLicensesData,
      ...nugetLicensesData,
      ...mavenLicensesData
    ],
    [commonLicensesData, mavenLicensesData, npmLicensesData, nugetLicensesData]
  );

  return <DataGrid columns={columns} data={licensesData} />;
};

export default LicensesList;
