// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import DGAOperationsContainer from "features/detailpage/features/ltc/containers/DGAOperationsContainer";
import HistorySwitchingContainer from "features/detailpage/features/ltc/containers/HistorySwitchingContainer";
import LTCOperationsContainer from "features/detailpage/features/ltc/containers/LTCOperationsContainer";
import * as React from "react";
import "./LTCTab.less";

const LTCWidget = ({ children }: React.Props<any>) => (
  <div className="card">
    <div className="card-body">{children}</div>
  </div>
);

interface ILTCTabProps {
  assetId: string;
  modelId: string;
  implementationId: string;
}

class LTCTab extends React.PureComponent<ILTCTabProps> {
  render() {
    const { assetId, modelId, implementationId } = this.props;
    return (
      <div className="ltc-tab">
        <div className="container">
          <div className="bootstrap-row">
            <div className="col">
              <LTCWidget>
                <LTCOperationsContainer assetId={assetId} />
              </LTCWidget>
            </div>
          </div>
          <div className="bootstrap-row">
            <div className="col-14">
              <LTCWidget>
                <HistorySwitchingContainer assetId={assetId} />
              </LTCWidget>
            </div>
            <div className="col">
              <LTCWidget>
                <DGAOperationsContainer
                  assetId={assetId}
                  modelId={modelId}
                  implementationId={implementationId}
                />
              </LTCWidget>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LTCTab;
