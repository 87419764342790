// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React, { ReactNode } from "react";

export interface ISettingsProviderActions {
  loadCustomer: () => void;
  loadEnvironment: () => void;
  loadMapProvider: () => void;
  loadUser: () => void;
  loadTenantList: () => void;
  loadCustomerVisualizations: () => void;
  loadCustomerVisualizationsAssetGrid: () => void;
}

export interface ISettingsProviderProps extends ISettingsProviderActions {
  children?: ReactNode;
}

class SettingsProvider extends React.Component<ISettingsProviderProps> {
  componentDidMount() {
    const {
      loadCustomer,
      loadEnvironment,
      loadMapProvider,
      loadTenantList,
      loadUser,
      loadCustomerVisualizations,
      loadCustomerVisualizationsAssetGrid
    } = this.props;

    loadCustomer();
    loadCustomerVisualizations();
    loadCustomerVisualizationsAssetGrid();
    loadEnvironment();
    loadMapProvider();
    loadTenantList();
    loadUser();
  }

  render() {
    const { children } = this.props;
    const childArray = React.Children.toArray(children);
    return <div>{childArray}</div>;
  }
}

export default SettingsProvider;
