// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React, { ReactNode } from "react";

export interface ITranslationsProviderActions {
  loadTranslations: (locale: string) => void;
}

export interface ITranslationsProviderData {
  locale: string | null;
}

export interface ITranslationsProviderProps
  extends ITranslationsProviderActions,
    ITranslationsProviderData {
  children?: ReactNode;
}

class TranslationsProvider extends React.Component<ITranslationsProviderProps> {
  componentDidMount() {
    const { loadTranslations, locale } = this.props;
    loadTranslations(locale);
  }

  componentDidUpdate() {
    const { loadTranslations, locale } = this.props;
    loadTranslations(locale);
  }

  render() {
    const { children } = this.props;
    const childArray = React.Children.toArray(children);
    return <div>{childArray}</div>;
  }
}

export default TranslationsProvider;
