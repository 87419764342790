// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import { SelectedFilters } from "common/FilterBar";
import { routesConfig } from "core/app/components/router/RouterProvider";
import { isEmpty, isEqual } from "lodash";
import AssetRisks from "models/AssetRisks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import addFilterSearchParam from "utils/addFilterSearchParam";
import AssetRiskTypes from "../models/AssetRiskTypes";
import getAssetRiskCountsData from "../selectors/getAssetRiskCountsData";
import getAssetRiskCountsStatus from "../selectors/getAssetRiskCountsStatus";
import createAssetRiskJellyBeans from "../utils/createAssetRiskJellyBeans";
import useRequests from "./useRequests";

const useAssetRiskCounts = (
  filters: SelectedFilters,
  type: AssetRiskTypes,
  loadAssetRiskCounts: (
    type: AssetRiskTypes,
    filters: SelectedFilters,
    id?: string[]
  ) => void,
  removeAssetRiskCounts: (type: AssetRiskTypes, id?: string[]) => void,
  formatGroupName?: (groupKey: string) => string
) => {
  const history = useHistory();
  const location = useLocation();

  const containerRef = useRef<HTMLDivElement>(null);
  const [currentId, setCurrentId] = useState<string[]>([]);
  const { addRequest, resetRequests, requests, requestsRef } = useRequests();
  const status = useSelector(getAssetRiskCountsStatus)(type, requests);
  const assetRiskCounts = useSelector(getAssetRiskCountsData)(type, requests);

  const handleCollapse = useCallback((id: string[]) => {
    setCurrentId(id.slice(0, -1));
  }, []);

  const handleExpand = useCallback(
    (id: string[]) => {
      const isFetched = requests.findIndex((r) => isEqual(r, id)) >= 0;
      if (!isFetched) {
        loadAssetRiskCounts(type, filters, id);
        addRequest(id);
      }
      setCurrentId(id);

      containerRef?.current?.scrollIntoView({
        behavior: "smooth"
      });
    },
    [addRequest, filters, loadAssetRiskCounts, requests, type]
  );

  const handleItemClick = useCallback(
    (assetRisk: AssetRisks, id: string[], value: number) => {
      id = id.map((x) => (isEmpty(x) ? null : x));
      const searchParams = new SearchParams(location.search);

      if (type === "RiskByAgeRange")
        addFilterSearchParam(searchParams, "f_aa", id);
      if (type === "RiskByAssetType")
        addFilterSearchParam(searchParams, "f_at", id);
      if (type === "RiskByCompanyHierarchy")
        addFilterSearchParam(searchParams, "f_ch", id);
      if (type === "RiskByOrganization")
        addFilterSearchParam(searchParams, "f_ao", id);
      if (type === "RiskByVoltageClass")
        addFilterSearchParam(searchParams, "f_av", id);

      searchParams.set("f_ar", JSON.stringify([assetRisk]));

      history.push({
        pathname: routesConfig.assets,
        search: searchParams.toString()
      });
    },
    [history, location.search, type]
  );

  const assetRiskJellyBeans = useMemo(
    () =>
      createAssetRiskJellyBeans(
        assetRiskCounts,
        currentId,
        formatGroupName,
        handleItemClick
      ),
    [assetRiskCounts, currentId, formatGroupName, handleItemClick]
  );

  useEffect(() => {
    loadAssetRiskCounts(type, filters);
    setCurrentId([]);
    resetRequests();
    return () => {
      removeAssetRiskCounts(type);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      requestsRef.current?.forEach((r) => removeAssetRiskCounts(type, r));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, type]);

  return {
    assetRiskJellyBeans,
    containerRef,
    handleCollapse,
    handleExpand,
    status
  };
};

export default useAssetRiskCounts;
