// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IMessages } from "core/app/reducers/TranslationsReducer";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { IntlProvider as ReactIntlProvider } from "react-intl";
import polyfillDateTimeFormat from "../utils/polyfillDateTimeFormat";
import polyfillDisplayNames from "../utils/polyfillDisplayNames";
import polyfillGetCanonicalLocales from "../utils/polyfillGetCanonicalLocales";
import polyfillListFormat from "../utils/polyfillListFormat";
import polyfillLocale from "../utils/polyfillLocale";
import polyfillNumberFormat from "../utils/polyfillNumberFormat";
import polyfillPluralRules from "../utils/polyfillPluralRules";
import polyfillRelativeTimeFormat from "../utils/polyfillRelativeTimeFormat";
import setupMoment from "../utils/setupMoment";
import Message from "./Message";

export interface IIntlProviderData {
  locale: string;
  messages: IMessages;
}

export interface IIntlProviderProps extends IIntlProviderData {
  children?: ReactNode;
}

const IntlProvider = ({ children, locale, messages }: IIntlProviderProps) => {
  const polyfilledRef = useRef<{ [name: string]: boolean }>({});

  const [polyfilled, setPolyfilled] = useState<boolean>();

  const polyfill = useCallback(
    (propertyName: string, polyfillFn: () => Promise<any>) => {
      return polyfillFn()
        .then(() => {
          polyfilledRef.current[propertyName] = true;
        })
        .catch(() => {
          polyfilledRef.current[propertyName] = false;
        })
        .finally(() => {
          const polyfilled =
            Object.keys(polyfilledRef.current)
              .map((p) => polyfilledRef.current[p])
              .filter((p) => p === true).length === 8;

          setPolyfilled(polyfilled);
        });
    },
    []
  );

  useEffect(() => {
    polyfill("getCanonicalLocales", () => polyfillGetCanonicalLocales()).then(
      () => {
        polyfill("locale", () => polyfillLocale(locale)).then(() => {
          polyfill("pluralRules", () => polyfillPluralRules(locale)).then(
            () => {
              polyfill("numberFormat", () => polyfillNumberFormat(locale)).then(
                () => {
                  polyfill("relativeTimeFormat", () =>
                    polyfillRelativeTimeFormat(locale)
                  );
                  polyfill("dateTimeFormat", () =>
                    polyfillDateTimeFormat(locale)
                  );
                }
              );
            }
          );
          polyfill("displayNames", () => polyfillDisplayNames(locale));
          polyfill("listFormat", () => polyfillListFormat(locale));
        });
      }
    );

    setupMoment(locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <>
      {polyfilled && (
        <ReactIntlProvider
          locale={locale}
          messages={messages}
          textComponent="span"
        >
          {children}
        </ReactIntlProvider>
      )}
      {!polyfilled && <Message>Loading ...</Message>}
    </>
  );
};

export default IntlProvider;
