// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { connect } from "react-redux";

import Data, { Statuses } from "core/data/models/Data";
import App, { AppStatuses, IAppData } from "core/app/components/App";
import { IState } from "reducers/Index";

const is = <T>(obj: Data<T>, statuses: Statuses[]): boolean =>
  obj && statuses.indexOf(obj.status) >= 0;

const isAll = (state: IState, status: Statuses): boolean =>
  is(state.app.customer, [status]) &&
  is(state.app.environment, [status]) &&
  is(state.app.mapProvider, [status]) &&
  is(state.app.tenants, [status]) &&
  is(state.app.translations, [status]) &&
  is(state.app.user, [status]) &&
  is(state.app.version.ui, [status]);

const isAllCustomerSettings = (state: IState, statuses: Statuses[]): boolean =>
  is(state.app.customerVisualizations, statuses) &&
  is(state.app.customerVisualizationsAssetGrid, statuses);

const isAny = (state: IState, status: Statuses): boolean =>
  is(state.app.customer, [status]) ||
  is(state.app.environment, [status]) ||
  is(state.app.mapProvider, [status]) ||
  is(state.app.tenants, [status]) ||
  is(state.app.translations, [status]) ||
  is(state.app.user, [status]) ||
  is(state.app.version.ui, [status]) ||
  is(state.app.customerVisualizations, [status]) ||
  is(state.app.customerVisualizationsAssetGrid, [status]);

function mapStateToProps(state: IState): IAppData {
  const isAuthenticating = isAny(state, Statuses.Unauthorized);
  const isError = isAny(state, Statuses.Failed);
  const isForbidden = isAny(state, Statuses.Forbidden);
  const isLoading = isAny(state, Statuses.Loading);
  const isStarted =
    isAll(state, Statuses.Succeeded) &&
    isAllCustomerSettings(state, [Statuses.Succeeded, Statuses.NotFound]);

  let appStatus = AppStatuses.Loading;

  if (isAuthenticating) appStatus = AppStatuses.Authenticating;
  else if (isError) appStatus = AppStatuses.Error;
  else if (isForbidden) appStatus = AppStatuses.Forbidden;
  else if (isLoading) appStatus = AppStatuses.Loading;
  else if (isStarted) appStatus = AppStatuses.Started;

  return {
    appStatus: appStatus
  };
}

const AppContainer = connect(mapStateToProps)(App);

export default AppContainer;
