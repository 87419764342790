// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { isEmpty } from "lodash";
import { useThunkMiddleware } from "thunk";

import { useLTCOperations } from "features/detailpage/features/ltc/reducers/LTCReducer";
import { loadLtcOperationsAction } from "features/detailpage/features/ltc/actions/LTCOperationsActions";

import LTCOperations from "features/detailpage/features/ltc/components/LTCOperations";
import { Statuses } from "core/data/models/Data";

interface ILTCOperationsContainerProps {
  assetId: string;
}

export default ({ assetId }: ILTCOperationsContainerProps) => {
  const { ltcOperations, percentageUsed } = useLTCOperations();

  const loadLtcOperations = useThunkMiddleware(
    loadLtcOperationsAction(assetId)
  );

  React.useEffect(() => {
    if (
      ltcOperations.status !== Statuses.Succeeded ||
      isEmpty(ltcOperations.data)
    ) {
      loadLtcOperations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LTCOperations
      ltcOperations={ltcOperations}
      percentageUsed={percentageUsed}
    />
  );
};
