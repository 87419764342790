// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash";
import ISF6ChartSeries from "@apm/widgets/build/widgets/SF6Chart/models/ISF6ChartSeries";

import { ITrendChart } from "common/chart/components/TrendLineChart";
import ISF6ChartRegion from "@apm/widgets/build/widgets/SF6Chart/models/ISF6ChartRegion";
import ISF6ChartLine from "@apm/widgets/build/widgets/SF6Chart/models/ISF6ChartLine";
import RegionClasses from "@apm/widgets/build/widgets/SF6Chart/models/RegionClasses";
import { IModelDetails } from "../../DielectricTab/DielectricTab";

interface IUseMoistureChart {
  trendChart: ITrendChart;
  model: IModelDetails;
}

const useMoistureChart = ({
  trendChart: { DataSeries, Regions, Lines, Unit },
  model
}: IUseMoistureChart) => {
  const intl = useIntl();
  const { modelId, implementationId } = model;

  const emptyTranslation = useMemo(
    () =>
      intl.formatMessage({
        id: "global.chart.no_data_available",
        defaultMessage: "No data available"
      }),
    [intl]
  );

  const series: ISF6ChartSeries[] = useMemo(() => {
    const series: ISF6ChartSeries[] = [];

    DataSeries &&
      Object.keys(DataSeries).forEach((key) => {
        series.push({
          name: key,
          displayName: intl.formatMessage({
            id: `${modelId}.parameter.${key}`,
            defaultMessage: intl.formatMessage({
              id: `${implementationId}.parameter.${key}`,
              defaultMessage: intl.formatMessage({
                id: key,
                defaultMessage: key
              })
            })
          }),
          values: DataSeries[key],
          unit: Unit
        });
      });

    return series;
  }, [DataSeries, intl, modelId, implementationId, Unit]);

  const regions: ISF6ChartRegion[] = useMemo(() => {
    return (
      (!isEmpty(series) &&
        Regions?.map<ISF6ChartRegion>((r) => ({
          regionClass: r.Class as RegionClasses,
          displayName: intl.formatMessage({
            id: `threshold.${r.Class}`,
            defaultMessage: r.Class
          }),
          name: r.Class,
          start: r.Start,
          end: r.End
        }))) ||
      []
    );
  }, [Regions, intl, series]);

  const lines: ISF6ChartLine[] = useMemo(() => {
    return (
      (!isEmpty(series) &&
        Lines?.map<ISF6ChartLine>((l) => ({
          displayName: l.Description,
          name: l.Class,
          value: l.Value
        }))) ||
      []
    );
  }, [Lines, series]);

  const formatXTick = useCallback((date: Date) => intl.formatDate(date), [
    intl
  ]);

  const formatTooltipTitle = useCallback(
    (date: Date) =>
      intl.formatDate(date, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      }),
    [intl]
  );

  return {
    series,
    regions,
    lines,
    formatXTick,
    formatTooltipTitle,
    emptyTranslation
  };
};

export default useMoistureChart;
