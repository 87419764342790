// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";

import { loadConductanceData } from "../actions/BatteryConductanceActions";
import BatteryConductance from "../components/BatteryConductance";
import batteryConductanceSelector from "../selectors/batteryConductanceSelector";

interface IBatteryConductanceContainerProps {
  assetId: string;
}

const BatteryConductanceContainer = ({
  assetId
}: IBatteryConductanceContainerProps) => {
  const dispatch = useDispatch();
  const conductanceData = useSelector(batteryConductanceSelector);
  const memoizedLoadConductanceData = useCallback(
    () => loadConductanceData(dispatch as Dispatch, assetId),
    [assetId, dispatch]
  );

  useEffect(() => {
    memoizedLoadConductanceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <BatteryConductance conductanceData={conductanceData} />;
};

export default BatteryConductanceContainer;
