// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import { TooltipPlacement } from "antd/lib/tooltip/index";
import Popover from "common/popover/Popover";
import {
  loadDegradationScoreFamilyInfoAction,
  resetFamilyInfoAction
} from "features/detailpage/features/familyinfo/actions/FamilyInfoActions";
import FamilyInfoContainer from "features/detailpage/features/familyinfo/containers/FamilyInfoContainer";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

interface IFamilyInfoDegradationScoreIconProps {
  assetId: string;
  isDisabled?: boolean;
  placement?: TooltipPlacement;
  stopPropagation?: boolean;
}

const DegradationScoreFamilyIcon = ({
  assetId,
  isDisabled,
  placement,
  stopPropagation
}: IFamilyInfoDegradationScoreIconProps) => {
  const dispatch = useDispatch();

  const handleShow = useCallback(() => {
    dispatch(loadDegradationScoreFamilyInfoAction(assetId));
  }, [assetId, dispatch]);

  const handleHide = useCallback(() => {
    dispatch(resetFamilyInfoAction());
  }, [dispatch]);

  return (
    <Popover
      content={
        <FamilyInfoContainer
          assetId={assetId}
          familyAnalyticsDataType="DegradationScore"
          familyAnalyticsSeriesName="DegradationScore"
        />
      }
      onShow={handleShow}
      onHide={handleHide}
      overlayClassName="family-info-degradation-popover family-info-popover"
      stopPropagation={stopPropagation}
      placement={placement}
    >
      <span>
        <Icon
          className={`family-info-popover-icon ${
            isDisabled ? "family-info-popover-off" : ""
          }`}
          dataQa="header-condition-family-icon"
          name="speed"
          size="sm"
        />
      </span>
    </Popover>
  );
};

export default DegradationScoreFamilyIcon;
