// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useIntl } from "react-intl";

import useBushingOfflineCharts from "./hooks/useBushingOfflineCharts";

import BushingOfflineChart from "features/detailpage/features/bushingsoffline/components/BushingOfflineChart/BushingOfflineChart";
import BushingOfflineChartLegend from "features/detailpage/features/bushingsoffline/components/BushingOfflineChartLegend/BushingOfflineChartLegend";
import { BushingReference } from "features/detailpage/features/bushingsoffline/models/BushingReference";
import BushingOfflineChartService from "features/detailpage/features/bushingsoffline/services/BushingOfflineChartService";
import IBushingOfflineDataResult from "../../models/IBushingOfflineDataResult";

interface IBushingOfflineChartsOwnProps {
  offlineBushingModelResult: IBushingOfflineDataResult[];
}

const BushingOfflineCharts = ({
  offlineBushingModelResult
}: IBushingOfflineChartsOwnProps) => {
  const intl = useIntl();
  const {
    focusPlotSeries,
    revertPlotSeries,
    togglePlotSeries,
    onChartRendered,
    settings
  } = useBushingOfflineCharts();

  return (
    <>
      {settings.bushingChartRows.map((p, index) => (
        <div className="bushing-row" data-qa="charts-row" key={`row${index}`}>
          {settings.bushingChartColumns.map((n, i) => {
            const bushingData = offlineBushingModelResult.find(
              (x) => x.WindingDesignationId === p + n
            );
            return (
              <div className="bushing-col" key={`col${i}`}>
                <BushingOfflineChart
                  id={`${p}${n}`}
                  chartTitle={`${p}${n}`}
                  manufacturer={bushingData.BushingInfoData.Manufacturer}
                  model={bushingData.BushingInfoData.Model}
                  installDate={bushingData.BushingInfoData.InstallDate}
                  series={BushingOfflineChartService.getSeries(
                    intl,
                    settings.dataSeriesInfo,
                    bushingData.BushingParametersData,
                    p,
                    n
                  )}
                  onChartRendered={onChartRendered}
                  powerFactorThreshold={BushingOfflineChartService.getReference(
                    intl,
                    BushingReference.PowerFactor,
                    bushingData.BushingReferenceData,
                    p,
                    n
                  )}
                  capacitanceThreshold={BushingOfflineChartService.getReference(
                    intl,
                    BushingReference.Capacitance,
                    bushingData.BushingReferenceData,
                    p,
                    n
                  )}
                />
              </div>
            );
          })}
          <BushingOfflineChartLegend
            focusPlotSeries={focusPlotSeries}
            revertPlotSeries={revertPlotSeries}
            togglePlotSeries={togglePlotSeries}
            series={settings.bushingChartColumns
              .map((c) => `${p}${c}`)
              .join(",")}
          />
        </div>
      ))}
    </>
  );
};

export default BushingOfflineCharts;
