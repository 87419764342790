// Copyright 2016-2022 Hitachi Energy. All rights reserved.
/// <reference path="../types/intl-displaynames.d.ts" />

import { shouldPolyfill } from "@formatjs/intl-displaynames/should-polyfill";

const polyfillDisplayNames = (locale: string): Promise<any> => {
  if (!shouldPolyfill()) return Promise.resolve();
  return Promise.all([
    import("@formatjs/intl-displaynames/polyfill"),
    import("@formatjs/intl-displaynames/locale-data/es"),
    import("@formatjs/intl-displaynames/locale-data/pl"),
    import("@formatjs/intl-displaynames/locale-data/zh"),
    import("@formatjs/intl-displaynames/locale-data/ja"),
    import("@formatjs/intl-displaynames/locale-data/pt"),
    import("@formatjs/intl-displaynames/locale-data/de"),
    import("@formatjs/intl-displaynames/locale-data/en")
  ]);
};

export default polyfillDisplayNames;
