// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import Processing from "components/common/Processing";
import { WidgetNoDataMessage } from "components/common/widget/Widget";
import { Statuses } from "core/data/models/Data";
import * as React from "react";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { loadAgingVisualization } from "../../actions/AgingActions";
import IAgingVisualization from "../../models/IAgingVisualization";
import getAgingVisualizationSelector from "../../selectors/getAgingVisualizationSelector";
import "./AgingVisualization.less";
import AgingVisualizationChart from "./AgingVisualizationChart";

interface IAgingVisualizationProps {
  assetId: string;
}

const AgingVisualization = ({ assetId }: IAgingVisualizationProps) => {
  const dispatch = useDispatch();
  const aging = useSelector(getAgingVisualizationSelector);

  useEffect(() => {
    loadAgingVisualization(dispatch, assetId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="aging-visualization container">
      {aging && aging.status === Statuses.Loading && (
        <AgingVisualizationProcessing />
      )}
      {aging && aging.status === Statuses.Succeeded && (
        <AgingVisualizationSucceeded agingData={aging.data} />
      )}

      {aging && aging.status === Statuses.Failed && (
        <AgingVisualizationFailed />
      )}
      {aging && aging.status === Statuses.NotFound && (
        <AgingVisualizationNotFound />
      )}
    </div>
  );
};

const AgingVisualizationProcessing = () => (
  <div className="aging-visualization container">
    <Processing />
  </div>
);

interface IAgingVisualizationSucceededProps {
  agingData: IAgingVisualization;
}

const AgingVisualizationSucceeded = ({
  agingData
}: IAgingVisualizationSucceededProps) => (
  <div className="aging-visualization container">
    <div className="chart-name">
      <FormattedMessage
        id="detail_page.replacement_priority.aging_visualization.title"
        defaultMessage="Aging"
      />
    </div>
    <AgingVisualizationChart agingData={agingData}></AgingVisualizationChart>
  </div>
);

const AgingVisualizationFailed = () => (
  <div className="aging-visualization container">
    <div className="failed">
      <Icon name="warning" />
      <FormattedMessage
        defaultMessage="Data loading failed"
        id="detail_page.replacement_priority.aging_visualization.data_loading_failed"
      />
    </div>
  </div>
);

const AgingVisualizationNotFound = () => <WidgetNoDataMessage />;

export default AgingVisualization;
