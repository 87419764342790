// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Processing from "components/common/Processing";
import { Statuses } from "core/data/models/Data";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import AssetModalContext from "features/ConfigurationTool/contexts/AssetModalContext";
import getConnectedDevicesDataSelector from "features/ConfigurationTool/selectors/getConnectedDevicesDataSelector";
import getNameplateSelector from "features/ConfigurationTool/selectors/getNameplateSelector";
import getParameterSelector from "features/ConfigurationTool/selectors/getParameterSelector";
import IAssetNameplate from "features/detailpage/models/IAssetNameplate";
import { TypedValue } from "features/detailpage/models/IParameter";
import update from "immutability-helper";
import { isNil } from "lodash";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { ThunkDispatch } from "thunk";
import { config } from "utils/AppConfig";
import loadAssetDetails from "../actions/loadAssetDetails";
import loadConnectedDevices from "../actions/loadConnectedDevices";
import loadParameters, { IParametersData } from "../actions/loadParameters";
import removeAssetDetails from "../actions/removeAssetDetails";
import useForms from "../hooks/useForms";
import useNameplate from "../hooks/useNameplate";
import IConfigurableConnectedDevice from "../models/connectedDevices/IConfigurableConnectedDevice";
import IConnectedDevice from "../models/connectedDevices/IConnectedDevice";
import IConnectedDevicesData from "../models/connectedDevices/IConnectedDevicesData";
import IRegisterConfiguration from "../models/connectedDevices/IRegisterConfiguration";
import DataTypes from "../models/DataTypes";
import FieldTypes from "../models/FieldTypes";
import FormTypes from "../models/FormTypes";
import IField from "../models/IField";
import { IParameter } from "../models/IParameter";
interface IAssetModalContextProviderProps {
  children?: ReactNode;
  initialAssetId: string;
}

type AssetModalContextProviderProps = IAssetModalContextProviderProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const AssetModalContextProvider = ({
  initialAssetDetails,
  initialAssetId,
  initialParameters,
  initialConnectedDevicesData,
  children,
  loadAssetDetailsData,
  cleanAssetDetailsData,
  loadParametersData,
  loadConnectedDevicesData
}: AssetModalContextProviderProps) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(
    initialAssetId !== null
  );
  const [assetId, setAssetId] = useState<string>(initialAssetId);
  const [assetIdRestorePoint, setAssetIdRestorePoint] =
    useState<string>(initialAssetId);
  const [assetNameplate, setAssetNameplate] = useState<IAssetNameplate>(null);
  const [inspection, setInspection] = useState<IParameter[]>([]);
  const [assetNameplateRestorePoint, setAssetNameplateRestorePoint] =
    useState<IAssetNameplate>(null);
  const [resetFormsNeeded, setResetFormsNeeded] = useState<boolean>(false);
  const [parameters, setParameters] = useState<IParametersData>(null);
  const [parametersRestorePoint, setParametersRestorePoint] =
    useState<IParametersData>(null);
  const [customParameters, setCustomParameters] = useState<IField[]>([]);

  const [connectedDevices, setConnectedDevices] = useState<
    IConfigurableConnectedDevice[]
  >([]);

  const [connectedDevicesRestorePoint, setConnectedDevicesRestorePoint] =
    useState<IConfigurableConnectedDevice[]>([]);

  const [connectedDeviceLastUpdatedDate, setAssetDeviceLastUpdatedDate] =
    useState<IConnectedDevicesData["LastUpdatedDate"]>("");

  const [connectedDeviceParameterNames, setConnectedDeviceParameterNames] =
    useState<IConnectedDevicesData["AssetParameterNames"]>([]);

  const [connectedDeviceAction, setConnectedDeviceAction] = useState<
    "add" | "delete" | "upload"
  >(null);

  const mapToConfigurableConnectedDevices = useCallback(
    ({
      connectedDevices,
      lastDeviceOrder,
      imported = false
    }: {
      connectedDevices: IConnectedDevice[];
      lastDeviceOrder?: number;
      imported?: boolean;
    }): IConfigurableConnectedDevice[] => {
      return connectedDevices.map(
        (device, idx): IConfigurableConnectedDevice => ({
          ...device,
          RegisterConfiguration: device?.RegisterConfiguration.map(
            (field, idx) => {
              const initialRegisterConfigCells = Object.entries(field).map(
                ([key, val]) => {
                  return {
                    cellName: key as keyof IRegisterConfiguration,
                    cellValue: val,
                    modified: false,
                    valid: true
                  };
                },
                {}
              );

              return {
                id: idx,
                cells: initialRegisterConfigCells
              };
            }
          ),
          Guid: `${performance.now()}${Math.random()
            .toString()
            .slice(5)}`.replace(".", ""),
          Order: lastDeviceOrder ? lastDeviceOrder + idx + 1 : idx + 1,
          Imported: imported
        })
      );
    },
    []
  );

  const mapToConnectedDevicesPayload = useCallback(
    (connectedDevices: IConfigurableConnectedDevice[]): IConnectedDevice[] => {
      return connectedDevices.map((device) => {
        return {
          ...device,
          Guid: undefined,
          Order: undefined,
          Imported: undefined,
          RegisterConfiguration: device.RegisterConfiguration.map(
            ({ cells }) => {
              return cells.reduce((acc, val) => {
                return {
                  ...acc,
                  [val.cellName]: val.cellValue ?? undefined
                };
              }, {}) as IRegisterConfiguration;
            }
          )
        };
      });
    },
    []
  );

  const {
    forms,
    menuItems,
    selectedMenu,
    blockActions,
    validateMessages,
    configuredParameterNames,
    getFormInfo,
    registerForm,
    changeSelectedMenu,
    updateFormItemTouchedInfo,
    updateFormItemInvalidInfo,
    resetFormsState,
    isDataTypeTouched,
    validForms,
    changeTabsInfo,
    getConfiguredParameterNames,
    updateFormItemValidationInfo
  } = useForms();

  const { defaultNameplate } = useNameplate();

  const preloadedParameters = useMemo(
    () =>
      getConfiguredParameterNames(
        forms
          .filter((form) => form.formType === FormTypes.PreloadedInspection)
          .map((form) => form.formKey)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getConfiguredParameterNames]
  );

  useEffect(() => {
    if (!isNil(initialAssetId)) {
      loadAssetDetailsData(initialAssetId);
      loadParametersData(assetId, preloadedParameters);
      loadConnectedDevicesData(assetId);
    } else {
      setAssetNameplate(defaultNameplate);
      setAssetNameplateRestorePoint(defaultNameplate);
      setAssetIdRestorePoint(initialAssetId);
      setConnectedDevicesRestorePoint([]);
    }

    return () => {
      cleanAssetDetailsData(initialAssetId);
      setAssetNameplate(null);
      setAssetNameplateRestorePoint(null);
      setParametersRestorePoint(null);
      setConnectedDevices([]);
      setConnectedDevicesRestorePoint([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAssetId]);

  useEffect(() => {
    if (
      isEditMode &&
      initialConnectedDevicesData?.status === Statuses.Succeeded
    ) {
      const configurableConnectedDevices = mapToConfigurableConnectedDevices({
        connectedDevices: initialConnectedDevicesData?.data.AssetDevices
      });

      setConnectedDevices(configurableConnectedDevices);
      setConnectedDevicesRestorePoint(configurableConnectedDevices);
      setAssetDeviceLastUpdatedDate(
        initialConnectedDevicesData?.data.LastUpdatedDate
      );
      setConnectedDeviceParameterNames(
        initialConnectedDevicesData?.data?.AssetParameterNames
      );
    }
  }, [
    isEditMode,
    initialConnectedDevicesData,
    mapToConfigurableConnectedDevices
  ]);

  useEffect(() => {
    if (
      assetNameplate === null &&
      isEditMode &&
      initialAssetDetails?.status === Statuses.Succeeded
    ) {
      setAssetNameplate(initialAssetDetails?.data.NameplateWithModelInfo);
      setAssetNameplateRestorePoint(
        initialAssetDetails?.data.NameplateWithModelInfo
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, initialAssetDetails]);

  useEffect(() => {
    if (
      parameters === null &&
      isEditMode &&
      initialParameters?.status === Statuses.Succeeded
    ) {
      setParameters(initialParameters?.data);
      setParametersRestorePoint(initialParameters?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, initialParameters]);

  const changeNameplateField = useCallback(
    (fieldName: string, fieldValue: number | boolean | string | Date) => {
      if (fieldName === "AssetId") {
        setAssetId(fieldValue.toString());
      } else {
        const newAssetDetails = update(assetNameplate, {
          [fieldName]: { $set: fieldValue }
        });
        setAssetNameplate(newAssetDetails);
      }
    },
    [assetNameplate]
  );

  const changeConnectedDeviceGeneralInformationField = useCallback(
    (config: {
      fieldName: IParameter["InputName"];
      fieldValue: IParameter["Value"];
    }) => {
      const { fieldName, fieldValue } = config;

      const modifiedField = connectedDevices.find(({ Guid }) =>
        fieldName.includes(Guid)
      );

      const modifiedFieldOriginalName = Object.keys(modifiedField).find((key) =>
        fieldName.includes(key)
      );

      setConnectedDevices((assetDevices) =>
        [
          ...assetDevices.filter(({ Guid }) => modifiedField.Guid !== Guid),
          {
            ...modifiedField,
            [modifiedFieldOriginalName]: fieldValue
          }
        ].sort((a, b) => a.Order - b.Order)
      );
    },
    [connectedDevices]
  );

  const changeInspectionField = useCallback(
    (
      key: string,
      value: string | number | boolean | Date,
      fieldType: TypedValue,
      date?: Date,
      oldFieldName?: string
    ) => {
      const inspectionData = inspection.filter(
        (p) => p.InputName !== key && p.InputName !== oldFieldName
      );
      if (!isNil(value)) {
        inspectionData.push({
          InputName: key,
          Value: value,
          Timestamp: date,
          ValueType: fieldType
        });
      }
      setInspection(inspectionData);
    },
    [inspection]
  );

  const getInspectionWithDateForFields = useCallback(
    (date: Date, keys: string[]) => {
      return inspection.map((p) =>
        keys.find((k) => k === p.InputName) !== p.InputName
          ? p
          : {
              InputName: p.InputName,
              Value: p.Value,
              Timestamp: date,
              ValueType: p.ValueType
            }
      );
    },
    [inspection]
  );

  const changeInspectionDateForFields = useCallback(
    (date: Date, keys: string[]) => {
      setInspection(getInspectionWithDateForFields(date, keys));
    },
    [getInspectionWithDateForFields]
  );

  const addAttribute = useCallback(
    (attributeName: string, attributeValue: string) => {
      if (Object.keys(assetNameplate.Attributes).length === 0) {
        const newAssetDetails = update(assetNameplate, {
          Attributes: { $set: { [attributeName]: attributeValue } }
        });
        setAssetNameplate(newAssetDetails);
      } else {
        const newAssetDetails = update(assetNameplate, {
          Attributes: { $merge: { [attributeName]: attributeValue } }
        });
        setAssetNameplate(newAssetDetails);
      }
    },
    [assetNameplate]
  );

  const editAttribute = useCallback(
    (
      previousAttributeName: string,
      attributeName: string,
      attributeValue: string
    ) => {
      const changedAttributes: { [key: string]: string } = {};
      Object.keys(assetNameplate.Attributes).forEach((key) => {
        if (key === previousAttributeName)
          changedAttributes[attributeName] = attributeValue;
        else changedAttributes[key] = assetNameplate.Attributes[key];
      });
      const newAssetNameplate = update(assetNameplate, {
        Attributes: { $set: changedAttributes }
      });
      setAssetNameplate(newAssetNameplate);
    },
    [assetNameplate]
  );

  const deleteAttribute = useCallback(
    (attributeName: string) => {
      const newAssetDetails = update(assetNameplate, {
        Attributes: { $unset: [attributeName] }
      });
      setAssetNameplate(newAssetDetails);
    },
    [assetNameplate]
  );

  const resetConnectedDevicesValidationState = useCallback(
    (
      connectedDevices: IConfigurableConnectedDevice[]
    ): IConfigurableConnectedDevice[] => {
      return connectedDevices.map((device) => ({
        ...device,
        RegisterConfiguration: device?.RegisterConfiguration.map((config) => ({
          ...config,
          cells: config.cells.map((cell) => ({
            ...cell,
            valid: true,
            modified: false
          }))
        }))
      }));
    },
    []
  );

  const saveConnectedDevices = useCallback((): Promise<string> => {
    const url = UrlService.getApiUrl(
      config.api.configurationTool.assetDeviceUrl,
      {
        assetId
      }
    );

    return new Promise(function (resolve, reject) {
      EndpointService.put<
        IConnectedDevice[],
        Pick<IConnectedDevicesData, "LastUpdatedDate">
      >(
        url,
        (request, data) => {
          const submittedConnectedDevices =
            resetConnectedDevicesValidationState(connectedDevices);
          resetFormsState(DataTypes.ConnectedDevices);
          setAssetDeviceLastUpdatedDate(data.LastUpdatedDate);
          setConnectedDevices(submittedConnectedDevices);
          resolve(request);
          setConnectedDevicesRestorePoint(submittedConnectedDevices);
        },
        (request) => {
          reject(request.responseJSON);
        },
        null,
        mapToConnectedDevicesPayload(connectedDevices),
        "application/json; charset=utf-8"
      );
    });
  }, [
    assetId,
    mapToConnectedDevicesPayload,
    connectedDevices,
    resetFormsState,
    resetConnectedDevicesValidationState
  ]);

  const saveAssetNameplate = useCallback((): Promise<string> => {
    const url = UrlService.getApiUrl(
      config.api.configurationTool.nameplateUrl,
      {
        assetId: assetId
      }
    );

    return new Promise(function (resolve, reject) {
      EndpointService.post<IAssetNameplate>(
        url,
        (request, data) => {
          resolve(request);
          setAssetNameplateRestorePoint(assetNameplate);
          setAssetId(assetId);
          setAssetIdRestorePoint(assetId);
          setIsEditMode(true);
          resetFormsState(DataTypes.Nameplate);
        },
        (request) => {
          reject(request.responseJSON);
        },
        assetNameplate,
        "application/json; charset=utf-8"
      );
    });
  }, [assetId, assetNameplate, resetFormsState]);

  const saveInspection = useCallback(() => {
    const url = UrlService.getApiUrl(
      config.api.configurationTool.inspectionUrl,
      {
        assetId: assetId
      }
    );

    const updateParameters = (inspection: IParameter[]) => {
      const updatedParameters: IParametersData = { ...parameters };
      inspection.forEach((p) => {
        if (preloadedParameters.includes(p.InputName)) {
          updatedParameters[p.InputName] = p;
        }
      });
      setParameters(updatedParameters);
      setParametersRestorePoint(updatedParameters);
    };

    const getInspectionData = (isDetailsFormTouched: boolean) => {
      return isDetailsFormTouched
        ? getInspectionWithDateForFields(new Date(), preloadedParameters)
        : inspection;
    };

    return new Promise<string>(function (resolve, reject) {
      const detailsTouched = forms.find(
        (form) => form.formType === FormTypes.PreloadedInspection
      )?.touched;
      const inspectionData = getInspectionData(detailsTouched);

      EndpointService.post<IParameter[]>(
        url,
        (request, data) => {
          resolve(request);
          if (detailsTouched) updateParameters(inspectionData);
          forms.forEach((f) => f.form?.resetFields());
          setInspection([]);
          resetFormsState();
        },
        (request) => {
          reject(request.responseJSON);
        },
        inspectionData,
        "application/json; charset=utf-8"
      );
    });
  }, [
    assetId,
    parameters,
    preloadedParameters,
    getInspectionWithDateForFields,
    inspection,
    resetFormsState,
    forms
  ]);

  const addParameter = useCallback(
    (parameterName: string, parameterType: TypedValue) => {
      const newParameters = update(customParameters, {
        $push: [
          {
            fieldName: {
              id: parameterName,
              defaultMessage: parameterName
            },
            fieldKey: parameterName,
            fieldType: FieldTypes.Parameter,
            dataType: parameterType,
            inputType: parameterType,
            disabled: true
          }
        ]
      });
      setCustomParameters(newParameters);
    },
    [customParameters]
  );

  const editParameter = useCallback(
    (
      previousParameterName: string,
      parameterName: string,
      parameterType: TypedValue
    ) => {
      const newParameter = {
        fieldName: {
          id: parameterName,
          defaultMessage: parameterName
        },
        fieldKey: parameterName,
        fieldType: FieldTypes.Parameter,
        dataType: parameterType,
        inputType: parameterType,
        disabled: true
      };
      const parameterIndex = customParameters.findIndex(
        (parameter) => parameter.fieldKey === previousParameterName
      );
      const newParameters = update(customParameters, {
        [parameterIndex]: { $set: newParameter }
      });
      setCustomParameters(newParameters);
    },
    [customParameters]
  );

  const deleteParameter = useCallback(
    (parameterName: string) => {
      const itemIndex = customParameters.findIndex(
        (field) => field.fieldKey === parameterName
      );
      const newParameters = update(customParameters, {
        $splice: [[itemIndex, 1]]
      });
      setCustomParameters(newParameters);
    },
    [customParameters]
  );

  const resetData = useCallback(() => {
    setAssetNameplate(assetNameplateRestorePoint);
    setParameters(parametersRestorePoint);
    setAssetId(assetIdRestorePoint);
    setInspection([]);
    resetFormsState();
    setResetFormsNeeded(true);
    setConnectedDevices(connectedDevicesRestorePoint);
    setConnectedDeviceAction(null);
  }, [
    assetNameplateRestorePoint,
    parametersRestorePoint,
    assetIdRestorePoint,
    resetFormsState,
    connectedDevicesRestorePoint
  ]);

  useEffect(() => {
    if (resetFormsNeeded) {
      setResetFormsNeeded(false);
      forms.forEach((item) => {
        item.form?.resetFields();
      });
    }
  }, [forms, resetFormsNeeded]);

  const initialAttributeKeys = useMemo(() => {
    return assetNameplateRestorePoint
      ? Object.keys(assetNameplateRestorePoint?.Attributes)
      : [];
  }, [assetNameplateRestorePoint]);

  return (
    <AssetModalContext.Provider
      value={{
        isEditMode,
        assetId,
        assetNameplate,
        inspection,
        initialAttributeKeys,
        parameters,
        configuredParameterNames,
        customParameters,
        changeNameplateField,
        changeInspectionField,
        changeInspectionDateForFields,
        addAttribute,
        editAttribute,
        deleteAttribute,
        saveAssetNameplate,
        saveInspection,
        resetData,
        menuItems,
        selectedMenu,
        blockActions,
        validateMessages,
        getFormInfo,
        registerForm,
        changeSelectedMenu,
        updateFormItemTouchedInfo,
        updateFormItemInvalidInfo,
        isDataTypeTouched,
        validForms,
        changeTabsInfo,
        addParameter,
        editParameter,
        deleteParameter,
        connectedDevices,
        setConnectedDevices,
        changeConnectedDeviceGeneralInformationField,
        saveConnectedDevices,
        connectedDeviceLastUpdatedDate,
        connectedDeviceParameterNames,
        connectedDeviceAction,
        setConnectedDeviceAction,
        updateFormItemValidationInfo,
        mapToConnectedDevicesPayload,
        mapToConfigurableConnectedDevices
      }}
    >
      {assetNameplate ? children : <Processing />}
    </AssetModalContext.Provider>
  );
};

const mapStateToProps = (
  state: IState,
  { initialAssetId }: IAssetModalContextProviderProps
) => ({
  initialAssetDetails: getNameplateSelector(state)(initialAssetId),
  initialParameters: getParameterSelector(state)(initialAssetId),
  initialConnectedDevicesData:
    getConnectedDevicesDataSelector(state)(initialAssetId)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any>) => {
  const loadAssetDetailsData = (assetId: string) => {
    if (assetId !== null) dispatch(loadAssetDetails(assetId));
  };
  const cleanAssetDetailsData = (assetId: string) => {
    dispatch(removeAssetDetails(assetId));
  };
  const loadParametersData = (assetId: string, parameterNames: string[]) => {
    if (!isNil(assetId)) dispatch(loadParameters(assetId, parameterNames));
  };

  const loadConnectedDevicesData = (assetId: string) =>
    dispatch(loadConnectedDevices(assetId));

  return {
    loadAssetDetailsData,
    cleanAssetDetailsData,
    loadParametersData,
    loadConnectedDevicesData
  };
};

const AssetModalContextProviderWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetModalContextProvider);

export default AssetModalContextProviderWithData;
