// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { MessageDescriptor } from "react-intl";

import IValidator from "common/form/validators/IValidator";

abstract class ModelValidator<T> implements IValidator<T> {
  constructor(private validatePredicate: () => boolean) {}
  validate = (value: T): boolean => {
    return this.validatePredicate();
  };
  errorMessage: MessageDescriptor;
}

export class ModelIdValidator extends ModelValidator<string> {
  errorMessage = {
    defaultMessage: "Invalid value",
    id: "model_integration.model_config.model_data.invalid_value"
  };
}

export class ModelIdUniqueValidator extends ModelValidator<string> {
  errorMessage = {
    defaultMessage: "Model with provided id already exists",
    id: "model_integration.model_config.model_data.unique_id"
  };
}

export class ModelIdStartsFromLetter extends ModelValidator<string> {
  errorMessage = {
    defaultMessage: "Model id must start with letter",
    id: "model_integration.model_config.model_data.start_letter"
  };
}

export class ModelUrlValidator extends ModelValidator<string> {
  errorMessage = {
    defaultMessage: "Invalid URL",
    id: "model_integration.model_config.model_data.invalid_url"
  };
}
