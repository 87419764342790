// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";

import MaintenanceScoreFormatted from "common/formatters/MaintenanceScore";

import "./MaintenancePriority.less";

interface IMaintenancePriorityProps {
  onClick: () => void;
  value: number;
}

const MaintenancePriority = ({ onClick, value }: IMaintenancePriorityProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick();
  };

  return value !== undefined && value != null ? (
    <a
      className="column maintenance-priority anchor"
      href="#/"
      onClick={handleClick}
    >
      <MaintenanceScoreFormatted value={value} />
    </a>
  ) : (
    <span className="column maintenance-priority">
      <MaintenanceScoreFormatted value={value} />
    </span>
  );
};

export default MaintenancePriority;
