// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { FC, useEffect, useMemo, useState } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";

export interface ISwitchItem {
  id: string;
  message: MessageDescriptor;
}

export interface ISwitchProps {
  className?: string;
  defaultSelected?: string;
  disabled?: boolean;
  items: ISwitchItem[];
  label?: MessageDescriptor;
  onChange?: (id: string) => void;
  onInit?: (id: string) => void;
}

export interface ISwitchState {
  selected: string;
}

const Switch: FC<ISwitchProps> = ({
  onInit,
  className = "",
  disabled,
  items,
  label,
  onChange,
  defaultSelected
}) => {
  const [selected, setSelected] = useState(defaultSelected);

  useEffect(() => {
    setSelected(defaultSelected);
    if (onInit) onInit(defaultSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelected]);

  const handleChange = (e: RadioChangeEvent) => {
    setSelected(e.target.value);
    if (onChange) onChange(e.target.value);
  };

  const renderItems = useMemo(() => {
    return items.map((i: ISwitchItem) => {
      return (
        <Radio.Button
          key={i.id}
          value={i.id}
          checked={i.id === selected}
          className={
            i.id === selected ? "ant-radio-button-wrapper-checked" : ""
          }
          disabled={disabled}
        >
          <FormattedMessage {...i.message} />
        </Radio.Button>
      );
    });
  }, [items, selected, disabled]);

  return (
    <div className={`switch-control ${className}`}>
      {label && (
        <div className="control-label default-grey-label">
          <FormattedMessage {...label} />
        </div>
      )}
      <Radio.Group defaultValue={defaultSelected} onChange={handleChange}>
        {renderItems}
      </Radio.Group>
    </div>
  );
};

export default Switch;
