// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import BrowserService from "core/app/services/BrowserService";
import * as React from "react";
import { FormattedMessage, FormattedDate, MessageDescriptor } from "react-intl";

interface IHeaderCellProps {
  message: MessageDescriptor;
  className: string;
  date?: string;
}

const HeaderCell = ({
  message: { id, defaultMessage },
  className,
  date
}: IHeaderCellProps) => (
  <div className={`grid-cell default-grey-label ${className ? className : ""}`}>
    <FormattedMessage id={id} defaultMessage={defaultMessage} />
    {date && (
      <span className="date-value" data-qa="subfactors-date">
        {"("}
        <FormattedDate value={new Date(date)} />
        {")"}
      </span>
    )}
  </div>
);

interface ISubfactorsGridHeaderProps {
  previousDate: string;
  currentDate: string;
}

const SubfactorsGridHeader = ({
  previousDate,
  currentDate
}: ISubfactorsGridHeaderProps) => {
  const isSafari = BrowserService.isSafari();

  return (
    <div className={`grid-header ${isSafari ? "safari" : ""}`}>
      <div className="grid-row">
        <HeaderCell
          message={{
            id: "detail_page.widgets.subfactors_grid.header_name",
            defaultMessage: "Name"
          }}
          className="name"
        />
        <HeaderCell
          message={{
            id: "detail_page.widgets.subfactors_grid.header_previous_value",
            defaultMessage: "Previous Value"
          }}
          className="value"
          date={previousDate}
        />
        <HeaderCell
          message={{
            id: "detail_page_widgets.subfactors_grid.header_current_value",
            defaultMessage: "Current Value"
          }}
          className="value"
          date={currentDate}
        />
        <HeaderCell
          message={{
            id: "detail_page.widgets.subfactors_grid.header_data_quality",
            defaultMessage: "Data Quality"
          }}
          className="confidence"
        />
      </div>
    </div>
  );
};

export default SubfactorsGridHeader;
