// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import IThresholdCorrection from "@apm/widgets/build/components/Parameters/models/IThresholdCorrection";
import { isNil } from "lodash";
import IParameterThresholdCorrection from "../models/IThresholdCorrection";

const mapToThresholdCorrection = (
  correction: IParameterThresholdCorrection | undefined
): IThresholdCorrection => {
  if (isNil(correction)) return undefined;

  return {
    nameAndSurname: correction.NameAndSurname,
    newValue: correction.NewValue,
    oldValue: correction.OldValue,
    updateDate: correction.UpdateDate
      ? new Date(correction.UpdateDate)
      : undefined
  };
};

export default mapToThresholdCorrection;
