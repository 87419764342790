// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Actions } from "core/app/actions/settings/CustomerActions";
import ICustomer from "core/app/models/ICustomer";
import IVisualizationsAssetGrid from "core/app/models/IVisualizationsAssetGrid";
import IVisualizationsConfiguration from "core/app/models/IVisualizationsConfiguration";
import Data from "core/data/models/Data";
import { IAjaxAction } from "reducers/Index";
import { Reducer } from "redux/index";

export interface IState {
  customer: Data<ICustomer>;
  customerVisualizations: Data<IVisualizationsConfiguration>;
  customerVisualizationsAssetGrid: Data<IVisualizationsAssetGrid>;
}

const customerReducer: Reducer<IState> = (
  state: IState,
  action: IAjaxAction<ICustomer> | IAjaxAction<IVisualizationsConfiguration>
) => {
  switch (action.type) {
    case Actions.Settings_SetCustomer:
      return Object.assign({}, state, {
        customer: new Data(
          (action as IAjaxAction<ICustomer>).request,
          (action as IAjaxAction<ICustomer>).data
        )
      } as IState);
    case Actions.Settings_SetCustomerVisualizations:
      return {
        ...state,
        customerVisualizations: new Data(
          (action as IAjaxAction<IVisualizationsConfiguration>).request,
          (action as IAjaxAction<IVisualizationsConfiguration>).data
        )
      };
    case Actions.Settings_SetCustomerVisualizationsAssetGrid:
      return {
        ...state,
        customerVisualizationsAssetGrid: new Data(
          (action as IAjaxAction<IVisualizationsAssetGrid>).request,
          (action as IAjaxAction<IVisualizationsAssetGrid>).data
        )
      };
    default:
      return state;
  }
};

export default customerReducer;
