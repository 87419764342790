// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import common, { IState as ICommonState } from "common/CommonReducer";
import app, { IState as IAppState } from "core/app/reducers/AppReducer";
import data, { IState as IDataState } from "core/data/reducers/DataReducer";
import familyInfo, {
  IState as IFamilyInfoState
} from "features/detailpage/features/familyinfo/reducers/FamilyInfoReducer";
import rogersRatio, {
  IState as IRogersRatioState
} from "features/detailpage/features/rogersratio/reducers/RogersRatioReducer";
import detailPage, {
  IState as IDetailPageState
} from "features/detailpage/reducers/DetailPageReducer";
import familyAnalytics, {
  IState as IFamilyAnalyticsState
} from "features/familyanalytics/reducers/FamilyAnalyticsReducer";
import issues, {
  IState as IIssuesState
} from "features/issues/reducers/IssuesReducer";
import mainDashboard, {
  IState as IMainDashboardState
} from "features/MainDashboard/reducers/MainDashboardReducer";
import notifications, {
  IState as INotificationState
} from "features/notifications/reducers/NotificationsReducer";
import { Action, combineReducers } from "redux";

export interface IAjaxAction<T> extends Action {
  type: string;
  request: JQuery.jqXHR;
  data: T;
}

export interface IState {
  app: IAppState;
  data: IDataState;
  common: ICommonState;
  detailPage: IDetailPageState;
  issues: IIssuesState;
  notifications: INotificationState;
  mainDashboard: IMainDashboardState;
  rogersRatio: IRogersRatioState;
  familyInfo: IFamilyInfoState;
  familyAnalytics: IFamilyAnalyticsState;
}

const reducer = combineReducers({
  app,
  data,
  common,
  detailPage,
  issues,
  notifications,
  mainDashboard,
  rogersRatio,
  familyInfo,
  familyAnalytics
});

export default reducer;
