// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { ChartAPI } from "c3";

import C3Chart from "common/chart/components/C3Chart";
import AgingOperationsBarChartLegend from "features/detailpage/features/aging/components/AgingOperationsBarChartLegend";

import useAgingOperationsConfiguration from "features/detailpage/features/aging/hooks/useAgingOperationsConfiguration";

import "./AgingOperationsBarChart.less";

interface IAgingOperationsBarChartProps {
  agingOperations: number;
  warningThreshold: number;
  alarmThreshold: number;
  isFaultOperations?: boolean;
}

const AgingOperationsBarChart = ({
  agingOperations,
  warningThreshold,
  alarmThreshold,
  isFaultOperations = false
}: IAgingOperationsBarChartProps) => {
  const { configuration, legendData } = useAgingOperationsConfiguration({
    agingOperations,
    warningThreshold,
    alarmThreshold
  });
  let chartApi: ChartAPI;

  return (
    <>
      <div
        className={`${
          isFaultOperations
            ? "aging-operations-chart--fault"
            : "aging-operations-chart--total"
        } aging-operations-chart`}
      >
        <C3Chart
          configuration={configuration}
          onRendered={chart => (chartApi = chart)}
        />
      </div>
      <AgingOperationsBarChartLegend
        plots={legendData}
        series={"Aging Total Operations"}
        onFocus={(series: string, id: string) => {
          chartApi.focus(id);
        }}
        onRevert={(series: string) => {
          chartApi.revert();
        }}
      />
    </>
  );
};

export default AgingOperationsBarChart;
