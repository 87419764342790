// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import {
  colorStatusOrange,
  colorStatusRed
} from "@pg/common/build/styles/ColorVariables";
import { IntlShape } from "react-intl";
import SotTypes from "../models/SotTypes";

const getLegend =
  (sotType: string, intl: IntlShape) =>
  (chartGroupName: string, dataSource: string) => {
    return sotType === "MineralOilIECNetworkTransformer"
      ? getMineralOilIECLegend(sotType, intl)
      : sotType === "SiliconeIEEENetworkTransformer"
      ? getSiliconeIEEElegend(sotType, intl)
      : null;
  };

const getMineralOilIECLegend = (sotType: SotTypes, intl: IntlShape) => [
  {
    name: sotType,
    displayName: intl.formatMessage({
      defaultMessage: "Mineral Oil IEC",
      id: "detail_page.widgets.analytics.transformers.sot.mineral_oil_iec"
    }),
    isDataSeries: false,
    type: "info"
  },
  {
    name: "Alarm",
    displayName: intl.formatMessage({
      defaultMessage: "Alarm",
      id: "detail_page.widgets.analytics.transformers.sot.alarm"
    }),
    isDataSeries: false,
    type: "region",
    color: colorStatusRed
  },
  {
    name: "Alert",
    displayName: intl.formatMessage({
      defaultMessage: "Alert",
      id: "detail_page.widgets.analytics.transformers.sot.alert"
    }),
    isDataSeries: false,
    type: "region",
    color: colorStatusOrange
  }
];

const getSiliconeIEEElegend = (sotType: string, intl: IntlShape) => [
  {
    name: sotType,
    displayName: intl.formatMessage({
      defaultMessage: "Silicone IEEE",
      id: "detail_page.widgets.analytics.transformers.sot.silicone_ieee"
    }),
    isDataSeries: false,
    type: "info"
  },
  {
    name: "Alarm",
    displayName: intl.formatMessage({
      defaultMessage: "Alarm",
      id: "detail_page.widgets.analytics.transformers.sot.alarm"
    }),
    isDataSeries: false,
    type: "region",
    color: colorStatusRed
  },
  {
    name: "Alert",
    displayName: intl.formatMessage({
      defaultMessage: "Alert",
      id: "detail_page.widgets.analytics.transformers.sot.alert"
    }),
    isDataSeries: false,
    type: "region",
    color: colorStatusOrange
  }
];

export default getLegend;
