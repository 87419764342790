// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { isEmpty } from "lodash";

import useAgingContactWear from "features/detailpage/features/aging/hooks/useAgingContactWear";
import { IAgingContactWear } from "features/detailpage/features/aging/models/IAgingContactWear";
import Data, { Statuses } from "core/data/models/Data";

import Processing from "components/common/Processing";
import {
  WidgetNoDataMessage,
  WidgetErrorMessage
} from "components/common/widget/Widget";

import WearI2tChart from "common/WearI2tChart";

import "./AgingContactWear.less";

interface IContactWearStateProps {
  agingContactWear: Data<IAgingContactWear>;
}

interface IContactWearProps extends IContactWearStateProps {}

export default ({ agingContactWear }: IContactWearProps) => {
  const { isDataNull, height, title } = useAgingContactWear();

  switch (agingContactWear.status) {
    case Statuses.Loading:
      return <Processing className="spinner small dark" />;
    case Statuses.Succeeded:
      return isEmpty(agingContactWear.data) ||
        isDataNull(agingContactWear.data) ? (
        <WidgetNoDataMessage />
      ) : (
        <WearI2tChart
          height={height}
          showWidgetCard={false}
          trendChart={agingContactWear.data.Chart}
          title={title}
          showModalHeaderOnly={true}
        />
      );
    case Statuses.NotFound:
      return <WidgetNoDataMessage />;
    case Statuses.Failed:
      return <WidgetErrorMessage />;
    default:
      return (
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      );
  }
};
