// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getDegradationScoreTrendSelector = createSelector(
  (state: IState) => state.detailPage.degradationScore,
  degradationScore => degradationScore
);

export default getDegradationScoreTrendSelector;
