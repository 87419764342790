// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import { useMemo } from "react";

import RoutableTabs from "common/RoutableTabs";
import useAgingSubTabs from "features/detailpage/features/aging/hooks/useAgingSubTabs";

import "./AgingSubTabs.less";

interface IAgingSubTabsProps {
  assetId: string;
}

const AgingSubTabs = ({ assetId }: IAgingSubTabsProps) => {
  const items = useAgingSubTabs(assetId);
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);

  return (
    <div className="aging-sub-tabs" data-qa="aging-inner-tabs">
      <RoutableTabs tabs={items} noBorder={true} searchParams={searchParams} />
    </div>
  );
};

export default AgingSubTabs;
