// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import RoutableTabs from "common/RoutableTabs";
import ElectricalCharts from "./ElectricalCharts";
import UltraHighFrequencyCharts from "./UltraHighFrequencyCharts";

interface IPartialDischargeProps {
  assetId: string;
  modelId: string;
  modelImplementationId: string;
}

const PartialDischarge = ({
  assetId,
  modelId,
  modelImplementationId
}: IPartialDischargeProps) => {
  const intl = useIntl();
  const items = useMemo(
    () => [
      {
        id: "Electrical",
        name: intl.formatMessage({
          id: "detail_page.widgets.analytics.transformers.partial_discharge.electrical",
          defaultMessage: "Electrical"
        }),
        Content: () => (
          <ElectricalCharts
            assetId={assetId}
            modelId={modelId}
            modelImplementationId={modelImplementationId}
          />
        )
      },
      {
        id: "UltraHighFrequency",
        name: intl.formatMessage({
          id: "detail_page.widgets.analytics.transformers.partial_discharge.high_frequency",
          defaultMessage: "Ultra high frequency"
        }),
        Content: () => (
          <UltraHighFrequencyCharts
            assetId={assetId}
            modelId={modelId}
            modelImplementationId={modelImplementationId}
          />
        )
      }
    ],
    [assetId, intl, modelId, modelImplementationId]
  );

  return (
    <RoutableTabs
      tabs={items}
      noBorder
      searchParams={new SearchParams({ assetId })}
    />
  );
};

export default PartialDischarge;
