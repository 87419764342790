// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import Data from "../../../core/data/models/Data";
import { IState } from "../../../reducers/Index";
import { dataKey } from "../actions/loadReports";
import { IReportInfo } from "../models/IReportInfo";

const getReportsSelector = createSelector<
  IState,
  Data<IReportInfo[]>,
  Data<IReportInfo[]>
>(
  (state: IState) => state.data[dataKey],
  (reports) => reports
);

export default getReportsSelector;
