// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";
import { IntlShape, useIntl } from "react-intl";

export interface ISubscoreName {
  nameId: string;
  modelId: string;
  implementationId: string;
}

const SubscoreName = ({ nameId, modelId, implementationId }: ISubscoreName) => {
  const intl = useIntl();
  const formattedValue = SubscoreService.format(nameId, modelId, implementationId, intl);
  return <span>{formattedValue}</span>;
};

export default SubscoreName;

export class SubscoreService {
  private static readonly subscoreIntlPrefix = "subscore";

  static format(nameId: string, modelId: string, implementationId: string, intl: IntlShape): string {
    const subscoreId = nameId.trim();
    return intl.formatMessage({
      id: `${modelId}.${this.subscoreIntlPrefix}.${subscoreId}`,
      defaultMessage: intl.formatMessage({
        id: `${implementationId}.${this.subscoreIntlPrefix}.${subscoreId}`,
        defaultMessage: nameId
      })
    });
  }
}
