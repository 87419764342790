// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";
import { useIntl } from "react-intl";

import "./BushingOfflineChartStaticLegend.less";
import BushingOfflineChartService from "features/detailpage/features/bushingsoffline/services/BushingOfflineChartService";
import IBushingOfflineStaticLegendItem from "features/detailpage/features/bushingsoffline/models/IBushingOfflineStaticLegendItem";

export interface IBushingOfflineChartStaticLegendProps {
  thresholds?: string[];
}

const BushingOfflineChartStaticLegend = ({
  thresholds
}: IBushingOfflineChartStaticLegendProps) => {
  const intl = useIntl();
  const legendItems: IBushingOfflineStaticLegendItem[] = BushingOfflineChartService.getLegendItems(
    thresholds,
    intl
  );

  return (
    <div className="bushing-threshold-legend">
      {legendItems.map((item, index) => (
        <div className="bushing-threshold-legend-item" key={index}>
          <span className="bushingThresholdLegendItemBox">
            <span
              className={`bushingThresholdLegendItemBox_dotted ${item.className}`}
            />
          </span>
          <span className="bushingThresholdLegendItemText">{item.name}</span>
        </div>
      ))}
    </div>
  );
};

export default BushingOfflineChartStaticLegend;
