// Copyright 2016-2022 Hitachi Energy. All rights reserved.

import Icon from "@pg/common/build/components/Icon";
import { notifications } from "@pg/common/build/components/Notifications";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { colorIconAccent } from "styles/ColorVariables";
import { deleteAsset } from "./actions/DeleteAssetAction";

interface IEditDeleteAssetActionsProps {
  assetId: string;
  onAssetModalOpen: (assetId: string) => void;
  className?: string;
}

const EditDeleteAssetActions = ({
  assetId,
  onAssetModalOpen,
  className
}: IEditDeleteAssetActionsProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleDeleteAssetConfirm = useCallback(
    async (assetId) => {
      try {
        await deleteAsset(dispatch, assetId);
        notifications.success({
          message: intl.formatMessage({
            id: "asset_query.grid.close.success",
            defaultMessage: "Asset was deleted"
          })
        });
      } catch (e) {
        notifications.success({
          message: intl.formatMessage(
            {
              id: "asset_query.grid.close.success",
              defaultMessage: "Cannot delete asset. Response code: {code}"
            },
            {
              code: e.status
            }
          )
        });
      }
    },
    [dispatch, intl]
  );

  const handleDeleteAsset = useCallback(
    (assetId) => {
      notifications.confirm({
        title: intl.formatMessage({
          defaultMessage: "Are you sure you want to delete this asset?",
          id: "asset_query.grid.opened_actions_confirmation"
        }),
        onOk: () => {
          handleDeleteAssetConfirm(assetId);
        }
      });
    },
    [handleDeleteAssetConfirm, intl]
  );

  return (
    <div className={className}>
      <Icon
        name="border_color"
        onClick={() => {
          onAssetModalOpen(assetId);
        }}
        size="xs"
        variant="outlined"
      />
      <Icon
        name="delete"
        size="xs"
        variant="outlined"
        onClick={() => handleDeleteAsset(assetId)}
      />
    </div>
  );
};

const StyledEditDeleteAssetActions = styled(EditDeleteAssetActions)`
  display: flex;
  flex-direction: column;

  .icon {
    &:hover {
      color: ${colorIconAccent};
    }
  }
`;

export default StyledEditDeleteAssetActions;
