// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { ChartConfiguration } from "c3";
import {
  colorBlue50,
  colorStatusOrange,
  colorStatusYellow
} from "styles/ColorVariables";

export const LTCPercentageUsedId = "percentage-used";
export const LTCWarningLegendId = "ltc-warning";
export const LTCAlarmLegendId = "ltc-alarm";

export const colors = {
  percentageUsed: colorBlue50,
  warning: colorStatusYellow,
  alarm: colorStatusOrange
};

function getDefaultConfiguration(): ChartConfiguration {
  return {
    size: {
      height: 120
    },
    padding: { left: 10, right: 15 },
    tooltip: {
      show: false
    },
    data: {
      type: "bar"
    },
    axis: {
      rotated: true,
      y: {
        min: 0,
        max: 100,
        padding: { top: 0, bottom: 0 },
        tick: {
          format: (v: any) => `${v}%`
        }
      },
      x: {
        show: false
      }
    },
    grid: {
      y: {
        show: true,
        lines: [
          { value: 0, class: "start-line" },
          { value: 100, class: "end-line" }
        ]
      }
    },
    regions: [],
    legend: {
      hide: true
    },
    bar: {
      width: {
        ratio: 0.3
      }
    }
  };
}

export default (
  percentageUsed: number,
  warningThreshold: number,
  alarmThreshold: number
) => {
  const configuration = getDefaultConfiguration();
  configuration.data.columns = [[LTCPercentageUsedId, percentageUsed]];

  if (warningThreshold) {
    configuration.grid.y.lines.push({
      value: warningThreshold,
      class: LTCWarningLegendId
    });
    configuration.regions.push({
      axis: "y",
      start: warningThreshold,
      end: alarmThreshold,
      class: LTCWarningLegendId
    });
  }

  if (alarmThreshold) {
    configuration.grid.y.lines.push({
      value: alarmThreshold,
      class: LTCAlarmLegendId
    });
    configuration.regions.push({
      axis: "y",
      start: alarmThreshold,
      end: percentageUsed > 100 ? percentageUsed : 100,
      class: LTCAlarmLegendId
    });
  }

  return configuration;
};
