// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";

import { dataKey } from "../actions/loadParameterHistogram";
import IParameterHistogram from "../models/IParameterHistogram";

const getParameterHistogramSelector = createSelector<
  IState,
  Data<IParameterHistogram[]>,
  Data<IParameterHistogram[]>
>(
  (state: IState) => state.data[dataKey],
  (parameterHistogram) => parameterHistogram
);

export default getParameterHistogramSelector;
