// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import ISelectedFilterSelect from "common/FilterBar/models/ISelectedFilterSelect";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { config } from "utils/AppConfig";
import IAssetWithIssues from "../models/AssetWithIssues";

interface IUseLocationsIssuesListOptions {
  location?: string;
  organization?: string;
  region?: string;
}

interface IRangeData {
  Total: number;
  RowData: IAssetWithIssues[];
}

const dataPortionSize = 10;

const assetsWithIssuesInitialValue: IAssetWithIssues[] = [];

const rowsTotalInitialValue = 0;

const startInitialValue = 0;

const useLocationsIssuesList = ({
  organization,
  region,
  location
}: IUseLocationsIssuesListOptions) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [rowsTotal, setRowsTotal] = useState<number>(rowsTotalInitialValue);
  const [start, setStart] = useState<number>(startInitialValue);
  const [assetsWithIssues, setAssetsWithIssues] = useState<IAssetWithIssues[]>(
    assetsWithIssuesInitialValue
  );

  const selectedFilters: ISelectedFilterSelect[] = useMemo(
    () => [
      {
        Id: "AssetOrganizationWithRegionAndLocation",
        Options: [
          {
            Id: organization || null,
            Options: [
              {
                Id: region || null,
                Options: [
                  {
                    Id: location || null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        Id: "IssueStatus",
        Options: [
          {
            Id: "New"
          },
          {
            Id: "InProgress"
          },
          {
            Id: "Monitor"
          }
        ]
      }
    ],
    [organization, region, location]
  );

  const handleLoadMoreDataSuccessfully = useCallback(
    (newData: IRangeData) => {
      setAssetsWithIssues((assets) => [...assets, ...newData.RowData]);
      setRowsTotal(newData.Total);
      setStart((start) => start + dataPortionSize);
      setLoading(false);
    },
    [setAssetsWithIssues, setRowsTotal, setStart, setLoading]
  );

  const loadMoreData = useCallback(() => {
    if (loading) {
      return;
    }
    setLoading(true);
    const endpointParams = {
      Start: start,
      Size: dataPortionSize,
      Filters: selectedFilters
    };

    EndpointService.post<IRangeData>(
      UrlService.getApiUrl(config.api.locations.issuesUrl),
      (_, data) => {
        handleLoadMoreDataSuccessfully(data);
      },
      () => {
        setLoading(false);
      },
      endpointParams,
      "application/json; charset=utf-8"
    );
  }, [loading, selectedFilters, start, handleLoadMoreDataSuccessfully]);

  const restoreData = useCallback(() => {
    setAssetsWithIssues(assetsWithIssuesInitialValue);
    setRowsTotal(rowsTotalInitialValue);
    setStart(startInitialValue);
  }, [setAssetsWithIssues, setRowsTotal, setStart]);

  useEffect(() => {
    restoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, region, location]);

  useEffect(() => {
    loadMoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsWithIssues, rowsTotal, start]);

  return {
    assetsWithIssues,
    loadMoreData,
    rowsTotal,
    dataPortionSize,
    loading
  };
};

export default useLocationsIssuesList;
