// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { ParametersGroup } from "@apm/widgets/build/widgets/MechanicalParameters";
import IPole from "@apm/widgets/build/widgets/MechanicalParameters/models/IPole";
import { FormattedMessage, IntlShape } from "react-intl";
import IMechanicalPoleGroup from "../models/IMechanicalPoleGroup";
import IMechanicalPoleParameter from "../models/IMechanicalPoleParameter";
import IMechanicalPoleParameterValue from "../models/IMechanicalPoleParameterValue";
import mapToParameterValue from "./mapToParameterValue";

const mapToPole = (
  poleGroup: IMechanicalPoleGroup[],
  intl: IntlShape,
  i: number
): IPole => {
  if (!poleGroup) return null;

  const pole: IPole = {
    name: intl.formatMessage({
      defaultMessage: `Pole ${i}`,
      id: `detail_page.cbk.mechanical.pole_${i}`
    }),
    parametersGroups: poleGroup.map<ParametersGroup>((g) => ({
      name: intl.formatMessage({
        id: `detail_page.cbk.mechanical.${g.Title}`,
        defaultMessage: g.Title
      }),
      columns: g.ColumnsNames?.map((c: string) => ({
        block: (
          <div data-qa="parameter-column-header">
            <FormattedMessage
              defaultMessage={c}
              id={`detail_page.cbk.mechanical.${g.Title}.${c}`}
            />
          </div>
        ),
        inline: intl.formatMessage({
          id: `detail_page.cbk.mechanical.${g.Title}.${c}`,
          defaultMessage: c
        })
      })),
      parameters: g.Parameters?.map((p: IMechanicalPoleParameter) => ({
        id: p.Name,
        name: intl.formatMessage({
          id: `detail_page.cbk.mechanical.${p.Name}`,
          defaultMessage: p.Name
        }),
        unit: p.Unit,
        values: p.Values?.map((v: IMechanicalPoleParameterValue) =>
          mapToParameterValue(p.Name, v, intl)
        )
      }))
    }))
  };

  return pole;
};
export default mapToPole;
