// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { IState } from "reducers/Index";
import { createForm, resetForm } from "../actions/FormActions";
import Form, {
  IFormActions,
  IFormOwnProps,
  IFormData
} from "../components/Form";

export function mapStateToProps(
  state: IState,
  ownProps: IFormOwnProps
): IFormData {
  const form = state.common.forms && state.common.forms.get(ownProps.name);

  return {
    form
  };
}

export function mapDispatchToProps(
  dispatch: Dispatch<Action>,
  ownProps: IFormOwnProps
): IFormActions {
  return {
    createForm: () => {
      createForm(dispatch, ownProps.name);
    },
    resetForm: () => {
      resetForm(dispatch, ownProps.name);
    }
  };
}

export function mergeProps(
  stateProps: IFormData,
  dispatchProps: IFormActions,
  ownProps: IFormOwnProps
) {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Form);
