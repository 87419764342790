// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import PartialDischarge, {
  IPartialDischargeChart
} from "@apm/widgets/build/widgets/PartialDischarge";
import { PartialDischargeReadingService } from "common/formatters/PartialDischargeReadingName";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { spacingXXXLarge } from "styles/StyleVariables";

interface IPartialDischargeChartsProps {
  className?: string;
  charts: IPartialDischargeChart[];
  modelId: string;
  modelImplementationId: string;
}

const PartialDischargeCharts = styled(
  ({
    className,
    charts,
    modelId,
    modelImplementationId
  }: IPartialDischargeChartsProps) => {
    const intl = useIntl();

    return (
      <PartialDischarge
        charts={charts}
        className={className}
        formatXTick={(date, timeRange) =>
          timeRange === "recent" ? intl.formatTime(date) : intl.formatDate(date)
        }
        height={320}
        showWidgetCard={false}
        translations={{
          alert: intl.formatMessage({
            defaultMessage: "Alert",
            id: "global.threshold.alert"
          }),
          adjustToThresholds: intl.formatMessage({
            defaultMessage: "Adjust to thresholds",
            id: "detail_page.adjust_to_thresholds"
          }),
          empty: intl.formatMessage({
            defaultMessage: "NO DATA AVAILABLE",
            id: "detail_page.widgets.analytics.linechart.nodata"
          }),
          expandButtonTooltip: intl.formatMessage({
            defaultMessage: "Expand",
            id: "detail_page.widgets.analytics.transformers.expand"
          }),
          seriesDisplayName: (name) =>
            PartialDischargeReadingService.format(
              modelId,
              modelImplementationId,
              name,
              intl
            ),
          timestamp24Hours: intl.formatMessage({
            defaultMessage: "24 hours",
            id: "detail_page.widgets.analytics.transformers.partial_discharge.switch.24_hours"
          }),
          timestamp30Days: intl.formatMessage({
            defaultMessage: "30 days",
            id: "detail_page.widgets.analytics.transformers.partial_discharge.switch.30_days"
          }),
          warning: intl.formatMessage({
            defaultMessage: "Warning",
            id: "global.threshold.warning"
          }),
          lastReadingDate: intl.formatMessage({
            defaultMessage: "Last reading / Date",
            id: "detail_page.widgets.analytics.transformers.partial_discharge.legend_title"
          }),
          readingValue: (value: number, unit: string) =>
            intl.formatMessage(
              {
                defaultMessage: "{value} {unit}",
                id: "detail_page.widgets.analytics.transformers.partial_discharge.legend_value"
              },
              {
                value: intl.formatNumber(value, { maximumFractionDigits: 1 }),
                unit
              }
            ),
          readingDate: (date: Date) => intl.formatDate(date)
        }}
      />
    );
  }
)`
  margin-top: -${spacingXXXLarge};
`;

export default PartialDischargeCharts;
