// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import reducer from "reducers/Index";

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
