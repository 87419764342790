// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";

import { dataKey } from "../actions/AssetRiskDashboardActions";
import TrendChart from "../models/TrendChart";

const getTrendChartSelector = createSelector<
  IState,
  Data<TrendChart>,
  Data<TrendChart>
>(
  (state: IState) => state.data[dataKey],
  stationRisk => stationRisk
);

export default getTrendChartSelector;
