// Copyright 2016-2022 Hitachi Energy. All rights reserved.
export class ImpactLevels {
  static readonly Unknown = "Unknown";
  static readonly VeryHigh = "VeryHigh";
  static readonly High = "High";
  static readonly Medium = "Medium";
  static readonly Low = "Low";
  static readonly VeryLow = "VeryLow";
}

export class Impact {
  static readonly Unknown = "Unknown";
  static readonly VeryHigh = "VeryHigh";
  static readonly High = "High";
  static readonly Medium = "Medium";
  static readonly Low = "Low";
  static readonly VeryLow = "VeryLow";
}

export default class ImpactConverter {
  static convertImpactLevelToImpact(impactLevel: string): string {
    switch (impactLevel) {
      case ImpactLevels.VeryHigh:
        return Impact.VeryHigh;
      case ImpactLevels.High:
        return Impact.High;
      case ImpactLevels.Medium:
        return Impact.Medium;
      case ImpactLevels.Low:
        return Impact.Low;
      case ImpactLevels.VeryLow:
        return Impact.VeryLow;
      case ImpactLevels.Unknown:
      default:
        return Impact.Unknown;
    }
  }
}
