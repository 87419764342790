// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import AgingSubTabs from "features/detailpage/features/aging/components/AgingSubTabs";
import AgingTotalOperationsContainer from "features/detailpage/features/aging/containers/AgingOperationsContainer";
import * as React from "react";
import "./AgingTab.less";

const AgingWidget = ({ children }: React.Props<any>) => (
  <div className="card">
    <div className="card-body">{children}</div>
  </div>
);

interface IAgingTabProps {
  assetId: string;
  modelId?: string;
}

class AgingTab extends React.PureComponent<IAgingTabProps> {
  render() {
    const { assetId } = this.props;

    return (
      <div className="aging-tab">
        <div className="container">
          <div className="bootstrap-row">
            <div className="col-24">
              <AgingWidget>
                <AgingTotalOperationsContainer assetId={assetId} />
              </AgingWidget>
            </div>
          </div>
          <div className="bootstrap-row">
            <div className="col-24">
              <AgingSubTabs assetId={assetId} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AgingTab;
