// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";

interface ILabeledRow extends React.Props<ILabeledRow> {
  className?: string;
  message: MessageDescriptor;
}

const LabeledRow = ({ className, message, children }: ILabeledRow) => (
  <div className={`slide-panel-labeled-row ${className}`}>
    <span className="default-grey-label bold" data-qa="status">
      <FormattedMessage {...message} />
    </span>
    <span>{children}</span>
  </div>
);

export default LabeledRow;
