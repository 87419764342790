// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IParamCollection } from "./StandardOilTestsTab";
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";

export default class StandardOilTestsDataService {
  static loadStandardOilParametersTrends = (
    assetId: string,
    onSuccess: (request: JQuery.jqXHR, data: IParamCollection) => void,
    onError: (error: string) => void
  ) => {
    const url = UrlService.getApiUrl(
      config.api.detailPage.assetStandardOilParametersTrendValuesUrl,
      [
        {
          name: "assetId",
          value: assetId
        }
      ]
    );

    return EndpointService.getJson<IParamCollection>(
      url,
      (request, data) => {
        onSuccess(request, data);
      },
      request => {
        onError(request.statusText.concat(" ").concat(request.responseText));
      }
    );
  };
}
