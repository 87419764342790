// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Children, FC, ReactNode } from "react";
import styled from "styled-components";

import { spacingLarge } from "styles/StyleVariables";

interface IContainerProps {
  className?: string;
  children?: ReactNode;
}

const Container: FC<IContainerProps> = ({ className, children }) => {
  return (
    <div className={className}>
      <div className="container-content">{Children.toArray(children)}</div>
    </div>
  );
};

const StyledContainer = styled(Container)`
  & {
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @media (min-width: 1600px) {
      max-width: 1600px;
    }

    .container-content {
      padding: ${spacingLarge};
    }
  }

  & + & {
    padding-top: 0;
  }
`;

export default StyledContainer;
