// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import Processing from "components/common/Processing";
import { Statuses } from "core/data/models/Data";
import React from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import ScrollArea from "react-scrollbar";
import DataIntegrationStore, {
  IModel,
  ModelType
} from "stores/dashboards/dataintegration/DataIntegrationStore";
import { IData } from "utils/WebService";
import "./ModelsPanel.less";

interface IModelsPanelProps {
  intl: IntlShape;
}

interface IModelsPanelState {
  models: IData<IModel[]>;
  searchText: string;
  selectedModel: IModel;
}

class ModelsPanel extends React.Component<
  IModelsPanelProps,
  IModelsPanelState
> {
  constructor(props: IModelsPanelProps) {
    super(props);

    this.state = {
      models: {
        status: null,
        data: null,
        message: null
      },
      searchText: null,
      selectedModel: null
    };

    this.handleChange = this.handleChange.bind(this);
  }

  public componentDidMount() {
    DataIntegrationStore.addChangeListener(this.handleChange);
  }

  public componentWillUnmount() {
    DataIntegrationStore.removeChangeListener(this.handleChange);
  }

  public render() {
    let component: JSX.Element = null;
    if (this.state.models.status === Statuses.Loading) {
      component = this.getLoadingComponent();
    } else if (this.state.models.status === Statuses.Succeeded) {
      component = this.getModelsPanelComponent();
    } else if (this.state.models.status === Statuses.Failed) {
      component = null;
    }

    return component;
  }

  private handleChange() {
    this.setState((prevState, props) => {
      return Object.assign({}, prevState, {
        models: DataIntegrationStore.getModels(),
        selectedModel: DataIntegrationStore.getSelectedModel()
      });
    });
  }

  private getLoadingComponent(): JSX.Element {
    return (
      <div className="models-panel col3vw">
        <Processing className="spinner margin small light" />
      </div>
    );
  }

  private getModelsPanelComponent(): JSX.Element {
    const modelId = this.state.selectedModel
      ? this.state.selectedModel.ModelId
      : "";

    const modelList = (this.state.models.data || []).filter((d) => {
      if (
        this.state.searchText === undefined ||
        this.state.searchText == null ||
        this.state.searchText === ""
      )
        return true;

      return (
        d.ModelId.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >=
        0
      );
    });

    return (
      <div className="models-panel col3vw">
        <div className="search">
          <input
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const val = (e.target as any).value;
              this.setState((prevState, props) => {
                return Object.assign({}, prevState, {
                  searchText: val
                });
              });
            }}
            placeholder={this.props.intl.formatMessage({
              id: "data_integration.models_panel.search_placeholder",
              defaultMessage: "Search"
            })}
            type="search"
          />
          <Icon name="search" />
        </div>
        <div
          className="add-new"
          onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
            DataIntegrationStore.addNewModel(ModelType.Rest, this.props.intl);
          }}
        >
          <Icon name="add" />
          <span className="bold" data-qa="add-new-model">
            <FormattedMessage
              id="data_integration.models_panel.add_new_rest_model"
              defaultMessage="Add new rest model"
            />
          </span>
        </div>
        <ScrollArea smoothScrolling={true}>
          <div className="models-list">
            {modelList.length > 0 ? (
              modelList.map((model: IModel) => (
                <div
                  className={`model bold ${
                    model.ModelId === modelId ? "selected" : ""
                  }`}
                  key={model.ModelId}
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    DataIntegrationStore.selectModel(
                      model.ModelId,
                      this.props.intl
                    );
                  }}
                >
                  {model.ModelId === modelId ? (
                    <Icon name="folder_open" />
                  ) : (
                    <Icon name="folder" />
                  )}
                  <span>{model.ModelId}</span>
                  {model.ModelType !== ModelType.Standard ? (
                    <span className="indicator" />
                  ) : null}
                </div>
              ))
            ) : (
              <div className="no-data">
                <FormattedMessage
                  id="data_integration.models_panel.no_data"
                  defaultMessage="No model configurations exist"
                />
              </div>
            )}
          </div>
        </ScrollArea>
      </div>
    );
  }
}

export default injectIntl(ModelsPanel);
