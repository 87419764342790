// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IntlShape } from "react-intl";

import UrlService from "core/data/services/UrlService";
import { config } from "utils/AppConfig";
import { ScatterPlotParameters } from "../components/ScatterPlotWidget";

export type DataTypes =
  | "Parameter"
  | "DerivativeParameter"
  | "DegradationScore"
  | "DegradationSubScore"
  | "ReplacementScore"
  | "LatestValueDate"
  | "MaintenancePriorityScore";

export default class ScatterPlotWidgetService {
  static resolveApiUrl(
    dataType: DataTypes,
    seriesName: string,
    parameters: ScatterPlotParameters
  ): string {
    switch (dataType) {
      case "Parameter":
        return UrlService.getApiUrl(
          config.api.familyAnalytics.assetParametersUrl,
          {
            assetId: "",
            parameterName: seriesName,
            ...parameters
          }
        );
      case "DerivativeParameter":
        return UrlService.getApiUrl(
          config.api.familyAnalytics.assetDerivativeParametersUrl,
          {
            parameterName: seriesName,
            ...parameters
          }
        );
      case "DegradationScore":
        return UrlService.getApiUrl(
          config.api.familyAnalytics.degradationScoreUrl,
          {
            assetId: "",
            ...parameters
          }
        );
      case "ReplacementScore":
        return UrlService.getApiUrl(
          config.api.familyAnalytics.replacementScoreUrl,
          {
            assetId: "",
            ...parameters
          }
        );
      case "DegradationSubScore":
        return UrlService.getApiUrl(
          config.api.familyAnalytics.degradationSubScoreUrl,
          {
            assetId: "",
            subScoreName: seriesName,
            level: "1",
            ...parameters
          }
        );
      case "MaintenancePriorityScore":
        return UrlService.getApiUrl(
          config.api.familyAnalytics.maintenancePriorityScoreUrl,
          {
            assetId: "",
            ...parameters
          }
        );
      default:
        return null;
    }
  }

  static resolveLabelY(
    parameters: ScatterPlotParameters,
    intl: IntlShape
  ): string {
    switch (parameters.dataTypeY) {
      case "Parameter":
        return parameters.seriesNameY;
      case "DerivativeParameter":
        return parameters.seriesNameY;
      case "DegradationScore":
        return intl.formatMessage({
          id: "family_analytics.filters.degradation_score",
          defaultMessage: "Condition"
        });
      case "ReplacementScore":
        return intl.formatMessage({
          id: "family_analytics.filters.replacement_score",
          defaultMessage: "Replacement Score"
        });
      case "DegradationSubScore":
        return parameters.seriesNameY;
      case "LatestValueDate":
        return intl.formatMessage({
          id: "family_analytics.scatter_plot.labelX.label",
          defaultMessage: "Latest value date"
        });
      case "MaintenancePriorityScore":
        return intl.formatMessage({
          id: "family_analytics.filters.maintenance_priority_score",
          defaultMessage: "Maintenance Priority Score"
        });
      default:
        return null;
    }
  }

  static resolveLabelX(
    parameters: ScatterPlotParameters,
    intl: IntlShape
  ): string {
    switch (parameters.dataTypeX) {
      case "Parameter":
        return parameters.seriesNameX;
      case "DerivativeParameter":
        return parameters.seriesNameX;
      case "DegradationScore":
        return intl.formatMessage({
          id: "family_analytics.filters.degradation_score",
          defaultMessage: "Condition"
        });
      case "ReplacementScore":
        return intl.formatMessage({
          id: "family_analytics.filters.replacement_score",
          defaultMessage: "Replacement Score"
        });
      case "DegradationSubScore":
        return parameters.seriesNameX;
      case "LatestValueDate":
        return intl.formatMessage({
          id: "family_analytics.scatter_plot.labelX.label",
          defaultMessage: "Latest value date"
        });
      case "MaintenancePriorityScore":
        return intl.formatMessage({
          id: "family_analytics.filters.maintenance_priority_score",
          defaultMessage: "Maintenance Priority Score"
        });
      default:
        return null;
    }
  }
}
