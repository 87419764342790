// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import { routesConfig } from "core/app/components/router/RouterProvider";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import "./Asset.less";

interface IAssetProps {
  assetId: string;
  assetName: string;
  assetType: string | undefined;
  location: string | undefined;
}

const Asset = (props: IAssetProps) => {
  const { assetId, assetName, assetType, location } = props;
  const showLongMessage = assetType !== undefined && location !== undefined;
  const showShortMessage = !showLongMessage;

  const searchParams = new SearchParams({ assetId });

  return (
    <div className="column asset">
      <Link
        className="anchor id"
        to={{
          pathname: routesConfig.detailPage,
          search: searchParams.toString()
        }}
      >
        {assetId}
        {assetName && " - "}
        {assetName}
      </Link>
      <div className="details">
        {showLongMessage && (
          <FormattedMessage
            defaultMessage="{location} / {assetType}"
            id="issues_page.grid.columns.asset.location_asset_type"
            values={{
              assetType: assetType,
              location: location
            }}
          />
        )}
        {showShortMessage && (
          <FormattedMessage
            defaultMessage="{value}"
            id="issues_page.grid.columns.asset.location_asset_type_short"
            values={{
              value: assetType || location
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Asset;
