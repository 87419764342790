// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IRoutableTab } from "common/RoutableTabs";
import {
  IVisualizationInfoDictionary,
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import StandardOilTestsTab from "features/detailpage/components/analytics/StandardOilTests/StandardOilTestsTab";
import BushingOfflineTab from "features/detailpage/features/bushingsoffline/components/BushingOfflineTab";
import BushingVisualizationTab from "features/detailpage/features/bushingvisualization/components/BushingVisualizationTab";
import DGA from "features/detailpage/features/DGA";
import DuvalTabs from "features/detailpage/features/duval/components/DuvalTabs";
import LTCTab from "features/detailpage/features/ltc/components/LTCTab";
import PartialDischarge from "features/detailpage/features/PartialDischarge";
import RogersRatioTab from "features/detailpage/features/rogersratio/components/RogersRatioTab";
import SubscoresGridContainer from "features/detailpage/features/subfactorsgrid/containers/SubfactorsGridContainer";
import ThermalVisualizationTab from "features/detailpage/features/ThermalVisualizationTab";
import { useMemo } from "react";
import { useIntl } from "react-intl";

interface IUseTransformerTabsOptions {
  analyticsAvailable: boolean;
  assetId: string;
  modelId: string;
  implementationId: string;
}

const useTransformerTabs = ({
  analyticsAvailable,
  assetId,
  modelId,
  implementationId
}: IUseTransformerTabsOptions) => {
  const { getAvailable } = useFeature();
  const intl = useIntl();
  const visualizations: IVisualizationInfoDictionary<IRoutableTab> =
    useMemo(() => {
      return {
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Subfactors]: {
          Content: () => (
            <SubscoresGridContainer
              assetId={assetId}
              modelId={modelId}
              implementationId={implementationId}
            />
          ),
          id: "Factors",
          name: intl.formatMessage({
            defaultMessage: "Factors",
            id: "detail_page.widgets.subfactors"
          })
        },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Dga]: {
          Content: () => (
            <DGA
              assetId={assetId}
              dataSources={["default", "advanced", "offline"]}
              defaultDataSource={"offline"}
            />
          ),
          id: "DGA",
          name: intl.formatMessage({
            defaultMessage: "DGA",
            id: "detail_page.widgets.analytics.transformers.dga"
          })
        },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Duval]: {
          Content: () => (
            <DuvalTabs assetId={assetId} implementationId={implementationId} />
          ),
          id: "Duval",
          name: intl.formatMessage({
            defaultMessage: "Duval",
            id: "detail_page.widgets.analytics.transformers.duval"
          })
        },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Rogers_Ratio]: {
          Content: () => <RogersRatioTab assetId={assetId} />,
          id: "RogersRatio",
          name: intl.formatMessage({
            defaultMessage: "Rogers Ratio",
            id: "detail_page.widgets.analytics.transformers.rogers_ratio"
          })
        },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Standard_Oil_Tests]:
          {
            Content: () => <StandardOilTestsTab assetId={assetId} />,
            id: "StandardOilTests",
            name: intl.formatMessage({
              defaultMessage: "Standard Oil Tests",
              id: "detail_page.widgets.analytics.transformers.standard_oil_tests"
            })
          },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Bushing_Offline]: {
          Content: () => <BushingOfflineTab assetId={assetId} />,
          id: "Bushings",
          name: intl.formatMessage({
            defaultMessage: "Bushings",
            id: "detail_page.widgets.analytics.transformers.bushings_offline"
          })
        },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Bushing_Visualization]:
          {
            Content: () => <BushingVisualizationTab assetId={assetId} />,
            id: "OnlineBushings",
            name: intl.formatMessage({
              defaultMessage: "Online Bushings",
              id: "detail_page.widgets.analytics.transformers.bushing_visualization"
            })
          },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Thermal_Visualization]:
          {
            Content: () => <ThermalVisualizationTab assetId={assetId} />,
            id: "Thermal",
            name: intl.formatMessage({
              defaultMessage: "Thermal",
              id: "detail_page.widgets.analytics.transformers.thermal_visualization"
            })
          },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Ltc]: {
          Content: () => (
            <LTCTab
              assetId={assetId}
              modelId={modelId}
              implementationId={implementationId}
            />
          ),
          id: "LTC",
          name: intl.formatMessage({
            defaultMessage: "LTC",
            id: "detail_page.widgets.analytics.transformers.ltc"
          })
        },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Partial_Discharge]: {
          Content: () => (
            <PartialDischarge
              assetId={assetId}
              modelId={modelId}
              modelImplementationId={implementationId}
            />
          ),
          id: "PartialDischarge",
          name: intl.formatMessage({
            defaultMessage: "Partial Discharge",
            id: "detail_page.widgets.analytics.transformers.partial_discharge"
          })
        }
      };
    }, [assetId, implementationId, intl, modelId]);

  const transformerTabs: IRoutableTab[] = useMemo(() => {
    if (analyticsAvailable) {
      return getAvailable<IRoutableTab>(visualizations);
    }

    return [];
  }, [analyticsAvailable, visualizations, getAvailable]);

  return transformerTabs;
};

export default useTransformerTabs;
