// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import { setIsComponentAction } from "../actions/RiskMatrixActions";
import IAssetNameplate from "features/detailpage/models/IAssetNameplate";

const useSetIsComponentAction = (assetNameplate: IAssetNameplate) => {
  const dispatch = useDispatch();
  const isComponent = !isEmpty(assetNameplate && assetNameplate.ParentId);

  return useCallback(() => dispatch(setIsComponentAction(isComponent)), [
    dispatch,
    isComponent
  ]);
};

export default useSetIsComponentAction;
