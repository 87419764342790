// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Button } from "antd";
import { ButtonType, ButtonSize } from "antd/lib/button/index";
import React, { ReactNode } from "react";

import "./ButtonWithIcon.less";

interface IButtonWithIconProps {
  active?: boolean;
  disabled?: boolean;
  className?: string;
  dataQa?: string;
  icon: ReactNode;
  loading?:
    | boolean
    | {
        delay?: number;
      };
  onClick?: (event: React.MouseEvent<any>) => void;
  size?: ButtonSize;
  type?: ButtonType;
  children?: ReactNode;
}

const ButtonWithIcon = ({
  active = false,
  disabled,
  dataQa,
  children,
  className,
  icon,
  loading,
  onClick,
  size,
  type
}: IButtonWithIconProps) => {
  return (
    <Button
      data-qa={dataQa}
      className={`
        ${className}
        ${active && "active"}
        button-with-icon
      `}
      icon={icon}
      loading={loading}
      onClick={onClick}
      size={size}
      type={type}
      disabled={disabled}
    >
      {children && <div className="child">{children}</div>}
    </Button>
  );
};

export default ButtonWithIcon;
