// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import {
  IColumn,
  IExportToCsv,
  IExportToExcel,
  IRow
} from "common/datagrid/DataGrid";
import { IProperties } from "common/datagrid/services/PropertyService";
import React from "react";
import { FormattedMessage } from "react-intl";
import ExportToCsvButton from "./ExportToCsvButton";
import ExportToExcelButton from "./ExportToExcelButton";

export interface IGridFooterProps {
  columns: IColumn[];
  component?: (rows: IRow[], rowsTotal: number) => JSX.Element;
  exportToExcel?: IExportToExcel;
  exportToCsv?: IExportToCsv;
  fixed?: boolean;
  gridId: string;
  rows: IRow[];
  rowsTotal: number;
  visible?: boolean;
  width?: number;
}

export interface IGridFooterState {}

export default class GridFooter extends React.Component<
  IGridFooterProps,
  IGridFooterState
> {
  static defaultProps = {
    fixed: false,
    visible: false
  };

  static showFixedFooter(properties: IProperties): boolean {
    if (!properties) return false;

    const isFooterInBoundingBox =
      properties.footerTop +
        properties.footerHeight -
        (properties.parentTop + properties.parentHeight) >
      0;
    const isHeaderInBoundingBoxOrAbove =
      properties.headerTop +
        properties.headerHeight -
        (properties.parentTop + properties.parentHeight) +
        properties.footerHeight <
        0 || properties.headerTop === undefined;
    const isVisible = properties.footerHeight > 0 && properties.footerWidth > 0;

    return isFooterInBoundingBox && isVisible && isHeaderInBoundingBoxOrAbove;
  }

  render() {
    const {
      columns,
      component,
      exportToExcel,
      exportToCsv,
      fixed,
      rows,
      rowsTotal,
      visible,
      width
    } = this.props;

    const style = fixed
      ? {
          display: !visible && "none",
          bottom: 0,
          width: width
        }
      : undefined;
    const menu = (
      <Menu>
        <Menu.Item>
          {exportToExcel && exportToExcel.disabled ? (
            <Tooltip title={exportToExcel.disabledMessage}>
              <span>
                <ExportToExcelButton
                  exportToExcel={exportToExcel}
                  columns={columns}
                />
              </span>
            </Tooltip>
          ) : (
            <ExportToExcelButton
              exportToExcel={exportToExcel}
              columns={columns}
            />
          )}
        </Menu.Item>
        <Menu.Item>
          <ExportToCsvButton exportToCsv={exportToCsv} columns={columns} />
        </Menu.Item>
      </Menu>
    );
    return (
      <div
        className={`
          grid-footer
          ${fixed ? "fixed" : "static"}
        `}
        style={style}
      >
        <div className="grid-row">
          {component ? (
            component(rows, rowsTotal)
          ) : (
            <div className="grid-row-content default-grey-label">
              <FormattedMessage
                defaultMessage="Number of rows: {numberOfRows, number}"
                id="data_grid.footer.number_of_rows"
                values={{
                  numberOfRows: isNaN(rowsTotal) ? 0 : rowsTotal
                }}
              />
              {(exportToExcel || exportToCsv) && (
                <Dropdown overlay={menu}>
                  <Button type="link" className="export-button">
                    <Icon name="file_download" size="sm" />
                    <FormattedMessage
                      defaultMessage="Export"
                      id="data_grid.footer.export"
                    />
                    <Icon name="expand_more" />
                  </Button>
                </Dropdown>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
