// Copyright 2016-2022 Hitachi Energy. All rights reserved.
type ItemTypes = "Persisted" | "UserSession";

export default class StorageService {
  static clearSessionStorageItems() {
    Object.keys(sessionStorage)
      .filter((k) => k.startsWith("apm:"))
      .forEach((k) => {
        localStorage.removeItem(k);
      });
  }

  static clearUserSessionStorageItems() {
    Object.keys(localStorage)
      .filter((k) => k.startsWith("apm:us:"))
      .forEach((k) => {
        localStorage.removeItem(k);
      });
  }

  static getPersistedStorageItem(key: string) {
    const itemKey = StorageService.getPersistedStorageItemKey(key);
    return localStorage.getItem(itemKey);
  }

  static getSessionStorageItem(key: string) {
    const sessionItemKey = StorageService.getSessionStorageItemKey(key);
    return sessionStorage.getItem(sessionItemKey);
  }

  static getUserSessionStorageItem(key: string) {
    const userSessionItemKey = StorageService.getUserSessionStorageItemKey(key);
    return localStorage.getItem(userSessionItemKey);
  }

  static removeUserSessionStorageItem(key: string) {
    const userSessionItemKey = StorageService.getUserSessionStorageItemKey(key);
    localStorage.removeItem(userSessionItemKey);
  }
  static setPersistedStorageItem(key: string, value: string) {
    const itemKey = StorageService.getPersistedStorageItemKey(key);
    localStorage.setItem(itemKey, value);
  }

  static setSessionStorageItem(key: string, value: string) {
    const sessionItemKey = StorageService.getSessionStorageItemKey(key);
    sessionStorage.setItem(sessionItemKey, value);
  }

  static setUserSessionStorageItem(key: string, value: string) {
    const userSessionItemKey = StorageService.getUserSessionStorageItemKey(key);
    localStorage.setItem(userSessionItemKey, value);
  }

  private static getPersistedStorageItemKey(key: string) {
    return this.getStorageItemKey("Persisted", key);
  }

  private static getSessionStorageItemKey(key: string) {
    return `apm:${key}`;
  }

  private static getStorageItemKey(type: ItemTypes, key: string) {
    return `apm:${type === "UserSession" ? "us" : ""}:${key}`;
  }

  private static getUserSessionStorageItemKey(key: string) {
    return this.getStorageItemKey("UserSession", key);
  }
}
