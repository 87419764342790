// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";
import { IState } from "reducers/Index";
import NotificationsService from "../services/NotificationsService";

import getAllAssetTypesCheckedSelector from "./getAllAssetTypesCheckedSelector";
import getNotificationsEnabledSelector from "./getNotificationsEnabledSelector";

const getAssetTypesCheckedKeys = createSelector(
  (state: IState) => state.notifications.modelInput.data,
  getAllAssetTypesCheckedSelector,
  getNotificationsEnabledSelector,
  (data, allAssetTypesChecked, notificationsEnabled) =>
    data && data.AssetTypes && notificationsEnabled
      ? NotificationsService.convertITableItemToStringArray(
          data.AssetTypes,
          allAssetTypesChecked
        )
      : []
);

export default getAssetTypesCheckedKeys;
