// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadSimulationConfig } from "../../../actions/RiskSimulationSummaryActions";
import OpenButton from "./OpenButton";
import "./RiskSimulationSummary.less";
import SimulationLabel from "./SimulationLabel";

export interface IRiskSimulationSummaryProps {
  assetId: string;
  modelId: string;
  implementationId: string;
  children?: ReactNode;
}

const RiskSimulationSummary = ({
  assetId,
  modelId,
  implementationId,
  children
}: IRiskSimulationSummaryProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    loadSimulationConfig(dispatch, assetId).catch(() => {});
  }, [assetId, dispatch]);

  return (
    <div className="risk-simulation-summary">
      <SimulationLabel modelId={modelId} implementationId={implementationId} />
      <div className="risk-simulation-summary__buttons">
        <div>
          <OpenButton />
        </div>
        {children}
      </div>
    </div>
  );
};

export default RiskSimulationSummary;
