// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { IntlShape } from "react-intl";

interface IDuvalPentagonTooltipProps {
  intl: IntlShape;
  date: Date;
  hydrogenLabel: string;
  hydrogenPPM: number;
  hydrogenPercent: number;
  acetyleneLabel: string;
  acetylenePPM: number;
  acetylenePercent: number;
  ethyleneLabel: string;
  ethylenePPM: number;
  ethylenePercent: number;
  methaneLabel: string;
  methanePPM: number;
  methanePercent: number;
  ethaneLabel: string;
  ethanePPM: number;
  ethanePercent: number;
}

const DuvalPentagonTooltip = ({
  intl,
  date,
  hydrogenLabel,
  hydrogenPPM,
  hydrogenPercent,
  acetyleneLabel,
  acetylenePPM,
  acetylenePercent,
  ethyleneLabel,
  ethylenePPM,
  ethylenePercent,
  methaneLabel,
  methanePPM,
  methanePercent,
  ethaneLabel,
  ethanePPM,
  ethanePercent
}: IDuvalPentagonTooltipProps) => {
  return (
    <>
      <div className="header">{intl.formatDate(date)}</div>
      <table>
        <tr>
          <td className="left">{hydrogenLabel}</td>
          <td className="right">
            {intl.formatMessage(
              {
                id: "detail_page.widgets.analytics.transformers.duval_triangles.ppm",
                defaultMessage: "{value} ppm"
              },
              {
                value: hydrogenPPM
              }
            )}
          </td>
          <td className="right">
            {intl.formatMessage(
              {
                id: "detail_page.widgets.analytics.transformers.duval_triangles.percent",
                defaultMessage: "{value} %"
              },
              {
                value: hydrogenPercent
              }
            )}
          </td>
        </tr>
        <tr>
          <td className="left">{acetyleneLabel}</td>
          <td className="right">
            {intl.formatMessage(
              {
                id: "detail_page.widgets.analytics.transformers.duval_triangles.ppm",
                defaultMessage: "{value} ppm"
              },
              {
                value: acetylenePPM
              }
            )}
          </td>
          <td className="right">
            {intl.formatMessage(
              {
                id: "detail_page.widgets.analytics.transformers.duval_triangles.percent",
                defaultMessage: "{value} %"
              },
              {
                value: acetylenePercent
              }
            )}
          </td>
        </tr>
        <tr>
          <td className="left">{ethyleneLabel}</td>
          <td className="right">
            {intl.formatMessage(
              {
                id: "detail_page.widgets.analytics.transformers.duval_triangles.ppm",
                defaultMessage: "{value} ppm"
              },
              {
                value: ethylenePPM
              }
            )}
          </td>
          <td className="right">
            {intl.formatMessage(
              {
                id: "detail_page.widgets.analytics.transformers.duval_triangles.percent",
                defaultMessage: "{value} %"
              },
              {
                value: ethylenePercent
              }
            )}
          </td>
        </tr>
        <tr>
          <td className="left">{methaneLabel}</td>
          <td className="right">
            {intl.formatMessage(
              {
                id: "detail_page.widgets.analytics.transformers.duval_triangles.ppm",
                defaultMessage: "{value} ppm"
              },
              {
                value: methanePPM
              }
            )}
          </td>
          <td className="right">
            {intl.formatMessage(
              {
                id: "detail_page.widgets.analytics.transformers.duval_triangles.percent",
                defaultMessage: "{value} %"
              },
              {
                value: methanePercent
              }
            )}
          </td>
        </tr>
        <tr>
          <td className="left">{ethaneLabel}</td>
          <td className="right">
            {intl.formatMessage(
              {
                id: "detail_page.widgets.analytics.transformers.duval_triangles.ppm",
                defaultMessage: "{value} ppm"
              },
              {
                value: ethanePPM
              }
            )}
          </td>
          <td className="right">
            {intl.formatMessage(
              {
                id: "detail_page.widgets.analytics.transformers.duval_triangles.percent",
                defaultMessage: "{value} %"
              },
              {
                value: ethanePercent
              }
            )}
          </td>
        </tr>
      </table>
    </>
  );
};

export const getStaticMarkup = (props: IDuvalPentagonTooltipProps) =>
  renderToStaticMarkup(<DuvalPentagonTooltip {...props} />);
