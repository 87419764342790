// Copyright 2016-2022 Hitachi Energy. All rights reserved.
const condition = [
  "normal",
  "cracked wires",
  "loose connection",
  "damaged connector",
  "oil soaked"
];

export default condition;
