// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { ChartAPI } from "c3";
import C3Chart from "common/chart/components/C3Chart";
import LTCOperationsBarChartLegend from "features/detailpage/features/ltc/components/LTCOperationsBarChartLegend";
import useLTCOperationsChartConfiguration from "features/detailpage/features/ltc/hooks/useLTCOperationsChartConfiguration";
import "./LTCOperationsBarChart.less";
interface ILTCOperationsBarChartProps {
  percentageUsed: number;
  warningThreshold: number;
  alarmThreshold: number;
}

const LTCOperationsBarChart = ({
  percentageUsed,
  warningThreshold,
  alarmThreshold
}: ILTCOperationsBarChartProps) => {
  const { configuration, legendData } = useLTCOperationsChartConfiguration({
    percentageUsed,
    warningThreshold,
    alarmThreshold
  });
  let chartApi: ChartAPI;

  return (
    <>
      <div className="ltc-operations-chart">
        <C3Chart
          configuration={configuration}
          onRendered={(chart) => (chartApi = chart)}
        />
      </div>
      <LTCOperationsBarChartLegend
        plots={legendData}
        series={"LTC Operations"}
        onFocus={(series: string, id: string) => {
          chartApi.focus(id);
        }}
        onRevert={(series: string) => {
          chartApi.revert();
        }}
      />
    </>
  );
};

export default LTCOperationsBarChart;
