// Copyright 2016-2022 Hitachi Energy. All rights reserved.
const pumpTypes = [
  "new_ball_bearing",
  "new_sleeve_bearing",
  "none",
  "old_ball_bearing",
  "old_sleeve_bearing",
  "unknown"
];

export default pumpTypes;
