// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import namedSeries from "common/chart/utils/namedSeries";
import { IAnalyticsChartDataSet } from "features/detailpage/components/analytics/AnalyticsLineChart";
import Axis from "features/detailpage/features/bushingsoffline/models/Axis";
import { BushingReference } from "features/detailpage/features/bushingsoffline/models/BushingReference";
import IBushingChartDataSet from "features/detailpage/features/bushingsoffline/models/IBushingChartDataSet";
import IBushingChartsSettings from "features/detailpage/features/bushingsoffline/models/IBushingChartsSettings";
import IBushingLineChartDataSet from "features/detailpage/features/bushingsoffline/models/IBushingLineChartDataSet";

import IBushingOfflineStaticLegendItem from "features/detailpage/features/bushingsoffline/models/IBushingOfflineStaticLegendItem";
import IBushingParamValues from "features/detailpage/features/bushingsoffline/models/IBushingParamValues";
import IBushingReferencedDataSet from "features/detailpage/features/bushingsoffline/models/IBushingReferenceDataSet";
import ISeriesPoint from "features/detailpage/features/bushingsoffline/models/ISeriesPoint";
import { isNil } from "lodash";
import moment from "moment";
import { IntlShape } from "react-intl";
import IBushingOfflineParameterData from "../models/IBushingOfflineParameterData";
import IBushingReferenceData from "../models/IBushingReferenceData";

type DataSeries = ISeriesPoint[] | undefined | null;

export default class BushingOfflineChartService {
  public static getBushingChartsSettings(): IBushingChartsSettings {
    return {
      dataSeriesInfo: [
        { name: "PowerFactor", axis: Axis.Left, unit: "%" },
        { name: "Capacitance", axis: Axis.Right, unit: "pF" }
      ],
      References: [BushingReference.PowerFactor, BushingReference.Capacitance],
      bushingChartRows: ["H", "X", "Y"],
      bushingChartColumns: [1, 2, 3]
    };
  }

  public static getChartConfigurationSeriesData(
    series: IBushingLineChartDataSet[]
  ) {
    const xs: { [key: string]: string } = {};
    const colors: { [key: string]: string } = {};
    const axes: { [key: string]: c3.AxisName } = {};
    const types: { [key: string]: c3.ChartType } = {};
    let columns: [string, ...c3.Primitive[]][] = [];

    (series || []).forEach((s) => {
      xs[s.name] = `x_${s.name}`;
      colors[s.name] = s.axis === Axis.Left ? "#1f77b4" : "#ff7f0e";
      if (s.axis === Axis.Right) axes[s.name] = "y2";
      types[s.name] = "line";
      columns = columns.concat([
        namedSeries(
          s.name,
          s.values.map((v) => v.value)
        )
      ]);
      columns = columns.concat([
        namedSeries(
          `x_${s.name}`,
          s.values.map((v) => v.date)
        )
      ]);
    });

    let isEmpty =
      (series || []).filter((s) => s.values && s.values.length > 0).length ===
      0;

    if (isEmpty) {
      columns = [];
      isEmpty = true;
    }

    return {
      xs,
      columns,
      axes,
      types,
      colors
    };
  }

  public static getFormattedPlotName(
    intl: IntlShape,
    plot: IBushingChartDataSet
  ): string {
    const messageId = `detail_page.widgets.analytics.transformers.${plot.name}`;
    return intl.formatMessage({
      id: messageId,
      defaultMessage: plot.name
    });
  }

  public static getReferencedValue(
    pc: IBushingReferenceData[],
    referenceName: string
  ): number {
    const referenceData = pc.find((x) => x.ReferenceId === referenceName);
    const value: number | undefined | null = !isNil(referenceData)
      ? referenceData.Value
      : null;

    return value;
  }

  public static extractSeriesFromParamsCollection(
    pc: IBushingOfflineParameterData[],
    paramName: string
  ): DataSeries {
    const readingData = pc.find((x) => x.ParameterId === paramName);
    const pv: IBushingParamValues | undefined | null =
      readingData && !isNil(readingData.Readings) ? readingData.Readings : null;

    return this.transformParamValuesToSeries(pv);
  }

  public static transformParamValuesToSeries(
    paramValues: IBushingParamValues
  ): DataSeries {
    const result: DataSeries = [];

    if (paramValues === undefined || paramValues === null) return result;

    for (const dt of Object.keys(paramValues)) {
      const point: ISeriesPoint = {
        date: moment(dt).local().format("YYYY-MM-DD"),
        value: paramValues[dt]
      };
      result.push(point);
    }

    return result;
  }

  public static getSeries(
    intl: IntlShape,
    dataSeriesInfo: IBushingChartDataSet[],
    parameterData: IBushingOfflineParameterData[],
    series: string,
    num: number
  ): IBushingLineChartDataSet[] {
    return dataSeriesInfo.map((si) => {
      return {
        name: this.getFormattedPlotName(intl, si),
        axis: si.axis,
        values: this.extractSeriesFromParamsCollection(
          parameterData,
          this.getBushingParameterName(series, num, si)
        )
      };
    });
  }

  public static getBushingParameterName(
    series: string,
    num: number,
    plot: IBushingChartDataSet
  ): string {
    return `MTMP.InputData.BushingData.Bushing${series}${num}${plot.name}`;
  }

  public static getPlots(intl: IntlShape): IAnalyticsChartDataSet[] {
    const settings: IBushingChartsSettings = this.getBushingChartsSettings();

    return settings.dataSeriesInfo.map((d) => {
      return {
        standard: null,
        name: this.getFormattedPlotName(intl, d),
        axis: d.axis,
        unit: d.unit
      };
    });
  }

  public static getReferencedParameterName(
    series: string,
    num: number,
    referenceName: string
  ): string {
    return `MTMP.InputData.BushingData.Bushing${series}${num}Fat${referenceName}`;
  }

  public static getReference(
    intl: IntlShape,
    referenceName: string,
    referencesData: IBushingReferenceData[],
    series: string,
    num: number
  ): IBushingReferencedDataSet {
    return {
      name: this.getFormattedReferenceName(intl, referenceName),
      value: this.getReferencedValue(
        referencesData,
        this.getReferencedParameterName(series, num, referenceName)
      )
    };
  }

  public static getFormattedReferenceName(
    intl: IntlShape,
    referenceName: string
  ): string {
    const name = `Reference${referenceName}`;
    const messageId = `detail_page.widgets.analytics.transformers.${name}`;
    return intl.formatMessage({
      id: messageId,
      defaultMessage: name
    });
  }

  public static getParameterNames(): string[] {
    const parameters: string[] = [];
    const settings: IBushingChartsSettings = this.getBushingChartsSettings();

    settings.bushingChartRows.forEach((p) => {
      settings.bushingChartColumns.forEach((n) => {
        settings.dataSeriesInfo.forEach((s) => {
          parameters.push(this.getBushingParameterName(p, n, s));
        });
        settings.References.forEach((s) => {
          parameters.push(this.getReferencedParameterName(p, n, s));
        });
      });
    });

    return parameters;
  }

  public static getLegendItems(
    references: string[],
    intl: IntlShape
  ): IBushingOfflineStaticLegendItem[] {
    const legendItems: IBushingOfflineStaticLegendItem[] = [];

    references.forEach((t) => {
      const translatedLabel = intl.formatMessage({
        id: `detail_page.widgets.analytics.transformers.Reference${t}`,
        defaultMessage: t
      });
      legendItems.push({
        name: translatedLabel,
        className: `${t}-box`
      });
    });

    return legendItems;
  }
}
