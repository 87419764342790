// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";

// eslint-disable-next-line no-extend-native
String.prototype.linkify = function (): JSX.Element[] {
  const text = this as string;
  // regex taken from: http://stackoverflow.com/questions/1500260/detect-urls-in-text-with-javascript
  const urlRegex = /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

  return text.split(urlRegex).map((textPart, i) => {
    if (urlRegex.test(textPart)) {
      return (
        <a
          key={i}
          className="anchor"
          href={textPart}
          target="_blank"
          rel="noopener noreferrer"
        >
          {textPart}
        </a>
      );
    } else {
      return <span key={i}>{textPart}</span>;
    }
  });
};
