// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Reducer } from "redux";
import Data from "core/data/models/Data";

import {
  Actions,
  ISetNotificationsEnabledAction,
  ISetAllOrganizationsCheckedAction,
  ISetAllAssetTypesCheckedAction,
  ISetCanBeSavedAction,
  ISetAllDegradedCheckedAction
} from "../actions/NotificationsActions";

import INotificationsModelInput from "../models/INotificationsModelInput";
import { IAjaxAction } from "reducers/Index";

export interface IState {
  modelInput: Data<INotificationsModelInput>;
  canBeSaved: boolean;
  notificationsEnabled: boolean;
  allOrganizationsChecked: boolean;
  allAssetTypesChecked: boolean;
  allDegradedChecked: boolean;
}

export const defaultState: IState = {
  modelInput: new Data(),
  canBeSaved: false,
  notificationsEnabled: false,
  allOrganizationsChecked: undefined,
  allAssetTypesChecked: undefined,
  allDegradedChecked: undefined
};

type NotificationsActions =
  | IAjaxAction<INotificationsModelInput>
  | ISetNotificationsEnabledAction
  | ISetAllOrganizationsCheckedAction
  | ISetAllAssetTypesCheckedAction
  | ISetCanBeSavedAction
  | ISetAllDegradedCheckedAction;

const reducer: Reducer<IState> = (
  state = defaultState,
  action: NotificationsActions
) => {
  switch (action.type) {
    case Actions.Notifications_SetNotificationsSettings:
      return {
        ...state,
        modelInput: new Data(
          (action as IAjaxAction<INotificationsModelInput>).request,
          (action as IAjaxAction<INotificationsModelInput>).data
        )
      };
    case Actions.Notifications_SetNotificationsEnabled:
      return {
        ...state,
        notificationsEnabled: (action as ISetNotificationsEnabledAction).value
      };
    case Actions.Notifications_SetAllOrganizationsChecked:
      return {
        ...state,
        allOrganizationsChecked: (action as ISetAllOrganizationsCheckedAction)
          .value
      };
    case Actions.Notifications_SetAllAssetTypesChecked:
      return {
        ...state,
        allAssetTypesChecked: (action as ISetAllAssetTypesCheckedAction).value
      };
    case Actions.Notifications_SetAllDegradedChecked:
      return {
        ...state,
        allDegradedChecked: (action as ISetAllDegradedCheckedAction).value
      };
    case Actions.Notifications_SetCanBeSaved:
      return {
        ...state,
        canBeSaved: (action as ISetCanBeSavedAction).value
      };
    case Actions.Notifications_ResetToDefaultState:
      return { ...defaultState };
    default:
      return state;
  }
};

export default reducer;
