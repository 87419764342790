// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";

import useSlidePanelActions from "../hooks/useSlidePanelActions";
import { Statuses } from "core/data/models/Data";
import LabeledRow from "./LabeledRow";
import ActionsProcessing from "./ActionsProcessing";
import ActionsFailed from "./ActionsFailed";
import ActionsSucceeded from "./ActionsSucceeded";

import "./SlidePanelActions.less";

interface ISlidePanelActionsProps {
  assetIssueId: string;
  assetType: string;
  assetLocation: string;
}

const SlidePanelActions = ({
  assetIssueId,
  assetType,
  assetLocation
}: ISlidePanelActionsProps) => {
  const { actions } = useSlidePanelActions(assetIssueId);

  return (
    <div className="slide-panel-content">
      <LabeledRow
        className="actions-asset-type"
        message={{
          defaultMessage: "Asset Type",
          id: "issues_page.slide_panel.actions.asset_type"
        }}
      >
        {assetType}
      </LabeledRow>
      <LabeledRow
        className="actions-asset-location"
        message={{
          defaultMessage: "Location",
          id: "issues_page.slide_panel.actions.location"
        }}
      >
        {assetLocation}
      </LabeledRow>
      {actions.status === Statuses.Loading && <ActionsProcessing />}
      {actions.status === Statuses.Succeeded && actions.data && (
        <ActionsSucceeded actions={actions.data} />
      )}
      {actions.status === Statuses.Failed && <ActionsFailed />}
    </div>
  );
};

export default SlidePanelActions;
