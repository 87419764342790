// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Cascader as AntdCascader } from "antd";
import { CascaderValueType, DataNode } from "rc-cascader/lib/interface";
import React from "react";
import { useIntl } from "react-intl";

interface ICascaderProps {
  options: DataNode[];
  defaultText?: string;
  defaultValue?: string[];
  expandTrigger?: "hover" | "click";
  className?: string;
  dropdownClassName?: string;
  allowClear?: boolean;
  showSearch?: boolean;
  onChange?: (value: CascaderValueType, selectOptions: DataNode[]) => void;
}

const Cascader = ({
  options,
  defaultText,
  expandTrigger = "hover",
  className,
  dropdownClassName,
  allowClear = false,
  defaultValue,
  onChange,
  showSearch = false
}: ICascaderProps) => {
  const intl = useIntl();

  const defaultTextValue =
    defaultText ||
    intl.formatMessage({
      defaultMessage: "All",
      id: "common.ddl.default_text"
    });
  const noDataFoundTextValue = intl.formatMessage({
    defaultMessage: "No data",
    id: "common.no_data_message"
  });

  return (
    <AntdCascader
      options={options}
      placeholder={defaultTextValue}
      expandTrigger={expandTrigger}
      className={className}
      allowClear={allowClear}
      defaultValue={defaultValue}
      onChange={onChange}
      dropdownClassName={dropdownClassName}
      showSearch={showSearch}
      notFoundContent={noDataFoundTextValue}
    />
  );
};

export default Cascader;
