// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useDispatch } from "react-redux";
import { useCallback } from "react";

import { setAllAssetTypesCheckedAction } from "../actions/NotificationsActions";

const useSetAllAssetTypesChecked = () => {
  const dispatch = useDispatch();
  const setAllAssetTypesChecked = useCallback(
    (value: boolean) => {
      dispatch(setAllAssetTypesCheckedAction(value));
    },
    [dispatch]
  );

  return { setAllAssetTypesChecked };
};

export default useSetAllAssetTypesChecked;
