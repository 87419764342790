// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { checkedLimit } from "common/datagrid/BodyRow";
import { IRow } from "common/datagrid/DataGrid";
import { useCallback, useState } from "react";

const useAssetQueryGrid = () => {
  const [isAssetModalVisible, setIsAssetModalVisible] = useState(false);
  const [assetIdForAssetModal, setAssetIdForAssetModal] =
    useState<string>(null);
  const [checkedAssetList, setCheckedAssetList] = useState<IRow[]>([]);

  const handleAssetModalOpen = useCallback((assetId: string) => {
    setAssetIdForAssetModal(assetId);
    setIsAssetModalVisible(true);
  }, []);

  const handleAssetModalCancel = useCallback(() => {
    setAssetIdForAssetModal(null);
    setIsAssetModalVisible(false);
  }, []);

  const handleNewAssetModalOpen = useCallback(() => {
    handleAssetModalOpen(null);
  }, [handleAssetModalOpen]);

  const handleCheckboxChange = useCallback((_: IRow, checkedRows: IRow[]) => {
    setCheckedAssetList([...checkedRows]);
  }, []);

  const checkableRows = useCallback(
    (rows: IRow[]) => rows.filter((a) => a.data["AssetType"] === "Transformer"),
    []
  );

  const isCheckboxDisabled = useCallback((row: IRow, checkedList: IRow[]) => {
    const isDisabled: boolean =
      (checkedList &&
        checkedList.length === checkedLimit &&
        !checkedList.map((e) => e.rowId).includes(row.rowId)) ||
      row.data["AssetType"] !== "Transformer";

    return isDisabled;
  }, []);

  return {
    handleAssetModalOpen,
    handleNewAssetModalOpen,
    handleAssetModalCancel,
    isAssetModalVisible,
    assetIdForAssetModal,
    handleCheckboxChange,
    checkedAssetList,
    isCheckboxDisabled,
    checkableRows
  };
};

export default useAssetQueryGrid;
