// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import HeaterStatus from "@apm/widgets/build/widgets/HeaterStatus";
import Processing from "components/common/Processing";
import ProcessingModal from "components/common/ProcessingModal";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import { UserRoles } from "core/app/components/auth/Authorization";
import { getUserSelector } from "core/app/reducers/AppReducer";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";
import Data, { Statuses } from "core/data/models/Data";
import { useMemo } from "react";
import { IntlShape } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { ThunkDispatch } from "thunk";
import acknowledge from "../../../actions/acknowledge";
import IThresholdCorrection from "../../../models/IThresholdCorrection";
import loadHeaterStatus from "../actions/loadHeaterStatus";
import useHeaterStatusParameters from "../hooks/useHeaterStatusParameters";
import IHeaterStatus from "../models/IHeaterStatus";
import getHeaterStatusSelector from "../selectors/getHeaterStatusSelector";

interface IHeaterStatusData {
  heaterStatus: Data<IHeaterStatus>;
  user: Data<IUser>;
}

interface IIHeaterStatusActions {
  loadHeaterStatus: (assetId: string) => void;
  acknowledge: (
    intl: IntlShape,
    parameterName: string,
    success: (correction: IThresholdCorrection) => void,
    error: () => void
  ) => void;
}
interface IHeaterStatusOwnProps {
  assetId: string;
  getAcknowledgeUrl: (assetId: string, parameterName: string) => string;
}

type HeatherStatusInfoProps = IHeaterStatusOwnProps &
  IHeaterStatusData &
  IIHeaterStatusActions;

const HeaterStatusInfo = ({
  assetId,
  heaterStatus,
  loadHeaterStatus,
  acknowledge,
  user
}: HeatherStatusInfoProps) => {
  const {
    handleAcknowledgeClick,
    parametersGroup,
    processing,
    thresholdCorrectionParams,
    translations
  } = useHeaterStatusParameters({
    assetId,
    loadHeaterStatus,
    heaterStatus,
    acknowledge
  });

  const isReadOnlyMode = useMemo(() => {
    return !AuthorizationService.isAuthorized(user.data, [
      UserRoles.Administrator,
      UserRoles.Engineer,
      UserRoles.LimitedEngineer
    ]);
  }, [user.data]);

  return (
    <>
      {heaterStatus && heaterStatus.status === Statuses.Loading && (
        <Processing />
      )}
      {heaterStatus &&
        heaterStatus.status === Statuses.Succeeded &&
        heaterStatus.data && (
          <HeaterStatus
            parametersGroups={[parametersGroup]}
            onAcknowledgeClick={handleAcknowledgeClick}
            thresholdCorrectionParams={thresholdCorrectionParams}
            translations={translations}
            readonly={isReadOnlyMode}
          />
        )}
      {((heaterStatus && heaterStatus.status === Statuses.NotFound) ||
        (heaterStatus === null &&
          heaterStatus.status === Statuses.Succeeded)) && (
        <WidgetNoDataMessage />
      )}
      {heaterStatus && heaterStatus.status === Statuses.Failed && (
        <WidgetErrorMessage />
      )}
      {processing && <ProcessingModal />}
    </>
  );
};

const mapStateToProps = (
  state: IState,
  { assetId }: IHeaterStatusOwnProps
): IHeaterStatusData => ({
  heaterStatus: getHeaterStatusSelector(state)(assetId),
  user: getUserSelector(state)
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any>,
  { assetId, getAcknowledgeUrl }: IHeaterStatusOwnProps
): IIHeaterStatusActions => ({
  acknowledge: (
    intl: IntlShape,
    parameterName: string,
    success: (correction: IThresholdCorrection) => void,
    error: () => void
  ) =>
    dispatch(
      acknowledge({
        intl,
        assetId,
        parameterName,
        success,
        error,
        url: getAcknowledgeUrl(assetId, parameterName)
      })
    ),
  loadHeaterStatus: (assetId: string) => dispatch(loadHeaterStatus(assetId))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaterStatusInfo);
