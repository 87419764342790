// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Col, Row } from "antd";
import DegradationScore from "common/formatters/DegradationScore";
import { isNil } from "lodash";
import { memo, useContext } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import AssetRibbonContext from "../contexts/AssetRibbonContext";
import useRiskInfo from "../hooks/useRiskInfo";
import DegradationScoreFamilyIcon from "./DegradationScoreFamilyIcon";
import ImportanceScoreFamilyIcon from "./ImportanceScoreFamilyIcon";
import Label from "./Label";
import NumberValue from "./NumberValue";
import "./RiskInfo.less";

interface IRiskInfoContextProps {
  sidePanelExpanded: boolean;
  assetId: string;
}

interface IRiskInfoOwnProps {
  risk: string;
  condition: number;
  importance: number;
  isInFamily: boolean;
}

type RiskInfoProps = IRiskInfoOwnProps & IRiskInfoContextProps;

const RiskInfo = memo(
  ({
    risk,
    condition,
    importance,
    isInFamily,
    assetId,
    sidePanelExpanded
  }: RiskInfoProps) => {
    const { classNames } = useRiskInfo(risk);
    return (
      <Row
        className="asset-detail-risk asset-ribbon-section"
        justify="space-between"
      >
        <Col className="asset-ribbon-column">
          <div data-qa="header-risk-label">
            <Label>
              <FormattedMessage
                id="asset_detail_page.header.risk"
                defaultMessage="Risk"
              />
            </Label>
          </div>
          <div>
            <div className={classNames} data-qa="header-risk-value" />
          </div>
        </Col>
        {!sidePanelExpanded && (
          <>
            <Col
              className={`asset-ribbon-column ${
                isInFamily ? "with-family" : ""
              }`}
            >
              <div data-qa="header-condition-label">
                <Row className="risk-column-family">
                  <Col>
                    <Label>
                      <FormattedMessage
                        id="asset_detail_page.header.condition"
                        defaultMessage="Condition"
                      />
                    </Label>
                    {isInFamily && (
                      <div className="asset-risk-family">
                        <Label>
                          <DegradationScoreFamilyIcon
                            assetId={assetId}
                            stopPropagation={true}
                            placement="bottom"
                            isDisabled={isNil(condition)}
                          />
                        </Label>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
              <div data-qa="header-condition-value">
                <NumberValue>
                  <DegradationScore value={condition} />
                </NumberValue>
              </div>
            </Col>
            <Col
              className={`asset-ribbon-column ${
                isInFamily ? "with-family" : ""
              }`}
            >
              <div data-qa="header-importance-label">
                <Row className="risk-column-family">
                  <Col>
                    <Label>
                      <FormattedMessage
                        id="asset_detail_page.header.importance"
                        defaultMessage="Importance"
                      />
                    </Label>
                    {isInFamily && (
                      <div className="asset-risk-family">
                        <Label>
                          <ImportanceScoreFamilyIcon
                            assetId={assetId}
                            stopPropagation={true}
                            placement="bottom"
                          />
                        </Label>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
              <div data-qa="header-importance-value">
                <NumberValue>
                  <FormattedNumber value={importance} />
                </NumberValue>
              </div>
            </Col>
          </>
        )}
      </Row>
    );
  }
);

export default (props: IRiskInfoOwnProps) => {
  const { assetId, sidePanelExpanded } = useContext(AssetRibbonContext);
  return (
    <RiskInfo
      {...props}
      assetId={assetId}
      sidePanelExpanded={sidePanelExpanded}
    />
  );
};
