// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import Data from "../../../../../core/data/models/Data";
import { IState } from "../../../../../reducers/Index";

import { getDataKey } from "../actions/loadOperatingMechanismMotor";

import IOperatingMechanismMotor from "../models/IOperatingMechanismMotor";

const getOperatingMechanismMotorSelector = createSelector<
  IState,
  (assetId: string) => Data<IOperatingMechanismMotor>,
  (assetId: string) => Data<IOperatingMechanismMotor>
>(
  (state: IState) => (assetId: string) => state.data[getDataKey(assetId)],
  (operatingMechanismMotor) => operatingMechanismMotor
);

export default getOperatingMechanismMotorSelector;
