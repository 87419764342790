// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { ChartConfiguration, ChartAPI } from "c3";
import { select, selectAll, format } from "d3";
import React from "react";
import { useIntl } from "react-intl";

// utils
import { IAssetRiskDashboardTrendProps } from "features/assetriskdashboard/components/AssetRiskDashboardTrend";
import ResizeWrapper from "common/ResizeWrapper";
import { ITrendChartData } from "features/assetriskdashboard/models/TrendChart";

// components
import C3CustomLegend, { ILegendItem } from "components/common/C3CustomLegend";
import C3Chart from "common/chart/components/C3Chart";
import {
  colorRiskHigh,
  colorRiskLow,
  colorRiskMedium,
  colorRiskUnknown
} from "styles/ColorVariables";

type TrendComponentProps = IAssetRiskDashboardTrendProps;

const TrendComponent = ({ trendChart }: TrendComponentProps): JSX.Element => {
  const intl = useIntl();
  let c3chart: ChartAPI = null;

  const processData = () => {
    if (columns) {
      (trendChart.data || []).forEach((c: ITrendChartData) => {
        const allAssetCount =
          c.HighRiskAssetCount + c.MediumRiskAssetCount + c.LowRiskAssetCount;
        columns[0].push(c.CalculationDate.substr(0, 10));
        columns[1].push((c.HighRiskAssetCount / allAssetCount) * 100);
        columns[2].push((c.MediumRiskAssetCount / allAssetCount) * 100);
        columns[3].push((c.LowRiskAssetCount / allAssetCount) * 100);
      });
    }
  };

  const addGradient = (defs: any, gradientId: string, color: string) => {
    const gradient = defs
      .append("linearGradient")
      .attr("id", gradientId)
      .attr("x1", "0%")
      .attr("x2", "0%")
      .attr("y1", "0%")
      .attr("y2", "100%");

    gradient
      .append("stop")
      .attr("class", "start")
      .attr("offset", "0%")
      .attr("stop-color", color)
      .attr("stop-opacity", 1);

    gradient
      .append("stop")
      .attr("class", "end")
      .attr("offset", "100%")
      .attr("stop-color", color)
      .attr("stop-opacity", 0.2);
  };

  const C3CustomLegendItems = [
    "highRisk",
    "mediumRisk",
    "lowRisk"
  ].map<ILegendItem>((x) => {
    return {
      id: x,
      background:
        x === "highRisk"
          ? colorRiskHigh
          : x === "mediumRisk"
          ? colorRiskMedium
          : x === "lowRisk"
          ? colorRiskLow
          : colorRiskUnknown,
      messageId: "homepage.asset_risk.asset_risk." + x,
      defaultMessage: x
    } as ILegendItem;
  });

  const columns: [string, ...c3.Primitive[]][] = [
    ["x"],
    ["highRisk"],
    ["mediumRisk"],
    ["lowRisk"]
  ];

  const c3ChartConfig: ChartConfiguration = {
    oninit: () => {
      const defs = select(".trend-chart .c3 svg defs");
      addGradient(defs, "lowRiskGradient", colorRiskLow);
      addGradient(defs, "mediumRiskGradient", colorRiskMedium);
      addGradient(defs, "highRiskGradient", colorRiskHigh);
    },
    onrendered: () => {
      selectAll(
        ".trend-chart .c3 svg .c3-chart .c3-chart-lines .c3-chart-line .c3-shapes path.c3-shape.c3-area-lowRisk"
      ).style("fill", 'url("#lowRiskGradient")');
      selectAll(
        ".trend-chart .c3 svg .c3-chart .c3-chart-lines .c3-chart-line .c3-shapes path.c3-shape.c3-area-mediumRisk"
      ).style("fill", 'url("#mediumRiskGradient")');
      selectAll(
        ".trend-chart .c3 svg .c3-chart .c3-chart-lines .c3-chart-line .c3-shapes path.c3-shape.c3-area-highRisk"
      ).style("fill", 'url("#highRiskGradient")');
    },
    axis: {
      x: {
        type: "timeseries",
        tick: {
          count: 7,
          fit: false,
          format: (x: Date) =>
            intl.formatDate(x, {
              month: "short",
              year: "numeric"
            }),
          outer: false
        }
      },
      y: {
        default: [0, 100],
        max: 100,
        min: 0,
        padding: {
          top: 0,
          bottom: 0
        },
        tick: {
          count: 6,
          format: format("d"),
          outer: false
        }
      }
    },
    data: {
      x: "x",
      columns,
      colors: {
        lowRisk: colorRiskLow,
        mediumRisk: colorRiskMedium,
        highRisk: colorRiskHigh
      },
      groups: [["highRisk", "mediumRisk", "lowRisk"]],
      names: {
        highRisk: intl.formatMessage({
          id: "asset-risk-dashboard.asset_risk.asset_risk.highRisk",
          defaultMessage: "High"
        }),
        mediumRisk: intl.formatMessage({
          id: "asset-risk-dashboard.asset_risk.asset_risk.mediumRisk",
          defaultMessage: "Medium"
        }),
        lowRisk: intl.formatMessage({
          id: "asset-risk-dashboard.asset_risk.asset_risk.lowRisk",
          defaultMessage: "Low"
        })
      },
      order: null,
      types: {
        lowRisk: "area",
        mediumRisk: "area",
        highRisk: "area"
      }
    },
    grid: {
      x: {
        show: true
      },
      y: {
        show: true
      }
    },
    point: {
      show: false
    },
    tooltip: {
      format: {
        value: (value: number): string => {
          return intl.formatNumber(value / 100, {
            maximumFractionDigits: 2,
            style: "percent"
          });
        }
      }
    },
    zoom: {
      enabled: true
    },
    padding: {
      right: 25
    }
  };

  processData();

  return (
    <div className="trend-chart" data-qa="risk-trend">
      <C3CustomLegend
        items={C3CustomLegendItems}
        onMouseOver={(id: string) => {
          c3chart.focus(id);
        }}
        onMouseOut={(id: string) => {
          c3chart.revert();
        }}
        onClick={(id: string) => {
          c3chart.toggle(id);
        }}
      />
      <div className="y-axis-label bold">%</div>

      <ResizeWrapper>
        <C3Chart
          configuration={c3ChartConfig}
          onRendered={(c: ChartAPI) => {
            c3chart = c;
          }}
        />
      </ResizeWrapper>
    </div>
  );
};

export default TrendComponent;
