// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { RegionOptions } from "c3";
import C3Chart from "common/chart/components/C3Chart";
import namedSeries from "common/chart/utils/namedSeries";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import "./AnalyticsLineChart.less";
import AnalyticsLineChartService from "./AnalyticsLineChartService";
import getSeriesColor from "./getSeriesColor";
import isThreshold from "./isThreshold";
import StandardOilTestsChartService from "./StandardOilTests/StandardOilTestsChartService";

export enum Axis {
  Left,
  Right
}

enum FormatType {
  Default,
  InterfacialTension,
  Moisture,
  PowerFactor,
  DielectricStrength,
  AcidNumber,
  InhibitorContent
}

export interface IDataSeriesPoint {
  value: number;
  date: string;
}

export interface ISize {
  height: number;
  width: number;
}

interface IGenericAnalyticsLineChartProps {
  series: IAnalyticsLineChartDataSet[];
  id: string;
  chartTitle: string;
  manufacturer?: string;
  model?: string;
  installDate?: string;
  leftAxisLabel?: string;
  rightAxisLabel?: string;
  size?: ISize;
  format?: FormatType;
}

export interface IAnalyticsChartDataSet {
  standard: string;
  name: string;
  axis: Axis;
  unit?: string;
  className?: string;
  isHidden?: boolean;
}

export interface IAnalyticsLineChartDataSet extends IAnalyticsChartDataSet {
  values: IDataSeriesPoint[];
}

const formatDate = () => "%Y-%m-%d %H:%M:%S";

const GenericAnalyticsLineChart = ({
  series,
  leftAxisLabel,
  rightAxisLabel,
  chartTitle,
  manufacturer,
  model,
  installDate,
  id,
  format,
  size
}: IGenericAnalyticsLineChartProps) => {
  const intl = useIntl();
  const {
    getTickFormatValuesForY,
    getTickFormatValuesForY2,
    getTickCountValuesForY2,
    getTooltipFormatDate
  } = StandardOilTestsChartService;

  const xs: any = {};
  const colors: any = {};
  const axes: any = {};
  const types: any = {};
  const classes: any = {};
  let columns: [string, ...c3.Primitive[]][] = [];
  const standards: any = {};
  const regions: { [key: string]: RegionOptions[] } = {};

  (series || []).forEach((s) => {
    xs[s.name] = `x_${s.name}`;
    colors[s.name] = getSeriesColor(s.name, s.axis === Axis.Left);
    if (s.axis === Axis.Right) axes[s.name] = "y2";
    types[s.name] = isThreshold(s.name) ? "step" : "line";

    if (s.values && s.values.length > 0) {
      columns = columns.concat([
        namedSeries(
          s.name,
          s.values.map((v) => v.value)
        )
      ]);
      columns = columns.concat([
        namedSeries(
          `x_${s.name}`,
          s.values.map((v) => v.date)
        )
      ]);
    }

    standards[s.name] = s.standard;
    regions[s.name] = isThreshold(s.name) ? [{ style: "dashed" }] : [];
  });

  let isEmpty =
    (series || []).filter((s) => s.values && s.values.length > 0).length === 0;

  if (isEmpty) {
    columns = [];
    isEmpty = true;
  }

  let labelY1: any = {};
  let labelY2: any = {};

  if (leftAxisLabel)
    labelY1 = {
      text: leftAxisLabel,
      position: "inner-top"
    };

  if (rightAxisLabel)
    labelY2 = {
      text: rightAxisLabel,
      position: "inner-top"
    };

  return (
    <div>
      <div className="analytics-chart-header">
        <div>{chartTitle}</div>
        <div>
          {manufacturer} {model}{" "}
          {installDate
            ? `(${moment(installDate).local().format("YYYY")})`
            : null}
        </div>
      </div>
      <C3Chart
        className={isEmpty ? "disabled" : ""}
        onRendered={(ref) => {
          AnalyticsLineChartService.chartRefs[id] = ref;
        }}
        configuration={{
          data: {
            xs,
            xFormat: formatDate(),
            columns,
            axes,
            types,
            colors,
            classes,
            regions,
            empty: {
              label: {
                text: `${intl.formatMessage({
                  id: "detail_page.widgets.analytics.linechart.nodata",
                  defaultMessage: "NO INPUT DATA AVAILABLE"
                })}`
              }
            }
          },
          grid: {
            y: {
              show: true
            }
          },
          axis: {
            x: {
              type: "timeseries",
              tick: {
                format: function (x: Date): string {
                  return intl.formatDate(moment(x).toDate());
                },
                count: 5,
                culling: {
                  max: 5
                }
              }
            },
            y: {
              min: 0,
              show: true,
              label: labelY1,
              tick: {
                format: getTickFormatValuesForY(format)
              }
            },
            y2: {
              show: true,
              label: labelY2,
              tick: {
                format: getTickFormatValuesForY2(format),
                count: getTickCountValuesForY2(format)
              }
            }
          },
          legend: {
            show: false
          },
          tooltip: {
            format: {
              title: getTooltipFormatDate(format, intl),
              name: (name: string) =>
                intl.formatMessage({
                  defaultMessage: name,
                  id: `detail_page.widgets.analytics.transformers.StandardOilTests.${standards[name]}.${name}`
                })
            }
          },
          size: size,
          line: {
            connectNull: false,
            step: {
              type: "step-after"
            }
          }
        }}
      />
    </div>
  );
};

export default GenericAnalyticsLineChart;
export { FormatType };
