// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Processing from "components/common/Processing";
import { WidgetErrorMessage } from "components/common/widget/Widget";
import { IMapProvider } from "core/app/reducers/settings/MapProviderReducer";
import Data, { Statuses } from "core/data/models/Data";
import getMapProviderSelector from "core/selectors/getMapProviderSelector";
import {
  getStationRiskSelector,
  loadStationRisk,
  LocationRisk,
  removeStationRisk
} from "features/RiskMapDashboard";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import styled from "styled-components";
import { ThunkDispatch } from "thunk";
import ICustomer from "../../../../core/app/models/ICustomer";
import { getUserSelector } from "../../../../core/app/reducers/AppReducer";
import { IUser } from "../../../../core/app/reducers/settings/UserReducer";
import getCustomerSelector from "../../../../core/selectors/getCustomerSelector";
import useRiskMap from "../hooks/useRiskMap";
import MapView from "./MapView";

interface IRiskMapDataActions {
  loadStationRisk: () => void;
  removeStationRisk: () => void;
}

interface IRiskMapData {
  mapProvider: IMapProvider;
  stationRisk: Data<LocationRisk[]>;
  customer: Data<ICustomer>;
  user: Data<IUser>;
}

interface IRiskMapOwnProps {
  className?: string;
}

type RiskMapProps = IRiskMapDataActions & IRiskMapData & IRiskMapOwnProps;

const RiskMap = ({
  className,
  mapProvider,
  stationRisk,
  loadStationRisk,
  removeStationRisk,
  customer,
  user
}: RiskMapProps) => {
  const { handleStationClick, handleClusterClick } = useRiskMap(
    loadStationRisk,
    removeStationRisk,
    customer,
    user
  );

  return (
    <div className="open-issues">
      <div className="tile" data-qa="spatial-view-tile">
        <div className={`tile-content ${className}`}>
          {stationRisk?.status === Statuses.Loading && (
            <Processing className="spinner small dark" />
          )}
          {stationRisk?.status === Statuses.Succeeded && (
            <MapView
              onStationClick={handleStationClick}
              onClusterClick={handleClusterClick}
              mapProvider={mapProvider}
              stationRisk={stationRisk}
            />
          )}
          {stationRisk?.status === Statuses.Failed && (
            <WidgetErrorMessage
              messageId="homepage.server_error"
              messageDefault="Sorry, we've got problems with server connection."
            />
          )}
        </div>
      </div>
    </div>
  );
};

const StyledRiskMap = styled(RiskMap)`
  .station-risk-map {
    height: 450px;
  }
`;

const mapStateToProps = (state: IState): IRiskMapData => {
  return {
    mapProvider: getMapProviderSelector(state),
    stationRisk: getStationRiskSelector(state),
    customer: getCustomerSelector(state),
    user: getUserSelector(state)
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any>
): IRiskMapDataActions => ({
  loadStationRisk: () => dispatch(loadStationRisk()),
  removeStationRisk: () => dispatch(removeStationRisk())
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledRiskMap);
