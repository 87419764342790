// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "thunk";

import removeDataAction from "./removeDataAction";

const removeData = (key: string): ThunkAction<Promise<void>, Action> => (
  dispatch: ThunkDispatch<Action>
) =>
  new Promise(resolve => {
    dispatch(removeDataAction(key));
    resolve();
  });

export default removeData;
