// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Col, Row } from "antd";
import Container from "common/Container";
import { UserRoles } from "core/app/components/auth/Authorization";
import { getUserSelector } from "core/app/reducers/AppReducer";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Analytics from "./analytics/Analytics";
import AssetRisk from "./AssetRisk";
import KeyRiskChanges from "./keyriskchanges/components/keyriskchanges";
import NumberOfAssetsContainer from "./numberofassets/containers/NumberOfAssetsContainer";
import OpenIssues from "./openissues";
import RiskMap from "./RiskMap";

const MainDashboard: React.FunctionComponent = () => {
  const user = useSelector(getUserSelector);

  const userHasAccess = useMemo(
    () =>
      AuthorizationService.isAuthorized(user.data as IUser, [
        UserRoles.Administrator,
        UserRoles.Engineer
      ]),
    [user.data]
  );

  return (
    <Container>
      <Row className="homepage">
        <Col span={12}>
          <AssetRisk />
          <RiskMap />
        </Col>
        <Col span={6}>
          <OpenIssues />
        </Col>
        <Col span={6}>
          {userHasAccess && <Analytics />}
          <NumberOfAssetsContainer />
          <KeyRiskChanges />
        </Col>
      </Row>
    </Container>
  );
};

export default MainDashboard;
