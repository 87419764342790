// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { ChangeEvent, useCallback, useState } from "react";

const isNumber = (value: number) => {
  return typeof value === "number";
};

const useSimulationSettings = (
  defaultBeginValue: number,
  defaultEndValue: number
) => {
  const [inputBeginValue, setInputBeginValue] = useState(defaultBeginValue);
  const [sliderBeginValue, setSliderBeginValue] = useState(defaultBeginValue);

  const [inputEndValue, setInputEndValue] = useState(defaultEndValue);
  const [sliderEndValue, setSliderEndValue] = useState(defaultEndValue);

  const [inputNameValue, setInputNameValue] = useState<string>(null);

  const handleInputBeginChange = useCallback(
    (value: number) => {
      if (isNumber(value)) {
        if (value > inputEndValue) {
          setInputBeginValue(inputEndValue);
          setSliderBeginValue(inputEndValue);
        } else {
          setInputBeginValue(value);
          setSliderBeginValue(value);
        }
      }
    },
    [inputEndValue]
  );

  const handleInputEndChange = useCallback(
    (value: number) => {
      if (isNumber(value)) {
        if (inputBeginValue > value) {
          setInputEndValue(inputBeginValue);
          setSliderEndValue(inputBeginValue);
        } else {
          setInputEndValue(value);
          setSliderEndValue(value);
        }
      }
    },
    [inputBeginValue]
  );

  const handleInputNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInputNameValue(event.target.value || null);
    },
    []
  );

  const handleSliderChange = useCallback(
    ([beginValue, endValue]: [number, number]) => {
      setSliderBeginValue(isNumber(beginValue) ? beginValue : undefined);
      setInputBeginValue(isNumber(beginValue) ? beginValue : undefined);
      setSliderEndValue(isNumber(endValue) ? endValue : undefined);
      setInputEndValue(isNumber(endValue) ? endValue : undefined);
    },
    []
  );

  return {
    inputBeginValue,
    sliderBeginValue,
    handleInputBeginChange,
    inputEndValue,
    sliderEndValue,
    handleInputEndChange,
    handleSliderChange,
    inputNameValue,
    handleInputNameChange
  };
};

export default useSimulationSettings;
