// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { StatusPanel } from "@pg/common/build/components/FilterPanel";
import { FC } from "react";
import { useIntl } from "react-intl";

const StatusBar: FC = () => {
  const intl = useIntl();

  return (
    <StatusPanel
      translations={{
        clearFilters: intl.formatMessage({
          defaultMessage: "Clear Filters",
          id: "filter_panel.status_panel.clear_all"
        }),
        unknownValue: intl.formatMessage({
          defaultMessage: "Unknown",
          id: "filter_panel.status_panel.unknown"
        }),
        range: (displayName: string, min: number, max: number) =>
          intl.formatMessage(
            {
              defaultMessage: "{displayName}: {min, number} - {max, number}",
              id: "filter_panel.status.panel.range"
            },
            {
              displayName,
              min,
              max
            }
          )
      }}
    />
  );
};

export default StatusBar;
