// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Processing from "components/common/Processing";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import Data, { Statuses } from "core/data/models/Data";
import React from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import AnalyticsChartLegend from "../AnalyticsChartLegend";
import AnalyticsLineChart, {
  IAnalyticsLineChartDataSet,
  ISize
} from "../AnalyticsLineChart";
import AnalyticsLineChartService from "../AnalyticsLineChartService";
import StandardOilTestsChartService from "./StandardOilTestsChartService";
import StandardOilTestsDataService from "./StandardOilTestsDataService";
import "./StandardOilTestsTab.less";
import StandardOilTestsTabService, {
  acidNumber,
  dielectricStrength,
  inhibitorContent,
  interfacialTension,
  moisture,
  powerFactor
} from "./StandardOilTestsTabService";

interface IStandardOilTestsTabOwnProps {
  assetId: string;
  intl: IntlShape;
}

interface IStandardOilTestsTabState {
  transformerStandardOilData: Data<IParamCollection>;
}

export interface IParamCollection {
  OilStandard: string;
  Charts: IChartsCollection | null | undefined;
}

interface IChartsCollection {
  [paramName: string]: IOilParameters | null | undefined;
}

export interface IOilParameters {
  OfflineTrends: IParamValues | null | undefined;
  OnlineTrends: IParamValues | null | undefined;
  Thresholds: { [name: string]: IThresholdInterval[] };
}

interface IParamValues {
  [date: string]: number | null | undefined;
}

interface IThresholdInterval {
  Start: string;
  End: string;
  Value: number;
}

class StandardOilTestsTab extends React.Component<
  IStandardOilTestsTabOwnProps,
  IStandardOilTestsTabState
> {
  constructor(props: IStandardOilTestsTabOwnProps) {
    super(props);

    this.state = {
      transformerStandardOilData: new Data()
    };
  }

  componentDidMount() {
    const { assetId } = this.props;
    const { loadStandardOilParametersTrends } = StandardOilTestsDataService;

    const request = loadStandardOilParametersTrends(
      assetId,
      (request: JQuery.jqXHR, data: IParamCollection) => {
        this.setState(() => ({
          transformerStandardOilData: new Data(request, data)
        }));
      },
      (error: string): void => {
        this.setState(() => ({
          transformerStandardOilData: new Data(request)
        }));
      }
    );

    this.setState(() => ({
      transformerStandardOilData: new Data(request)
    }));
  }

  render() {
    const { getComponent } = this;
    const { transformerStandardOilData } = this.state;
    return getComponent(transformerStandardOilData.status);
  }

  private handleChartLegendFocused = (series: string, plotName: string) => {
    const { focus } = AnalyticsLineChartService;
    focus(series, plotName);
  };

  private handleChartLegendReverted = (series: string) => {
    const { revert } = AnalyticsLineChartService;
    revert(series);
  };

  private handleChartLegendToggled = (series: string, plotName: string) => {
    const { toggle } = AnalyticsLineChartService;
    toggle(series, plotName);
  };

  private getStandardOilTestWidgets(): JSX.Element[] {
    const { getChartTitle, getChartUnit, getChartType } =
      StandardOilTestsChartService;
    const size: ISize = {
      height: 180,
      width: null
    };

    const chartsRows = [
      [interfacialTension, moisture, powerFactor],
      [dielectricStrength, acidNumber, inhibitorContent]
    ];

    const sot = this.state.transformerStandardOilData.data;

    return chartsRows.map((chartsRow) => (
      <div
        className="standard-oil-tests-row"
        data-qa="charts-row"
        key={`row ${chartsRow.join("-")}`}
      >
        {chartsRow.map((chartName) => {
          const {
            getThresholdFairSet,
            getThresholdMinSet,
            getThresholdMaxSet,
            getThresholdPoorSet,
            hasThresholdMinSeries,
            hasThresholdMaxSeries,
            hasThresholdPoorSeries,
            hasThresholdFairSeries,
            hasTrendsData,
            hasOnlineSeries,
            hasOfflineSeries,
            pushSet
          } = StandardOilTestsTabService;

          const sets: IAnalyticsLineChartDataSet[] = [];

          if (hasTrendsData(sot, chartName)) {
            if (sot.OilStandard === "IEEE") {
              if (hasThresholdMinSeries(sot, chartName))
                sets.push(...getThresholdMinSet(sot, chartName));
              if (hasThresholdMaxSeries(sot, chartName))
                sets.push(...getThresholdMaxSet(sot, chartName));
            } else if (sot.OilStandard === "IEC") {
              if (hasThresholdPoorSeries(sot, chartName))
                sets.push(...getThresholdPoorSet(sot, chartName));
              if (hasThresholdFairSeries(sot, chartName))
                sets.push(...getThresholdFairSet(sot, chartName));
            }

            if (hasOnlineSeries(sot, chartName))
              pushSet(sets, sot, chartName, "online");
            if (hasOfflineSeries(sot, chartName))
              pushSet(sets, sot, chartName, "offline");
          }

          return (
            <div
              className="standard-oil-tests-col"
              key={`col ${chartName}`}
              data-qa={`${chartName}-chart`}
            >
              <div className="sot-chart">
                <AnalyticsLineChart
                  id={chartName}
                  chartTitle={getChartTitle(
                    sot.OilStandard,
                    chartName,
                    this.props.intl
                  )}
                  size={size}
                  leftAxisLabel={getChartUnit(chartName)}
                  format={getChartType(chartName)}
                  series={sets}
                />
                <AnalyticsChartLegend
                  plots={sets}
                  series={chartName}
                  onFocus={this.handleChartLegendFocused}
                  onRevert={this.handleChartLegendReverted}
                  onToggle={this.handleChartLegendToggled}
                />
              </div>
            </div>
          );
        })}
      </div>
    ));
  }

  private getNoDataComponent = (): JSX.Element => {
    return (
      <div className="standard-oil-tests" data-qa="standard-oil-tests">
        <WidgetNoDataMessage />
      </div>
    );
  };

  private getLoadingComponent = (): JSX.Element => {
    return (
      <div className="standard-oil-tests" data-qa="standard-oil-tests">
        <Processing />
      </div>
    );
  };

  private getErrorComponent = (): JSX.Element => {
    return (
      <div className="standard-oil-tests" data-qa="standard-oil-tests">
        <WidgetErrorMessage
          messageId="global.empty"
          messageDefault=""
          messageValues=""
        />
      </div>
    );
  };

  private getComponent = (status: Statuses): JSX.Element => {
    switch (status) {
      case Statuses.Loading: {
        return this.getLoadingComponent();
      }
      case Statuses.Succeeded: {
        if (
          this.state.transformerStandardOilData.data !== undefined &&
          this.state.transformerStandardOilData.data !== null
        )
          return (
            <div className="standard-oil-tests" data-qa="standard-oil-tests">
              {this.getStandardOilTestWidgets()}
              <div className="oil-standard">
                <FormattedMessage
                  defaultMessage="Oil Standard {standard}"
                  id="detail_page.widgets.analytics.transformers.oil_standard"
                  values={{
                    standard:
                      this.state.transformerStandardOilData.data.OilStandard
                  }}
                />
              </div>
            </div>
          );
        else return this.getNoDataComponent();
      }
      case Statuses.Failed: {
        return this.getErrorComponent();
      }
      default: {
        return this.getNoDataComponent();
      }
    }
  };
}

export default injectIntl(StandardOilTestsTab, {
  forwardRef: true
});
