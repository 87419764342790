// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { useSelector } from "react-redux";

import getAdSupportContactUrlSelector from "../selectors/getAdSupportContactUrlSelector";
import getUserHasEmailSelector from "../selectors/getUserHasEmailSelector";

import ActiveDirectoryEmailStatus from "../components/ActiveDirectoryEmailStatus";

const ActiveDirectoryEmailStatusContainer = () => {
  const adSupportContactUrl = useSelector(getAdSupportContactUrlSelector);
  const userHasEmail = useSelector(getUserHasEmailSelector);

  return (
    <ActiveDirectoryEmailStatus
      adSupportContactUrl={adSupportContactUrl}
      userHasEmail={userHasEmail}
    />
  );
};

export default ActiveDirectoryEmailStatusContainer;
