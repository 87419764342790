// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import { isEmpty, isEqual } from "lodash";

const addFilterSearchParam = (
  searchParams: SearchParams,
  paramName: string,
  paramValue: string[]
) => {
  paramValue = paramValue.map((value) => (isEmpty(value) ? null : value));

  const params = searchParams
    .getAll(paramName)
    .map<string[]>((p) => JSON.parse(p));

  const matchedParams = params.filter((p) =>
    isEqual(p.slice(0, paramValue.length), paramValue)
  );

  searchParams.delete(paramName);
  const newValues = matchedParams?.length ? [...matchedParams] : [paramValue];
  newValues.forEach((v) => searchParams.append(paramName, JSON.stringify(v)));
};

export default addFilterSearchParam;
