// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";

import BatteryConductanceContainer from "../containers/BatteryConductanceContainer";

interface IBatteryConductanceTabProps {
  assetId: string;
}

const BatteryConductanceTab: React.FC<IBatteryConductanceTabProps> = ({
  assetId
}) => <BatteryConductanceContainer assetId={assetId} />;

export default BatteryConductanceTab;
