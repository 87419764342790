// Copyright 2016-2021 Hitachi Power Grids. All rights reserved.
import { isNil } from "lodash";
import { Moment } from "moment";
import { FormattedDate } from "react-intl";
import IField from "../../models/IField";

interface IValueFieldProps {
  configuration: IField;
  toggleEdit: () => void;
  value?: number | string | Moment;
}

const ValueField = ({ configuration, toggleEdit, value }: IValueFieldProps) => {
  return (
    <div
      className={"input-value"}
      onClick={toggleEdit}
      id={configuration.fieldKey}
    >
      {configuration.inputType === "DateTime" && !isNil(value) && (
        <FormattedDate
          value={(value as Moment).toDate()}
          year="numeric"
          month="numeric"
          day="numeric"
        />
      )}
      {(configuration.inputType !== "DateTime" || isNil(value)) && value}
    </div>
  );
};

export default ValueField;
