// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import { Menu, Typography } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import { useCallback } from "react";
import styled from "styled-components";
import { colorStatusRed, colorTeal100 } from "styles/ColorVariables";
import { spacingLarge } from "styles/StyleVariables";

const { SubMenu } = Menu;
const { Text } = Typography;

export interface IMenuItem {
  key: string;
  title: string;
  iconName?: string;
  children?: Array<IMenuItem>;
  invalid?: boolean;
  touched?: boolean;
}

interface IAssetModalMenuProps {
  menuItems: Array<IMenuItem>;
  onMenuChange: (key: string, keyPath: string[]) => void;
  selectedMenu?: string;
  className?: string;
}

const AssetModalMenu = ({
  menuItems,
  onMenuChange,
  selectedMenu,
  className
}: IAssetModalMenuProps) => {
  const handleMenuClick = useCallback(
    (info: MenuInfo) => {
      onMenuChange(info.key, info.keyPath);
    },
    [onMenuChange]
  );

  return (
    <Menu
      style={{ width: 200 }}
      defaultOpenKeys={menuItems.map((item) => item.key)}
      selectedKeys={[selectedMenu]}
      mode="inline"
      onClick={handleMenuClick}
      inlineIndent={0}
      className={className}
    >
      {menuItems.map((submenuItem) => {
        return (
          <SubMenu
            className={className}
            key={submenuItem.key}
            title={submenuItem.title}
          >
            {submenuItem.children?.map((menuItem) => {
              return (
                <Menu.Item
                  key={menuItem.key}
                  icon={
                    menuItem.iconName ? (
                      <Icon
                        className="menu-custom-icon"
                        name={menuItem.iconName}
                      />
                    ) : (
                      ""
                    )
                  }
                >
                  <Text title={menuItem.title}>{menuItem.title}</Text>
                  {menuItem.invalid ? (
                    <Icon
                      className="menu-validation-icon"
                      name="circle"
                      size="xs"
                    />
                  ) : menuItem.touched ? (
                    <Icon
                      className="menu-touched-icon"
                      name="circle"
                      size="xs"
                    />
                  ) : (
                    ""
                  )}
                </Menu.Item>
              );
            })}
          </SubMenu>
        );
      })}
    </Menu>
  );
};

const StyledAssetModalMenu = styled(AssetModalMenu)`
  .menu-custom-icon {
    svg {
      display: flex;
    }
  }
  .svg-icon.md {
    width: auto;
  }

  .ant-menu-title-content {
    display: flex;
    .ant-typography {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .menu-touched-icon {
      color: ${colorTeal100};
      margin: auto ${spacingLarge};
    }

    .menu-validation-icon {
      color: ${colorStatusRed};
      margin: auto ${spacingLarge};
    }
  }
`;

export default StyledAssetModalMenu;
