// Copyright 2016-2022 Hitachi Energy. All rights reserved.
const manufacturers = {
  a_c: "a_c - Allis Chalmers",
  abb: "abb - Asea Brown Boveri",
  aeg: "aeg - AEG",
  alst: "alst - Alstom",
  ansa: "ansa - Ansaldo",
  arda: "arda - Ardan",
  areva: "areva - AREVA",
  asea: "asea - Asea",
  bbc: "bbc - Brown-Boveri",
  betra: "betra - BETRA",
  bhel: "bhel - Bharat Heavy Electricals",
  btw: "btw - BTW",
  burro: "burro - Burrows",
  celme: "celme - CELME",
  cemo: "cemo - Central Moloney",
  cge: "cge - Canadian General Electric",
  chang: "chang - Changzhou",
  com: "com - Comtrafo",
  coopr: "coopr - Cooper",
  crmpg: "crmpg - Crompton Greaves",
  crmpp: "crmpp - Crompton Parkinson",
  cxdg: "cxdg - China XD Group",
  dest: "dest - Delta Star",
  efacec: "efacec - EFACEC",
  elbu: "elbu - Elektrisk Bureau",
  elco: "elco - Elco",
  elin: "elin - Elin",
  emtr: "emtr - Emirates Transformer",
  enel: "enel - English Electric",
  erma: "erma - Ercoli Marelli",
  ernco: "ernco - Ernco",
  euroe: "euroe - Euroelettro",
  fayet: "fayet - Fayetteville",
  fed_pac: "fed_pac - Federal Pacific",
  fed_pio: "fed_pio - Federal Pioneer",
  ferranti: "ferranti - Ferranti-Packard",
  forte: "forte - Fortune Electric",
  fuji: "fuji - Fuji",
  ge: "ge - General Electric",
  hangz: "hangz - Hangzhou",
  hawks: "hawks - Hawker Siddeley",
  hefel: "hefel - Hefel",
  hico: "hico - HICO",
  hitachi: "hitachi - Hitachi",
  howa: "howa - Howard",
  hps: "hps - HPS",
  hyosu: "hyosu - HYOSUNG",
  hyundai: "hyundai - Hyundai",
  india: "india - Indiamart",
  iniem: "iniem - Industrias IEM",
  jaeps: "jaeps - Jaeps",
  jesch: "jesch - Jeumont Schneider",
  jshp: "jshp - JSHP",
  jsttr: "jsttr - JST Transformers",
  ketra: "ketra - Kolektor ETRA",
  kirel: "kirel - Kirloskar Electric",
  koncar: "koncar - KONCAR",
  kuhlm: "kuhlm - Kuhlman",
  legna: "legna - Legnano",
  mace: "mace - Mace",
  mag: "mag - Magnetek",
  marke: "marke - Markham Electric",
  mcgraw: "mcgraw - McGraw-Edison",
  mec: "mec - Magnetic Electric Company",
  mitsubishi: "mitsubishi - Mitsubishi",
  moloney: "moloney - Moloney",
  na: "na - North American Transformers",
  natin: "natin - National Industri",
  niatr: "niatr - Niagara Transformer",
  oerli: "oerli - Oerlikon",
  ohtr: "ohtr - Ohio Transformer",
  other: "other",
  pauw: "pauw - Pauwels",
  penn: "penn - Pennsylvania Transformer",
  pmeps: "pmeps - PME Power Solutions",
  powtr: "powtr - Powertech Transformers",
  prme: "prme - Prime Meiden",
  prolec: "prolec - Prolec",
  rte: "rte - RTE",
  sanbi: "sanbi - Sanbian",
  sea: "sea - SEA",
  shang: "shang - Shangong",
  siem: "siem - Siemens",
  skot: "skot - Skot",
  smit: "smit - Smit",
  snc: "snc - SNC",
  spx: "spx - SPX",
  strom: "strom - Stromberg",
  subo: "subo - Suzhou Boyuan",
  swe: "swe - Southwest Electric",
  tal: "tal - Technical Associates",
  tami: "tami - Tamini",
  tamu: "tamu - Tamura",
  tbea: "tbea - TBEA",
  toshiba: "toshiba - Toshiba",
  trasf: "trasf - Trasfor",
  trun: "trun - Trafo Union",
  tti: "tti - TTI",
  ust: "ust - US Transformer",
  volta: "volta - Voltamp",
  voro: "voro - VonRoll",
  vtc: "vtc - Virginia Transformers",
  vtech: "vtech - VA Tech",
  vtran: "vtran - VanTran",
  wagn: "wagn - Wagner",
  wau: "wau - Waukesha",
  weg: "weg - WEG",
  west: "west - Westinghouse",
  wils: "wils - Wilson",
  xixia: "xixia - Xian Xiandian",
  zaptr: "zaptr - Zaporozhtransformator"
};

export default manufacturers;
