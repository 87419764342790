// Copyright 2016-2021 Hitachi Power Grids. All rights reserved.
import { Col, Typography } from "antd";
import { Moment } from "moment";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import ISection from "../models/ISection";
import CustomField from "./CustomField";

const { Title } = Typography;

interface ICustomSectionListProps {
  configuration: ISection;
  fieldIsRequired?: (fieldName: string) => boolean;
  getInitialValue?: (
    fieldName: string
  ) => string | number | boolean | Date | Moment;
  horizontally?: boolean;
}

const CustomSectionList = ({
  configuration,
  fieldIsRequired,
  getInitialValue,
  horizontally = false
}: ICustomSectionListProps) => {
  const fieldNodes = useMemo(() => {
    return configuration.fields.map((field) => {
      const key = field.guid
        ? `${field.fieldKey}-${field.guid}`
        : field.fieldKey;

      return !horizontally ? (
        <CustomField
          key={key}
          configuration={field}
          fieldIsRequired={fieldIsRequired}
          getInitialValue={getInitialValue}
        />
      ) : (
        <Col span={8} key={key}>
          <CustomField
            configuration={field}
            fieldIsRequired={fieldIsRequired}
            getInitialValue={getInitialValue}
          />
        </Col>
      );
    });
  }, [configuration.fields, fieldIsRequired, getInitialValue, horizontally]);

  return (
    <div className="section section-list">
      <Title level={5}>
        {configuration.sectionName && (
          <FormattedMessage {...configuration.sectionName} />
        )}
      </Title>
      <div
        className={`section-nodes-wrapper ${
          horizontally ? "section-nodes-horizontally" : ""
        } `}
      >
        {fieldNodes}
      </div>
    </div>
  );
};

export default CustomSectionList;
