// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import Data from "core/data/models/Data";
import { IState } from "reducers/Index";

import { getDataKey } from "../actions/loadMechanical";

import IMechanicalPoles from "../models/IMechanicalPoles";

const getMechanicalSelector = createSelector<
  IState,
  (assetId: string) => Data<IMechanicalPoles>,
  (assetId: string) => Data<IMechanicalPoles>
>(
  (state: IState) => (assetId: string) => state.data[getDataKey(assetId)],
  (mechanical) => mechanical
);

export default getMechanicalSelector;
