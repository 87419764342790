// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";

import AuthorizationMessage from "core/app/components/auth/AuthorizationMessage";
import { IUser } from "core/app/reducers/settings/UserReducer";
import AuthorizationService from "core/app/services/AuthorizationService";

export enum UserRoles {
  Administrator,
  Engineer,
  LimitedEngineer,
  ViewerEngineer
}

export interface IAuthorizationData {
  user: IUser;
}

export interface IAuthorizationProps
  extends React.Props<IAuthorizationProps>,
    IAuthorizationData {
  className?: string;
  roles: UserRoles[];
  showMessage?: boolean;
  message?: string;
}

const Authorization = (props: IAuthorizationProps) => {
  const { className, children, user, roles, message, showMessage } = props;

  const isAuthorized = user
    ? AuthorizationService.isAuthorized(user, roles)
    : false;

  let component: JSX.Element | null = null;
  if (isAuthorized) {
    component = <div className={className}>{children}</div>;
  } else if (showMessage) {
    component = <AuthorizationMessage message={message} />;
  }

  return component;
};

export default Authorization;
