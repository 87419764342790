// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import React, { useMemo } from "react";

import RoutableTabs from "common/RoutableTabs";
import useReplacementScoreTabs from "./hooks/useReplacementScoreTabs";

import "./ReplacementScoreTabs.less";

interface IReplacementScoreTabsProps {
  assetId: string;
  replacementScoreModelImplementationId: string;
}

const ReplacementScoreTabs = ({
  assetId,
  replacementScoreModelImplementationId
}: IReplacementScoreTabsProps) => {
  const tabs = useReplacementScoreTabs(
    assetId,
    replacementScoreModelImplementationId
  );
  const searchParams = useMemo(() => new SearchParams({ assetId }), [assetId]);
  return (
    <RoutableTabs tabs={tabs} noBorder={true} searchParams={searchParams} />
  );
};

export default ReplacementScoreTabs;
