// Copyright 2016-2022 Hitachi Energy. All rights reserved.

const oilPreservationPressure = [
  "normal",
  "broken",
  "missing",
  "cannot see",
  "cannot interpret"
];

export default oilPreservationPressure;
