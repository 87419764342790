// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Icon } from "@pg/common";
import SearchParams from "@pg/common/build/models/SearchParams";
import { routesConfig } from "core/app/components/router/RouterProvider";
import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colorPrimary } from "styles/ColorVariables";
import {
  spacingBase,
  spacingLarge,
  spacingXLarge,
  spacingXSmall
} from "styles/StyleVariables";
import IssuesListAssetInfoIssues from "../components/IssuesListAssetInfoIssues";
import IAssetWithIssues from "../models/AssetWithIssues";

interface IIssuesListAssetInfoProps {
  asset: IAssetWithIssues;
  className?: string;
}

const IssuesListAssetInfo = ({
  asset,
  className = ""
}: IIssuesListAssetInfoProps) => {
  const [issueListVisible, setIssueListVisible] = useState<boolean>(true);

  const searchParams = useMemo(
    () => new SearchParams({ assetId: asset.AssetId }),
    [asset?.AssetId]
  );

  return (
    <div
      className={`${className} ${!issueListVisible && "list-item-collapsed"}`}
      onClick={() => {
        setIssueListVisible((isVisible) => !isVisible);
      }}
    >
      <div className="asset-info-wrapper">
        <div className="asset-info">
          <Link
            className="asset-id"
            to={{
              pathname: routesConfig.detailPage,
              search: searchParams.toString()
            }}
          >
            {asset.AssetId}
            {asset.Name && " - "}
            {asset.Name}
          </Link>
          {!issueListVisible && (
            <FormattedMessage
              defaultMessage="{count} Issues"
              id="locations_page.side_panel.issues.issues_count"
              values={{
                count: asset.Issues.length
              }}
            />
          )}
        </div>

        <Icon name={issueListVisible ? "expand_less" : "expand_more"} />
      </div>
      {issueListVisible && <IssuesListAssetInfoIssues issues={asset.Issues} />}
    </div>
  );
};

export default styled(IssuesListAssetInfo)`
  display: flex;
  flex-direction: column;
  width: 100%;

  &.list-item-collapsed {
    cursor: pointer;
  }

  .asset-info-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding: ${spacingLarge} 0 ${spacingLarge} 0;

    .asset-info {
      display: flex;
      flex-direction: column;
      row-gap: ${spacingBase};
    }

    .asset-id {
      font-size: ${spacingXLarge};
      color: ${colorPrimary};
      padding-bottom: ${spacingXSmall};
    }

    .issue-count {
      font-size: ${spacingXLarge};
    }
  }
`;
