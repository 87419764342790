// Copyright 2016-2022 Hitachi Energy. All rights reserved.
export default class Sequence {
  private value: number;

  constructor(value?: number) {
    this.value = value ? value : 0;
  }

  next() {
    return ++this.value;
  }
}
