// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import IssueGrid from "./components/IssuesGrid";
import {
  IOnMaintenancePriorityClickOptions as _IOnMaintenancePriorityClickOptions,
  IOnNumberOfActionsClickOptions as _IOnNumberOfActionsClickOptions
} from "./hooks/useIssuesGridColumnsConfig";

export default IssueGrid;
export type IOnMaintenancePriorityClickOptions = _IOnMaintenancePriorityClickOptions;
export type IOnNumberOfActionsClickOptions = _IOnNumberOfActionsClickOptions;
