// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import EndpointService from "core/data/services/EndpointService";
import UrlService from "core/data/services/UrlService";
import { IAjaxAction } from "reducers/Index";
import { Action, Dispatch } from "redux";
import { config } from "utils/AppConfig";

export enum Actions {
  Assets_SetDeleteAsset = "Assets_SetDeleteAsset"
}

export const setCloseIssueAction = (request: JQueryXHR): IAjaxAction<void> => {
  const action: IAjaxAction<void> = {
    type: Actions.Assets_SetDeleteAsset,
    request,
    data: undefined
  };
  return action;
};

export const deleteAsset = (dispatch: Dispatch<Action>, assetId: string) =>
  new Promise((resolve, reject) => {
    const url = UrlService.getApiUrl(config.api.assetQuery.deleteAsset, {
      assetId
    });

    const request = EndpointService.delete(
      url,
      (request, data) => {
        dispatch(setCloseIssueAction(request));
        resolve(data);
      },
      (request) => {
        dispatch(setCloseIssueAction(request));
        reject(request);
      }
    );

    dispatch(setCloseIssueAction(request));
  });
