// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useSelector } from "react-redux";
import { Action } from "redux";
import { useThunkMiddleware } from "thunk";
import { loadKeyRiskChangesInfo as loadKeyRiskChangesInfoAction } from "../actions/KeyRiskChangesActions";
import { getKeyRiskChangesInfoSelector } from "../reducers/KeyRiskChangesReducer";

const useKeyRiskChanges = () => {
  const keyRiskChangesInfo = useSelector(getKeyRiskChangesInfoSelector);
  const loadKeyRiskChangesInfo = useThunkMiddleware<Promise<void>, Action>(
    loadKeyRiskChangesInfoAction()
  );

  return { keyRiskChangesInfo, loadKeyRiskChangesInfo };
};

export default useKeyRiskChanges;
