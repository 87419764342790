// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SpatialView from "@apm/widgets/build/widgets/SpatialView";
import { Typography } from "antd";
import FilterBar from "common/FilterBar";
import { isNil } from "lodash";
import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import useRiskMapDashboard from "../hooks/useRiskMapDashboard";

const { Text } = Typography;

interface IRiskMapDashboardProps {
  className?: string;
}

const RiskMapDashboard = ({ className }: IRiskMapDashboardProps) => {
  const intl = useIntl();
  const {
    handleFilter,
    mapProvider,
    stationRisk,
    handleSingleMarkerClick,
    handleClusterMarkerClick
  } = useRiskMapDashboard();

  const formatName = useCallback(
    (name: string, level: string) =>
      isNil(name) ? (
        <Text style={{ fontStyle: "italic" }} type="secondary">
          {intl.formatMessage({
            defaultMessage: "Unknown",
            id: level + ".unknown"
          })}
        </Text>
      ) : (
        name
      ),
    [intl]
  );

  return (
    <div className={`${className} risk-map-dashboard`}>
      <FilterBar
        namespace="risk-map-dashboard"
        useClearAll
        useAssetOrganizationWithRegionAndLocation
        useAssetTypeWithSubType
        onFilter={handleFilter}
      >
        <SpatialView
          config={{
            key: mapProvider.Key,
            type: mapProvider.Type.toString(),
            urlTemplate: mapProvider.MapProviderUriSchema
          }}
          onStationClick={handleSingleMarkerClick}
          onClusterClick={handleClusterMarkerClick}
          showChangeIndicator
          stations={
            stationRisk?.data?.map((s) => ({
              id: `${s.Organization}${s.Region}${s.Station}`,
              organization: s.Organization,
              region: s.Region,
              location: s.Station,
              highRiskAssetCount: s.HighRiskAssetCount,
              mediumRiskAssetCount: s.MediumRiskAssetCount,
              lowRiskAssetCount: s.LowRiskAssetCount,
              unknownRiskAssetCount: s.UnknownRiskAssetCount,
              latitude: s.Latitude,
              longitude: s.Longitude,
              stateChanged: s.StateChanged
            })) || []
          }
          translations={{
            locations: (numberOfLocations) =>
              intl.formatMessage(
                {
                  id: "homepage.station_risk.map.cluster.title.multi_system",
                  defaultMessage:
                    "{ value, plural, zero {no locations} one {# location} other {# locations} }"
                },
                {
                  value: numberOfLocations
                }
              ),
            highAssets: (value) =>
              intl.formatMessage(
                {
                  id: "homepage.station_risk.map.pushpin_info.high",
                  defaultMessage: "{ value, number } high"
                },
                {
                  value
                }
              ),
            mediumAssets: (value) =>
              intl.formatMessage(
                {
                  id: "homepage.station_risk.map.pushpin_info.medium",
                  defaultMessage: "{ value, number } medium"
                },
                {
                  value
                }
              ),
            lowAssets: (value) =>
              intl.formatMessage(
                {
                  id: "homepage.station_risk.map.pushpin_info.low",
                  defaultMessage: "{ value, number } low"
                },
                {
                  value
                }
              ),
            unknownAssets: (value) =>
              intl.formatMessage(
                {
                  id: "homepage.station_risk.map.pushpin_info.unknown",
                  defaultMessage: "{ value, number } unknown"
                },
                {
                  value
                }
              ),
            name: (organization: string, region: string, location: string) => (
              <FormattedMessage
                defaultMessage="{organization} / {region} / {location}"
                id="homepage.station_risk.map.station_name"
                values={{
                  organization: formatName(organization, "organization"),
                  region: formatName(region, "region"),
                  location: formatName(location, "location")
                }}
              />
            )
          }}
        />
      </FilterBar>
    </div>
  );
};

const StyledRiskMapDashboard = styled(RiskMapDashboard)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .filter-panel {
    flex-shrink: 0;
  }
`;

export default StyledRiskMapDashboard;
