// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { Action } from "redux";
import { ThunkDispatch } from "thunk";
import {
  loadNumberOfAssetsByOrganizations,
  loadNumberOfAssetsByType,
  setPage
} from "../actions/NumberOfAssetsActions";
import NumberOfAssets, {
  INumberOfAssetsActions
} from "../components/NumberOfAssets";
import {
  getNumberOfAssetsByOrganizationSelector,
  getNumberOfAssetsByTypeSelector,
  getPageSelector,
  INumberOfAssetsStateProps,
  Page
} from "../reducers/NumberOfAssetsReducer";

export function mapStateToProps(state: IState): INumberOfAssetsStateProps {
  return {
    numberOfAssetsByOrganization:
      getNumberOfAssetsByOrganizationSelector(state),
    numberOfAssetsByType: getNumberOfAssetsByTypeSelector(state),
    page: getPageSelector(state)
  };
}

export function mapDispatchToProps(
  dispatch: ThunkDispatch<Action>
): INumberOfAssetsActions {
  return {
    loadNumberOfAssetsByType: () => dispatch(loadNumberOfAssetsByType()),
    loadNumberOfAssetsByOrganizations: () =>
      dispatch(loadNumberOfAssetsByOrganizations()),
    setPage: (page: Page) => dispatch(setPage(page))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberOfAssets);
