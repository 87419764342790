// Copyright 2016-2022 Hitachi Energy. All rights reserved.
export default class AnalyticsLineChartService {
  static chartRefs: any = {};

  static focus(chartName: string, plotName: string) {
    const { chartRefs } = AnalyticsLineChartService;
    if (chartRefs && chartRefs[chartName]) chartRefs[chartName].focus(plotName);
  }

  static revert(chartName: string) {
    const { chartRefs } = AnalyticsLineChartService;
    if (chartRefs && chartRefs[chartName]) chartRefs[chartName].revert();
  }

  static toggle(chartName: string, plotName: string) {
    const { chartRefs } = AnalyticsLineChartService;
    if (chartRefs && chartRefs[chartName])
      chartRefs[chartName].toggle(plotName);
  }
}
