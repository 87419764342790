// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import C3Chart from "common/chart/components/C3Chart";
import "common/tile/Tile.less";
import TileErrorMessage from "common/tile/TileErrorMessage";
import TileSwitch from "common/tile/TileSwitch";
import C3CustomLegend, { ILegendItem } from "components/common/C3CustomLegend";
import Processing from "components/common/Processing";
import { routesConfig } from "core/app/components/router/RouterProvider";
import { Statuses } from "core/data/models/Data";
import { selectAll } from "d3";
import React from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router";
import {
  colorRiskHigh,
  colorRiskLow,
  colorRiskMedium,
  colorRiskUnknown
} from "styles/ColorVariables";
import {
  INumberOfAssets,
  INumberOfAssetsStateProps,
  Page
} from "../reducers/NumberOfAssetsReducer";
import "./NumberOfAssets.less";
import { getStaticMarkup } from "./NumberOfAssetsTooltip";

export interface INumberOfAssetsProps
  extends INumberOfAssetsActions,
    INumberOfAssetsStateProps {
  intl: IntlShape;
}

export interface INumberOfAssetsActions {
  loadNumberOfAssetsByType: () => void;
  loadNumberOfAssetsByOrganizations: () => void;
  setPage: (page: Page) => void;
}

class NumberOfAssets extends React.Component<
  INumberOfAssetsProps & RouteComponentProps,
  any
> {
  public render(): JSX.Element {
    const { numberOfAssetsByOrganization, numberOfAssetsByType } = this.props;
    let component: JSX.Element = null;

    if (
      numberOfAssetsByType.status === Statuses.Loading ||
      numberOfAssetsByOrganization.status === Statuses.Loading
    ) {
      component = this.getLoadingComponent();
    } else if (
      numberOfAssetsByType.status === Statuses.Succeeded &&
      numberOfAssetsByOrganization.status === Statuses.Succeeded
    ) {
      component = this.getNumberOfAssetsComponent();
    } else if (
      numberOfAssetsByType.status === Statuses.Failed ||
      numberOfAssetsByOrganization.status === Statuses.Failed
    ) {
      component = this.getErrorComponent();
    } else {
      component = this.getEmptyTileComponent();
    }

    return component;
  }

  public componentDidMount(): void {
    this.props.loadNumberOfAssetsByType();
    this.props.loadNumberOfAssetsByOrganizations();
  }

  private handleC3ChartRendered(): void {
    selectAll(
      ".number-of-assets .c3 svg .c3-grid .c3-ygrids line.c3-ygrid"
    ).style("stroke", "#e4e4e4");
  }

  private prepareColumns(
    data: INumberOfAssets
  ): Array<[string, ...c3.PrimitiveArray]> {
    const columns: Array<[string, ...c3.PrimitiveArray]> = [
      ["x"],
      ["unknown"],
      ["high"],
      ["medium"],
      ["low"]
    ];

    const keys: string[] = Object.keys(data);
    for (const key of keys) {
      const value = data[key];
      if (key === "")
        columns[0].push(
          this.props.intl.formatMessage({
            id: "organization.unknown",
            defaultMessage: "Unknown"
          })
        );
      else columns[0].push(key);
      columns[1].push(value.UnknownRisk);
      columns[2].push(value.HighRisk);
      columns[3].push(value.MediumRisk);
      columns[4].push(value.LowRisk);
    }

    return columns;
  }

  private getC3ChartAxisConfig(columns: Array<[string, ...c3.PrimitiveArray]>) {
    return {
      x: {
        type: "category",
        tick: {
          format: function (x: number) {
            const name: string = columns[0][x + 1].toString();
            if (name.length > 15) {
              return name.substr(0, 12) + "...";
            } else {
              return name;
            }
          },
          rotate: 90,
          multiline: false
        },
        height: 100
      },
      y: {
        tick: {
          format: (value: number) => (Number.isInteger(value) ? value : "")
        }
      }
    };
  }

  private getC3ChartDataConfig(
    columns: Array<[string, ...c3.PrimitiveArray]>,
    filterName: string
  ): any {
    const { history } = this.props;
    const configuration: c3.Data = {
      x: "x",
      columns: columns,
      colors: {
        high: colorRiskHigh,
        medium: colorRiskMedium,
        low: colorRiskLow,
        unknown: colorRiskUnknown
      },
      groups: [["high", "medium", "low", "unknown"]],
      onclick: (d: any): void => {
        const column = columns[0][d.x + 1] as string;
        const searchParams = new SearchParams();
        const unknownOrganization = this.props.intl.formatMessage({
          id: "organization.unknown",
          defaultMessage: "Unknown"
        });

        if (!!column && column !== unknownOrganization)
          searchParams.append(filterName, `["${decodeURIComponent(column)}"]`);

        if (column === unknownOrganization)
          searchParams.append(filterName, `[${null}]`);

        searchParams.append(`f_as`, JSON.stringify(["InService"]));
        history.push({
          pathname: routesConfig.assets,
          search: searchParams.toString()
        });
      },
      order: null as any,
      type: "bar"
    };

    return configuration;
  }

  private getC3ChartTooltipConfig(
    columns: Array<[string, ...c3.PrimitiveArray]>
  ) {
    const colors: { [level: string]: string } = {
      high: colorRiskHigh,
      medium: colorRiskMedium,
      low: colorRiskLow,
      unknown: colorRiskUnknown
    };

    const sorting: { [level: string]: number } = {
      high: 2,
      medium: 1,
      low: 0,
      unknown: 3
    };

    return {
      contents: (d: any): string => {
        d.sort((a: any, b: any) => {
          if (sorting[a.id] < sorting[b.id]) return -1;
          if (sorting[a.id] > sorting[b.id]) return 1;
          return 0;
        });

        return getStaticMarkup({
          intl: this.props.intl,
          colors,
          dataPoints: d,
          title: columns[0][d[0].x + 1].toLocaleString()
        });
      }
    };
  }

  private getLoadingComponent(): JSX.Element {
    return (
      <div className="number-of-assets">
        <div className="tile">
          <div className="tile-content processing">
            <Processing className="spinner small dark" />
          </div>
        </div>
      </div>
    );
  }

  private getNumberOfAssetsComponent(): JSX.Element {
    return (
      <div className="number-of-assets">
        <div className="tile" data-qa="assets-by-type-tile">
          <div className="tile-content">
            {this.props.page === Page.ByType
              ? this.getNumberOfAssetsByTypeComponent()
              : null}
            {this.props.page === Page.ByOrganization
              ? this.getNumberOfAssetsByOrganizationComponent()
              : null}
          </div>
          <div className="tile-footer" data-qa="footer">
            <TileSwitch
              dots={[
                {
                  checked: this.props.page === Page.ByType,
                  onClick: () => {
                    this.props.setPage(Page.ByType);
                  }
                },
                {
                  checked: this.props.page === Page.ByOrganization,
                  onClick: () => {
                    this.props.setPage(Page.ByOrganization);
                  }
                }
              ]}
              onLeftClick={() => {
                if (this.props.page === Page.ByOrganization)
                  this.props.setPage(Page.ByType);
              }}
              onRightClick={() => {
                if (this.props.page === Page.ByType)
                  this.props.setPage(Page.ByOrganization);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  private getNumberOfAssetsByTypeComponent(): JSX.Element[] {
    return this.getNumberOfAssetsComponentByGroup(
      "homepage.number_of_assets_by_type",
      "Assets by Type",
      this.props.numberOfAssetsByType.data,
      "assetType",
      "f_at"
    );
  }

  private getNumberOfAssetsByOrganizationComponent(): JSX.Element[] {
    return this.getNumberOfAssetsComponentByGroup(
      "homepage.number_of_assets_by_organization",
      "Assets by Organization",
      this.props.numberOfAssetsByOrganization.data,
      "organization",
      "f_ao"
    );
  }

  private getNumberOfAssetsComponentByGroup(
    messageId: string,
    defaultMessage: string,
    data: INumberOfAssets,
    queryParamName: string,
    filterName: string
  ): JSX.Element[] {
    const titleComponent: JSX.Element = (
      <div key="title" className="title" data-qa="title">
        <FormattedMessage id={messageId} defaultMessage={defaultMessage} />
      </div>
    );

    const columns: Array<[string, ...c3.PrimitiveArray]> =
      this.prepareColumns(data);

    const c3ChartConfig: any = {
      onrendered: this.handleC3ChartRendered,
      axis: this.getC3ChartAxisConfig(columns),
      data: this.getC3ChartDataConfig(columns, filterName),
      grid: { y: { show: true } },
      legend: { show: false },
      tooltip: this.getC3ChartTooltipConfig(columns),
      size: { height: 250 }
    };

    const contentComponent = (
      <div
        key="content"
        className="assets-by-organization"
        data-qa={"assets-by-" + queryParamName}
      >
        <C3CustomLegend
          items={["low", "medium", "high", "unknown"].map<ILegendItem>((x) => {
            return {
              id: x,
              background:
                x === "high"
                  ? colorRiskHigh
                  : x === "medium"
                  ? colorRiskMedium
                  : x === "low"
                  ? colorRiskLow
                  : colorRiskUnknown,
              messageId: "risk." + x.charAt(0).toUpperCase() + x.slice(1),
              defaultMessage: x.charAt(0).toUpperCase() + x.slice(1)
            } as ILegendItem;
          })}
          onMouseOver={(id: string) => {
            this.chart.focus(id);
          }}
          onMouseOut={(id: string) => {
            this.chart.revert();
          }}
          onClick={(id: string) => {
            this.chart.toggle(id);
          }}
        />
        <C3Chart
          configuration={c3ChartConfig}
          onRendered={(c) => {
            this.chart = c;
          }}
        />
      </div>
    );

    return [titleComponent, contentComponent];
  }

  private chart: c3.ChartAPI;
  private getErrorComponent(): JSX.Element {
    return (
      <div className="number-of-assets">
        <TileErrorMessage
          messageId="homepage.server_error"
          defaultMessage="Sorry, we've got problems with server connection."
        />
      </div>
    );
  }

  private getEmptyTileComponent(): JSX.Element {
    return (
      <div className="number-of-assets">
        <div className="tile">
          <div className="tile-content empty" />
        </div>
      </div>
    );
  }
}

export default withRouter(injectIntl(NumberOfAssets));
