// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { Input } from "antd";

const postponeTime = 1000;

interface ISearchProps {
  defaultSearchValue?: string;
  onSearch?: (value: string) => void;
}

const Search = ({ onSearch, defaultSearchValue }: ISearchProps) => {
  const timerRef = useRef<number>();
  const [internalSearch, setInternalSearch] = useState<string>(
    defaultSearchValue
  );

  const clearTimer = useCallback(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
  }, [timerRef]);

  const setTimer = useCallback(
    (func: () => void) => {
      clearTimer();
      timerRef.current = window.setTimeout(func, postponeTime);
    },
    [clearTimer, timerRef]
  );

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setInternalSearch(value);
      setTimer(() => {
        if (onSearch) onSearch(value);
      });
    },
    [onSearch, setTimer]
  );

  useEffect(() => {
    return () => {
      clearTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Input.Search value={internalSearch} onChange={handleSearchChange} />;
};

export default Search;
