// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { uniq } from "lodash";
import moment from "moment";
import {
  Axis,
  IAnalyticsLineChartDataSet,
  IDataSeriesPoint
} from "../AnalyticsLineChart";
import StandardOilTestsChartService from "./StandardOilTestsChartService";
import { IOilParameters, IParamCollection } from "./StandardOilTestsTab";

export const interfacialTension = "InterfacialTension";
export const moisture = "Moisture";
export const powerFactor = "PowerFactor";
export const powerFactor25C = "PowerFactor25C";
export const powerFactor90C = "PowerFactor90C";
export const powerFactor100C = "PowerFactor100C";
export const dielectricStrength = "DielectricStrength";
export const acidNumber = "AcidNumber";
export const inhibitorContent = "InhibitorContent";

export const dateFormat = "YYYY-MM-DD HH:mm:ss";

export default class StandardOilTestsTabService {
  static hasTrendsData = (sot: IParamCollection, chartName: string) => {
    const { hasTrends, has } = StandardOilTestsTabService;
    return has(sot, chartName, (chart) => hasTrends(chart));
  };

  static hasThresholdMinSeries = (sot: IParamCollection, chartName: string) => {
    const { hasThreshold, has } = StandardOilTestsTabService;
    return has(sot, chartName, (chart) => hasThreshold(chart, "Min"));
  };

  static hasThresholdMaxSeries = (sot: IParamCollection, chartName: string) => {
    const { hasThreshold, has } = StandardOilTestsTabService;
    return has(sot, chartName, (chart) => hasThreshold(chart, "Max"));
  };

  static hasThresholdPoorSeries = (
    sot: IParamCollection,
    chartName: string
  ) => {
    const { hasThreshold, has } = StandardOilTestsTabService;
    return has(sot, chartName, (chart) => hasThreshold(chart, "Poor"));
  };

  static hasThresholdFairSeries = (
    sot: IParamCollection,
    chartName: string
  ) => {
    const { hasThreshold, has } = StandardOilTestsTabService;
    return has(sot, chartName, (chart) => hasThreshold(chart, "Fair"));
  };

  static hasOfflineSeries = (sot: IParamCollection, chartName: string) => {
    const { has } = StandardOilTestsTabService;
    return has(
      sot,
      chartName,
      (chart) =>
        chart &&
        chart.OfflineTrends !== undefined &&
        chart.OfflineTrends != null
    );
  };

  static hasOnlineSeries = (sot: IParamCollection, chartName: string) => {
    const { has } = StandardOilTestsTabService;
    return has(
      sot,
      chartName,
      (chart) =>
        chart && chart.OnlineTrends !== undefined && chart.OnlineTrends != null
    );
  };

  static getThresholdMinSet = (
    sot: IParamCollection,
    chartName: string
  ): IAnalyticsLineChartDataSet[] => {
    const { getThresholdSet, hasTrendsData } = StandardOilTestsTabService;

    if (chartName === powerFactor) {
      return hasTrendsData(sot, powerFactor90C)
        ? [getThresholdSet(sot, powerFactor90C, "Min")]
        : [
            getThresholdSet(sot, powerFactor25C, "Min"),
            getThresholdSet(sot, powerFactor100C, "Min", Axis.Right)
          ];
    } else {
      return [getThresholdSet(sot, chartName, "Min")];
    }
  };

  static getThresholdMaxSet = (
    sot: IParamCollection,
    chartName: string
  ): IAnalyticsLineChartDataSet[] => {
    const { getThresholdSet, hasTrendsData } = StandardOilTestsTabService;

    if (chartName === powerFactor) {
      return hasTrendsData(sot, powerFactor90C)
        ? [getThresholdSet(sot, powerFactor90C, "Max")]
        : [
            getThresholdSet(sot, powerFactor25C, "Max"),
            getThresholdSet(sot, powerFactor100C, "Max", Axis.Right)
          ];
    } else {
      return [getThresholdSet(sot, chartName, "Max")];
    }
  };

  static getThresholdPoorSet = (
    sot: IParamCollection,
    chartName: string
  ): IAnalyticsLineChartDataSet[] => {
    const { getThresholdSet, hasTrendsData } = StandardOilTestsTabService;

    if (chartName === powerFactor) {
      return hasTrendsData(sot, powerFactor90C)
        ? [getThresholdSet(sot, powerFactor90C, "Poor")]
        : [
            getThresholdSet(sot, powerFactor25C, "Poor"),
            getThresholdSet(sot, powerFactor100C, "Poor", Axis.Right)
          ];
    } else {
      return [getThresholdSet(sot, chartName, "Poor")];
    }
  };

  static getThresholdFairSet = (
    sot: IParamCollection,
    chartName: string
  ): IAnalyticsLineChartDataSet[] => {
    const { getThresholdSet, hasTrendsData } = StandardOilTestsTabService;

    if (chartName === powerFactor) {
      return hasTrendsData(sot, powerFactor90C)
        ? [getThresholdSet(sot, powerFactor90C, "Fair")]
        : [
            getThresholdSet(sot, powerFactor25C, "Fair"),
            getThresholdSet(sot, powerFactor100C, "Fair", Axis.Right)
          ];
    } else {
      return [getThresholdSet(sot, chartName, "Fair")];
    }
  };

  static isGenericSet = (chartName: string): boolean => {
    return (
      [
        interfacialTension,
        moisture,
        dielectricStrength,
        acidNumber,
        inhibitorContent
      ].indexOf(chartName) > -1
    );
  };

  static pushSet = (
    sets: IAnalyticsLineChartDataSet[],
    sot: IParamCollection,
    chartName: string,
    mode: "online" | "offline"
  ) => {
    const { getTrendsPoints, isGenericSet, hasTrendsData } =
      StandardOilTestsTabService;
    const { getChartUnit } = StandardOilTestsChartService;

    if (isGenericSet(chartName)) {
      const values = getTrendsPoints(sot, chartName, mode);

      if (values) {
        const set: IAnalyticsLineChartDataSet = {
          standard: sot.OilStandard,
          name: `${chartName}_${mode}`,
          axis: Axis.Left,
          values: values,
          unit: getChartUnit(chartName)
        };

        sets.push(set);
      }
    } else {
      if (hasTrendsData(sot, powerFactor90C)) {
        const values90C = getTrendsPoints(sot, powerFactor90C, mode);

        if (values90C) {
          const set: IAnalyticsLineChartDataSet = {
            standard: sot.OilStandard,
            name: `${powerFactor90C}_${mode}`,
            axis: Axis.Left,
            values: values90C,
            unit: getChartUnit(chartName)
          };

          sets.push(set);
        }
      } else {
        const values25C = getTrendsPoints(sot, powerFactor25C, mode);
        const values100C = getTrendsPoints(sot, powerFactor100C, mode);

        if (values25C) {
          const set: IAnalyticsLineChartDataSet = {
            standard: sot.OilStandard,
            name: `${powerFactor25C}_${mode}`,
            axis: Axis.Left,
            values: values25C,
            unit: getChartUnit(chartName)
          };

          sets.push(set);
        }

        if (values100C) {
          const set: IAnalyticsLineChartDataSet = {
            standard: sot.OilStandard,
            name: `${powerFactor100C}_${mode}`,
            axis: Axis.Right,
            values: values100C,
            unit: getChartUnit(chartName)
          };

          sets.push(set);
        }
      }
    }
  };

  private static getChart = (sot: IParamCollection, name: string) =>
    sot.Charts[name];

  private static getThresholdDates = (chart: IOilParameters): string[] => {
    const getKeys = (
      chart: IOilParameters,
      type: "OnlineTrends" | "OfflineTrends"
    ) => (chart && chart[type] ? Object.keys(chart[type]) : []);

    const getThresholdKeys = (chart: IOilParameters) =>
      chart && chart.Thresholds
        ? Object.values(chart.Thresholds)
            .flat()
            .filter((x) => x.End || x.Start)
            .map(
              (x) => x.Start ?? moment(x.End).add(-1, "second").toISOString()
            )
        : [];

    const onlineDates = getKeys(chart, "OnlineTrends");
    const offlineDates = getKeys(chart, "OfflineTrends");
    const thresholdDates = getThresholdKeys(chart);
    const allDates = uniq([...onlineDates, ...offlineDates, ...thresholdDates]);

    if (allDates.length === 1) {
      const currentDate = moment(allDates[0]).utc().toISOString();
      const prevDate = moment(currentDate).utc().add(-1, "days").toISOString();
      const nextDate = moment(currentDate).utc().add(1, "days").toISOString();

      allDates.push(prevDate, nextDate);
    }

    return allDates;
  };

  private static getThresholdPoints = (
    chart: IOilParameters,
    type: "Min" | "Max" | "Poor" | "Fair"
  ): IDataSeriesPoint[] => {
    const { getThresholdDates } = StandardOilTestsTabService;

    const dates = getThresholdDates(chart);
    const points = dates.map<IDataSeriesPoint>((d) => ({
      date: moment(d).format(dateFormat),
      value: chart.Thresholds[type]?.find(
        (x) =>
          (!x.Start || moment(x.Start) >= moment(d)) &&
          (!x.End || moment(x.End) < moment(d))
      )?.Value
    }));

    return points;
  };

  private static getThresholdSet = (
    sot: IParamCollection,
    chartName: string,
    type: "Min" | "Max" | "Poor" | "Fair",
    axis: Axis = Axis.Left
  ) => {
    const { getChart, getThresholdPoints } = StandardOilTestsTabService;
    const { getChartUnit } = StandardOilTestsChartService;
    const chart = getChart(sot, chartName);
    const values = getThresholdPoints(chart, type);

    return {
      standard: sot.OilStandard,
      name: `${chartName}_${type.toLowerCase()}`,
      axis: axis,
      values: values,
      unit: getChartUnit(chartName)
    };
  };

  private static getTrendsPoints = (
    sot: IParamCollection,
    chartName: string,
    mode: "online" | "offline"
  ) => {
    const { getChart } = StandardOilTestsTabService;
    const chart = getChart(sot, chartName);

    const trends = mode === "online" ? chart.OnlineTrends : chart.OfflineTrends;
    if (!trends) return [];

    const keys = Object.keys(trends);
    if (!keys || !keys.length) return [];

    const points = keys.map<IDataSeriesPoint>((k) => ({
      date: moment(k).format(dateFormat),
      value: trends[k]
    }));

    return points;
  };

  private static has = (
    sot: IParamCollection,
    chartName: string,
    hasFunction: (chart: IOilParameters) => boolean
  ): boolean => {
    const { getChart } = StandardOilTestsTabService;
    if (chartName === powerFactor) {
      const chart25C = getChart(sot, powerFactor25C);
      const chart90C = getChart(sot, powerFactor90C);
      const chart100C = getChart(sot, powerFactor100C);
      const hasChart25C = hasFunction(chart25C);
      const hasChart90C = hasFunction(chart90C);
      const hasChart100C = hasFunction(chart100C);
      return hasChart25C || hasChart90C || hasChart100C;
    } else {
      const chart = getChart(sot, chartName);
      const has = hasFunction(chart);
      return has;
    }
  };

  private static hasTrends = (chart: IOilParameters) => {
    const { isSet } = StandardOilTestsTabService;
    return chart && (isSet(chart.OnlineTrends) || isSet(chart.OfflineTrends));
  };

  private static hasThreshold = (
    chart: IOilParameters,
    type: "Min" | "Max" | "Poor" | "Fair"
  ) => {
    const { isSet } = StandardOilTestsTabService;
    return chart ? isSet(chart.Thresholds[type]) : false;
  };

  private static isSet = (value: any) =>
    value !== undefined &&
    value != null &&
    ((Array.isArray(value) && value.length > 0) || !Array.isArray(value));
}
