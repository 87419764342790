// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import { find, first } from "lodash";
import { memo, useCallback, useMemo } from "react";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import Header from "./Header";
import TabsSwitch from "./TabsSwitch";

import IRoutableTab from "../models/IRoutableTab";

import { StaticContext } from "react-router";
import { colorContentAreaLight, colorGray10 } from "styles/ColorVariables";
import { spacingBase, spacingMedium } from "styles/StyleVariables";

interface IRoutableTabsReactRouterProps {
  path: string;
  url: string;
}

interface IRoutableTabsOwnProps {
  className?: string;
  tabs: IRoutableTab[];
  defaultRouteId?: string;
  noBorder?: boolean;
  searchParams?: SearchParams;
}

type RoutableTabsProps = IRoutableTabsReactRouterProps & IRoutableTabsOwnProps;

const RoutableTabs = memo(
  ({
    className,
    tabs,
    path,
    url,
    defaultRouteId,
    noBorder,
    searchParams
  }: RoutableTabsProps) => {
    const pathName = useMemo(() => {
      return defaultRouteId || first(tabs)?.id;
    }, [defaultRouteId, tabs]);

    const redirectPathName = useMemo(() => {
      return `${url}/${pathName}`;
    }, [url, pathName]);

    const handleRender = useCallback(
      (
        routerProps: RouteComponentProps<
          {
            id: string;
          },
          StaticContext,
          {}
        >
      ) => {
        const {
          match: {
            params: { id }
          },
          history
        } = routerProps;
        const tab = find(tabs, (x) => x.id === id);

        if (tab && tab.Content) {
          return (
            <div className={`tab-routable-body ${tab.className || ""}`}>
              <tab.Content />
            </div>
          );
        } else {
          history.replace({
            pathname: redirectPathName,
            search: searchParams?.toString()
          });
        }
      },
      [redirectPathName, searchParams, tabs]
    );

    return (
      <div className={`${className} tabs-routable`}>
        <Header tabs={tabs} noBorder={noBorder} searchParams={searchParams} />
        <div className="tabs-routable-container">
          <TabsSwitch
            path={path}
            searchParams={searchParams}
            redirectPathName={redirectPathName}
            onRender={handleRender}
          />
        </div>
      </div>
    );
  }
);

const StyledRoutableTabs = styled(RoutableTabs)`
  overflow-y: hidden;
  .tabs-routable-container {
    border-width: ${({ noBorder }) => (noBorder ? "0px" : "1px")};
    border-style: solid;
    border-color: ${colorGray10};
    background-color: ${colorContentAreaLight};
    width: 100%;
    height: 100%;
  }

  .tab-routable-body {
    padding: ${spacingMedium} ${spacingBase} ${spacingMedium} ${spacingBase};
  }
`;

export default (props: IRoutableTabsOwnProps) => {
  const { path, url } = useRouteMatch();
  return <StyledRoutableTabs {...props} path={path} url={url} />;
};
