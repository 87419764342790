// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as d3 from "d3";
import { IntlShape } from "react-intl";
import { getStaticMarkup } from "../components/DuvalPentagonTooltip";
import IDuvalPoint from "../models/IDuvalPoint";
import createDuvalPentagonConvertedCoordinates from "./createDuvalPentagonConvertedCoordinates";

interface IDuvalPentagonPoints {
  root: d3Selection<SVGElement>;
  tooltip: d3Selection<HTMLElement>;
  points: IDuvalPoint[];
  intl: IntlShape;
}

type d3Selection<T extends d3.BaseType> = d3.Selection<
  T,
  unknown,
  null,
  undefined
>;

const createDuvalPentagonPoints = ({
  root,
  tooltip,
  points,
  intl
}: IDuvalPentagonPoints) => {
  const sortedPoints = points.sort((a, b) =>
    a.Date < b.Date ? -1 : a.Date > b.Date ? 1 : 0
  );
  const length = sortedPoints.length;

  const maxPointSize = 5;
  const minSizeThreshold = 4;
  const visiblePoints = [];

  if (length > 0) {
    for (let i = 0; i <= sortedPoints.length; i++) {
      if (!sortedPoints[i]) continue;

      visiblePoints.push(sortedPoints[i]);
      const pointCoords = getPointCoordinates(sortedPoints[i]);
      const coords = createDuvalPentagonConvertedCoordinates({
        point: pointCoords
      });
      const circle = root
        .append("circle")
        .attr("cx", coords.x)
        .attr("cy", coords.y)
        .attr("r", 0)
        .attr("style", "fill:black;stroke:white;stroke-width:2;")
        .on("mouseover", (d: any) => showTooltip(tooltip, d, intl))
        .on("mouseout", (d: any) => hideTooltip(tooltip));

      circle
        .transition()
        .delay(50 * i)
        .duration(200)
        .attr(
          "r",
          maxPointSize * Math.pow((i + 1) / length, 2) + minSizeThreshold
        );
    }

    root.selectAll("circle").data(visiblePoints);
  }
};

function getPointCoordinates(point: IDuvalPoint) {
  const eighteenDegrees = (18.0 * Math.PI) / 180.0; // 18 degrees in radians.
  const fiftyFourDegrees = (54.0 * Math.PI) / 180.0; // 54 degrees in radians.

  let x1 = 0;
  let y1 = getHydrogenPercent(point);
  let x2 = getEthanePercent(point) * Math.cos(eighteenDegrees) * -1;
  let y2 = getEthanePercent(point) * Math.sin(eighteenDegrees);
  let x3 = getMethanePercent(point) * Math.cos(fiftyFourDegrees) * -1;
  let y3 = getMethanePercent(point) * Math.sin(fiftyFourDegrees) * -1;
  let x4 = getEthylenePercent(point) * Math.cos(-fiftyFourDegrees);
  let y4 = getEthylenePercent(point) * Math.sin(-fiftyFourDegrees);
  let x5 = getAcetylenePercent(point) * Math.cos(eighteenDegrees);
  let y5 = getAcetylenePercent(point) * Math.sin(eighteenDegrees);

  // Area
  let area1 = x1 * y2 - x2 * y1;
  let area2 = x2 * y3 - x3 * y2;
  let area3 = x3 * y4 - x4 * y3;
  let area4 = x4 * y5 - x5 * y4;
  let area5 = x5 * y1 - x1 * y5;
  let totalArea = (area1 + area2 + area3 + area4 + area5) / 2;

  // Centroipoint X
  let centroidX1 = (x1 + x2) * area1;
  let centroidX2 = (x2 + x3) * area2;
  let centroidX3 = (x3 + x4) * area3;
  let centroidX4 = (x4 + x5) * area4;
  let centroidX5 = (x5 + x1) * area5;
  let centroidX =
    (centroidX1 + centroidX2 + centroidX3 + centroidX4 + centroidX5) /
    (6 * totalArea);

  // Centroipoint Y
  let centroidY1 = (y1 + y2) * area1;
  let centroidY2 = (y2 + y3) * area2;
  let centroidY3 = (y3 + y4) * area3;
  let centroidY4 = (y4 + y5) * area4;
  let centroidY5 = (y5 + y1) * area5;
  let centroidY =
    (centroidY1 + centroidY2 + centroidY3 + centroidY4 + centroidY5) /
    (6 * totalArea);

  return {
    x: centroidX,
    y: centroidY
  };
}

function getHydrogenPercent(point: IDuvalPoint) {
  return getRelativePercent(point.Hydrogen, point);
}

function getAcetylenePercent(point: IDuvalPoint) {
  return getRelativePercent(point.Acetylene, point);
}

function getEthylenePercent(point: IDuvalPoint) {
  return getRelativePercent(point.Ethylene, point);
}

function getMethanePercent(point: IDuvalPoint) {
  return getRelativePercent(point.Methane, point);
}

function getEthanePercent(point: IDuvalPoint) {
  return getRelativePercent(point.Ethane, point);
}

function getRelativePercent(value: number, point: IDuvalPoint) {
  return (value / getTotal(point)) * 100;
}

function getTotal(point: IDuvalPoint) {
  return (
    point.Hydrogen +
    point.Acetylene +
    point.Ethylene +
    point.Methane +
    point.Ethane
  );
}

function showTooltip(
  tooltip: d3Selection<HTMLElement>,
  point: IDuvalPoint,
  intl: IntlShape
) {
  const labels = {
    hydrogen: "H2",
    acetylene: "C2H2",
    ethylene: "C2H4",
    methane: "CH4",
    ethane: "C2H6"
  };
  const html = getStaticMarkup({
    intl: intl,
    date: new Date(point.Date),
    hydrogenLabel: labels.hydrogen,
    hydrogenPPM: point.Hydrogen,
    hydrogenPercent: Math.round(getHydrogenPercent(point)),
    acetyleneLabel: labels.acetylene,
    acetylenePPM: point.Acetylene,
    acetylenePercent: Math.round(getAcetylenePercent(point)),
    ethyleneLabel: labels.ethylene,
    ethylenePPM: point.Ethylene,
    ethylenePercent: Math.round(getEthylenePercent(point)),
    methaneLabel: labels.methane,
    methanePPM: point.Methane,
    methanePercent: Math.round(getMethanePercent(point)),
    ethaneLabel: labels.ethane,
    ethanePPM: point.Ethane,
    ethanePercent: Math.round(getEthanePercent(point))
  });

  tooltip
    .transition()
    .duration(200)
    .style("opacity", 0.95)
    .style("display", "block");
  tooltip
    .html(html)
    .style("left", (d3 as any).event.pageX + 10 + "px")
    .style("top", (d3 as any).event.pageY + 10 + "px");
}

function hideTooltip(tooltip: d3Selection<HTMLElement>) {
  tooltip
    .transition()
    .duration(500)
    .style("opacity", 0)
    .style("display", "none");
}

export default createDuvalPentagonPoints;
