// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { ThunkDispatch } from "thunk";
import { connect } from "react-redux";
import { IntlShape, useIntl } from "react-intl";
import { isEmpty, find } from "lodash";
import { useHistory } from "react-router-dom";

import { IRow, IRowData } from "common/datagrid/DataGrid";
import ParametersChart, { ISelectedParameter } from "./ParametersChart";
import ParametersGrid from "./ParametersGrid";
import IParameterInfoSummary from "../models/IParameterInfoSummary";
import loadParametersAction from "../actions/loadParameters";
import Data, { Statuses } from "core/data/models/Data";
import { IState } from "reducers/Index";
import getParametersSelector from "../selectors/getParametersSelector";
import IAssetDetails from "features/detailpage/models/IAssetDetails";
import getAssetDetailsSelector from "features/detailpage/selectors/getAssetDetailsSelector";

import "./ParametersTab.less";

import IParameterInfo from "../models/IParameterInfo";

interface IParametersTabDataProps {
  assetDetails: Data<IAssetDetails>;
  parameters: Data<IParameterInfoSummary>;
}

interface IParametersTabActionsProps {
  loadParameters: (assetId: string) => void;
}

interface IParametersTabOwnProps {
  assetId: string;
  modelId: string;
  implementationId: string;
}

interface IParametersTabIntlProps {
  intl: IntlShape;
}

type ParametersTabProps = IParametersTabOwnProps &
  IParametersTabActionsProps &
  IParametersTabDataProps &
  IParametersTabIntlProps;

const selectedParametersNamesSearchParamKey = "p_selected";
const maxParamsLength = 5;

const ParametersTab = ({
  assetId,
  loadParameters,
  parameters,
  assetDetails
}: ParametersTabProps) => {
  const history = useHistory();
  const selectedParametersNamesRef = useRef<string[]>(
    ((search) => {
      try {
        const selected: string[] = JSON.parse(
          new SearchParams(search).get(selectedParametersNamesSearchParamKey)
        );
        if (selected?.length <= maxParamsLength) {
          return selected;
        }
      } catch (e) {
        return [];
      }
      return [];
    })(history.location.search)
  );
  const [selectedParameters, setSelectedParameters] =
    useState<ISelectedParameter[]>();

  const updateSearchParams = useCallback(
    (selectedParametesNames: string[]) => {
      const searchParams = new SearchParams(history.location.search);
      selectedParametersNamesRef.current = selectedParametesNames;
      if (
        selectedParametesNames.length <= maxParamsLength &&
        selectedParametesNames.length > 0
      ) {
        searchParams.set(
          selectedParametersNamesSearchParamKey,
          JSON.stringify(selectedParametesNames)
        );
      } else {
        searchParams.delete(selectedParametersNamesSearchParamKey);
      }
      history.replace({
        search: searchParams.toString()
      });
    },
    [history]
  );

  const handleSelectionChanged = useCallback(
    (
      affectedRow: IRow<IParameterInfo>,
      selectedRows: IRow<IParameterInfo>[]
    ) => {
      const selectedParameters = selectedRows.map<ISelectedParameter>((r) => ({
        name: r.data.Name,
        displayName: r.data.TranslatedName,
        unit: r.data.Unit,
        parameterSource: r.data.ParameterSource
      }));
      setSelectedParameters(selectedParameters);
      updateSearchParams(selectedParameters?.map((x) => x.name));
    },
    [updateSearchParams]
  );

  const isDefaultRowSelected = (arg: IRowData): boolean => {
    return selectedParametersNamesRef?.current?.some((x) => x === arg["Name"]);
  };

  const handleSelectionCleared = useCallback(() => {
    setSelectedParameters([]);
    updateSearchParams([]);
  }, [updateSearchParams]);

  useEffect(() => {
    loadParameters(assetId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const setDefaultSelectedParameters = (
      parameters: Data<IParameterInfoSummary>
    ) => {
      if (
        parameters?.status === Statuses.Succeeded &&
        !isEmpty(parameters?.data) &&
        selectedParametersNamesRef?.current?.length
      ) {
        const selectedParameters: ISelectedParameter[] = [];
        selectedParametersNamesRef?.current?.forEach((name) => {
          const parameter = find(
            parameters.data.Parameters,
            (x) => x.Name === name
          );
          if (parameter) {
            selectedParameters.push({
              name: parameter?.Name,
              displayName: parameter?.TranslatedName,
              unit: parameter?.Unit,
              parameterSource: parameter?.ParameterSource
            });
          }
        });
        setSelectedParameters(selectedParameters);
      }
    };

    setDefaultSelectedParameters(parameters);
  }, [parameters]);

  return (
    <div className="parameters-tab">
      <ParametersChart
        assetId={assetId}
        selectedParameters={selectedParameters}
      />
      <ParametersGrid
        assetId={assetId}
        parameters={parameters}
        assetDetails={assetDetails?.data}
        onSelectionChanged={handleSelectionChanged}
        onSelectionCleared={handleSelectionCleared}
        getDefaultRowSelected={isDefaultRowSelected}
      />
    </div>
  );
};

const mapStateToProps = (
  state: IState,
  {
    assetId,
    implementationId,
    modelId,
    intl
  }: IParametersTabOwnProps & IParametersTabIntlProps
): IParametersTabDataProps => ({
  parameters: getParametersSelector(state)(
    assetId,
    modelId,
    implementationId,
    intl
  ),
  assetDetails: getAssetDetailsSelector(state)(assetId)
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any>
): IParametersTabActionsProps => ({
  loadParameters: (assetId: string) => dispatch(loadParametersAction(assetId))
});

const ParameterTabWithData = connect(
  mapStateToProps,
  mapDispatchToProps
)(ParametersTab);

export default (props: IParametersTabOwnProps) => {
  const intlShape = useIntl();
  return <ParameterTabWithData {...props} intl={intlShape} />;
};
