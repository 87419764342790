// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { isEmpty } from "lodash";

import ReplacementPriority from "features/detailpage/features/replacementscore/components/replacementpriority/ReplacementPriority";

import useReplacementScoreReducer from "features/detailpage/features/replacementscore/hooks/useReplacementScoreReducer";

import { loadReplacementPriorityScoreCalculationAction } from "features/detailpage/features/replacementscore/actions/ReplacementScoreActions";
import { useThunkMiddleware } from "thunk";
import { Statuses } from "core/data/models/Data";

interface IReplacementPriorityContainerProps {
  assetId: string;
  replacementScoreModelImplementationId: string;
}

const ReplacementPriorityContainer = ({
  assetId,
  replacementScoreModelImplementationId
}: IReplacementPriorityContainerProps) => {
  const { scoreCalculation } = useReplacementScoreReducer();
  const loadReplacementPriorityScoreCalculation = useThunkMiddleware(
    loadReplacementPriorityScoreCalculationAction(assetId)
  );

  React.useEffect(() => {
    if (
      scoreCalculation.status !== Statuses.Succeeded ||
      isEmpty(scoreCalculation.data)
    ) {
      loadReplacementPriorityScoreCalculation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReplacementPriority
      scoreCalculation={scoreCalculation}
      replacementScoreModelImplementationId={
        replacementScoreModelImplementationId
      }
    />
  );
};

export default ReplacementPriorityContainer;
