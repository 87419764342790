// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data from "core/data/models/Data";
import {
  Actions,
  loadRogersRatioChartData,
  RogersRatioActions
} from "features/detailpage/features/rogersratio/actions/RogersRatioActions";
import { RogersRatioDataFetched } from "features/detailpage/features/rogersratio/hooks/useProcessRogersRatioData";
import { useCallback, useMemo, useReducer } from "react";
import { IntlShape } from "react-intl";
import { IAjaxAction } from "reducers/Index";
import { Dispatch, Reducer } from "redux";

export interface IState {
  rogersRatioData: Data<RogersRatioDataFetched>;
}

export const initialState: IState = {
  rogersRatioData: new Data()
};

export type RogersRatioReducer = Reducer<IState, RogersRatioActions>;

const reducer: RogersRatioReducer = (
  state = initialState,
  action: RogersRatioActions
) => {
  switch (action.type) {
    case Actions.RogersRatio_SetRogersRatioData: {
      return {
        ...state,
        rogersRatioData: new Data(
          (action as IAjaxAction<RogersRatioDataFetched>).request,
          (action as IAjaxAction<RogersRatioDataFetched>).data
        )
      };
    }
    default:
      return state;
  }
};

export default reducer;

// selectors

export const getRogersRatioSelector = (state: IState) => state.rogersRatioData;

// hook

type UseRogersRatioReducer = {
  rogersRatioData: Data<RogersRatioDataFetched>;
  loadRogersRatioData: (intl: IntlShape) => void;
};

export const useRogersRatioReducer = (
  assetId: string
): UseRogersRatioReducer => {
  const [state, dispatch] = useReducer<RogersRatioReducer>(
    reducer,
    initialState
  );

  const rogersRatioData = useMemo(() => getRogersRatioSelector(state), [state]);

  const loadRogersRatioData = useCallback(
    (intl: IntlShape) =>
      loadRogersRatioChartData(dispatch as Dispatch, intl, assetId),
    [assetId]
  );

  return {
    rogersRatioData,
    loadRogersRatioData
  };
};
