// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data from "core/data/models/Data";
import { getDataKey } from "features/ConfigurationTool/actions/loadAssetDetails";
import IAssetDetails from "features/detailpage/models/IAssetDetails";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";

const getNameplateSelector = createSelector<
  IState,
  (assetId: string) => Data<IAssetDetails>,
  (assetId: string) => Data<IAssetDetails>
>(
  (state: IState) => (assetId: string) => state.data[getDataKey(assetId)],
  (assetDetails) => assetDetails
);

export default getNameplateSelector;
