// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import moment from "moment";
import { FormattedDate } from "react-intl";

interface ITooltipFormattedDate {
  date: moment.MomentInput;
}

const TooltipFormattedDate = ({ date }: ITooltipFormattedDate) => {
  return (
    <span className="date">
      {date === undefined || date == null ? (
        <span>-</span>
      ) : (
        <FormattedDate
          value={moment(date).toDate()}
          year="numeric"
          month="numeric"
          day="numeric"
          hour="numeric"
          minute="numeric"
        />
      )}
    </span>
  );
};

export default TooltipFormattedDate;
