// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { AssetRiskJellyBean } from "@apm/widgets/build/widgets/AssetRiskCounts";
import { drop, first, isEmpty, isNil } from "lodash";
import AssetRisks from "models/AssetRisks";
import IAssetRiskCountsTree from "../models/IAssetRiskCountsTree";
import createJellyBenasValues from "../utils/createJellyBenasValues";

const createAssetRiskJellyBeans = (
  assetRiskCounts: IAssetRiskCountsTree[],
  currentId: string[],
  formatGroupName?: (groupKey: string) => string,
  handleItemClick?: (
    assetRisk: AssetRisks,
    id: string[],
    value: number
  ) => void,
  parentId: string[] = []
): AssetRiskJellyBean[] => {
  if (isNil(assetRiskCounts)) return;

  assetRiskCounts =
    currentId?.length > 0
      ? assetRiskCounts.filter((c) => c.GroupKey === first(currentId))
      : assetRiskCounts;

  return assetRiskCounts.map<AssetRiskJellyBean>((c) => ({
    name: c.GroupKey,
    displayName: formatGroupName ? formatGroupName(c.GroupKey) : c.GroupKey,
    isLeafe: isNil(c.HasChild) ? undefined : !c.HasChild,
    jellyBeans:
      !isNil(currentId) && !isEmpty(currentId)
        ? createAssetRiskJellyBeans(
            c.Childrens,
            drop(currentId),
            formatGroupName,
            handleItemClick,
            [...parentId, c.GroupKey]
          )
        : undefined,
    values: createJellyBenasValues(
      c,
      [...parentId, c.GroupKey],
      handleItemClick
    )
  }));
};

export default createAssetRiskJellyBeans;
