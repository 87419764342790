// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IRoutableTab } from "common/RoutableTabs";
import {
  IVisualizationInfoDictionary,
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import AgingTab from "features/detailpage/features/aging/components/AgingTab";
import AuxiliariesTab from "features/detailpage/features/AuxiliariesTab";
import DielectricSF6Tab from "features/detailpage/features/dielectric/components/DielectricSF6Tab";
import DielectricTab from "features/detailpage/features/dielectric/components/DielectricTab";
import LogTab from "features/detailpage/features/LogTab";
import MechanicalTab from "features/detailpage/features/MechanicalTab";
import OperationsTab from "features/detailpage/features/OperationsTab";
import WearTab from "features/detailpage/features/WearTab";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { IModelDetails } from "../../dielectric/components/DielectricTab/DielectricTab";

interface IUseCircuitBreakerTabsOptions {
  cbkAvailable: boolean;
  assetId: string;
  modelId: string;
  implementationId: string;
}

const useCircuitBreakerTabs = ({
  cbkAvailable,
  assetId,
  modelId,
  implementationId
}: IUseCircuitBreakerTabsOptions) => {
  const { getAvailable } = useFeature();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const model = { modelId, implementationId } as IModelDetails;
  const intl = useIntl();

  const visualizations: IVisualizationInfoDictionary<IRoutableTab> =
    useMemo(() => {
      return {
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Aging]: {
          Content: () => <AgingTab assetId={assetId} />,
          id: "Aging",
          name: intl.formatMessage({
            defaultMessage: "Aging",
            id: "detail_page.widgets.circuit_breaker.aging.tab_title"
          })
        },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Dielectric]: {
          Content: () => <DielectricTab assetId={assetId} model={model} />,
          id: "Dielectric",
          name: intl.formatMessage({
            defaultMessage: "Dielectric",
            id: "detail_page.widgets.circuit_breaker.dielectric.tab_title"
          })
        },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Dielectric_Parameters]:
          {
            Content: () => <DielectricSF6Tab assetId={assetId} model={model} />,
            id: "DielectricParameters",
            name: intl.formatMessage({
              defaultMessage: "Dielectric",
              id: "detail_page.widgets.circuit_breaker.dielectric_parameters.tab_title"
            })
          },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Mechanical]: {
          Content: () => <MechanicalTab assetId={assetId} />,
          id: "Mechanical",
          name: intl.formatMessage({
            defaultMessage: "Mechanical",
            id: "detail_page.widgets.circuit_breaker.mechanical.tab_title"
          })
        },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Wear]: {
          Content: () => <WearTab assetId={assetId} />,
          id: "Wear",
          name: intl.formatMessage({
            defaultMessage: "Wear",
            id: "detail_page.widgets.circuit_breaker.wear.tab_title"
          })
        },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Auxiliaries]: {
          Content: () => <AuxiliariesTab assetId={assetId} />,
          id: "Auxiliaries",
          name: intl.formatMessage({
            defaultMessage: "Auxiliaries",
            id: "detail_page.widgets.circuit_breaker.auxiliaries.tab_title"
          })
        },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Operations]: {
          Content: () => <OperationsTab assetId={assetId} />,
          id: "Operations",
          name: intl.formatMessage({
            defaultMessage: "Operations",
            id: "detail_page.widgets.circuit_breaker.operations.tab_title"
          })
        },
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_Log]: {
          Content: () => <LogTab assetId={assetId} />,
          id: "Log",
          name: intl.formatMessage({
            defaultMessage: "Log",
            id: "detail_page.widgets.circuit_breaker.log.tab_title"
          })
        }
      };
    }, [assetId, intl, model]);

  const cbkTabs: IRoutableTab[] = useMemo(() => {
    if (cbkAvailable) {
      return getAvailable<IRoutableTab>(visualizations);
    }

    return [];
  }, [cbkAvailable, visualizations, getAvailable]);

  return cbkTabs;
};

export default useCircuitBreakerTabs;
