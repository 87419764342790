// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IRoutableTab } from "common/RoutableTabs";
import {
  IVisualizationInfoDictionary,
  useFeature,
  VisualizationKeys
} from "core/app/components/FeatureToggle";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import DCTab from "../../DCTab";

interface IUseDCTransformerTabsOptions {
  dcTransformerAvailable: boolean;
  assetId: string;
}

const useDCTransformerTabs = ({
  dcTransformerAvailable,
  assetId
}: IUseDCTransformerTabsOptions) => {
  const { getAvailable } = useFeature();
  const intl = useIntl();
  const visualizations: IVisualizationInfoDictionary<IRoutableTab> =
    useMemo(() => {
      return {
        [VisualizationKeys.AssetDetailPage_Tabs_Parameters_DC]: {
          Content: () => <DCTab assetId={assetId} />,
          id: "DC",
          name: intl.formatMessage({
            defaultMessage: "DC",
            id: "detail_page.widgets.circuit_breaker.dc.tab_title"
          })
        }
      };
    }, [assetId, intl]);

  const transformerTabs: IRoutableTab[] = useMemo(
    () =>
      dcTransformerAvailable ? getAvailable<IRoutableTab>(visualizations) : [],
    [dcTransformerAvailable, visualizations, getAvailable]
  );

  return transformerTabs;
};

export default useDCTransformerTabs;
