// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import Data from "core/data/models/Data";
import { IState } from "../../../reducers/Index";
import { dataKey } from "../actions/loadTemplates";
import { ITemplateInfo } from "../models/ITemplate";

const getTemplatesSelector = createSelector<
  IState,
  Data<ITemplateInfo[]>,
  Data<ITemplateInfo[]>
>(
  (state: IState) => state.data[dataKey],
  (templates) => templates
);

export default getTemplatesSelector;
