// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import "components/common/Processing.less";
import { FC } from "react";


interface IProcessingProps {
  className?: string;
}

const defaultClassName = "spinner margin small dark";

const Processing: FC<IProcessingProps> = ({ className = "" }) => {
  return (
    <div className={`${defaultClassName} ${className}`}>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
      <div className="bounce4"></div>
      <div className="bounce5"></div>
      <div className="bounce6"></div>
    </div>
  );
};

export default Processing;
