// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IRoutableTab } from "common/RoutableTabs";
import AgingContactWearContainer from "features/detailpage/features/aging/containers/AgingContactWearContainer";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

const useAgingSubTabs = (assetId: string): IRoutableTab[] => {
  const intl = useIntl();
  return useMemo(
    () => [
      {
        Content: () => <AgingContactWearContainer assetId={assetId} />,
        id: "ContactWear",
        name: intl.formatMessage({
          defaultMessage: "Contact Wear (I²t)",
          id: "detail_page.widgets.circuit_breaker.aging.contact_wear_chart.title"
        })
      }
    ],
    [assetId, intl]
  );
};

export default useAgingSubTabs;
