// Copyright 2016-2022 Hitachi Energy. All rights reserved.
const dgaStatus = [
  "bad_issues",
  "issues",
  "normal",
  "severe_issues",
  "unknown"
];

export default dgaStatus;
