// Copyright 2016-2022 Hitachi Energy. All rights reserved.
export const prognosisColorsPalette = [
  "#434343",
  "#246495",
  "#1AA0DC",
  "#47BBE7",
  "#9BDAF3",
  "#3B786E",
  "#3DA190",
  "#5ABFAE",
  "#7ED6C6",
  "#6C46AA",
  "#8664BF",
  "#A288CE",
  "#BAACDD",
  "#628288",
  "#80A0A6",
  "#98B6BC",
  "#BCD0D4",
  "#A8135D",
  "#E64997",
  "#FF94C9",
  "#FFC7E4",
  "#A65503",
  "#D98F45",
  "#F2AC66",
  "#FFD3A8",
  "#ED5739",
  "#F57C64",
  "#FF9F8C",
  "#FFCEC4",
  "#A13FA1",
  "#CC50CC",
  "#F291F2",
  "#FAC5FA"
];

export function* colorGenerator() {
  for (let i = 0; i < prognosisColorsPalette.length; i++)
    yield prognosisColorsPalette[i];
}
