// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { useSelector } from "react-redux";

import getNotificationsEnabledSelector from "../selectors/getNotificationsEnabledSelector";
import getOrganizationsOptionsSelector from "../selectors/getOrganizationsOptionsSelector";
import getOrganizationsCheckedKeys from "../selectors/getOrganizationsCheckedKeys";
import getAllOrganizationsCheckedSelector from "../selectors/getAllOrganizationsCheckedSelector";

import Organizations from "../components/Organizations";

const OrganizationsContainer = () => {
  const notificationsEnabled = useSelector(getNotificationsEnabledSelector);
  const items = useSelector(getOrganizationsOptionsSelector);
  const defaultCheckedKeys = useSelector(getOrganizationsCheckedKeys);
  const allOrganizationsChecked = useSelector(
    getAllOrganizationsCheckedSelector
  );

  return (
    <Organizations
      notificationsEnabled={notificationsEnabled}
      items={items}
      defaultCheckedKeys={defaultCheckedKeys}
      allOrganizationsChecked={allOrganizationsChecked}
    />
  );
};

export default OrganizationsContainer;
