// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import IValidationRules from "@apm/widgets/build/widgets/ConfigurationGrid/models/IValidationRules";
import { createValidationSchema } from "@apm/widgets/build/widgets/ConfigurationGrid/utils/validationHelper";
import { notifications } from "@pg/common/build/components/Notifications";
import { FormInstance } from "antd";
import { RcFile } from "antd/lib/upload";
import IConfigurableConnectedDevice from "features/ConfigurationTool/models/connectedDevices/IConfigurableConnectedDevice";
import {
  ConnectedDeviceModelKeys,
  isConnectedDeviceModel
} from "features/ConfigurationTool/models/connectedDevices/typeGuards";
import IFormValues from "features/ConfigurationTool/models/IFormValues";
import ISection from "features/ConfigurationTool/models/ISection";
import { ITabInfo } from "features/ConfigurationTool/models/ITabInfo";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { IFormItem } from "../useForms";
interface IConfig {
  formInfo?: IFormItem;
  connectedDevices: IConfigurableConnectedDevice[];
  setConnectedDevices: (
    val:
      | IConfigurableConnectedDevice[]
      | ((
          prevValue: IConfigurableConnectedDevice[]
        ) => IConfigurableConnectedDevice[])
  ) => void;
  changeTabsInfo: (formKey: string, tabInfo: ITabInfo[]) => void;
  connectedDeviceAction?: "add" | "delete" | "upload";
  setConnectedDeviceAction?: (newAction: "add" | "delete" | "upload") => void;
  connectedDeviceParameterNames?: string[];
  registerForm?: (
    formKey: string,
    form: FormInstance<IFormValues>,
    tabs?: ITabInfo[]
  ) => void;
  form?: FormInstance<IFormValues>;
}
interface ISectionWithGuidConfig {
  guid: string;
  sections: ISection[];
}

export interface ICellValidateParamKey {
  max?: string;
  min?: string;
  required?: string;
  oneOf?: string;
  typeError?: string;
}

export interface ICellValidateMessages {
  required?: string;
  typeError?: string;
  string?: {
    max?: ICellValidateParamKey["max"];
    min?: ICellValidateParamKey["min"];
    oneOf?: ICellValidateParamKey["oneOf"];
  };
  number?: {
    max?: ICellValidateParamKey["max"];
    min?: ICellValidateParamKey["min"];
    oneOf?: ICellValidateParamKey["oneOf"];
  };
}

const connectedDevicesMaxLimit = 5;

export const useConnectedDevices = ({
  formInfo,
  connectedDevices,
  changeTabsInfo,
  connectedDeviceAction,
  setConnectedDevices,
  connectedDeviceParameterNames,
  registerForm,
  form,
  setConnectedDeviceAction
}: IConfig) => {
  const { formatMessage } = useIntl();

  const [activeTabKey, setActiveTabKey] = useState<string>(
    connectedDevices[0]?.Guid
  );

  const [addNewParameterModalVisible, setAddNewParameterModalVisible] =
    useState<boolean>(false);

  const [highlightAfterUpdatedRows, setHighlightAfterUpdatedRows] =
    useState<boolean>(false);

  const [importConnectedDevicesModalOpen, setImportConnectedDevicesModalOpen] =
    useState<boolean>(false);

  const [uploadedData, setUploadedData] = useState(null);

  const handleTabChange = useCallback(
    (activeKey: string) => {
      setActiveTabKey(activeKey);
    },
    [setActiveTabKey]
  );

  const mappedSectionsWithGuid: ISectionWithGuidConfig[] = useMemo(
    () =>
      connectedDevices?.map(({ Guid }) => ({
        sections: formInfo.formConfiguration.sections,
        guid: Guid
      })),
    [connectedDevices, formInfo.formConfiguration]
  );

  const activeConnectedDeviceData = useMemo(
    () => connectedDevices?.find(({ Guid }) => Guid === activeTabKey),
    [connectedDevices, activeTabKey]
  );

  const connectedDevicesTabsInfo: ITabInfo[] = useMemo(
    () =>
      connectedDevices?.map(({ Guid }) => ({
        tabId: Guid,
        invalid:
          formInfo?.tabs?.find(({ tabId }) => tabId === Guid)?.invalid ?? false,
        touched:
          formInfo?.tabs?.find(({ tabId }) => tabId === Guid)?.touched ?? false
      })),
    [connectedDevices, formInfo.tabs]
  );

  const gridConfiguration = useMemo(() => {
    const gridSection = formInfo.formConfiguration.sections.find((section) => {
      return section.sectionType === "grid";
    });

    const mappedGridSection: ISection = {
      ...gridSection,
      fields: [
        ...gridSection.fields.filter(
          ({ fieldKey }) => fieldKey !== "ParamName"
        ),
        {
          ...gridSection.fields.find(
            ({ fieldKey }) => fieldKey === "ParamName"
          ),
          autoCompleteOptions: connectedDeviceParameterNames.map(
            (parameterName) => ({
              value: parameterName,
              label: parameterName
            })
          )
        }
      ].sort((a, b) => a.order - b.order)
    };

    return mappedGridSection;
  }, [connectedDeviceParameterNames, formInfo.formConfiguration.sections]);

  const activeSectionConfig: ISection[] = useMemo(() => {
    const activeSectionWithGuidConfig = mappedSectionsWithGuid?.find(
      ({ guid }) => guid === activeConnectedDeviceData?.Guid
    );

    return activeSectionWithGuidConfig?.sections.map((section) => ({
      ...section,
      guid: activeSectionWithGuidConfig.guid,
      fields: section.fields.map((field) => ({
        ...field,
        guid: activeSectionWithGuidConfig.guid
      }))
    }));
  }, [activeConnectedDeviceData, mappedSectionsWithGuid]);

  const yupValidateMessages: ICellValidateMessages = useMemo(() => {
    return {
      required: formatMessage({
        id: "configuration_tool.validation.value_is_required",
        defaultMessage: "Value is required"
      }),
      typeError: formatMessage({
        id: "configuration_tool.validation.value_incorrect_type",
        defaultMessage: "Value has incorrect type"
      }),
      number: {
        min: formatMessage({
          id: "configuration_tool.validation.value_greater_or_equal_to",
          // eslint-disable-next-line no-template-curly-in-string
          defaultMessage: "Value must be greater or equal to ${min}"
        }),
        max: formatMessage({
          id: "configuration_tool.validation.value_lower_or_equal_to",
          // eslint-disable-next-line no-template-curly-in-string
          defaultMessage: "Value must be lower or equal to ${max}"
        })
      },
      string: {
        max: formatMessage({
          id: "configuration_tool.validation.max_length",
          // eslint-disable-next-line no-template-curly-in-string
          defaultMessage: "Max length: ${max}"
        })
      }
    };
  }, [formatMessage]);

  const setValidationResultTab = useCallback(
    ({
      valid,
      modified,
      tabId = activeTabKey
    }: {
      valid: boolean;
      modified: boolean;
      tabId?: string;
    }) => {
      const tabInfo: ITabInfo[] = [
        {
          tabId,
          touched: modified,
          invalid: !valid
        }
      ];
      changeTabsInfo(formInfo.formConfiguration.formKey, tabInfo);
    },
    [activeTabKey, changeTabsInfo, formInfo.formConfiguration.formKey]
  );

  const isSomeTabInvalid = useMemo(
    () => formInfo?.tabs?.some(({ invalid }) => invalid),
    [formInfo?.tabs]
  );

  const isNoConnectedDevices = useMemo(
    () => connectedDevices?.length === 0,
    [connectedDevices?.length]
  );

  const exportAsJsonDisabledMessage = useMemo(
    () =>
      isSomeTabInvalid
        ? formatMessage({
            id: "configuration_tool.tab.connected_devices_has_errors",
            defaultMessage: "Some data from Connected Devices contains errors"
          })
        : isNoConnectedDevices &&
          formatMessage({
            id: "configuration_tool.tab.connected_devices_no_data_to_export_as_json",
            defaultMessage: "You have no connected devices to export"
          }),
    [formatMessage, isNoConnectedDevices, isSomeTabInvalid]
  );

  const parseJsonToArray = useCallback(
    (data: string) => JSON.parse(`${data}`),
    []
  );

  const hasStructureOfConnectedDeviceModel = useCallback(
    (data: unknown[]) =>
      data.every((obj) => {
        const objectKeys = Object.keys(obj);
        return (
          objectKeys.every((key) => isConnectedDeviceModel(key)) &&
          objectKeys.length === ConnectedDeviceModelKeys.length
        );
      }),
    []
  );

  const onJsonFileUpload = useCallback(
    (file: RcFile) => {
      setConnectedDeviceAction(null);
      if (file.type === "application/json") {
        const reader = new FileReader();

        reader.onload = (e) => {
          try {
            const data: unknown[] = parseJsonToArray(e.target.result as string);

            if (
              Array.isArray(data) &&
              hasStructureOfConnectedDeviceModel(data)
            ) {
              if (data.length <= connectedDevicesMaxLimit) {
                setUploadedData(data);
                setImportConnectedDevicesModalOpen(true);
              } else {
                notifications.error({
                  message: formatMessage({
                    id: "configuration_tool.tab.connected_devices_add_new_device_max_limit",
                    defaultMessage:
                      "The maximum number of Connected Devices has been reached"
                  })
                });
              }
            } else {
              notifications.error({
                message: formatMessage({
                  id: "configuration_tool.tab.connected_devices_import_json_incorrect_type_of_data",
                  defaultMessage: "File has incorrect type of data"
                })
              });
            }
          } catch (err) {
            notifications.error({
              message: formatMessage({
                id: "configuration_tool.tab.connected_devices_import_json_incorrect_type_of_data",
                defaultMessage: "File has incorrect type of data"
              })
            });
          }
        };
        reader.readAsText(file);
      } else {
        notifications.error({
          message: formatMessage({
            id: "configuration_tool.tab.connected_devices_import_json_file_extension_invalid",
            defaultMessage: "File extension is invalid"
          })
        });
      }

      return false;
    },
    [
      formatMessage,
      hasStructureOfConnectedDeviceModel,
      parseJsonToArray,
      setConnectedDeviceAction
    ]
  );

  const configureYupValidatorWithTranslation = useCallback(
    (yupValidationRules: IValidationRules): IValidationRules => {
      const validationParamsKeys =
        yupValidationRules?.params &&
        yupValidationRules?.validations &&
        Object.keys(yupValidationRules.params).filter((key) =>
          yupValidationRules.validations?.includes(
            key as IValidationRules["validations"][0]
          )
        );

      const params = validationParamsKeys?.reduce((acc, val) => {
        const paramKey = val as unknown as keyof ICellValidateParamKey;
        const paramFirstValue = yupValidationRules?.params[paramKey][0];
        const isNumber = yupValidationRules?.validations.includes("number");

        const paramValidationType = isNumber ? "number" : "string";
        return {
          ...acc,
          [val]:
            paramKey === "required" || paramKey === "typeError"
              ? yupValidateMessages[paramKey]
              : [
                  paramFirstValue,
                  yupValidateMessages[paramValidationType][paramKey]
                ]
        };
      }, {});

      return yupValidationRules
        ? {
            validations: yupValidationRules?.validations,
            params
          }
        : undefined;
    },
    [yupValidateMessages]
  );

  const validateGeneralInformationFieldsAfterUpload = useCallback(() => {
    const devicesWithoutRegisterConfiguration = connectedDevices.map(
      (device) => {
        return {
          ...device,
          RegisterConfiguration: undefined
        };
      }
    );

    const generalInformationConfigFields =
      formInfo.formConfiguration.sections.find(
        ({ sectionType }) => sectionType === "horizontalList"
      )?.fields;

    const promises = devicesWithoutRegisterConfiguration
      .filter(({ Imported }) => Imported)
      .map((device) => {
        return Object.entries(device).map(([key, value]) => {
          const keyValidationRules = generalInformationConfigFields.find(
            ({ fieldKey }) => fieldKey === key
          )?.validationRules;

          const validConfig = {
            status: "valid",
            tabId: device.Guid
          };

          if (keyValidationRules) {
            const validationSchema = createValidationSchema(keyValidationRules);

            return validationSchema
              .validate(value)
              .then(() => {
                return validConfig;
              })
              .catch(() => {
                return {
                  status: "invalid",
                  tabId: device.Guid
                };
              });
          }
          return validConfig;
        });
      });

    const uploadedDevicesValidationResultPromises = promises.reduce(
      (acc, val) => [...acc, ...val],
      []
    );

    return Promise.all(uploadedDevicesValidationResultPromises).then(
      (results) => {
        const newTabsInfo: ITabInfo[] = connectedDevicesTabsInfo.map(
          (tabInfo): ITabInfo => {
            const isInvalidDevices =
              results.filter(
                (result) =>
                  result.tabId === tabInfo.tabId && result.status === "invalid"
              )?.length > 0;
            const isValidDevices =
              results.filter(
                (result) =>
                  result.tabId === tabInfo.tabId && result.status === "valid"
              )?.length > 0;

            if (
              connectedDevices.find(
                ({ Guid, Imported }) => Guid === tabInfo.tabId && Imported
              )
            ) {
              return {
                tabId: tabInfo.tabId,
                touched: isValidDevices,
                invalid: isInvalidDevices
              };
            }
            return tabInfo;
          }
        );

        return {
          tabsAfterGeneralInformationValidate: newTabsInfo
        };
      }
    );
  }, [
    connectedDevices,
    connectedDevicesTabsInfo,
    formInfo.formConfiguration.sections
  ]);

  const validateDevicesAfterUploadedJsonFile = useCallback(() => {
    validateGeneralInformationFieldsAfterUpload().then(
      ({ tabsAfterGeneralInformationValidate }) => {
        const registerConfigurationFieldsAllDevices = connectedDevices.map(
          ({ Guid, RegisterConfiguration, Imported }) => {
            return {
              Guid,
              RegisterConfiguration,
              Imported
            };
          }
        );

        const promises = registerConfigurationFieldsAllDevices
          .filter(({ Imported }) => Imported)
          .map((device) => {
            return device.RegisterConfiguration.map(({ id, cells }) => {
              return cells.map((cell) => {
                const cellValidationRules = gridConfiguration.fields.find(
                  ({ fieldKey }) => fieldKey === cell.cellName
                )?.validationRules;

                const cellValidationRulesWithTranslations =
                  configureYupValidatorWithTranslation(cellValidationRules);

                const validConfig = {
                  status: "valid",
                  tabId: device.Guid,
                  id,
                  cellName: cell.cellName,
                  cellValue: cell.cellValue,
                  validationMessage: cell.validationMessage
                };

                if (cellValidationRulesWithTranslations) {
                  const validationSchema = createValidationSchema(
                    cellValidationRulesWithTranslations
                  );

                  return validationSchema
                    .validate(cell.cellValue)
                    .then(() => {
                      return validConfig;
                    })
                    .catch((error) => {
                      return {
                        status: "invalid",
                        tabId: device.Guid,
                        id,
                        cellName: cell.cellName,
                        cellValue: cell.cellValue,
                        validationMessage: error.message
                      };
                    });
                }

                return validConfig;
              });
            });
          });

        const allCellsValidationPromises = promises
          .reduce((acc, config) => [...acc, ...config], [])
          .reduce((acc, cells) => [...acc, ...cells], []);

        Promise.all(allCellsValidationPromises).then((results) => {
          const invalidCells = results.filter(
            (result) => result?.status === "invalid"
          );

          setConnectedDevices((connectedDevices) =>
            connectedDevices.map((device): IConfigurableConnectedDevice => {
              const invalidDeviceCells = invalidCells.filter(
                ({ tabId }) => tabId === device.Guid
              );

              return {
                ...device,
                RegisterConfiguration: invalidDeviceCells
                  ? device.RegisterConfiguration.map((config) => {
                      return {
                        ...config,
                        cells: config.cells.map((cell) => {
                          const invalidRowCellName = invalidDeviceCells.find(
                            ({ id, cellName }) =>
                              config.id === id && cell.cellName === cellName
                          );
                          if (invalidRowCellName) {
                            return {
                              ...cell,
                              valid: false,
                              validationMessage:
                                invalidRowCellName.validationMessage
                            };
                          }
                          return cell;
                        })
                      };
                    })
                  : [...device.RegisterConfiguration]
              };
            })
          );

          const newTabsInfo: ITabInfo[] =
            tabsAfterGeneralInformationValidate.map(
              ({ tabId, touched, invalid }): ITabInfo => {
                const invalidCells = results.filter(
                  (result) =>
                    result.tabId === tabId && result.status === "invalid"
                );

                return {
                  tabId,
                  touched,
                  invalid: invalidCells?.length > 0 || invalid
                };
              }
            );

          registerForm(formInfo.formKey, form, newTabsInfo);
          handleTabChange(newTabsInfo[0].tabId);
        });
      }
    );
  }, [
    configureYupValidatorWithTranslation,
    connectedDevices,
    form,
    formInfo.formKey,
    gridConfiguration.fields,
    handleTabChange,
    registerForm,
    setConnectedDevices,
    validateGeneralInformationFieldsAfterUpload
  ]);

  useEffect(() => {
    if (!connectedDeviceAction)
      handleTabChange(connectedDevices[0]?.Guid ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedDeviceAction]);

  useEffect(() => {
    if (formInfo?.tabs?.length !== 0 && connectedDeviceAction === "delete") {
      handleTabChange(connectedDevices[0]?.Guid ?? null);
    } else if (
      formInfo?.tabs?.length === 0 &&
      connectedDeviceAction === "delete"
    ) {
      handleTabChange(null);
    } else if (connectedDeviceAction === "add") {
      const newAddedDevice = connectedDevices[connectedDevices.length - 1];

      handleTabChange(newAddedDevice.Guid);

      setValidationResultTab({
        valid: false,
        modified: true,
        tabId: newAddedDevice?.Guid
      });
    } else if (connectedDeviceAction === "upload") {
      validateDevicesAfterUploadedJsonFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInfo?.tabs?.length, connectedDeviceAction]);

  return {
    activeTabKey,
    handleTabChange,
    activeConnectedDeviceData,
    activeSectionConfig,
    connectedDevicesTabsInfo,
    addNewParameterModalVisible,
    setAddNewParameterModalVisible,
    highlightAfterUpdatedRows,
    setHighlightAfterUpdatedRows,
    setValidationResultTab,
    exportAsJsonDisabledMessage,
    onJsonFileUpload,
    importConnectedDevicesModalOpen,
    setImportConnectedDevicesModalOpen,
    uploadedData,
    gridConfiguration,
    configureYupValidatorWithTranslation,
    validateDevicesAfterUploadedJsonFile,
    connectedDevicesMaxLimit
  };
};
