// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

import "./ReplacementScoreTooltip.less";

interface IReplacementScoreTooltipProps {
  rank: number;
  total: number;
  scoreValue: number;
  assetType: string;
}
const ReplacementScoreTooltip = ({
  rank,
  total,
  scoreValue,
  assetType
}: IReplacementScoreTooltipProps) => {
  return (
    <div className="rank-tooltip">
      <div className="text">
        <span className="rank">
          {"#"}
          <FormattedNumber value={rank} />{" "}
        </span>
        <FormattedMessage
          id="asset_detail_page.replacement_score.rank.out_of"
          defaultMessage="out of"
        />{" "}
        <FormattedNumber value={total} />
        {assetType != null && <span>{` ${assetType}`}</span>}
      </div>
      <hr />
      <div className="text">
        <FormattedMessage
          id="detail_page.replacement_rank.tooltip.replacement_score"
          defaultMessage="Replacement Score"
        />
        {": "}
        <FormattedNumber value={scoreValue} />
      </div>
    </div>
  );
};

export default ReplacementScoreTooltip;
