// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { useEffect, useContext, useMemo, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import loadDielectricPressure from "../actions/loadDielectricPressure";
import getDielectricPressureSelector from "../selectors/getDielectricPressureSelector";
import getDielectricPressureTrendChartSelector from "../selectors/getDielectricPressureTrendChartSelector";
import DielectricContext from "../../../contexts/DielectricContext";

import useSF6PressureChartProps from "./useSF6PressureChartProps";
import useSF6PressureTranslations from "./useSF6PressureTranslations";
import isCompensatedGasPressureNull from "../utils/isCompensatedGasPressureNull";
import { DielectricDataSource } from "../../../models/DielectricDataSource";
import { IModelDetails } from "../../DielectricTab/DielectricTab";

const useSF6PressureChart = (model: IModelDetails) => {
  const { emptyTranslation } = useSF6PressureTranslations();
  const { assetId } = useContext(DielectricContext);

  const dispatch = useDispatch();

  const [dataSource, setDataSource] = useState<DielectricDataSource>();

  const trendChartSelector = useSelector(
    getDielectricPressureTrendChartSelector
  );

  const trendChart = useMemo(() => trendChartSelector(dataSource), [
    dataSource,
    trendChartSelector
  ]);

  const dielectric = useSelector(getDielectricPressureSelector);

  const handleDataSourceChange = useCallback((id: DielectricDataSource) => {
    setDataSource(id);
  }, []);

  const loadData = useCallback(async () => {
    const data = await loadDielectricPressure(dispatch, assetId);
    if (isCompensatedGasPressureNull(data)) {
      setDataSource(DielectricDataSource.NonCompensatedGasPressure);
    } else {
      setDataSource(DielectricDataSource.CompensatedGasPressure);
    }
  }, [dispatch, assetId]);

  const {
    formatTooltipTitle,
    formatXTick,
    height,
    lines,
    regions,
    series,
    title
  } = useSF6PressureChartProps(model, trendChart);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dielectric,
    dataSource,
    handleDataSourceChange,
    series,
    regions,
    lines,
    formatXTick,
    formatTooltipTitle,
    title,
    height,
    emptyTranslation
  };
};

export default useSF6PressureChart;
