// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { editIssueStatus } from "features/detailpage/features/issues/actions/IssuesActions";
import IssueDetailsHeader, {
  IIssueDetailsHeaderActions,
  IIssueDetailsHeaderData,
  IIssueDetailsHeaderOwnProps
} from "features/detailpage/features/issues/components/IssueDetailsHeader";
import { IssueStatuses } from "features/detailpage/features/issues/models/IssueStatuses";
import { IntlShape } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { Action, Dispatch } from "redux";

export function mapStateToProps(state: IState): IIssueDetailsHeaderData {
  return {};
}

export function mapDispatchToProps(
  dispatch: Dispatch<Action>,
  ownProps: IIssueDetailsHeaderOwnProps
): IIssueDetailsHeaderActions {
  return {
    editIssueStatus: (
      intl: IntlShape,
      issueId: string,
      status: IssueStatuses
    ) => editIssueStatus(dispatch, intl, issueId, status)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueDetailsHeader);
