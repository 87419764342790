// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IState as IOperationsParametersWidgetState } from "@apm/widgets/build/widgets/OperationsParameters";

const convertParametersToArray = (value: IOperationsParametersWidgetState) => {
  const result: string[][] = [];

  Object.keys(value).forEach((groupKey) => {
    const group = value[groupKey];
    Object.keys(group).forEach((parameterKey) => {
      const { checked, color } = group[parameterKey];
      if (checked) {
        result.push([groupKey, parameterKey, color]);
      }
    });
  });

  return result;
};

export default convertParametersToArray;
