// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { useThunkMiddleware } from "thunk";

import useAgingOperations from "features/detailpage/features/aging/hooks/useAgingOperations";
import { loadOperationsAction } from "features/detailpage/features/aging/actions/AgingOperationsActions";

import AgingOperations from "features/detailpage/features/aging/components/AgingOperations";
import { Statuses } from "core/data/models/Data";

interface IAgingTotalOperationsContainerProps {
  assetId: string;
}

export default ({ assetId }: IAgingTotalOperationsContainerProps) => {
  const { agingOperations } = useAgingOperations();

  const loadOperations = useThunkMiddleware(loadOperationsAction(assetId));

  useEffect(() => {
    if (
      agingOperations.status !== Statuses.Succeeded ||
      isEmpty(agingOperations.data)
    ) {
      loadOperations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AgingOperations agingOperations={agingOperations} />;
};
