// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";
import { MessageDescriptor } from "react-intl";

import { IMessage } from "common/form/models/IMessage";
import Input, {
  IInputActions,
  IInputState,
  IInputOwnProps,
  IInputData
} from "./Input";
import Select, { ISelectProps } from "./Select";

export interface ISelectInputActions extends IInputActions {}

export interface ISelectInputData extends IInputData {
  guid: string;
  validationMessages: MessageDescriptor[];
}

export interface ISelectInputOwnProps
  extends IInputOwnProps<any>,
    ISelectProps {}

export interface ISelectInputProps
  extends ISelectInputActions,
    ISelectInputData,
    ISelectInputOwnProps {}

export interface ISelectInputState extends IInputState {
  showValidationMessages: boolean;
}

class SelectInput extends Input<ISelectInputProps, ISelectInputState, any> {
  public static getComputedMessages = (
    messages: IMessage[],
    validationMessages: MessageDescriptor[]
  ): IMessage[] => {
    if (messages || validationMessages) {
      if (!messages) messages = [];
      if (validationMessages)
        messages = messages.concat(
          validationMessages.map<IMessage>((m) => ({
            type: "error",
            descriptor: m
          }))
        );
    }

    return messages;
  };

  state: ISelectInputState = {
    showValidationMessages: false
  };

  componentDidUpdate(prevProps: ISelectInputProps) {
    super.componentDidUpdate(prevProps);

    if (prevProps.guid !== this.props.guid)
      this.setShowValidationMessages(false);
  }

  render() {
    const { validationMessages, messages, guid } = this.props;
    const { showValidationMessages } = this.state;
    const computedMessages = showValidationMessages
      ? SelectInput.getComputedMessages(messages, validationMessages)
      : messages;

    return (
      <div className="input-select">
        <Select
          key={guid}
          {...this.props}
          onInit={this.handleInit.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          onChange={this.handleChange.bind(this)}
          messages={computedMessages}
        />
      </div>
    );
  }

  protected handleChange(value: any) {
    this.setShowValidationMessages(true);
    super.handleChange(value);
  }

  private handleBlur = () => {
    const { onBlur } = this.props;
    this.setShowValidationMessages(true);
    if (onBlur) onBlur();
  };

  private setShowValidationMessages = (value: boolean) => {
    const { showValidationMessages } = this.state;
    if (showValidationMessages !== value) {
      this.setState((prevState) =>
        Object.assign({}, prevState, {
          showValidationMessages: value
        })
      );
    }
  };
}

export default SelectInput;
