// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Row } from "antd";
import Container from "common/Container";
import FilterBar, { SelectedFilters, StatusBar } from "common/FilterBar";
import SectionName from "components/common/SectionName";
import Data from "core/data/models/Data";
import getExperimentalSelector from "core/selectors/getExperimentalSelector";
import { isEmpty, isNil } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import styled from "styled-components";
import { spacingXXLarge, spacingXXXLarge } from "styles/StyleVariables";
import { ThunkDispatch } from "thunk";
import loadTrends from "../actions/loadTrends";
import removeTrends from "../actions/removeTrends";
import IAssetRiskTrend from "../models/IAssetRiskTrend";
import getTrendsSelector from "../selectors/getTrendsSelector";
import getNumberOfAssets from "../utils/getNumberOfAssets";
import AssetRiskCounts from "./AssetRiskCounts";
import AssetRiskTrends from "./AssetRiskTrends";

interface IAssetRiskSummaryActions {
  loadTrends: (filters: SelectedFilters) => void;
  removeTrends: () => void;
}

interface IAssetRiskSummaryState {
  experimental: boolean;
  trends: Data<IAssetRiskTrend[]>;
}

interface IAssetRiskSummaryOwnProps {
  className?: string;
}

type AssetRiskSummaryProps = IAssetRiskSummaryOwnProps &
  IAssetRiskSummaryActions &
  IAssetRiskSummaryState;

const AssetRiskSummary = ({
  className,
  experimental,
  loadTrends,
  removeTrends,
  trends
}: AssetRiskSummaryProps) => {
  const intl = useIntl();
  const [filters, setFilters] = useState<SelectedFilters>();

  const handleFilter = (filters: SelectedFilters) => {
    setFilters(filters);
    loadTrends(filters);
  };

  const numberOfAssets = useMemo(() => {
    if (isNil(trends?.data)) return 0;
    return getNumberOfAssets(trends.data);
  }, [trends?.data]);

  useEffect(() => {
    if (!isNil(filters)) loadTrends(filters);

    return () => {
      removeTrends();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${className} asset-risk-summary`}>
      <FilterBar
        onFilter={handleFilter}
        namespace="asset-risk-summary"
        useAssetOrganizationWithRegionAndLocation
        useAssetTypeWithSubType
        useAssetVoltage
        useAssetAge
        useAssetStatus
        useAssetCompanyHierarchy={{
          hidden: !experimental
        }}
      >
        {filters && (
          <div className="asset-risk-summary-content">
            <Container>
              <div className="row">
                <div className="header">
                  <SectionName
                    messageId="assets-summary.trends.title"
                    messageDefault="Assets Summary / {numberOfAssets} items"
                    messageValues={{
                      numberOfAssets
                    }}
                  />
                  <StatusBar />
                </div>
                <Row data-qa="tiles-row">
                  <AssetRiskTrends trends={trends} />
                </Row>
                <Row gutter={48}>
                  <AssetRiskCounts
                    className="asset-risk-summary-counts"
                    displayType={intl.formatMessage({
                      defaultMessage: "Assets by Age Range",
                      id: "assets-summary.bars.age"
                    })}
                    formatGroupName={(groupName) =>
                      intl.formatMessage({
                        defaultMessage: groupName,
                        id: `age_range_level.${groupName}`
                      })
                    }
                    filters={filters}
                    type="RiskByAgeRange"
                    dataQa="assets-summary.bars.age"
                  />
                  <AssetRiskCounts
                    className="asset-risk-summary-counts"
                    displayType={intl.formatMessage({
                      defaultMessage: "Assets by Voltage Class",
                      id: "assets-summary.bars.voltage"
                    })}
                    filters={filters}
                    formatGroupName={(groupName) =>
                      intl.formatMessage({
                        defaultMessage: groupName,
                        id: `voltage_range_level.${groupName}`
                      })
                    }
                    type="RiskByVoltageClass"
                    dataQa="assets-summary.bars.voltage"
                  />
                  <AssetRiskCounts
                    className="asset-risk-summary-counts"
                    displayType={intl.formatMessage({
                      defaultMessage: "Assets by Asset Type",
                      id: "assets-summary.bars.type"
                    })}
                    filters={filters}
                    formatGroupName={(groupName) =>
                      isNil(groupName) || isEmpty(groupName)
                        ? intl.formatMessage({
                            defaultMessage: "Unknown",
                            id: "asset_type.unknown"
                          })
                        : groupName
                    }
                    type="RiskByAssetType"
                    dataQa="assets-summary.bars.type"
                  />
                  <AssetRiskCounts
                    className="asset-risk-summary-counts"
                    displayType={intl.formatMessage({
                      defaultMessage: "Assets by Organization",
                      id: "assets-summary.bars.organization"
                    })}
                    filters={filters}
                    formatGroupName={(groupName) =>
                      isNil(groupName) || isEmpty(groupName)
                        ? intl.formatMessage({
                            defaultMessage: "Unknown",
                            id: "organization.unknown"
                          })
                        : groupName
                    }
                    type="RiskByOrganization"
                    dataQa="assets-summary.bars.organization"
                  />
                  {experimental && (
                    <AssetRiskCounts
                      className="asset-risk-summary-counts"
                      displayType={intl.formatMessage({
                        defaultMessage: "Assets by Company Hierarchy",
                        id: "assets-summary.bars.company_hierarchy"
                      })}
                      filters={filters}
                      type="RiskByCompanyHierarchy"
                    />
                  )}
                </Row>
              </div>
            </Container>
          </div>
        )}
      </FilterBar>
    </div>
  );
};

const StyledAssetRiskSummary = styled(AssetRiskSummary)`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  .filter-panel {
    flex-shrink: 0;
  }

  .asset-risk-summary-content {
    height: 100%;
    overflow: auto;

    .header {
      display: flex;
      align-items: center;

      .status-panel {
        margin-left: ${spacingXXLarge};
      }
    }

    .asset-risk-summary-counts {
      margin-top: ${spacingXXXLarge};
    }
  }
`;

export default connect(
  (state: IState): IAssetRiskSummaryState => ({
    trends: getTrendsSelector(state),
    experimental: getExperimentalSelector(state)
  }),
  (dispatch: ThunkDispatch<any>): IAssetRiskSummaryActions => ({
    loadTrends: (filters: SelectedFilters) => dispatch(loadTrends(filters)),
    removeTrends: () => dispatch(removeTrends())
  })
)(StyledAssetRiskSummary);
