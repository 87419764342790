// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { Select } from "antd";
import React from "react";
import styled from "styled-components";

const { Option } = Select;

interface IOperationsEventDropdownProps {
  event: string;
  events: string[];
  onChange?: (value: string) => void;
  className?: string;
}

const OperationsEventDropdown = ({
  event,
  events,
  onChange,
  className
}: IOperationsEventDropdownProps) => {
  return (
    <Select className={className} value={event} onChange={onChange}>
      {events.map((id, index) => (
        <Option key={`${id}__${index}`} value={id}>
          {id}
        </Option>
      ))}
    </Select>
  );
};

const StyledOperationsEventDropdown = styled(OperationsEventDropdown)`
  width: 200px;
`;

export default StyledOperationsEventDropdown;
