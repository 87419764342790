// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";
import { Statuses } from "core/data/models/Data";
import {
  WidgetErrorMessage,
  WidgetNoDataMessage
} from "components/common/widget/Widget";
import DuvalPentagonChart from "common/DuvalPentagon/components/DuvalPentagonChart";
import Processing from "components/common/Processing";
import useDuvalPentagonTab from "../hooks/useDuvalPentagonTab";
import "./DuvalPentagonTab.less";

interface IDuvalPentagonTabProps {
  assetId: string;
}

const DuvalPentagonTab = ({ assetId }: IDuvalPentagonTabProps) => {
  const { duvalPoints } = useDuvalPentagonTab(assetId);
  switch (duvalPoints.status) {
    case Statuses.Loading:
      return <Processing />;
    case Statuses.Succeeded:
      return (
        <div className="analytics duval-pentagons" data-qa="duval-pentagons">
          <div className="duval-row">
            <div className="duval-col">
              <DuvalPentagonChart type={3} points={duvalPoints.data} />
            </div>
            <div className="duval-col">
              <DuvalPentagonChart type={1} points={duvalPoints.data} />
            </div>
            <div className="duval-col">
              <DuvalPentagonChart type={2} points={duvalPoints.data} />
            </div>
          </div>
        </div>
      );
    case Statuses.NotFound:
      return (
        <div className="analytics duval-pentagons" data-qa="duval-pentagons">
          <WidgetNoDataMessage />
        </div>
      );
    case Statuses.Failed:
      return (
        <div className="analytics duval-pentagons" data-qa="duval-pentagons">
          <WidgetErrorMessage
            messageId="global.empty"
            messageDefault={""}
            messageValues={""}
          />
        </div>
      );
    default:
      return (
        <div className="analytics duval-pentagons" data-qa="duval-pentagons">
          <div className="tile">
            <div className="tile-content empty" />
          </div>
        </div>
      );
  }
};

export default DuvalPentagonTab;
