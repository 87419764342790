// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { IChart } from "@apm/widgets/build/widgets/ParameterCharts";
import { ReactNode } from "react";
import { IntlShape } from "react-intl";
import chartSettings from "../constants/chartSettings";
import IChartData from "../models/IChartData";
import { OilTest } from "../models/IOilSettings";
import mapToThresholds from "./mapToThresholds";

const mapToChart = (
  chartData: IChartData | undefined,
  name: OilTest,
  displayName: ReactNode,
  intl: IntlShape
): IChart => {
  const chart: IChart = {
    name: name,
    displayName
  };

  if (!chartData) return chart;

  chart.values = {
    offline: {
      series: [
        {
          name: name,
          unit: chartSettings[name].unit,
          values: chartData.OfflineTrends
        }
      ],
      thresholds: chartData.Thresholds
        ? mapToThresholds(chartData.Thresholds, intl)
        : undefined,
      reversedThresholds: chartSettings[name].reversedThresholds
    }
  };

  return chart;
};

export default mapToChart;
