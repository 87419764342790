// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import * as React from "react";
import { IAction, IRow } from "common/datagrid/DataGrid";

export interface IBodyRowActionsProps {
  actions: IAction[];
  row: IRow;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const BodyRowActions = ({ actions, row, onClick }: IBodyRowActionsProps) => {
  if (!actions || !actions.length) return null;

  const elements = actions
    .map((a) => {
      if (a.display && a.iconName) {
        const display = a.display(row);
        if (!display) return null;
        else
          return (
            <Icon
              key={a.id}
              name={a.iconName}
              onClick={(e) => {
                e.stopPropagation();
                a.callback(row);
              }}
            />
          );
      } else if (a.display && a.Component) {
        const display = a.display(row);
        if (!display) return null;
        else return <a.Component key={a.id} row={row} />;
      } else if (a.Component) {
        return <a.Component key={a.id} row={row} />;
      } else if (a.iconName) {
        return (
          <Icon
            key={a.id}
            name={a.iconName}
            onClick={(e) => {
              e.stopPropagation();
              a.callback(row);
            }}
          />
        );
      } else return null;
    })
    .filter((a) => a !== null);

  return (
    <div className="grid-row-actions" onClick={onClick}>
      <div
        className={`actions actions-${elements.length}`}
        style={{ width: elements.length * 40 }}
      >
        {elements}
      </div>
    </div>
  );
};

export default BodyRowActions;
