// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import FormContainer from "common/form/containers/FormContainer";
import ProcessingModal from "components/common/ProcessingModal";
import { Statuses } from "core/data/models/Data";
import IssueStatusSelectContainer from "features/detailpage/features/issues/containers/IssueStatusSelectContainer";
import IIssue from "features/detailpage/features/issues/models/IIssue";
import IIssueMeta from "features/detailpage/features/issues/models/IIssueMeta";
import { IssueStatuses } from "features/detailpage/features/issues/models/IssueStatuses";
import React from "react";
import { IntlShape, useIntl } from "react-intl";
import IssueCreatedDate, { Formats } from "./IssueCreatedDate";
import IssueIcon from "./IssueIcon";

export const issueCompactFormName = "DetailPage_IssueCompactForm";

export interface IIssueCompactActions {
  editIssueStatus: (
    intl: IntlShape,
    issueId: string,
    statue: IssueStatuses
  ) => Promise<void>;
  updateActiveIssue: (issueId: string) => Promise<void>;
}

export interface IIssueCompactOwnProps {
  issue: IIssue;
  meta: IIssueMeta;
  readonly?: boolean;
}

export interface IIssueCompactProps
  extends IIssueCompactActions,
    IIssueCompactOwnProps {}

const IssueCompact = ({
  editIssueStatus,
  updateActiveIssue,
  issue,
  meta,
  readonly
}: IIssueCompactProps) => {
  const intl = useIntl();
  const formName = `${issueCompactFormName}-${issue.Id}`;

  const title = issue.HeaderText;
  const condition = issue.ConditionText;

  const handleStatusChange = async (status: IssueStatuses) => {
    await editIssueStatus(intl, issue.Id, status);
    if (status !== "Closed") updateActiveIssue(issue.Id);
  };

  return (
    <FormContainer
      className="issue-compact"
      name={formName}
      showActionButtons={false}
    >
      <div className="issue-header">
        <div className="issue-header-status">
          <IssueIcon issue={issue} />
          <IssueStatusSelectContainer
            formName={formName}
            disabled={readonly}
            issueStatus={issue.Status}
            onChange={handleStatusChange}
          />
        </div>
        <IssueCreatedDate
          className="issue-header-date"
          format={Formats.Date}
          issue={issue}
        />
      </div>
      <div className="issue-title" data-qa="issue-title">
        {title}
      </div>
      <div className="issue-condition">{condition}</div>
      {meta.editStatusRequest?.status === Statuses.Loading && (
        <ProcessingModal />
      )}
    </FormContainer>
  );
};

export default IssueCompact;
