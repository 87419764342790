// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as d3 from "d3";
import createDuvalPentagonConvertedCoordinates from "./createDuvalPentagonConvertedCoordinates";

interface IDuvalPentagonAxis {
  root: d3.Selection<SVGSVGElement, unknown, null, undefined>;
}

type d3Selection<T extends d3.BaseType> = d3.Selection<
  T,
  unknown,
  null,
  undefined
>;

const createDuvalPentagonAxis = ({ root }: IDuvalPentagonAxis) => {
  const axis = root.append("polygon");
  axis.attr("style", "fill:none;stroke:black;stroke-width:1");

  const vertices = [
    createDuvalPentagonConvertedCoordinates({
      point: { x: 0, y: 40 }
    }),
    createDuvalPentagonConvertedCoordinates({
      point: { x: 38, y: 12.4 }
    }),
    createDuvalPentagonConvertedCoordinates({
      point: { x: 23.5, y: -32.4 }
    }),
    createDuvalPentagonConvertedCoordinates({
      point: { x: -23.5, y: -32.4 }
    }),
    createDuvalPentagonConvertedCoordinates({
      point: { x: -38, y: 12.4 }
    })
  ];

  setPentagonPoints(axis, vertices);
};

function setPentagonPoints(
  pentagon: d3Selection<SVGElement>,
  vertices: { x: number; y: number }[]
) {
  let coordsString = "";
  vertices.forEach((vertex) => {
    coordsString += vertex.x + "," + vertex.y + " ";
  });
  pentagon.attr("points", coordsString);
}

export default createDuvalPentagonAxis;
