// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { ITrendChart } from "common/chart/components/TrendLineChart";
import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { dataKey } from "../actions/loadDielectricTimedPressure";

const getDielectricTimedPressureSelector = createSelector<
  IState,
  Data<ITrendChart>,
  Data<ITrendChart>
>(
  (state: IState) => state.data[dataKey],
  (pressure) => pressure || new Data()
);

export default getDielectricTimedPressureSelector;
