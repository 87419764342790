// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getUserHasEmailSelector = createSelector(
  (state: IState) => state.notifications.modelInput.data,
  data => (data ? data.UserHasEmail : false)
);

export default getUserHasEmailSelector;
