// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import * as React from "react";
import { Modal as AntdModal } from "antd";
import { ModalProps } from "antd/lib/modal";

interface IModalProps extends ModalProps, React.Props<{}> {}

const Modal = ({ children, ...antdProps }: IModalProps) => (
  <AntdModal {...(antdProps as ModalProps)}>
    {React.Children.toArray(children)}
  </AntdModal>
);

export default Modal;
