// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import React, { memo } from "react";
import { StaticContext } from "react-router";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

interface ITabSwitchRouteProps {
  path: string;
  searchParams?: SearchParams;
}

interface ITabsSwitchOwnProps {
  redirectPathName: string;
  onRender: (
    props: RouteComponentProps<
      {
        id: string;
      },
      StaticContext,
      {}
    >
  ) => React.ReactNode;
}

type TabsSwitchProps = ITabSwitchRouteProps & ITabsSwitchOwnProps;

const TabsSwitch = memo(
  ({ path, searchParams, redirectPathName, onRender }: TabsSwitchProps) => {
    return (
      <Switch>
        <Route path={`${path}/:id`} render={onRender} />
        <Redirect
          path={path}
          to={{
            pathname: redirectPathName,
            search: searchParams?.toString()
          }}
        />
      </Switch>
    );
  }
);

export default TabsSwitch;
