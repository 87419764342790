// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Data from "core/data/models/Data";
import { IState } from "reducers/Index";
import { createSelector } from "reselect";
import { getDataKey } from "../actions/loadEvents";

const getEventsNameSelector = createSelector<
  IState,
  (assetId: string) => Data<string[]>,
  (assetId: string) => Data<string[]>
>(
  (state: IState) => (assetId: string) => state.data[getDataKey(assetId)],
  (eventNames) => eventNames
);

export default getEventsNameSelector;
