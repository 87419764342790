// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import IGasSettings, { GasName } from "../models/IGasSettings";

export const gasSettings: { [key in GasName]: IGasSettings } = {
  Acetylene: {
    formula: (
      <>
        C<sub>2</sub>H<sub>2</sub>
      </>
    ),
    unit: "ppm"
  },
  Ethylene: {
    formula: (
      <>
        C<sub>2</sub>H<sub>4</sub>
      </>
    ),
    unit: "ppm"
  },
  Ethane: {
    formula: (
      <>
        C<sub>2</sub>H<sub>6</sub>
      </>
    ),
    unit: "ppm"
  },
  Methane: {
    formula: (
      <>
        CH<sub>4</sub>
      </>
    ),
    unit: "ppm"
  },
  Hydrogen: {
    formula: (
      <>
        H<sub>2</sub>
      </>
    ),
    unit: "ppm"
  },
  CarbonMonoxide: { formula: <>CO</>, unit: "ppm" },
  CarbonDioxide: {
    formula: (
      <>
        CO<sub>2</sub>
      </>
    ),
    unit: "ppm"
  },
  Oxygen: {
    formula: (
      <>
        O<sub>2</sub>
      </>
    ),
    unit: "ppm"
  },
  Nitrogen: {
    formula: (
      <>
        N<sub>2</sub>
      </>
    ),
    unit: "ppm"
  },
  OxideOfCarbonRatio: {
    formula: (
      <>
        CO<sub>2</sub>/CO
      </>
    ),
    reversedName: "OxideOfCarbonRatioReversed",
    reversedFormula: (
      <>
        CO/CO<sub>2</sub>
      </>
    ),
    useRegions: true
  },
  Hydran: {
    unit: "ppm"
  },
  TotalDissolvedCombustibleGases: {
    unit: "ppm"
  }
};

export default gasSettings;
