// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import Icon from "@pg/common/build/components/Icon";
import { FC, useMemo } from "react";
import { connect } from "react-redux";
import { IState } from "reducers/Index";
import { processingIssuesSelector } from "../features/issues/selectors/processingIssuesSelector";

import "./Dashboard.less";
export interface IDashboardPropsOwnProps {
  children?: React.ReactNode;
  expanded: boolean;
  onChange?: (value: boolean) => void;
  sidePanelNode?: React.ReactNode;
}

type DashboardProps = ReturnType<typeof mapStateToProps> &
  IDashboardPropsOwnProps;

const DashboardComponent: FC<DashboardProps> = ({
  expanded,
  onChange,
  sidePanelNode,
  children,
  processingIssues
}) => {
  const sidePanelWithButtonNode = useMemo(() => {
    if (sidePanelNode) {
      return (
        <div className={`side-panel ${expanded ? "expanded" : ""}`}>
          <div className="panel-header" data-qa="panel-header">
            <Icon
              name={expanded ? "chevron_left" : "list"}
              onClick={() => {
                onChange(!expanded);
              }}
              size="lg"
              disabled={processingIssues && !expanded}
            />
          </div>
          <div className="scroll-area">{expanded ? sidePanelNode : null}</div>
        </div>
      );
    }
  }, [sidePanelNode, expanded, onChange, processingIssues]);

  return (
    <div className="dashboard">
      {sidePanelWithButtonNode}
      <div className="content">{children}</div>
    </div>
  );
};

const mapStateToProps = (state: IState, ownProps: IDashboardPropsOwnProps) => ({
  processingIssues: processingIssuesSelector(state),
  expanded: ownProps.expanded,
  onChange: ownProps.onChange,
  sidePanelNode: ownProps.sidePanelNode,
  children: ownProps.children
});

const Dashboard = connect(mapStateToProps, null)(DashboardComponent);

export default Dashboard;
