// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { createSelector } from "reselect";

import { IState } from "reducers/Index";

const getExperimentalSelector = createSelector(
  (state: IState) => state.app.location.queryParams.experimental,
  experimental => experimental
);

export default getExperimentalSelector;
