import { ReactNode } from "react";
import { IntlShape } from "react-intl";

const getGasDisplayName = (
  intl: IntlShape,
  gasName: string,
  translationName?: string,
  formula?: ReactNode
): ReactNode =>
  formula ? (
    <span>
      {intl.formatMessage(
        {
          defaultMessage: `${gasName}: {formula}`,
          id: `detail_page.widgets.analytics.transformers.dga.${translationName}.formula`
        },
        {
          formula: formula
        }
      )}
    </span>
  ) : (
    <span>
      {intl.formatMessage({
        defaultMessage: `${gasName}`,
        id: `detail_page.widgets.analytics.transformers.dga.${gasName}`
      })}
    </span>
  );

export default getGasDisplayName;
