// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import React from "react";

import C3Chart from "common/chart/components/C3Chart";

import useOpenIssuesChart from "../hooks/useOpenIssuesChart";
import IOpenIssueInfo from "../models/IOpenIssuesInfo";

interface IOpenIssueChartProps {
  openIssues: IOpenIssueInfo;
}

const OpenIssuesChart = ({ openIssues }: IOpenIssueChartProps) => {
  const { c3Config, nodeRef, handleChartRendered } = useOpenIssuesChart({
    openIssues
  });

  return (
    <div ref={nodeRef}>
      <C3Chart configuration={c3Config} onRendered={handleChartRendered} />
    </div>
  );
};

export default OpenIssuesChart;
