// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import SearchParams from "@pg/common/build/models/SearchParams";
import { useMemo } from "react";
import { isEmpty } from "lodash";

import ISingleImportantIsses from "../models/ISingleImportantIssue";

interface IUseOpenIssuesGridOptions {
  issues: ISingleImportantIsses[];
}

interface IGridData {
  description: string;
  assetIdWithName: string;
  searchParams: SearchParams;
  scheduledDate: string;
}

const useOpenIssuesGrid = ({ issues }: IUseOpenIssuesGridOptions) => {
  const gridData = useMemo(
    () =>
      issues.map(
        ({
          IssueSummary,
          AssetName,
          AssetId,
          ScheduledDate
        }: ISingleImportantIsses): IGridData => ({
          description: IssueSummary,
          assetIdWithName: isEmpty(AssetName)
            ? AssetId
            : `${AssetId} - ${AssetName}`,
          searchParams: new SearchParams({ assetId: AssetId }),
          scheduledDate: ScheduledDate
        })
      ),
    [issues]
  );

  return { gridData };
};

export default useOpenIssuesGrid;
