// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { IState } from "reducers/Index";
import ActionsModal, {
  IActionsModalData,
  IActionsModalActions
} from "features/detailpage/features/issues/components/ActionsModal";

export function mapStateToProps(state: IState): IActionsModalData {
  return {
    availableWorkOrders: state.detailPage.actions.workOrders.data
  };
}

export function mapDispatchToProps(
  dispatch: Dispatch<Action>
): IActionsModalActions {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionsModal);
