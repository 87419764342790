// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import { loadVersion } from "core/app/actions/VersionActions";
import VersionProvider, {
  IVersionProviderActions
} from "core/app/components/VersionProvider";
import { IState } from "reducers/Index";

function mapStateToProps(state: IState): {} {
  return {};
}

function mapDispatchToProps(
  dispatch: Dispatch<Action>
): IVersionProviderActions {
  return {
    loadVersion: () => {
      loadVersion(dispatch);
    }
  };
}

const VersionProviderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VersionProvider);

export default VersionProviderContainer;
