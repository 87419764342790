// Copyright 2016-2022 Hitachi Energy. All rights reserved.
import { notifications } from "@pg/common/build/components/Notifications";
import SearchParams from "@pg/common/build/models/SearchParams";
import { IAssetPoint, ICluster } from "common/AssetRiskMatrix";
import { routesConfig } from "core/app/components/router/RouterProvider";
import Data, { Statuses } from "core/data/models/Data";
import getTenantSelector from "core/selectors/getTenantSelector";
import IAssetDetails from "features/detailpage/models/IAssetDetails";
import { isNil } from "lodash";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import useRiskMatrixConfiguration from "../../../hooks/useRiskMatrixConfiguration";
import getAssetRiskConfigurationSelector from "../../../selectors/getAssetRiskConfigurationSelector";
import getIsComponentSelector from "../../../selectors/getIsComponentSelector";
import getRiskMatrixClustersSelector from "../../../selectors/getRiskMatrixClustersSelector";
import getRiskMatrixConfigurationSelector from "../../../selectors/getRiskMatrixConfigurationSelector";
import getRiskMatrixPrognosticSelector from "../../../selectors/getRiskMatrixPrognosticSelector";

const formatParam = (value: string | null) =>
  isNil(value) ? null : `"${value}"`;

interface IUseRiskMatrixChartOptions {
  assetId: string;
  assetDetails: Data<IAssetDetails>;
}

const useRiskMatrixChart = ({
  assetId,
  assetDetails
}: IUseRiskMatrixChartOptions) => {
  const intl = useIntl();
  const riskMatrixClusters = useSelector(getRiskMatrixClustersSelector);
  const assetRiskConfiguration = useSelector(getAssetRiskConfigurationSelector);
  const isComponent = useSelector(getIsComponentSelector);
  const tenant = useSelector(getTenantSelector);
  const riskMatrixConfiguration = useSelector(
    getRiskMatrixConfigurationSelector
  );
  const prognosticPoints = useSelector(
    getRiskMatrixPrognosticSelector(
      assetDetails?.data?.NameplateWithModelInfo?.Importance
    )
  );
  const history = useHistory();

  const { getRiskMatrixConfiguration } = useRiskMatrixConfiguration();

  const currentAsset: IAssetPoint = useMemo(
    () => ({
      AssetId: assetId,
      Importance: assetDetails?.data?.NameplateWithModelInfo?.Importance,
      Score:
        assetDetails?.data?.DegradationScore >= 0
          ? assetDetails?.data?.DegradationScore
          : null,
      Risk: assetDetails?.data?.Risk
    }),
    [assetDetails, assetId]
  );

  const otherAssets = useMemo(() => {
    if (
      riskMatrixClusters.status === Statuses.Succeeded &&
      riskMatrixClusters.data
    ) {
      const { AssetPoints } = riskMatrixClusters.data;
      return (AssetPoints || []).filter((x) => x.AssetId !== assetId);
    }

    return [];
  }, [riskMatrixClusters, assetId]);

  const clusters = useMemo(() => {
    if (
      riskMatrixClusters.status === Statuses.Succeeded &&
      riskMatrixClusters.data
    ) {
      const { Clusters } = riskMatrixClusters.data;
      return Clusters;
    }

    return [];
  }, [riskMatrixClusters]);

  const modelId = useMemo(
    () => assetDetails?.data?.NameplateWithModelInfo?.ModelId || null,
    [assetDetails]
  );

  const assetOrganization = useMemo(
    () => assetDetails?.data?.NameplateWithModelInfo?.Organization,
    [assetDetails]
  );

  const assetRegion = useMemo(
    () => assetDetails?.data?.NameplateWithModelInfo?.Region,
    [assetDetails]
  );

  const assetLocation = useMemo(
    () => assetDetails?.data?.NameplateWithModelInfo?.Location,
    [assetDetails]
  );

  const scope = useMemo(() => {
    const config = getRiskMatrixConfiguration(
      riskMatrixConfiguration.data,
      tenant
    );
    return config.DefaultScope;
  }, [getRiskMatrixConfiguration, riskMatrixConfiguration, tenant]);

  const handleOnAssetClick = useCallback(
    (assetId: string) => {
      const searchParams = new SearchParams({ assetId });
      history.push({
        pathname: routesConfig.detailPage,
        search: searchParams.toString()
      });
    },
    [history]
  );

  const navigateToAssetQuery = useCallback(
    ({ MinImportance, MaxImportance, MinScore, MaxScore }: ICluster) => {
      const searchParams = new SearchParams({
        f_s: modelId,
        f_air: [MinImportance, MaxImportance].toString(),
        f_dsr: [MinScore, MaxScore].toString()
      });
      if (scope === "Location") {
        searchParams.append(
          "f_ao",
          `[${formatParam(assetOrganization)},${formatParam(
            assetRegion
          )},${formatParam(assetLocation)}]`
        );
      }
      searchParams.append(`f_as`, JSON.stringify(["InService"]));
      history.push({
        pathname: routesConfig.assets,
        search: searchParams.toString()
      });
    },
    [modelId, scope, history, assetOrganization, assetRegion, assetLocation]
  );

  const showComponentClickNotification = useCallback(() => {
    notifications.info({
      message: intl.formatMessage({
        id: "detail_page.widgets.risk_matrix_widget.cluster_component_click",
        defaultMessage:
          "You are in the Risk Matrix widget for a component. You cannot drill down from here to the Assets page."
      })
    });
  }, [intl]);

  const handleOnClusterClick = useCallback(
    (cluster: ICluster) => {
      if (isComponent) {
        showComponentClickNotification();
      } else {
        navigateToAssetQuery(cluster);
      }
    },
    [isComponent, navigateToAssetQuery, showComponentClickNotification]
  );

  return {
    currentAsset,
    otherAssets,
    assetRiskConfiguration,
    clusters,
    prognosticPoints,
    handleOnAssetClick,
    handleOnClusterClick
  };
};

export default useRiskMatrixChart;
